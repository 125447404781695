import {
  addOwnerNote,
  updateOwnerNote,
  deleteOwnerNote,
  getOwnerNotes,
} from 'actions/owner-notes';

const ownerNotes = () => {
  const initialState = {
    noteCreated: {},
    noteUpdated: {},
    noteDeleted: {},
    allOwnerNotes: [],
  };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case addOwnerNote.requestTypes.SUCCESS:
        return {
          ...state,
          noteCreated: response,
        };
      case updateOwnerNote.requestTypes.SUCCESS:
        return {
          ...state,
          noteUpdated: response,
        };
      case deleteOwnerNote.requestTypes.SUCCESS:
        return {
          ...state,
          noteDeleted: response,
        };
      case getOwnerNotes.requestTypes.SUCCESS:
        return {
          ...state,
          allOwnerNotes: response,
        };
      default:
        return state;
    }
  };
};

export default ownerNotes();
