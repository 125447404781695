import styled from 'styled-components';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Tabs as tabs, Upload, Icon, Row, Button, notification,
} from 'antd';
import { Title, Table, Modal } from 'components';
import * as DateFormatType from 'utils/moments/date-formats';

import {
  addFormUploads,
  deleteFormUploads,
} from 'actions/form-builder';
import uploads from 'selectors/uploads';

import { addSpecDocs, removeSpecDocs } from 'actions/specDocs';
import { API_ROOT } from '../../configs/env-vars';
import AddTabForm from './AddTabForm';

const StyledTabs = styled(tabs)`
  background-color: #fffcfc
  width: 80%;
  min-height: 300px;
`;

notification.config({
  duration: 2,
});

const tableColumns = [
  {
    title: 'File Name',
    dataIndex: 'name',
    key: 'fileName',
    align: 'left',
    render: (text, record) => <a href={`${API_ROOT}/file-storage/${record.hashedId}`} >{record.name}</a>,
    sorter: (a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1),
  },
  {
    title: 'Date Uploaded',
    dataIndex: 'createdAt',
    key: 'createdAt',
    align: 'left',
    render: (text, record) => (`${DateFormatType
      .dateFormatHandler(
        new Date(text),
        DateFormatType.MMM_DD_YYYY,
      )} at ${DateFormatType.dateFormatHandler(
      new Date(text),
      DateFormatType.HH_MM_A,
    )}`),
    sorter: (a, b) => (Date(a.createdAt) < Date(b.createdAt) ? -1 : 1),
  },
];

function Tabs(props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [validation, setValidation] = useState('');
  const newUpload = useSelector(uploads);
  const { TabPane } = tabs;
  const customCategories = props.customCategories
    ? props.customCategories.map((category) => ({
      name: category,
      source: category,
      closable: true,
    }))
    : [];
  const headings = [
    {
      name: 'LOI', source: 'LOIs', closable: false,
    },
    {
      name: 'C.L.', source: 'CLs', closable: false,
    },
    {
      name: 'Loan Documents', source: 'loanDocs', closable: false,
    },
    {
      name: '3rd Party Reports', source: 'thirdPartyReports', closable: false,
    },
    {
      name: 'Estoppels & Leases', source: 'estoppelsAndLeases', closable: false,
    },
    {
      name: 'Other', source: 'otherDocs', closable: false,
    },
    ...customCategories,
  ];

  const [currentTab, setCurrentTab] = useState('LOIs');
  const [optionsIndex, setOptionsIndex] = useState(null);

  const onEdit = (targetKey, action) => {
    if (action === 'add') {
      setShowModal(true);
    } else {
      // eslint-disable-next-line
      if (props.files.find((file) => file.category === targetKey)) {
        notification.warning({
          message: 'Files Exist',
          description: 'Please delete all the files before deleting category.',
        });
      } else {
        const updatedCategories = customCategories.filter((category) => category.name !== targetKey).map((item) => item.name);
        props.updateCustomCategories(updatedCategories);
      }
    }
  };

  const handleOk = (name) => {
    setValidation('');

    if (headings.some((tab) => tab.name.toUpperCase() === name.toUpperCase())) {
      setValidation('Category with same name already exists.');
      return;
    }

    props.addCustomCategory(name);
    setShowModal(false);
  };

  const handleDelete = (record) => {
    if (props.add) {
      dispatch(deleteFormUploads.request({ id: record.hashedId }));
      props.removeFile(record);
    } else if (props.edit) {
      dispatch(deleteFormUploads.request({ id: record.hashedId }));
      dispatch(removeSpecDocs.request(record));
      props.refresh(true);
    }
  };

  const tableData = props.files ? props.files.map((record) => ({
    ...record,
    key: record._id ? record._id : record.hashedId,
    options: record.hashedId === optionsIndex
      ? <Button
        onClick={() => handleDelete(record)}
        type='danger'
        size='small'>Remove</Button>
      : <Icon type='ellipsis' onClick={() => setOptionsIndex(record.hashedId)} />,
  })) : [];

  if ((props.edit || props.add) && tableColumns.length < 3) {
    tableColumns.push({
      title: '',
      dataIndex: 'options',
      key: 'options',
    });
  }

  const uploadFile = (file) => {
    setLoading(true);
    dispatch(addFormUploads.request({
      file: [file],
      source: 'MultiPortfolio',
    }));
  };

  useEffect(() => {
    if (newUpload.LOAD_ADD_FORM_UPLOADS && newUpload.LOAD_ADD_FORM_UPLOADS.length > 0) {
      const fileSet = newUpload.LOAD_ADD_FORM_UPLOADS.map((file) => ({
        fileName: file.name, createdAt: new Date(), hashedId: file.uid,
      }));
      delete newUpload.LOAD_ADD_FORM_UPLOADS;

      if (props.edit) {
        const defaultTabs = ['LOIs', 'CLs', 'loanDocs', 'thirdPartyReports', 'estoppelsAndLeases', 'otherDocs'];
        const isDefaultTab = defaultTabs.indexOf(currentTab) > -1;

        if (isDefaultTab) {
          dispatch(addSpecDocs.request({
            propertyId: props.propertyId,
            category: currentTab,
            name: fileSet[0].fileName,
            createdAt: fileSet[0].createdAt,
            hashedId: fileSet[0].hashedId,
          }));
        } else {
          dispatch(addSpecDocs.request({
            propertyId: props.propertyId,
            customCategory: currentTab,
            name: fileSet[0].fileName,
            createdAt: fileSet[0].createdAt,
            hashedId: fileSet[0].hashedId,
          }));
        }
        setLoading(false);
        props.refresh(true);
      } else if (props.add) {
        if (currentTab === 'LOIs' || currentTab === 'CLs' || currentTab === 'loanDocs') {
          props.addFile({
            category: currentTab,
            name: fileSet[0].fileName,
            createdAt: fileSet[0].createdAt,
            hashedId: fileSet[0].hashedId,
          });
        } else if (currentTab === 'thirdPartyReports' || currentTab === 'estoppelsAndLeases' || currentTab === 'otherDocs') {
          props.addFile({
            category: currentTab,
            name: fileSet[0].fileName,
            createdAt: fileSet[0].createdAt,
            hashedId: fileSet[0].hashedId,
          });
        }
        setLoading(false);
      }
    }
    // eslint-disable-next-line
  }, [newUpload]);

  const tabPanes = headings.map((tab) => (

    <TabPane
      tab={tab.name}
      key={tab.source}
      closable={tab.closable}
    >

      {(props.edit || props.add)
        && <Row style={{ marginBottom: '140px' }}>
          <Upload.Dragger
            name='file'
            fileList={[]}
            multiple={false}
            accept={'.xlsx,.jpg,.jpeg,.pdf,.png,.xls,.csv,.doc'}
            beforeUpload={uploadFile}
            disabled={loading}
          >
            <Icon type='upload' />
            <Title level={4} >Drag or Click to upload</Title>
            *Single File Only
          </Upload.Dragger>
        </Row>
      }
      <Table
        className={'table-striped-rows sticky'}
        loading={loading}
        columns={tableColumns}
        dataSource={tableData.filter((file) => file.category === tab.source)}
      />
    </TabPane>
  ));

  return (
    <>
      <StyledTabs
        type={props.edit ? 'editable-card' : 'card'}
        onChange={(e) => setCurrentTab(e)}
        onEdit={onEdit}
        style={{
          margin: 'auto',
          width: '100%',
        }}
      >
        {tabPanes}
      </StyledTabs>
      <Modal
        title='Please Provide Category Name'
        visible={showModal}
        footer={null}
        onCancel={() => setShowModal(false)}
      >
        <AddTabForm
          hideModal={setShowModal}
          handleOk={handleOk}
          validation={validation}
        />
      </Modal>
    </>
  );
}

/** @component */
export default Tabs;
