import { makeRequestAction } from './index';

export const sendTestEmail = makeRequestAction('POST_TEST_EMAIL', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const getTestEmailDeal = makeRequestAction('GET_TEST_EMAIL_DEAL', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});
