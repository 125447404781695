import { makeRequestAction } from './index';

export const sendLeadEmail = makeRequestAction('SEND_LEAD_EMAIL', {
  onSuccess(params, response) {
    return {
      response,
    };
  },
});

export const sendLeadApprovalEmail = makeRequestAction('SEND_LEAD_APPROVAL_EMAIL', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const checkLeadEmailLimit = makeRequestAction('CHECK_LEAD_EMAIL_LIMIT', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

// export const addLeadEmailUploads = makeRequestAction('ADD_LEAD_EMAIL_UPLOADS', {
//   onSuccess(params, response) {
//     return {
//       files: response.map((file) => ({
//         uid: file.hashedId,
//         name: file.fileName,
//         url: `${API_ROOT}/file-storage/${file.hashedId}`,
//         source: file.source,
//       })),
//     };
//   },
// });
