import client from './main-client';

export const fetchDealsApi = (params) => client().get('/deals', { params });
export const getDealApi = ({ id, ...params }) => client().get(`/deals/${id}`, { params });
export const updateDealApi = (params) => client().put(`/deals/${params.id}`, params);
export const patchDealApi = (params) => client().patch(`/deals/${params.id}`, params);
export const addDealApi = (data) => client().post('/deals', data);
export const deleteDealApi = (params) => client().delete(`/deals/${params.id}`);
export const deleteDealsApi = (params) => client().delete('/deals', { params });

// acion reducer services saga selector
