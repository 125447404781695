import styled from 'styled-components';
import { PageHeader as header } from 'antd';

const PageHeader = styled(header)`

    background: #fff !important; 
    box-shadow: 0 4px 2px -2px rgba(0,0,0,0.05);
    .ant-page-header-heading-title
    {
        color: #4962F5;
    }
    .ant-page-header-back-button
    {
        color: #4962F5;
    }
    .ant-page-header-heading-extra button
    {
        padding: 0;
        font-size: 12px;
        margin-left: 0px !important;
    }
    i.anticon
    {
        color: #1c1c1c; 
    }
    .ant-page-header-heading-extra .ant-avatar
    {
        color: #475DFE;
        margin-left: 10px;
    }
`;

/** @component */
export default PageHeader;
