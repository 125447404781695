import styled from 'styled-components';
import React from 'react';
import { useSelector } from 'react-redux';
import { getLenders } from 'selectors/lenders';
import { Carousel as carousel } from 'antd';
import CarouselCard from './CarouselCard';

const StyledCarousel = styled(carousel)`
  margin: auto;
  width: 100%;
  color: black;
  background-color: white;
  border-radius: 10px;

  div{
    max-height: 460px !important;
  }

  button{
    min-width: 12px;
    min-height: 45px;
    margin: 2px;
    background-color: navy !important;
  }

  ul{
    margin-right: 40px;
  }
`;

function Carousel(props) {
  const lenderValues = useSelector(getLenders);
  const carouselData = lenderValues !== null ? props.data.map((item, index) => (
    <CarouselCard index={index} data={item} lenders={lenderValues.data} />

  )) : '';

  return (
    <StyledCarousel afterChange={props.onChangeCarousel}>
      {carouselData}
    </StyledCarousel>
  );
}

/** @component */
export default Carousel;
