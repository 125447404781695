import { makeRequestAction } from './index';

export const reportsValues = makeRequestAction('REPORTS', {
  onSuccess(params, response) {
    return {
      response: {
        entities: {
          reportsData: response,
        },
      },
    };
  },
});

export const getReport = makeRequestAction('FETCH_REPORT', {
  onSuccess(params, response) {
    return {
      response: {
        entities: {
          getReport: response,
        },
      },
    };
  },
});

export const addReport = makeRequestAction('CREATE_REPORT', {
  onSuccess(params, response) {
    return {
      response: {
        entities: {
          createdReport: response,
        },
      },
    };
  },
});

export const updateReport = makeRequestAction('UPDATE_REPORT', {
  onSuccess(params, response) {
    return {
      response: {
        entities: {
          updateReport: response,
        },
      },
    };
  },
});

export const deleteReport = makeRequestAction('DELETE_REPORT', {
  onSuccess(params, response) {
    return {
      response: {
        entities: {
          deleteReport: response,
        },
      },
    };
  },
});

export const guestReport = makeRequestAction('GUEST_REPORT', {
  onSuccess(params, response) {
    return {
      response: {
        entities: {
          guestReport: response,
        },
      },
    };
  },
});

export const updateGuestReport = makeRequestAction('UPDATE_GUEST_REPORT', {
  onSuccess(params, response) {
    return {
      response: {
        entities: {
          updateGuestReport: response,
        },
      },
    };
  },
});
