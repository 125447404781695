import client from './main-client';

export const addSurveyApi = (data) => client().patch(`guest-service/?service=client-survey&id=${data.surveyId}&guestToken=${data.token}`, data);
export const getSurveyApi = (params) => {
  if (params.id) {
    return client().get(`/client-survey/${params.id}`);
  }

  return client().get('/client-survey/', { params });
};
