import { call, takeLatest } from 'redux-saga/effects';
import {
  sendTestEmail as sendTestEmailAction,
  getTestEmailDeal as getTestEmailDealAction,
} from 'actions/test-email';
import { sendTestEmailApi, getTestEmailDealApi } from 'services/test-email';
import fetchEntity from './fetch-entity';

const sendTestEmail = fetchEntity.bind(null, sendTestEmailAction.actions, sendTestEmailApi);

export function* loadsendTestEmail({ params }) {
  yield call(sendTestEmail, { ...params });
}

function* watchSendTestEmail() {
  yield takeLatest(sendTestEmailAction.actionName, loadsendTestEmail);
}

const getTestDeal = fetchEntity.bind(null, getTestEmailDealAction.actions, getTestEmailDealApi);

export function* loadGetTestEmail({ params }) {
  yield call(getTestDeal, { ...params });
}

function* watchGetTestEmail() {
  yield takeLatest(getTestEmailDealAction.actionName, loadGetTestEmail);
}

export default {
  watchSendTestEmail,
  watchGetTestEmail,
};
