import client from './main-client';

export const getOwnerApi = ({ id, ...params }) => client().get(`/owners/${id}`, { params });
export const getOwnersApi = (params) => client().get('/owners', { params });
export const deleteOwnerApi = ({ id, ...params }) => client().delete(`/owners/${id}`);
export const addOwnerApi = (data) => client().post('/owners', data);
export const updateOwnerApi = (params) => {
  let queryString = '';
  if (params.query) {
    queryString = `?${new URLSearchParams(params.query).toString()}`;
    delete params.query;
  }

  return client().patch(`/owners/${params.id}${queryString}`, params);
};

export const bulkAssignManagersApi = (data) => {
  const queryString = data.ownerIds && Array.isArray(data.ownerIds)
    ? data.ownerIds.map((item) => `_id[$in][]=${item}`).join('&')
    : '';

  delete data.ownerIds;
  return client().patch(`/owners?bulkAssign=true&${queryString}`, data);
};
