/* eslint-disable no-console */
/* eslint-disable no-fallthrough */
/* eslint-disable prefer-destructuring */
/* eslint-disable eqeqeq */
import {
  getColliersLeads,
  getFinneoLeads,
  getPreviousFinneoLeads,
  finneoUploadLeads,
  colliersUploadLeads,
  getFinneoLeadPermission,
  getColliersLeadPermission,
  getColliersMarketLeads,
  deleteUnassignColliersLeads,
  assignColliersLeads,
  updatestageColliersLeads,
  updateLeads,
  getMarketLeadPermission,
  getTemplateDownloadLink,
  downloadLeads,
  getRelatedFinneoLeads,
} from 'actions/fetch-colliersFinneo-leads';

import { Message } from 'antd';

const colliersFinneoLeads = () => {
  const initialState = {
    colliersLeads: null,
    colliersMarketLeads: null,
    finneoLeads: null,
    previousFinneoLeads: null,
    TemplateDownloadLink: null,
    FinneoLeadPermission: null,
    ColliersLeadPermission: null,
    updateLeadsLoading: false,
    successfullyUpdated: false,
    isLoading: false,
    errorWhileUpdate: null,
    relatedFinneoLeads: null,
  };
  return (state = initialState, { type, response }) => {
    // console.log('>>>>> REDUCER',response)
    switch (type) {
      case getColliersLeads.requestTypes.SUCCESS:
        return {
          ...state,
          colliersLeads: response,
          isSuccessful: true,
        };
      case getColliersLeads.requestTypes.REQUEST:
        return {
          ...state,
          // colliersLeads: null,
          isSuccessful: false,
        };
      case getColliersMarketLeads.requestTypes.SUCCESS:
        return {
          ...state,
          colliersMarketLeads: response,
          isSuccessful: true,
        };
      case getColliersMarketLeads.requestTypes.REQUEST:
        return {
          ...state,
          // colliersMarketLeads: null,
          isSuccessful: false,
        };
      case getFinneoLeads.requestTypes.SUCCESS:
        return {
          ...state,
          finneoLeads: response,
          isSuccessful: true,
        };
      case getFinneoLeads.requestTypes.REQUEST:
        return {
          ...state,
          // finneoLeads: null,
          isSuccessful: false,
        };
      case getRelatedFinneoLeads.requestTypes.SUCCESS:
        return {
          ...state,
          relatedFinneoLeads: response,
          isSuccessful: true,
        };
      case getRelatedFinneoLeads.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case getPreviousFinneoLeads.requestTypes.SUCCESS:
        return {
          ...state,
          previousFinneoLeads: response,
          isSuccessful: true,
        };
      case getPreviousFinneoLeads.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case finneoUploadLeads.requestTypes.REQUEST:
        return {
          ...state,
          upload: false,
        };
      case finneoUploadLeads.requestTypes.SUCCESS:
        Message.success(response.message);
        return {
          ...state,
          upload: true,
        };
      case finneoUploadLeads.requestTypes.FAILURE:
        Message.error('Finneo leads could not be uploaded!');
        return {
          ...state,
          upload: false,
        };
      case colliersUploadLeads.requestTypes.REQUEST:
        return {
          ...state,
          upload: false,
        };
      case colliersUploadLeads.requestTypes.SUCCESS:
        Message.success(response.message);
        return {
          ...state,
          upload: true,
        };
      case colliersUploadLeads.requestTypes.FAILURE:
        Message.error('Colliers leads could not be uploaded!');
        return {
          ...state,
          upload: false,
        };
      case getTemplateDownloadLink.requestTypes.REQUEST:
        return {
          ...state,
          TemplateDownloadLink: null,
        };
      case getTemplateDownloadLink.requestTypes.SUCCESS:
        return {
          ...state,
          TemplateDownloadLink: response,
        };
      case getFinneoLeadPermission.requestTypes.SUCCESS:
        return {
          ...state,
          FinneoLeadPermission: response,
        };
      case getColliersLeadPermission.requestTypes.SUCCESS:
        return {
          ...state,
          ColliersLeadPermission: response,
        };
      case getFinneoLeadPermission.requestTypes.REQUEST:
        return {
          ...state,
          FinneoLeadPermission: null,
        };
      case getColliersLeadPermission.requestTypes.REQUEST:
        return {
          ...state,
          ColliersLeadPermission: null,
        };
      case getMarketLeadPermission.requestTypes.SUCCESS:
        return {
          ...state,
          MarketLeadPermission: response,
        };
      case getMarketLeadPermission.requestTypes.REQUEST:
        return {
          ...state,
          MarketLeadPermission: null,
        };
      case deleteUnassignColliersLeads.requestTypes.REQUEST:
        return {
          ...state,
          isLoading: true,
          isSuccessful: true,
        };
      case deleteUnassignColliersLeads.requestTypes.SUCCESS:
        Message.success(response.message);
        return {
          ...state,
          isLoading: false,
          isSuccessful: false,
        };
      case assignColliersLeads.requestTypes.REQUEST:
        return {
          ...state,
          isLoading: true,
          isSuccessful: false,
        };
      case assignColliersLeads.requestTypes.SUCCESS:
        return {
          ...state,
          isLoading: false,
          isSuccessful: true,
        };
      case updatestageColliersLeads.requestTypes.REQUEST:
        return {
          ...state,
          isLoading: true,
          isSuccessful: false,
        };
      case updatestageColliersLeads.requestTypes.SUCCESS:
        Message.success(response.message);
        return {
          ...state,
          isLoading: false,
          isSuccessful: true,
        };

      case updateLeads.requestTypes.REQUEST:
        return {
          ...state,
          updateLeadsLoading: true,
          successfullyUpdated: false,
        };
      case updateLeads.requestTypes.SUCCESS:
        Message.success(response.message);
        return {
          ...state,
          updateLeadsLoading: false,
          errorWhileUpdate: false,
          successfullyUpdated: true,
        };
      case updateLeads.requestTypes.FAILURE:
        Message.error('Error while updating the lead, please retry');
        return {
          ...state,
          errorUpdating: true,
          updateLeadsLoading: false,
          successfullyUpdated: false,
        };

      case downloadLeads.requestTypes.SUCCESS:
        if (response.status) {
          Message.error('No data to be downloaded');
          return {
            ...state,
          };
        }

        try {
          const contentType = 'text/csv; charset=utf-8';
          const blob = new Blob([response.file], { type: contentType });
          // downloading the file depends on the browser
          // IE handles it differently than chrome/webkit
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, response.fileName);
          } else {
            const downloadLink = document.createElement('a');
            const url = URL.createObjectURL(blob);
            downloadLink.href = url;
            downloadLink.download = response.fileName;

            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          }
          return true;
        } catch (exc) {
          console.log('Save Blob method failed with the following exception.');
          console.log(exc);
        }
      default:
        return state;
    }
  };
};

export default colliersFinneoLeads();
