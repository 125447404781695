import styled from 'styled-components';
import propTypes from 'prop-types';
import { Layout } from 'antd';

const Content = styled(Layout.Content)`
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 100px;
  min-height: auto !important;
`;

Content.propTypes = {
  background: propTypes.string,
};

Content.defaultProps = {
  background: '#fff',
};

export default Content;
