import {
  openContactsModal,
} from 'actions/contacts-modal';

const contactsModal = () => {
  const initialState = {
    name: '',
  };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case openContactsModal.actionName:
        return {
          name: '123',
        };
      default:
        return state;
    }
  };
};

export default contactsModal();
