import client from './main-client';
import ratesClient from './rates-client';

// Construction Property Calls
export const addPropertyApi = (data) => client().post('/construction-property-details', data);
export const getConstructionPropertiesApi = (params) => client().get('/construction-property-details?getAll=true');
export const updateConstructionPropertyApi = (data) => client().put(`/construction-property-details/${data._id}`, data);
export const removeConstructionPropertyApi = (data) => client().delete(`construction-property-details/${data._id}`);

// Project Metric Calls
export const addProjectMetricsApi = (data) => client().post('/construction-project-metrics', data);
export const getProjectMetricsApi = ({ userId }) => client().get(`/construction-project-metrics?userId=${userId}&getAll=true`);
export const updateProjectMetricsApi = (data) => client().put(`/construction-project-metrics/${data._id}`, data);
export const removeProjectMetricsApi = (data) => client().delete(`construction-project-metrics/${data._id}`);

// Construction Loan Calls
export const addConstructionLoanApi = (data) => client().post('/construction-loan', data);
export const removeConstructionLoanApi = (data) => client().delete(`/construction-loan/${data._id}`);
export const getConstructionLoanApi = (params) => client().get(`/construction-loan?userId=${params.userId}&active=true&getAll=true`);
export const updateConstructionLoanApi = (data) => client().put(`construction-loan/${data._id}`, data);

// Rate Call for Construction Loan Form
export const getIndexRatesApi = (params) => ratesClient().get('/rates');

// Revenue Details Calls
export const addConstructionRevenueApi = (data) => client().post('/construction-property-revenue', data);
export const getConstructionRevenueApi = (data) => client().get('/construction-property-revenue?getAll=true');
export const updateConstructionRevenueApi = (data) => client().put(`construction-property-revenue/${data._id}`, data);
export const removeConstructionRevenueApi = (data) => client().delete(`construction-property-revenue/${data._id}`);
