import React from 'react';
import {
  BrowserRouter,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { userIsAuth } from 'selectors/users';
import { Content } from 'components/Partials';
import Routes from './Routes';
import DashboardWrapper from './DashboardWrapper';

const AppRouter = (props) => {
  const isAuth = useSelector(userIsAuth);
  return <BrowserRouter>
    {isAuth
      ? <DashboardWrapper isAuth={isAuth}><Routes {...props} /></DashboardWrapper>
      : <Content>
        <Routes {...props} />
      </Content>}
  </BrowserRouter>;
};

export default AppRouter;
