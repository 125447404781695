/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from 'react';
import { Title } from 'components';
import { Row, Col, Tabs } from 'antd';
import Moment from 'react-moment';
import {
  Marker,
  GoogleMap,
  InfoWindow,
  useLoadScript,
} from '@react-google-maps/api';
import Geocode from 'react-geocode';
import { GOOGLE_MAPS_KEY } from 'configs/env-vars';
import { formatter } from 'utils/numberInputFormatter';
import './css/styles.css';

const { TabPane } = Tabs;
const apiKey = GOOGLE_MAPS_KEY;
Geocode.setApiKey(apiKey);

function AddressMap(props) {
  const [places, setPlaces] = useState([]);
  const [loansAttachedToSelectedProperty, setLoansAttachedToSelectedProperty] = useState([]);
  const [propertyDetail, setPropertyDetail] = useState();
  const [mapRef, setMapRef] = useState(null);
  const [markerMap, setMarkerMap] = useState({});
  const [infoOpen, setInfoOpen] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [uniqueId, setUniqueId] = useState('');
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: apiKey,
  });

  useEffect(() => {
    if (props.type === 'colliers') {
      setUniqueId('id');
    } else {
      setUniqueId('_id');
    }
  }, [props.type]);

  useEffect(() => {
    // eslint-disable-next-line no-shadow
    const fetchPlacesData = async (places) => {
      const result = await getAddresses(places);
      setPlaces(result);
    };
    fetchPlacesData(props.places);
    // eslint-disable-next-line
  }, [props.places]);

  useEffect(() => {
    if (mapRef) {
      const bounds = new window.google.maps.LatLngBounds();
      places.map((place) => {
        bounds.extend(place.coordinates);
        return place[uniqueId];
      });
      mapRef.fitBounds(bounds);
    }
  }, [mapRef, uniqueId, places]);

  const mapLoadHandler = useCallback((map) => setMapRef(map), []);

  const markerLoadHandler = (marker, place) => (
    setMarkerMap((prevState) => ({ ...prevState, [place[uniqueId]]: marker }))
  );

  const markerClickHandler = (event, place) => {
    setSelectedPlace(place);
    if (props.type === 'colliers') {
      setPropertyDetail(props.leads.filter((item) => item.propertyAddress === place.propertyAddress));
    } else {
      setLoansAttachedToSelectedProperty(props.leads.filter((loan) => loan.address === place.address));
    }

    if (infoOpen) {
      setInfoOpen(false);
    }
    setInfoOpen(true);
  };

  /**
   * Returns an array of JSONs containing the given location information
   * and the coordinates for each one of them.
   * @param {{Title: String, Name: String, Address: String}[]} places
   */
  // eslint-disable-next-line no-shadow
  const getAddresses = async (places) => {
    /**
     * Returns a promise that would return a JSON with the given location
     * information, plus its coordinates.
     * @param {{Title: String, Name: String, Address: String}} place
     */
    const GeoPromise = (place) => new Promise((resolve, reject) => {
      props.type === 'colliers'

        ? Geocode.fromAddress(place.propertyAddress).then(
          (response) => {
            const { lat, lng } = response.results[0].geometry.location;
            resolve({ ...place, coordinates: { lat, lng } });
          },
          (error) => {
            resolve(null); // Even if we fail, we still want to display the rest of the locations
          },
        )
        : Geocode.fromAddress(place.address).then(
          (response) => {
            const { lat, lng } = response.results[0].geometry.location;
            resolve({ ...place, coordinates: { lat, lng } });
          },
          (error) => {
            resolve(null); // Even if we fail, we still want to display the rest of the locations
          },
        );
    });

    const result = await Promise.all(
      places.map((place) => GeoPromise(place)),
    ).then((res) => res.filter((place) => place)); // Remove null values
    return result;
  };

  function leadsTemplate(lead) {
    return (
      <Row key={lead._id}>
        <Row>
          <Col span={24} style={{ marginBottom: 20 }}>
            <div onClick={() => props.openDetailModal(lead.address.slice(0, lead.address.lastIndexOf(',')), lead._id)}>
              <Title level={4} color={'blue'} style={{ cursor: 'pointer' }}>{lead.address}</Title>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={12} >Property Type</Col>
          <Col span={12} >{lead.propertyType}</Col>
        </Row>
        <Row>
          <Col span={12} >Principal Amount</Col>
          <Col span={12} >{formatter(lead.principalamount, { prefix: '$', moneyFormat: true })}</Col>
        </Row>
        <Row>
          <Col span={12} >Maturity Date</Col>
          <Col span={12} >{lead.maturity ? <Moment local format='MMM DD, YYYY'>{lead.maturity}</Moment> : '-' }</Col>
        </Row>
        <Row>
          <Col span={12} >Borrower</Col>
          <Col span={12} >{lead.owner}</Col>
        </Row>
        <Row style={{ marginBottom: '15px' }}>
          <Col span={12} >Mortgage</Col>
          <Col span={12} >{lead.mortgage}</Col>
        </Row>
      </Row>
    );
  }

  function listingLeadsTemplate(lead) {
    return (
      <Row key={lead.id}>
        <Row>
          <Col span={24} style={{ marginBottom: 20 }}>
            <div onClick={() => props.openDetailModal(null, lead.id, propertyDetail[0])}>
              <Title level={4} color={'blue'} style={{ cursor: 'pointer' }}>{lead.propertyAddress}</Title>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={12} style={{ marginBottom: '15px' }} >Asset Type</Col>
          <Col span={12} >{lead.assetType}</Col>
        </Row>
        <Row>
          <Col span={12} style={{ marginBottom: '15px' }} >Building Size</Col>
          <Col span={12} >{lead.buildingSize ? lead.buildingSize : '-'}</Col>
        </Row>
        <Row>
          <Col span={12} style={{ marginBottom: '15px' }} >Land Size</Col>
          <Col span={12} >{lead.landSize ? lead.landSize : '-'}</Col>
        </Row>
        <Row>
          <Col span={12}style={{ marginBottom: '15px' }} >Listed Amount</Col>
          <Col span={12} >{lead.listingAmount ? lead.listingAmount : '-'}</Col>
        </Row>
        {/* <Row>
          <Col span={12} >Principal Amount</Col>
          <Col span={12} >{formatter(lead.principalamount, { prefix: '$', moneyFormat: true })}</Col>
        </Row>
        <Row>
          <Col span={12} >Maturity Date</Col>
          <Col span={12} >{lead.maturity ? <Moment local format='MMM DD, YYYY'>{lead.maturity}</Moment> : '-' }</Col>
        </Row>
        <Row>
          <Col span={12} >Borrower</Col>
          <Col span={12} >{lead.borrower}</Col>
        </Row>
        <Row style={{ marginBottom: '15px' }}>
          <Col span={12} >Mortgage</Col>
          <Col span={12} >{lead.mortgage}</Col>
        </Row> */}
      </Row>
    );
  }

  function selectedPropertyInfo() {
    return (
      <Row>
        <div>
          {loansAttachedToSelectedProperty && loansAttachedToSelectedProperty.length > 1
            ? <Tabs
              tabPosition={'right'}
              tabBarStyle={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}>
              {loansAttachedToSelectedProperty && loansAttachedToSelectedProperty.length
                ? loansAttachedToSelectedProperty.map((lead, i) => (
                  <TabPane tab={`Mortgage ${i + 1}`} key={`${i + 1} ${lead.address}`}>
                    {leadsTemplate(lead)}
                  </TabPane>
                ))
                : <></>
              }
            </Tabs>
            : loansAttachedToSelectedProperty && loansAttachedToSelectedProperty.length
              ? loansAttachedToSelectedProperty.map((lead, i) => (
                <>{leadsTemplate(lead)}</>
              ))
              : <>
                {propertyDetail && propertyDetail.length > 0
                && propertyDetail.map((lead, i) => (
                  <>{listingLeadsTemplate(lead)}</>
                ))
              }
              </>
          }
        </div>
      </Row>
    );
  }

  function selectedListingPropertyInfo() {
    return (
      <Row>
        <div>
          {propertyDetail && propertyDetail.length > 0
            ? propertyDetail.map((lead, i) => (
              listingLeadsTemplate(lead)
            ))
            : <></>
              }
        </div>
      </Row>
    );
  }

  return (
    isLoaded
      ? <GoogleMap
        id='maps'
        onLoad={mapLoadHandler}
        mapContainerStyle={props.style}
        zoom={props.zoom || 10}
      >
        {places.map((place, i) => (
          <Marker
            key={place[uniqueId]}
            icon={{
              url: 'https://upload.wikimedia.org/wikipedia/commons/0/02/Red_Circle%28small%29.svg',
              scaledSize: { width: 8, height: 8 },
            }}
            position={place.coordinates}
            onLoad={(marker) => markerLoadHandler(marker, place)}
            onClick={(event) => markerClickHandler(event, place)}
            style={{ width: '20px', height: '20px', backgroundColor: 'red' }}
          />
        ))}
        {infoOpen && selectedPlace && (
          <InfoWindow
            anchor={markerMap[selectedPlace[uniqueId]]}
            onCloseClick={() => setInfoOpen(false)}
            options={{ minWidth: 300 }}
          >
            {props.type === 'colliers' ? selectedListingPropertyInfo() : selectedPropertyInfo()}
          </InfoWindow>
        )}
      </GoogleMap>
      : null
  );
}

export default React.memo(AddressMap);
