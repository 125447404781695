import { getAllUserCategories, addCategory, removeCategory } from 'actions/documents-categories';

const documentCategories = () => {
  const initialState = {
    categories: [],
    isSuccessful: false,
  };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case getAllUserCategories.requestTypes.SUCCESS:
        return {
          ...state,
          categories: response,
        };
      // case addDocument.requestTypes.REQUEST: {
      //   return {
      //     documetsData: response,
      //   };
      // }
      case addCategory.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case addCategory.requestTypes.SUCCESS:
        return {
          ...state,
          categories: response,
          isSuccessful: true,
        };
      case removeCategory.requestTypes.SUCCESS:
        return {
          ...state,
          isSuccessful: true,
          categories: response,
        };
      case removeCategory.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      default:
        return state;
    }
  };
};

export default documentCategories();
