import { makeRequestAction } from './index';

export const blanketsValues = makeRequestAction('BLANKETS', {
  onSuccess(params, response) {
    return {
      response: {
        entities: {
          blanketsData: response,
        },
      },
    };
  },
});

export const addBlanketValues = makeRequestAction('ADD_BLANKET', {
  onSuccess(params, response) {
    return {
      response: {
        entities: {
          blanketsData: response,
        },
      },
    };
  },
});

export const updateBlanketValues = makeRequestAction('UPDATE_BLANKET', {
  onSuccess(params, response) {
    return {
      response: {
        entities: {
          blanketsData: response,
        },
      },
    };
  },
});
