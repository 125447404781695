import styled from 'styled-components';
import { Input } from 'antd';

const Password = styled(Input.Password)`
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  border-radius: 4px !important;
  background-color: #ffffff !important;
  height: 40px !important;
`;

/** @component */
export default Password;
