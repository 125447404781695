import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDidUpdateEffect } from 'hooks';
import { Icon, Upload as upload, Message } from 'antd';
import styled from 'styled-components';
import {
  getUserCompanies as fetchUserCompanies,
  updateUserCompany,
  addUserCompanyLogo,
  deleteUserCompanyLogo,
} from 'actions/user-companies';
import {
  getUserCompanies,
  getUserCompanyLogo,
  userCompaniesLoading,
  updateUserCompanyLoading,
  addUserCompanyLogoLoading,
  deleteUserCompanyLogoLoading,
} from 'selectors/user-companies';

const Upload = styled(upload)`
.ant-upload-select {
  width: 240px !important;
  height: 60px !important
  margin: 5px !important;
  background-color: inherit !important;

  .ant-upload-text {
    color: #f1f1f1 !important;
  }
}
`;

const Logo = styled.div`
  background-position: center !important;
  background-repeat: no-repeat;
  background-size: contain;
  width: 240px !important;
  height: 60px !important;
`;

const LogoDeleteIcon = styled(Icon)`
  font-size: 10px;
  color: #fff !important;
  position: absolute;
  right: 5px;
  top: 5px;
`;

const UserCompnayLogo = () => {
  const [isLogoHovered, setLogoHovered] = useState(false);
  const dispatch = useDispatch();
  const userCompany = useSelector(getUserCompanies).data[0] || {};
  const logo = useSelector(getUserCompanyLogo);
  const isGetUserCompanyLoading = useSelector(userCompaniesLoading);
  const isUpdateUserCompanyLoading = useSelector(updateUserCompanyLoading);
  const isAddUserCompanyLogoLoading = useSelector(addUserCompanyLogoLoading);
  const isDeleteUserCompanyLogoLoading = useSelector(
    deleteUserCompanyLogoLoading,
  );

  const logoLoading = isUpdateUserCompanyLoading
    || isAddUserCompanyLogoLoading
    || isGetUserCompanyLoading
    || isDeleteUserCompanyLogoLoading;

  useEffect(() => {
    dispatch(fetchUserCompanies.request());
  }, [dispatch]);

  useDidUpdateEffect(() => {
    const logoUrl = logo && logo[0] && logo[0].url;
    dispatch(
      updateUserCompany.request({
        id: userCompany.id,
        values: { ...userCompany, logo: logoUrl },
      }),
    );
  }, [JSON.stringify(logo)]);

  const addLogo = useCallback(
    (file) => {
      if (!file.type.includes('image')) {
        Message.error('Logo must be an image!');
      } else {
        dispatch(
          addUserCompanyLogo.request({ file: [file], source: 'user-companies' }),
        );
      }
      return false;
    },
    [dispatch],
  );

  const deleteLogo = useCallback(() => {
    dispatch(
      deleteUserCompanyLogo.request({
        id: userCompany.logo.split('/').slice(-1)[0],
      }),
    );
  }, [dispatch, userCompany.logo]);

  return (
    <Upload
      disabled={logoLoading}
      showUploadList={false}
      listType={(!userCompany.logo || logoLoading) && 'picture-card'}
      beforeUpload={addLogo}
    >
      {// eslint-disable-next-line no-nested-ternary
      logoLoading ? (
        <Icon type='loading' className='ant-upload-text' />
      ) : userCompany.logo ? (
        <div
          onMouseEnter={() => setLogoHovered(true)}
          onMouseLeave={() => setLogoHovered(false)}
        >
          <Logo
            style={{ backgroundImage: `url(${userCompany.logo})` }}
            alt='logo'
          />
          {isLogoHovered && (
            <LogoDeleteIcon
              onClick={(e) => {
                e.stopPropagation();
                deleteLogo();
              }}
              type='close'
            />
          )}
        </div>
      ) : (
        <div className='ant-upload-text'> Upload Your Logo </div>
      )}
    </Upload>
  );
};

export default UserCompnayLogo;
