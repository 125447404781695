import { getUserSettings, updateUserSettings } from 'actions/user-settings';

const userSettings = () => {
  const initialState = {
    userSettings: null,
  };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case getUserSettings.requestTypes.SUCCESS:
        return {
          ...state,
          userSettings: response,
        };
      case updateUserSettings.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateUserSettings.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateUserSettings.requestTypes.SUCCESS:
        return {
          ...state,
          isSuccessful: true,
        };
      default:
        return state;
    }
  };
};

export default userSettings();
