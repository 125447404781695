export const roles = {
  ROLE_SUPER_ADMIN: 'Super Admin',
  ROLE_CAPSTACK: 'Capstack',
  ROLE_SELF_SERVE: 'Self Serve',
  ROLE_FULL_SERVER: 'Full Server',
  ROLE_FS_UNDERWRITER: 'FS/Underwriter',
  ROLE_FS_AGENT: 'FS/Agent',
  ROLE_TRANSACTION_ONLY: 'Trasaction Only',
  ROLE_TO_UNDERWRITER: 'TO/Underwriter',
  ROLE_TO_AGENT: 'TO/Agent',
  ROLE_DASHBOARD_ONLY: 'Dashboard Only',
};

export const defaultLoginPath = {
  [roles.ROLE_TO_UNDERWRITER]: '/manager-dashboard',
  [roles.ROLE_TRANSACTION_ONLY]: '/admin-dashboard',
  [roles.ROLE_SELF_SERVE]: '/deals/draft',
  [roles.ROLE_TO_AGENT]: '/originator-dashboard',
  [roles.ROLE_CAPSTACK]: '/',
  [roles.ROLE_FULL_SERVER]: '/',
  [roles.ROLE_FS_UNDERWRITER]: '/',
  [roles.ROLE_FS_AGENT]: '/',
  [roles.ROLE_DASHBOARD_ONLY]: '/',
};

export const routePermissions = {
  [roles.ROLE_SELF_SERVE]: [
    // please recheck this role
    '/documents',
    '/bonds-rates',
    '/calculators',
    '/service/:type',
    '/service/request-new/:type/:id?',
    '/service/request-new',
    '/deals/draft',
    '/deals/in-progress',
    '/deals/archived',
    '/deal/track/lender-bids',
    '/deal-bid',
    '/deal/:id',
    '/deal-bid/:id',
    '/deal-consultant-bid/:id',
    '/deal/track/lender-bids/:id',
    '/deal/preview/:id/:bidauth?',
    '/profile',
  ],
  [roles.ROLE_CAPSTACK]: [
    '/',
    '/documents',
    '/bonds-rates',
    '/calculators',
    '/service/:type',
    '/service/request-new/:type/:id?',
    '/service/request-new',
    '/deals/draft',
    '/deals/in-progress',
    '/deals/archived',
    '/deal/track/lender-bids',
    '/deal-bid',
    '/deal/:id',
    '/deals/email',
    '/deal-bid/:id',
    '/deal-consultant-bid/:id',
    '/deal/track/lender-bids/:id',
    '/deal/preview/:id/:bidauth?',
    '/deals/pending',
    '/profile',
    '/manage-properties',
    '/add-loan',
    '/view-loan/:id',
    '/edit-loan/:id',
    '/add-line',
    '/edit-line/:id',
    '/view-line/:id',
    '/add-blanket',
    '/edit-blanket/:id',
    '/view-blanket/:id',
    '/users',
    '/lenders/list/:country/:dealId?',
    '/lenders/view/:id',
    '/consultants/:dealId?',
    '/consultants/view/:id',
    '/inbox',
    '/inbox/:id',
    '/add-loan/:id/:address/:lender/:loanAmount/:term/:amortization/:interestRate/:ltv',
  ],
  [roles.ROLE_FULL_SERVER]: [
    '/',
    '/documents',
    '/bonds-rates',
    '/calculators',
    '/service/:type',
    '/service/request-new/:type/:id?',
    '/service/request-new',
    '/deals/draft',
    '/deals/in-progress',
    '/deals/archived',
    '/deal/track/lender-bids',
    '/deal-bid',
    '/deal/:id',
    '/deals/email',
    '/deal-bid/:id',
    '/deal-consultant-bid/:id',
    '/deal/track/lender-bids/:id',
    '/deal/preview/:id/:bidauth?',
    '/deals/pending',
    '/profile',
    '/manage-properties',
    '/add-loan',
    '/view-loan/:id',
    '/edit-loan/:id',
    '/add-line',
    '/edit-line/:id',
    '/view-line/:id',
    '/add-blanket',
    '/edit-blanket/:id',
    '/view-blanket/:id',
    '/users',
    '/lenders/list/:country/:dealId?',
    '/lenders/view/:id',
    '/consultants/:dealId?',
    '/consultants/view/:id',
    '/inbox',
    '/inbox/:id',
    '/add-loan/:id/:address/:lender/:loanAmount/:term/:amortization/:interestRate/:ltv',

  ],
  [roles.ROLE_FS_UNDERWRITER]: [
    '/',
    '/documents',
    '/bonds-rates',
    '/calculators',
    '/service/:type',
    '/service/request-new/:type/:id?',
    '/service/request-new',
    '/deals/draft',
    '/deals/in-progress',
    '/deals/archived',
    '/deal/track/lender-bids',
    '/deal-bid',
    '/deal/:id',
    '/deals/email',
    '/deal-bid/:id',
    '/deal-consultant-bid/:id',
    '/deal/track/lender-bids/:id',
    '/deal/preview/:id/:bidauth?',
    '/deals/pending',
    '/manage-properties',
    '/add-loan',
    '/add-loan/:id/:address/:lender/:loanAmount/:term/:amortization/:interestRate/:ltv',
    '/view-loan/:id',
    '/edit-loan/:id',
    '/add-line',
    '/edit-line/:id',
    '/view-line/:id',
    '/add-blanket',
    '/edit-blanket/:id',
    '/view-blanket/:id',
    '/profile',
    '/users',
    '/lenders/list/:country/:dealId?',
    '/lenders/view/:id',
    '/consultants/:dealId?',
    '/consultants/view/:id',
    '/inbox',
    '/inbox/:id',
  ],
  [roles.ROLE_FS_AGENT]: [
    '/',
    '/bonds-rates',
    '/documents',
    '/calculators',
    '/service/:type',
    '/service/request-new/:type/:id?',
    '/service/request-new',
    '/deals/draft',
    '/deals/in-progress',
    '/deals/archived',
    '/deal/track/lender-bids',
    '/deal-bid',
    '/deal/:id',
    '/deal-bid/:id',
    '/deal-consultant-bid/:id',
    '/deal/track/lender-bids/:id',
    '/deal/preview/:id/:bidauth?',
    '/manage-properties',
    '/add-loan',
    '/add-loan/:id/:address/:lender/:loanAmount/:term/:amortization/:interestRate/:ltv',
    '/add-line',
    '/edit-line/:id',
    '/view-loan/:id',
    '/edit-loan/:id',
    '/view-line/:id',
    '/add-blanket',
    '/edit-blanket/:id',
    '/view-blanket/:id',
    '/profile',
  ],
  [roles.ROLE_TRANSACTION_ONLY]: [
    '/bonds-rates',
    '/documents',
    '/calculators',
    '/service/:type',
    '/service/request-new/:type/:id?',
    '/service/request-new',
    '/consultants/:dealId?',
    '/consultants/view/:id',
    '/deals/draft',
    '/deals/in-progress',
    '/deals/archived',
    '/deal/track/lender-bids',
    '/deal-bid',
    '/deal/:id',
    '/deals/email',
    '/deal-bid/:id',
    '/deal-consultant-bid/:id',
    '/deal/track/lender-bids/:id',
    '/deal/preview/:id/:bidauth?',
    '/deals/pending',
    '/profile',
    '/users',
    '/lenders/list/:country/:dealId?',
    '/lenders/view/:id',
    '/lenders/edit/:id',
    '/inbox',
    '/inbox/:id',
    '/admin-dashboard',
    '/crm-dashboard',
    '/lead-assignment',
  ],
  [roles.ROLE_TO_UNDERWRITER]: [
    '/manager-dashboard',
    '/crm-dashboard',
    '/bonds-rates',
    '/calculators',
    '/documents',
    '/service/:type',
    '/service/request-new/:type/:id?',
    '/consultants/:dealId?',
    '/consultants/view/:id',
    '/deals/draft',
    '/deals/in-progress',
    '/deals/archived',
    '/deal/track/lender-bids/:id',
    '/deal/:id',
    '/deal/preview/:id/:bidauth?',
    '/deals/pending',
    '/profile',
    '/users',
    '/lenders/list/:country/:dealId?',
    '/lenders/view/:id',
    '/lenders/edit/:id',
    '/deals/email',
    '/deal-bid/:id',
    '/deal-consultant-bid/:id',
    '/inbox',
    '/inbox/:id',
  ],
  [roles.ROLE_TO_AGENT]: [
    '/originator-dashboard',
    '/bonds-rates',
    '/documents',
    '/calculators',
    '/service/:type',
    '/service/request-new/:type/:id?',
    '/deals/draft',
    '/deals/in-progress',
    '/deals/archived',
    '/deal/track/lender-bids/:id',
    '/deal/:id',
    '/deal/preview/:id/:bidauth?',
    '/profile',
    '/deal-bid/:id',
    '/deal-consultant-bid/:id',
    '/consultants/:dealId?',
    '/consultants/view/:id',
    '/lenders/list/:country/:dealId?',
    '/lenders/view/:id',
  ],
  [roles.ROLE_DASHBOARD_ONLY]: [
    '/',
    '/documents',
    '/bonds-rates',
    '/calculators',
    '/service/:type',
    '/service/request-new/:type/:id?',
    '/manage-properties',
    '/add-loan',
    '/add-loan/:id/:address/:lender/:loanAmount/:term/:amortization/:interestRate/:ltv',
    '/view-loan/:id',
    '/edit-loan/:id',
    '/add-line',
    '/edit-line/:id',
    '/view-line/:id',
    '/add-blanket',
    '/edit-blanket/:id',
    '/view-blanket/:id',
    '/profile',
  ],
};
