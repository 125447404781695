/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import {
  Row, Col, Select, message, DatePicker,
} from 'antd';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { listings, updateListing } from 'actions/listingLeads';
import { updatestageColliersLeads, updateLeads } from 'actions/fetch-colliersFinneo-leads';
import * as DateFormatType from 'utils/moments/date-formats';
import PitchDateTime from 'containers/Colliers/partials/Leads/PitchDateTime';

const HeadingWrapper = styled.span`
    font-weight: 500;
    color: #2a2a2a;
    ${(props) => props.children === 'Building Size' && css`
    color: #2a2a2a;
    `}
    ${(props) => props.children === 'Price Per Sqft' && css`
    color: #2a2a2a;
    `}
    ${(props) => props.children === 'PIN Number' && css`
    color: #2a2a2a;
    `}
    ${(props) => props.children === 'Lot Area' && css`
    color: #2a2a2a;
    `}
    ${(props) => props.children === 'Lot Details' && css`
    color: #2a2a2a;
    `}
`;
const ValueWrapper = styled.span`
    font-weight: 500;
    color: #727070;
`;
const { Option } = Select;

const ModalDetailsRow = ({
  heading, value, type, lead, leadType, setStageValuesToEdit, setActionDateToEdit,
  setEditedLeadMonth,
}) => {
  const [dateTimeRecord, setDateTimeRecord] = useState(null);
  const dispatch = useDispatch();

  function handleStatusChange(val, leadObj) {
    type === 'select' ? dispatch(updateListing.request({ ...leadObj, stage: val }))
      : dispatch(updateListing.request({ ...leadObj, actionDate: val }));

    message.loading('Updating Lead...', [2], () => dispatch(listings.request({ isActive: true })));
  }

  const settingMonthOfLead = () => {
    setEditedLeadMonth(
      lead?.actionDate
        ? DateFormatType.dateFormatHandler(lead?.actionDate, DateFormatType.MMM)
        : DateFormatType.dateFormatHandler(lead?.maturity, DateFormatType.MMM),
    );
  };
  const updateLeadStage = (val, id, ownerId, rec, pitchTime) => {
    dispatch(
      updatestageColliersLeads.request({
        leadIds: [id],
        ownerId,
        stage: val,
        leadType,
        pitchTime,
        isSetStatus: true,
      }),
    );
    setEditedLeadMonth(
      lead?.actionDate
        ? DateFormatType.dateFormatHandler(lead?.actionDate, DateFormatType.MMM)
        : DateFormatType.dateFormatHandler(lead?.maturity, DateFormatType.MMM),
    );
    setStageValuesToEdit({ id, stage: val });
  };
  const today = new Date();

  const fontColour = lead && lead?.actionDate !== null && new Date(lead.actionDate) < today ? 'pastDue' : 'due';
  return (

    <Row className='details-row'>
      <Col span={8}>
        <HeadingWrapper>
          {heading}
        </HeadingWrapper>
      </Col>
      <Col span={16}>
        <ValueWrapper>
          {!value
            ? '-'
            : type === 'phone'
              ? <a href={`tel:${value.replace('x', ',')}`}>{value}</a>
              : type === 'email'
                ? <a href={`mailto:${value}`}>{value}</a>
                : type === 'select'
                  ? <Select
                    value={value}
                    onChange={(val) => (
                      leadType === 'internal'
                        ? (val === 'pitch sch.' ? setDateTimeRecord({
                          type: 'listings',
                          record: lead,
                        })
                          : handleStatusChange(val, lead)
                        )
                        : (val === 'pitch sch.' ? setDateTimeRecord({
                          type: 'expiries',
                          record: lead,
                        })
                          : updateLeadStage(val, lead._id, lead.ownerId)))
                    }
                    className={`select-before stage-${lead?.stage}`}
                    style={{ minWidth: '94px' }}
                  >
                    <Option value='outreach' style={{ color: 'blue' }}>
                      Outreach
                    </Option>
                    <Option value='targeted' style={{ color: '#d37ce9' }}>
                      Targeted
                    </Option>
                    <Option value='engaged' style={{ color: '#ff8601' }}>
                      Engaged
                    </Option>
                    <Option value='pitch sch.' style={{ color: 'orange' }}>
                      Pitch Sch.
                    </Option>
                    <Option value='pitched' style={{ color: 'green' }}>
                      Pitched
                    </Option>
                    <Option value='mandated' style={{ color: 'rebeccapurple' }}>
                      Mandated
                    </Option>
                    <Option value='pass' style={{ color: 'grey' }}>
                      Pass
                    </Option>
                    <Option value='data' style={{ color: 'red' }}>
                      Data
                    </Option>
                    <Option value='-'>{'  -  '}</Option>
                  </Select>
                  : type === 'actionDate'
                    ? <DatePicker
                      className={fontColour}
                      style={{ width: '125px' }}
                      size={'medium'}
                      onChange={(val) => (leadType === 'refinance' ? (dispatch(
                        updateLeads.request({
                          _id: lead._id, values: { actionDate: val }, ownerId: lead.ownerId, leadType: 'refinance',
                        }),
                        settingMonthOfLead(),
                        setActionDateToEdit({ id: lead._id, actionDate: val._d }),

                      )
                      )
                        : handleStatusChange(val, lead))
                      }// eslint-disable-next-line
                      value={lead && lead.hasOwnProperty('actionDate') && lead?.actionDate !== null ? moment(lead.actionDate) : null}
                      format={'YYYY/MM/DD'}
                    //  defaultValue={((filteredScraped) ? filteredScraped.actionDate : '')}
                    />
                    : value
          }
        </ValueWrapper>
      </Col>
      <PitchDateTime
        updateLeadStage={updateLeadStage}
        handleStatusChange={handleStatusChange}
        dateTimeRecord={dateTimeRecord}
        setDateTimeRecord={setDateTimeRecord}
      />
    </Row>

  );
};

export default ModalDetailsRow;
