import {
  call, takeLatest,
} from 'redux-saga/effects';
import {
  getmunicipalityLeads,
} from 'actions/search-leads';

import { fetchMunicipalityLeadsApi } from '../services/search-leads';
import fetchEntity from './fetch-entity';

export const fetchMunicipalityLeads = fetchEntity
  .bind(null, getmunicipalityLeads.actions, fetchMunicipalityLeadsApi);

export function* loadMunicipalityLeads({ params }) {
  yield call(fetchMunicipalityLeads, { ...params });
}
function* watchGetMunicipalityLeads() {
  yield takeLatest(getmunicipalityLeads.actionName, loadMunicipalityLeads);
}

export default {
  watchGetMunicipalityLeads,
};
