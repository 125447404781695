import {
  getDealRevenue, addDealRevenue, updateDealRevenue,
} from 'actions/deal-revenue';

const dealRevenue = () => {
  const initialState = {
    dealRevenue: null,
    data: null,
  };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case getDealRevenue.requestTypes.REQUEST:
        return {
          ...state,
        };
      case getDealRevenue.requestTypes.SUCCESS:
        return {
          ...state,
          dealRevenue: response,
        };
      case addDealRevenue.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case addDealRevenue.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case addDealRevenue.requestTypes.SUCCESS:
        return {
          ...state,
          data: response,
          isSuccessful: true,
        };
      case updateDealRevenue.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateDealRevenue.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateDealRevenue.requestTypes.SUCCESS:
        return {
          ...state,
          data: response,
          isSuccessful: true,
        };
      //
      default:
        return state;
    }
  };
};
export default dealRevenue();
