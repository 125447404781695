import { call, takeLatest } from 'redux-saga/effects';
import {
  portfoliosValues, addPortfolio, updatePortfolio, removePortfolio,
} from 'actions/portfolios';
import {
  portfoliosApi, addPortfolioApi, updatePortfolioApi, removePortfolioApi,
} from 'services/portfolios';
import fetchEntity from './fetch-entity';

const fetchPortfoliosValues = fetchEntity.bind(null, portfoliosValues.actions, portfoliosApi);

export function* loadPortfolios({ params }) {
  yield call(fetchPortfoliosValues, { ...params });
}

function* watchPortfoliosValues() {
  yield takeLatest(portfoliosValues.actionName, loadPortfolios);
}

const postAddPortfolio = fetchEntity.bind(null, addPortfolio.actions, addPortfolioApi);

export function* loadAddPortfolio({ params }) {
  yield call(postAddPortfolio, { ...params });
}

function* watchAddPortfolio() {
  yield takeLatest(addPortfolio.actionName, loadAddPortfolio);
}

const putUpdatePortfolio = fetchEntity.bind(null, updatePortfolio.actions, updatePortfolioApi);

export function* loadUpdatePortfolio({ params }) {
  yield call(putUpdatePortfolio, { ...params });
}

function* watchUpdatePortfolio() {
  yield takeLatest(updatePortfolio.actionName, loadUpdatePortfolio);
}

const removeDeletePortfolio = fetchEntity.bind(null, removePortfolio.actions, removePortfolioApi);

export function* loadRemovePortfolio({ params }) {
  yield call(removeDeletePortfolio, { ...params });
}

function* watchRemovePortfolio() {
  yield takeLatest(removePortfolio.actionName, loadRemovePortfolio);
}

export default {
  watchPortfoliosValues,
  watchAddPortfolio,
  watchUpdatePortfolio,
  watchRemovePortfolio,
};
