import {
  addNoteAction,
  updateNoteAction,
  deleteNoteAction,
} from 'actions/colliers-notes';

import { Message } from 'antd';

const colliersNotes = () => {
  const initialState = {
    addNoteRequestLoading: false,
    addNoteRequestError: false,
    addNoteSuccess: false,
    updateNoteRequestLoading: false,
    updateNoteRequestError: false,
    updateNoteSuccess: false,
    deleteNoteRequestLoading: false,
    deleteNoteRequestError: false,
    deleteNoteSuccess: false,
    response: [],
  };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case addNoteAction.requestTypes.LOAD:
        return {
          ...state,
          addNoteRequestLoading: true,
          addNoteRequestError: false,
        };

      case addNoteAction.requestTypes.REQUEST:
        return {
          ...state,
          addNoteRequestLoading: true,
          addNoteSuccess: false,
        };

      case addNoteAction.requestTypes.SUCCESS: {
        if (response && response.code === 200 && response.data !== null) {
          Message.success('Note Added');
          return {
            ...state,
            response: response.data,
            addNoteRequestLoading: false,
            addNoteRequestError: false,
            addNoteSuccess: true,
          };
        }
        Message.error('Please Try Again!');
        return {
          ...state,
          addNoteRequestLoading: false,
          addNoteRequestError: true,
          addNoteSuccess: false,
        };
      }

      case addNoteAction.requestTypes.FAILURE: {
        Message.warning('Please Try Again!');
        return {
          ...state,
          addNoteRequestLoading: false,
          addNoteRequestError: true,
          addNoteSuccess: false,
        };
      }

      case updateNoteAction.requestTypes.LOAD:
        return {
          ...state,
          updateNoteRequestLoading: true,
          updateNoteRequestError: false,
        };

      case updateNoteAction.requestTypes.REQUEST:
        return {
          ...state,
          updateNoteRequestLoading: true,
          updateNoteSuccess: false,
        };

      case updateNoteAction.requestTypes.SUCCESS: {
        if (response && response.code === 200 && response.data !== null) {
          Message.success('Note Updated');
          return {
            ...state,
            response: response.data,
            updateNoteRequestLoading: false,
            updateNoteRequestError: false,
            updateNoteSuccess: true,
          };
        }
        Message.error('Please Try Again!');
        return {
          ...state,
          updateNoteRequestLoading: false,
          updateNoteRequestError: true,
          updateNoteSuccess: false,
        };
      }

      case updateNoteAction.requestTypes.FAILURE: {
        Message.warning('Please Try Again!');
        return {
          ...state,
          updateNoteRequestLoading: false,
          updateNoteRequestError: true,
          updateNoteSuccess: false,
        };
      }

      case deleteNoteAction.requestTypes.LOAD:
        return {
          ...state,
          deleteNoteRequestLoading: true,
          deleteNoteRequestError: false,
        };

      case deleteNoteAction.requestTypes.REQUEST:
        return {
          ...state,
          deleteNoteSuccess: false,
        };

      case deleteNoteAction.requestTypes.SUCCESS: {
        if (response && response.code === 200 && response.data !== null) {
          Message.success('Note Deleted');
          return {
            ...state,
            deleteNoteRequestLoading: false,
            deleteNoteRequestError: false,
            deleteNoteSuccess: true,
          };
        }
        Message.error('Please Try Again!');
        return {
          ...state,
          deleteNoteRequestLoading: false,
          deleteNoteRequestError: true,
          deleteNoteSuccess: false,
        };
      }

      case deleteNoteAction.requestTypes.FAILURE: {
        Message.warning('Please Try Again!');
        return {
          ...state,
          deleteNoteRequestLoading: false,
          deleteNoteRequestError: true,
          deleteNoteSuccess: false,
        };
      }

      default: {
        return {
          ...state,
        };
      }
    }
  };
};

export default colliersNotes();
