import { API_ROOT } from 'configs/env-vars';
import { makeRequestAction } from './index';

export const fetchLenders = makeRequestAction('FETCH_LENDERS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const getLender = makeRequestAction('FETCH_LENDER', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const updateLender = makeRequestAction('UPDATE_LENDER', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const addLenderLogo = makeRequestAction('ADD_LENDER_UPLOADS', {
  onSuccess(params, response) {
    return {
      url: `${API_ROOT}/file-storage/${response[0].hashedId}`,
    };
  },
});

export const deleteLenderLogo = makeRequestAction('DELETE_LENDER_UPLOADS', {
  onSuccess(params, response) {
    return {
      url: '',
    };
  },
});
