import {
  addOwnerContact,
  getOwnerContact,
  getAllContacts,
  updateOwnerContact,
  deleteOwnerContact,
} from 'actions/owner-contacts';

const allOwnerContacts = () => {
  const initialState = {
    ownerContact: {},
    allOwnerContacts: [],
    contactCreated: {},
    contactUpdated: {},
    contactDeleted: {},
  };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case addOwnerContact.requestTypes.SUCCESS:
        return {
          ...state,
          contactCreated: response,
        };
      case getOwnerContact.requestTypes.SUCCESS:
        return {
          ...state,
          ownerContact: response,
        };
      case getAllContacts.requestTypes.SUCCESS:
        return {
          ...state,
          allOwnerContacts: response,
        };
      case updateOwnerContact.requestTypes.SUCCESS:
        return {
          ...state,
          contactUpdated: response,
        };
      case deleteOwnerContact.requestTypes.SUCCESS:
        return {
          ...state,
          contactDeleted: response,
        };
      default:
        return state;
    }
  };
};

export default allOwnerContacts();
