import { getPropertyClasses } from 'actions/property-classes';

const propertyClasses = () => {
  const initialState = {};
  return (state = initialState, { type, response }) => {
    switch (type) {
      case getPropertyClasses.requestTypes.SUCCESS:
        return {
          ...response,
        };
      default:
        return state;
    }
  };
};

export default propertyClasses();
