import {
  call, takeLatest,
} from 'redux-saga/effects';

import {
  getOwner,
  getOwners,
  updateOwner,
  addOwner,
  deleteOwner,
  getSimilarOwners,
  bulkAssignManagers,
} from 'actions/owners';
import {
  getOwnerApi,
  getOwnersApi,
  updateOwnerApi,
  deleteOwnerApi,
  addOwnerApi,
  bulkAssignManagersApi,
} from 'services/owners';

import fetchEntity from './fetch-entity';

const fetchAddOwner = fetchEntity.bind(
  null,
  addOwner.actions,
  addOwnerApi,
);

export function* loadAddOwner({ params }) {
  yield call(fetchAddOwner, params);
}

function* watchAddOwner() {
  yield takeLatest(addOwner.actionName, loadAddOwner);
}

const fetchUpdateOwner = fetchEntity.bind(
  null,
  updateOwner.actions,
  updateOwnerApi,
);

export function* loadUpdateOwner({ params }) {
  yield call(fetchUpdateOwner, params);
}
function* watchUpdateOwner() {
  yield takeLatest(updateOwner.actionName, loadUpdateOwner);
}

const fetchDeleteOwner = fetchEntity.bind(
  null,
  deleteOwner.actions,
  deleteOwnerApi,
);

export function* loadDeleteOwner({ params }) {
  yield call(fetchDeleteOwner, params);
}
function* watchDeleteOwner() {
  yield takeLatest(deleteOwner.actionName, loadDeleteOwner);
}

const fetchGetOwners = fetchEntity.bind(
  null,
  getOwners.actions,
  getOwnersApi,
);

export function* loadGetOwners({ params }) {
  yield call(fetchGetOwners, params);
}

function* watchGetOwners() {
  yield takeLatest(getOwners.actionName, loadGetOwners);
}

const fetchGetOwner = fetchEntity.bind(
  null,
  getOwner.actions,
  getOwnerApi,
);

export function* loadGetOwner({ params }) {
  yield call(fetchGetOwner, params);
}

function* watchGetOwner() {
  yield takeLatest(getOwner.actionName, loadGetOwner);
}

const fetchGetSimilarOwners = fetchEntity.bind(
  null,
  getSimilarOwners.actions,
  getOwnersApi,
);

export function* loadGetSimilarOwners({ params }) {
  yield call(fetchGetSimilarOwners, params);
}

function* watchGetSimilarOwners() {
  yield takeLatest(getSimilarOwners.actionName, loadGetSimilarOwners);
}

const fetchBulkAssignManagers = fetchEntity.bind(
  null,
  bulkAssignManagers.actions,
  bulkAssignManagersApi,
);

export function* loadFetchBulkAssignManagers({ params }) {
  yield call(fetchBulkAssignManagers, params);
}

function* watchBulkAssignManagers() {
  yield takeLatest(bulkAssignManagers.actionName, loadFetchBulkAssignManagers);
}

export default {
  watchAddOwner,
  watchUpdateOwner,
  watchGetOwners,
  watchGetOwner,
  watchDeleteOwner,
  watchGetSimilarOwners,
  watchBulkAssignManagers,
};
