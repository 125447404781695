import { makeRequestAction } from './index';

export const getStates = makeRequestAction('GET_STATES', {
  onSuccess(params, response) {
    return {
      response,
    };
  },
});

export const getAllStates = makeRequestAction('GET_ALL_STATES', {
  onSuccess(params, response) {
    return {
      response,
    };
  },
});
