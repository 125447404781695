import { makeRequestAction } from './index';

export const addRevenue = makeRequestAction('ADD_REVENUE', {
  onSuccess(params, response) {
    return {
      response: {
        entities: {
        },
      },
    };
  },
});

export const editRevenue = makeRequestAction('EDIT_REVENUE', {
  onSuccess(params, response) {
    return {
      response: {
        entities: {
        },
      },
    };
  },
});
