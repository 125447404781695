import { call, takeLatest } from 'redux-saga/effects';
import { fetchConsultantsCategories } from 'actions/consultant-categories';
import { consultantCategoriesApi } from 'services/consultant-categories';
import fetchEntity from './fetch-entity';

const fetchfetchConsultantsCategories = fetchEntity.bind(
  null,
  fetchConsultantsCategories.actions,
  consultantCategoriesApi,
);

export function* loadConsultantCategories({ params }) {
  yield call(fetchfetchConsultantsCategories, { ...params });
}

function* watchfetchConsultantsCategories() {
  yield takeLatest(fetchConsultantsCategories.actionName, loadConsultantCategories);
}

export default {
  watchfetchConsultantsCategories,
};
