import styled, { css } from 'styled-components';
import { Typography } from 'antd';
import propTypes from 'prop-types';

const { Title: title } = Typography;
const Title = styled(title)`
      ${(props) => props.level === 1
        && css`
          font-size: 32px ${props.importance};
          line-height: 30px ${props.importance};
          letter-spacing: 2px ${props.importance};
          font-weight: normal ;
          text-transform: uppercase;
        `}
       ${(props) => props.level === 2
         && css`
           font-size: 24px ${props.importance};
           line-height: 24px ${props.importance};
           letter-spacing: 2px ${props.importance};
           font-weight: normal ;
           text-transform: uppercase;
         `}
        ${(props) => props.level === 3
          && css`
            font-size: 18px ${props.importance};
            line-height: 17px ${props.importance};
            letter-spacing: 2px ${props.importance};
            font-weight: normal ;
          `}
      ${(props) => props.level === 4
        && css`
          font-size: 12px ${props.importance};
          letter-spacing: 2px ${props.importance};
          font-weight: normal ;
        `}
      ${(props) => props.level === 5
        && css`
          font-size: 10px ${props.importance};
          letter-spacing: 2px ${props.importance};
          font-weight: normal ;
        `}
      span {
        position: relative;
        top: -2px;
      }
      ${(props) => props.lowercase !== 'true' && css`
        text-transform: uppercase;
      `}
      ${(props) => props.nospacing === 'true' && css`
        letter-spacing: 0 !important;
      `}
      ${(props) => props.clickable === 'true' && css`
        cursor: pointer;
      `}
      ${(props) => props.centered === 'true' && css`
        text-align: center;
      `}
     color: ${(props) => props.color + props.importance};
     margin: ${(props) => props.margin + props.importance};
     display: ${(props) => props.display + props.importance};
     width:  ${(props) => props.width + props.importance};
`;

Title.propTypes = {
  /** set the title color */
  color: propTypes.string,
  margin: propTypes.string,
};

Title.defaultProps = {
  color: 'rgb(0, 0, 0)',
  margin: '0 0 15px 0',
  display: 'block',
  width: '100%',
  importance: '!important',
};

/** @component */
export default Title;
