import { combineReducers } from 'redux';
import entities from './entities';
import loading from './loading';
import error from './error';
import user from './user';
import uploads from './uploads';
import consultantsWithCategories from './consultants-categories';
import lendersWithCategories from './lenders-with-categories';
import services from './services';
import lenders from './lenders';
import consultants from './consultants';
import lendersContacts from './lender-contacts';
import stateProvince from './state-province';
import bondsData from './bond';
import rateData from './rate';
import propertyTypes from './property-types';
import propertyClasses from './property-classes';
import loansData from './loans';
import linesData from './lines';
import blanketsData from './blankets';
import propertiesData from './properties';
import portfoliosData from './portfolios';
import lenderCategory from './lenderCategory';
import deals from './deals';
import reporting from './reporting';
import listingLeads from './listingLeads';
import dealsLenderBids from './deals-lender-bids';
import userCompanies from './user-companies';
import formBuilder from './form-builder';
import loanTypes from './loan-types';
import markets from './markets';
import territories from './territories';
import dealEmail from './deal-email';
import leadsEmail from './leads-email';
import emailTemplates from './email-templates';
import guestDeal from './guest-deal';
import borrowerNotes from './borrower-notes';
import inboxEmails from './inbox-emails';
import contactsModal from './contacts-modal';
import dealsConsultantBids from './deals-consultant-bids';
import specDocs from './specDocs';
import userTargets from './user-target';
import userSettings from './user-settings';
import emailDrafts from './email-drafts';
import confAgreement from './conf-agreement';
import colliersFinneo from './fetch-colliersFinneo-leads';
import consultantContacts from './consultant-contact';
import searchLeads from './search-leads';
import DashboardCompanies from './dashboard-companies';
import bidEmails from './bids-email';
import DocumentsUploader from './documents-uploader';
import documentCategories from './documents-categories';
import testEmail from './test-email';
import construction from './construction';
import preFundingTracker from './preFundingTracker';
import colliersNotes from './colliers-notes';
import reportsData from './annual-reports';
import collaborators from './collaborators';
import listingLeadsNotes from './listingLeads-notes';
import revenue from './revenue';
import dealRevenue from './deal-revenue';
import lenderLoans from './lender-loans';
import owners from './owners';
import ownerContacts from './owner-contacts';
import ownerNotes from './owner-notes';
import myClients from './my-clients';
import brokerContacts from './broker-contacts';
import brokerCompanies from './broker-companies';
import survey from './survey';
import lenderAnalytics from './lender-analytics';
import lenderDeals from './lender-deals';

const appReducer = combineReducers({
  user,
  lenders,
  consultants,
  lendersWithCategories,
  lendersContacts,
  consultantsWithCategories,
  rateData,
  bondsData,
  entities,
  loading,
  error,
  services,
  uploads,
  stateProvince,
  propertyTypes,
  propertyClasses,
  loansData,
  linesData,
  blanketsData,
  propertiesData,
  portfoliosData,
  lenderCategory,
  deals,
  reporting,
  dealsLenderBids,
  userCompanies,
  formBuilder,
  loanTypes,
  markets,
  territories,
  dealEmail,
  leadsEmail,
  emailTemplates,
  guestDeal,
  borrowerNotes,
  inboxEmails,
  contactsModal,
  dealsConsultantBids,
  specDocs,
  userTargets,
  userSettings,
  emailDrafts,
  confAgreement,
  colliersFinneo,
  consultantContacts,
  searchLeads,
  DashboardCompanies,
  bidEmails,
  DocumentsUploader,
  documentCategories,
  testEmail,
  construction,
  preFundingTracker,
  colliersNotes,
  reportsData,
  collaborators,
  revenue,
  listingLeads,
  listingLeadsNotes,
  dealRevenue,
  lenderLoans,
  owners,
  ownerContacts,
  ownerNotes,
  myClients,
  brokerContacts,
  brokerCompanies,
  survey,
  lenderAnalytics,
  lenderDeals,
});

export default (state, action) => appReducer(state, action);
