import styled from 'styled-components';

const AttachmentWrapper = styled.div`
  border-radius: 3px;
  background-color: #E7E9E5;
  padding: 20px;
  color: #000;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: bold;
  a
  {
    color: #000;
  }
  i
  {
    color: #546E00
    margin-right: 10px; 
    font-size: 16px;
  }
`;

export default AttachmentWrapper;
