import client from './main-client';

export const portfoliosApi = (params) => client().get(`/portfolios?userId=${params.userId}&$limit=null&active=true`);

export const addPortfolioApi = (data) => {
  client().post('/portfolios', data);
};

export const updatePortfolioApi = (data) => client().put(`/portfolios/${data._id}`, data);

export const removePortfolioApi = (data) => client().delete(`/portfolios/${data._id}`);
