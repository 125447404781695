import { makeRequestAction } from './index';

export const exportData = makeRequestAction('EXPORT_DATA', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});
