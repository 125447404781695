import { makeRequestAction } from './index';

export const specDocsValues = makeRequestAction('SPECDOCS', {
  onSuccess(params, response) {
    return {
      response: {
        entities: {
          specDocsData: response,
        },
      },
    };
  },
});

export const addSpecDocs = makeRequestAction('CREATE_SPECDOCS', {
  onSuccess(params, response) {
    return {
      response: {
        entities: {
          createdSpecDocs: response,
        },
      },
    };
  },
});

export const updateSpecDocs = makeRequestAction('UPDATE_SPECDOCS', {
  onSuccess(params, response) {
    return {
      response: {
        entities: {
          updatedSpecDocs: response,
        },
      },
    };
  },
});

export const removeSpecDocs = makeRequestAction('REMOVE_SPECDOCS', {
  onSuccess(params, response) {
    return {
      response: {
        entities: {
          deleteSpecDocs: response,
        },
      },
    };
  },
});
