import client from './main-client';

export const listingLeadsApi = (params) => client().get('/listing-leads', { params });

export const addLeadListingApi = (data) => {
  client().post('/listing-leads', data);
};

export const updatelistingLeadsApi = (data) => {
  if (data.brokerId) {
    const { brokerId } = data;
    delete data.brokerId;
    return client().patch(`/listing-leads?brokerId=${brokerId}&updateCRM=true`, data);
  }
  return client().patch(`/listing-leads/${data.id}`, data);
};

export const updateMultipleListingsLeadsApi = (data) => {
  client().patch(`/listing-leads?${data.url}&updateCRM=true`, data.body);
};
