import styled, { css } from 'styled-components';
import { Input as input } from 'antd';

const Input = styled(input)`
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  border-radius: 4px !important;
  background-color: #ffffff !important;
  height: 40px !important;

  ${(props) => (props.theme === 'email')
    && css`
    border-left-width: 0px !important;
    border-right-width: 0px !important;
    border-top-width: 0px !important;
    `}

    ${(props) => (props.theme === 'disabled')
    && css`
    background-color: #f5f5f5 !important;
    color: black !important;
    .ant-input[disabled]
    {
      color:black !important;
    }
    `}
`;

/** @component */
export default Input;
