import { call, takeLatest } from 'redux-saga/effects';
import {
  specDocsValues, addSpecDocs, updateSpecDocs, removeSpecDocs,
} from 'actions/specDocs';
import {
  specDocsApi, addSpecDocsApi, updateSpecDocsApi, removeSpecDocsApi,
} from 'services/specDocs';
import fetchEntity from './fetch-entity';

const fetchSpecDocsValues = fetchEntity.bind(null, specDocsValues.actions, specDocsApi);

export function* loadSpecDocs({ params }) {
  yield call(fetchSpecDocsValues, { ...params });
}

function* watchSpecDocsValues() {
  yield takeLatest(specDocsValues.actionName, loadSpecDocs);
}

const postAddSpecDocs = fetchEntity.bind(null, addSpecDocs.actions, addSpecDocsApi);

export function* loadAddSpecDocs({ params }) {
  yield call(postAddSpecDocs, { ...params });
}

function* watchAddSpecDocs() {
  yield takeLatest(addSpecDocs.actionName, loadAddSpecDocs);
}

const putUpdateSpecDocs = fetchEntity.bind(null, updateSpecDocs.actions, updateSpecDocsApi);

export function* loadUpdateSpecDocs({ params }) {
  yield call(putUpdateSpecDocs, { ...params });
}

function* watchUpdateSpecDocs() {
  yield takeLatest(updateSpecDocs.actionName, loadUpdateSpecDocs);
}

const removeDeleteSpecDocs = fetchEntity.bind(null, removeSpecDocs.actions, removeSpecDocsApi);

export function* loadRemoveSpecDocs({ params }) {
  yield call(removeDeleteSpecDocs, { ...params });
}

function* watchRemoveSpecDocs() {
  yield takeLatest(removeSpecDocs.actionName, loadRemoveSpecDocs);
}

export default {
  watchSpecDocsValues,
  watchAddSpecDocs,
  watchUpdateSpecDocs,
  watchRemoveSpecDocs,
};
