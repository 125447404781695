import { makeRequestAction } from './index';

export const importPortfolio = makeRequestAction('IMPORT_PORTFOLIO', {
  onSuccess(params, response) {
    return {
      response: {
        entities: {
          import: response,
        },
      },
    };
  },
});

export const importBorrowers = makeRequestAction('IMPORT_BORROWERS', {
  onSuccess(params, response) {
    return {
      response: {
        entities: {
          import: response,
        },
      },
    };
  },
});
