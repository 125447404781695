import {
  call, takeLatest,
} from 'redux-saga/effects';
import {
  addOwnerNote,
  getOwnerNotes,
  updateOwnerNote,
  deleteOwnerNote,
} from 'actions/owner-notes';
import {
  addOwnerNoteApi,
  updateOwnerNoteApi,
  getAllOwnerNotesApi,
  deleteOwnerContactApi,
} from 'services/owner-notes';
import fetchEntity from './fetch-entity';

const fetchAddOwnerNote = fetchEntity.bind(
  null,
  addOwnerNote.actions,
  addOwnerNoteApi,
);

export function* loadAddOwnerNote({ params }) {
  yield call(fetchAddOwnerNote, params);
}

function* watchAddOwnerNote() {
  yield takeLatest(addOwnerNote.actionName, loadAddOwnerNote);
}

const fetchGetOwnerNotes = fetchEntity.bind(
  null,
  getOwnerNotes.actions,
  getAllOwnerNotesApi,
);

export function* loadGetOwnerNotes({ params }) {
  yield call(fetchGetOwnerNotes, params);
}

function* watchGetOwnerNotes() {
  yield takeLatest(getOwnerNotes.actionName, loadGetOwnerNotes);
}

const fetchUpdateOwnerNote = fetchEntity.bind(
  null,
  updateOwnerNote.actions,
  updateOwnerNoteApi,
);

export function* loadUpdateOwnerNote({ params }) {
  yield call(fetchUpdateOwnerNote, params);
}

function* watchUpdateOwnerNote() {
  yield takeLatest(updateOwnerNote.actionName, loadUpdateOwnerNote);
}

const fetchDeleteOwnerNote = fetchEntity.bind(
  null,
  deleteOwnerNote.actions,
  deleteOwnerContactApi,
);

export function* loadDeleteOwnerNote({ params }) {
  yield call(fetchDeleteOwnerNote, params);
}

function* watchDeleteOwnerNote() {
  yield takeLatest(deleteOwnerNote.actionName, loadDeleteOwnerNote);
}

export default {
  watchAddOwnerNote,
  watchGetOwnerNotes,
  watchUpdateOwnerNote,
  watchDeleteOwnerNote,
};
