import {
  getAllListingLeadsNotes,
  addListingLeadsNote,
  updateListingNote,
  deleteListingNote,
  getRealtorListingNotes,
} from 'actions/listingLeads-notes';

import {
  getListingLeadsNotesApi,
  updateListingLeadsNotesApi,
  addListingLeadsNotesApi,
  deleteListingLeadsNotesApi,
} from 'services/listingLeads-notes';
import {
  call, takeLatest,
} from 'redux-saga/effects';
import fetchEntity from './fetch-entity';

const fetchListingLeadsNotesData = fetchEntity
  .bind(null, getAllListingLeadsNotes.actions, getListingLeadsNotesApi);

export function* loadFetchListingLeadsNotes({ params }) {
  yield call(fetchListingLeadsNotesData, params);
}

function* watchListingLeadsNotes() {
  yield takeLatest(getAllListingLeadsNotes.actionName, loadFetchListingLeadsNotes);
}

const fetchAddListingLeadsNotes = fetchEntity.bind(
  null,
  addListingLeadsNote.actions,
  addListingLeadsNotesApi,
);

export function* loadAddListingLeadsNotes({ params }) {
  yield call(fetchAddListingLeadsNotes, params);
}

function* watchAddListingLeadsNotes() {
  yield takeLatest(addListingLeadsNote.actionName, loadAddListingLeadsNotes);
}

const fetchUpdateListingLeadsNotes = fetchEntity
  .bind(null, updateListingNote.actions, updateListingLeadsNotesApi);

export function* loadUpdateListingLeadsNotes({ params }) {
  yield call(fetchUpdateListingLeadsNotes, params);
}

function* watchUpdateListingLeadsNotes() {
  yield takeLatest(updateListingNote.actionName, loadUpdateListingLeadsNotes);
}

const fetchDeleteListingLeadsNotes = fetchEntity
  .bind(null, deleteListingNote.actions, deleteListingLeadsNotesApi);

export function* loadDeleteListingLeadsNotes({ params }) {
  yield call(fetchDeleteListingLeadsNotes, params);
}

function* watchDeleteListingLeadsNotes() {
  yield takeLatest(deleteListingNote.actionName, loadDeleteListingLeadsNotes);
}

const fetchRealtorListingNotes = fetchEntity
  .bind(null, getRealtorListingNotes.actions, getListingLeadsNotesApi);

export function* loadFetchRealtorListingNotes({ params }) {
  yield call(fetchRealtorListingNotes, params);
}

function* watchFetchRealtoListingNotes() {
  yield takeLatest(getRealtorListingNotes.actionName, loadFetchRealtorListingNotes);
}

export default {
  watchListingLeadsNotes,
  watchUpdateListingLeadsNotes,
  watchAddListingLeadsNotes,
  watchDeleteListingLeadsNotes,
  watchFetchRealtoListingNotes,
};
