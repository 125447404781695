import styled, { css } from 'styled-components';
import { Select as select } from 'antd';

const Select = styled(select)`
  .ant-select-selection--single {
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    border-radius: 4px !important;
    background-color: #ffffff !important;
    height: 40px !important;

    .ant-select-selection__rendered {
      height: 40px;
      line-height: 39px;
    }
  }

  ${(props) => props.block && css`
    width: 100% !important;
  `}

  ${(props) => (props.type === 'email') && css`
    .ant-select-selection {
      border-left-width: 0px !important;
      border-right-width: 0px !important;
      border-top-width: 0px !important;
    }
  `}

  ${(props) => (props.theme === 'multiSelect') && css`
    .ant-select-selection--multiple {
      white-space: nowrap;
      height: 30px;
      overflow: auto
    }
  `}

  ${(props) => (props.theme === 'managerSelect') && css`
    &.ant-select-disabled .ant-select-selection--single {
      background-color: initial !important;
      background-color: initial !important;
    }

    .ant-select-selection--single {
      height: initial !important;
    }

    .ant-select-selection__rendered {
      height: 32px !important;
      line-height: 32px !important;
    }
  `}
`;

export default Select;
