import { call, takeLatest, select } from 'redux-saga/effects';
import {
  getUserCompanies, updateUserCompany, addUserCompanyLogo, deleteUserCompanyLogo,
} from 'actions/user-companies';
import {
  getUserCompaniesApi, updateUserCompaniesApi,
} from 'services/user-companies';
import { addUploadApi, deleteUploadApi } from 'services/uploads';
import { getUserData } from 'selectors/users';
import fetchEntity from './fetch-entity';

const fetchUserCompanies = fetchEntity.bind(null, getUserCompanies.actions, getUserCompaniesApi);

const fetchUpdateUserCompany = fetchEntity.bind(
  null,
  updateUserCompany.actions,
  updateUserCompaniesApi,
);

const fetchAddUserCompanyLogo = fetchEntity.bind(
  null,
  addUserCompanyLogo.actions,
  addUploadApi,
);

const fetchDeleteUserCompanyLogo = fetchEntity.bind(
  null,
  deleteUserCompanyLogo.actions,
  deleteUploadApi,
);

export function* loadGetUserCompaniesOnChange({ params }) {
  const userData = yield select(getUserData);
  const userCompanies = yield select((state) => state.userCompanies);
  const { total, skip, limit } = userCompanies;
  if (skip && skip >= total) {
    yield call(fetchUserCompanies, {
      $skip: total - Math.max(total % limit, limit),
      '$sort[updatedAt]': -1,
      name: userData.user_company.name,
    });
  } else {
    yield call(fetchUserCompanies, {
      $skip: skip,
      '$sort[updatedAt]': -1,
      name: userData.user_company.name,
    });
  }
}

function* watchGetUserCompaniesOnChange() {
  yield takeLatest([
    updateUserCompany.requestTypes.SUCCESS,
  ], loadGetUserCompaniesOnChange);
}

export function* loadGetUserCompanies({ params }) {
  const userData = yield select(getUserData);
  yield call(fetchUserCompanies, { ...params, name: userData.user_company && userData.user_company.name });
}
function* watchGetUserCompanies() {
  yield takeLatest(getUserCompanies.actionName, loadGetUserCompanies);
}

export function* loadUpdateUserCompanies({ params }) {
  yield call(fetchUpdateUserCompany, { ...params });
}

function* watchUpdateUserCompanies() {
  yield takeLatest(updateUserCompany.actionName, loadUpdateUserCompanies);
}

export function* loadAddUserCompanyLogo({ params }) {
  yield call(fetchAddUserCompanyLogo, { ...params });
}

function* watchAddUserCompanyLogo() {
  yield takeLatest(addUserCompanyLogo.actionName, loadAddUserCompanyLogo);
}

export function* loadDeleteUserCompanyLogo({ params }) {
  yield call(fetchDeleteUserCompanyLogo, { ...params });
}

function* watchDeleteUserCompanyLogo() {
  yield takeLatest(deleteUserCompanyLogo.actionName, loadDeleteUserCompanyLogo);
}

export default {
  watchGetUserCompanies,
  watchUpdateUserCompanies,
  watchGetUserCompaniesOnChange,
  watchAddUserCompanyLogo,
  watchDeleteUserCompanyLogo,
};
