import {
  addBrokerCompany,
  getBrokerCompany,
  getBrokerCompanies,
} from 'actions/broker-companies';

const brokerCompanies = () => {
  const initialState = {
    brokerCompany: {},
    allBrokerCompanies: [],
    brokerCompanyAdded: {},
  };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case getBrokerCompany.requestTypes.SUCCESS:
        return {
          ...state,
          brokerCompany: response,
        };
      case getBrokerCompanies.requestTypes.SUCCESS:
        return {
          ...state,
          allBrokerCompanies: response,
        };
      case addBrokerCompany.requestTypes.SUCCESS:
        return {
          ...state,
          brokerCompanyAdded: response,
        };
      default:
        return state;
    }
  };
};

export default brokerCompanies();
