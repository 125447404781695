import { call, takeLatest } from 'redux-saga/effects';
import { bondsValues } from 'actions/bonds';
import { bondsApi } from 'services/bonds';
import fetchEntity from './fetch-entity';

const fetchbondsValues = fetchEntity.bind(null, bondsValues.actions, bondsApi);

export function* loadBonds({ params }) {
  yield call(fetchbondsValues, { ...params });
}

function* watchbondsValues() {
  yield takeLatest(bondsValues.actionName, loadBonds);
}

export default {
  watchbondsValues,
};
