/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Title } from 'components';
import { Row, Col } from 'antd';
import {
  Map, Marker, GoogleApiWrapper, InfoWindow,
} from 'google-maps-react';
import {
  InfoCircleOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { getLenders } from 'selectors/lenders';
import { GOOGLE_MAPS_KEY } from 'configs/env-vars';
import { monthAdder } from '../../utils/monthAdder';

const apiKey = GOOGLE_MAPS_KEY;

function MarkerMap(props) {
  const [places, setPlaces] = useState([]);
  const [activeMarker, setActiveMarker] = useState({});
  const [selectedProperty, setSelectedProperty] = useState({});
  const [loansAttachedToSelectedProperty, setLoansAttachedToSelectedProperty] = useState([]);
  const [linesAttachedToSelectedProperty, setLinesAttachedToSelectedProperty] = useState([]);
  // this will hold all the construction attached to property, this will
  // come into picture when we are coming from viewLoanPage, case when we
  // have both term and constructionLoan
  const [constructionLoansAttachedToSelectedProperty, setConstructionLoansAttachedToSelectedProperty] = useState([]);
  const [showInfoWindow, setShowInfoWindow] = useState(false);

  const lenderValues = useSelector(getLenders);

  useEffect(() => {
    setPlaces(props.places.filter((place) => place.coordinates));
  }, [props.places]);

  const onMarkerClick = (property, marker, e) => {
    setSelectedProperty(property);
    setLoansAttachedToSelectedProperty(props.loans.filter((loan) => loan.propertyId === property.clientPlace._id));
    setLinesAttachedToSelectedProperty(props.lines.filter((line) => line.propertyId.includes(property.clientPlace._id)));
    setConstructionLoansAttachedToSelectedProperty(props.constructionLoans
      ? props.constructionLoans.filter((constructionLoan) => constructionLoan.propertyId === property.clientPlace._id) : []);
    setActiveMarker(marker);
    setShowInfoWindow(true);
  };

  const onMapClicked = () => {
    if (showInfoWindow) {
      setShowInfoWindow(false);
      setActiveMarker(null);
    }
  };

  const getLenderValues = () => {
    let lenderValue = '';
    if ((loansAttachedToSelectedProperty.length && constructionLoansAttachedToSelectedProperty.length) || loansAttachedToSelectedProperty.length > 1 || linesAttachedToSelectedProperty.length > 1 || constructionLoansAttachedToSelectedProperty.length > 1
      || (loansAttachedToSelectedProperty.length > 0 && linesAttachedToSelectedProperty.length > 0)) {
      lenderValue = 'Multiple';
    } else if (constructionLoansAttachedToSelectedProperty.length) {
      lenderValue = lenderValues.data.find((lender) => lender.id === constructionLoansAttachedToSelectedProperty[0].lenderId)?.companyName;
    } else if (loansAttachedToSelectedProperty.length === 1 && linesAttachedToSelectedProperty.length === 0) {
      lenderValue = lenderValues.data.find((lender) => lender.id === loansAttachedToSelectedProperty[0].lenderId)?.companyName;
    } else if (linesAttachedToSelectedProperty.length === 1 && loansAttachedToSelectedProperty.length === 0) {
      lenderValue = lenderValues.data.find((lender) => lender.id === linesAttachedToSelectedProperty[0].lenderId)?.companyName;
    }

    return lenderValue;
  };

  const getRateValue = () => {
    let rate = '';

    if ((loansAttachedToSelectedProperty.length && constructionLoansAttachedToSelectedProperty.length) || loansAttachedToSelectedProperty.length > 1 || linesAttachedToSelectedProperty.length > 1 || constructionLoansAttachedToSelectedProperty.length > 1
      || (loansAttachedToSelectedProperty.length > 0 && linesAttachedToSelectedProperty.length > 0)) {
      rate = 'Multiple';
    } else if (constructionLoansAttachedToSelectedProperty.length) {
      rate = constructionLoansAttachedToSelectedProperty[0].allInRate.toFixed(2);
    } else if (loansAttachedToSelectedProperty.length === 1 && linesAttachedToSelectedProperty.length === 0) {
      rate = loansAttachedToSelectedProperty[0].allInRate.toFixed(2);
    } else if (linesAttachedToSelectedProperty.length === 1 && loansAttachedToSelectedProperty.length === 0) {
      rate = linesAttachedToSelectedProperty[0].allInRate.toFixed(2);
    }

    return rate;
  };

  const getLoanAmount = () => {
    if (props.construction) {
      let totalConstLoanAmount = 0;
      for (const loan of loansAttachedToSelectedProperty) {
        totalConstLoanAmount += loan.loanAmount;
      }
      return `$${totalConstLoanAmount.toLocaleString()}`;
    }

    let amount = '';

    if (loansAttachedToSelectedProperty.length && constructionLoansAttachedToSelectedProperty.length) {
      amount = 'Multiple';
    } else if (constructionLoansAttachedToSelectedProperty.length) {
      let totalConstLoanAmount = 0;
      for (const loan of constructionLoansAttachedToSelectedProperty) {
        totalConstLoanAmount += loan.totalOutstandingLoanAmount;
      }
      amount = totalConstLoanAmount.toLocaleString();
    } else if (loansAttachedToSelectedProperty.length > 0 && linesAttachedToSelectedProperty.length > 0) {
      const loanAmount = loansAttachedToSelectedProperty.reduce((acc, curr) => acc + curr.originalLoanAmount, 0);
      const lineAmount = linesAttachedToSelectedProperty.reduce((acc, curr) => acc + curr.outstandingAmount, 0);
      amount = (loanAmount + lineAmount).toLocaleString();
    } else if (loansAttachedToSelectedProperty.length > 0 && linesAttachedToSelectedProperty.length === 0) {
      amount = (loansAttachedToSelectedProperty.reduce((acc, curr) => acc + curr.originalLoanAmount, 0)).toLocaleString();
    } else if (linesAttachedToSelectedProperty.length > 0 && loansAttachedToSelectedProperty.length === 0) {
      amount = (linesAttachedToSelectedProperty.reduce((acc, curr) => acc + curr.outstandingAmount, 0)).toLocaleString();
    }

    return amount;
  };

  const selectedPropertyInfo = showInfoWindow ? (
    <Row>
      <Row>
        <Col span={24}>
          <a href={`/view-loan/${selectedProperty && selectedProperty.clientPlace && selectedProperty.clientPlace._id}`}>
            <Row>
              <Col span={20}>
                <Title color={'#051289'} level={4}>{selectedProperty.name}
                </Title>
              </Col>
              <Col span={4} >
                <InfoCircleOutlined styles={{ color: '#051289', marginTop: '15px' }} />
              </Col>
            </Row>
          </a>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ marginBottom: 20 }}>
          <text >{selectedProperty.clientPlace.address}</text>
        </Col>
      </Row>
      <Row>
        <Col span={12} >{(loansAttachedToSelectedProperty.length && constructionLoansAttachedToSelectedProperty.length) ? 'Value'
          : (props.construction
        || constructionLoansAttachedToSelectedProperty.length) ? 'Stabilized Value' : 'Internal Valuation'}</Col>
        {/* for some term loan properties internalValuationAmount was undefined for me */}
        <Col span={12} >{(loansAttachedToSelectedProperty.length && constructionLoansAttachedToSelectedProperty.length) || (loansAttachedToSelectedProperty.length > 1) || (constructionLoansAttachedToSelectedProperty.length > 1) ? 'Multiple' : constructionLoansAttachedToSelectedProperty.length ? activeMarker.clientPlace.totalStabilizedValue.toLocaleString() : activeMarker.clientPlace.internalValuationAmount ? activeMarker.clientPlace.internalValuationAmount.toLocaleString() : 'N/A'}</Col>
      </Row>
      <Row>
        <Col span={12} >Lender</Col>
        <Col span={12} >{getLenderValues()}</Col>
      </Row>
      <Row>
        {props && props.propertyPage === 'propertyPage'
          ? <div>
            <Col span={12} >{(loansAttachedToSelectedProperty.length && constructionLoansAttachedToSelectedProperty.length) ? 'Amount' : (props.construction) || (constructionLoansAttachedToSelectedProperty.length) ? 'Outstanding Loan' : 'Loan Amount'}</Col>
            <Col span={12} >{getLoanAmount()}</Col>
          </div>
          : <div>
            <Col span={12} >Outstanding Loan</Col>
            <Col span={12} >{getLoanAmount()}</Col>
          </div>
      }
      </Row>
      <Row>
        <Col span={12} >All In Rate</Col>
        <Col span={12} >{getRateValue()}</Col>
      </Row>
      <Row style={{ marginBottom: '15px' }}>
        <Col span={12} >Loan Expiry Date(s)</Col>
        <Col span={12} >{loansAttachedToSelectedProperty
          .map((loan, index) => <Row key={index}>{monthAdder(new Date(loan.firstPaymentDate), loan.term)
            .toISOString().split('T')[0]}</Row>)}</Col>
        <Col span={12} /><Col span={12} >{constructionLoansAttachedToSelectedProperty
          .map((loan, index) => <Row key={index}>{monthAdder(new Date(loan.firstPaymentDate), loan.term)
            .toISOString().split('T')[0]}</Row>)}</Col>
      </Row>
      <Row>
        <Col span={24}>
          <img
            style={{ marginBottom: '15px' }}
            src={
              `https://maps.googleapis.com/maps/api/streetview?location=${
                activeMarker.clientPlace.address
              }&size=300x220&key=${
                apiKey}`
            }
            alt={`${selectedProperty.name}`}
          />
        </Col>
      </Row>
    </Row>
  ) : (
    <Row />
  );

  // Create the bounds to center the map
  const points = places.map((place) => place.coordinates);
  const bounds = props && props.google && new props.google.maps.LatLngBounds();
  points.map((point) => bounds && bounds.extend(point));
  // on bases of notBounds prop (which is coming only from viewProperty page) we are rendering either bounded or zoom out map
  // when notBounds -> true -> zoom out map, without bounding
  return (
    props.notBounds ? (<Map
      gestureHandling={'cooperative'}
      mapTypeControl={false}
      zoomControl
      google={props.google}
      onClick={onMapClicked}
      center={{
        // by default if no noordinates, we are showing pointer on Toronto
        // eslint-disable-next-line max-len
        lat: props.places && props.places[0] && props.places[0].coordinates && props.places[0].coordinates.lat ? props.places[0].coordinates.lat : 43.6532,
        // eslint-disable-next-line max-len
        lng: props.places && props.places[0] && props.places[0].coordinates && props.places[0].coordinates.lng ? props.places[0].coordinates.lng : -79.3832,
      }}
      zoom={12}
      style={props.style}
      >
      {places.map((place) => (
        <Marker
          icon={{
            url: 'https://upload.wikimedia.org/wikipedia/commons/0/02/Red_Circle%28small%29.svg',
            scaledSize: { width: 8, height: 8 },
          }}
          style={{ width: '20px', height: '20px', backgroundColor: 'red' }}
          key={place.address}
          title={place.title}
          name={place.name}
          position={place.coordinates}
          onClick={onMarkerClick}
          clientPlace={place}
          />
      ))}
      <InfoWindow marker={activeMarker} visible={showInfoWindow}>
        {selectedPropertyInfo}
      </InfoWindow>
    </Map>) : (<Map
      gestureHandling={'cooperative'}
      mapTypeControl={false}
      zoomControl={false}
      google={props.google}
      onClick={onMapClicked}
      center={{ lat: 45.6532, lng: -95.3832 }}
      zoom={3.7}
      // bounds={bounds}
      style={props.style}
        // bounds={points.length ? bounds : null}
      >
      {places.map((place) => (
        <Marker
          icon={{
            url: 'https://upload.wikimedia.org/wikipedia/commons/0/02/Red_Circle%28small%29.svg',
            scaledSize: { width: 8, height: 8 },
          }}
          style={{ width: '20px', height: '20px', backgroundColor: 'red' }}
          key={place.address}
          title={place.title}
          name={place.name}
          position={place.coordinates}
          onClick={onMarkerClick}
          clientPlace={place}
          />
      ))}
      <InfoWindow marker={activeMarker} visible={showInfoWindow}>
        {selectedPropertyInfo}
      </InfoWindow>
    </Map>)
  );
}

export default GoogleApiWrapper({
  apiKey,
})(React.memo(MarkerMap));
