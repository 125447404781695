import {
  fetchUserTargetValues,
  addUserTargetValues,
  updateUserTargetValues,
  fetchUserTargetAllyears,
} from 'actions/user-target';
import { Message } from 'antd';

const userTargets = () => {
  const initialState = {
    userTargets: null,
  };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case fetchUserTargetValues.requestTypes.SUCCESS:
        return {
          ...state,
          userTargets: response,
        };
      case fetchUserTargetAllyears.requestTypes.SUCCESS:
        return {
          ...state,
          userTargetsAllYears: response,
        };
      case addUserTargetValues.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case addUserTargetValues.requestTypes.FAILURE:
        Message.error(response.message);
        return {
          ...state,
          isSuccessful: false,
        };
      case addUserTargetValues.requestTypes.SUCCESS:
        return {
          ...state,
          isSuccessful: true,
        };
      case updateUserTargetValues.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateUserTargetValues.requestTypes.FAILURE:
        Message.error(response.message);
        return {
          ...state,
          isSuccessful: false,
        };
      case updateUserTargetValues.requestTypes.SUCCESS:
        return {
          ...state,
          isSuccessful: true,
        };
      default:
        return state;
    }
  };
};

export default userTargets();
