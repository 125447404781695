import styled, { css } from 'styled-components';

import {
  Tabs as tabs,
} from 'antd';

const TabsAntD = styled(tabs)`
${(props) => props.theme === 'carousal' && css`
.ant-tabs-bar {
  margin: 0px!important;
}`}`;

export default TabsAntD;
