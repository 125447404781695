import React, { useState, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { Table as table } from 'antd';
import propTypes from 'prop-types';

const StyledTable = styled(table)`
  margin-bottom: 40px;
  border-collapse: collapse !important;
  color: #353535;
  em {
    color: #b0afaf;
    font-size: 12px;
  }
  tbody {
    background: #fff;
  }
  .hide .ant-table-row-expand-icon{
    display:none;
  }
  .highlight > td {
    background: #e4e4e4;
  }
  tr > th {
    background: none !important;
    color: #4962f5 !important;
    font-size: 12px;
    border-bottom: none !important;
  }

  ${(props) => (props.showColumn === false && css`
    tr > th {
      display: none;
    }
  )`)};

  tr > td {
    border-bottom: 4px solid #eff2f6 !important;
    position: relative;
  }
  tr:hover > td {
    background: #fff !important;
  }
  .ant-table-footer {
    padding: 0;
  }

  .ant-table
  {
    line-height:2.2;
  }

  .orangeRowHighlight {
    background: #fff5ec;
  }

  .orangeRowHighlight:hover > td {
    background: #fff5ec !important;
  }
  ${(props) => (props.design === true && css`
    tr > th {
      display: none;
    }
  )`)};

  tr > td {
    border-bottom: 4px solid #eff2f6 !important;
    position: relative;
  }
  tr:hover > td {
    background: #fff !important;
  }
  .ant-table-footer {
    padding: 0;
  }
  .ant-table
  {
    line-height:2.2;
  }

  .orangeRowHighlight {
    background: #fff5ec;
  }

  .orangeRowHighlight:hover > td {
    background: #fff5ec !important;
  }

`;

StyledTable.defaultProps = {
  type: 'standard',
};

StyledTable.propTypes = {
  type: propTypes.string,
};

const Table = (props) => {
  const [hoveredRow, setHoveredRow] = useState();
  const rowKey = props.rowKey || 'key';

  const getDataSource = useCallback(() => {
    const dataSource = props.dataSource && [...props.dataSource];
    if (hoveredRow) {
      const rowIndex = dataSource.findIndex((l) => l[rowKey] === hoveredRow);
      const newRow = {
        ...dataSource[rowIndex],
        hovered: true,
      };
      dataSource[rowIndex] = newRow;
    }
    return dataSource;
  }, [hoveredRow, props.dataSource, rowKey]);

  return props.notShowTableIfEmpty ? null
    : <StyledTable
      pagination={props.notShowPagination ? false : {
        ...props.pagination,
        showTotal: (total, range) => `Showing: ${range[0]} - ${range[1]} / ${total}`,
        position: 'bottom',
        showLessItems: true,
        embedded: true,
      }}
      onRow={(record) => ({
        onMouseEnter: () => setHoveredRow(record[rowKey]),
        onMouseLeave: () => setHoveredRow(),
      })}
      rowClassName={(record, index) => {
        const tempArr = [];
        if (record && record.term && record.term.length > 0) {
          record.term.forEach((term) => {
            if (term && term.props.children?.props.children?.props
              && Number(term.props.children?.props.children?.props.children) >= 0) {
              tempArr.push(Number(term.props.children.props.children.props.children));
            } else if (term && term.props.children?.props.children?.props.children?.props
              && Number(term.props.children?.props.children?.props.children?.props.children) >= 0) {
              tempArr.push(Number(term.props.children.props.children.props.children.props.children));
            }
          });
        }
        const highlightRow = tempArr.some((item) => item <= 6);
        return highlightRow ? 'orangeRowHighlight' : '';
      }}
      {...props}
      dataSource={getDataSource()}
  />;
};

/** @component */
export default Table;
