import {
  getCollaborators, addCollaborators, updateCollaborators, deleteCollaborators,
} from 'actions/collaborator';

const collaborators = () => {
  const initialState = {
    collaborators: null,
    data: null,
  };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case getCollaborators.requestTypes.REQUEST:
        return {
          ...state,
        };
      case getCollaborators.requestTypes.SUCCESS:
        return {
          ...state,
          collaborators: response,
        };
      case addCollaborators.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case addCollaborators.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case addCollaborators.requestTypes.SUCCESS:
        return {
          ...state,
          data: response,
          isSuccessful: true,
        };
      case updateCollaborators.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateCollaborators.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateCollaborators.requestTypes.SUCCESS:
        return {
          ...state,
          data: response,
          isSuccessful: true,
        };
      //
      case deleteCollaborators.requestTypes.SUCCESS:
        return {
          ...state,
        };
      default:
        return state;
    }
  };
};
export default collaborators();
