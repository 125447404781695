import { call, takeLatest } from 'redux-saga/effects';
import {
  getAllUserDocuments, addDocument, removeDocument,
} from 'actions/documents-uploader';
import {
  getAllUserDocumentsApi, addDocumentApi, removeDocumentApi,
} from 'services/documents-uploader';
import fetchEntity from './fetch-entity';

const getAllUserDocumentsValues = fetchEntity.bind(null, getAllUserDocuments.actions, getAllUserDocumentsApi);

export function* loadGetAllUserDocuments({ params }) {
  yield call(getAllUserDocumentsValues, { ...params });
}

function* watchGetAllUserDocuments() {
  yield takeLatest(getAllUserDocuments.actionName, loadGetAllUserDocuments);
}

const addDocumentValues = fetchEntity.bind(null, addDocument.actions, addDocumentApi);

export function* loadAddDocumentValues({ params }) {
  yield call(addDocumentValues, { ...params });
}

function* watchAddDocumentValues() {
  yield takeLatest(addDocument.actionName, loadAddDocumentValues);
}

const removeDocumentValues = fetchEntity.bind(null, removeDocument.actions, removeDocumentApi);

export function* loadRemoveDocumentValues({ params }) {
  yield call(removeDocumentValues, { ...params });
}

function* watchRemoveDocumentValues() {
  yield takeLatest(removeDocument.actionName, loadRemoveDocumentValues);
}

export default {
  watchGetAllUserDocuments,
  watchAddDocumentValues,
  watchRemoveDocumentValues,
};
