import {
  getUserCompanies,
  updateUserCompany,
} from 'actions/user-companies';

const userCompanies = () => {
  const initialState = {};

  return (state = initialState, action) => {
    const { type, response } = action;
    switch (type) {
      case getUserCompanies.requestTypes.SUCCESS:
        return {
          ...response,
        };
      case updateUserCompany.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateUserCompany.requestTypes.SUCCESS:
        return {
          ...state,
          isSuccessful: true,
        };
      case updateUserCompany.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      default:
        return state;
    }
  };
};

export default userCompanies();
