import {
  call, takeLatest,
} from 'redux-saga/effects';
import {
  getStates, getAllStates,
} from 'actions/state-province';
import {
  getStatesApi,
} from 'services/state-province';
import fetchEntity from './fetch-entity';

const getStatesData = fetchEntity
  .bind(null, getStates.actions, getStatesApi);

export function* loadGetStatesData({ params }) {
  yield call(
    getStatesData,
    { ...params },
  );
}

function* watchGetStatesData() {
  yield takeLatest(getStates.actionName, loadGetStatesData);
}

const getAllStatesData = fetchEntity.bind(null, getAllStates.actions, getStatesApi);
export function* loadGetAllStatesData({ params }) {
  yield call(
    getAllStatesData,
    { ...params },
  );
}

function* watchGetAllStatesData() {
  yield takeLatest(getAllStates.actionName, loadGetAllStatesData);
}

export default {
  watchGetStatesData,
  watchGetAllStatesData,
};
