import {
  sendTestEmail,
  getTestEmailDeal,
} from 'actions/test-email';

const testEmail = () => {
  const initialState = {
    testEmailPostError: false,
    testEmailPostRequestLoading: false,
    testEmailGetError: false,
    testEmailGetRequestLoading: false,
    testEmailDealData: null,
  };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case sendTestEmail.requestTypes.LOAD: {
        return {
          ...state,
          testEmailPostError: false,
          testEmailPostRequestLoading: true,
        };
      }

      case sendTestEmail.requestTypes.REQUEST: {
        return {
          ...state,
          testEmailPostError: false,
          testEmailPostRequestLoading: true,
        };
      }

      case sendTestEmail.requestTypes.SUCCESS: {
        // sendgrid will return 202 when success
        if (response && response[0] && response[0].statusCode === 202) {
          return {
            ...state,
            testEmailPostRequestLoading: false,
            testEmailPostError: false,
          };
        }
        return {
          ...state,
          testEmailPostRequestLoading: false,
          testEmailPostError: true,
        };
      }

      case sendTestEmail.requestTypes.FAILURE: {
        return {
          ...state,
          testEmailPostError: true,
          testEmailPostRequestLoading: false,
        };
      }

      case getTestEmailDeal.requestTypes.LOAD: {
        return {
          ...state,
          testEmailGetError: false,
          testEmailGetRequestLoading: true,
        };
      }

      case getTestEmailDeal.requestTypes.REQUEST: {
        return {
          ...state,
          testEmailGetError: false,
          testEmailGetRequestLoading: true,
        };
      }

      case getTestEmailDeal.requestTypes.SUCCESS: {
        return {
          ...state,
          testEmailGetError: false,
          testEmailGetRequestLoading: false,
          testEmailDealData: response,
        };
      }

      case getTestEmailDeal.requestTypes.FAILURE: {
        return {
          ...state,
          testEmailGetError: true,
          testEmailGetRequestLoading: false,
        };
      }

      default: {
        return {
          ...state,
        };
      }
    }
  };
};

export default testEmail();
