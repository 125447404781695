import styled from 'styled-components';
import propTypes from 'prop-types';

const DataSection = styled.div`
  padding-top: ${(props) => `${props.top}px`};
  padding-bottom: ${(props) => `${props.bottom}px`};
  padding-right: ${(props) => `${props.left}px`};
  padding-left: ${(props) => `${props.right}px`};
`;

DataSection.propTypes = {
  top: propTypes.number,
  bottom: propTypes.number,
  left: propTypes.number,
  right: propTypes.number,
};

DataSection.defaultProps = {
  top: 40,
  bottom: 40,
  left: 0,
  right: 0,
};

/** @component */
export default DataSection;
