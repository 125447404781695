import client from './main-client';

export const importPortfolioApi = async (data) => {
  try {
    const formData = new FormData();
    formData.append('userId', data.userId);
    formData.append('file', data.file);
    await client({ 'Content-type': 'application/x-www-form-urlencoded' }).post('/loans', formData);
  } catch (error) {
    // ignore error
  }
};

export const importBorrowersApi = (data) => {
  const formData = new FormData();
  formData.append('file', data.file);
  return client({ 'Content-type': 'application/x-www-form-urlencoded' }).post('owners', formData);
};
