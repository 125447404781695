/* eslint-disable camelcase */
import React from 'react';
import styled, { css } from 'styled-components';
import { InputNumber as input } from 'antd';
import propTypes from 'prop-types';
import { formatter, parser } from 'utils/numberInputFormatter';
import reactComponentDebounce from 'react-component-debounce';

const StyledInputDef = styled(input)`
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  border-radius: 4px !important;
  background-color: #ffffff !important;
  height: 40px !important;
  ${(props) => props.block
    && css`
    width: 100% !important;
    `}
`;

/* NO-537 - Resolves the performance issue associated with slow typing in any input fields on large forms */
const StyledInput = reactComponentDebounce({
  valuePropName: 'value',
  triggerMs: 2500,
})(StyledInputDef);

/** @component */
const DebouncedInputNumber = (props) => {
  const {
    prefix, postfix, money_format, ...otherProps
  } = props;
  return <StyledInput
    formatter={(val) => formatter(val, { prefix, postfix, money_format })}
    parser={(val) => parser(val, { prefix, postfix, money_format })}
    {...otherProps} />;
};

DebouncedInputNumber.propTypes = {
  block: propTypes.bool,
};

DebouncedInputNumber.defaultProps = {
  block: false,
};

export default DebouncedInputNumber;
