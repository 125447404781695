import { makeRequestAction } from './index';

export const getAllListingLeadsNotes = makeRequestAction('GET_LISTING_COLLIERS', {
  onSuccess(params, response) {
    return {
      response,
    };
  },
});

export const addListingLeadsNote = makeRequestAction('ADD_LISTING_NOTE', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const updateListingNote = makeRequestAction('UPDATE_LISTING_NOTE', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const deleteListingNote = makeRequestAction('DELETE_LISTING_NOTE', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const getRealtorListingNotes = makeRequestAction('GET_RELATOR_NOTES', {
  onSuccess(params, response) {
    return {
      response,
    };
  },
});
