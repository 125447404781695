import { fetchLenders } from 'actions/lenders-with-categories';

const lendersWithCategories = () => {
  const initialState = {
    lenders: null,
  };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case fetchLenders.requestTypes.SUCCESS:
        return {
          ...state,
          lenders: response,
        };
      default:
        return state;
    }
  };
};

export default lendersWithCategories();
