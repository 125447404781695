import { makeRequestAction } from './index';

export const getAllUserCategories = makeRequestAction('GET_CATEGORIES', {
  onSuccess(params, response) {
    return {
      response,
      // response: {
      //   entities: {
      //     documentsData: response,
      //   },
      // },
    };
  },
});

export const addCategory = makeRequestAction('ADD_CATEGORY', {
  onSuccess(params, response) {
    return {
      response,
      // response: {
      //   entities: {
      //     documentsData: response,
      //   },
      // },
    };
  },
});

export const removeCategory = makeRequestAction('REMOVE_CATEGORY', {
  onSuccess(params, response) {
    return {
      response,
      // response: {
      //   entities: {
      //     documentsData: response,
      //   },
      // },
    };
  },
});
