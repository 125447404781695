import React, { useState } from 'react';
import {
  Modal, Row, DatePicker,
} from 'antd';
// import moment from 'moment';

function PitchDateTime({
  updateLeadStage,
  dateTimeRecord,
  setDateTimeRecord,
  handleStatusChange,
  updateCrmStage,
  ownerValues,
  setOwnerValues,
  companyData,
  setCompanyData,
}) {
  const [time, setTime] = useState(null);

  const handleCancel = () => {
    if (dateTimeRecord.type === 'Edit Owner') {
      setOwnerValues({
        ...dateTimeRecord.record,
        contactStatus: 'mandated',
        stageChanged: true,
      });
    }
    setTime(null);
    setDateTimeRecord(null);
  };

  const handleOk = () => {
    if (dateTimeRecord?.type === 'expiries') {
      updateLeadStage('pitch sch.', dateTimeRecord?.record._id, dateTimeRecord?.record.ownerId, dateTimeRecord?.record, time);
    } else if (dateTimeRecord?.type === 'listings') {
      handleStatusChange('pitch sch.', dateTimeRecord?.record, 'stage');
    } else if (dateTimeRecord?.type === 'crm') {
      updateCrmStage(dateTimeRecord?.record, 'pitch sch.', time, dateTimeRecord?.record);
      const newData = companyData.map((company) => (company._id === dateTimeRecord?.record?._id ? { ...company, pitchTime: time } : company));
      setCompanyData(newData);
    }

    setTime(null);
    setDateTimeRecord(null);
  };

  return (
    <Modal
      // title={'Set Date & Time'}
      visible={dateTimeRecord !== null}
      // width={type === 'crm' ? '70vw' :'80vw'}
      onCancel={handleCancel}
      okButtonProps={{ disabled: !time }}
      onOk={handleOk}
      destroyOnClose
    >
      <div style={{ padding: '15px' }}>
        <Row >
          Please set a Date and Time for the pitch.
        </Row>
        <Row>
          <DatePicker
            placeholder='Select Date & Time'
            onChange={(e) => setTime(e)}
            showTime={{
              format: 'HH:mm',
              minuteStep: 15,
            }}
            format='YYYY-MM-DD HH:mm'
          />
        </Row>
      </div>

    </Modal>
  );
}

export default PitchDateTime;
