import { call, takeLatest } from 'redux-saga/effects';
import { addBlanketValues, updateBlanketValues, blanketsValues } from 'actions/blankets';
import { updateBlanketApi, addBlanketApi, blanketsApi } from 'services/blankets';
import fetchEntity from './fetch-entity';

const fetchBlanketsValues = fetchEntity.bind(null, blanketsValues.actions, blanketsApi);

export function* loadBlankets({ params }) {
  yield call(fetchBlanketsValues, { ...params });
}

function* watchBlanketsValues() {
  yield takeLatest(blanketsValues.actionName, loadBlankets);
}

const addBlanketPost = fetchEntity.bind(null, addBlanketValues.actions, addBlanketApi);

export function* addBlanket({ params }) {
  yield call(addBlanketPost, { ...params });
}

function* watchAddBlanket() {
  yield takeLatest(addBlanketValues.actionName, addBlanket);
}

const updateBlanketPost = fetchEntity.bind(null, updateBlanketValues.actions, updateBlanketApi);

export function* updateBlanket({ params }) {
  yield call(updateBlanketPost, { ...params });
}

function* watchUpdateBlanket() {
  yield takeLatest(updateBlanketValues.actionName, updateBlanket);
}

export default {
  watchBlanketsValues,
  watchAddBlanket,
  watchUpdateBlanket,
};
