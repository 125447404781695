import React, { Fragment } from 'react';
import { Upload, Icon } from 'antd';

const EmailUploader = (props) => {
  const {
    changeState,
    fileList = [],
    progress = {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      strokeWidth: 3,
      format: (percent) => `${parseFloat(percent.toFixed(2))}%`,
    },
  } = props;

  const addFile = (file, selectedFileList) => {
    const files = [...fileList, ...selectedFileList];
    changeState && changeState(files, 'addFile', selectedFileList);
    return false;
  };

  const deleteFile = (file) => {
    const files = fileList.filter((f) => (
      // eslint-disable-next-line
      f.hasOwnProperty('hashedId') ? f.hashedId !== file.hashedId : f.uid !== file.uid));
    changeState && changeState(files, 'deleteFile', file);
  };

  return (
    <Fragment>
      <Upload.Dragger
        name='file'
        fileList={fileList}
        beforeUpload={addFile}
        onRemove={deleteFile}
        progress={progress}
        multiple
            >
        <p className='ant-upload-drag-icon'> <Icon type='inbox' /> </p>
        <p className='ant-upload-text'> Click or drag file to this area to upload </p>
        <p className='ant-upload-hint'>
          <span />
        </p>
      </Upload.Dragger>
    </Fragment>
  );
};

export default EmailUploader;
