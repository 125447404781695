import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import { MIXPANEL_PROJECT_TOKEN } from 'configs/env-vars';

mixpanel.init(MIXPANEL_PROJECT_TOKEN, { debug: true });

const AttachmentItem = ({
  item, bidId, dealId, sessionId, previewType,
}) => {
  const id = `attachment-${item.uid}`;
  useEffect(() => {
    if (previewType === 'guest' && dealId && bidId) {
      mixpanel.track_links(`#${id}`, 'Attachment download', {
        bidId,
        dealId,
        sessionId,
      });
    }
  }, [id, previewType, dealId, bidId, sessionId]);

  return (
    previewType === 'guest'
      ? <Link
        id={id}
        to={{ pathname: item.url }}
      >
        {item.name}
      </Link>
      : <a href={item.url}>{item.name}</a>

  );
};

export default AttachmentItem;
