import {
  fetchLenders,
  getLender,
  updateLender,
  addLenderLogo,
} from 'actions/lenders';
import { createSelector } from 'reselect';
import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';

export const getLendersState = (state) => state.lenders;
export const getLenders = (state) => state.lenders.lenders;
export const lendersValuesLoading = createLoadingSelector(fetchLenders.actionName)();
export const lendersValuesError = createErrorSelector(fetchLenders.actionName)();

export const getLenderState = (state) => state.lenders.selectedLender;
export const lenderValuesLoading = createLoadingSelector(getLender.actionName)();
export const lenderValuesError = createErrorSelector(getLender.actionName)();

export const getUpdatedLender = (state) => state.lenders.updatedLender;
export const updatedLenderError = createErrorSelector(updateLender.actionName)();
export const updatedLenderLoading = createLoadingSelector(updateLender.actionName)();

export const getUploadsState = (state) => state.uploads;
export const getLenderLogo = createSelector(getUploadsState, (uploads) => uploads[addLenderLogo.actionName]);
export const lenderLogoLoading = createLoadingSelector(addLenderLogo.actionName)();
