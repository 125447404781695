import {
  fetchDealsConsultantBids, createBid, updateBid, deleteBid, patchBid,
} from 'actions/deals-consultant-bids';

const dealsConsultantBids = () => {
  const initialState = {};

  return (state = initialState, action) => {
    const { type, response, ids } = action;
    switch (type) {
      case fetchDealsConsultantBids.requestTypes.SUCCESS:
        return {
          ...response,
        };
      case deleteBid.requestTypes.SUCCESS:
        return {
          ...state,
          total: state.total - ids.length,
        };
      case createBid.requestTypes.REQUEST:
      case updateBid.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case patchBid.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      default:
        return state;
    }
  };
};

export default dealsConsultantBids();
