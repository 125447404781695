import { call, takeLatest, select } from 'redux-saga/effects';
import { fetchLenderContacts, addNewContact, editContact } from 'actions/lender-contacts';
import { fetchLenderContactsApi, addNewContactApi, editContactApi } from 'services/lender-contacts';
import { getUserData } from 'selectors/users';
import fetchEntity from './fetch-entity';

const fetchLenderContactsData = fetchEntity.bind(
  null,
  fetchLenderContacts.actions,
  fetchLenderContactsApi,
);

export function* loadFetchLenderData({ params }) {
  yield call(fetchLenderContactsData, { ...params });
}

function* watchFetchLenderContacsData() {
  yield takeLatest(fetchLenderContacts.actionName, loadFetchLenderData);
}

const fetchAddNewContact = fetchEntity.bind(
  null,
  addNewContact.actions,
  addNewContactApi,
);

export function* loadAddNewContact({ params }) {
  const userData = yield select(getUserData);
  yield call(fetchAddNewContact, { ...params, createdBy: userData.id });
}

function* watchAddNewContact() {
  yield takeLatest(addNewContact.actionName, loadAddNewContact);
}

const fetchEditContact = fetchEntity.bind(
  null,
  editContact.actions,
  editContactApi,
);

export function* loadEditContact({ params }) {
  yield call(fetchEditContact, { ...params });
}

function* watchEditContact() {
  yield takeLatest(editContact.actionName, loadEditContact);
}

export default {
  watchFetchLenderContacsData,
  watchAddNewContact,
  watchEditContact,
};
