import addMonths from 'date-fns/addMonths';
import startOfMonth from 'date-fns/startOfMonth';
import getDaysInMonth from 'date-fns/getDaysInMonth';
import endOfMonth from 'date-fns/endOfMonth';

export function monthAdder(firstPaymentDate, term) {
  if (
    firstPaymentDate.getDate()
      > getDaysInMonth(addMonths(startOfMonth(firstPaymentDate), term))
  ) {
    return endOfMonth(addMonths(startOfMonth(firstPaymentDate), term));
  }
  return addMonths(firstPaymentDate, term);
}

export function findCurrentPaymentPeriod(firstPaymentDate) {
  let monthDifference = new Date().getMonth() - new Date(firstPaymentDate).getMonth()
    + (new Date().getFullYear() - new Date(firstPaymentDate).getFullYear()) * 12;

  if (new Date().getDate() < new Date(firstPaymentDate).getDate()) { monthDifference -= 1; }

  // return 0;
  return monthDifference;
}
