import { makeRequestAction } from './index';

export const getBidEmails = makeRequestAction('GET_BID_EMAILS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const getBidEmailsById = makeRequestAction('GET_BID_EMAILS_BY_ID', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const composeBidEmails = makeRequestAction('COMPOSE_BID_EMAIL', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const replyBidEmails = makeRequestAction('REPLY_BID_EMAIL', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const updateFavouriteThreads = makeRequestAction('UPDATE_FAVOURITE_TRUE', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const updateOpenedMessage = makeRequestAction('UPDATE_OPENED_MESSAGE_STATUS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const updateFavouriteMessage = makeRequestAction('UPDATE_FAVOURITE_MESSAGE', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const saveDraftEmail = makeRequestAction('SAVE_DRAFT', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const updateSavedDraftEmail = makeRequestAction('UPDATE_SAVE_DRAFT_EMAIL', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const deleteDraftEmail = makeRequestAction('DELETE_DRAFT_EMAIL', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const deleteEmail = makeRequestAction('DELETE_EMAIL', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const downloadFile = makeRequestAction('DOWNLOAD_UPLOADED_FILE', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const updateDraftAttachments = makeRequestAction('UPDATE_DRAFT_ATTACHMENTS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const updateDeleteDraftAttachments = makeRequestAction('UPDATE_DELETE_DRAFT_ATTACHMENTS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});
