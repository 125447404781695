import styled from 'styled-components';
import { Collapse as collapse } from 'antd';

const Collapse = styled(collapse)`
  > .ant-collapse-item > .ant-collapse-header {
    box-shadow: none !important;
  }
`;

/** @component */
export default Collapse;
