import { editRevenue } from 'actions/revenue';

const revenue = () => {
  const initialState = {
    data: null,
  };
  return (state = initialState, { type, response }) => {
    switch (type) {
      case editRevenue.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case editRevenue.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case editRevenue.requestTypes.SUCCESS:
        return {
          ...state,
          data: response,
          isSuccessful: true,
        };

      default:
        return state;
    }
  };
};

export default revenue();
