import { makeRequestAction } from './index';

export const openContactsModal = makeRequestAction('OPEN_CONTACTS_MODAL', {
  advanced: false,
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});
