import { all, fork } from 'redux-saga/effects';
import users from './users';
import fetchConsultantsCategories from './consultant-categories';
import fetchConsultant from './consultants';

import lendersWithCategories from './lenders-with-categories';
import lenderContacts from './lender-contacts';
import stateProvince from './state-province';
import bonds from './bonds';
import rates from './rates';
import services from './services';
import propertyTypes from './property-types';
import propertyClasses from './property-classes';
import lines from './lines';
import loans from './loans';
import properties from './properties';
import blankets from './blankets';
import portfolios from './portfolios';
import lenderCategory from './lenderCategory';
import deals from './deals';

import dealsLenderBids from './deals-lender-bids';
import lenders from './lenders';
import userCompanies from './user-companies';
import formBuilder from './form-builder';
import loanTypes from './loan-types';
import markets from './markets';
import territories from './territories';
import dealEmail from './deal-email';
import leadsEmail from './leads-email';
import emailTemplates from './email-templates';
import guestDeal from './guest-deal';
import importData from './importData';
import borrowerNotes from './borrower-notes';
import inboxEmails from './inbox-emails';
import dealsConsultantBids from './deals-consultant-bids';
import specDocs from './specDocs';
import userTarget from './user-target';
import revenue from './revenue';
import userSettings from './user-settings';
import emailDrafts from './email-drafts';
import confAgreement from './conf-agreement';
import fetchcollersFinneo from './fetch-collersFinneo-leads';
import consultantContacts from './consultant-contact';
import searchLeads from './search-leads';
import dashboardCompanies from './dashboard-companies';
import bidEmails from './bids-email';
import documentsUploader from './documents-uploader';
import documentCategories from './document-categories';
import testEmail from './test-email';
import construction from './construction';
import preFundingData from './preFundingTracker';
import colliersNotes from './colliers-notes';
import reporting from './reporting';
import annualReports from './annual-reports';
import collaborator from './collaborator';
import listingLeads from './listingLeads';
import listingLeadsNotes from './listingLeads-notes';
import dealRevenue from './deal-revenue';
import lenderLoans from './lender-loans';
import owners from './owners';
import ownerContacts from './owner-contacts';
import ownerNotes from './owner-notes';
import myClients from './my-clients';
import brokerContacts from './broker-contacts';
import brokerCompanies from './broker-companies';
import survey from './survey';
import lenderAnalytics from './lender-analytics';
import lenderDeals from './lender-deals';

const combinedSagas = {
  ...users,
  ...fetchConsultantsCategories,
  ...fetchConsultant,
  ...lendersWithCategories,
  ...deals,
  ...dealsLenderBids,
  ...lenderContacts,
  ...stateProvince,
  ...bonds,
  ...rates,
  ...services,
  ...propertyTypes,
  ...propertyClasses,
  ...lines,
  ...loans,
  ...properties,
  ...blankets,
  ...portfolios,
  ...lenderCategory,
  ...lenders,
  ...userCompanies,
  ...formBuilder,
  ...loanTypes,
  ...markets,
  ...propertyClasses,
  ...territories,
  ...dealEmail,
  ...leadsEmail,
  ...reporting,
  ...emailTemplates,
  ...guestDeal,
  ...importData,
  ...borrowerNotes,
  ...inboxEmails,
  ...dealsConsultantBids,
  ...specDocs,
  ...userTarget,
  ...revenue,
  ...userSettings,
  ...emailDrafts,
  ...confAgreement,
  ...fetchcollersFinneo,
  ...consultantContacts,
  ...searchLeads,
  ...dashboardCompanies,
  ...bidEmails,
  ...documentsUploader,
  ...documentCategories,
  ...testEmail,
  ...construction,
  ...preFundingData,
  ...colliersNotes,
  ...annualReports,
  ...collaborator,
  ...listingLeads,
  ...listingLeadsNotes,
  ...dealRevenue,
  ...lenderLoans,
  ...owners,
  ...ownerContacts,
  ...ownerNotes,
  ...myClients,
  ...brokerContacts,
  ...brokerCompanies,
  ...survey,
  ...lenderAnalytics,
  ...lenderDeals,
};

export default function* rootSaga() {
  yield all(Object.values(combinedSagas).map(fork));
}
