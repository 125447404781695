import { getLoanTypes } from 'actions/loan-types';

const loanTypes = () => {
  const initialState = {};

  return (state = initialState, { type, response }) => {
    switch (type) {
      case getLoanTypes.requestTypes.SUCCESS:
        return {
          ...response,
        };
      default:
        return state;
    }
  };
};

export default loanTypes();
