import { call, takeLatest } from 'redux-saga/effects';
import { addRevenue, editRevenue } from 'actions/revenue';
import { addRevenueApi, editRevenueApi } from 'services/revenue';
import fetchEntity from './fetch-entity';

const addRevenuePost = fetchEntity.bind(null, addRevenue.actions, addRevenueApi);

const editRevenuePost = fetchEntity.bind(null, editRevenue.actions, editRevenueApi);

export function* addNewRevenue({ params }) {
  yield call(addRevenuePost, { ...params });
}

function* watchAddNewRevenue() {
  yield takeLatest(addRevenue.actionName, addNewRevenue);
}

export function* editExistingRevenue({ params }) {
  yield call(editRevenuePost, { ...params });
}

function* watchEditExistingRevenue() {
  yield takeLatest(editRevenue.actionName, editExistingRevenue);
}

export default {
  watchAddNewRevenue,
  watchEditExistingRevenue,
};
