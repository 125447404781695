import { makeRequestAction } from './index';

export const getOwner = makeRequestAction('GET_OWNER', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const getOwners = makeRequestAction('GET_OWNERS', {
  onSuccess(params, response) {
    return {
      response: [
        ...response,
      ],
    };
  },
});

export const getSimilarOwners = makeRequestAction('GET_SIMILAR_OWNERS', {
  onSuccess(params, response) {
    return {
      response: [
        ...response,
      ],
    };
  },
});

export const addOwner = makeRequestAction('ADD_OWNER', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const updateOwner = makeRequestAction('UPDATE_OWNER', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const deleteOwner = makeRequestAction('DELETE_OWNER', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const getScheduleBClients = makeRequestAction('GET_SCHEDULE_B_CLIENTS', {
  onSuccess(params, response) {
    return {
      response: [
        ...response,
      ],
    };
  },
});

export const getFinneoClients = makeRequestAction('GET_FINNEO_CLIENTS', {
  onSuccess(params, response) {
    return {
      response: [
        ...response,
      ],
    };
  },
});

export const bulkAssignManagers = makeRequestAction('BULK_ASSIGN_MANAGERS', {
  onSuccess(params, response) {
    return {
      response,
    };
  },
});
