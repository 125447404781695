import styled, { css } from 'styled-components';
import { Tooltip as tooltip } from 'antd';

const Tooltip = styled(tooltip)`
    ${(props) => props.theme === 'info' && css`
    .ant-tooltip-inner {
        color: yellow;
        background-color: green;
        width: 1000px;
      }
    `}
`;

export default Tooltip;
