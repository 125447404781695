import React, { useState, useEffect } from 'react';
import { Layout, notification, Icon } from 'antd';
import { UserSettings, Content, PageHeader } from 'components';
import { MainMenu } from 'components/Partials';
import Drift from 'react-driftjs';
import { withRouter } from 'react-router-dom';
import LogoutButton from 'containers/Authorization/LogoutButton';

// import Breadcrumbs from 'components/breadcrumbs';

// Manager Dashboard
const DashboardLayout = ({ history, location, children }) => {
  const [notificationActive, setNotificationActive] = useState(false);
  const isDashboardPage = location.pathname === '/';
  const currentItem = '/';
  const locationHistory = JSON.parse(localStorage.getItem('locationHistory')) || {};

  if (Object.keys(locationHistory).length > 0 && locationHistory.prev.includes('/lenders/list') && history.action === 'PUSH') {
    localStorage.removeItem('lenderTabsActive');
  }

  const handleCloseNotification = () => {
    const currentDate = new Date();
    const futureDate = new Date(currentDate);
    futureDate.setDate(currentDate.getDate() + 4);

    // Convert the future date to a string and save it to localStorage
    // localStorage.setItem('futureDate', futureDate.toISOString());
    setNotificationActive(false);
  };

  const openNotification = () => {
    const args = {
      message: 'Did you know?',
      description:
  <div>
    <p> You can click on <Icon style={{ fontSize: '16px', color: '#eb5119' }} type='info-circle' /> for contextual infomation.

    </p>
  </div>,
      duration: 0,
      onClose: () => { handleCloseNotification(); },
    };
    // useEffect may force more than one notification; to avoid it we created notificationActive(BOOLEAN) state
    // which will run the following block once
    if (!notificationActive) {
      notification.open(args);
      setNotificationActive(true);
    }
  };

  useEffect(() => {
    // Uncomment the code BELOW && IN CLOSE NOTIFICATION FUNCTION to have the notification open after every 4 days after closing
    // const storedFutureDate = localStorage.getItem('futureDate') ? new Date(localStorage.getItem('futureDate')) : null
    // const currentDate = new Date();

    // if (currentDate.toDateString() >= storedFutureDate?.toDateString() || !storedFutureDate) {

    location.pathname === '/deals/email' && openNotification();
    // }

    // eslint-disable-next-line
  },[location])

  useEffect(() => {
    locationHistory.prev = locationHistory.curr || location.pathname;
    locationHistory.curr = location.pathname;

    localStorage.setItem('locationHistory', JSON.stringify(locationHistory));
    // eslint-disable-next-line
  }, [location]);

  return (
    <Layout style={{ height: '100vh' }}>
      <MainMenu />
      <Layout>
        <PageHeader
          {...(!isDashboardPage && { onBack: () => history.goBack() })}
          title={currentItem.name}
          subTitle={!isDashboardPage && ''}
          // title={<Breadcrumbs breadCrumbs={breadCrumbs} />}
          extra={[
            <UserSettings
              key={'userSttings'}
              changePath={(item) => {
                history.push(item.path);
              }}
              logoutButton={<LogoutButton />}
            />,
          ]}
        />
        <Content>{children}</Content>
      </Layout>
      {history.location.pathname !== '/inbox' && <Drift appId='sbtsvm8x955a' />}
    </Layout>
  );
};
export default withRouter(DashboardLayout);
