import { makeRequestAction } from './index';

export const getPreFundingData = makeRequestAction('GET_PREFUNDING_DATA', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const addPreFundingData = makeRequestAction('ADD_PREFUNDING_DATA', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const updatePreFundingData = makeRequestAction('UPDATE_PREFUNDING_DATA', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const deletePreFundingData = makeRequestAction('DELETE_PREFUNDING_DATA', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const resetPreFundingData = makeRequestAction('RESET_PREFUNDING_DATA', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const getPreFundingCountData = makeRequestAction('GET_PREFUNDING_COUNT', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const addSyndicateLendersData = makeRequestAction('ADD_SYNDICATE_LENDERS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const generatePrefundingTokenData = makeRequestAction('GENERATE_PREFUNDING_TOKEN', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const getShareableLinkData = makeRequestAction('GET_SHAREABLE_LINK_DATA', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const deleteFileData = makeRequestAction('DELETE_FILE_DATA', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});
