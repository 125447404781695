import { makeRequestAction } from './index';

export const addOwnerNote = makeRequestAction('ADD_OWNER_NOTE', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const updateOwnerNote = makeRequestAction('UPDATE_OWNER_NOTE', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const deleteOwnerNote = makeRequestAction('DELETE_OWNER_NOTE', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const getOwnerNotes = makeRequestAction('GET_OWNER_NOTES', {
  onSuccess(params, response) {
    return {
      response: [
        ...response,
      ],
    };
  },
});
