import { getStates, getAllStates } from 'actions/state-province';

const states = () => (state = {
  states: null,
  allStates: [],
}, action) => {
  switch (action.type) {
    case getStates.requestTypes.SUCCESS:
      return {
        ...state,
        states: action.response,
      };
    case getAllStates.requestTypes.SUCCESS:
      return {
        ...state,
        allStates: action.response.data,
      };
    default:
      return state;
  }
};

export default states();
