import {
  call, takeLatest,
} from 'redux-saga/effects';

import { addSurvey, getSurvey } from 'actions/survey';
import { addSurveyApi, getSurveyApi } from 'services/survey';
import fetchEntity from './fetch-entity';

const fetchAddSurvey = fetchEntity
  .bind(null, addSurvey.actions, addSurveyApi);

export function* loadAddSurveyData({ params }) {
  yield call(fetchAddSurvey, params);
}

function* watchAddSurveyData() {
  yield takeLatest(addSurvey.actionName, loadAddSurveyData);
}

const fetchGetSurvey = fetchEntity.bind(
  null,
  getSurvey.actions,
  getSurveyApi,
);

export function* loadGetSurveyData({ params }) {
  yield call(fetchGetSurvey, params);
}

function* watchLoadSurveyData() {
  yield takeLatest(getSurvey.actionName, loadGetSurveyData);
}

export default {
  watchAddSurveyData,
  watchLoadSurveyData,
};
