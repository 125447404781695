import merge from 'deepmerge';
import { combineReducers } from 'redux';

const properties = () => (
  state = {
    // inital states here
    propertiesData: null,
    createdProperty: null,
  },
  action,
) => {
  if (action.type === 'PROPERTIES_SUCCESS' && action.response.entities) {
    return merge.all([state, action.response.entities], {
      clone: false,
      arrayMerge: (destinationArray, sourceArray) => sourceArray,
    });
  }
  if (action.type === 'ADD_PROPERTY_SUCCESS' && action.response.entities) {
    return {
      ...state,
      createdProperty: action.response.entities,
    };
  }

  return state;
};

export default combineReducers({
  properties: properties(),
});
