import { makeRequestAction } from './index';

export const fetchDealsConsultantBids = makeRequestAction('FETCH_DEALS_CONSULTANT_BIDS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const createBid = makeRequestAction('CREATE_DEAL_CONSULTANT_BIDS');
export const updateBid = makeRequestAction('UPDATE_DEAL_CONSULTANT_BIDS');
export const patchBid = makeRequestAction('PATCH_DEAL_CONSULTANT_BIDS');

export const deleteBid = makeRequestAction('DELETE_DEAL_CONSULTANT_BIDS', {
  onSuccess(params, response) {
    return {
      ids: response.map((len) => len.id),
    };
  },
});
