import styled, { css } from 'styled-components';
import { List } from 'antd';

const CompanyListItem = styled(List.Item)`
  padding: 2px 6px !important;
  border: 1px solid #e0e0e0 !important;
  border-radius: 2px;
  background-color: #ffffff;
  height: 24px;
  // line-height: 10px;
  margin-bottom: 10px;
  a {
    height: 14px;
    color: #000000;
    font-family: Roboto;
    font-size: 0.75rem;
    line-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    width: 80%;
    white-space: nowrap;
  }
  .ant-checkbox
  {
    left: -7px;
  }
  .ant-checkbox-inner
  {
    width: 24px;
    height: 24px;

  }
  ${(props) => props.match === true
  && css`
    .ant-checkbox-inner
    {
      width: 24px;
      height: 24px;
      border: 1px solid #4962F5 !important;
    }
    border: 1px solid #4962F5 !important;
    a > span
    {
      color: #4962F5 !important;
    }
  `};
  ${(props) => props.submitted === true
    && css`
      & > span {
        color: red !important;
      }
    `};
`;

export default CompanyListItem;
