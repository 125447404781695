import { fetchLenderAnalytics } from 'actions/lender-analytics';

const lenderAnalytics = () => {
  const initialState = {
    data: null,
  };
  return (state = initialState, { type, response }) => {
    switch (type) {
      case fetchLenderAnalytics.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case fetchLenderAnalytics.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case fetchLenderAnalytics.requestTypes.SUCCESS:
        return {
          ...state,
          data: response,
          isSuccessful: true,
        };

      default:
        return state;
    }
  };
};

export default lenderAnalytics();
