import client from './main-client';
import mailClient from './mail-client';

export const getBidEmailsApi = (params = {}) => client().get(`/inbox?dealId=${params.dealId}${params.isAdmin ? '&isAdmin=true' : ''}`);

export const getBidEmailsByIdApi = (data) => client().get(`/inbox?id=${data.chatId}`, data);

export const composeEmailApi = (data) => {
  const formData = new FormData();
  data.files && data.files.length > 0 && Array.from(data.files).forEach((file) => {
    formData.append('file', file);
  });
  formData.append('reqData', JSON.stringify(data));
  client({ 'Content-type': 'application/x-www-form-urlencoded' }).post('/inbox?compose=new', formData);
};

export const deleteEmailApi = (data) => client().delete(`/inbox/${data.messageId}?chatId=${data.chatId}&delType=message`);

export const replyEmailApi = (data) => {
  const formData = new FormData();
  data.files && data.files.length > 0 && Array.from(data.files).forEach((file) => {
    formData.append('file', file);
  });
  formData.append('reqData', JSON.stringify(data));
  client({ 'Content-type': 'application/x-www-form-urlencoded' }).post('/inbox?compose=reply', formData);
};

export const updateFavouriteThreadsApi = (data) => {
  client().post(`/inbox?favourite=${data.params.favourite}&chatId=${data.params.chatId}`);
};

export const updateOpenedMessageApi = (data) => {
  if (data?.params) return client().post(`/inbox?opened=${data?.params?.opened}&messageId=${data?.params?.id}&dealId=${data?.params?.dealId}`);
  return client().post(`/inbox?opened=${data?.opened}&messageId=${data?.id}&dealId=${data?.dealId}`);
};

export const updateFavouriteMessageApi = (data) => {
  client().patch(`/inbox/${data.params.id}?favourite=${data.params.favourite}`);
};

export const saveDraftEmailApi = (data) => {
  const formData = new FormData();
  data.files && data.files.length > 0 && Array.from(data.files).forEach((file) => {
    formData.append('file', file);
  });

  formData.append('reqData', JSON.stringify(data));

  client({ 'Content-type': 'application/x-www-form-urlencoded' }).post(`/inbox?dealId=${data.dealId}&compose=newDraft`, formData);
};

export const updateSavedDraftEmailApi = (data) => {
  const formData = new FormData();
  data.files && data.files.length > 0 && Array.from(data.files).forEach((file) => {
    formData.append('file', file);
  });
  formData.append('reqData', JSON.stringify(data));
  client({ 'Content-type': 'application/x-www-form-urlencoded' }).post(`/inbox?dealId=${data.dealId}&compose=updateDraft`, formData);
};

export const deleteDraftEmailApi = (data) => {
  client().delete(`/inbox/${data.messageId}?chatId=${data.chatId}&delType=message`);
};

export const downloadUploadedFilesApi = (data) => {
  mailClient().get(`files/${data.params.hashedId}`);
};

export const updateSavedDraftAttachmentApi = (data) => {
  const formData = new FormData();
  data.files && data.files.length > 0 && Array.from(data.files).forEach((file) => {
    formData.append('file', file);
  });
  formData.append('reqData', JSON.stringify(data));
  client({ 'Content-type': 'application/x-www-form-urlencoded' }).post('/inbox?create=newfile', formData);
};

export const deleteDraftAttachmentEmailApi = (data) => {
  client().delete(`/inbox/${data.params.hashedId}?message=${data.params.messageId}`);
};
