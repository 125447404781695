import {
  call, takeLatest,
} from 'redux-saga/effects';

import {
  addBrokerContact,
  getBrokerContact,
  getBrokerContacts,
  updateBrokerContact,
  bulkUpdateManager,
} from 'actions/broker-contacts';
import {
  addBrokerContactApi,
  getBrokerContactApi,
  getBrokerContactsApi,
  updateBrokerContactApi,
  bulkUpdateManagerApi,
} from 'services/broker-contacts';

import fetchEntity from './fetch-entity';

const fetchAddBrokerContact = fetchEntity.bind(null, addBrokerContact.actions, addBrokerContactApi);

export function* loadAddBrokerContact({ params }) {
  yield call(fetchAddBrokerContact, params);
}

function* watchAddBrokerContact() {
  yield takeLatest(addBrokerContact.actionName, loadAddBrokerContact);
}

const fetchGetBrokerContact = fetchEntity.bind(null, getBrokerContact.actions, getBrokerContactApi);

export function* loadGetBrokerContact({ params }) {
  yield call(fetchGetBrokerContact, params);
}

function* watchGetBrokerContact() {
  yield takeLatest(getBrokerContact.actionName, loadGetBrokerContact);
}

const fetchGetBrokerContacts = fetchEntity.bind(null, getBrokerContacts.actions, getBrokerContactsApi);

export function* loadGetBrokerContacts({ params }) {
  yield call(fetchGetBrokerContacts, params);
}

function* watchGetBrokerContacts() {
  yield takeLatest(getBrokerContacts.actionName, loadGetBrokerContacts);
}

const fetchUpdateBrokerContact = fetchEntity.bind(null, updateBrokerContact.actions, updateBrokerContactApi);

export function* loadUpdateBrokerContact({ params }) {
  yield call(fetchUpdateBrokerContact, params);
}

function* watchUpdateBrokerContact() {
  yield takeLatest(updateBrokerContact.actionName, loadUpdateBrokerContact);
}

const fetchBulkUpdate = fetchEntity.bind(null, bulkUpdateManager.actions, bulkUpdateManagerApi);

export function* loadBulkUpdate({ params }) {
  yield call(fetchBulkUpdate, params);
}

function* watchBulkUpdate() {
  yield takeLatest(bulkUpdateManager.actionName, loadBulkUpdate);
}

export default {
  watchAddBrokerContact,
  watchGetBrokerContact,
  watchGetBrokerContacts,
  watchUpdateBrokerContact,
  watchBulkUpdate,
};
