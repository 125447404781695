/* eslint-disable camelcase */
export const formatter = (value, options) => {
  if (!value || !options) return value;
  let returnValue = value.toString();

  const {
    prefix, postfix, moneyFormat, money_format,
  } = options;
  if (moneyFormat) {
    returnValue = returnValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  if (money_format) {
    returnValue = returnValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  if (prefix) {
    returnValue = `${prefix}${returnValue}`;
  }
  if (postfix) {
    returnValue = `${returnValue}${postfix}`;
  }
  return returnValue;
};

export const parser = (value, options) => {
  if (!value) return value;
  let returnValue = value.replace(/^[A-Za-z]+$/, '');
  const { prefix, postfix, money_format } = options;
  if (prefix) {
    prefix.split('').forEach((char) => {
      returnValue = returnValue.replace(char, '');
    });
  }
  if (postfix) {
    postfix.split('').forEach((char) => {
      returnValue = returnValue.replace(char, '');
    });
  }
  if (money_format) {
    returnValue = returnValue.replace(/\$\s?|(,*)/g, '');
  }
  return returnValue;
};
