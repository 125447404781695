import styled from 'styled-components';

const EmailBoxWrapper = styled.div`
    overflow: hidden;
    height: calc(100vh - 57px); 
    margin-right: -40px;
    .sendMessagSectionWrapper
    {
        background-color: #f8f8ff;
        position: absolute;
        bottom: 0;
        right: -40;
        width: calc(100% + 40px);
    }
    .sendMessagSection
    {
        position: relative; 
        .ant-form-item, .ant-row
        {
            padding: 0 !important;
            margin: 0 !important;
        }
        button
        {
            position: absolute; 
            bottom: 0;
            right: 0;
        }
        textarea
        {
            border: 0;
            border-radius: 0; 
        }
        i
        {
            font-size: 21px;
        }
    }
`;

export default EmailBoxWrapper;
