import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const useGaTracker = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (window.location.hostname === 'app.finneotech.com'
      && (location.pathname.includes('prefundingTracker') || location.pathname.includes('deal-bid'))
    ) {
      ReactGA.initialize('G-4LTN1XEMNW');
      setInitialized(true);
    }
  }, [location]);

  useEffect(() => {
    if (initialized) {
      ReactGA.send({ hitType: 'pageView', path: location.pathname + location.search });
    }
  }, [initialized, location]);
};

export default useGaTracker;
