import {
  call, takeLatest, put, select,
} from 'redux-saga/effects';
import {
  fetchBorrowerNotes,
  addBorrowerNote,
  updateBorrowerNote,
  getBorrowerNote,
  deleteBorrowerNote,
} from 'actions/borrower-notes';
import {
  fetchBorrowerNotesApi,
  addBorrowerNoteApi,
  updateBorrowerNoteApi,
  getBorrowerNoteApi,
  deleteBorrowerNoteApi,
} from 'services/borrower-notes';
import fetchEntity from './fetch-entity';

const fetchBorrowerNotesData = fetchEntity
  .bind(null, fetchBorrowerNotes.actions, fetchBorrowerNotesApi);

const fetchAddBorrowerNote = fetchEntity.bind(
  null,
  addBorrowerNote.actions,
  addBorrowerNoteApi,
);

const fetchUpdateBorrowerNote = fetchEntity.bind(
  null,
  updateBorrowerNote.actions,
  updateBorrowerNoteApi,
);

const fetchDeleteBorrowerNote = fetchEntity.bind(
  null,
  deleteBorrowerNote.actions,
  deleteBorrowerNoteApi,
);

export function* loadFetchLenderData({ params }) {
  yield call(
    fetchBorrowerNotesData,
    { ...params },
  );
}

export function* loadAddBorrowerNote({ params }) {
  yield call(fetchAddBorrowerNote, params);
}

export function* loadUpdateBorrowerNote({ params }) {
  yield call(fetchUpdateBorrowerNote, params);
}

export function* loadDeleteBorrowerNote({ params }) {
  yield call(fetchDeleteBorrowerNote, { ...params });
}

function* watchFetchBorrowerNotes() {
  yield takeLatest(fetchBorrowerNotes.actionName, loadFetchLenderData);
}
function* watchAddBorrowerNote() {
  yield takeLatest(addBorrowerNote.actionName, loadAddBorrowerNote);
}

function* watchUpdateBorrowerNote() {
  yield takeLatest(updateBorrowerNote.actionName, loadUpdateBorrowerNote);
}

function* watchDeleteBorrowerNote() {
  yield takeLatest(deleteBorrowerNote.actionName, loadDeleteBorrowerNote);
}

const fetchBorrowerNote = fetchEntity.bind(
  null,
  getBorrowerNote.actions,
  getBorrowerNoteApi,
);

export function* loadGetBorrowerNote({ params }) {
  yield put(getBorrowerNote.actions.failure({}, undefined));
  yield call(fetchBorrowerNote, params);
}

function* watchGetBorrowerNote() {
  yield takeLatest(getBorrowerNote.actionName, loadGetBorrowerNote);
}

export function* loadBorrowerNotesOnChange() {
  const borrowerNotes = yield select((state) => state.borrowerNotes);
  const { total, skip } = borrowerNotes;
  if (skip && skip >= total) {
    yield call(fetchBorrowerNotesData, {
      contactId: borrowerNotes.selectedBorrowerNote.contactId,
    });
  } else {
    yield call(fetchBorrowerNotesData, {
      contactId: borrowerNotes.selectedBorrowerNote.contactId,
    });
  }
}

function* watchBorrowerNotesOnChange() {
  yield takeLatest([
    addBorrowerNote.requestTypes.SUCCESS,
    deleteBorrowerNote.requestTypes.SUCCESS,
  ], loadBorrowerNotesOnChange);
}

export default {
  watchFetchBorrowerNotes,
  watchAddBorrowerNote,
  watchGetBorrowerNote,
  watchUpdateBorrowerNote,
  watchBorrowerNotesOnChange,
  watchDeleteBorrowerNote,
  // watchBorrowerNotesOnDelete,
};
