import { call, takeLatest } from 'redux-saga/effects';
import {
  getAllUserCategories, addCategory, removeCategory,
} from 'actions/documents-categories';
import {
  getAllUserCategoriesApi, addCategoryApi, removeCategoryApi,
} from 'services/documents-categories';
import fetchEntity from './fetch-entity';

const getAllUserCategoriesValues = fetchEntity.bind(null, getAllUserCategories.actions, getAllUserCategoriesApi);

export function* loadGetAllUserCategories({ params }) {
  yield call(getAllUserCategoriesValues, { ...params });
}

function* watchGetAllUserCategories() {
  yield takeLatest(getAllUserCategories.actionName, loadGetAllUserCategories);
}

const addCategoryValues = fetchEntity.bind(null, addCategory.actions, addCategoryApi);

export function* loadAddCategoryValues({ params }) {
  yield call(addCategoryValues, { ...params });
}

function* watchAddCategoryValues() {
  yield takeLatest(addCategory.actionName, loadAddCategoryValues);
}

const removeCategoryValues = fetchEntity.bind(null, removeCategory.actions, removeCategoryApi);

export function* loadRemoveCategoryValues({ params }) {
  yield call(removeCategoryValues, { ...params });
}

function* watchRemoveCategoryValues() {
  yield takeLatest(removeCategory.actionName, loadRemoveCategoryValues);
}

export default {
  watchGetAllUserCategories,
  watchAddCategoryValues,
  watchRemoveCategoryValues,
};
