import merge from 'deepmerge';
import { combineReducers } from 'redux';

const lenderContacts = () => (state = {
  lenderContacts: null,
}, action) => {
  if (action.type === 'FETCH_LENDER_CONTACTS_SUCCESS' && action.response.entities) {
    return merge.all([state, action.response.entities], {
      clone: false,
      arrayMerge: (destinationArray, sourceArray) => sourceArray,
    });
  }
  return state;
};

export default combineReducers({
  lenderContacts: lenderContacts(),
});
