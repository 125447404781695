import styled from 'styled-components';
import { DatePicker as datapicker } from 'antd';

const DatePicker = styled(datapicker)`
    .ant-calendar-picker-input
    {
        border: 1px solid rgba(0, 0, 0, 0.1) !important;
        border-radius: 4px !important;
        background-color: #ffffff !important;
        height: 40px !important;
    }
`;

export default DatePicker;
