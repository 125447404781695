import {
  call, takeLatest,
} from 'redux-saga/effects';
import {
  addConstructionPropertyValues, addProjectMetrics, addConstructionLoan, getConstructionLoanDetails, getIndexRates, removeConstructionLoan,
  getconstructionPropertyDetails, getProjectMetrics, getConstructionRevenue, updateConstructionLoan, updateConstructionRevenue, updateProjectMetrics,
  addConstructionRevenue, getConstructionLoan, updateConstructionPropertyValues, removeConstructionProperty, removeConstructionRevenue,
  removeProjectMetrics,
} from 'actions/construction';
import {
  addPropertyApi, addProjectMetricsApi, addConstructionLoanApi, getConstructionLoanApi, getIndexRatesApi, removeConstructionLoanApi,
  getConstructionPropertiesApi, getProjectMetricsApi, getConstructionRevenueApi, updateConstructionLoanApi, updateConstructionPropertyApi,
  addConstructionRevenueApi, updateConstructionRevenueApi, updateProjectMetricsApi, removeConstructionRevenueApi, removeProjectMetricsApi,
  removeConstructionPropertyApi,
} from 'services/construction';
import fetchEntity from './fetch-entity';

const fetchConstructionLoansValues = fetchEntity.bind(null, getConstructionLoan.actions, getConstructionLoanApi);

export function* loadConstructionLoans({ params }) {
  yield call(fetchConstructionLoansValues, { ...params });
}

function* watchConstructionLoansValues() {
  yield takeLatest(getConstructionLoan.actionName, loadConstructionLoans);
}

const sendConstructionPropertyValues = fetchEntity.bind(null, addConstructionPropertyValues.actions, addPropertyApi);

export function* loadsendConstructionPropertyValues({ params }) {
  yield call(sendConstructionPropertyValues,
    { ...params });
}

function* watchSendConstructionPropertyValues() {
  yield takeLatest(addConstructionPropertyValues.actionName, loadsendConstructionPropertyValues);
}

const sendConstructionProjectMetrics = fetchEntity.bind(null, addProjectMetrics.actions, addProjectMetricsApi);

export function* loadsendConstructionProjectMetrics({ params }) {
  yield call(sendConstructionProjectMetrics,
    { ...params });
}

function* watchSendConstructionProjectMetrics() {
  yield takeLatest(addProjectMetrics.actionName, loadsendConstructionProjectMetrics);
}

const sendAddConstructionLoan = fetchEntity.bind(null, addConstructionLoan.actions, addConstructionLoanApi);

export function* loadsendAddConstructionLoan({ params }) {
  yield call(sendAddConstructionLoan,
    { ...params });
}

function* watchSendAddConstructionLoan() {
  yield takeLatest(addConstructionLoan.actionName, loadsendAddConstructionLoan);
}

const sendRemoveConstructionLoan = fetchEntity.bind(null, removeConstructionLoan.actions, removeConstructionLoanApi);

export function* loadSendRemoveConstructionLoan({ params }) {
  yield call(sendRemoveConstructionLoan,
    { ...params });
}

function* watchSendRemoveConstructionLoan() {
  yield takeLatest(removeConstructionLoan.actionName, loadSendRemoveConstructionLoan);
}

const sendGetConstructionLoan = fetchEntity.bind(null, getConstructionLoanDetails.actions, getConstructionLoanApi);

export function* loadSendGetConstructionLoan({ params }) {
  yield call(sendGetConstructionLoan,
    { ...params });
}

function* watchSendGetConstructionLoan() {
  yield takeLatest(getConstructionLoanDetails.actionName, loadSendGetConstructionLoan);
}

const sendGetIndexRates = fetchEntity.bind(null, getIndexRates.actions, getIndexRatesApi);

export function* loadSendGetIndexRates({ params }) {
  yield call(sendGetIndexRates,
    { ...params });
}

function* watchSendGetIndexRates() {
  yield takeLatest(getIndexRates.actionName, loadSendGetIndexRates);
}

const getPropertyDetailsConstruction = fetchEntity.bind(null, getconstructionPropertyDetails.actions, getConstructionPropertiesApi);

export function* loadSendGetPropertyDetailsConstruction({ params }) {
  yield call(getPropertyDetailsConstruction,
    { ...params });
}

function* watchSendGetPropertyDetailsConstruction() {
  yield takeLatest(getconstructionPropertyDetails.actionName, loadSendGetPropertyDetailsConstruction);
}

const addPropertyRevenueDetails = fetchEntity.bind(null, addConstructionRevenue.actions, addConstructionRevenueApi);

export function* loadAddPropertyRevenueDetails({ params }) {
  yield call(addPropertyRevenueDetails,
    { ...params });
}

function* watchSendAddPropertyRevenueDetails() {
  yield takeLatest(addConstructionRevenue.actionName, loadAddPropertyRevenueDetails);
}

const getPropertyRevenueDetails = fetchEntity.bind(null, getConstructionRevenue.actions, getConstructionRevenueApi);

export function* loadGetPropertyRevenueDetails({ params }) {
  yield call(getPropertyRevenueDetails,
    { ...params });
}

function* watchGetPropertyRevenueDetails() {
  yield takeLatest(getConstructionRevenue.actionName, loadGetPropertyRevenueDetails);
}

const getConstructionProjectMetrics = fetchEntity.bind(null, getProjectMetrics.actions, getProjectMetricsApi);

export function* loadGetConstructionProjectMetrics({ params }) {
  yield call(getConstructionProjectMetrics,
    { ...params });
}

function* watchGetConstructionProjectMetrics() {
  yield takeLatest(getProjectMetrics.actionName, loadGetConstructionProjectMetrics);
}

const updateConstructionProjectMetrics = fetchEntity.bind(null, updateProjectMetrics.actions, updateProjectMetricsApi);

export function* loadUpdateConstructionProjectMetrics({ params }) {
  yield call(updateConstructionProjectMetrics,
    { ...params });
}

function* watchUpdateConstructionProjectMetrics() {
  yield takeLatest(updateProjectMetrics.actionName, loadUpdateConstructionProjectMetrics);
}

const updatePropertyRevenueDetails = fetchEntity.bind(null, updateConstructionRevenue.actions, updateConstructionRevenueApi);

export function* loadUpdatePropertyRevenueDetails({ params }) {
  yield call(updatePropertyRevenueDetails,
    { ...params });
}

function* watchUpdatePropertyRevenueDetails() {
  yield takeLatest(updateConstructionRevenue.actionName, loadUpdatePropertyRevenueDetails);
}

const sendRemoveConstructionProjectMetrics = fetchEntity.bind(null, removeProjectMetrics.actions, removeProjectMetricsApi);

export function* loadSendRemoveConstructionProjectMetrics({ params }) {
  yield call(sendRemoveConstructionProjectMetrics,
    { ...params });
}

function* watchSendRemoveConstructionProjectMetrics() {
  yield takeLatest(removeProjectMetrics.actionName, loadSendRemoveConstructionProjectMetrics);
}

const updatePropertyDetailsConstruction = fetchEntity.bind(null, updateConstructionPropertyValues.actions, updateConstructionPropertyApi);

export function* loadSendUpdatePropertyDetailsConstruction({ params }) {
  yield call(updatePropertyDetailsConstruction,
    { ...params });
}

function* watchSendUpdatePropertyDetailsConstruction() {
  yield takeLatest(updateConstructionPropertyValues.actionName, loadSendUpdatePropertyDetailsConstruction);
}

const sendUpdateConstructionLoan = fetchEntity.bind(null, updateConstructionLoan.actions, updateConstructionLoanApi);

export function* loadsendUpdateConstructionLoan({ params }) {
  yield call(sendUpdateConstructionLoan,
    { ...params });
}

function* watchSendUpdateConstructionLoan() {
  yield takeLatest(updateConstructionLoan.actionName, loadsendUpdateConstructionLoan);
}

const sendRemoveConstructionRevenue = fetchEntity.bind(null, removeConstructionRevenue.actions, removeConstructionRevenueApi);

export function* loadSendRemoveConstructionRevenue({ params }) {
  yield call(sendRemoveConstructionRevenue,
    { ...params });
}

function* watchSendRemoveConstructionRevenue() {
  yield takeLatest(removeConstructionRevenue.actionName, loadSendRemoveConstructionRevenue);
}

const sendRemoveConstructionProperty = fetchEntity.bind(null, removeConstructionProperty.actions, removeConstructionPropertyApi);

export function* loadSendRemoveConstructionProperty({ params }) {
  yield call(sendRemoveConstructionProperty,
    { ...params });
}

function* watchSendRemoveConstructionProperty() {
  yield takeLatest(removeConstructionProperty.actionName, loadSendRemoveConstructionProperty);
}

export default {
  watchSendConstructionPropertyValues,
  watchSendConstructionProjectMetrics,
  watchSendAddConstructionLoan,
  watchSendGetConstructionLoan,
  watchSendGetIndexRates,
  watchSendRemoveConstructionLoan,
  watchSendGetPropertyDetailsConstruction,
  watchSendAddPropertyRevenueDetails,
  watchGetPropertyRevenueDetails,
  watchGetConstructionProjectMetrics,
  watchConstructionLoansValues,
  watchUpdateConstructionProjectMetrics,
  watchUpdatePropertyRevenueDetails,
  watchSendUpdatePropertyDetailsConstruction,
  watchSendUpdateConstructionLoan,
  watchSendRemoveConstructionProjectMetrics,
  watchSendRemoveConstructionProperty,
  watchSendRemoveConstructionRevenue,
};
