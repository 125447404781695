import { getDashboardCompanies } from 'actions/dashboard-companies';

const DashboardCompanies = () => {
  const initialState = {
    companies: null,
  };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case getDashboardCompanies.requestTypes.SUCCESS:
        return {
          ...state,
          companies: response,
        };
      default:
        return state;
    }
  };
};

export default DashboardCompanies();
