import React from 'react';
import {
  Upload,
  Icon,
  Message,
  Spin,
  Avatar,
} from 'antd';

import {
  DeleteOutlined,
} from '@ant-design/icons';

import { Table } from 'components';
import { ReactComponent as pdf } from 'assets/imgs/pdf.svg';
import { ReactComponent as csv } from 'assets/imgs/csv.svg';
import { ReactComponent as doc } from 'assets/imgs/doc.svg';
import { ReactComponent as textSvg } from 'assets/imgs/txt.svg';

const { Dragger } = Upload;

const icons = {
  pdf: <Icon component={pdf} style={{ fontSize: '35px' }} />,
  word: <Icon component={doc} style={{ fontSize: '35px' }} />,
  txt: <Icon component={textSvg} style={{ fontSize: '35px' }} />,
  excel: <Icon component={csv} style={{ fontSize: '35px' }} />,
};

// TODO what if there is '.' in filename
const iconFactory = (extention) => {
  const ext = extention.split('.').pop();
  switch (ext) {
    case 'pdf':
    case 'PDF': {
      return icons.pdf;
    }
    case 'doc':
    case 'docx':
    case 'DOC': {
      return icons.word;
    }
    case 'txt':
    case 'TXT': {
      return icons.txt;
    }
    case 'xlsx':
    case 'xls':
    case 'csv': {
      return icons.excel;
    }
    default: {
      return '';
    }
  }
};

const FileUpload = (props) => {
  const {
    fileList = [],
    changeHandler,
    loading,
    extensions,
    maxAmount,
    multiple,
    fileSize = 5,
    ...otherProps
  } = props;

  const tableColumns = [
    {
      title: 'Preview',
      dataIndex: 'preview',
      key: 'preview',
      align: 'left',
      render: (text, record) => {
        const icon = iconFactory(record && record.name ? record.name : '');
        if (icon) {
          return icon;
        }
        return <Avatar shape='square' size='large' src={`${record.preview}`} />;
      },
    },
    {
      title: 'File Name',
      dataIndex: 'name',
      key: 'fileName',
      align: 'left',
      render: (text, record) => <a href={`${record.url}`} >{record.name}</a>,
      sorter: (a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1),
    },
    // {
    //   title: 'Date Uploaded',
    //   dataIndex: 'createdAt',
    //   key: 'createdAt',
    //   align: 'left',
    //   render: (text, record) => {
    //     const date = new Date(record.createdAt);
    //     return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()} at ${date.toLocaleTimeString()}`;
    //   },
    //   sorter: (a, b) => (Date(a.createdAt) < Date(b.createdAt) ? -1 : 1),
    // },
    {
      title: '',
      dataIndex: '',
      key: 'delete',
      align: '',
      render: (text, record) => (<DeleteOutlined onClick={() => {
        deleteFile(fileList.find((file) => file.uid === record.uid));
      }} />),
    },

  ];

  const tableData = (fileList && Array.isArray(fileList)) ? fileList.map((record) => ({
    ...record,
    preview: record.url,
    key: record.uid,
  })) : [];

  const addFile = (file, selectedFileList) => {
    function updateFileExtension(fileName) {
      return fileName.slice(0,
        fileName.lastIndexOf('.')).concat('.').concat(fileName.slice(fileName.lastIndexOf('.') + 1,
        fileName.length).toLowerCase());
    }

    const hasBigFiles = selectedFileList.find((f) => f.size > fileSize * 1024 * 1024);
    const hasRestrictedExtensions = !!selectedFileList.filter((f) => extensions
    && !extensions.includes(f.name.split('.').pop().toLowerCase())).length;

    if (
      (!multiple && fileList.length >= 1)
      || (fileList.length + selectedFileList.length > maxAmount)
      || hasBigFiles
    ) {
      Message.error(`Maximum ${maxAmount || (!multiple && 1)} files allowed, ${fileSize} MB each`);
    } else if (hasRestrictedExtensions) {
      Message.error('Unsupported extension!');
    } else {
      const updatedSelectedFileList = selectedFileList.map((el) => {
        const fileName = String(el && el.name);
        const updatedFileName = updateFileExtension(fileName);
        const myRenamedFile = new File([el], updatedFileName, {
          type: selectedFileList.type,
          lastModified: selectedFileList.lastModified,
          lastModifiedDate: selectedFileList.lastModifiedDate,
        });
        return myRenamedFile;
      });
      // changeHandler && changeHandler(selectedFileList, [...fileList, ...selectedFileList], 'add');
      changeHandler && changeHandler(updatedSelectedFileList, [...fileList, ...updatedSelectedFileList], 'add');
    }
    return false;
  };

  const deleteFile = (file) => {
    changeHandler && changeHandler(file, fileList.filter((f) => f.uid !== file.uid), 'delete');
  };

  return (
    <Spin spinning={!!loading}>
      <Dragger
        multiple={multiple}
        fileList={[]}
        // onRemove={deleteFile}
        beforeUpload={addFile}
        listType='picture-card'
        {...otherProps}
      >
        <p className='ant-upload-drag-icon'> <Icon type='inbox' /> </p>
        <p className='ant-upload-text'> Click or drag file to this area to upload </p>
        <p className='ant-upload-hint'>
          <span />
          {multiple && maxAmount
            ? <span>
              {`Maximum ${maxAmount} files allowed `}
              {fileSize && `${fileSize}MB each`}
            </span>
            : fileSize && `Upload size limit is ${fileSize} MB`}
        </p>
        {extensions && <p className='ant-upload-hint'>
          Supported extensions: {(extensions && extensions !== 'undefined') && extensions.map((ext) => `.${ext}, `)}
        </p>}
      </Dragger>
      <Table
        // loading={loading}
        showColumn={false}
        columns={tableColumns}
        dataSource={tableData}
        // we do not want to show table when there is no file and props has been set to true
        notShowTableIfEmpty={props.notShowTableIfEmpty && (tableData && tableData.length <= 0)}
        notShowPagination={props.notShowPagination}
      />
    </Spin>
  );
};

export default FileUpload;
