import {
  fetchConfAgreement,
} from 'actions/conf-agreement';

const confAgreement = () => {
  const initialState = {
    confAgreement: null,
  };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case fetchConfAgreement.requestTypes.SUCCESS:
        return {
          ...state,
          confAgreement: response,
        };
      default:
        return state;
    }
  };
};

export default confAgreement();
