import { fetchLenderDeals } from 'actions/lender-deals';

const lenderDeals = () => {
  const initialState = {
    data: null,
  };
  return (state = initialState, { type, response }) => {
    switch (type) {
      case fetchLenderDeals.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case fetchLenderDeals.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case fetchLenderDeals.requestTypes.SUCCESS:
        return {
          ...state,
          data: response,
          isSuccessful: true,
        };

      default:
        return state;
    }
  };
};

export default lenderDeals();
