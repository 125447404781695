import { makeRequestAction } from './index';

export const fetchLenderLoans = makeRequestAction('FETCH_LENDER_LOANS', {
  onSuccess(params, response) {
    return {
      response: [
        ...response,
      ],
    };
  },
});

export const fetchLoanNumbers = makeRequestAction('FETCH_LOAN_NUMBERS', {
  onSuccess(params, response) {
    return {
      response: [
        ...response,
      ],
    };
  },
});

export const fetchLoanBorrowers = makeRequestAction('FETCH_LOAN_BORROWERS', {
  onSuccess(params, response) {
    return {
      response: [
        ...response,
      ],
    };
  },
});

export const fetchBorrowerData = makeRequestAction('FETCH_BORROWER_DATA', {
  onSuccess(params, response) {
    return {
      response: [
        ...response,
      ],
    };
  },
});

export const sendLenderInfo = makeRequestAction('SEND_LENDER_INFO', {
  onSuccess(params, response) {
    return {
      response: [
        ...response,
      ],
    };
  },
});

export const fetchLoanComparison = makeRequestAction('FETCH_LOAN_COMPARISON', {
  onSuccess(params, response) {
    return {
      response,
    };
  },
});
