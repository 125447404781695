import {
  call, takeLatest, put, select,
} from 'redux-saga/effects';
import { addUploadApi, deleteUploadApi } from 'services/uploads';
import {
  fetchDeals,
  fetchAllDeals,
  addDeal,
  updateDeal,
  patchDeal,
  getDeal,
  deleteDeal,
  addConfAgreUpload,
  deleteConfAgreUpload,
  getHouseDeals,
  deleteDeals,
} from 'actions/deals';
import {
  fetchDealsApi,
  addDealApi,
  updateDealApi,
  patchDealApi,
  getDealApi,
  deleteDealApi,
  deleteDealsApi,
} from 'services/deals';

import fetchEntity from './fetch-entity';

const fetchDealsData = fetchEntity
  .bind(null, fetchDeals.actions, fetchDealsApi);

const fetchAllDealsData = fetchEntity
  .bind(null, fetchAllDeals.actions, fetchDealsApi);

const getAllHouseDealsData = fetchEntity
  .bind(null, getHouseDeals.actions, fetchDealsApi); // change

const fetchAddDeal = fetchEntity.bind(
  null,
  addDeal.actions,
  addDealApi,
);

const fetchUpdateDeal = fetchEntity.bind(
  null,
  updateDeal.actions,
  updateDealApi,
);

const fetchPatchDeal = fetchEntity.bind(
  null,
  patchDeal.actions,
  patchDealApi,
);

const fetchDeleteDeal = fetchEntity.bind(
  null,
  deleteDeal.actions,
  deleteDealApi,
);

const fetchDeleteDeals = fetchEntity.bind(
  null,
  deleteDeals.actions,
  deleteDealsApi,
);

const fetchAddConfAgreUpload = fetchEntity.bind(
  null,
  addConfAgreUpload.actions,
  addUploadApi,
);

const fetchDeleteConfAgreUpload = fetchEntity.bind(
  null,
  deleteConfAgreUpload.actions,
  deleteUploadApi,
);

export function* loadFetchLenderData({ params }) {
  yield call(
    fetchDealsData,
    { ...params },
  );
}

export function* loadFetchAllDeals({ params }) {
  yield call(fetchAllDealsData, params);
}

export function* loadGetAllHouseDeals({ params }) {
  yield call(getAllHouseDealsData, params);
}

export function* loadAddDeal({ params }) {
  yield call(fetchAddDeal, params);
  yield put({ type: 'CLEAR_UPLOADS' });
}

export function* loadUpdateDeal({ params }) {
  yield call(fetchUpdateDeal, params);
}

export function* loadPatchDeal({ params }) {
  yield call(fetchPatchDeal, params);
}

export function* loadDeleteDeal({ params }) {
  yield call(fetchDeleteDeal, { ...params });
}

export function* loadDeleteDeals({ params }) {
  yield call(fetchDeleteDeals, { ...params });
}

function* watchFetchDeals() {
  yield takeLatest(fetchDeals.actionName, loadFetchLenderData);
}

function* watchFetchAllDeals() {
  yield takeLatest(fetchAllDeals.actionName, loadFetchAllDeals);
}

function* watchGetAllHouseDeals() {
  yield takeLatest(getHouseDeals.actionName, loadGetAllHouseDeals);
}
function* watchAddDeal() {
  yield takeLatest(addDeal.actionName, loadAddDeal);
}

function* watchUpdateDeal() {
  yield takeLatest(updateDeal.actionName, loadUpdateDeal);
}
function* watchPatchDeal() {
  yield takeLatest(patchDeal.actionName, loadPatchDeal);
}

function* watchDeleteDeal() {
  yield takeLatest(deleteDeal.actionName, loadDeleteDeal);
}

function* watchDeleteDeals() {
  yield takeLatest(deleteDeals.actionName, loadDeleteDeals);
}

const fetchDeal = fetchEntity.bind(
  null,
  getDeal.actions,
  getDealApi,
);

export function* loadGetDeal({ params }) {
  yield put(getDeal.actions.failure({}, undefined));
  yield call(fetchDeal, params);
}

function* watchGetDeal() {
  yield takeLatest(getDeal.actionName, loadGetDeal);
}

export function* loadDealsOnChange({ params }) {
  const deals = yield select((state) => state.deals);
  const dealStatus = deals.deals.data[0] && deals.deals.data[0].dealStatus;
  const { total, skip, limit } = deals;
  if (skip && skip >= total) {
    yield call(fetchDealsData, {
      $skip: total - Math.max(total % limit, limit),
      '$sort[updatedAt]': -1,
      dealStatus,
    });
  } else {
    yield call(fetchDealsData, { $skip: skip, '$sort[updatedAt]': -1, dealStatus });
  }
}

function* watchDealsOnChange() {
  yield takeLatest([
    deleteDeal.requestTypes.SUCCESS,
  ], loadDealsOnChange);
}

export function* loadAllDealsOnChange() {
  yield call(fetchAllDealsData, { getAll: true, '$sort[createdAt]': -1 });
}

export function* loadAllHouseDealsOnChange() {
  yield call(getAllHouseDealsData, { getAll: true, '$sort[createdAt]': -1 });
}

function* watchAllDealsOnChange() {
  yield takeLatest([
    deleteDeal.requestTypes.SUCCESS,
    addDeal.requestTypes.SUCCESS,
  ], loadAllDealsOnChange);
}

export function* loadAddConfAgreUpload({ params }) {
  yield call(fetchAddConfAgreUpload, { ...params });
}

function* watchAddConfAgreUpload() {
  yield takeLatest(addConfAgreUpload.actionName, loadAddConfAgreUpload);
}

export function* loadDeleteConfAgreUpload({ params }) {
  yield call(fetchDeleteConfAgreUpload, { ...params });
}

function* watchDeleteConfAgreUpload() {
  yield takeLatest(deleteConfAgreUpload.actionName, loadDeleteConfAgreUpload);
}

export default {
  watchFetchDeals,
  watchFetchAllDeals,
  watchGetAllHouseDeals,
  watchAddDeal,
  watchGetDeal,
  watchUpdateDeal,
  watchPatchDeal,
  watchDealsOnChange,
  watchAllDealsOnChange,
  watchDeleteDeal,
  watchDeleteDeals,
  watchAddConfAgreUpload,
  watchDeleteConfAgreUpload,
};
