import { makeRequestAction } from './index';

export const portfoliosValues = makeRequestAction('PORTFOLIOS', {
  onSuccess(params, response) {
    return {
      response: {
        entities: {
          portfoliosData: response,
        },
      },
    };
  },
});

export const addPortfolio = makeRequestAction('CREATE_PORTFOLIO', {
  onSuccess(params, response) {
    return {
      response: {
        entities: {
          createPortfolio: response,
        },
      },
    };
  },
});

export const updatePortfolio = makeRequestAction('UPDATE_PORTFOLIO', {
  onSuccess(params, response) {
    return {
      response: {
        entities: {
          updatePortfolio: response,
        },
      },
    };
  },
});

export const removePortfolio = makeRequestAction('REMOVE_PORTFOLIO', {
  onSuccess(params, response) {
    return {
      response: {
        entities: {
          deletePortfolio: response,
        },
      },
    };
  },
});
