import styled, { css } from 'styled-components';
import propTypes from 'prop-types';
import { Modal as modal } from 'antd';

const Modal = styled(modal)`
  .ant-modal-content {
    font-family: "Roboto", sans-serif;
  }

  .ant-modal-header {
    background: #F2F2F2;
    font-size: 18px;
    font-weight: bold;
    border-bottom: 3px solid #E7E7E7 !important;
  }

  .ant-modal-close-x {
    color: #000;
  }

  ${(props) => props.theme === 'selection' && css`
    .ant-modal-body{
      padding: 0 !important;
    }
  `}

  ${(props) => props.theme === 'light' && css`
    .ant-modal-content {
      background-color: #f0f2f5;
    }
  `}
`;

Modal.propTypes = {
  centered: propTypes.bool,
};
Modal.defaultProps = {
  centered: true,
};
/** @component */
export default Modal;
