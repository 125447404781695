import axios from 'axios';
import Promise from 'bluebird';
import { MARKET_DATA_ROOT } from 'configs/env-vars';
import { getAccessToken } from 'configs/localStorage';

Promise.config({
  cancellation: true,
});

// overwrite native Promise implementation with Bluebird's
window.Promise = Promise;

export const authorizationHeader = () => ({
  Authorization: getAccessToken(),
});

export default (headers = {}) => {
  const service = axios.create({
    baseURL: MARKET_DATA_ROOT, // url of the api
    headers: {
      Authorization: getAccessToken(),
      ...headers,
    },
  });
  service.interceptors.response.use(
    (response) => response,
  );
  return service;
};
