import {
  call, takeLatest,
} from 'redux-saga/effects';

import { getLenderDealsApi } from 'services/lender-deals';
import { fetchLenderDeals } from 'actions/lender-deals';
import fetchEntity from './fetch-entity';

const fetchlenderDealsData = fetchEntity
  .bind(null, fetchLenderDeals.actions, getLenderDealsApi);

export function* loadFetchlenderDealsData({ params }) {
  yield call(fetchlenderDealsData, params);
}

function* watchFetchlenderDealsData() {
  yield takeLatest(fetchLenderDeals.actionName, loadFetchlenderDealsData);
}
export default { watchFetchlenderDealsData };
