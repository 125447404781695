import { makeRequestAction } from './index';

export const getAllUserDocuments = makeRequestAction('DOCUMENTS', {
  onSuccess(params, response) {
    return {
      response,
    };
  },
});

export const addDocument = makeRequestAction('CREATE_DOCUMENT', {
  onSuccess(params, response) {
    return {
      response,
    };
  },
});

export const removeDocument = makeRequestAction('REMOVE_DOCUMENT', {
  onSuccess(params, response) {
    return {
      response,
    };
  },
});
