import { call, takeLatest } from 'redux-saga/effects';
import {
  getUserSettings,
  updateUserSettings,
} from 'actions/user-settings';
import { getUserSettingsApi, updateUserSettingsApi } from 'services/user-settings';
import fetchEntity from './fetch-entity';

const fetchUserTargetValuesData = fetchEntity.bind(
  null,
  getUserSettings.actions,
  getUserSettingsApi,
);

function* loadFetchUserSettings({ params }) {
  yield call(fetchUserTargetValuesData, { ...params });
}

function* watchFetchUserSettings() {
  yield takeLatest(getUserSettings.actionName, loadFetchUserSettings);
}

const fetchUpdateUserSettingsValues = fetchEntity.bind(
  null,
  updateUserSettings.actions,
  updateUserSettingsApi,
);

function* loadUpdateUserSettings({ params }) {
  yield call(fetchUpdateUserSettingsValues, params);
}

function* watchUpdateUserSettings() {
  yield takeLatest(updateUserSettings.actionName, loadUpdateUserSettings);
}

export default {
  watchFetchUserSettings,
  watchUpdateUserSettings,
};
