import { makeRequestAction } from './index';

export const fetchInboxEmails = makeRequestAction('FETCH_INBOX_EMAILS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const addInboxEmail = makeRequestAction('ADD_INBOX_EMAIL', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const updateInboxEmail = makeRequestAction('UPDATE_INBOX_EMAIL', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const getInboxEmail = makeRequestAction('GET_INBOX_EMAIL', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const deleteInboxEmail = makeRequestAction('DELETE_INBOX_EMAIL', {
  onSuccess(params, response) {
    return {
      response: { ids: [response.id], type: response.type },
    };
  },
});
