import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { message } from 'antd';
import { Button } from 'components';
import { userLogout } from 'actions/users';
import { logoutError } from 'selectors/users';
import errors from '../../constants/errors';

const LogoutButton = (props) => {
  const dispatch = useDispatch();
  const error = useSelector(logoutError);
  useEffect(() => {
    if (error) {
      message.error(errors.LOGOUT_ERROR);
    }
  }, [error]);

  const onLogout = () => {
    dispatch(userLogout.request());
    const futureDate = localStorage.getItem('futureDate');
    localStorage.clear();
    if (futureDate) {
      localStorage.setItem('futureDate', futureDate);
    }
  };

  return <Button type='link' onClick={onLogout} {...props}>
    {props.text}
  </Button>;
};
LogoutButton.defaultProps = {
  text: 'Logout',
};
export default React.memo(LogoutButton);
