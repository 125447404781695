import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import useGaTracker from 'hooks/useGaTracker';
import asyncComponent from './asyncComponent';
import {
  isAuth, isNotAuth, isPermitted, dashboardRedirect,
} from './configs/auth';

const LoginPage = asyncComponent(() => import('./containers/Authorization/LoginPage'));
const NotFoundPage = asyncComponent(() => import('./containers/NotFoundPage'));
const NotPermittedPage = asyncComponent(() => import('./containers/NotPermittedPage'));
const Dashboard = asyncComponent(() => import('./containers/Portfolio/Dashboard'));
const ExpiredLink = asyncComponent(() => import('./containers/ExpiredLink'));
// const DashboardLender = asyncComponent(() => import('./containers/Portfolio/DashboardLender');

const RequestAccess = asyncComponent(() => import('./containers/Authorization/RequestAccessPage'));
const NotVerifiedUser = asyncComponent(() => import('./containers/NotVerifiedUser'));
const NotActiveUser = asyncComponent(() => import('./containers/NotActiveUser'));
const ThankYouPage = asyncComponent(() => import('./containers/ThankYouPage'));
const VerifyAccountPage = asyncComponent(() => import('./containers/Authorization/VerifyAccountPage'));
const ForgotPassword = asyncComponent(() => import('./containers/Authorization/ForgotPassword'));
const ResetPassword = asyncComponent(() => import('./containers/Authorization/ResetPassword'));

const CalculatorPage = asyncComponent(() => import('./containers/Calculators'));
const CalculatorIntegration = asyncComponent(() => import('./containers/CalculatorsIntegration'));
const ServicesTable = asyncComponent(() => import('./containers/Services/Table'));
const ServicesForm = asyncComponent(() => import('./containers/Services/Form'));
const LendersListPage = asyncComponent(() => import('./containers/Lenders/LendersListPage'));
const LendersPage = asyncComponent(() => import('./containers/Lenders/LendersPage'));
const LenderSinglePage = asyncComponent(() => import('./containers/Lenders/LenderSinglePage'));
const LenderEditPage = asyncComponent(() => import('./containers/Lenders/LenderEditPage'));
const MarketDataPage = asyncComponent(() => import('./containers/MarketDataPage'));
const Profile = asyncComponent(() => import('./containers/Profile'));
const RelatedUsers = asyncComponent(() => import('./containers/Users'));

const ManagePropertiesPage = asyncComponent(() => import('./containers/Portfolio/ManagePropertiesPage'));
const ReportingPage = asyncComponent(() => import('./containers/Reporting/ReportingPage'));
const AddLoanPage = asyncComponent(() => import('./containers/Portfolio/AddLoanPage'));
const ViewLoansPage = asyncComponent(() => import('./containers/Portfolio/ViewLoansPage'));
const EditLoanPage = asyncComponent(() => import('./containers/Portfolio/EditLoanPage'));
const AddLinePage = asyncComponent(() => import('./containers/Portfolio/AddLinePage'));
const EditLinePage = asyncComponent(() => import('./containers/Portfolio/EditLinePage'));
const ViewLinePage = asyncComponent(() => import('./containers/Portfolio/ViewLinePage'));
const AddBlanketPage = asyncComponent(() => import('./containers/Portfolio/AddBlanketPage'));
const EditBlanketPage = asyncComponent(() => import('./containers/Portfolio/EditBlanketPage'));
const ViewBlanketPage = asyncComponent(() => import('./containers/Portfolio/ViewBlanketPage'));

const TermsAndConditions = asyncComponent(() => import('./containers/StaticPages/TermsAndConditionsPage'));
const PrivacyPolicy = asyncComponent(() => import('./containers/StaticPages/PrivacyPolicyPage'));

const DealsDraft = asyncComponent(() => import('./containers/Deals/DealsDraft'));
const DealsInProgress = asyncComponent(() => import('./containers/Deals/DealsInProgress'));
const DealsArchived = asyncComponent(() => import('./containers/Deals/DealsArchived'));
const DealsInActive = asyncComponent(() => import('./containers/Deals/DealsInActive'));

const DealForm = asyncComponent(() => import('./containers/Deals/DealForm'));
const DealPreview = asyncComponent(() => import('./containers/Deals/DealPreview'));
const DealPDF = asyncComponent(() => import('./containers/Deals/DealPDF'));

const DealsApproval = asyncComponent(() => import('./containers/Deals/DealsApproval'));
const DealsLendersBids = asyncComponent(() => import('./containers/Deals/DealsLendersBids'));
const DealsConsultantsBids = asyncComponent(() => import('./containers/Deals/DealsConsultantsBids'));

const PrefundingTracker = asyncComponent(() => import('./containers/Deals/PreFundingTracker'));
const GuestPreFundingTracker = asyncComponent(() => import('./containers/Deals/GuestPreFundingTracker'));

const ConsultantsListPage = asyncComponent(() => import('./containers/Consultants/ConsultantsListPage'));
const ConsultantsPage = asyncComponent(() => import('./containers/Consultants/ConsultantsPage'));
const ConsultantsSingle = asyncComponent(() => import('./containers/Consultants/ConsultantsSingle'));

const DealsEmailPage = asyncComponent(() => import('./containers/DealsEmail/DealsEmailPage'));
const GuestUserDeal = asyncComponent(() => import('./containers/GuestUserDeal'));

const Documents = asyncComponent(() => import('./containers/Documents'));
const ManagerDashboard = asyncComponent(() => import('./containers/Colliers/Dashboard'));
const LoanReview = asyncComponent(() => import('./containers/LoanReview/LoanReviewPage'));
const GuestLoanReview = asyncComponent(() => import('./containers/LoanReview/GuestLoanReviewPage'));
const CRMDashboard = asyncComponent(() => import('./containers/CRM/Dashboard'));
const PitchSurvey = asyncComponent(() => import('./containers/PitchSurvey/PitchSurvey'));
const LeadAssignment = asyncComponent(() => import('./containers/ICP/LeadAssignment'));
const Tutorial = asyncComponent(() => import('./containers/Tutorials'));

const Routes = ({
  isTempPassword, history, location,
}) => {
  useGaTracker();
  return <Switch>
    {/* unprotected routes */}
    <Route exact path='/' component={isAuth(dashboardRedirect(Dashboard))} />
    <Route exact path='/login' component={isNotAuth(LoginPage)} />
    <Route exact path='/request-access' component={isNotAuth(RequestAccess)} />
    <Route exact path='/manager-dashboard' component={isAuth(isPermitted(ManagerDashboard))} />
    <Route exact path='/originator-dashboard' component={isAuth(isPermitted(ManagerDashboard))} />
    <Route exact path='/admin-dashboard' component={isAuth(isPermitted(ManagerDashboard))} />
    <Route exact path='/crm-dashboard' component={isAuth(isPermitted(CRMDashboard))} />
    <Route exact path='/lead-assignment' component={isAuth(isPermitted(LeadAssignment))} />
    {/* <Route exact path='/dashboard-lender' component={isAuth(DashboardLender)} /> */}
    <Route exact path='/not-verified' component={NotVerifiedUser} />
    <Route exact path='/not-active' component={isNotAuth(NotActiveUser)} />
    <Route exact path='/not-permitted' component={NotPermittedPage} />
    <Route exact path='/thank-you/:email' component={isNotAuth(ThankYouPage)} />
    <Route exact path='/verify-account/:token' component={isNotAuth(VerifyAccountPage)} />
    <Route exact path='/forgot-password' component={isNotAuth(ForgotPassword)} />
    <Route exact path='/reset/:token' component={isNotAuth(ResetPassword)} />
    {/* protected routes */}
    <Route exact path='/bonds-rates' component={isAuth(isPermitted(MarketDataPage))} />
    <Route exact path='/manage-properties' component={isAuth(isPermitted(ManagePropertiesPage))} />
    <Route exact path='/add-loan' component={isAuth(isPermitted(AddLoanPage))} />
    <Route exact path='/reporting' component={isAuth(ReportingPage)} />
    <Route
      exact
      path='/add-loan/:id/:address/:lender/:loanAmount/:term/:amortization/:interestRate/:ltv'
      component={isAuth(isPermitted(AddLoanPage))}
    />
    <Route exact path='/view-loan/:id' component={isAuth(isPermitted(ViewLoansPage))} />
    <Route exact path='/edit-loan/:id' component={isAuth(isPermitted(EditLoanPage))} />
    <Route exact path='/add-line' component={isAuth(isPermitted(AddLinePage))} />
    <Route exact path='/edit-line/:id' component={isAuth(isPermitted(EditLinePage))} />
    <Route exact path='/view-line/:id' component={isAuth(isPermitted(ViewLinePage))} />
    <Route exact path='/add-blanket' component={isAuth(isPermitted(AddBlanketPage))} />
    <Route exact path='/edit-blanket/:id' component={isAuth(isPermitted(EditBlanketPage))} />
    <Route exact path='/view-blanket/:id' component={isAuth(isPermitted(ViewBlanketPage))} />
    <Route exact path='/calculators' component={isAuth(isPermitted(CalculatorPage))} />
    <Route exact path='/tools/calculators' component={CalculatorIntegration} />
    <Route exact path='/service/:type' component={isAuth(isPermitted(ServicesTable))} />
    <Route exact path='/service/request-new/:type/:id?' component={isAuth(isPermitted(ServicesForm))} />

    <Route exact path='/consultants' component={isAuth(ConsultantsListPage)} />
    <Route exact path='/consultants/:dealId?' component={isAuth(isPermitted(ConsultantsPage))} />
    <Route exact path='/consultants/view/:id' component={isAuth(isPermitted(ConsultantsSingle))} />

    <Route exact path='/lenders/list/:country' component={isAuth(LendersListPage)} />
    <Route exact path='/lenders/list/:country/:dealId?' component={isAuth(isPermitted(LendersPage))} />
    <Route exact path='/lenders/view/:id' component={isAuth(isPermitted(LenderSinglePage))} />
    <Route exact path='/lenders/edit/:id' component={isAuth(isPermitted(LenderEditPage))} />

    <Route exact path='/term-and-conditions' component={TermsAndConditions} />
    <Route exact path='/privacy-policy' component={PrivacyPolicy} />
    <Route exact path='/deals/draft' component={isAuth(isPermitted(DealsDraft))} />
    <Route exact path='/deals/in-progress' component={isAuth(isPermitted(DealsInProgress))} />
    <Route exact path='/deals/archived' component={isAuth(isPermitted(DealsArchived))} />
    <Route exact path='/deals/pending' component={isAuth(DealsApproval)} />
    <Route exact path='/deals/inactive' component={isAuth(DealsInActive)} />
    <Route exact path='/deal/track/lender-bids/:id' component={isAuth(isPermitted(DealsLendersBids))} />
    <Route exact path='/deal/track/consultant-bids/:id' component={isAuth(DealsConsultantsBids)} />
    <Route exact path='/deal/track/prefundingTracker' component={isAuth(PrefundingTracker)} />
    <Route exact path='/guest/prefundingTracker/:dealId/:guestToken' component={GuestPreFundingTracker} />
    <Route exact path='/deal/:id' component={isAuth(isPermitted(DealForm))} />
    <Route exact path='/deal/pdf/:id' component={DealPDF} />

    <Route exact path='/deal/preview/:id/:bidauth?' component={isPermitted(DealPreview)} />
    <Route exact path='/profile' component={isAuth(Profile)} />
    <Route exact path='/users' component={isAuth(isPermitted(RelatedUsers))} />
    <Route exact path='/deals/email' component={isAuth(isPermitted(DealsEmailPage))} />
    <Route exact path='/deal-bid/:id?' component={isNotAuth(GuestUserDeal)} />
    <Route exact path='/deal-consultant-bid/:id?' component={isNotAuth(GuestUserDeal)} />
    <Route exact path='/test-email/:id?' component={isNotAuth(GuestUserDeal)} />
    <Route exact path='/documents' component={isAuth(isPermitted(Documents))} />
    <Route exact path='/expired-link' component={isNotAuth(ExpiredLink)} />
    <Route exact path='/report/:id' component={isAuth(LoanReview)} />
    <Route exact path='/tutorials' component={isAuth(Tutorial)} />
    <Route exact path='/guest/report/:reportId/:guestToken' component={GuestLoanReview} />
    <Route exact path='/guest/survey/:surveyId/:token/' component={PitchSurvey} />
    <Route component={NotFoundPage} />
  </Switch>;
};

export default withRouter(Routes);
