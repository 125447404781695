import { call, takeLatest } from 'redux-saga/effects';
import { fetchConsultant } from 'actions/consultants';
import { consultantsApi } from 'services/consultants';
import fetchEntity from './fetch-entity';

const fetchConsultantsData = fetchEntity.bind(null, fetchConsultant.actions, consultantsApi);

export function* loadConsultants({ params }) {
  yield call(fetchConsultantsData, { ...params });
}

function* watchfetchConsultant() {
  yield takeLatest(fetchConsultant.actionName, loadConsultants);
}

export default {
  watchfetchConsultant,
};
