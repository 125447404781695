import { createSelector } from 'reselect';
import {
  getUserCompanies as fetchUserCompanies,
  updateUserCompany,
  addUserCompanyLogo,
  deleteUserCompanyLogo,
} from 'actions/user-companies';
import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';

export const getUserCompaniesState = (state) => state.userCompanies;
export const getErrorsState = (state) => state.error;
export const getUploadsState = (state) => state.uploads;

export const getUserCompanies = createSelector(
  getUserCompaniesState,
  (state) => ({ ...state, data: state.data || [] }),
);

export const getSuccessState = createSelector(
  getUserCompaniesState,
  (state) => state.isSuccessful,
);

export const userCompaniesLoading = createLoadingSelector(fetchUserCompanies.actionName)();
export const userCompaniesError = createErrorSelector(fetchUserCompanies.actionName)();

export const updateUserCompanyLoading = createLoadingSelector(updateUserCompany.actionName)();
export const updateUserCompanyError = createErrorSelector(updateUserCompany.actionName)();

export const getUserCompanyLogo = createSelector(getUploadsState, (uploads) => uploads[addUserCompanyLogo.actionName]);

export const addUserCompanyLogoLoading = createLoadingSelector(addUserCompanyLogo.actionName)();
export const addUserCompanyLogoError = createErrorSelector(addUserCompanyLogo.actionName)();

export const deleteUserCompanyLogoLoading = createLoadingSelector(deleteUserCompanyLogo.actionName)();
export const deleteUserCompanyLogoError = createErrorSelector(deleteUserCompanyLogo.actionName)();
