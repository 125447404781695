export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (e) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const { user } = state;
    if (user.user.loggedInUser.accessToken) {
      const serializedState = JSON.stringify({
        user,
      });
      localStorage.setItem('state', serializedState);
    }
  } catch (e) {
    // ignore errors
  }
};

export const emptyState = () => {
  localStorage.removeItem('state');
};

export const getAccessToken = () => {
  const state = loadState();
  return (state && state.user && state.user.user && state.user.user.loggedInUser
    && state.user.user.loggedInUser.accessToken) || '';
};

/**
 * Stores auth toke from Matrix in localstorage
 * @param {String} token - Token from Matrix login API.
 */
export const storeMatrixToken = (token) => {
  localStorage.setItem('matrixAuthToken', token);
};

/**
 * Retrieves the auth token for Matrix APIs from localstorage.
 * @returns {String|null} auth token for Matrix API.
 */
export const getMatrixToken = () => {
  const matrixAuthToken = localStorage.getItem('matrixAuthToken');
  return matrixAuthToken;
};
