import {
  call, takeLatest, select,
} from 'redux-saga/effects';
import {
  fetchDealsLenderBids, createBid, updateBid, deleteBid, patchBid, changeGuestLinkStatus,
} from 'actions/deals-lender-bids';
import {
  fetchDealsLenderBidsApi,
  postNewDealsLenderBid,
  updateDealsLenderBidsApi,
  deleteDealsLenderBidsApi,
  patchDealsLenderBidsApi,
  changeGuestLinkStatusApi,
} from 'services/deals-lender-bids';
import fetchEntity from './fetch-entity';

const fetchDealsLenderBidsData = fetchEntity.bind(
  null,
  fetchDealsLenderBids.actions,
  fetchDealsLenderBidsApi,
);
const fetchAddNewDealsLenderBids = fetchEntity.bind(
  null,
  createBid.actions,
  postNewDealsLenderBid,
);
const fetchUpdateDealsLenderBid = fetchEntity.bind(
  null,
  updateBid.actions,
  updateDealsLenderBidsApi,
);
const fetchPatchDealsLenderBid = fetchEntity.bind(
  null,
  patchBid.actions,
  patchDealsLenderBidsApi,
);
const fetchDeleteDealsLenderBid = fetchEntity.bind(
  null,
  deleteBid.actions,
  deleteDealsLenderBidsApi,
);
const updateGuestLinkStatus = fetchEntity.bind(
  null, changeGuestLinkStatus.actions, changeGuestLinkStatusApi,
);

export function* loadFetchDealsBidsData({ params }) {
  yield call(
    fetchDealsLenderBidsData,
    { ...params },
  );
}
export function* loadAddNewDealsLenderBids({ params }) {
  yield call(fetchAddNewDealsLenderBids, { ...params });
}
export function* loadUpdateDealsLenderBids({ params }) {
  yield call(fetchUpdateDealsLenderBid, { ...params });
}
export function* loadPatchDealsLenderBids({ params }) {
  yield call(fetchPatchDealsLenderBid, { ...params });
}
export function* loadDeleteDealsLenderBid({ params }) {
  yield call(fetchDeleteDealsLenderBid, { ...params });
}
export function* loadDealsLenderBidsOnChange({ params }) {
  const dealsLenderBids = yield select((state) => state.dealsLenderBids);
  const dealId = dealsLenderBids && dealsLenderBids[0].dealId;
  const { total, skip, limit } = dealsLenderBids;
  if (skip && skip >= total) {
    yield call(fetchDealsLenderBidsData, {
      ...params,
      $skip: total - Math.max(total % limit, limit),
      '$sort[updatedAt]': -1,
      dealId,
    });
  } else {
    yield call(fetchDealsLenderBidsData, {
      $skip: skip,
      '$sort[updatedAt]': -1,
      dealId,
    });
  }
}
export function* loadChangeGuestLinkStatus({ params }) {
  yield call(updateGuestLinkStatus, { ...params });
}

function* watchFetchDealsLenderBids() {
  yield takeLatest([fetchDealsLenderBids.actionName], loadFetchDealsBidsData);
}
function* watchAddNewDealsLenderBids() {
  yield takeLatest(createBid.actionName, loadAddNewDealsLenderBids);
}
function* watchUpdateDealsLenderBids() {
  yield takeLatest(updateBid.actionName, loadUpdateDealsLenderBids);
}
function* watchPatchDealsLenderBids() {
  yield takeLatest(patchBid.actionName, loadPatchDealsLenderBids);
}
function* watchDeleteDealsLenderBid() {
  yield takeLatest(deleteBid.actionName, loadDeleteDealsLenderBid);
}
function* watchDealsLenderBidsOnChange() {
  yield takeLatest([
    deleteBid.requestTypes.SUCCESS,
  ], loadDealsLenderBidsOnChange);
}
function* watchChangeGuestLinkStatus() {
  yield takeLatest(changeGuestLinkStatus.actionName, loadChangeGuestLinkStatus);
}

export default {
  watchFetchDealsLenderBids,
  watchAddNewDealsLenderBids,
  watchUpdateDealsLenderBids,
  watchDeleteDealsLenderBid,
  watchDealsLenderBidsOnChange,
  watchPatchDealsLenderBids,
  watchChangeGuestLinkStatus,
};
