import { makeRequestAction } from './index';

export const getOwnerContact = makeRequestAction('GET_OWNER_CONTACT', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const getAllContacts = makeRequestAction('GET_ALL_CONTACTS', {
  onSuccess(params, response) {
    return {
      response: [
        ...response,
      ],
    };
  },
});

export const addOwnerContact = makeRequestAction('ADD_OWNER_CONTACT', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const updateOwnerContact = makeRequestAction('UPDATE_OWNER_CONTACT', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const deleteOwnerContact = makeRequestAction('DELETE_OWNER_CONTACT', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});
