import { call, takeLatest } from 'redux-saga/effects';
import {
  addNoteAction,
  updateNoteAction,
  deleteNoteAction,
} from 'actions/colliers-notes';
import { addNoteApi, updateNoteApi, removeNoteApi } from 'services/colliers-notes';
import fetchEntity from './fetch-entity';

// ADD Note
const addNote = fetchEntity.bind(null, addNoteAction.actions, addNoteApi);

export function* loadAddNote({ params }) {
  yield call(addNote, { ...params });
}

function* watchAddNote() {
  yield takeLatest(addNoteAction.actionName, loadAddNote);
}

// UPDATE Note
const updateNote = fetchEntity.bind(null, updateNoteAction.actions, updateNoteApi);

export function* loadUpdateNote({ params }) {
  yield call(updateNote, { ...params });
}

function* watchUpdateNote() {
  yield takeLatest(updateNoteAction.actionName, loadUpdateNote);
}

// DELETE Note
const deleteNote = fetchEntity.bind(null, deleteNoteAction.actions, removeNoteApi);

export function* loadDeleteNote({ params }) {
  yield call(deleteNote, { ...params });
}

function* watchDeleteNote() {
  yield takeLatest(deleteNoteAction.actionName, loadDeleteNote);
}

export default {
  watchAddNote,
  watchUpdateNote,
  watchDeleteNote,
};
