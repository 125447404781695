import React from 'react';
import styled from 'styled-components';

const CardWrapper = styled.div`
  width: 80%;
  height: 130px;
  border: 1px solid #ddd;
  border-radius: 5px;
  -webkit-box-shadow: 0px 1px 0px 1px rgb(223, 221, 223);
  -moz-box-shadow: 0px 1px 0px 1px rgb(223, 221, 223);
  box-shadow: 0px 1px 0px 1px rgb(223, 221, 223);
  display: block;
`;
const CardWrapperHeading = styled.div`
  text-align: center;
  font-size: 15px;
  color: #2a2a2a;
  padding-top: 22px;
  font-weight: 500;
`;
const CardWrapperBody = styled.div`
  position: relative;
  width: 100%;
  .hide {
    display: none;
  }
  &:hover {
    .hide {
      display: block;
    }
  }
`;
const Text = styled.p`
  text-align: center;
  padding-top: 10px;
  font-weight: 500;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const ToolTip = styled.div`
  background-color: white;
  padding: 7px;
  border-radius: 5px;
  font-size: 12px;
  box-shadow: 0 2px 10px 0 rgba(73, 98, 245, 0.4);
  position: absolute;
  z-index: 22;
  bottom: -78px;
  width: 100%;
`;

const ModalDetailCard = (props) => (
  <CardWrapper>
    <CardWrapperHeading> {props.heading} </CardWrapperHeading>
    <CardWrapperBody>
      {props.tooltip && <ToolTip className='hide'>{props.text}</ToolTip>}
      <Text>{props.text}</Text>
    </CardWrapperBody>
  </CardWrapper>
);

export default ModalDetailCard;
