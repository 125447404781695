import {
  getSavedConsultants,
  getSavedLenders,
  saveConsultantDraft,
  saveLenderDraft,
  getLenderDraftEmail,
  getConsultantDraftEmail,
} from 'actions/email-drafts';

const emailDrafts = () => {
  const initialState = {
    consultants: null,
    lenders: null,
    draftEmail: null,
    savedDraft: null,
  };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case getSavedConsultants.requestTypes.SUCCESS:
        return {
          ...state,
          consultants: response,
        };
      case getSavedLenders.requestTypes.SUCCESS:
        return {
          ...state,
          lenders: response,
        };
      case saveConsultantDraft.requestTypes.SUCCESS:
        return {
          lenders: null,
          consultants: null,
          draftEmail: null,
          savedDraft: response,
          isSuccessful: true,
        };
      case saveConsultantDraft.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case saveConsultantDraft.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case saveLenderDraft.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case saveLenderDraft.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case saveLenderDraft.requestTypes.SUCCESS:
        return {
          lenders: null,
          consultants: null,
          draftEmail: null,
          savedDraft: response,
          isSuccessful: true,
        };
      case getLenderDraftEmail.requestTypes.SUCCESS:
        return {
          ...state,
          savedDraft: null,
          draftEmail: response,
        };
      case getConsultantDraftEmail.requestTypes.SUCCESS:
        return {
          ...state,
          savedDraft: null,
          draftEmail: response,
        };
      default:
        return state;
    }
  };
};

export default emailDrafts();
