import merge from 'deepmerge';
import { combineReducers } from 'redux';

const lines = () => (
  state = {
    // inital states here
    linesData: null,
    createdLine: null,
  },
  action,
) => {
  if (action.type === 'LINES_SUCCESS' && action.response.entities) {
    return merge.all([state, action.response.entities], {
      clone: false,
      arrayMerge: (destinationArray, sourceArray) => sourceArray,
    });
  }
  if (action.type === 'ADD_LINE_SUCCESS' && action.response.entities) {
    return {
      ...state,
      createdLine: action.response.entities,
    };
  }
  return state;
};

export default combineReducers({
  lines: lines(),
});
