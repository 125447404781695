import { makeRequestAction } from './index';

export const getTerritories = makeRequestAction('GET_TERRITORIES', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});
