import client from './main-client';

// this will get all the prefunding data corresponding to deal -> usually called from src/containers/Deals/PreFundingTracker.js
export const getPreFundingDataApi = (params) => client().get(`/deals/${params.dealId}?prefunding=show`, { params });

// this will add PreFundingDocuments to corresponding deal, data has dealId -> usually called from src/containers/Deals/PreFundingTracker.js
// export const addPreFundingDataApi = (data) => client().post('/prefunding-documents', data);
export const addPreFundingDataApi = (data) => {
  const formData = new FormData();
  data.files && data.files.length > 0 && Array.from(data.files).forEach((file) => {
    formData.append('files', file);
  });
  formData.append('reqData', JSON.stringify(data));
  return client({ 'Content-type': 'application/x-www-form-urlencoded' }).post('/prefunding-documents', formData);
};

// this will update PreFundingDocuments corresponding to deal. Usually called from src/containers/Deals/PreFundingTracker.js
export const updatePreFundingDataApi = (data, params) => {
  const formData = new FormData();
  data.files && data.files.length > 0 && Array.from(data.files).forEach((file) => {
    formData.append('files', file);
  });

  formData.append('reqData', JSON.stringify(data));
  if (data.reset) {
    return client().patch(`/prefunding-documents/${data.preFundingDocumentId}?reset=true&dealId=${data.dealId}`, formData, { params });
  }

  return client().patch(`/prefunding-documents/${data.preFundingDocumentId}?dealId=${data.dealId}&guestToken=${data.guestToken}`, formData);
};

// this will delete PreFundingDocuments corresponding to deal. Usually called from src/containers/Deals/PreFundingTracker.js
export const deletePreFundingDataApi = (data) => client().delete(`/prefunding-documents/${data.preFundingDocumentId}`);

// this will update preFundingDocuments when changing primary lender
export const resetPreFundingDataApi = (data) => client().put(`/deals/${data.dealId}?primary-lender=change&data=reset`, data);

// this is to add/remove syndicate lenders
export const addSyndicateLendersApi = (data) => client().patch(`/deals-lender-bids/${data.bidId}`, data);

// this will get us documents count on DealLenderBids
export const getTrackerCountApi = (params) => client().get(`/prefunding-documents?dealId=${params.dealId}&show=documentCount`, { params });

// this will generate new preFundingGuestToken
export const generatePrefundingTokenApi = (data) => client().put(`/deals/${data.dealId}?prefundingGuestToken=create`);

// this will get prefunding data from guest(shareable link) page
export const getShareableLinkDataApi = (params) => client().get(`/deals/${params.dealId}?prefunding=show&guestToken=${params.guestToken}`);

// this will delete prefunding file inside any prefunding document
export const deletePreFundingFileApi = (params) => client()
  .delete(`prefunding-documents/${params.preFundingDocumentId}?fileDelete=true&fileToBeDeleted=${params.fileToBeDeleted}`);
