import {
  fetchDeals,
  addDeal,
  getDeal,
  addConfAgreUpload,
  updateDeal,
  patchDeal,
  getHouseDeals,
} from 'actions/deals';

import { createSelector } from 'reselect';
import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';

export const getDealsState = (state) => state.deals;
export const getDeals = createSelector(
  getDealsState,
  (data) => ((data.deals) ? data.deals : []),
);

export const getAllDeals = createSelector(
  getDealsState,
  (data) => ((data.allDeals) ? data.allDeals : []),
);

export const fetchHouseDeals = createSelector(
  getDealsState,
  (data) => ((data.houseDeals) ? data.houseDeals : []),
);

export const getSuccessState = createSelector(
  getDealsState,
  (data) => data.isSuccessful,
);

export const getSelectedDeal = createSelector(
  getDealsState,
  (data) => data.selectedDeal,
);

export const dealsValuesLoading = createLoadingSelector(fetchDeals.actionName)();
export const dealsValuesError = createErrorSelector(fetchDeals.actionName)();

export const houseDealsValuesLoading = createLoadingSelector(getHouseDeals.actionName)();
export const houseDealsValuesError = createErrorSelector(getHouseDeals.actionName)();

export const getupdateDealLoading = createLoadingSelector(updateDeal.actionName)();
export const getupdateDealError = createErrorSelector(updateDeal.actionName)();

export const getpatchDealLoading = createLoadingSelector(patchDeal.actionName)();
export const getpatchDealError = createErrorSelector(patchDeal.actionName)();

export const getAddDealLoading = createLoadingSelector(
  addDeal.actionName,
)();
export const getAddDealError = createErrorSelector(
  addDeal.actionName,
)();

export const getSelectedDealLoading = createLoadingSelector(
  getDeal.actionName,
)();
export const getSelectedDealError = createErrorSelector(
  getDeal.actionName,
)();

export const getAddConfAgreUploadSuccess = createLoadingSelector(addConfAgreUpload.actionName)();
