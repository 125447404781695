import { makeRequestAction } from './index';

export const lenderCategoryValues = makeRequestAction('LENDERCATEGORY', {
  onSuccess(params, response) {
    return {
      response: {
        entities: {
          lenderCategoryData: response,
        },
      },
    };
  },
});
