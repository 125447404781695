import { call, takeLatest } from 'redux-saga/effects';
import {
  propertiesValues, addPropertyValues, updatePropertyValues, removeProperty,
} from 'actions/properties';
import {
  propertiesApi, addPropertyApi, updatePropertyApi, removePropertyApi,
} from 'services/properties';
import fetchEntity from './fetch-entity';

const fetchPropertiesValues = fetchEntity.bind(null, propertiesValues.actions, propertiesApi);

export function* loadProperties({ params }) {
  yield call(fetchPropertiesValues, { ...params });
}

function* watchPropertiesValues() {
  yield takeLatest(propertiesValues.actionName, loadProperties);
}

const addPropertyPost = fetchEntity.bind(null, addPropertyValues.actions, addPropertyApi);

export function* addProperty({ params }) {
  yield call(addPropertyPost, { ...params });
}

function* watchAddProperty() {
  yield takeLatest(addPropertyValues.actionName, addProperty);
}

const updatePropertyPut = fetchEntity.bind(null, updatePropertyValues.actions, updatePropertyApi);

export function* updateProperty({ params }) {
  yield call(updatePropertyPut, { ...params });
}

function* watchUpdateProperty() {
  yield takeLatest(updatePropertyValues.actionName, updateProperty);
}

const removeDeleteProperty = fetchEntity.bind(null, removeProperty.actions, removePropertyApi);

export function* loadRemoveProperty({ params }) {
  yield call(removeDeleteProperty, { ...params });
}

function* watchRemoveProperty() {
  yield takeLatest(removeProperty.actionName, loadRemoveProperty);
}

export default {
  watchPropertiesValues,
  watchAddProperty,
  watchUpdateProperty,
  watchRemoveProperty,
};
