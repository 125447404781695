import {
  updateListingNote,
  deleteListingNote,
  addListingLeadsNote,
  getRealtorListingNotes,
  getAllListingLeadsNotes,
} from 'actions/listingLeads-notes';

const listingLeadsNotes = () => {
  const initialState = {
    listingLeadsNotes: null,
    data: null,
    noteAdded: null,
    noteUpdated: null,
    realtorNotes: [],
  };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case getAllListingLeadsNotes.requestTypes.REQUEST:
        return {
          ...state,
        };
      case getAllListingLeadsNotes.requestTypes.SUCCESS:
        return {
          ...state,
          listingLeadsNotes: response,
        };
      case addListingLeadsNote.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case addListingLeadsNote.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case addListingLeadsNote.requestTypes.SUCCESS:
        return {
          ...state,
          isSuccessful: true,
          noteAdded: response,
        };
      case updateListingNote.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateListingNote.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateListingNote.requestTypes.SUCCESS:
        return {
          ...state,
          isSuccessful: true,
          noteUpdated: response,
        };
      case deleteListingNote.requestTypes.SUCCESS:
        return {
          ...state,
          data: response,
          isSuccessful: true,
        };
      case getRealtorListingNotes.requestTypes.SUCCESS:
        return {
          ...state,
          isSuccessful: true,
          realtorNotes: response,
        };
      default:
        return state;
    }
  };
};

export default listingLeadsNotes();
