import { call, takeLatest } from 'redux-saga/effects';
import {
  getMarkets, getAllMarkets,
} from 'actions/markets';
import {
  getMarketsApi,
} from 'services/markets';
import fetchEntity from './fetch-entity';

const getMarketsData = fetchEntity.bind(
  null,
  getMarkets.actions,
  getMarketsApi,
);

export function* loadGetMarkets({ params }) {
  yield call(getMarketsData, { ...params });
}

function* watchGetMarkets() {
  yield takeLatest(getMarkets.actionName, loadGetMarkets);
}

const getAllMarketsData = fetchEntity.bind(
  null,
  getAllMarkets.actions,
  getMarketsApi,
);

export function* loadGetAllMarkets({ params }) {
  yield call(getAllMarketsData, { ...params });
}

function* watchGetAllMarkets() {
  yield takeLatest(getAllMarkets.actionName, loadGetAllMarkets);
}

export default {
  watchGetMarkets,
  watchGetAllMarkets,
};
