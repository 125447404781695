import { makeRequestAction } from './index';

export const fetchLenderContacts = makeRequestAction('FETCH_LENDER_CONTACTS', {
  onSuccess(params, response) {
    return {
      response: {
        entities: {
          lenderContacts: response,
        },
      },
    };
  },
});

export const addNewContact = makeRequestAction('ADD_NEW_CONTACT');

export const editContact = makeRequestAction('EDIT_CONTACT');
