import { API_ROOT } from 'configs/env-vars';
import { makeRequestAction } from './index';

export const getUserCompanies = makeRequestAction('GET_USER_COMPANIES', {
  onSuccess(params, response) {
    return {
      response,
    };
  },
});

export const updateUserCompany = makeRequestAction('UPDATE_USER_COMPANY', {
  onSuccess(params, response) {
    return {
      response,
    };
  },
});

export const addUserCompanyLogo = makeRequestAction('ADD_USER_COMPANY_UPLOADS', {
  onSuccess(params, response) {
    return {
      files: response.map((file) => ({
        uid: file.hashedId,
        name: file.fileName,
        url: `${API_ROOT}/file-storage/${file.hashedId}`,
      })),
    };
  },
});

export const deleteUserCompanyLogo = makeRequestAction('DELETE_USER_COMPANY_UPLOADS', {
  onSuccess(params, response) {
    return {
      deletedFileId: response.hashedId,
    };
  },
});
