import React, { useState, useEffect } from 'react';
import { Modal, Icon } from 'antd';

// eslint-disable-next-line import/no-unresolved
import domtoimage from 'dom-to-image';
import JsPDF from 'jspdf';
import finneoLogoSrc from '../../assets/imgs/finneoColliersTechstars2.png';

const PRINT_ASPECT_RATIOS = {
  constructionPortfolioVitals: 0.2,
  termPortfolioVitals: 0.2,
  loanExpiriesAndPrincipalInterestCharts: 0.4,
  lenderDiversificationAndSpeedometerCharts: 0.4,
  finneoLogo: 0.291,

  // view-loan page props
  loanPageTitle: 0.07,
  loanPagePropertyDetails: 0.6,
  constructionLoanTitle: 0.07,
  buildingMetrics: 0.3,
  revenueDetails: 0.35,
  usesAndSources: 0.65,
  projectMetrics: 0.3,
  termLoanTitle: 0.07,
  loanPageValuation: 0.4,
  colliersVitals: 0.15,
  colliersCharts: 0.5,
  colliersDeals: 0.7,
  clChartsRow1: 0.5,
  clChartsRow2: 0.5,
  clChartsRow3: 0.4,

};

function PrintPreviewModal(props) {
  const [compiledPdf, setCompiledPdf] = useState(null);

  useEffect(() => {
    if (props.showPrintPreviewModal) {
      createPdfDocument();
    }
    // eslint-disable-next-line
  }, [props.showPrintPreviewModal]);

  // forEach function that runs asynchronously. Waits for the first iteration callback to be done
  async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index += 1) {
      // eslint-disable-next-line no-await-in-loop
      await callback(array[index], index, array);
    }
  }

  async function createPdfDocument() {
    const logoWidth = 2;
    const companyLogo = new Image();
    const finneoLogo = new Image();
    // LOAD FINNEO LOGO
    if (finneoLogoSrc) {
      finneoLogo.src = `${finneoLogoSrc}`;
    }
    // LOAD COMPANY LOGO
    if (props.userCompany && props.userCompany.logo) {
      companyLogo.src = `${props.userCompany.logo}`;
    }
    const pdf = new JsPDF({ orientation: props.orientation, format: 'letter', unit: 'in' });
    // LANDSCAPE LOGOS
    if (props.orientation === 'l') {
      pdf.addImage(finneoLogo, 'PNG', 8.5, 0.15, logoWidth, logoWidth * PRINT_ASPECT_RATIOS.finneoLogo);
      if (props.userCompany && props.userCompany.logo) {
        pdf.addImage(companyLogo, 'PNG', 0.5, 0.15, logoWidth, logoWidth * PRINT_ASPECT_RATIOS.finneoLogo);
      }
      // PORTRAIT LOGOS
    } else if (props.orientation === 'p') {
      pdf.addImage(finneoLogo, 'PNG', 6.0, 0.15, logoWidth, logoWidth * PRINT_ASPECT_RATIOS.finneoLogo);
      if (props.userCompany && props.userCompany.logo) {
        pdf.addImage(companyLogo, 'PNG', 0.5, 0.15, logoWidth, logoWidth * PRINT_ASPECT_RATIOS.finneoLogo);
      }
    }

    let offsetTop = 1;

    // ALL OTHER PROPS IMAGES
    await asyncForEach(props.itemsSelectedForPrint, async (elementId) => {
      const element = document.getElementById(elementId);
      const image = await domtoimage.toPng(element, { encode: elementId === 'propertyLocations' });

      // PRINT RATIOS
      const printHeightMax = props.orientation === 'l' ? 8 : 10;
      const printWidth = props.orientation === 'l' ? 10 : 7.5;
      const printHeight = PRINT_ASPECT_RATIOS[elementId] ? printWidth * PRINT_ASPECT_RATIOS[elementId] : 0.55 * printWidth;

      if (offsetTop + printHeight > printHeightMax) {
        offsetTop = 1;
        pdf.addPage({ format: 'letter' });
        pdf.addImage(finneoLogo, 'PNG', 8.5, 0.15, logoWidth, logoWidth * PRINT_ASPECT_RATIOS.finneoLogo);
        if (props.userCompany && props.userCompany.logo) {
          pdf.addImage(companyLogo, 'PNG', 1, 0.15, logoWidth, logoWidth * PRINT_ASPECT_RATIOS.finneoLogo);
        }
      }

      // OTHER IMAGES
      if (image.length > 0) {
        pdf.addImage(image, 'PNG', 0.5, offsetTop, printWidth, printHeight);
        offsetTop += (printHeight + 0.2);
      }
    });

    setCompiledPdf(true);
    pdf.save('document.pdf');
    props.setShowPrintPreviewModal(false);
    setCompiledPdf(null);
  }

  return <Modal
    visible={props.showPrintPreviewModal}
    footer={null}
    onCancel={() => props.setShowPrintPreviewModal(false)}
    >
    {compiledPdf === null ? <div>Creating PDF... <Icon type='loading' /></div> : compiledPdf}
  </Modal>;
}

export default PrintPreviewModal;
