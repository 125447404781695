import React from 'react';
import styled, { css } from 'styled-components';
import { Icon } from 'antd';
import { SvgIcons } from 'components';

const AvarageRatingWrapper = styled.div`
  display: inline-block;
  text-align: center;
  .rating-inner-wrapper {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    background-color: #4962f5;
    box-shadow: 0 2px 10px 0 rgba(73, 98, 245, 0.4);
    color: #fff;
    text-align: center;
    font-size: 0.75rem;
    font-weight: bold;
    padding: 8px 18px;
  }
  .rating-label {
    color: #475dfe;
    font-size: 0.75rem;
  }
  ${(props) => props.theme === 'tiny'
    && css`
      color: #4962f5;
      font-size: 0.6rem;
      float: right;
      padding: 5px;
      // border-left: 2px solid lightgrey;
  `}
  ${(props) => props.theme === 'iconOnly' && css`
    .rating-inner-wrapper {
      padding: 3px;
    }
  `}
`;

const AvarageRating = (props) => {
  switch (props.theme) {
    case 'tiny':
      return (
        <AvarageRatingWrapper theme='tiny'>
          {props.rating}
        </AvarageRatingWrapper>
      );
    case 'iconOnly':
      return (
        <AvarageRatingWrapper theme='iconOnly'>
          <div className={'rating-inner-wrapper'}>
            <div className={'rating-inner-icon'}>
              <Icon
                component={SvgIcons.ratingLableSvg}
                style={{ color: '#FFD000', fontSize: '0.75 rem' }}
                />
            </div>
          </div>
        </AvarageRatingWrapper>
      );
    default:
      return (
        <AvarageRatingWrapper>
          <div className={'rating-inner-wrapper'}>
            <div className={'rating-inner-icon'}>
              <Icon
                component={SvgIcons.ratingLableSvg}
                style={{ color: '#FFD000', fontSize: '0.75 rem' }}
                />
            </div>
            {props.rating}
          </div>
          <div className='rating-label'>RATING</div>
        </AvarageRatingWrapper>
      );
  }
};

/** @component */
export default AvarageRating;
