import { getAllUserDocuments, addDocument, removeDocument } from 'actions/documents-uploader';

const documentsUploader = () => {
  const initialState = {
    documentsData: null,
    isSuccessful: false,
  };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case getAllUserDocuments.requestTypes.SUCCESS:
        return {
          ...state,
          documentsData: response,
        };
      // case addDocument.requestTypes.REQUEST: {
      //   return {
      //     documetsData: response,
      //   };
      // }
      case addDocument.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case addDocument.requestTypes.SUCCESS:
        return {
          ...state,
          documentsData: response,
          isSuccessful: true,
        };
      case removeDocument.requestTypes.SUCCESS:
        return {
          ...state,
          isSuccessful: true,
          documentsData: response,
        };
      case removeDocument.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      default:
        return state;
    }
  };
};

export default documentsUploader();
