import { call, takeLatest } from 'redux-saga/effects';
import {
  getPropertyClasses,
} from 'actions/property-classes';
import {
  getPropertyClassesApi,
} from 'services/property-classes';
import fetchEntity from './fetch-entity';

const getPropertyClassesData = fetchEntity.bind(
  null,
  getPropertyClasses.actions,
  getPropertyClassesApi,
);

export function* loadGetPropertyClasses({ params }) {
  yield call(getPropertyClassesData, { ...params });
}

function* watchGetPropertyClasses() {
  yield takeLatest(getPropertyClasses.actionName, loadGetPropertyClasses);
}

export default {
  watchGetPropertyClasses,
};
