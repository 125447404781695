import client from './main-client';

export const fetchLendersApi = (params) => {
  if (params.getAll) {
    return client().get('/lender-company?getAll=true');
  }
  return client().get('/lender-company');
};

export const allLendersApi = () => client.get('/lender-company/?$limit=50000');

export const fetchLenderApi = ({ id, ...params }) => client()
  .get(`/lender-company/${id}`, { params });

export const updateLenderApi = (params) => client().patch(`/lender-company/${params.id}`, params.values);
