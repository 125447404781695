import styled from 'styled-components';
import { Input as input } from 'antd';
import reactComponentDebounce from 'react-component-debounce';

const StyledInput = styled(input)`
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  border-radius: 4px !important;
  background-color: #ffffff !important;
  height: 40px !important;
`;
/** @component */

/* NO-537 - Resolves the performance issue associated with slow typing in any input fields on large forms */
const DebouncedInput = reactComponentDebounce({
  valuePropName: 'value',
  triggerMs: 2500,
})(StyledInput);

export default DebouncedInput;
