import React, { Fragment } from 'react';
import { Col, Card, Statistic } from 'antd';
import propTypes from 'prop-types';

function StaticCustom(props) {
  const defaultValueStyle = { fontSize: '20px' };
  const value = parseInt(props.value, 10);
  defaultValueStyle.color = (value > 0) ? '#3f8600' : '#cf1322';
  if (!props.value || value === 0) defaultValueStyle.color = '#42404099';
  return (
    <Fragment>
      <Col span={props.colSpam || 6} style={props.columnStyle || { padding: '0.5em', height: '130px' }}>
        <Card style={props.style}>
          <Statistic
            title={props.title}
            value={props.value}
            valueStyle={props.valueStyle || defaultValueStyle}
            prefix={props.prefix}
            suffix={props.suffix}
        />
        </Card>
      </Col>
    </Fragment>
  );
}

StaticCustom.propTypes = {
  title: propTypes.string.isRequired,
  value: propTypes.any,
  colSpam: propTypes.number,
  columnStyle: propTypes.object,
  valueStyle: propTypes.object,
  prefix: propTypes.string,
  suffix: propTypes.string,
};

export default StaticCustom;
