import {
  call, takeLatest, put, select,
} from 'redux-saga/effects';
import {
  fetchInboxEmails,
  addInboxEmail,
  updateInboxEmail,
  getInboxEmail,
  deleteInboxEmail,
} from 'actions/inbox-emails';
import {
  fetchInboxEmailsApi,
  addInboxEmailApi,
  updateInboxEmailApi,
  getInboxEmailApi,
  deleteInboxEmailApi,
} from 'services/inbox-emails';
import fetchEntity from './fetch-entity';

const fetchInboxEmailsData = fetchEntity
  .bind(null, fetchInboxEmails.actions, fetchInboxEmailsApi);

const fetchAddInboxEmail = fetchEntity.bind(
  null,
  addInboxEmail.actions,
  addInboxEmailApi,
);

const fetchUpdateInboxEmail = fetchEntity.bind(
  null,
  updateInboxEmail.actions,
  updateInboxEmailApi,
);

const fetchDeleteInboxEmail = fetchEntity.bind(
  null,
  deleteInboxEmail.actions,
  deleteInboxEmailApi,
);

export function* loadFetchLenderData({ params }) {
  yield call(
    fetchInboxEmailsData,
    { ...params },
  );
}

export function* loadAddInboxEmail({ params }) {
  yield call(fetchAddInboxEmail, params);
}

export function* loadUpdateInboxEmail({ params }) {
  yield call(fetchUpdateInboxEmail, params);
}

export function* loadDeleteInboxEmail({ params }) {
  yield call(fetchDeleteInboxEmail, { ...params });
}

function* watchFetchInboxEmails() {
  yield takeLatest(fetchInboxEmails.actionName, loadFetchLenderData);
}
function* watchAddInboxEmail() {
  yield takeLatest(addInboxEmail.actionName, loadAddInboxEmail);
}

function* watchUpdateInboxEmail() {
  yield takeLatest(updateInboxEmail.actionName, loadUpdateInboxEmail);
}

function* watchDeleteInboxEmail() {
  yield takeLatest(deleteInboxEmail.actionName, loadDeleteInboxEmail);
}

const fetchInboxEmail = fetchEntity.bind(
  null,
  getInboxEmail.actions,
  getInboxEmailApi,
);

export function* loadGetInboxEmail({ params }) {
  yield put(getInboxEmail.actions.failure({}, undefined));
  yield call(fetchInboxEmail, params);
}

function* watchGetInboxEmail() {
  yield takeLatest(getInboxEmail.actionName, loadGetInboxEmail);
}

export function* loadInboxEmailsOnChange() {
  const inboxEmails = yield select((state) => state.inboxEmails);
  const { total, skip, limit } = inboxEmails;
  if (skip && skip >= total) {
    yield call(fetchInboxEmailsData, {
      $skip: total - Math.max(total % limit, limit),
      '$sort[updatedAt]': -1,
    });
  } else {
    yield call(fetchInboxEmailsData, { $skip: skip, '$sort[updatedAt]': -1 });
  }
}

function* watchInboxEmailsOnChange() {
  yield takeLatest([
    deleteInboxEmail.requestTypes.SUCCESS,
    addInboxEmail.requestTypes.SUCCESS,
  ], loadInboxEmailsOnChange);
}

export default {
  watchFetchInboxEmails,
  watchAddInboxEmail,
  watchGetInboxEmail,
  watchUpdateInboxEmail,
  watchInboxEmailsOnChange,
  watchDeleteInboxEmail,
};
