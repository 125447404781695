import { makeRequestAction } from './index';

export const propertiesValues = makeRequestAction('PROPERTIES', {
  onSuccess(params, response) {
    return {
      response: {
        entities: {
          propertiesData: response,
        },
      },
    };
  },
});

export const addPropertyValues = makeRequestAction('ADD_PROPERTY', {
  onSuccess(params, response) {
    return {
      response: {
        entities: {
          propertiesData: response,
        },
      },
    };
  },
});

export const updatePropertyValues = makeRequestAction('UPDATE_PROPERTY', {
  onSuccess(params, response) {
    return {
      response: {
        entities: {
          propertiesData: response,
        },
      },
    };
  },
});

export const removeProperty = makeRequestAction('REMOVE_PROPERTY', {
  onSuccess(params, response) {
    return {
      response: {
        entities: {
          deleteProperty: response,
        },
      },
    };
  },
});
