import {
  call, takeLatest, put,
} from 'redux-saga/effects';
import {
  getSavedConsultantApi,
  getSavedLenderApi,
  saveConsultantDraftApi,
  saveLenderDraftApi,
  getSavedLenderDraftEmailApi,
  getSavedConsultantDraftEmailApi,
} from 'services/email-drafts';
import { addUploadApi, deleteUploadApi } from 'services/uploads';
import {
  getSavedConsultants,
  getSavedLenders,
  saveConsultantDraft,
  saveLenderDraft,
  getConsultantDraftEmail,
  getLenderDraftEmail,
  addDraftUploads,
  deleteDraftUpload,
} from 'actions/email-drafts';
import fetchEntity from './fetch-entity';

const fetchSavedConsultants = fetchEntity
  .bind(null, getSavedConsultants.actions, getSavedConsultantApi);

export function* loadFetchSavedConsultants({ params }) {
  yield call(fetchSavedConsultants, params);
}

function* watchFetchSavedConsultants() {
  yield takeLatest(getSavedConsultants.actionName, loadFetchSavedConsultants);
}

const fetchSavedLenders = fetchEntity
  .bind(null, getSavedLenders.actions, getSavedLenderApi);

export function* loadFetchSavedLenders({ params }) {
  yield call(fetchSavedLenders, params);
}

function* watchFetchSavedLenders() {
  yield takeLatest(getSavedLenders.actionName, loadFetchSavedLenders);
}

const fetchSaveConsultantDraft = fetchEntity
  .bind(null, saveConsultantDraft.actions, saveConsultantDraftApi);

export function* loadFetchSaveConsultantDraft({ params }) {
  yield put({ type: 'CLEAR_UPLOADS' });
  yield call(fetchSaveConsultantDraft, params);
}

function* watchFetchSaveConsultantDraft() {
  yield takeLatest(saveConsultantDraft.actionName, loadFetchSaveConsultantDraft);
}

const fetchSaveLenderDraft = fetchEntity
  .bind(null, saveLenderDraft.actions, saveLenderDraftApi);

export function* loadFetchSaveLenderDraft({ params }) {
  yield put({ type: 'CLEAR_UPLOADS' });
  yield call(fetchSaveLenderDraft, params);
}

function* watchFetchSaveLenderDraft() {
  yield takeLatest(saveLenderDraft.actionName, loadFetchSaveLenderDraft);
}

const fetchLenderDraftEmail = fetchEntity
  .bind(null, getLenderDraftEmail.actions, getSavedLenderDraftEmailApi);

export function* loadFetchLenderDraftEmail({ params }) {
  yield call(fetchLenderDraftEmail, params);
}

function* watchFetchLenderDraftEmail() {
  yield takeLatest(getLenderDraftEmail.actionName, loadFetchLenderDraftEmail);
}

const fetchConsultantDraftEmail = fetchEntity
  .bind(null, getConsultantDraftEmail.actions, getSavedConsultantDraftEmailApi);

export function* loadFetchConsultantDraftEmail({ params }) {
  yield call(fetchConsultantDraftEmail, params);
}

function* watchFetchConsultantDraftEmail() {
  yield takeLatest(getConsultantDraftEmail.actionName, loadFetchConsultantDraftEmail);
}

const fetchAddSaveUploads = fetchEntity.bind(
  null,
  addDraftUploads.actions,
  addUploadApi,
);

export function* loadAddEmailUploads({ params }) {
  yield call(fetchAddSaveUploads, { ...params });
}

function* watchAddSaveUploads() {
  yield takeLatest(addDraftUploads.actionName, loadAddEmailUploads);
}

const fetchDeleteSaveUpload = fetchEntity.bind(
  null,
  deleteDraftUpload.actions,
  deleteUploadApi,
);

export function* loadDeleteSaveUploads({ params }) {
  yield put({ type: 'CLEAR_UPLOADS' });
  yield call(fetchDeleteSaveUpload, { ...params });
}

function* watchDeleteSaveUploads() {
  yield takeLatest(deleteDraftUpload.actionName, loadDeleteSaveUploads);
}

export default {
  watchFetchSavedConsultants,
  watchFetchSavedLenders,
  watchFetchSaveConsultantDraft,
  watchFetchSaveLenderDraft,
  watchFetchLenderDraftEmail,
  watchFetchConsultantDraftEmail,
  watchAddSaveUploads,
  watchDeleteSaveUploads,
};
