import merge from 'deepmerge';
import { combineReducers } from 'redux';

const portfolios = () => (
  state = {
    // inital states here
    portfoliosData: null,
  },
  action,
) => {
  if (action.type === 'PORTFOLIOS_SUCCESS' && action.response.entities) {
    return merge.all([state, action.response.entities], {
      clone: false,
      arrayMerge: (destinationArray, sourceArray) => sourceArray,
    });
  }
  return state;
};

export default combineReducers({
  portfolios: portfolios(),
});
