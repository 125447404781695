import { makeRequestAction } from './index';

export const fetchUserTargetValues = makeRequestAction('USER_TARGET', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const addUserTargetValues = makeRequestAction('ADD_USER_TARGET', {
  onFailure(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const updateUserTargetValues = makeRequestAction('UPDATE_USER_TARGET', {
  onFailure(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const fetchUserTargetAllyears = makeRequestAction('USER_TARGET_ALL_YEARS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});
