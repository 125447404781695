import client from './main-client';

export const fetchLeadsApi = (params) => client()
  .get(`leads?leadType=${params.leadType}${params.icpQueryStr
    && params.icpQueryStr !== 'undefined' ? `&icp=true&${params.icpQueryStr}` : ''}${params.filteredUserId
    ? `&filteredUserId=${params.filteredUserId}`
    : ''}`);

export const fetchRelatedLeadsApi = (params) => client()
  .get(`leads?leadType=refinance&getRelatedProperties=true&ownerId=${params.ownerId}`);

export const fetchPreviousLeadsApi = (params) => client()
  .get(`leads?leadType=${params.leadType}&state=${params.state}${params.filteredUserId
    ? `&filteredUserId=${params.filteredUserId}`
    : ''}`);

export const uploadLeadsApi = (data) => {
  const formData = new FormData();
  formData.append('file', data.file);
  formData.append('filteredUserId', [data.filteredUserId]);
  return client({ 'Content-type': 'application/x-www-form-urlencoded' })
    .post(`leads?leadType=${data.leadType}`, formData);
};

export const downloadLeadsApi = (params) => client()
  .get(`/leads?leadType=${params.leadType}&exports=true${params.filteredUserId
    ? `&filteredUserId=${params.filteredUserId}`
    : ''}`);

export const fetchTemplateLinkApi = (params) => client().get('/file-storage?fileName=leads-standard-template-underscores.csv');

export const fetchColliersLeadPermissionApi = (params) => client().get('/leads-roles-internal-permissions');
export const fetchFinneoLeadPermissionApi = (params) => client().get('/leads-roles-refinance-permissions');
export const fetchMarketLeadPermissionApi = (params) => client().get('/leads-roles-internal-market-permissions');

export const deleteUnassignColliersLeadsApi = (params) => client()
  .delete(`leads?leadType=${params.leadType}${params.isDelete
    ? `&isDelete=${params.isDelete}`
    : `&isUnassign=${params.isUnassign}`}${params.bulk && `&bulk=${params.bulk}`}`, { data: { ...params } });

export const assignColliersLeadsApi = (params) => client()
  .patch(`leads?leadType=${params.leadType}${params.isAssign ? `&isAssign=${params.isAssign}`
    : `&replaceOriginators=${params.replaceOriginators}`}`, params);

// export const unassignColliersLeadsApi = (params) => client()
//   .delete(`leads?companyName=${params.companyName}&leadType=${params.leadType}&isUnassign=${params.isUnassign}`, { data: { ...params } });

export const updatestageColliersLeadsApi = (params) => client()
  .patch(`leads?leadType=${params.leadType}&isSetStatus=${params.isSetStatus}`, params);

export const updateLeadsApi = (params) => client()
  .patch(`leads/${params._id}?leadType=${params.leadType}&update=lead`, params);
