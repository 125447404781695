import {
  fetchDealsLenderBids, createBid, updateBid, deleteBid, patchBid, changeGuestLinkStatus,
} from 'actions/deals-lender-bids';
import { Message } from 'antd';

const dealsLenderBids = () => {
  const initialState = {
    isFollowUpSuccessful: false,
  };

  return (state = initialState, action) => {
    const {
      type, response, ids, error,
    } = action;
    switch (type) {
      case fetchDealsLenderBids.requestTypes.SUCCESS:
        // state.isFollowUpSuccessful = !state.isFollowUpSuccessful
        return {
          ...response,
          isFollowUpSuccessful: state.isFollowUpSuccessful,
        };
      case fetchDealsLenderBids.requestTypes.FAILURE:

        return {
          ...response,
          isFollowUpSuccessful: false,
        };
      case deleteBid.requestTypes.SUCCESS:
        return {
          ...state,
          total: state.total - ids.length,
        };
      case createBid.requestTypes.REQUEST:
      case updateBid.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case patchBid.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,

        };
      case patchBid.requestTypes.SUCCESS:
        return {
          ...state,
          isFollowUpSuccessful: true,

        };
      // case changeGuestLinkStatus.requestTypes.REQUEST:
      //   return {
      //     ...state,
      //   };
      case changeGuestLinkStatus.requestTypes.SUCCESS: {
        Message.success("Link's status updated successfully");
        // eslint-disable-next-line
        const changed = Object.keys(state).filter((bid) => response.lenderBidId == state[bid].id);
        state[changed].isTokenActive = response.isActive;
        return { ...state };
      }
      case changeGuestLinkStatus.requestTypes.FAILURE: {
        Message.error('This token cannot be toggled');
        // eslint-disable-next-line
        const changed = Object.keys(state).filter((bid) => error.data.lenderBidId == state[bid].id);
        state[changed].isTokenActive = error.data.isActive;
        return { ...state };
      }
      default:
        return state;
    }
  };
};

export default dealsLenderBids();
