import merge from 'deepmerge';
import { combineReducers } from 'redux';

const bonds = () => (state = {
  // inital states here
  bondsData: null,
}, action) => {
  if (action.type === 'BONDS_SUCCESS' && action.response.entities) {
    return merge.all([state, action.response.entities], {
      clone: false,
      arrayMerge: (destinationArray, sourceArray) => sourceArray,
    });
  }
  return state;
};

export default combineReducers({
  bonds: bonds(),
});
