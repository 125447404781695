import {
  call, takeLatest,
} from 'redux-saga/effects';
import {
  getPropertyTypes, getAllPropertyTypes,
} from 'actions/property-types';
import {
  getPropertyTypesApi,
} from 'services/property-types';
import fetchEntity from './fetch-entity';

const getPropertyTypesData = fetchEntity
  .bind(null, getPropertyTypes.actions, getPropertyTypesApi);

export function* loadGetPropertyTypesData({ params }) {
  yield call(
    getPropertyTypesData,
    { ...params },
  );
}

function* watchGetPropertyTypesData() {
  yield takeLatest(getPropertyTypes.actionName, loadGetPropertyTypesData);
}

const getAllPropertyTypesData = fetchEntity
  .bind(null, getAllPropertyTypes.actions, getPropertyTypesApi);

export function* loadGetAllPropertyTypesData({ params }) {
  yield call(
    getAllPropertyTypesData,
    { ...params },
  );
}

function* watchGetAllPropertyTypesData() {
  yield takeLatest(getAllPropertyTypes.actionName, loadGetAllPropertyTypesData);
}

export default {
  watchGetPropertyTypesData,
  watchGetAllPropertyTypesData,
};
