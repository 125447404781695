/* eslint-disable no-useless-escape */
import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Row, Col, Typography, Card,
} from 'antd';
import {
  Input, FormItem,
} from 'components';

const { Title } = Typography;
const HeadingWrapper = styled.span`
  font-weight: 500;
  color: #2a2a2a;
`;
const ValueWrapper = styled.span`
  font-weight: 500;
  color: #727070;
`;
const ModalMortgageBox = (props) => {
  const { inEditMode } = props || { inEditMode: false };
  const { i } = props || { i: 0 };
  const [valuesChanged, setValuesChanged] = useState({});

  const { allMortgages } = props;

  React.useEffect(() => {
    if (allMortgages) {
      allMortgages[i] = { ...props.thisMortgage, ...valuesChanged };
      props.setMortgages(allMortgages);
    }
  }, [allMortgages, i, props, valuesChanged]);

  return (<>
    {!inEditMode ? <Card>
      <Title level={4}>{props.CardTitle}</Title>
      <Row>
        <Col span={12}>
          {props.PrincipalAmount && (<Row>
            <Col span={10}>
              <HeadingWrapper>Principal Amount</HeadingWrapper>
            </Col>
            <Col span={14}>
              <ValueWrapper>{props.PrincipalAmount}</ValueWrapper>
            </Col>
          </Row>)
          }
          {
            props.PrimaryLender && (
              <Row>
                <Col span={10}>
                  <HeadingWrapper>Primary Lender</HeadingWrapper>
                </Col>
                <Col span={14}>
                  <ValueWrapper>{props.PrimaryLender}</ValueWrapper>
                </Col>
              </Row>
            )
          }
          {props.TertiaryLender && (
            <Row>
              <Col span={10}>
                <HeadingWrapper>Tertiary Lender</HeadingWrapper>
              </Col>
              <ValueWrapper>{props.TertiaryLender}</ValueWrapper>
            </Row>
          )}
        </Col>
        <Col span={12}>
          {
            props.Maturity && (
              <Row>
                <Col span={10}>
                  <HeadingWrapper>Maturity</HeadingWrapper>
                </Col>
                <Col span={14}>
                  <ValueWrapper>{props.Maturity}</ValueWrapper>
                </Col>
              </Row>
            )
          }
          {props.SecondaryLender && (
            <Row>
              <Col span={10}>
                <HeadingWrapper>Secondary Lender</HeadingWrapper>
              </Col>
              <Col span={14}>
                <ValueWrapper>{props.SecondaryLender}</ValueWrapper>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </Card>
      : <>
        <Title level={4}>{props.CardTitle}</Title>
        <Row>
          <Row gutter={[8, 24]}>
            <FormItem label='Principal Amount'>
              <Input
                onChange={(e) => {
                  setValuesChanged({
                    ...valuesChanged,
                    principalAmount: {
                      $numberDecimal: e.target.value.includes('$') ? e.target.value.split('$')[1].replace(/\,/g, '').trim()
                        : e.target.value.replace(/\,/g, '').trim(),
                    },
                  });
                }}
                type='string'
                defaultValue={((props.PrincipalAmount) ? props.PrincipalAmount : '')} />
            </FormItem>
            <FormItem label='Primary Lender'>
              <Input
                onChange={(e) => setValuesChanged({ ...valuesChanged, primaryLender: e.target.value })}
                type='string'
                defaultValue={((props.PrimaryLender) ? props.PrimaryLender : '')} />
            </FormItem>
            <FormItem label='Tertiary Lender'>
              <Input
                onChange={(e) => setValuesChanged({ ...valuesChanged, tertiaryLender: e.target.value })}
                type='string'
                defaultValue={((props.TertiaryLender) ? props.TertiaryLender : '')} />
            </FormItem>
            <FormItem label='Maturity'>
              <Input
                onChange={(e) => setValuesChanged({ ...valuesChanged, maturityDate: e.target.value })}
                type='string'
                defaultValue={((props.Maturity) ? props.Maturity : '')} />
            </FormItem>
            <FormItem label='Secondary Lender'>
              <Input
                onChange={(e) => setValuesChanged({ ...valuesChanged, secondaryLender: e.target.value })}
                type='string'
                defaultValue={((props.SecondaryLender) ? props.SecondaryLender : '')} />
            </FormItem>
          </Row>
        </Row>
      </>

    }
  </>
  );
};

export default ModalMortgageBox;
