import {
  call, takeLatest, takeEvery,
} from 'redux-saga/effects';
import {
  getBidEmails, composeBidEmails, replyBidEmails, updateFavouriteThreads, updateFavouriteMessage,
  updateOpenedMessage, saveDraftEmail, updateSavedDraftEmail, downloadFile, deleteDraftEmail, updateDraftAttachments,
  updateDeleteDraftAttachments, deleteEmail,
} from 'actions/bids-email';
import {
  getBidEmailsApi, composeEmailApi, updateFavouriteThreadsApi, replyEmailApi, updateFavouriteMessageApi,
  updateOpenedMessageApi, saveDraftEmailApi, downloadUploadedFilesApi, updateSavedDraftEmailApi,
  deleteDraftEmailApi, updateSavedDraftAttachmentApi, deleteDraftAttachmentEmailApi, deleteEmailApi,
} from 'services/bids-email';
import fetchEntity from './fetch-entity';

const getBidEmailsData = fetchEntity
  .bind(null, getBidEmails.actions, getBidEmailsApi);

export function* loadBidEmails({ params }) {
  yield call(
    getBidEmailsData,
    { ...params },
  );
}

function* watchGetBidEmails() {
  yield takeLatest(getBidEmails.actionName, loadBidEmails);
}

const sendComposeEmail = fetchEntity.bind(null, composeBidEmails.actions, composeEmailApi);

export function* loadSendComposeEmail({ params }) {
  yield call(sendComposeEmail,
    { ...params });
}

function* watchSendComposeEmail() {
  yield takeLatest(composeBidEmails.actionName, loadSendComposeEmail);
}

const sendReplyEmail = fetchEntity.bind(null, replyBidEmails.actions, replyEmailApi);

export function* loadSendReplyEmail({ params }) {
  yield call(sendReplyEmail, { ...params });
}

function* watchSendReplyEmail() {
  yield takeLatest(replyBidEmails.actionName, loadSendReplyEmail);
}

const updateFavourites = fetchEntity.bind(null, updateFavouriteThreads.actions, updateFavouriteThreadsApi);

export function* loadUpdateFavourites({ params }) {
  yield call(updateFavourites,
    { ...params });
}

function* watchUpdateFavouritesThread() {
  yield takeLatest(updateFavouriteThreads.actionName, updateFavouriteThreadsApi);
}

const updateFavouriteMsg = fetchEntity.bind(null, updateFavouriteMessage.actions, updateFavouriteMessageApi);
export function* loadUpdateFavouritesMsg({ params }) {
  yield call(updateFavouriteMsg,
    { ...params });
}
function* watchUpdateFavouritesMsg() {
  yield takeEvery(updateFavouriteMessage.actionName, updateFavouriteMessageApi);
}

const updateMessage = fetchEntity.bind(null, updateOpenedMessage.actions, updateOpenedMessageApi);

export function* loadUpdateMessage({ params }) {
  yield call(updateMessage,
    { ...params });
}

function* watchUpdateMessage() {
  // originally, api call func was directly passed to watcher as in commented code,
  // but in that case, failure function in reducer case was not executing,
  // so it was changed to generator function....!!

  // yield takeLatest(updateOpenedMessage.actionName, updateOpenedMessageApi);
  yield takeLatest(updateOpenedMessage.actionName, loadUpdateMessage);
}

const saveNewDraft = fetchEntity.bind(null, saveDraftEmail.actions, saveDraftEmailApi);

export function* loadsaveDraftEmail({ params }) {
  yield call(saveNewDraft,
    { ...params });
}

function* watchsaveDraftEmail() {
  yield takeLatest(saveDraftEmail.actionName, loadsaveDraftEmail);
}

const updateSavedDraft = fetchEntity.bind(null, updateSavedDraftEmail.actions, updateSavedDraftEmailApi);

export function* loadUpdatedSavedDraft({ params }) {
  yield call(updateSavedDraft,
    { ...params });
}

function* watchUpdatedSavedDraft() {
  yield takeLatest(updateSavedDraftEmail.actionName, loadUpdatedSavedDraft);
}

const deleteDraft = fetchEntity.bind(null, deleteDraftEmail.actions, deleteDraftEmailApi);

export function* loadDeletedDraft({ params }) {
  yield call(deleteDraft,
    { ...params });
}

function* watchDeletedDraft() {
  yield takeLatest(deleteDraftEmail.actionName, loadDeletedDraft);
}

const deleteMail = fetchEntity.bind(null, deleteEmail.actions, deleteEmailApi);
export function* loadDeleteEmail({ params }) {
  yield call(deleteMail,
    { ...params });
}
function* watchDeleteEmail() {
  yield takeLatest(deleteEmail.actionName, loadDeleteEmail);
}

const downloadUploadedFiles = fetchEntity.bind(null, downloadFile.actions, downloadUploadedFilesApi);

export function* loadUploadedFiles({ params }) {
  yield call(downloadUploadedFiles,
    { ...params });
}

function* watchdownloadUploadedFiles() {
  yield takeLatest(downloadFile.actionName, downloadUploadedFilesApi);
}

const updateAttachmentDraftsEmail = fetchEntity.bind(null, updateDraftAttachments.actions, updateSavedDraftAttachmentApi);

export function* loadupdateAttachmentDraftsEmail({ params }) {
  yield call(updateAttachmentDraftsEmail,
    { ...params });
}

function* watchupdateAttachmentDraftsEmail() {
  yield takeLatest(updateDraftAttachments.actionName, loadupdateAttachmentDraftsEmail);
}

const updateAttachmentDeleteDraftsEmail = fetchEntity.bind(null, updateDeleteDraftAttachments.actions, deleteDraftAttachmentEmailApi);

export function* loadupdateDeleteAttachmentDraftsEmail({ params }) {
  yield call(updateAttachmentDeleteDraftsEmail,
    { ...params });
}

function* watchupdateDeleteAttachmentDraftsEmail() {
  yield takeLatest(updateDeleteDraftAttachments.actionName, loadupdateDeleteAttachmentDraftsEmail);
}

export default {
  watchGetBidEmails,
  watchDeleteEmail,
  watchSendComposeEmail,
  watchSendReplyEmail,
  watchUpdateFavouritesThread,
  watchUpdateFavouritesMsg,
  watchUpdateMessage,
  watchsaveDraftEmail,
  watchDeletedDraft,
  watchdownloadUploadedFiles,
  watchUpdatedSavedDraft,
  watchupdateAttachmentDraftsEmail,
  watchupdateDeleteAttachmentDraftsEmail,
};
