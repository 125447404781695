import { getmunicipalityLeads } from '../actions/search-leads';

const seachLeadsReducer = () => {
  const initialState = {
    municipalityLeads: null,
  };
  return (state = initialState, { type, response }) => {
    switch (type) {
      case getmunicipalityLeads.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: true,
        };
      case getmunicipalityLeads.requestTypes.SUCCESS:
        return {
          ...state,
          isSuccessful: false,
          municipalityLeads: response,
        };
      default:
        return {
          ...state,
        };
    }
  };
};

export default seachLeadsReducer();
