import { makeRequestAction } from './index';

export const linesValues = makeRequestAction('LINES', {
  onSuccess(params, response) {
    return {
      response: {
        entities: {
          linesData: response,
        },
      },
    };
  },
});

export const addLineValues = makeRequestAction('ADD_LINE', {
  onSuccess(params, response) {
    return {
      response: {
        entities: {
          linesData: response,
        },
      },
    };
  },
});

export const updateLineValues = makeRequestAction('UPDATE_LINE', {
  onSuccess(params, response) {
    return {
      response: {
        entities: {
          linesData: response,
        },
      },
    };
  },
});
