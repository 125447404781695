import styled, { css } from 'styled-components';
import propTypes from 'prop-types';
import { Card as card } from 'antd';

const Card = styled(card)`
  margin: ${(props) => props.margin + props.importance};
  border-radius: ${(props) => `6px ${props.importance}`};
  .hl {
    background: #efff00;
  }

  ${(props) => props.hoverable && props.theme === 'rates'
    && css`
      &:hover {
        background: #4962F5 ${props.importance};
        .ant-typography {
          color: #fff ${props.importance};
        }
      }
    `
}

  ${(props) => (props.active === 'active')
    && css`
      background: #4962F5 ${props.importance};
      .ant-typography {
        color: #fff ${props.importance};
      }
    `
}

  ${(props) => props.theme === 'rates'
    && css`
    i {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  `}

  ${(props) => props.theme === 'preview'
    && css`
      background: none !important;
      border: none !important;
      color: #000 !imporant;
      .ant-card-head-title {
        font-size: 22px !important;
        letter-spacing: 2px !important;
        text-transform: uppercase !important;
        color: rgb(0,0,0)!important !important;
        font-weight: bold;
      }
      .ant-card-body {
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        padding-bottom: 0;
        color: #000 !imporant;
      }
      .ant-card-head {
        padding-left: 0;
        padding-right: 0;
        border-bottom: 0;
      }
  `}

  ${(props) => props.theme === 'logo'
    && css`
      border: 0 !important;
      background: none !important;
      padding: 40px 40px 0 !important;
      .ant-card-body {
        padding: 20px 0;
      }
      .ant-card-cover img {
        box-shadow: 0 2px 5px -7px rgba(0,0,0,0.6) !important;
        border-radius: 3px !important;
        max-height: 70px;
        max-width: 150px;
        width: auto;
        height: auto;
      }
      .ant-card-meta-title {
        font-size: 18px !important;
        line-height: 17px !important;
        color: rgb(0,0,0)!important;
        font-weight: bold;
        white-space: pre-wrap!important;
      }
      .ant-card-meta-description {
        display: inline-block !important;
        font-size: 11px;
        font-weight: 400;
        color: #9F9F9F;
      }
  `}

  ${(props) => props.theme === 'preFundingLogo'
    && css`
      border: 0 !important;
      background: none !important;
      .ant-card-body {
        padding: 0px;
        margin: 0px;
      }
      .ant-card-cover img {
        padding: 0px;
        margin: 0px;
        box-shadow: 0 2px 5px -7px rgba(0,0,0,0.6) !important;
        border-radius: 3px !important;
        max-height: 70px;
        max-width: 7.5vw;
        min-height: 40px;
        min-width: 60px;
        width: auto;
        height: auto;
      }
  `}

  ${(props) => props.theme === 'lenderLogo'
    && css`
      border: 0 !important;
      background: none !important;
      .ant-card-body {
        padding: 0px;
        margin: 0px;
      }
      .ant-card-cover img {
        padding: 0px;
        margin: 0px;
        box-shadow: 0 2px 5px -7px rgba(0,0,0,0.6) !important;
        border-radius: 3px !important;
        max-height: 40px;
        max-width: 6.5vw;
        min-height: 40px;
        min-width: 60px;
        width: auto;
        height: auto;
      }
  `}

  ${(props) => props.theme === 'prefundingBoxes'
    && css`
      .ant-card-body {
        padding-right: 0px;
        padding-left: 0px;
      }
  `}

  ${(props) => props.theme === 'prefundingDateBox'
    && css`
      .ant-card-body {
        padding-right: 0px;
        padding-left: 0px;
        padding-top: 20px;
      }
  `}

  ${(props) => props.theme === 'bids'
    && css`
      border: 0 !important;
      background: none !important;
      padding: 0px 0px 0 !important;
      .ant-card-body {
        padding: 0px 0;
      }
      .ant-card-cover img {
        box-shadow: 0 2px 5px -7px rgba(0,0,0,0.6) !important;
        border-radius: 3px !important;
        max-height: 45px;
        max-width: 85px;
        width: auto;
        height: auto;
        cursor: pointer;
      }
      .ant-card-meta-title {
        font-size: 18px !important;
        line-height: 17px !important;
        color: rgb(0,0,0)!important;
        font-weight: bold;
        white-space: pre-wrap!important;
      }
      .ant-card-meta-description {
        display: inline-block !important;
        font-size: 11px;
        font-weight: 400;
        color: #9F9F9F;
      }
    `
}

  ${(props) => props.theme === 'centered'
    && css`
      background: none !important;
      padding: 0px 0px 0 !important;
      .ant-card-body {
        padding: 0;
      }
      .ant-card-cover {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .ant-card-cover img {
        box-shadow: 0 2px 5px -7px rgba(0,0,0,0.6) !important;
        border-radius: 3px !important;
        max-height: 45px;
        max-width: 85px;
        width: auto;
        height: auto;
        cursor: pointer;
      }
    `
}

  ${(props) => props.theme === 'lenderPage'
    && css`
      border: 0 !important;
      background: none !important;
      padding: 0px 0px 0 !important;
      .ant-card-body {
        padding: 0px 0;
      }
      .ant-card-cover img {
        box-shadow: 0 2px 5px -7px rgba(0,0,0,0.6) !important;
        border-radius: 3px !important;
        max-height: 35px;
        max-width: 40px;
        margin-top:10px;
        width: auto;
        height: auto;
      }
      .ant-card-meta-title {
        font-size: 18px !important;
        line-height: 17px !important;
        color: rgb(0,0,0)!important;
        font-weight: bold;
        white-space: pre-wrap!important;
      }
      .ant-card-meta-description {
        display: inline-block !important;
        font-size: 11px;
        font-weight: 400;
        color: #9F9F9F;
      }
    `
}

  ${(props) => props.theme === 'rates'
    && css`
      span {
        color: #B5B7CA ${props.importance};
        font-size: 12px;
      }
      div.value {
        font-size: 18px; ${props.importance};
        color: #000000;
        line-height: 21px;
        margin: 15px 0 0 0;
        font-weight: bold;
      }
      i {
        position: absolute;
        top: 25px;
        right: 25px;
        color: #C4C5D6;
        font-size: 18px;
      }
    `
}

  ${(props) => props.theme === 'viewDetail'
    && css`
      margin: 30px auto !important;
      width: 80%;
      border-radius: 10px;
      background-color: #fffcfc !important;
    `
}

  ${(props) => props.theme === 'loans'
    && css`
      margin: 0 0 0 0 !important;
    `
}

  ${(props) => props.theme === 'draftMessage'
    && css`
      margin-left: 10px !important;
      margin-top: 10px !important;
      margin-bottom: 0px !important;
      margin-right: 10px !important;
      box-shadow: 0 4px 2px 2px rgba(0,0,0,0.05);
    `
}

  ${(props) => props.theme === 'receivedMessage'
    && css`
      margin-left: 10px !important;
      margin-top: 10px !important;
      margin-bottom: 0px !important;
      margin-right: 10px !important;
      box-shadow: 0 4px 2px 2px rgba(0,0,0,0.05);
      .ant-card-head {
        background:#F7F7F7;
        padding:0 0 0 24px;
      }
    `
}

  ${(props) => props.theme === 'receivedUnreadMessage'
    && css`
      margin-left: 10px !important;
      margin-top: 10px !important;
      margin-bottom: 0px !important;
      margin-right: 10px !important;
      box-shadow: 0 4px 2px 2px rgba(0,0,0,0.05);
      .ant-card-head{
        background:#F7F7F7;
        padding:0 0 0 24px;
      }
      .unread{
        height:8px !important;
        width:8px !important;
        background-color:rgb(73, 97, 247) !important;
        border-radius: 50% !important;
        display:inline-block !important;
       }
       .name, .subject{
         font-weight:1000 !important;
       }

    `
}

  ${(props) => props.theme === 'collapsedReceivedMessage'
    && css`
      margin-left: 10px !important;
      margin-top: 10px !important;
      margin-bottom: 0px !important;
      margin-right: 10px !important;
      box-shadow: 0 4px 2px 2px rgba(0,0,0,0.05);
      .ant-card-head{
        background:#F7F7F7;
        padding:0 0 0 24px;
      }
      .ant-card-body{
        padding:0px! important;
      }
    `
}

  ${(props) => props.theme === 'collapsedReceivedUnreadMessage'
    && css`
      margin-left: 10px !important;
      margin-top: 10px !important;
      margin-bottom: 0px !important;
      margin-right: 10px !important;
      box-shadow: 0 4px 2px 2px rgba(0,0,0,0.05);
      .ant-card-head{
        background:#F7F7F7;
        padding:0 0 0 24px;
      }
      .ant-card-body{
        padding:0px! important;
      }
      .unread{
        height:8px !important;
        width:8px !important;
        background-color:rgb(73, 97, 247) !important;
        border-radius: 50% !important;
        display:inline-block !important;
       }
       .name, .subject{
         font-weight:1000 !important;
       }
    `
}

  ${(props) => props.theme === 'sentMessage'
    && css`
      margin-left: 10px !important;
      margin-top: 10px !important;
      margin-bottom: 0px !important;
      margin-right: 10px !important;
      padding-right:0px !important;
      box-shadow: 0 4px 2px 0 rgba(0,0,0,0.05);
      .ant-card-head{
        background:#F8FCFF;
        padding:0 0 0 24px;
      }
    `
}

  ${(props) => props.theme === 'collapsedSentMessage'
    && css`
      margin-left: 10px !important;
      margin-top: 10px !important;
      margin-bottom: 0px !important;
      margin-right: 10px !important;
      box-shadow: 0 4px 2px 2px rgba(0,0,0,0.05);
      .ant-card-head{
        background:#F8FCFF;
        padding:0 0 0 24px;
      }
      .ant-card-body{
        padding:0px! important;
      }
    `
}

  ${(props) => props.theme === 'collapsedDraftMessage'
    && css`
      margin-left: 10px !important;
      margin-top: 10px !important;
      margin-bottom: 0px !important;
      margin-right: 10px !important;
      box-shadow: 0 4px 2px 2px rgba(0,0,0,0.05);
      .ant-card-head{
        background:#FFF9FB !important
        padding:0 0 0 24px;
      }
      .ant-card-body{
        padding:0px! important;
      }
    `
}

  ${(props) => props.theme === 'selection'
    && css`
      border-radius: 0px !important;
      border-bottom: solid black 2px !important;
      width:100% !important;
      margin-top: -1px !important;
      margin-bottom: -1px !important;
    `
}

  ${(props) => props.theme === 'none'
    && css`
      margin-bottom: 0px !important;
      border-radius: 0px !important;
      cursor: pointer;
      .ant-card-body{
        padding: 12px!important;
      }
      .to{
        display:none !important;
      }
    `
}

  ${(props) => props.theme === 'receivedUnopened'
    && css`
      margin-bottom: 0px !important;
      border-radius: 0px !important;
      cursor: pointer;
      .ant-card-body{
        padding: 12px!important;
      }
      .unread{
        height:8px !important;
        width:8px !important;
        background-color:rgb(73, 97, 247) !important;
        border-radius: 50% !important;
        display:inline-block !important;
      }
      .sentTo{
        display: none
      }
      .name, .subject{
        font-weight:1000 !important;
      }
    `
}

  ${(props) => props.theme === 'sentUnopened'
    && css`
      margin-bottom: 0px !important;
      border-radius: 0px !important;
      cursor: pointer;
      .ant-card-body{
        padding: 12px!important;
      }
      .unread{
        height:8px !important;
        width:8px !important;
        background-color:rgb(73, 97, 247) !important;
        border-radius: 50% !important;
        display:inline-block !important;
      }
      .name{
        display: none
      }
      .sentTo, .subject{
        font-weight:1000 !important;
      }
    `
}

  ${(props) => props.theme === 'draft'
    && css`
      margin-bottom: 0px !important;
      border-radius: 0px !important;
      cursor: pointer;
      .ant-card-body{
        padding: 12px!important;
      }
      .emailType:before{
        content: "DRAFT" !important;
        color:#F1547D !important;
        font-weight:500 !important;
      }
      .to{
        display:none !important;
      }
      .name, .subject{
        font-weight:1000 !important;
      }
    `
}

  ${(props) => props.theme === 'draftOnly'
    && css`
      margin-bottom: 0px !important;
      border-radius: 0px !important;
      cursor: pointer;
      .ant-card-body{
        padding: 12px!important;
      }
      .emailType:before{
        content: "DRAFT" !important;
        color:#F1547D !important;
        font-weight:500 !important;
      }
      .name{
        display:none !important;
      }
      .sentTo, .subject{
        font-weight:1000 !important;
      }
      background-color:#FFF9FB !important;
    `
}

  ${(props) => props.theme === 'received'
    && css`
      margin-bottom: 0px !important;
      border-radius: 0px !important;
      cursor: pointer;
      .ant-card-body{
        padding: 12px!important;
      }.sentTo{
        display:none !important;
      }
      .name, .subject{
        font-weight:1000 !important;
      }
    `
}

  ${(props) => props.theme === 'selectedSent'
    && css`
      margin-bottom: 0px !important;
      border-radius: 0px !important;
      cursor: pointer;
      .ant-card-body{
        padding: 12px!important;
      }
      .name{
        display:none !important;
      }
      background-color:#1A2C3D !important;
      color: white!important;
      .sentTo, .subject{
        font-weight:1000 !important;
      }
    `
}

  ${(props) => props.theme === 'selectedReceived'
    && css`
      margin-bottom: 0px !important;
      border-radius: 0px !important;
      cursor: pointer;
      .ant-card-body{
        padding: 12px!important;
      }
      .sentTo{
        display:none !important;
      }
      background-color:#1A2C3D !important;
      color: white!important;
      .name, .subject{
        font-weight:1000 !important;
      }
    `
}

  ${(props) => props.theme === 'selectedUnopenedSent'
    && css`
      margin-bottom: 0px !important;
      border-radius: 0px !important;
      cursor: pointer;
      .ant-card-body{
        padding: 12px!important;
      }
      .name{
        display:none !important;
      }
      .unread{
        height:8px !important;
        width:8px !important;
        background-color:rgb(73, 97, 247) !important;
        border-radius: 50% !important;
        display:inline-block !important;
      }
      background-color:#1A2C3D !important;
      color: white!important;
      .sentTo, .subject{
        font-weight:1000 !important;
      }
    `
}

  ${(props) => props.theme === 'selectedUnopenedReceived'
    && css`
      margin-bottom: 0px !important;
      border-radius: 0px !important;
      cursor: pointer;
      .ant-card-body{
        padding: 12px!important;
      }
      .sentTo{
        display:none !important;
      }
      .unread{
        height:8px !important;
        width:8px !important;
        background-color:rgb(73, 97, 247) !important;
        border-radius: 50% !important;
        display:inline-block !important;
      }
      background-color:#1A2C3D !important;
      color: white!important;
      .name, .subject{
        font-weight:1000 !important;
      }
    `
}

  ${(props) => props.theme === 'selectedUnopened'
    && css`
      margin-bottom: 0px !important;
      border-radius: 0px !important;
      .ant-card-body{
        padding: 12px!important;
      }
      .name{
        display:none !important;
      }
      .unread{
        height:8px !important;
        width:8px !important;
        background-color:rgb(73, 97, 247) !important;
        border-radius: 50% !important;
        display:inline-block !important;
      }
      .sentTo, .subject{
        font-weight:1000 !important;
      }
      color: white!important;
      background-color:#1A2C3D !important;
    `
}

  ${(props) => props.theme === 'sent'
    && css`
      margin-bottom: 0px !important;
      border-radius: 0px !important;
      cursor: pointer;
      .ant-card-body{
        padding: 12px!important;
      }
      .name{
        display:none !important;
      }
      .sentTo, .subject{
        font-weight:1000 !important;
      }
    `
}

  ${(props) => props.theme === 'selectedDraft'
    && css`
      margin-bottom: 0px !important;
      border-radius: 0px !important;
      cursor: pointer;
      .ant-card-body{
        padding: 12px!important;
      }
      .name{
        display:none !important;
      }
      .sentTo, .subject{
        font-weight:1000 !important;
      }
      background-color:#1A2C3D !important;
      color: white!important;
    `
}

  ${(props) => props.theme === 'icons'
    && css`
      background: none !important;
      padding: 0px 0px 0 !important;
      .ant-card-body {
        padding: 0;
      }
      .ant-card-cover img {
        height: 25px;
        object-fit: scale-down;
      }
    `
}
`;

Card.propTypes = {
  margin: propTypes.string,
  theme: propTypes.string,
  title: propTypes.string,
  active: propTypes.string,
};

Card.defaultProps = {
  active: 'inactive',
  theme: 'standard',
  margin: '0 0 30px 0',
  padding: '10px',
  importance: '!important',
};

export default Card;
