import {
  call, takeLatest,
} from 'redux-saga/effects';
import {
  getColliersLeads,
  getFinneoLeads,
  getPreviousFinneoLeads,
  getRelatedFinneoLeads,
  finneoUploadLeads,
  colliersUploadLeads,
  getTemplateDownloadLink,
  getFinneoLeadPermission,
  getColliersLeadPermission,
  getColliersMarketLeads,
  deleteUnassignColliersLeads,
  assignColliersLeads,
  updatestageColliersLeads,
  updateLeads,
  getMarketLeadPermission,
  downloadLeads,
} from 'actions/fetch-colliersFinneo-leads';
import {
  fetchLeadsApi,
  fetchPreviousLeadsApi,
  uploadLeadsApi,
  fetchTemplateLinkApi,
  fetchColliersLeadPermissionApi,
  fetchFinneoLeadPermissionApi,
  deleteUnassignColliersLeadsApi,
  assignColliersLeadsApi,
  updatestageColliersLeadsApi,
  updateLeadsApi,
  fetchMarketLeadPermissionApi,
  fetchRelatedLeadsApi,
  downloadLeadsApi,
} from 'services/fetch-colliersFinneo-leads';
import fetchEntity from './fetch-entity';

const fetchColliersData = fetchEntity
  .bind(null, getColliersLeads.actions, fetchLeadsApi);

const fetchColliersMarketData = fetchEntity
  .bind(null, getColliersMarketLeads.actions, fetchLeadsApi);

export function* loadColliersMarketLeadsData({ params }) {
  yield call(
    fetchColliersMarketData,
    { ...params },
  );
}

function* watchColliersMarketLeads() {
  yield takeLatest(getColliersMarketLeads.actionName, loadColliersMarketLeadsData);
}

const fetchFinneoData = fetchEntity
  .bind(null, getFinneoLeads.actions, fetchLeadsApi);

export function* loadColliersLeadsData({ params }) {
  yield call(
    fetchColliersData,
    { ...params },
  );
}
export function* loadFinneoLeadsData({ params }) {
  yield call(
    fetchFinneoData,
    { ...params },
  );
}

const fetchPreviousFinneoData = fetchEntity
  .bind(null, getPreviousFinneoLeads.actions, fetchPreviousLeadsApi);

// export function* loadColliersPreviousLeadsData({ params }) {
//   yield call(
//     fetchColliersData,
//     { ...params },
//   );
// }
export function* loadFinneoPreviousLeadsData({ params }) {
  yield call(
    fetchPreviousFinneoData,
    { ...params },
  );
}

const fetchRelatedFinneoData = fetchEntity.bind(
  null, getRelatedFinneoLeads.actions, fetchRelatedLeadsApi,
);

export function* loadRelatedFinneoData({ params }) {
  yield call(
    fetchRelatedFinneoData,
    { ...params },
  );
}

function* watchColliersLeads() {
  yield takeLatest(getColliersLeads.actionName, loadColliersLeadsData);
}
function* watchFinneoLeads() {
  yield takeLatest(getFinneoLeads.actionName, loadFinneoLeadsData);
}

function* watchFinneoPreviousLeads() {
  yield takeLatest(getPreviousFinneoLeads.actionName, loadFinneoPreviousLeadsData);
}

function* watchRelatedFinneoLeads() {
  yield takeLatest(getRelatedFinneoLeads.actionName, loadRelatedFinneoData);
}

const fetchFinneoUploadLead = fetchEntity.bind(null, finneoUploadLeads.actions, uploadLeadsApi);

export function* loadFinneoUploadLeads({ params }) {
  yield call(fetchFinneoUploadLead, { ...params });
}

function* watchFinneoUploadLeads() {
  yield takeLatest(finneoUploadLeads.actionName, loadFinneoUploadLeads);
}

const fetchColliersUploadLead = fetchEntity.bind(null, colliersUploadLeads.actions, uploadLeadsApi);

export function* loadColliersUploadLeads({ params }) {
  yield call(fetchColliersUploadLead, { ...params });
}

function* watchColliersUploadLeads() {
  yield takeLatest(colliersUploadLeads.actionName, loadColliersUploadLeads);
}

const fetchTemplateDownloadLink = fetchEntity
  .bind(null, getTemplateDownloadLink.actions, fetchTemplateLinkApi);

export function* loadTemplateDownloadLink({ params }) {
  yield call(fetchTemplateDownloadLink, { ...params });
}

function* watchTemplateDownoadLink() {
  yield takeLatest(getTemplateDownloadLink.actionName, loadTemplateDownloadLink);
}

export const fetchFinneoLeadPermission = fetchEntity
  .bind(null, getFinneoLeadPermission.actions, fetchFinneoLeadPermissionApi);

export function* loadFinneoLeadPermission({ params }) {
  yield call(fetchFinneoLeadPermission, { ...params });
}

function* watchFinneoLeadPermission() {
  yield takeLatest(getFinneoLeadPermission.actionName, loadFinneoLeadPermission);
}

export const fetchColliersLeadPermission = fetchEntity
  .bind(null, getColliersLeadPermission.actions, fetchColliersLeadPermissionApi);

export function* loadColliersLeadPermission({ params }) {
  yield call(fetchColliersLeadPermission, { ...params });
}

function* watchColliersLeadPermission() {
  yield takeLatest(getColliersLeadPermission.actionName, loadColliersLeadPermission);
}
export const actionDeleteUnassignColliersLeads = fetchEntity
  .bind(null, deleteUnassignColliersLeads.actions, deleteUnassignColliersLeadsApi);

export function* loadDeleteUnassignColliersLeads({ params }) {
  yield call(actionDeleteUnassignColliersLeads, { ...params });
}
function* watchDeleteUnassignColliersLeads() {
  yield takeLatest(deleteUnassignColliersLeads.actionName, loadDeleteUnassignColliersLeads);
}

export const actionAssignColliersLeads = fetchEntity
  .bind(null, assignColliersLeads.actions, assignColliersLeadsApi);

export function* loadAssignColliersLeads({ params }) {
  yield call(actionAssignColliersLeads, { ...params });
}
function* watchAssignColliersLeads() {
  yield takeLatest(assignColliersLeads.actionName, loadAssignColliersLeads);
}

export const actionUpdatestageColliersLeads = fetchEntity
  .bind(null, updatestageColliersLeads.actions, updatestageColliersLeadsApi);

export function* loadupdatestageColliersLeads({ params }) {
  yield call(actionUpdatestageColliersLeads, { ...params });
}

function* watchstageupdateColliersLeads() {
  yield takeLatest(updatestageColliersLeads.actionName, loadupdatestageColliersLeads);
}

export const actionUpdateLeads = fetchEntity
  .bind(null, updateLeads.actions, updateLeadsApi);

export function* loadupdateLeads({ params }) {
  yield call(actionUpdateLeads, { ...params });
}

function* watchUpdateLeads() {
  yield takeLatest(updateLeads.actionName, loadupdateLeads);
}

export const fetchMarketLeadPermission = fetchEntity
  .bind(null, getMarketLeadPermission.actions, fetchMarketLeadPermissionApi);

export function* loadMarketLeadPermission({ params }) {
  yield call(fetchMarketLeadPermission, { ...params });
}

function* watchMarketLeadPermission() {
  yield takeLatest(getMarketLeadPermission.actionName, loadMarketLeadPermission);
}

export const fetchDownloadLeads = fetchEntity
  .bind(null, downloadLeads.actions, downloadLeadsApi);

export function* loadDownloadLeads({ params }) {
  yield call(fetchDownloadLeads, { ...params });
}

function* watchDownloadLeads() {
  yield takeLatest(downloadLeads.actionName, loadDownloadLeads);
}

export default {
  watchColliersLeads,
  watchColliersMarketLeads,
  watchFinneoLeads,
  watchFinneoPreviousLeads,
  watchFinneoUploadLeads,
  watchColliersUploadLeads,
  watchFinneoLeadPermission,
  watchColliersLeadPermission,
  watchUpdateLeads,
  watchMarketLeadPermission,
  watchDeleteUnassignColliersLeads,
  watchAssignColliersLeads,
  watchstageupdateColliersLeads,
  watchTemplateDownoadLink,
  watchDownloadLeads,
  watchRelatedFinneoLeads,
};
