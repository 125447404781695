/* eslint-disable camelcase */
import React from 'react';
import styled, { css } from 'styled-components';
import { InputNumber as input } from 'antd';
import propTypes from 'prop-types';
import { formatter, parser } from 'utils/numberInputFormatter';

const StyledInput = styled(input)`
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  border-radius: 4px !important;
  background-color: #ffffff !important;
  height: 40px !important;
  ${(props) => props.block
    && css`
    width: 100% !important;
    `}
`;

/** @component */
const InputNumber = React.forwardRef((props, ref) => {
  const {
    prefix, postfix, money_format, ...otherProps
  } = props;
  return <StyledInput
    ref={ref}
    formatter={(val) => formatter(val, { prefix, postfix, money_format })}
    parser={(val) => parser(val, { prefix, postfix, money_format })}
    {...otherProps} />;
});

InputNumber.propTypes = {
  block: propTypes.bool,
};

InputNumber.defaultProps = {
  block: false,
};

export default InputNumber;
