import { call, takeLatest, select } from 'redux-saga/effects';
import {
  getConsultantContacts,
  deleteConsultantContacts,
  addNewConsultantContact,
  updateConsultantContacts,
  getPageConsultantContacts,
} from 'actions/consultant-contact';
import {
  getConsultantContactsApi,
  deleteConsultantContactsApi,
  addNewConsultantContactApi,
  updateConsultantContactsApi,
} from 'services/consultant-contact';
import fetchEntity from './fetch-entity';

const fetchConsultantContacts = fetchEntity.bind(null, getConsultantContacts.actions, getConsultantContactsApi);
const fetchPageConsultantContacts = fetchEntity.bind(null, getPageConsultantContacts.actions, getConsultantContactsApi);
const fetchAddNewConsultantContact = fetchEntity.bind(
  null,
  addNewConsultantContact.actions,
  addNewConsultantContactApi,
);

const fetchUpdateConsultantContact = fetchEntity.bind(
  null,
  updateConsultantContacts.actions,
  updateConsultantContactsApi,
);

const fetchDeleteConsultantContacts = fetchEntity.bind(
  null,
  deleteConsultantContacts.actions,
  deleteConsultantContactsApi,
);

export function* loadGetPageConsultantContacts({ params }) {
  yield call(fetchPageConsultantContacts, { ...params });
}

function* watchGetPageConsultantContacts() {
  yield takeLatest([getPageConsultantContacts.actionName], loadGetPageConsultantContacts);
}

export function* loadGetPageConsultantContactsOnChange(action) {
  const consultantContacts = yield select((state) => state.consultantContacts);
  const companyId = action.response.companyId || action.response[0].companyId;
  const { total, skip, limit } = consultantContacts;
  if (skip && skip >= total) {
    yield call(fetchPageConsultantContacts, {
      $skip: total - Math.max(total % limit, limit),
      companyId,
    });
  } else {
    yield call(fetchPageConsultantContacts, { $skip: skip, companyId });
  }
}

function* watchGetPageConsultantContactsOnChange() {
  yield takeLatest([
    deleteConsultantContacts.requestTypes.SUCCESS,
    addNewConsultantContact.requestTypes.SUCCESS,
    updateConsultantContacts.requestTypes.SUCCESS,
  ], loadGetPageConsultantContactsOnChange);
}

export function* loadGetConsultantContacts({ params }) {
  yield call(fetchConsultantContacts, { ...params });
}
function* watchGetConsultantContacts() {
  yield takeLatest(getConsultantContacts.actionName, loadGetConsultantContacts);
}

export function* loadUpdateConsultantContacts({ params }) {
  yield call(fetchUpdateConsultantContact, { ...params });
}

function* watchUpdateConsultantContacts() {
  yield takeLatest(updateConsultantContacts.actionName, loadUpdateConsultantContacts);
}

export function* loadAddNewConsultantContact({ params }) {
  yield call(fetchAddNewConsultantContact, { ...params });
}

function* watchAddNewConsultantContact() {
  yield takeLatest(addNewConsultantContact.actionName, loadAddNewConsultantContact);
}
export function* loadDeleteConsultantContacts({ params }) {
  yield call(fetchDeleteConsultantContacts, { ...params });
}

function* watchDeleteConsultantContacts() {
  yield takeLatest(deleteConsultantContacts.actionName, loadDeleteConsultantContacts);
}

export default {
  watchGetConsultantContacts,
  watchUpdateConsultantContacts,
  watchAddNewConsultantContact,
  watchDeleteConsultantContacts,
  watchGetPageConsultantContacts,
  watchGetPageConsultantContactsOnChange,
};
