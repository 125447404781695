import { makeRequestAction } from './index';

// Construction Property
export const getconstructionPropertyDetails = makeRequestAction('GET_CONSTRUCTION_PROPERTY', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const addConstructionPropertyValues = makeRequestAction('ADD_CONSTRUCTION_PROPERTY', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const updateConstructionPropertyValues = makeRequestAction('UPDATE_CONSTRUCTION_PROPERTY', {
  onSuccess(params, response) {
    return {
      response: {
        entities: {
          constructionPropertiesData: response,
        },
      },
    };
  },
});

export const removeConstructionProperty = makeRequestAction('REMOVE_CONSTRUCTION_PROPERTY', {
  onSuccess(params, response) {
    return {
      response: {
        entities: {
          deleteProperty: response,
        },
      },
    };
  },
});

// Project Metrics
export const addProjectMetrics = makeRequestAction('ADD_PROJECT_METRICS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const getProjectMetrics = makeRequestAction('GET_PROJECT_METRICS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const updateProjectMetrics = makeRequestAction('UPDATE_PROJECT_METRICS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const removeProjectMetrics = makeRequestAction('REMOVE_PROJECT_METRICS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

// Construction Revenue Details
export const addConstructionRevenue = makeRequestAction('ADD_CONSTRUCTION_REVENUE', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const getConstructionRevenue = makeRequestAction('GET_CONSTRUCTION_REVENUE', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const updateConstructionRevenue = makeRequestAction('UPDATE_CONSTRUCTION_REVENUE', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const removeConstructionRevenue = makeRequestAction('REMOVE_CONSTRUCTION_REVENUE', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

// Construction Loans
export const addConstructionLoan = makeRequestAction('ADD_CONSTRUCTION_LOAN', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const removeConstructionLoan = makeRequestAction('REMOVE_CONSTRUCTION_LOAN', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const getConstructionLoanDetails = makeRequestAction('GET_ALL_CONSTRUCTION_LOANS_FOR_USER', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const updateConstructionLoan = makeRequestAction('UPDATE_CONSTRUCTION_LOAN', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

// Index Rates
export const getIndexRates = makeRequestAction('GET_INDEX_RATES', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const getConstructionLoan = makeRequestAction('GET_CONSTRUCTION_LOAN', {
  onSuccess(params, response) {
    return {
      response: {
        entities: {
          constructionLoansData: response,
        },
      },
    };
  },
});
