import React from 'react';
import styled, { css } from 'styled-components';
// import { ArrowsAltOutlined } from '@ant-design/icons';
// import expand from '../../assets/imgs/expand.png';

const TitleDiv = styled.div`
  font-size: 11px;
  color: #000000;
  font-weight: bold;
  height: 45px;
  text-align: center;
`;

const StatCardWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  border: 1px solid #d3d3d3;
  border-radius: 7px;
  height: 102px;
  margin-bottom: 50px;
  ${(props) => props.leftMargin === '30px'
    && css`
      margin-left: ${props.leftMargin};
      width: ${props.width}
    `}
`;

const Divider = styled.div`
  border-left: 1px solid #d3d3d3;
  height: 102px;
`;

const Stat = styled.div`
  font-weight: bold;
  font-size: 13px;
  color: #000000;
  text-align: center;
  padding-top: 10px;
`;

const StatCardSubDiv = styled.div`
  padding: 10px;
  min-width: 49.8%;
  height: 100px;
  ${(props) => props.leftTheme === 'titleStat'
    && css`
      background: white;
      border-radius: 7px 0px 0px 7px;
    `}
  ${(props) => props.rightTheme === 'zoomStat'
    && css`
      background: #bbe1f2;
      border-radius: 0px 7px 7px 0px;
    `}
`;

const StatCard = (props) => (
  <StatCardWrapper leftMargin={props.leftMargin}>
    <StatCardSubDiv leftTheme='titleStat' onClick={props.showModal} style={{ cursor: 'pointer' }}>
      <TitleDiv style={{ color: props.backgroudColor }}>{props.title}<br />{props.subtitle}</TitleDiv>
      {props.active ? (
        <Stat style={{ color: props.backgroudColor }}>
          {props.active}{((props.title === 'Active Originators') || (props.title === 'Active Clients') ? '' : '/')}  {props.all}
        </Stat>
      ) : (
        <Stat style={{ color: props.backgroudColor }}>{((props.active === 0) ? 0 : props.all)}</Stat>
      )}
    </StatCardSubDiv>
    <Divider />
    <StatCardSubDiv rightTheme='zoomStat'>
      <div style={{
        textAlign: 'left', height: '45px',
      }}>
        <TitleDiv style={{ color: props.backgroudColor }}>Target</TitleDiv>
      </div>
      {props.subscribers ? (
        <Stat style={{ color: props.backgroudColor }}>
          {props.subscribers}{((props.title === 'Active Originators') ? '' : '/')}
          { props.allsubscriber }
        </Stat>
      ) : (
        <Stat style={{ color: props.backgroudColor }}>
          {((props.title === 'Active Originators') ? 0 : props.allsubscriber)}
        </Stat>
      )}
    </StatCardSubDiv>
  </StatCardWrapper>
);

StatCard.defaultProps = {
  leftTheme: 'titleStat',
  rightTheme: 'zoomStat',
  icon: '',
};

/** @component */
export default StatCard;
