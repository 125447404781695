import { call, takeLatest } from 'redux-saga/effects';
import { exportData } from 'actions/reporting';

import { exportDataApi } from 'services/reporting';
import fetchEntity from './fetch-entity';

const fetchReportingData = fetchEntity
  .bind(null, exportData.actions, exportDataApi);

export function* loadReportingData({ params }) {
  yield call(fetchReportingData, { ...params });
}

function* watchFetchReportingData() {
  yield takeLatest(exportData.actionName, loadReportingData);
}

export default { watchFetchReportingData };
