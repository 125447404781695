import client from './main-client';

export const getSavedConsultantApi = ({ id, params }) => client().get(`/deal-consultants?dealId=${id}`, { ...params });

export const getSavedLenderApi = ({ id, params }) => client().get(`/deal-lenders?dealId=${id}`, { ...params });

export const saveConsultantDraftApi = (data) => client().post('/consultant-draft-email-deals', data);

export const getSavedConsultantDraftEmailApi = ({ id, params }) => client().get(`/consultant-draft-email-deals?dealId=${id}`, { ...params });

export const getSavedLenderDraftEmailApi = ({ id, params }) => client().get(`/lender-draft-email-deals?dealId=${id}`, { ...params });

export const saveLenderDraftApi = (data) => client().post('/lender-draft-email-deals', data);
