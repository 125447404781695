import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { getUserData, getUserRole } from 'selectors/users';
import { Icon, Menu as menu, Dropdown } from 'antd';
import { isUserPermitted } from 'configs/isUserPermitted';
import { roles } from '../../constants/users';

const Menu = styled(menu)`
  background-color: #1c1c1c !important;
  box-shadow: 0 6px 40px 0 rgba(0, 0, 0, 0.1) !important;
  position: relative !important;
  width: 180px;
  margin-top: 15px !important;
  border-radius: 3px !important;
  &:before {
    width: 0;
    height: 0;
    border-left: 18px solid transparent;
    border-bottom: 15px solid #1c1c1c;
    position: absolute;
    top: -13px;
    right: 0;
    z-index: 9999;
  }
  a {
    color: #ffffff !important;
    font-size: 12px !important;
  }
  .ant-menu-item {
    margin: 0 !important;
    border-top: 1px solid rgba(255, 255, 255, 0.1) !important;
    padding: 0px 12px 0px 12px !important;
  }
  .ant-menu-item.ant-menu-item-selected,
  .ant-menu-item:active {
    background-color: #333333 !important;
    font-weight: bold;
  }
  button {
    color: #ffffff !important;
    font-size: 12px !important;
    padding: 0;
  }
`;

const SettingsMenu = ({ items, logoutButton, changePath }) => (
  <Menu>
    {items.map((item) => <Menu.Item key={item.name}>
      <Link to={item.path} onClick={() => changePath(item)}>{item.name}</Link>
    </Menu.Item>)}
    <Menu.Item key={'logoutButton'}>{logoutButton}</Menu.Item>
  </Menu>
);
SettingsMenu.defaultProps = {
  logoutButton: '',
};

export default (props) => {
  const userData = useSelector(getUserData);
  const currentRole = useSelector(getUserRole);

  const userSettingsItems = [
    {
      name: 'Profile',
      path: '/profile',
    },
  ];

  isUserPermitted(
    [
      roles.ROLE_CAPSTACK,
      roles.ROLE_FULL_SERVER,
      roles.ROLE_FS_UNDERWRITER,
      roles.ROLE_TRANSACTION_ONLY,
      roles.ROLE_TO_UNDERWRITER,
    ],
    currentRole,
  ) && userSettingsItems.push(
    {
      name: 'Users',
      path: '/users',
    },
  );

  return (
    <Fragment>
      <Dropdown overlay={<SettingsMenu items={userSettingsItems} {...props} />}>
        <Link to={'/profile'}>
          {userData.user_detail.firstName} {userData.user_detail.lastName}{' '}
          <Icon type='user' />
        </Link>
      </Dropdown>
    </Fragment>
  );
};
