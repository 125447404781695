/* eslint-disable max-len */
import React from 'react';
import SFicon from '../../assets/imgs/SFicon.svg';

// eslint-disable-next-line max-len
const portfolioSvg = () => (<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24'><path fill='none' d='M0 0h24v24H0zm10 5h4v2h-4zm0 0h4v2h-4z' /><path fill='currentColor' d='M10 16v-1H3.01L3 19c0 1.11.89 2 2 2h14c1.11 0 2-.89 2-2v-4h-7v1h-4zm10-9h-4.01V5l-2-2h-4l-2 2v2H4c-1.1 0-2 .9-2 2v3c0 1.11.89 2 2 2h6v-2h4v2h6c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2zm-6 0h-4V5h4v2z' /></svg>);
// eslint-disable-next-line max-len
const dealSvg = () => (<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24'><path d='M0 0h24v24H0z' fill='none' /><path fill='currentColor' d='M20.5 11H19V7c0-1.1-.9-2-2-2h-4V3.5C13 2.12 11.88 1 10.5 1S8 2.12 8 3.5V5H4c-1.1 0-1.99.9-1.99 2v3.8H3.5c1.49 0 2.7 1.21 2.7 2.7s-1.21 2.7-2.7 2.7H2V20c0 1.1.9 2 2 2h3.8v-1.5c0-1.49 1.21-2.7 2.7-2.7 1.49 0 2.7 1.21 2.7 2.7V22H17c1.1 0 2-.9 2-2v-4h1.5c1.38 0 2.5-1.12 2.5-2.5S21.88 11 20.5 11z' /></svg>);
// eslint-disable-next-line max-len
const lenderSvg = () => (<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24'><path d='M0 0h24v24H0z' fill='none' /><path fill='currentColor' d='M4 10v7h3v-7H4zm6 0v7h3v-7h-3zM2 22h19v-3H2v3zm14-12v7h3v-7h-3zm-4.5-9L2 6v2h19V6l-9.5-5z' /></svg>);
// eslint-disable-next-line max-len
const marketDataSvg = () => (<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24'><path fill='currentColor' d='M16 6l2.29 2.29-4.88 4.88-4-4L2 16.59 3.41 18l6-6 4 4 6.3-6.29L22 12V6z' /><path d='M0 0h24v24H0z' fill='none' /></svg>);
// eslint-disable-next-line max-len
const documentsSvg = () => (<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24'><path fill='currentColor' d='M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z' /><path d='M0 0h24v24H0z' fill='none' /></svg>);
// eslint-disable-next-line max-len
const consultantsSvg = () => (<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24'><path fill='currentColor' d='M2.53 19.65l1.34.56v-9.03l-2.43 5.86c-.41 1.02.08 2.19 1.09 2.61zm19.5-3.7L17.07 3.98c-.31-.75-1.04-1.21-1.81-1.23-.26 0-.53.04-.79.15L7.1 5.95c-.75.31-1.21 1.03-1.23 1.8-.01.27.04.54.15.8l4.96 11.97c.31.76 1.05 1.22 1.83 1.23.26 0 .52-.05.77-.15l7.36-3.05c1.02-.42 1.51-1.59 1.09-2.6zM7.88 8.75c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm-2 11c0 1.1.9 2 2 2h1.45l-3.45-8.34v6.34z' /><path d='M0 0h24v24H0z' fill='none' /></svg>);
// eslint-disable-next-line max-len
const calcSvg = () => (<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24'><path d='M0 0h24v24H0z' fill='none' /><path fill='currentColor' d='M20 2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM8 20H4v-4h4v4zm0-6H4v-4h4v4zm0-6H4V4h4v4zm6 12h-4v-4h4v4zm0-6h-4v-4h4v4zm0-6h-4V4h4v4zm6 12h-4v-4h4v4zm0-6h-4v-4h4v4zm0-6h-4V4h4v4z' /></svg>);
// eslint-disable-next-line max-len
const servicesSvg = () => (<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24'><path d='M0 0h24v24H0z' fill='none' /><path fill='currentColor' d='M5 2c0-.55-.45-1-1-1s-1 .45-1 1v4H1v6h6V6H5V2zm4 14c0 1.3.84 2.4 2 2.82V23h2v-4.18c1.16-.41 2-1.51 2-2.82v-2H9v2zm-8 0c0 1.3.84 2.4 2 2.82V23h2v-4.18C6.16 18.4 7 17.3 7 16v-2H1v2zM21 6V2c0-.55-.45-1-1-1s-1 .45-1 1v4h-2v6h6V6h-2zm-8-4c0-.55-.45-1-1-1s-1 .45-1 1v4H9v6h6V6h-2V2zm4 14c0 1.3.84 2.4 2 2.82V23h2v-4.18c1.16-.41 2-1.51 2-2.82v-2h-6v2z' /></svg>);
// eslint-disable-next-line max-len
const ratingSvg = () => (<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 60 45' ><g><g id='Stroke-1'><path fill='currentColor' d='M58.39,39.93H23.04V38h35.02V1.94H1.94V38h11.62v1.94H1.61c-0.89,0-1.61-0.72-1.61-1.6V1.6C0,0.72,0.72,0,1.61,0h56.77C59.28,0,60,0.72,60,1.6v36.73C60,39.22,59.28,39.93,58.39,39.93z' /></g><path fill='currentColor' className='st1' d='M26.84,27.74c0-4.62-3.85-8.38-8.58-8.38c-4.73,0-8.58,3.76-8.58,8.38c0,2.92,1.54,5.5,3.87,7v9.65c0,0.52,0.65,0.8,1.07,0.46l3.27-2.61c0.24-0.19,0.6-0.19,0.84,0L22,44.85c0.42,0.33,1.07,0.06,1.07-0.46v-9.71C25.34,33.17,26.84,30.62,26.84,27.74z' /><g id='Stroke-7'><rect x='30.48' y='28.73' className='st0' width='19.04' height='1.94' fill='currentColor' /></g><g id='Stroke-9'><rect fill='currentColor' x='30.48' y='19.46' className='st0' width='19.04' height='1.94' /></g><g id='Stroke-11'><rect fill='currentColor' x='10.49' y='10.19' width='39.03' height='1.94' /></g></g></svg>);
// eslint-disable-next-line max-len
const ratingLableSvg = () => (<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' x='0px' y='0px' viewBox='0 0 12 16' ><path fill='currentColor' className='st0' d='M12,5.82C12,2.61,9.31,0,6,0S0,2.61,0,5.82C0,7.28,0.58,8.6,1.5,9.63v4.46c0,0.79,0.47,1.49,1.2,1.78c0.7,0.28,1.49,0.13,2.03-0.4L6,14.24l1.27,1.23C7.63,15.82,8.11,16,8.59,16c0.24,0,0.48-0.05,0.71-0.14c0.73-0.29,1.2-0.99,1.2-1.78V9.63C11.42,8.6,12,7.28,12,5.82z' /></svg>);
// eslint-disable-next-line max-len
const emailSvg = () => (<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24'><path fill='currentColor' d='M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z' /><path d='M0 0h24v24H0z' fill='none' /></svg>);
// eslint-disable-next-line max-len
const emailOpenSvg = () => (<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24'><path d='M0 0h24v24H0z' fill='none' /><path d='M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z' fill='currentColor' /></svg>);
// eslint-disable-next-line max-len
const emailClickSvg = () => (<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24'><path d='M0 0h24v24H0z' fill='none' /><path d='M18 7l-1.41-1.41-6.34 6.34 1.41 1.41L18 7zm4.24-1.41L11.66 16.17 7.48 12l-1.41 1.41L11.66 19l12-12-1.42-1.41zM.41 13.41L6 19l1.41-1.41L1.83 12 .41 13.41z' fill='currentColor' /></svg>);
// eslint-disable-next-line max-len
const emailSendSvg = () => (<svg width='1em' height='1em' viewBox='0 0 18 15' version='1.1' xmlns='http://www.w3.org/2000/svg'><g id='Client' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'><g id='Inbox' transform='translate(-1391.000000, -614.000000)' fill='currentColor' fillRule='nonzero'><polygon points='1391 629 1391 623.18 1403.5 621.5 1391 619.82 1391 614 1408.5 621.5' /></g></g></svg>);

// eslint-disable-next-line max-len
const trainingSvg = () => (<svg class='w-[18px] h-[16px] text-gray-800 dark:text-white' aria-hidden='true' xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='currentColor' viewBox='0 0 24 24'>
  <path fill-rule='evenodd' d='M9 7V2.221a2 2 0 0 0-.5.365L4.586 6.5a2 2 0 0 0-.365.5H9Zm2 0V2h7a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V9h5a2 2 0 0 0 2-2Zm-2 4a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2v-2a2 2 0 0 0-2-2H9Zm0 2h2v2H9v-2Zm7.965-.557a1 1 0 0 0-1.692-.72l-1.268 1.218a1 1 0 0 0-.308.721v.733a1 1 0 0 0 .37.776l1.267 1.032a1 1 0 0 0 1.631-.776v-2.984Z' clip-rule='evenodd' />
</svg>
);
// eslint-disable-next-line max-len
const squareFeetSvg = SFicon;

const leftOutlined = () => <svg viewBox='64 64 896 896' focusable='false' className='' data-icon='left' width='1em' height='1em' fill='currentColor' aria-hidden='true'><path d='M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z' /></svg>;

const rightOutlined = () => <svg viewBox='64 64 896 896' focusable='false' className='' data-icon='right' width='1em' height='1em' fill='currentColor' aria-hidden='true'><path d='M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z' /></svg>;

export default {
  leftOutlined,
  rightOutlined,
  portfolioSvg,
  dealSvg,
  lenderSvg,
  marketDataSvg,
  documentsSvg,
  consultantsSvg,
  calcSvg,
  servicesSvg,
  ratingSvg,
  ratingLableSvg,
  emailSvg,
  squareFeetSvg,
  emailOpenSvg,
  emailClickSvg,
  emailSendSvg,
  trainingSvg,
};
