/* eslint-disable max-len */
import React, {
  useEffect, useState, useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import {
  Col, Typography, Checkbox, Row, Icon, Tooltip,
} from 'antd';
// import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import {
  List, CompanyListItem, AvarageRating,
} from 'components';

import { getDealsLenderBidsState } from 'selectors/deals-lender-bids';

const CompanyCategoryItem = (props) => {
  const {
    dealId,
    dealData,
    type,
    categoryName,
    companies,
    loading,
    expanded,
    checkBoxAction,
    confirmReselectModal,
    stateProvinces,
    getBondsValues,
    getRatesValues,
    userSettings,
    lenderClick,
    collapseCategory,
    handleCategoryCollapse,
    handleCategoryExpand,
  } = props;

  const { Text } = Typography;
  const dealsLenderBids = useSelector(getDealsLenderBidsState);
  const [listData, setListData] = useState([]);
  // const [lenderExpandedMode, setLenderExpandedMode] = useState(false);
  const [accessLenders, setAccessLenders] = useState(false);
  const [accessConsultants, setAccessConsultants] = useState(false);

  const convertObj = {
    yes: true,
    no: false,
    false: false,
    true: true,
  };

  const states = stateProvinces && dealData
    ? stateProvinces.filter((state) => state.abbreviation === dealData.Property_Address.province)
    : [];

  useEffect(() => {
    if (expanded) {
      showAllCompanies();
    } else {
      showLimitedCompanies();
    }
    // eslint-disable-next-line
  }, [expanded]);

  useEffect(() => {
    if (type === 'lenders'
      && userSettings
      && (userSettings.data.accessLendersIntermediate || userSettings.data.accessLendersAdvanced)
    ) {
      setAccessLenders(true);
    } else if (type === 'consultants'
      && userSettings
      && (userSettings.data.accessConsultantsIntermediate || userSettings.data.accessConsultantsAdvanced)
    ) {
      setAccessConsultants(true);
    } else {
      setAccessLenders(false);
      setAccessConsultants(false);
    }
  }, [type, userSettings]);

  const matchThisLender = useCallback((lender, data) => {
    let matchScore = 0;
    if (type === 'lenders') {
      if (lender.rel_lender_state_provinces && states.length > 0) {
        const dealState = states[0];
        if (lender.rel_lender_state_provinces
          .map((state) => state.optionId)
          .includes(dealState.id)
        ) {
          matchScore += 1;
        }
      }
      if (
        data.Loan_Amount
        && data.Loan_Amount >= Number(lender.minLoan)
        && data.Loan_Amount <= Number(lender.maxLoan)
      ) {
        matchScore += 1;
      }
      if (
        data.Term
        && data.Term >= lender.minTerm
        && data.Term <= lender.maxTerm
      ) {
        matchScore += 1;
      }
      if (data.DSCR && data.DSCR >= lender.minDSCR) {
        matchScore += 1;
      }
      if (
        data.Rate_Type
        && (data.Rate_Type === lender.rateType || lender.rateType === 'Both')
      ) {
        matchScore += 1;
      }
      if (data.LTV && data.LTV <= Number(lender.maxLTV)) {
        matchScore += 1;
      }
      if (data.Fee && data.Fee >= Number(lender.feeMin) - 20) {
        matchScore += 1;
      }
      if (
        Number.isFinite(data.Amortization)
        && (convertObj[data.IO.toLowerCase()]
          || (data.Amortization >= lender.minAmortization
            && data.Amortization <= lender.maxAmortization))
      ) {
        matchScore += 1;
      }
      if (data.Recourse && lender.lender_company_pricings) {
        const thisPricingModel = lender.lender_company_pricings.find(
          (pricing) => pricing.loanType === 'Term',
        );
        if (thisPricingModel && thisPricingModel.nonRecource) {
          matchScore += 1;
        } else if (thisPricingModel && !thisPricingModel.nonRecource) {
          if (data.Recourse === 'Full' || data.Recourse === 'Partial') {
            matchScore += 1;
          }
        }
      }
      if (data.Market && lender.rel_lender_options_markets) {
        const exists = lender.rel_lender_options_markets.some(
          (item) => data.Market === item.optionId,
        );
        if (exists) {
          matchScore += 1;
        }
      }
      if (data.Property_Type && lender.rel_lender_options_property_types) {
        const exists = lender.rel_lender_options_property_types.some((item) => data.Property_Type.includes(item.optionId));
        if (exists) {
          matchScore += 1;
        }
      }
      if (data.Property_Class && lender.rel_lender_options_property_classes) {
        const exists = lender.rel_lender_options_property_classes.some(
          (item) => data.Property_Class === item.optionId,
        );
        if (exists) {
          matchScore += 1;
        }
      }
      if (
        data.Expected_Rate
        && lender.lender_company_pricings
        && getRatesValues
        && getBondsValues.bonds
      ) {
        // hardcoded to Term right now, needs changing
        const thisPricingModel = lender.lender_company_pricings.find(
          (pricing) => pricing.loanType === 'Term',
        );
        if (thisPricingModel && thisPricingModel.indexType === 'GOC') {
          const rate = getBondsValues.bonds.b5yr.CAN && getBondsValues.bonds.b5yr.CAN;
          if (
            data.Expected_Rate + 0.5
            >= Number(rate) + thisPricingModel.spreadMin
          ) {
            matchScore += 1;
          }
        } else if (thisPricingModel && thisPricingModel.indexType === 'PRIME') {
          const rate = getRatesValues.bankOfCanadaPrime
            && getRatesValues.bankOfCanadaPrime.value;
          if (
            data.Expected_Rate + 0.5
            >= Number(rate) + thisPricingModel.spreadMin
          ) {
            matchScore += 1;
          }
        }
      }
      if (data.IO
        && lender.IO
        && (!convertObj[data.IO.toLowerCase()]
          || (convertObj[data.IO.toLowerCase()]
            && convertObj[lender.io.toLowerCase()]
          )
        )
      ) {
        matchScore += 1;
      }
    }
    if (type === 'consultants' && states) {
      if (states && states.length && lender.provinceId === states[0].id) {
        matchScore = 14;
      }
    }

    return matchScore >= 14 && true;
  }, [type, states, convertObj, getRatesValues, getBondsValues]);

  useEffect(() => {
    if (!expanded) {
      const limitedCompanies = companies.length > 20 ? companies.slice(0, 20) : companies;
      setListData(limitedCompanies);
    }
  }, [companies, expanded]);

  const showAllCompanies = () => {
    // setLenderExpandedMode(true);
    setListData(companies);
  };

  const showLimitedCompanies = () => {
    // setLenderExpandedMode(false);
    const limitedCompanies = companies.length > 20 ? companies.slice(0, 20) : companies;
    setListData(limitedCompanies);
  };

  const checkSubmitted = (item) => {
    const contacts = item.contacts && item.contacts.length > 0 && item.contacts.map((contact) => contact.companyId);
    const lenderSubmitted = (Object.values(dealsLenderBids).map((lender) => lender.lenderId));

    const check = contacts && contacts.filter((value) => lenderSubmitted.includes(value));

    if (check && check.length !== 0) { return true; }

    return false;
  };

  return (
    // <Col style={{ marginBottom: '50px' }}>
    <Row>
      <List
        style={{ display: 'block' }}
        loading={loading}
        header={
          <>
            <Text style={{ fontWeight: '600' }}>{categoryName}</Text>
            {
         collapseCategory && collapseCategory?.hasOwnProperty(categoryName) && collapseCategory[categoryName] === true
           ? <Tooltip
             title='Expand Category'
             placement='top'
            >
             <span style={{ fontSize: '18px', marginLeft: '10px', cursor: 'pointer' }} onClick={() => handleCategoryCollapse(categoryName)}><Icon type='down' /></span>
           </Tooltip>
           : <Tooltip
             title='Collapse Category'
             placement='top'
            >
             <span style={{ fontSize: '18px', marginLeft: '10px', cursor: 'pointer' }} onClick={() => handleCategoryExpand(categoryName)}><Icon type='up' /></span>
           </Tooltip>
        }

          </>
         }
        // footer={companies.length > 20 ? <> {!lenderExpandedMode && !expanded
        //   ? <Col span={24} style={{ textAlign: 'center' }}>
        //     <Button
        //       block
        //       style={{ width: '50%' }}
        //       type='primary'
        //       key={categoryName}
        //       onClick={showAllCompanies}
        //       size='small'
        //       ghost
        //     > + &nbsp; Show More <CaretDownOutlined /></Button>
        //   </Col>
        //   : <Col span={24} style={{ textAlign: 'center' }}>
        //     <Button
        //       block
        //       style={{ width: '50%' }}
        //       type='primary'
        //       key={categoryName}
        //       onClick={showLimitedCompanies}
        //       size='small'
        //       ghost
        //     > - &nbsp; Show Less <CaretUpOutlined /></Button>
        //   </Col>
        // } </> : ''}
        theme='dealLender'
        itemLayout='horizontal'
        size='small'
        key={categoryName}
        dataSource={listData && listData.sort((a, b) => a?.companyName.localeCompare(b?.companyName))}
        renderItem={
          (item) => (
            <>
              {
               (collapseCategory && collapseCategory[categoryName] !== true)
               && <Col span={6}>
                 <CompanyListItem
                   style={{ width: '93%', backgroundColor: '#ccc5ba29' }}
                   key={item.id}
                   match={dealData && matchThisLender(item, dealData)}
                   submitted={checkSubmitted(item)}
            >
                   <div style={{ display: 'flex' }}>
                     {/* <> to have center aligned text uncomment this and comment the div above */}
                     {dealId && dealData
                  && <Checkbox
                    className='lenderSelect'
                    checked={props.checkLender.includes(item.id)}
                    disabled={item.contacts && item.contacts.length === 0 ? 'disabled' : ''}
                    onChange={(e) => {
                      if (checkSubmitted(item)) {
                        confirmReselectModal(e, item);
                      } else {
                        checkBoxAction(e, item);
                      }
                    }}
                    key={`checkbox-${item.id}`}
                  />
                }
                     {// eslint-disable-next-line no-nested-ternary
                  type === 'lenders' && accessLenders
                    ? <Text
                      key={`text-${item.id}`}
                      ellipsis
                      style={{ display: 'block !important', cursor: 'pointer', color: checkSubmitted(item) ? 'red' : 'rgb(93 89 89 / 77%)' }}
                      onClick={() => lenderClick(item.id)}
                      >
                      {item?.companyName?.length > 35 ? `${item?.companyName?.slice(0, 35)}...` : item?.companyName}
                    </Text>
                    : type === 'lenders' && !accessLenders
                      ? <Text
                        ellipsis
                        style={{ display: 'block !important', cursor: 'pointer', color: checkSubmitted(item) ? 'red' : 'rgb(93 89 89 / 77%)' }}
                        key={`text-${item.id}`}
                      >
                        {item?.companyName?.length > 35 ? `${item?.companyName?.slice(0, 35)}...` : item?.companyName}
                      </Text>
                      : null
                }
                     {// eslint-disable-next-line no-nested-ternary
                  type === 'consultants' && accessConsultants
                    ? <Link key={`link-${item.id}`} to={`/${type}/view/${item.id}`}>
                      <Text ellipsis key={`text-${item.id}`}>{item?.companyName?.length > 35 ? `${item?.companyName?.slice(0, 35)}...` : item?.companyName}</Text>
                    </Link>
                    : type === 'consultants' && !accessConsultants
                      ? <Text
                        ellipsis
                        key={`text-${item.id}`}
                      >
                        {item?.companyName?.length > 35 ? `${item?.companyName?.slice(0, 35)}...` : item?.companyName}
                      </Text>
                      : null
                }
                     {/* </> */}
                   </div>
                   <AvarageRating
                     key={`rating-${item.id}`}
                     theme={item.relationshipLender ? 'iconOnly' : 'tiny'}
                     rating={item.rating}
              />
                 </CompanyListItem>
               </Col>
}
            </>

          )
        }
      />
    </Row>
  );
};

export default React.memo(CompanyCategoryItem);
