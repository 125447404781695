import { makeRequestAction } from './index';

export const getBrokerCompany = makeRequestAction('GET_BROKER_COMPANY', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const getBrokerCompanies = makeRequestAction('GET_BROKER_COMPANIES', {
  onSuccess(params, response) {
    return {
      response: [
        ...response,
      ],
    };
  },
});

export const addBrokerCompany = makeRequestAction('ADD_BROKER_COMPANY', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const updateBrokerCompany = makeRequestAction('UPDATE_BROKER_COMPANY', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});
