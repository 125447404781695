import { call, takeLatest } from 'redux-saga/effects';
import {
  getTerritories,
} from 'actions/territories';
import {
  getTerritoriesApi,
} from 'services/territories';
import fetchEntity from './fetch-entity';

const getTerritoriesData = fetchEntity.bind(
  null,
  getTerritories.actions,
  getTerritoriesApi,
);

export function* loadGetTerritories({ params }) {
  yield call(getTerritoriesData, { ...params });
}

function* watchGetTerritories() {
  yield takeLatest(getTerritories.actionName, loadGetTerritories);
}

export default {
  watchGetTerritories,
};
