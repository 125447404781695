import styled, { css } from 'styled-components';
import { Form } from 'antd';
import propTypes from 'prop-types';

const FormItem = styled(Form.Item)`
  margin-bottom: 15px !important;
  margin: ${(props) => props.margin + props.importance};
  color: ${(props) => props.color + props.importance};
  &.ant-form-item-with-help
  {
    margin-bottom: -5px !important;
  }
  label
  {
    font-weight: bold !important;
    font-size: 12px !important;
    line-height: 12px !important;
  }
  .ant-form-item-label
  {
    padding: 0 !important;
  }
  .ant-form-extra
  {
    font-size: 11px;
  }
  .ant-form-explain
  {
    font-size: 11px;
    line-height: 1.5;
    margin: 0;
  }
  .has-error {
    .ant-input {
      background-color: #fff !important;
      border-color: #f5222d !important;
    }
    .ant-select-selection {
      border-color: #f5222d !important;
    }
  }

  ${(props) => (props.type === 'compose')
  && css`
  .ant-form-item
  {
    margin-bottom:0px !important;
  }
  `}
  .ant-form-item-control
  {
    line-height:0px !important;
  }

  ${(props) => (props.theme === 'documentSelector')
  && css`
    &.ant-form-item {
      display: flex !important;
      flex-direction: column !important;
      align-items: center !important;
      justify-content: center !important;
      height: 40px !important;
      margin: 0 !important
      width: 100% !important;

      .ant-col {
        width: 100%;
      }
    }

    .ant-input[disabled] {
      background-color: #f6f6f6 !important;
    }
  `}

  ${(props) => (props.labelcolor) && css`
    label {
      color: ${`${props.labelcolor} ${props.importance}`};
    }
  `}
`;

FormItem.propTypes = {
  /** set the formitem margin */
  margin: propTypes.string,
};

FormItem.defaultProps = {
  importance: '!important',
};

/** @component */
export default FormItem;
