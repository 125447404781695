import React from 'react';
import { Alert } from 'antd';
import MarkerMap from './MarkerMap';
import PolygonMap from './PolygonMap';
import AddressMap from './AddressMap';
import ClusterMarkerMap from './ClusterMarkerMap';

const Map = (props) => {
  if (props.provinces) {
    return (<PolygonMap
      center={props.center}
      provinces={props.provinces}
      style={props.style}
      scrollwheel={props.scrollwheel}
      streetViewControl={props.streetViewControl}
      mapTypeControl={props.mapTypeControl}
      fullscreenControl={props.fullscreenControl} />);
  } if (props.places) {
    return (
      <MarkerMap
        places={props.places}
        zoom={props.zoom}
        loans={props.loans}
        lines={props.lines}
        style={props.style}
        scrollwheel={props.scrollwheel}
        streetViewControl={props.streetViewControl}
        mapTypeControl={props.mapTypeControl}
        fullscreenControl={props.fullscreenControl}
        construction={props.construction}
        propertyPage={props && props.propertyPage ? props.propertyPage : false}
        // eslint-disable-next-line max-len
        // because on view property page we do not need to bound (as there will be one and only one location tethered to that map ) the map and zoom out as well
        notBounds={props.notBounds}
        constructionLoans={props.constructionLoans}
        />
    );
  } if (props.addresses) {
    return (
      <AddressMap
        places={props.addresses}
        zoom={props.zoom}
        leads={props.leads}
        style={props.style}
        allLeads={props.allLeads}
        scrollwheel={props.scrollwheel}
        streetViewControl={props.streetViewControl}
        mapTypeControl={props.mapTypeControl}
        fullscreenControl={props.fullscreenControl}
        market={props.market}
        returnToLeads={props.returnToLeads}
        type={props.type}
        setRow={props.setRow}
        openDetailModal={props.openDetailModal}
      />
    );
  }

  if (props.markerCluster) {
    return (
      <ClusterMarkerMap
        data={props.data}
        markets={props.markets}
        propertyTypes={props.propertyTypes}
        propertyClasses={props.propertyClasses}
      />
    );
  }

  return <Alert message='Please set provinces or exact coordinates' type='warning' />;
};

export default Map;
