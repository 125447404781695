import {
  call, takeLatest,
} from 'redux-saga/effects';
import {
  getDealRevenueApi, updateDealRevenueApi, addDealRevenueApi,
} from 'services/deal-revenue';
import {
  getDealRevenue, addDealRevenue, updateDealRevenue,
} from 'actions/deal-revenue';
import fetchEntity from './fetch-entity';

const fetchDealRevenueData = fetchEntity
  .bind(null, getDealRevenue.actions, getDealRevenueApi);

export function* loadFetchDealRevenue({ params }) {
  yield call(fetchDealRevenueData, params);
}

function* watchFetchDealRevenue() {
  yield takeLatest(getDealRevenue.actionName, loadFetchDealRevenue);
}

const fetchAddDealRevenue = fetchEntity.bind(
  null,
  addDealRevenue.actions,
  addDealRevenueApi,
);

export function* loadAddDealRevenue({ params }) {
  yield call(fetchAddDealRevenue, params);
}

function* watchFetchAddDealRevenue() {
  yield takeLatest(addDealRevenue.actionName, loadAddDealRevenue);
}

const fetchUpdateDealRevenue = fetchEntity
  .bind(null, updateDealRevenue.actions, updateDealRevenueApi);

export function* loadUpdateDealRevenue({ params }) {
  yield call(fetchUpdateDealRevenue, params);
}

function* watchUpdateDealRevenue() {
  yield takeLatest(updateDealRevenue.actionName, loadUpdateDealRevenue);
}

export default {
  watchFetchDealRevenue,
  watchFetchAddDealRevenue,
  watchUpdateDealRevenue,
};
