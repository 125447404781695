import { call, takeLatest } from 'redux-saga/effects';
import { loansValues, addLoan, updateLoan } from 'actions/loans';
import { loansApi, addLoanApi, updateLoanApi } from 'services/loans';
import fetchEntity from './fetch-entity';

const fetchLoansValues = fetchEntity.bind(null, loansValues.actions, loansApi);

export function* loadLoans({ params }) {
  yield call(fetchLoansValues, { ...params });
}

function* watchLoansValues() {
  yield takeLatest(loansValues.actionName, loadLoans);
}

const postAddLoan = fetchEntity.bind(null, addLoan.actions, addLoanApi);

export function* loadAddLoan({ params }) {
  yield call(postAddLoan, { ...params });
}

function* watchAddLoan() {
  yield takeLatest(addLoan.actionName, loadAddLoan);
}

const postUpdateLoan = fetchEntity.bind(null, updateLoan.actions, updateLoanApi);

export function* loadUpdateLoan({ params }) {
  yield call(postUpdateLoan, { ...params });
}

function* watchUpdateLoan() {
  yield takeLatest(updateLoan.actionName, loadUpdateLoan);
}

export default {
  watchLoansValues,
  watchAddLoan,
  watchUpdateLoan,
};
