import { call, takeLatest } from 'redux-saga/effects';
import {
  fetchLenders,
  getLender,
  updateLender,
  addLenderLogo,
  deleteLenderLogo,
} from 'actions/lenders';
import {
  fetchLendersApi,
  fetchLenderApi,
  updateLenderApi,
} from 'services/lenders';
import { addUploadApi, deleteUploadApi } from 'services/uploads';
import fetchEntity from './fetch-entity';

const fetchLendersData = fetchEntity.bind(
  null,
  fetchLenders.actions,
  fetchLendersApi,
);

export function* loadFetchLendersData({ params }) {
  yield call(fetchLendersData, { ...params });
}

function* watchFetchLendersData() {
  yield takeLatest(fetchLenders.actionName, loadFetchLendersData);
}

const fetchLenderData = fetchEntity.bind(
  null,
  getLender.actions,
  fetchLenderApi,
);

export function* loadFetchLenderData({ params }) {
  yield call(fetchLenderData, { ...params });
}

function* watchFetchLenderData() {
  yield takeLatest(getLender.actionName, loadFetchLenderData);
}

const updateLenderData = fetchEntity.bind(
  null,
  updateLender.actions,
  updateLenderApi,
);

export function* loadUpdateLenderData({ params }) {
  yield call(updateLenderData, { ...params });
}

function* watchUpdateLenderData() {
  yield takeLatest(updateLender.actionName, loadUpdateLenderData);
}

const fetchAddLenderLogo = fetchEntity.bind(
  null,
  addLenderLogo.actions,
  addUploadApi,
);

export function* loadAddLenderLogo({ params }) {
  yield call(fetchAddLenderLogo, { ...params });
}

function* watchAddLenderLogo() {
  yield takeLatest(addLenderLogo.actionName, loadAddLenderLogo);
}

const fetchDeleteLenderLogo = fetchEntity.bind(
  null,
  deleteLenderLogo.actions,
  deleteUploadApi,
);

export function* loadDeleteLenderLogo({ params }) {
  yield call(fetchDeleteLenderLogo, { ...params });
}

function* watchDeleteLenderLogo() {
  yield takeLatest(deleteLenderLogo.actionName, loadDeleteLenderLogo);
}

export default {
  watchFetchLendersData,
  watchFetchLenderData,
  watchUpdateLenderData,
  watchAddLenderLogo,
  watchDeleteLenderLogo,
};
