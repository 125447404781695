import { makeRequestAction } from './index';

export const getBrokerContact = makeRequestAction('GET_BROKER_CONTACT', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const getBrokerContacts = makeRequestAction('GET_BROKER_CONTACTS', {
  onSuccess(params, response) {
    return {
      response: [
        ...response,
      ],
    };
  },
});

export const addBrokerContact = makeRequestAction('ADD_BROKER_CONTACT', {
  onSuccess(params, response) {
    return {
      response: [
        ...response,
      ],
    };
  },
});

export const updateBrokerContact = makeRequestAction('UPDATE_BROKER_CONTACT', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const bulkUpdateManager = makeRequestAction('BULK_UPDATE_MANAGER', {
  onSuccess(params, response) {
    return {
      response,
    };
  },
});
