/* eslint-disable */
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styled, { css } from 'styled-components';
import propTypes from 'prop-types';
import ImageResize from 'quill-image-resize-module-react';

const BlockEmbed = Quill.import('blots/block/embed');

class ImageBlot extends BlockEmbed {
  static create(value) {
    const node = super.create();
    node.setAttribute('alt', value.alt);
    node.setAttribute('src', value.url);
    node.setAttribute('class', value.class);
    node.setAttribute('style', value.style);
    return node;
  }

  static value(node) {
    return {
      alt: node.getAttribute('alt'),
      url: node.getAttribute('src'),
      class: node.getAttribute('class'),
      style: node.getAttribute('style'),
    };
  }
}
ImageBlot.blotName = 'image';
ImageBlot.tagName = 'img';

Quill.register(ImageBlot);
Quill.register('modules/imageResize', ImageResize);

class TableBlot extends BlockEmbed {
    static blotName = 'table';

    static tagName = 'table';

    static className = 'table';

    constructor(node) {
      super(node);
      this.domNode.setAttribute('contenteditable', 'false');
    }
}
Quill.register(TableBlot);

const modules = {
  imageResize: {
    parchment: Quill.import('parchment'),
    modules: ['Resize', 'DisplaySize'],
  },
  toolbar: {
    container: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ color: [] }, { background: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      [{ script: 'sub' }, { script: 'super' }],
      ['link',
      //  'image'
      ],
      ['clean'],
      [{ align: [] }],
      [{
        shortcodes: ['{{contactName_first}}','{{companyName}}','{{contactName}}', '{{ownerName}}', '{{coiCompany}}', '{{propertyAddress}}', '{{lenderName}}', '{{click_here}}', '{{signature}}']
      }],
    ],
    handlers: {
      shortcodes(value) {
        if (value) {
          const cursorPosition = this.quill.getSelection().index;
          this.quill.insertText(cursorPosition, value);
          this.quill.setSelection(cursorPosition + value.length);
        }
      },
    },
  },
};

const formats = [
  'header',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'color', 'background',
  'list', 'bullet', 'indent',
  'script',
  'link', 'image',
  'clean',
  'align',
];

const StyledReactQuill = styled(ReactQuill)`
  background-color: white;
  .ql-editor {
    min-height: 250px;
  }
  ${(props) => (props.type === 'messagebox')
    && css`
    .ql-editor {
      min-height: 50px;
    }
    `}
    ${(props) => !props.component
    && css`
    .ql-shortcodes {
      display:none !important;
    }
  `}
  {${(props) => props.component
    && css`
    .ql-editor p {
      margin: 1px 0;
    }
  `}}
  ${(props) => props.type === 'email'
    && css`
    .ql-toolbar {
      border-top-width: 0px!important;
      border-left-width: 0px!important;
      border-right-width: 0px!important;
    }

    .ql-container {
     border-left-width: 0px!important;
     border-right-width: 0px!important;
     border-bottom-width: 0px!important;
    }
  `}}
`;

StyledReactQuill.propTypes = {
  type: propTypes.string,
};

StyledReactQuill.defaultProps = {
  modules,
  formats,
};

export default StyledReactQuill;
