import { fetchConsultantsCategories } from 'actions/consultant-categories';

const consultantsWithCategories = () => {
  const initialState = {
    consultants: null,
  };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case fetchConsultantsCategories.requestTypes.SUCCESS:
        return {
          ...state,
          consultants: response,
        };
      default:
        return state;
    }
  };
};

export default consultantsWithCategories();
