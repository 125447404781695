import {
  call, takeLatest,
} from 'redux-saga/effects';

import {
  addBrokerCompany,
  getBrokerCompany,
  getBrokerCompanies,
  updateBrokerCompany,
} from 'actions/broker-companies';
import {
  addBrokerCompanyApi,
  getBrokerCompanyApi,
  getBrokerCompaniesApi,
  updateBrokerCompanyApi,
} from 'services/broker-companies';

import fetchEntity from './fetch-entity';

const fetchAddBrokerCompany = fetchEntity.bind(null, addBrokerCompany.actions, addBrokerCompanyApi);

export function* loadAddBrokerCompany({ params }) {
  yield call(fetchAddBrokerCompany, params);
}

function* watchAddBrokerCompany() {
  yield takeLatest(addBrokerCompany.actionName, loadAddBrokerCompany);
}

const fetchGetBrokerCompany = fetchEntity.bind(null, getBrokerCompany.actions, getBrokerCompanyApi);

export function* loadGetBrokerCompany({ params }) {
  yield call(fetchGetBrokerCompany, params);
}

function* watchGetBrokerCompany() {
  yield takeLatest(getBrokerCompany.actionName, loadGetBrokerCompany);
}

const fetchGetBrokerCompanies = fetchEntity.bind(null, getBrokerCompanies.actions, getBrokerCompaniesApi);

export function* loadGetBrokerCompanies({ params }) {
  yield call(fetchGetBrokerCompanies, params);
}

function* watchGetBrokerCompanies() {
  yield takeLatest(getBrokerCompanies.actionName, loadGetBrokerCompanies);
}

const fetchUpdateBrokerCompany = fetchEntity.bind(null, updateBrokerCompany.actions, updateBrokerCompanyApi);

export function* loadUpdateBrokerCompany({ params }) {
  yield call(fetchUpdateBrokerCompany, params);
}

function* watchUpdateBrokerCompany() {
  yield takeLatest(updateBrokerCompany.actionName, loadUpdateBrokerCompany);
}

export default {
  watchAddBrokerCompany,
  watchGetBrokerCompany,
  watchGetBrokerCompanies,
  watchUpdateBrokerCompany,
};
