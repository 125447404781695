import styled from 'styled-components';

const DashboardIconDiv = styled.div`
    height: 32px;
    width: 32px;
    min-width: 32px;
    border-radius: 3px;
    //background-color: #84AD01;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid #7d3a96 2px;
    transition: transform .2s;
    &:hover {
        transform: scale(1.3);
    }
`;

/** @component */
export default DashboardIconDiv;
