import {
  call, takeLatest,
} from 'redux-saga/effects';
import {
  updatelistingLeadsApi, addLeadListingApi, listingLeadsApi, updateMultipleListingsLeadsApi,
} from 'services/listingLeads';
import {
  listings,
  addListings,
  updateListing,
  updateMultipleListings,
  brokerListings,
} from 'actions/listingLeads';
import fetchEntity from './fetch-entity';

const fetchListingLeadsData = fetchEntity
  .bind(null, listings.actions, listingLeadsApi);

export function* loadFetchListingLeads({ params }) {
  yield call(fetchListingLeadsData, params);
}

function* watchFetchLeadListings() {
  yield takeLatest(listings.actionName, loadFetchListingLeads);
}

const fetchUpdateListingLeads = fetchEntity
  .bind(null, updateListing.actions, updatelistingLeadsApi);

export function* loadUpdateListingLeads({ params }) {
  yield call(fetchUpdateListingLeads, params);
}

function* watchUpdateListingLeads() {
  yield takeLatest(updateListing.actionName, loadUpdateListingLeads);
}

const fetchAddListingLeads = fetchEntity.bind(
  null,
  addListings.actions,
  addLeadListingApi,
);

export function* loadAddListingLeads({ params }) {
  yield call(fetchAddListingLeads, params);
}

function* watchAddListingLeads() {
  yield takeLatest(addListings.actionName, loadAddListingLeads);
}

const fetchMultipleUpdate = fetchEntity.bind(
  null,
  updateMultipleListings.actions,
  updateMultipleListingsLeadsApi,
);

export function* loadMultipleUpdate({ params }) {
  yield call(fetchMultipleUpdate, params);
}

function* watchMultipleUpdate() {
  yield takeLatest(updateMultipleListings.actionName, loadMultipleUpdate);
}

const fetchBrokerListings = fetchEntity.bind(
  null,
  brokerListings.actions,
  listingLeadsApi,
);

export function* loadBrokerListings({ params }) {
  yield call(fetchBrokerListings, params);
}

function* watchBrokerListings() {
  yield takeLatest(brokerListings.actionName, loadBrokerListings);
}

export default {
  watchFetchLeadListings,
  watchUpdateListingLeads,
  watchAddListingLeads,
  watchMultipleUpdate,
  watchBrokerListings,
};
