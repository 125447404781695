import {
  exportData,
} from 'actions/reporting';

const reporting = () => {
  const initialState = {
    data: null,
  };
  return (state = initialState, { type, response }) => {
    switch (type) {
      case exportData.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case exportData.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case exportData.requestTypes.SUCCESS:
        return {
          ...state,
          data: response,
          isSuccessful: true,
        };

      default:
        return state;
    }
  };
};

export default reporting();
