import { makeRequestAction } from './index';

export const getDealRevenue = makeRequestAction('GET_DEAL_REVEUE', {
  onSuccess(params, response) {
    return {
      response: {
        data: response,
      },
    };
  },
});

export const addDealRevenue = makeRequestAction('ADD_DEAL_REVEUE', {
  onSuccess(params, response) {
    return {
      response,
    };
  },
});

export const updateDealRevenue = makeRequestAction('UPDATE_DEAL_REVEUE', {
  onSuccess(params, response) {
    return {
      response,
    };
  },
});
