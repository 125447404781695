export const checkNum = (e) => {
  const converted = e.currentTarget ? e.currentTarget.value.replace(/,/g, '') : e.replace(/,/g, '');

  if (converted.includes('.')) {
    const index = converted.indexOf('.');
    return isNaN(converted) ? '' : Number(converted.slice(0, index)).toLocaleString() + converted.slice(index);
  }

  // if (Number(converted) === 0) {
  //   return '';
  // }
  return isNaN(converted) ? '' : Number(converted).toLocaleString();
};

export const checkPercent = (e) => {
  const target = e.currentTarget.value;
  if (isNaN(Number(target))) {
    return '';
  }
  if (Number(target) > 100 || Number(target) < 0) {
    return '';
  }
  return target;
};

export const checkDscr = (e) => {
  const target = e.currentTarget.value;
  if (isNaN(Number(target))) {
    return '';
  }
  if (Number(target) > 20 || Number(target) < 0) {
    return '';
  }
  return target;
};
