import { call, takeLatest, put } from 'redux-saga/effects';
import {
  sendDealEmail as sendDealEmailAction,
  sendApprovalEmail as sendApprovalEmailAction,
  checkDealEmailLimit as checkDealEmailLimitAction,
  addEmailUploads,
} from 'actions/deal-email';
import { sendDealApi, sendApprovalEmailApi, checkDealEmailLimitApi } from 'services/deal-email';
import { addUploadApi } from 'services/uploads';
import fetchEntity from './fetch-entity';

const sendDealEmail = fetchEntity.bind(null, sendDealEmailAction.actions, sendDealApi);

export function* loadSendDealEmail({ params }) {
  yield put({ type: 'CLEAR_UPLOADS' });
  yield call(sendDealEmail, { ...params });
}

function* watchSendDealEmail() {
  yield takeLatest(sendDealEmailAction.actionName, loadSendDealEmail);
}

const sendApprovalEmail = fetchEntity.bind(null, sendApprovalEmailAction.actions, sendApprovalEmailApi);

export function* loadSendApprovalEmail({ params }) {
  yield call(sendApprovalEmail, { ...params });
}

function* watchSendApprovalEmail() {
  yield takeLatest(sendApprovalEmailAction.actionName, loadSendApprovalEmail);
}

const checkDealEmailLimit = fetchEntity.bind(null, checkDealEmailLimitAction.actions, checkDealEmailLimitApi);

export function* loadCheckDealEmail({ params }) {
  yield call(checkDealEmailLimit, { ...params });
}

function* watchCheckDealEmail() {
  yield takeLatest(checkDealEmailLimitAction.actionName, loadCheckDealEmail);
}

const fetchAddEmailUploads = fetchEntity.bind(
  null,
  addEmailUploads.actions,
  addUploadApi,
);

export function* loadAddEmailUploads({ params }) {
  yield call(fetchAddEmailUploads, { ...params });
}

function* watchAddEmailUploads() {
  yield takeLatest(addEmailUploads.actionName, loadAddEmailUploads);
}

export default {
  watchSendDealEmail,
  watchAddEmailUploads,
  watchSendApprovalEmail,
  watchCheckDealEmail,
};
