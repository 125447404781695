import { call, takeLatest } from 'redux-saga/effects';
import { updateLineValues, addLineValues, linesValues } from 'actions/lines';
import { updateLineApi, addLineApi, linesApi } from 'services/lines';
import fetchEntity from './fetch-entity';

const fetchLinesValues = fetchEntity.bind(null, linesValues.actions, linesApi);

export function* loadLines({ params }) {
  yield call(fetchLinesValues, { ...params });
}

function* watchLinesValues() {
  yield takeLatest(linesValues.actionName, loadLines);
}

const addLinePost = fetchEntity.bind(null, addLineValues.actions, addLineApi);

export function* addLine({ params }) {
  yield call(addLinePost, { ...params });
}

function* watchAddLine() {
  yield takeLatest(addLineValues.actionName, addLine);
}
const updateLinePost = fetchEntity.bind(null, updateLineValues.actions, updateLineApi);

export function* updateLine({ params }) {
  yield call(updateLinePost, { ...params });
}

function* watchUpdateLine() {
  yield takeLatest(updateLineValues.actionName, updateLine);
}

export default {
  watchLinesValues,
  watchAddLine,
  watchUpdateLine,
};
