import { fetchLenders, getLender, updateLender } from 'actions/lenders';

const lenders = () => {
  const initialState = {
    lenders: null,
    selectedLender: null,
    updatedLender: null,
  };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case fetchLenders.requestTypes.SUCCESS:
        return {
          ...state,
          lenders: response,
        };
      case getLender.requestTypes.SUCCESS:
        return {
          ...state,
          selectedLender: response,
        };
      case updateLender.requestTypes.SUCCESS:
        return {
          ...state,
          updatedLender: response,
        };
      default:
        return state;
    }
  };
};

export default lenders();
