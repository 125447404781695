import {
  getconstructionPropertyDetails, addConstructionPropertyValues, updateConstructionPropertyValues, removeConstructionProperty,
  addProjectMetrics, updateProjectMetrics, addConstructionLoan, getConstructionLoanDetails, getIndexRates, removeConstructionLoan,
  addConstructionRevenue, getConstructionRevenue, getProjectMetrics, getConstructionLoan, updateConstructionRevenue,
  updateConstructionLoan, removeProjectMetrics, removeConstructionRevenue,
} from 'actions/construction';

const construction = () => {
  const initialState = {
    constructionLoansData: null,
    indexRates: null,
    constructionProperty: null,
    constructionRevenue: null,
    constructionProjectMetrics: null,
  };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case getconstructionPropertyDetails.requestTypes.SUCCESS:
        return {
          ...state,
          constructionProperty: response,
        };
      case addConstructionPropertyValues.requestTypes.SUCCESS:
        return {
          ...response,
        };
      case updateConstructionPropertyValues.requestTypes.SUCCESS:
        return {
          ...state,
          constructionProperty: response,
        };
      case removeConstructionProperty.requestTypes.SUCCESS:
        return {
          ...response,
        };
      case addProjectMetrics.requestTypes.SUCCESS:
        return {
          ...response,
        };
      case getProjectMetrics.requestTypes.SUCCESS:
        return {
          ...state,
          constructionProjectMetrics: response,
        };
      case updateProjectMetrics.requestTypes.SUCCESS:
        return {
          ...state,
          constructionProjectMetrics: response,
        };
      case removeProjectMetrics.requestTypes.SUCCESS:
        return {
          ...response,
        };
      case addConstructionRevenue.requestTypes.SUCCESS:
        return {
          ...response,
        };
      case getConstructionRevenue.requestTypes.SUCCESS:
        return {
          ...state,
          constructionRevenue: response,
        };
      case updateConstructionRevenue.requestTypes.SUCCESS:
        return {
          ...state,
          constructionRevenue: response,
        };
      case removeConstructionRevenue.requestTypes.SUCCESS:
        return {
          ...response,
        };
      case addConstructionLoan.requestTypes.SUCCESS:
        return {
          ...response,
        };
      case updateConstructionLoan.requestTypes.SUCCESS:
        return {
          ...state,
          constructionLoansDate: response,
        };
      case removeConstructionLoan.requestTypes.SUCCESS:
        return {
          ...response,
        };
      case getConstructionLoanDetails.requestTypes.SUCCESS:
        return {
          ...state,
          constructionLoansData: response,
        };
      case getIndexRates.requestTypes.SUCCESS:
        return {
          ...response,
          ...state,
        };
      case getConstructionLoan.requestTypes.SUCCESS:
        return {
          // constructionLoansData: action.response.entities,
          ...response,
          ...state,
        };
      default:
        return state;
    }
  };
};

export default construction();
