import { makeRequestAction } from './index';

export const ratesValues = makeRequestAction('RATES', {
  onSuccess(params, response) {
    return {
      response: {
        entities: {
          rateData: response,
        },
      },
    };
  },
});
