import { addSurvey, getSurvey } from 'actions/survey';

const survey = () => {
  const initialState = {
    survey: {},
  };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case addSurvey.requestTypes.SUCCESS:
        return {
          ...state,
          survey: response,
        };
      case getSurvey.requestTypes.SUCCESS:
        return {
          ...state,
          survey: response,
        };
      default:
        return state;
    }
  };
};

export default survey();
