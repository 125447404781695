import client from './main-client';

export const sendTestEmailApi = (data) => {
  if (data.leadEmails) {
    delete data.leadEmails;
    return client().post('/test-email?type=leadEmailTest', data);
  }
  return client().post('/test-email', data);
};

export const getTestEmailDealApi = (data) => client().get(`/test-email?token=${data.token}`);
