import {
  call, takeLatest,
} from 'redux-saga/effects';

import { getLenderAnalyticsApi } from 'services/lender-analytics';
import { fetchLenderAnalytics } from 'actions/lender-analytics';
import fetchEntity from './fetch-entity';

const fetchlenderAnalyticsData = fetchEntity
  .bind(null, fetchLenderAnalytics.actions, getLenderAnalyticsApi);

export function* loadFetchlenderAnalyticsData({ params }) {
  yield call(fetchlenderAnalyticsData, params);
}

function* watchFetchlenderAnalyticsData() {
  yield takeLatest(fetchLenderAnalytics.actionName, loadFetchlenderAnalyticsData);
}
export default { watchFetchlenderAnalyticsData };
