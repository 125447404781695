import { makeRequestAction } from './index';

export const bondsValues = makeRequestAction('BONDS', {
  onSuccess(params, response) {
    return {
      response: {
        entities: {
          bondsData: response,
        },
      },
    };
  },
});
