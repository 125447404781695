import { API_ROOT } from 'configs/env-vars';
import { makeRequestAction } from './index';

export const getSavedConsultants = makeRequestAction('GET_SAVED_CONSULTANTS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const getSavedLenders = makeRequestAction('GET_SAVED_LENDERS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const getLenderDraftEmail = makeRequestAction('GET_LENDER_DRAFT_EMAIL', {
  onSuccess(params, response) {
    return {
      response: { ...response },
    };
  },
});

export const getConsultantDraftEmail = makeRequestAction('GET_CONSULTANT_DRAFT_EMAIL', {
  onSuccess(params, response) {
    return {
      response: { ...response },
    };
  },
});

export const saveConsultantDraft = makeRequestAction('SAVE_CONSULTANT_DRAFT', {
  onSuccess(params, response) {
    return {
      response: { ...response },
    };
  },
});

export const saveLenderDraft = makeRequestAction('SAVE_LENDER_DRAFT', {
  onSuccess(params, response) {
    return {
      response: { ...response },
    };
  },
});

export const addDraftUploads = makeRequestAction('ADD_DRAFT_UPLOADS', {
  onSuccess(params, response) {
    return {
      files: response.map((file) => ({
        uid: file.hashedId,
        name: file.fileName,
        url: `${API_ROOT}/file-storage/${file.hashedId}`,
        source: file.source,
      })),
    };
  },
});

export const deleteDraftUpload = makeRequestAction('DELETE_DEAL_DRAFT_UPLOAD', {
  onSuccess(params, response) {
    return {
      deletedFileId: response.hashedId,
    };
  },
});
