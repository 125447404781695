import {
  getFinneoClients, getScheduleBClients,
} from 'actions/owners';

const myClients = () => {
  const initialState = {
    finneoClients: [],
    scheduleBClients: [],
  };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case getFinneoClients.requestTypes.SUCCESS:
        return {
          ...state,
          finneoClients: response,
        };
      case getScheduleBClients.requestTypes.SUCCESS:
        return {
          ...state,
          schedBClients: response,
        };
      default:
        return state;
    }
  };
};

export default myClients();
