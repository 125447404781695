import {
  call, takeLatest,
} from 'redux-saga/effects';
import {
  fetchConfAgreement,
} from 'actions/conf-agreement';
import {
  fetchConfAgreementApi,
} from 'services/conf-agreement';
import fetchEntity from './fetch-entity';

const fetchConfAgreementData = fetchEntity
  .bind(null, fetchConfAgreement.actions, fetchConfAgreementApi);

export function* loadFetchConfData({ params }) {
  yield call(
    fetchConfAgreementData,
    { ...params },
  );
}

function* watchFetchConfData() {
  yield takeLatest(fetchConfAgreement.actionName, loadFetchConfData);
}

export default {
  watchFetchConfData,
};
