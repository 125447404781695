import { makeRequestAction } from './index';

export const fetchLenderDeals = makeRequestAction('LENDER_DEALS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});
