import {
  sendLenderInfo,
  fetchLenderLoans,
  fetchLoanNumbers,
  fetchBorrowerData,
  fetchLoanBorrowers,
  fetchLoanComparison,
} from 'actions/lender-loans';

const lenderLoans = () => (state = {
  borrowerData: [],
  lenderLoans: null,
  lenderLoansNumber: null,
  lenderLoansBorrowers: null,
  loansComparisonData: null,
}, { type, response }) => {
  switch (type) {
    case fetchLenderLoans.requestTypes.SUCCESS:
      return {
        ...state,
        lenderLoans: response,
      };
    case fetchLoanNumbers.requestTypes.SUCCESS:
      return {
        ...state,
        lenderLoansNumber: response,
      };
    case fetchLoanBorrowers.requestTypes.SUCCESS:
      return {
        ...state,
        lenderLoansBorrowers: response,
      };
    case fetchBorrowerData.requestTypes.SUCCESS:
      return {
        ...state,
        borrowerData: response,
      };
    case sendLenderInfo.requestTypes.SUCCESS:
      return {
        ...state,
        lenderLoansNumber: response,
      };
    case fetchLoanComparison.requestTypes.SUCCESS:
      return {
        ...state,
        loansComparisonData: response,
      };
    default:
      return state;
  }
};

export default lenderLoans();
