import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { userIsAuth } from 'selectors/users';
import { getAccessToken } from 'configs/localStorage';
import { userLogout } from 'actions/users';
import { API_ROOT } from 'configs/env-vars';
import io from 'socket.io-client';

const socket = io(API_ROOT, {
  transports: ['websocket'],
});

export default (props) => {
  const isAuth = useSelector(userIsAuth);
  const intervalRef = useRef(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (isAuth) {
      intervalRef.current = setInterval(() => {
        socket.emit('create', 'check-authentication', { accessToken: getAccessToken() }, (error, message) => {
          if (error) {
            clearInterval(intervalRef.current);
            dispatch(userLogout.request());
          }
        });
      }, 1000 * 15); // 15 seconds
    } else {
      clearInterval(intervalRef.current);
    }
  }, [dispatch, isAuth]);

  return props.children;
};
