import { call, takeLatest } from 'redux-saga/effects';
import { importPortfolio, importBorrowers } from 'actions/import';
import { importPortfolioApi, importBorrowersApi } from 'services/import';
import fetchEntity from './fetch-entity';

const importData = fetchEntity.bind(null, importPortfolio.actions, importPortfolioApi);
const importBorrowersData = fetchEntity.bind(null, importBorrowers.actions, importBorrowersApi);

export function* loadImport({ params }) {
  yield call(importData, { ...params });
}

function* watchImport() {
  yield takeLatest(importPortfolio.actionName, loadImport);
}

export function* loadImportBorrowers({ params }) {
  yield call(importBorrowersData, { ...params });
}

function* watchImportBorrowers() {
  yield takeLatest(importBorrowers.actionName, loadImportBorrowers);
}

export default {
  watchImport,
  watchImportBorrowers,
};
