import {
  call, takeLatest,
} from 'redux-saga/effects';

import {
  addOwnerContact,
  getOwnerContact,
  getAllContacts,
  updateOwnerContact,
  deleteOwnerContact,
} from 'actions/owner-contacts';
import {
  addOwnerContactApi,
  getOwnerContactApi,
  getAllContactsApi,
  updateOwnerContactApi,
  deleteOwnerContactApi,
} from 'services/owner-contacts';

import fetchEntity from './fetch-entity';

const fetchAddOwnerContact = fetchEntity.bind(
  null,
  addOwnerContact.actions,
  addOwnerContactApi,
);

export function* loadAddOwnerContact({ params }) {
  yield call(fetchAddOwnerContact, params);
}

function* watchAddOwnerContact() {
  yield takeLatest(addOwnerContact.actionName, loadAddOwnerContact);
}

const fetchGetOwnerContact = fetchEntity.bind(
  null,
  getOwnerContact.actions,
  getOwnerContactApi,
);

export function* loadGetOwnerContact({ params }) {
  yield call(fetchGetOwnerContact, params);
}

function* watchGetOwnerContact() {
  yield takeLatest(getOwnerContact.actionName, loadGetOwnerContact);
}

const fetchGetAllContacts = fetchEntity.bind(
  null,
  getAllContacts.actions,
  getAllContactsApi,
);

export function* loadGetAllContacts({ params }) {
  yield call(fetchGetAllContacts, params);
}

function* watchGetAllContacts() {
  yield takeLatest(getAllContacts.actionName, loadGetAllContacts);
}

const fetchUpdateOwnerContact = fetchEntity.bind(
  null,
  updateOwnerContact.actions,
  updateOwnerContactApi,
);

export function* loadUpdateOwnerContact({ params }) {
  yield call(fetchUpdateOwnerContact, params);
}

function* watchUpdateOwnerContact() {
  yield takeLatest(updateOwnerContact.actionName, loadUpdateOwnerContact);
}

const fetchDeleteOwnerContact = fetchEntity.bind(
  null,
  deleteOwnerContact.actions,
  deleteOwnerContactApi,
);

export function* loadDeleteOwnerContact({ params }) {
  yield call(fetchDeleteOwnerContact, params);
}

function* watchDeleteOwnerContact() {
  yield takeLatest(deleteOwnerContact.actionName, loadDeleteOwnerContact);
}

export default {
  watchAddOwnerContact,
  watchGetOwnerContact,
  watchGetAllContacts,
  watchUpdateOwnerContact,
  watchDeleteOwnerContact,
};
