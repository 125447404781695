import {
  call, takeLatest,
} from 'redux-saga/effects';
import {
  getDeal,
} from 'actions/guest-deal';
import {
  getGuestDealApi,
} from 'services/guest-deal';
import fetchEntity from './fetch-entity';

const getGuestDealData = fetchEntity
  .bind(null, getDeal.actions, getGuestDealApi);

export function* loadGetGuestDealData({ params }) {
  yield call(
    getGuestDealData,
    { ...params },
  );
}

function* watchGetGuestDeal() {
  yield takeLatest(getDeal.actionName, loadGetGuestDealData);
}

export default {
  watchGetGuestDeal,
};
