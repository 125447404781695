import React from 'react';
import {
  withScriptjs, withGoogleMap, GoogleMap, Marker,
} from 'react-google-maps';

const Map = withScriptjs(withGoogleMap((props) => {
  const {
    lat, lng,
  } = props;

  return <GoogleMap
    google={props.google}
    defaultZoom={10}
    defaultCenter={{ lat, lng }}
    center={{ lat, lng }}
  >
    <Marker
      google={props.google}
      position={{ lat, lng }} />
  </GoogleMap>;
}));

export default React.memo(Map);
