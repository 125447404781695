import moment from 'moment';

export const DD_MMM_YYYY = 'DD MMM, YYYY';
export const YYYY_MM_DD = 'YYYY MMM DD ';
export const HH_MM_A = 'hh:mm A';
export const MMMM = 'MMMM';
export const MMM = 'MMM';
export const MMMM_YYYY = 'MMMM, YYYY';
export const MMM_DD_YYYY = 'MMM DD, YYYY';
export const MMMM_DD_YYYY = 'MMMM DD, YYYY';
export const MMM_YY = 'MMM YY';
export const YYYY = 'YYYY';
export const LLLL = 'LLLL';

export const dateFormatHandler = (date, type, format = null) => {
  if (!date) return '-';
  if (format) {
    return moment(date, format).format(type);
  }
  return moment(date).format(type);
};
