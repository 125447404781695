import {
  getEmailTemplate,
} from 'actions/email-templates';

const emailTemplates = () => {
  const initialState = {
    templates: null,
    selectedTemplate: null,
  };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case getEmailTemplate.requestTypes.SUCCESS:
        return {
          ...state,
          selectedTemplate: response,
        };
      case getEmailTemplate.requestTypes.REQUEST:
        return {
          ...state,
          templates: null,
          selectedTemplate: null,
        };
      case 'CLEAR_TEMPLATE':
        return {
          ...state,
          templates: null,
          selectedTemplate: null,
        };
      default:
        return state;
    }
  };
};

export default emailTemplates();
