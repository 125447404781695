import { call, takeLatest } from 'redux-saga/effects';
import {
  getPreFundingData as getPreFundingDataAction,
  addPreFundingData as addPreFundingDataAction,
  updatePreFundingData as updatePreFundingDataAction,
  deletePreFundingData as deletePreFundingDataAction,
  resetPreFundingData as resetPreFundingDataAction,
  getPreFundingCountData as getPreFundingCountDataAction,
  addSyndicateLendersData as addSyndicateLendersDataAction,
  generatePrefundingTokenData as generatePrefundingTokenDataAction,
  getShareableLinkData as getShareableLinkDataAction,
  deleteFileData as deleteFileDataAction,
} from 'actions/preFundingTracker';
import {
  getPreFundingDataApi, addPreFundingDataApi,
  updatePreFundingDataApi, deletePreFundingDataApi,
  resetPreFundingDataApi, getTrackerCountApi, addSyndicateLendersApi, generatePrefundingTokenApi, getShareableLinkDataApi, deletePreFundingFileApi,
} from 'services/preFundingTracker';
import fetchEntity from './fetch-entity';

// Get PrefundingData
const getPreFundingData = fetchEntity.bind(null, getPreFundingDataAction.actions, getPreFundingDataApi);

export function* loadGetPreFundingData({ params }) {
  yield call(getPreFundingData, { ...params });
}

function* watchGetPreFundingData() {
  yield takeLatest(getPreFundingDataAction.actionName, loadGetPreFundingData);
}

// Add PrefundingData
const addPreFundingData = fetchEntity.bind(null, addPreFundingDataAction.actions, addPreFundingDataApi);

export function* loadAddPreFundingData({ params }) {
  yield call(addPreFundingData, { ...params });
}

function* watchAddPreFundingData() {
  yield takeLatest(addPreFundingDataAction.actionName, loadAddPreFundingData);
}

// Update PreFundingData
const updatePreFundingData = fetchEntity.bind(null, updatePreFundingDataAction.actions, updatePreFundingDataApi);

export function* loadUpdatePreFundingData({ params }) {
  yield call(updatePreFundingData, { ...params });
}

function* watchUpdatePreFundingData() {
  yield takeLatest(updatePreFundingDataAction.actionName, loadUpdatePreFundingData);
}

// delete PreFundingData
const deletePreFundingData = fetchEntity.bind(null, deletePreFundingDataAction.actions, deletePreFundingDataApi);

export function* loadDeletePreFundingData({ params }) {
  yield call(deletePreFundingData, { ...params });
}

function* watchDeletePreFundingData() {
  yield takeLatest(deletePreFundingDataAction.actionName, loadDeletePreFundingData);
}

// reset PreFundingData
const resetPreFundingData = fetchEntity.bind(null, resetPreFundingDataAction.actions, resetPreFundingDataApi);

export function* loadResetPreFundingData({ params }) {
  yield call(resetPreFundingData, { ...params });
}

function* watchResetPreFundingData() {
  yield takeLatest(resetPreFundingDataAction.actionName, loadResetPreFundingData);
}

// add SyndicateLenders
const addSyndicateLendersData = fetchEntity.bind(null, addSyndicateLendersDataAction.actions, addSyndicateLendersApi);

export function* loadAddSyndicateLenders({ params }) {
  yield call(addSyndicateLendersData, { ...params });
}

function* watchAddSyndicateLenders() {
  yield takeLatest(addSyndicateLendersDataAction.actionName, loadAddSyndicateLenders);
}

// get PreFunding count
const getPreFundingCountData = fetchEntity.bind(null, getPreFundingCountDataAction.actions, getTrackerCountApi);

export function* loadPreFundingCountData({ params }) {
  yield call(getPreFundingCountData, { ...params });
}

function* watchPreFundingCountData() {
  yield takeLatest(getPreFundingCountDataAction.actionName, loadPreFundingCountData);
}

// generate Prefunding token
const generatePrefundingTokenData = fetchEntity.bind(null, generatePrefundingTokenDataAction.actions, generatePrefundingTokenApi);

export function* loadGeneratePrefundingTokenData({ params }) {
  yield call(generatePrefundingTokenData, { ...params });
}

function* watchloadGeneratePrefundingTokenData() {
  yield takeLatest(generatePrefundingTokenDataAction.actionName, loadGeneratePrefundingTokenData);
}

// get sharable link data
const getShareableLinkData = fetchEntity.bind(null, getShareableLinkDataAction.actions, getShareableLinkDataApi);

export function* loadGetShareableLinkData({ params }) {
  yield call(getShareableLinkData, { ...params });
}

function* watchGetShareableLinkData() {
  yield takeLatest(getShareableLinkDataAction.actionName, loadGetShareableLinkData);
}

// delete file corresponding to prefundign document
const deletePreFundingFileData = fetchEntity.bind(null, deleteFileDataAction.actions, deletePreFundingFileApi);

export function* loadDeletePreFundingFileData({ params }) {
  yield call(deletePreFundingFileData, { ...params });
}

function* watchDeletePreFundingFileData() {
  yield takeLatest(deleteFileDataAction.actionName, loadDeletePreFundingFileData);
}

export default {
  watchGetPreFundingData,
  watchAddPreFundingData,
  watchUpdatePreFundingData,
  watchDeletePreFundingData,
  watchResetPreFundingData,
  watchPreFundingCountData,
  watchAddSyndicateLenders,
  watchloadGeneratePrefundingTokenData,
  watchGetShareableLinkData,
  watchDeletePreFundingFileData,
};
