import {
  fetchBorrowerNotes,
  addBorrowerNote,
  getBorrowerNote,
  updateBorrowerNote,
  deleteBorrowerNote,
} from 'actions/borrower-notes';

const borrowerNotes = () => {
  const initialState = {
    borrowerNotes: null,
    selectedBorrowerNote: null,
  };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case fetchBorrowerNotes.requestTypes.SUCCESS:
        return {
          ...state,
          borrowerNotes: response,
        };
      case addBorrowerNote.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case addBorrowerNote.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case addBorrowerNote.requestTypes.SUCCESS:
        return {
          ...state,
          selectedBorrowerNote: response,
          isSuccessful: true,
        };
      case updateBorrowerNote.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateBorrowerNote.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateBorrowerNote.requestTypes.SUCCESS:
        return {
          ...state,
          selectedBorrowerNote: response,
          isSuccessful: true,
        };
      case getBorrowerNote.requestTypes.SUCCESS:
        return {
          ...state,
          selectedBorrowerNote: response,
        };
      case deleteBorrowerNote.requestTypes.SUCCESS:
        return {
          ...state,
          selectedBorrowerNote: response,
        };
      default:
        return state;
    }
  };
};

export default borrowerNotes();
