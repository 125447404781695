import {
  fetchDealsLenderBids, createBid, updateBid, deleteBid, patchBid,
} from 'actions/deals-lender-bids';

import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';

export const getDealsLenderBidsState = (state) => state.dealsLenderBids;
export const dealsLenderBidsValuesLoading = createLoadingSelector(fetchDealsLenderBids.actionName)();
export const dealsLenderBidsValuesError = createErrorSelector(fetchDealsLenderBids.actionName)();

export const newDealLenderBidsLoading = createLoadingSelector(createBid.actionName)();
export const newDealLenderBidsError = createErrorSelector(createBid.actionName)();

export const updateDealLenderBidsLoading = createLoadingSelector(
  updateBid.actionName,
)();
export const updateDealLenderBidsError = createErrorSelector(
  updateBid.actionName,
)();

export const patchFollowUpStatus = (state) => state.dealsLenderBids.isFollowUpSuccessful;

export const patchDealLenderBidsLoading = createLoadingSelector(
  patchBid.actionName,
)();
export const patchDealLenderBidsError = createErrorSelector(
  patchBid.actionName,
)();

export const deleteBidsLoading = createLoadingSelector(deleteBid.actionName)();
