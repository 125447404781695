import {
  sendLeadEmail,
  sendLeadApprovalEmail,
  checkLeadEmailLimit,
} from 'actions/leads-email';

const leadsEmail = () => {
  const initialState = {
    leadsEmail: [],
  };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case sendLeadEmail.requestTypes.SUCCESS:
        return {
          ...state,
          leadsEmail: response,
        };
      case sendLeadApprovalEmail.requestTypes.SUCCESS:
        return {
          ...state,
          leadsEmail: response,
        };
      case checkLeadEmailLimit.requestTypes.SUCCESS:
        return {
          ...state,
          response,
        };
      default:
        return state;
    }
  };
};

export default leadsEmail();
