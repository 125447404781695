import { call, takeLatest } from 'redux-saga/effects';
import {
  fetchLenders,
} from 'actions/lenders-with-categories';
import {
  fetchLendersApi,
} from 'services/lenders-with-categories';
import fetchEntity from './fetch-entity';

const fetchLendersWithCategoriesData = fetchEntity.bind(
  null,
  fetchLenders.actions,
  fetchLendersApi,
);

export function* loadFetchLendersWithCategoriesData({ params }) {
  yield call(fetchLendersWithCategoriesData, { ...params });
}

function* watchFetchLendersWithCategoriesData() {
  yield takeLatest(fetchLenders.actionName, loadFetchLendersWithCategoriesData);
}

export default {
  watchFetchLendersWithCategoriesData,
};
