import { makeRequestAction } from './index';

export const fetchDealsLenderBids = makeRequestAction('FETCH_DEALS_LENDER_BIDS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const createBid = makeRequestAction('CREATE_DEAL_LENDER_BIDS');
export const updateBid = makeRequestAction('UPDATE_DEAL_LENDER_BIDS');
export const patchBid = makeRequestAction('PATCH_DEAL_LENDER_BIDS');
export const deleteBid = makeRequestAction('DELETE_DEAL_LENDER_BIDS', {
  onSuccess(params, response) {
    return {
      ids: response.map((len) => len.id),
    };
  },
});

export const changeGuestLinkStatus = makeRequestAction('CHANGE_LINK_STATUS_LENDER', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
  // onFailure(params, error){
  //   console.log("failure",error, params);
  // }
});
