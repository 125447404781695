import React from 'react';
import {
  withScriptjs, withGoogleMap, GoogleMap, Marker,
} from 'react-google-maps';
import Autocomplete from 'react-google-autocomplete';

const MyMapComponent = withScriptjs(withGoogleMap((props) => {
  const {
    lat, lng, setLat, setLng, address, setAddress, setProvince, setCity,
  } = props;

  const onPlaceSelected = (place) => {
    if (place.geometry) {
      const latValue = place.geometry.location.lat();
      const lngValue = place.geometry.location.lng();
      setAddress(place.formatted_address);
      setLat(latValue);
      setLng(lngValue);
    }
    if (place.address_components) {
      const provinceValue = place.address_components.find((level) => level.types[0] === 'administrative_area_level_1');
      if (provinceValue && provinceValue.short_name) {
        setProvince(provinceValue.short_name);
      }
    }
    // we need to fetch city name
    let cityName = '-';
    if (place.address_components) {
      // eslint-disable-next-line arrow-body-style
      place.address_components.find((level) => {
        return level.types.find((type) => {
          if (type === 'locality') {
            cityName = level.long_name;
            return true;
          }
          return false;
        });
      });
    }
    setCity(cityName);
  };

  return <GoogleMap
    google={props.google}
    defaultZoom={10}
    defaultCenter={{ lat, lng }}
    center={{ lat, lng }}
  >
    <Marker
      google={props.google}
      position={{ lat, lng }} />

    <Autocomplete
      defaultValue={address}
      style={{
        width: '100%',
        height: '40px',
        paddingLeft: '16px',
        marginTop: '2px',
        marginBottom: '500px',
      }}
      onPlaceSelected={onPlaceSelected}
      types={null}
    />
  </GoogleMap>;
}));

export default React.memo(MyMapComponent);
