import React from 'react';
import { Map, GoogleApiWrapper, Polygon } from 'google-maps-react';
import { GOOGLE_MAPS_KEY } from 'configs/env-vars';
import provinces from '../../utils/provinceCoordinates';

function PolygonMap(props) {
  const provincesToHighlight = [];

  props.provinces.map((province) => {
    if (provinces[province]) {
      return (province === 'BC' || province === 'NL'
        ? provincesToHighlight.push(...provinces[`${province}`])
        : provincesToHighlight.push(provinces[`${province}`]));
    }
    return null;
  });

  return props.provinces ? (
    <Map
      gestureHandling={'cooperative'}
      google={props.google}
      zoom={3}
      style={props.style}
      initialCenter={props.center}
      scrollwheel={props.scrollwheel}
      streetViewControl={props.streetViewControl}
      mapTypeControl={props.mapTypeControl}
      fullscreenControl={props.fullscreenControl}
      centerAroundCurrentLocation
    >
      <Polygon
        paths={provincesToHighlight}
        strokeColor='#0000FF'
        strokeOpacity={0.4}
        strokeWeight={1}
        fillColor='#0000FF'
        fillOpacity={0.2}
      />
    </Map>
  ) : (
    'no map'
  );
}

export default GoogleApiWrapper({
  // TODO: Must move this API key to environment variables
  apiKey: GOOGLE_MAPS_KEY,
})(PolygonMap);
