import { call, takeLatest, put } from 'redux-saga/effects';
import {
  getForms,
  addFormUploads,
  deleteFormUploads,
} from 'actions/form-builder';
import { getFormsApi } from 'services/form-builder';
import { addUploadApi, deleteUploadApi } from 'services/uploads';
import fetchEntity from './fetch-entity';

// get forms
const fetchForms = fetchEntity.bind(
  null,
  getForms.actions,
  getFormsApi,
);

export function* loadGetForms({ params }) {
  yield call(fetchForms, { ...params });
}

function* watchGetFormBuilders() {
  yield takeLatest(getForms.actionName, loadGetForms);
}

const fetchAddFormUploads = fetchEntity.bind(
  null,
  addFormUploads.actions,
  addUploadApi,
);

const fetchDeleteFormUploads = fetchEntity.bind(
  null,
  deleteFormUploads.actions,
  deleteUploadApi,
);

export function* loadAddFormUploads({ params }) {
  yield call(fetchAddFormUploads, { ...params });
}

function* watchAddFormUploads() {
  yield takeLatest(addFormUploads.actionName, loadAddFormUploads);
}

export function* loadDeleteFormUploads({ params }) {
  yield put({ type: 'CLEAR_UPLOADS' });
  yield call(fetchDeleteFormUploads, { ...params });
}

function* watchDeleteFormUploads() {
  yield takeLatest(deleteFormUploads.actionName, loadDeleteFormUploads);
}

export default {
  watchGetFormBuilders,
  watchAddFormUploads,
  watchDeleteFormUploads,
};
