import { makeRequestAction } from './index';

export const fetchBorrowerNotes = makeRequestAction('FETCH_BORROWER_NOTES', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const addBorrowerNote = makeRequestAction('ADD_BORROWER_NOTE', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const updateBorrowerNote = makeRequestAction('UPDATE_BORROWER_NOTE', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const getBorrowerNote = makeRequestAction('GET_BORROWER_NOTE', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const deleteBorrowerNote = makeRequestAction('DELETE_BORROWER_NOTES', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});
