import {
  getBidEmails, composeBidEmails, replyBidEmails, getBidEmailsById,
  updateFavouriteThreads, updateOpenedMessage, saveDraftEmail, downloadFile, updateFavouriteMessage,
  updateSavedDraftEmail, deleteDraftEmail, updateDraftAttachments, updateDeleteDraftAttachments, deleteEmail,
} from 'actions/bids-email';
import { Message } from 'antd';

const bidEmails = () => {
  const initialState = {
    deleteEmailError: false,
    unreadMessageFailed: false,
  };

  return (state = initialState, { type, response, error }) => {
    switch (type) {
      case getBidEmails.requestTypes.SUCCESS:
        return {
          ...response,
        };
      case getBidEmailsById.requestTypes.SUCCESS:
        return {
          ...response,
        };
      case composeBidEmails.requestTypes.SUCCESS:
        return {
          ...response,
        };
      case replyBidEmails.requestTypes.SUCCESS:
        return {
          ...response,
        };
      case updateFavouriteThreads.requestTypes.SUCCESS:
        return {
          ...response,
        };
      case updateFavouriteMessage.requestTypes.SUCCESS:
        return {
          ...response,
        };
      case updateOpenedMessage.requestTypes.SUCCESS:
        !response?.opened && Message.success('Message marked as Unread');
        return {
          ...state,
          unreadMessageFailed: false,
        };
      case updateOpenedMessage.requestTypes.FAILURE: {
        Message.error('Error while changing the status of message! Please reload and retry.');
        return {
          // ...response,
          // ...state,
          unreadMessageFailed: true,
        };
      }
      case saveDraftEmail.requestTypes.SUCCESS:
        return {
          ...response,
        };
      case updateSavedDraftEmail.requestTypes.SUCCESS:
        return {
          ...response,
        };
      case deleteDraftEmail.requestTypes.SUCCESS:
        return {
          deleteEmailError: false,
          ...response,
        };
      case deleteEmail.requestTypes.SUCCESS: {
        Message.success('Email deleted successfully');
        return {
          deleteEmailError: false,
          ...response,
        };
      }
      case deleteEmail.requestTypes.REQUEST: {
        return {
          deleteEmailError: undefined,
        };
      }
      case deleteEmail.requestTypes.FAILURE: {
        Message.error(error.data.error.message || 'Error while deleting the email!');
        return {
          deleteEmailError: true,
        };
      }
      case downloadFile.requestTypes.SUCCESS:
        return {
          ...response,
        };
      case updateDraftAttachments.requestTypes.SUCCESS:
        return {
          ...response,
        };
      case updateDeleteDraftAttachments.requestTypes.SUCCESS:
        return {
          ...response,
        };
      default:
        return state;
    }
  };
};

export default bidEmails();
