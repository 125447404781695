import React from 'react';

import './ProgressBar.css';

const ProgressBar = ({ percentValue }) => (
  <div className='bar animate'>
    <span style={{ width: `${percentValue.toFixed(2)}%` }} />
  </div>
);

export default ProgressBar;
