import { API_ROOT } from 'configs/env-vars';
import { makeRequestAction } from './index';

/**
 * Get Forms
 */
export const getForms = makeRequestAction('GET_FORMS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const addFormUploads = makeRequestAction('ADD_FORM_UPLOADS', {
  onSuccess(params, response) {
    return {
      files: response.map((file) => ({
        uid: file.hashedId,
        name: file.fileName,
        url: `${API_ROOT}/file-storage/${file.hashedId}`,
        source: file.source,
      })),
    };
  },
});

export const deleteFormUploads = makeRequestAction('DELETE_FORM_UPLOADS', {
  onSuccess(params, response) {
    return {
      deletedFileId: response.hashedId,
    };
  },
});
