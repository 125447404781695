import { call, takeLatest } from 'redux-saga/effects';
import { ratesValues } from 'actions/rates';
import { ratesApi } from 'services/rates';
import fetchEntity from './fetch-entity';

const fetchratesValues = fetchEntity.bind(null, ratesValues.actions, ratesApi);

export function* loadRates({ params }) {
  yield call(fetchratesValues, { ...params });
}

function* watchratesValues() {
  yield takeLatest(ratesValues.actionName, loadRates);
}

export default {
  watchratesValues,
};
