import {
  call, takeLatest, select,
} from 'redux-saga/effects';
import {
  fetchDealsConsultantBids, createBid, updateBid, deleteBid, patchBid,
} from 'actions/deals-consultant-bids';
import {
  fetchDealsConsultantBidsApi,
  postNewDealsConsultantBid,
  updateDealsConsultantBidsApi,
  deleteDealsConsultantBidsApi,
  patchDealsConsultantBidsApi,
} from 'services/deals-consultant-bids';
import fetchEntity from './fetch-entity';

const fetchDealsConsultantBidsData = fetchEntity.bind(
  null,
  fetchDealsConsultantBids.actions,
  fetchDealsConsultantBidsApi,
);
const fetchAddNewDealsConsultantBids = fetchEntity.bind(
  null,
  createBid.actions,
  postNewDealsConsultantBid,
);
const fetchUpdateDealsConsultantBid = fetchEntity.bind(
  null,
  updateBid.actions,
  updateDealsConsultantBidsApi,
);
const fetchPatchDealsConsultantBid = fetchEntity.bind(
  null,
  patchBid.actions,
  patchDealsConsultantBidsApi,
);
const fetchDeleteDealsConsultantBid = fetchEntity.bind(
  null,
  deleteBid.actions,
  deleteDealsConsultantBidsApi,
);

export function* loadFetchDealsBidsData({ params }) {
  yield call(
    fetchDealsConsultantBidsData,
    { ...params },
  );
}
export function* loadAddNewDealsConsultantBids({ params }) {
  yield call(fetchAddNewDealsConsultantBids, { ...params });
}
export function* loadUpdateDealsConsultantBids({ params }) {
  yield call(fetchUpdateDealsConsultantBid, { ...params });
}
export function* loadPatchDealsConsultantBids({ params }) {
  yield call(fetchPatchDealsConsultantBid, { ...params });
}
export function* loadDeleteDealsConsultantBid({ params }) {
  yield call(fetchDeleteDealsConsultantBid, { ...params });
}
export function* loadDealsConsultantBidsOnChange({ params }) {
  const dealsConsultantBids = yield select((state) => state.dealsConsultantBids);
  const dealId = dealsConsultantBids.data[0] && dealsConsultantBids.data[0].dealId;
  const { total, skip, limit } = dealsConsultantBids;
  if (skip && skip >= total) {
    yield call(fetchDealsConsultantBidsData, {
      ...params,
      $skip: total - Math.max(total % limit, limit),
      '$sort[updatedAt]': -1,
      dealId,
    });
  } else {
    yield call(fetchDealsConsultantBidsData, {
      $skip: skip,
      '$sort[updatedAt]': -1,
      dealId,
    });
  }
}

function* watchFetchDealsConsultantBids() {
  yield takeLatest([fetchDealsConsultantBids.actionName], loadFetchDealsBidsData);
}
function* watchAddNewDealsConsultantBids() {
  yield takeLatest(createBid.actionName, loadAddNewDealsConsultantBids);
}
function* watchUpdateDealsConsultantBids() {
  yield takeLatest(updateBid.actionName, loadUpdateDealsConsultantBids);
}
function* watchPatchDealsConsultantBids() {
  yield takeLatest(patchBid.actionName, loadPatchDealsConsultantBids);
}
function* watchDeleteDealsConsultantBid() {
  yield takeLatest(deleteBid.actionName, loadDeleteDealsConsultantBid);
}
function* watchDealsConsultantBidsOnChange() {
  yield takeLatest([
    deleteBid.requestTypes.SUCCESS,
  ], loadDealsConsultantBidsOnChange);
}

export default {
  watchFetchDealsConsultantBids,
  watchAddNewDealsConsultantBids,
  watchUpdateDealsConsultantBids,
  watchDeleteDealsConsultantBid,
  watchDealsConsultantBidsOnChange,
  watchPatchDealsConsultantBids,
};
