import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import Background from 'assets/imgs/login_bg.jpg';
import Logo from './Logo';

const FrontSidebarMainSidebar = styled(Col)`
  background-color: #4962f5;
  box-shadow: 0 2px 9px 0 rgba(35, 82, 124, 0.16);
  color: #fff;
  font-size: 18px;
  height: 100vh;
  position: fixed !important;
  width: inherit;
`;

const FrontSidebarMainInner = styled(Row)`
  background-image: url(${Background});
  background-size: 100% 100vh;
  padding: 40px;
  font-size: 14px;
  font-weight: normal;
  height: 100vh;
`;

const FrontSidebarIntro = styled.div``;

function FrontSidebar() {
  return (
    <FrontSidebarMainSidebar>
      <FrontSidebarMainInner type={'flex'}>
        <Logo />
        <FrontSidebarIntro>
          Finneo empowers CRE Debt Professionals who are experienced,
          knowledgeable, and skilled in commercial real estate transactions
          with market-leading technology that helps our clients manage
          and place loans in the best way possible.
        </FrontSidebarIntro>
      </FrontSidebarMainInner>
    </FrontSidebarMainSidebar>
  );
}

export default FrontSidebar;
