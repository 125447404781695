import styled, { css } from 'styled-components';
import { List as list } from 'antd';

const List = styled(list)`
    
    .ant-list-header
    {
        border-bottom: none !important;
    }
    .ant-list-header .ant-typography
    {
        color: #a1a1a1 !important;
        font-size: 12px;
        line-height: 18px;
        text-transform: uppercase;
    }

    ${(props) => props.theme === 'plain' && css`
        border-radius: 0 !important;
        border: 0  !important;
        .ant-list-item
        {
            display: block !important;
            padding: 15px 40px !important;
        }
        h4
        {
            color: #353535;
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            margin: 0 !important;
        }
        span
        {
           display: inline-block !important;
           font-size: 11px;
           font-weight: 400;
           color: #9F9F9F;
        }
    `}

    ${(props) => props.theme === 'dealLendersBidList' && css`
        border-radius: 0 !important;
        border: 0  !important;
        .ant-list-item
        {
            display: block !important;
            padding: 15px 0px !important;
        }
        h4
        {
            color: #353535;
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            margin: 0 !important;
        }
        span
        {
           display: inline-block !important;
           font-size: 11px;
           font-weight: 400;
           color: #9F9F9F;
        }
    `}
    ${(props) => props.theme === 'lender' && css`

    .ant-list-header .ant-typography
    {
        color: #242424 !important;
        font-size: 12px;
        line-height: 18px;
        text-transform: uppercase;
    }

    `}

    ${(props) => props.theme === 'dealLender' && css`

    .ant-list-header .ant-typography
    {
        color: #242424 !important;
        font-size: 12px;
        line-height: 18px;
        text-transform: uppercase;
    }

    `}
`;

export default List;
