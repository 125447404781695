import { makeRequestAction } from './index';

export const getMarkets = makeRequestAction('GET_MARKETS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const getAllMarkets = makeRequestAction('GET_ALL_MARKETS', {
  onSuccess(params, response) {
    return {
      response,
    };
  },
});
