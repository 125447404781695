import { combineReducers } from 'redux';

const reports = () => (
  state = {
    // inital states here
    reportsData: null,
    createdReport: null,
    selectedReport: null,
    updatedReport: null,
    deletedReport: null,
    guestReport: null,
    updateGuestReport: null,
  },
  action,
) => {
  if (action.type === 'REPORTS_SUCCESS' && action.response.entities) { // maybe add switch case
    return {
      ...state,
      reportsData: action.response.entities.reportsData,
      createdReport: null,
      updatedReport: null,
      deletedReport: null,
    };
  }
  if (action.type === 'CREATE_REPORT_SUCCESS' && action.response.entities) {
    return {
      ...state,
      createdReport: action.response.entities.createdReport,
      updatedReport: null,
      deletedReport: null,
    };
  }
  if (action.type === 'UPDATE_REPORT_SUCCESS' && action.response.entities) {
    return {
      ...state,
      updatedReport: action.response.entities.updateReport,
      createdReport: null,
      deletedReport: null,
    };
  }
  if (action.type === 'FETCH_REPORT_SUCCESS' && action.response.entities) {
    return {
      ...state,
      selectedReport: action.response.entities.getReport,
    };
  }
  if (action.type === 'DELETE_REPORT_SUCCESS' && action.response.entities) {
    return {
      ...state,
      deletedReport: action.response.entities.deleteReport,
      createdReport: null,
      updatedReport: null,
    };
  }
  if (action.type === 'GUEST_REPORT_SUCCESS' && action.response.entities) {
    return {
      ...state,
      guestReport: action.response.entities.guestReport,
    };
  }
  if (action.type === 'UPDATE_GUEST_REPORT_SUCCESS' && action.response.entities) {
    return {
      ...state,
      updateGuestReport: action.response.entities.updateReport,
    };
  }

  return state;
};

export default combineReducers({
  reports: reports(),
});
