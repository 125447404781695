import styled from 'styled-components';
import { List as list } from 'antd';

const Messenger = styled(list)`
    height: 100%; 
    overflow: scroll;  
    display: flex;
    flex-direction: column-reverse;   
    padding-bottom: 120px !important;
    .ant-list-item
    {
        width: 80%; 
        border-radius: 10px;
        background-color: #FFFFFF;
        float: left;
        margin-top: 30px;
        padding: 40px !important;
        margin-left: 40px;
        flex-flow: column; 
    }
    .ant-list-item.sent
    {
        float: right;
        margin-right: 40px;
        margin-left: 0px;
    }
    .ant-list-item div
    {
        width: 100%;
    }
    
    
`;

/** @component */
export default Messenger;
