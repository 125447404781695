import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
// location helper not needed now to pick the last path for login
// import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import { userIsAuth, getUserRole } from 'selectors/users';
import { routePermissions, defaultLoginPath } from '../constants/users';

// not needed now location last path for redirecting
// const locationHelper = locationHelperBuilder({});

export const isAuth = connectedRouterRedirect({
  // The url to redirect user to if they fail
  redirectPath: '/login',
  // If selector is true, wrapper will not redirect
  // For example let's check that state contains user data
  authenticatedSelector: userIsAuth,
  // A nice display name for this check
  wrapperDisplayName: 'UserIsAuthenticated',
});

export const isNotAuth = connectedRouterRedirect({
  // This sends the user either to the query param route if we have one, or to the landing page if none is specified and the user is already logged in
  // commented because we don't need the last path saved for login and need to redirect user to dashboard in every case.
  // redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(
  //   ownProps,
  // ) || '/',
  // new redirect
  redirectPath: '/',
  // This prevents us from adding the query parameter when we send the user away from the login page
  allowRedirectBack: false,
  // If selector is true, wrapper will not redirect
  // So if there is no user data, then we show the page
  authenticatedSelector: (state) => !userIsAuth(state),
  // A nice display name for this check
  wrapperDisplayName: 'UserIsNotAuthenticated',
});
export const isPermitted = connectedRouterRedirect({
  // This sends the user either to the query param route if we have one, or to the landing page if none is specified and the user is already logged in
  redirectPath: '/not-permitted',
  // This prevents us from adding the query parameter when we send the user away from the login page
  allowRedirectBack: false,
  // If selector is true, wrapper will not redirect
  // Check if user has permission for current route
  authenticatedSelector: (state, { match }) => {
    const pathName = match.path;
    const currentRoles = getUserRole(state);
    const permissions = routePermissions[currentRoles];
    const currentUri = window.location.pathname;
    if (currentUri === '/' && !permissions.some((paths) => paths === currentUri)) {
      return false;
    }
    return permissions.some((paths) => paths === pathName);
  },
  // A nice display name for this check
  wrapperDisplayName: 'routeIsNotPermitted',
});

export const dashboardRedirect = connectedRouterRedirect({
  // This sends the user either to the query param route if we have one, or to the landing page if none is specified and the user is already logged in
  // commenting because we need dynamic redirect now to dashboard
  // redirectPath: '/deals/draft',
  // new redirect takes decision based on user roles
  redirectPath: (state) => {
    const currentRoles = getUserRole(state);
    const userdefaultLoginPath = defaultLoginPath[currentRoles];
    return userdefaultLoginPath || '/deals/draft';
  },
  // This prevents us from adding the query parameter when we send the user away from the login page
  allowRedirectBack: false,
  // If selector is true, wrapper will not redirect
  // Check if user has permission for current route
  authenticatedSelector: (state, { match }) => {
    const pathName = match.path;
    const currentRoles = getUserRole(state);
    const permissions = routePermissions[currentRoles];
    const currentUri = window.location.pathname;
    if (currentUri === '/' && !permissions.some((paths) => paths === currentUri)) {
      return false;
    }
    return permissions.some((paths) => paths === pathName);
  },
  // A nice display name for this check
  wrapperDisplayName: 'routeIsNotPermitted',
});
