import {
  getDeal,
} from 'actions/guest-deal';

const guestDeal = () => {
  const initialState = {};

  return (state = initialState, { type, response }) => {
    switch (type) {
      case getDeal.requestTypes.SUCCESS:
        return {
          ...response,
        };
      default:
        return state;
    }
  };
};

export default guestDeal();
