import {
  listings,
  addListings,
  updateListing,
  brokerListings as getBrokerListings,
} from 'actions/listingLeads';

const listingLeads = () => {
  const initialState = {
    leads: null,
    updatedListingEntity: null,
    brokerListings: [],
  };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case listings.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case listings.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case listings.requestTypes.SUCCESS:
        return {
          ...state,
          leads: response,
          isSuccessful: true,
        };
      case addListings.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case addListings.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case addListings.requestTypes.SUCCESS:
        return {
          ...state,
          leads: response,
          isSuccessful: true,
        };
      case updateListing.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateListing.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateListing.requestTypes.SUCCESS:
        return {
          ...state,
          updatedListingEntity: response,
          isSuccessful: true,
        };
      case getBrokerListings.requestTypes.SUCCESS:
        return {
          ...state,
          brokerListings: response,
        };
      default:
        return state;
    }
  };
};

export default listingLeads();
