import styled from 'styled-components';
import { Divider as divider } from 'antd';
import propTypes from 'prop-types';

const Divider = styled(divider)`
  margin-top: ${(props) => `${props.top}px !important`};
  margin-bottom: ${(props) => `${props.bottom}px !important`};
  margin-right: ${(props) => `${props.left}px !important`};
  margin-left: ${(props) => `${props.right}px !important`};
`;

Divider.propTypes = {
  top: propTypes.number,
  bottom: propTypes.number,
  left: propTypes.number,
  right: propTypes.number,
};

Divider.defaultProps = {
  top: 40,
  bottom: 40,
  left: 0,
  right: 0,
};
/** @component */
export default Divider;
