import { Slider } from 'antd';
import styled from 'styled-components';

const sytledSlider = styled(Slider)`
  .ant-slider-rail {
    background-color: ${(props) => `${props.background} !important`};
  }


  ${(props) => (props.component === 'pitchSurvey')
    && `
    margin-left: 16px !important;

    .ant-slider-dot{
      display : none;
    }

    .ant-slider-track {
      position: absolute;
      height: 4px;
      background-image: linear-gradient(to right, #9c29c7, #ff6d0d);
      border-radius: 4px;
      -webkit-transition: background-color 0.3s;
      transition: background-color 0.3s;
    }

    .ant-slider-handle{
      border: solid 2px #7d3a96;
      box-shadow: none !important;
    }

    .ant-slider-step{
      background :rgb(173 179 214 / 28%)
    }

    .ant-slider-handle:hover{
      border: solid 2px #7d3a96;
      box-shadow: none !important;
    }
  `}
`;

export default sytledSlider;
