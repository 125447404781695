import React, { useState, useEffect, useCallback } from 'react';
import {
  Marker,
  GoogleMap,
  InfoWindow,
  useLoadScript,
  MarkerClusterer,
} from '@react-google-maps/api';
import Geocode from 'react-geocode';
import { Link } from 'react-router-dom';
import {
  Row, Col, Typography, Divider,
} from 'antd';
import * as DateFormatType from 'utils/moments/date-formats';
import { GOOGLE_MAPS_KEY, isProdEnv } from 'configs/env-vars';

Geocode.setApiKey(GOOGLE_MAPS_KEY);
const mapContainerStyle = {
  height: '500px',
  width: '100%',
};

const options = {
  ignoreHidden: true,
  averageCenter: true,
  minimumClusterSize: 10,
};

const { Text, Paragraph } = Typography;

const ClusterMarkerMap = (props) => {
  const [mapRef, setMapRef] = useState(null);
  const [markerMap, setMarkerMap] = useState({});
  const [locations, setLocations] = useState([]);
  const [infoOpen, setInfoOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAPS_KEY,
  });

  useEffect(() => {
    // if (isProdEnv) {
    // eslint-disable-next-line no-shadow
    const fetchPlacesData = async (places) => {
      const result = await getAddresses(places);
      setLocations(result);
    };

    fetchPlacesData(props.data);
    // }
    // eslint-disable-next-line
  }, [isProdEnv, props.data]);

  useEffect(() => {
    if (mapRef) {
      const bounds = new window.google.maps.LatLngBounds();
      locations.map((location) => {
        bounds.extend(location.coordinates);
        return location.id;
      });
      mapRef.fitBounds(bounds);
    }
  }, [mapRef, locations]);

  const mapLoadHandler = useCallback((map) => setMapRef(map), []);

  const markerLoadHandler = (marker, location) => (
    setMarkerMap((prevState) => ({ ...prevState, [location.id]: marker }))
  );

  const markerClickHandler = (event, place) => {
    setSelectedLocation(place);
    if (infoOpen) {
      setInfoOpen(false);
    }
    setInfoOpen(true);
  };

  const mapTypes = (id, type) => {
    const mapped = Object.keys(props[type]).map((key) => props[type][key]);
    const filtered = mapped.filter((item) => item.id === id);
    return filtered[0]?.name;
  };

  /**
   * Returns an array of JSONs containing the given location information
   * and the coordinates for each one of them.
   * @param {{Title: String, Name: String, Address: String}[]} places
   */
  // eslint-disable-next-line no-shadow
  const getAddresses = async (places) => {
    /**
     * Returns a promise that would return a JSON with the given location
     * information, plus its coordinates.
     * @param {{Title: String, Name: String, Address: String}} place
     */
    const GeoPromise = (place) => new Promise((resolve, reject) => {
      if (place && place.address) {
        const address = place.address.includes('(') ? place.address.split('(')[0] : place.address;
        const geoAddress = `${address} ${place.city ? place.city : ''} ${place.state ? place.state.name : ''}`;
        Geocode.fromAddress(geoAddress.trim()).then(
          (response) => {
            const { lat, lng } = response.results[0].geometry.location;
            resolve({ ...place, coordinates: { lat, lng } });
          },
          (error) => {
            resolve(null);
          },
        );
      }
    });

    const result = await Promise.all(places
      .map((place) => ((!place.coordinates || Object.keys(place.coordinates).length === 0) ? GeoPromise(place) : place)))
      .then((res) => res.filter((place) => place));

    return result;
  };

  return (
    isLoaded
      ? <GoogleMap
        id='maps'
        onLoad={mapLoadHandler}
        mapContainerStyle={mapContainerStyle}
        zoom={3}
      >
        <MarkerClusterer options={options}>
          {(clusterer) => locations?.map((location) => (
            <Marker
              key={location.id}
              icon={{
                url: 'https://upload.wikimedia.org/wikipedia/commons/0/02/Red_Circle%28small%29.svg',
                scaledSize: { width: 8, height: 8 },
              }}
              clusterer={clusterer}
              position={location.coordinates}
              onLoad={(marker) => markerLoadHandler(marker, location)}
              onClick={(event) => markerClickHandler(event, location)}
            />
          ))
          }
        </MarkerClusterer>
        {infoOpen && selectedLocation && (
          <InfoWindow
            anchor={markerMap[selectedLocation.id]}
            onCloseClick={() => setInfoOpen(false)}
          >
            <Row>
              <Col span={24}>
                {selectedLocation.propertyName
                  ? <>
                    <Paragraph>
                      <Text strong>{selectedLocation.propertyName}</Text>
                    </Paragraph>
                    <Paragraph>
                      {`${selectedLocation.address}, ${selectedLocation.city}`}
                    </Paragraph>
                    <Divider />
                  </>
                  : <>
                    <Paragraph>
                      <Text strong>{`${selectedLocation.address}, ${selectedLocation.city}`}</Text>
                    </Paragraph>
                    <Divider />
                  </>
                }
                {selectedLocation.borrower
                  ? <Paragraph>
                    <Text strong>Borrower:</Text>{' '}
                    <Link
                      to={{
                        pathname: '/crm-dashboard',
                        state: {
                          // companySearched
                          borrowerClickedOnMap: selectedLocation.borrower,
                        },
                      }}
                    >
                      <Text style={{ color: 'blue' }}>{selectedLocation.borrower}</Text>
                    </Link>
                  </Paragraph>
                  : null
                }
                { // eslint-disable-next-line no-nested-ternary
                  selectedLocation.loanAmount
                    ? <Paragraph>
                      <Text strong>Loan Amount:</Text>{' '}
                      <Text>{`$${Number(selectedLocation.loanAmount).toLocaleString()}`}</Text>
                    </Paragraph>
                    : selectedLocation.salePrice
                      ? <Paragraph>
                        <Text strong>Loan Amount:</Text>{' '}
                        <Text>{selectedLocation.salePrice}</Text>
                      </Paragraph>
                      : null
                }
                {selectedLocation.buildingSF
                  ? <Paragraph>
                    <Text strong>Building Size:</Text>{' '}
                    <Text>
                      {selectedLocation.buildingSF.includes(',')
                        ? `${selectedLocation.buildingSF} SF`
                        : `${Number(selectedLocation.buildingSF).toLocaleString()} SF`
                      }
                    </Text>
                  </Paragraph>
                  : null
                }
                {selectedLocation.loanPerSF
                  ? <Paragraph>
                    <Text strong>Loan/SF:</Text>{' '}
                    <Text>{selectedLocation.loanPerSF}</Text>
                  </Paragraph>
                  : null
                }
                {selectedLocation.propertyType
                  ? <Paragraph>
                    <Text strong>Property Type:</Text>{' '}
                    <Text>{mapTypes(selectedLocation.propertyType, 'propertyTypes')}</Text>
                  </Paragraph>
                  : null
                }
                {selectedLocation.propertyClass
                  ? <Paragraph>
                    <Text strong>Property Class:</Text>{' '}
                    <Text>{mapTypes(selectedLocation.propertyClass, 'propertyClasses')}</Text>
                  </Paragraph>
                  : null
                }
                {selectedLocation.marketType
                  ? <Paragraph>
                    <Text strong>Market Type:</Text>{' '}
                    <Text>{mapTypes(selectedLocation.marketType, 'markets')}</Text>
                  </Paragraph>
                  : null
                }
                {selectedLocation.originationDate
                  ? <Paragraph>
                    <Text strong>Origination Date:</Text>{' '}
                    <Text>{DateFormatType.dateFormatHandler(selectedLocation.originationDate, DateFormatType.DD_MMM_YYYY)}</Text>
                  </Paragraph>
                  : null
                }
                {selectedLocation.maturityDate
                  ? <Paragraph>
                    <Text strong>Maturity Date:</Text>{' '}
                    <Text>{DateFormatType.dateFormatHandler(selectedLocation.maturityDate, DateFormatType.DD_MMM_YYYY)}</Text>
                  </Paragraph>
                  : null
                }
              </Col>
            </Row>
          </InfoWindow>
        )}
      </GoogleMap>
      : null
  );
};

export default ClusterMarkerMap;
