const provinces = {
  // CANADA STATES
  // ONTARIO
  ON: [
    { lng: -95.18, lat: 49.36 },
    { lng: -95.19, lat: 52.86 },
    { lng: -94.49, lat: 53.3 },
    { lng: -93.67, lat: 53.77 },
    { lng: -93.32, lat: 54.04 },
    { lng: -92.08, lat: 54.92 },
    { lng: -90.76, lat: 55.8 },
    { lng: -90.66, lat: 55.85 },
    { lng: -90.13, lat: 56.2 },
    { lng: -89.09, lat: 56.84 },
    { lng: -89, lat: 56.89 },
    { lng: -88.93, lat: 56.88 },
    { lng: -88.87, lat: 56.9 },
    { lng: -88.79, lat: 56.87 },
    { lng: -88.77, lat: 56.81 },
    { lng: -88.67, lat: 56.77 },
    { lng: -88.63, lat: 56.72 },
    { lng: -88.5, lat: 56.7 },
    { lng: -88.34, lat: 56.6 },
    { lng: -88.19, lat: 56.55 },
    { lng: -88.12, lat: 56.55 },
    { lng: -87.95, lat: 56.5 },
    { lng: -87.92, lat: 56.47 },
    { lng: -87.91, lat: 56.43 },
    { lng: -87.85, lat: 56.4 },
    { lng: -87.81, lat: 56.35 },
    { lng: -87.75, lat: 56.31 },
    { lng: -87.59, lat: 56.12 },
    { lng: -87.48, lat: 56.09 },
    { lng: -87.4, lat: 56.03 },
    { lng: -87.37, lat: 56.03 },
    { lng: -87.3, lat: 55.99 },
    { lng: -87.19, lat: 55.97 },
    { lng: -86.91, lat: 55.96 },
    { lng: -86.68, lat: 55.88 },
    { lng: -86.59, lat: 55.87 },
    { lng: -86.52, lat: 55.84 },
    { lng: -86.37, lat: 55.81 },
    { lng: -86.24, lat: 55.74 },
    { lng: -85.9, lat: 55.7 },
    { lng: -85.78, lat: 55.64 },
    { lng: -85.72, lat: 55.64 },
    { lng: -85.67, lat: 55.61 },
    { lng: -85.64, lat: 55.61 },
    { lng: -85.57, lat: 55.55 },
    { lng: -85.53, lat: 55.54 },
    { lng: -85.46, lat: 55.48 },
    { lng: -85.36, lat: 55.43 },
    { lng: -85.27, lat: 55.41 },
    { lng: -85.23, lat: 55.42 },
    { lng: -85.19, lat: 55.39 },
    { lng: -85.1, lat: 55.37 },
    { lng: -85.07, lat: 55.32 },
    { lng: -85.04, lat: 55.31 },
    { lng: -84.95, lat: 55.32 },
    { lng: -84.71, lat: 55.27 },
    { lng: -84.49, lat: 55.28 },
    { lng: -84.45, lat: 55.3 },
    { lng: -84.32, lat: 55.32 },
    { lng: -84.16, lat: 55.32 },
    { lng: -84.08, lat: 55.29 },
    { lng: -83.98, lat: 55.33 },
    { lng: -83.89, lat: 55.34 },
    { lng: -83.75, lat: 55.29 },
    { lng: -83.65, lat: 55.31 },
    { lng: -83.58, lat: 55.28 },
    { lng: -83.52, lat: 55.28 },
    { lng: -83.46, lat: 55.25 },
    { lng: -83.38, lat: 55.26 },
    { lng: -83.35, lat: 55.24 },
    { lng: -83.26, lat: 55.26 },
    { lng: -83.18, lat: 55.24 },
    { lng: -82.93, lat: 55.26 },
    { lng: -82.87, lat: 55.22 },
    { lng: -82.86, lat: 55.18 },
    { lng: -82.83, lat: 55.17 },
    { lng: -82.76, lat: 55.17 },
    { lng: -82.74, lat: 55.2 },
    { lng: -82.71, lat: 55.2 },
    { lng: -82.7, lat: 55.22 },
    { lng: -82.66, lat: 55.22 },
    { lng: -82.64, lat: 55.2 },
    { lng: -82.54, lat: 55.17 },
    { lng: -82.51, lat: 55.14 },
    { lng: -82.45, lat: 55.16 },
    { lng: -82.41, lat: 55.14 },
    { lng: -82.39, lat: 55.11 },
    { lng: -82.38, lat: 55.11 },
    { lng: -82.39, lat: 55.18 },
    { lng: -82.36, lat: 55.2 },
    { lng: -82.31, lat: 55.19 },
    { lng: -82.28, lat: 55.16 },
    { lng: -82.22, lat: 55.14 },
    { lng: -82.2, lat: 55.08 },
    { lng: -82.21, lat: 55.03 },
    { lng: -82.23, lat: 55.02 },
    { lng: -82.21, lat: 54.96 },
    { lng: -82.22, lat: 54.92 },
    { lng: -82.18, lat: 54.91 },
    { lng: -82.17, lat: 54.89 },
    { lng: -82.16, lat: 54.78 },
    { lng: -82.24, lat: 54.67 },
    { lng: -82.24, lat: 54.62 },
    { lng: -82.27, lat: 54.57 },
    { lng: -82.28, lat: 54.48 },
    { lng: -82.32, lat: 54.44 },
    { lng: -82.4, lat: 54.27 },
    { lng: -82.37, lat: 54.19 },
    { lng: -82.2, lat: 54.06 },
    { lng: -82.17, lat: 53.98 },
    { lng: -82.09, lat: 53.85 },
    { lng: -82.09, lat: 53.75 },
    { lng: -82.16, lat: 53.66 },
    { lng: -82.17, lat: 53.54 },
    { lng: -82.13, lat: 53.49 },
    { lng: -82.07, lat: 53.26 },
    { lng: -82.09, lat: 53.23 },
    { lng: -82.18, lat: 53.19 },
    { lng: -82.23, lat: 53.13 },
    { lng: -82.25, lat: 53.06 },
    { lng: -82.23, lat: 52.97 },
    { lng: -82.19, lat: 52.94 },
    { lng: -82.09, lat: 52.92 },
    { lng: -82.08, lat: 52.9 },
    { lng: -82, lat: 52.86 },
    { lng: -81.98, lat: 52.83 },
    { lng: -81.92, lat: 52.8 },
    { lng: -81.9, lat: 52.73 },
    { lng: -81.87, lat: 52.7 },
    { lng: -81.75, lat: 52.65 },
    { lng: -81.62, lat: 52.52 },
    { lng: -81.51, lat: 52.47 },
    { lng: -81.5, lat: 52.42 },
    { lng: -81.52, lat: 52.37 },
    { lng: -81.5, lat: 52.37 },
    { lng: -81.44, lat: 52.31 },
    { lng: -81.41, lat: 52.2 },
    { lng: -81.37, lat: 52.15 },
    { lng: -81.26, lat: 52.11 },
    { lng: -81.18, lat: 52.1 },
    { lng: -81.16, lat: 52.08 },
    { lng: -81.06, lat: 52.1 },
    { lng: -81, lat: 52.05 },
    { lng: -80.97, lat: 52.05 },
    { lng: -80.89, lat: 51.95 },
    { lng: -80.77, lat: 51.93 },
    { lng: -80.72, lat: 51.88 },
    { lng: -80.66, lat: 51.88 },
    { lng: -80.65, lat: 51.83 },
    { lng: -80.63, lat: 51.83 },
    { lng: -80.6, lat: 51.78 },
    { lng: -80.53, lat: 51.73 },
    { lng: -80.52, lat: 51.7 },
    { lng: -80.53, lat: 51.67 },
    { lng: -80.49, lat: 51.62 },
    { lng: -80.47, lat: 51.55 },
    { lng: -80.41, lat: 51.52 },
    { lng: -80.39, lat: 51.47 },
    { lng: -80.4, lat: 51.41 },
    { lng: -80.36, lat: 51.37 },
    { lng: -80.18, lat: 51.33 },
    { lng: -80.1, lat: 51.33 },
    { lng: -80.03, lat: 51.29 },
    { lng: -79.95, lat: 51.27 },
    { lng: -79.92, lat: 51.23 },
    { lng: -79.87, lat: 51.2 },
    { lng: -79.82, lat: 51.2 },
    { lng: -79.74, lat: 51.26 },
    { lng: -79.78, lat: 51.31 },
    { lng: -79.77, lat: 51.4 },
    { lng: -79.7, lat: 51.44 },
    { lng: -79.63, lat: 51.45 },
    { lng: -79.61, lat: 51.49 },
    { lng: -79.58, lat: 51.51 },
    { lng: -79.5, lat: 51.5 },
    { lng: -79.48, lat: 51.48 },
    { lng: -79.47, lat: 47.52 },
    { lng: -79.5, lat: 47.49 },
    { lng: -79.52, lat: 47.49 },
    { lng: -79.52, lat: 47.46 },
    { lng: -79.55, lat: 47.43 },
    { lng: -79.39, lat: 47.28 },
    { lng: -79.4, lat: 47.21 },
    { lng: -79.39, lat: 47.17 },
    { lng: -79.41, lat: 47.12 },
    { lng: -79.31, lat: 47.03 },
    { lng: -79.29, lat: 46.96 },
    { lng: -79.22, lat: 46.91 },
    { lng: -79.2, lat: 46.87 },
    { lng: -79.16, lat: 46.87 },
    { lng: -79.13, lat: 46.85 },
    { lng: -79.06, lat: 46.71 },
    { lng: -78.98, lat: 46.66 },
    { lng: -78.95, lat: 46.59 },
    { lng: -78.96, lat: 46.57 },
    { lng: -78.94, lat: 46.55 },
    { lng: -78.92, lat: 46.55 },
    { lng: -78.87, lat: 46.49 },
    { lng: -78.7, lat: 46.42 },
    { lng: -78.68, lat: 46.36 },
    { lng: -78.58, lat: 46.36 },
    { lng: -78.5, lat: 46.33 },
    { lng: -78.49, lat: 46.34 },
    { lng: -78.36, lat: 46.33 },
    { lng: -78.33, lat: 46.3 },
    { lng: -78.29, lat: 46.3 },
    { lng: -78.26, lat: 46.32 },
    { lng: -78.13, lat: 46.32 },
    { lng: -78.05, lat: 46.28 },
    { lng: -77.98, lat: 46.29 },
    { lng: -77.92, lat: 46.26 },
    { lng: -77.82, lat: 46.24 },
    { lng: -77.66, lat: 46.24 },
    { lng: -77.64, lat: 46.22 },
    { lng: -77.55, lat: 46.2 },
    { lng: -77.37, lat: 46.1 },
    { lng: -77.34, lat: 46.1 },
    { lng: -77.3, lat: 46.06 },
    { lng: -77.27, lat: 46.06 },
    { lng: -77.24, lat: 46.04 },
    { lng: -77.24, lat: 45.97 },
    { lng: -77.2, lat: 45.95 },
    { lng: -77.2, lat: 45.93 },
    { lng: -77.17, lat: 45.9 },
    { lng: -77.05, lat: 45.87 },
    { lng: -77.04, lat: 45.85 },
    { lng: -76.98, lat: 45.83 },
    { lng: -76.96, lat: 45.87 },
    { lng: -76.97, lat: 45.91 },
    { lng: -76.93, lat: 45.94 },
    { lng: -76.87, lat: 45.94 },
    { lng: -76.76, lat: 45.91 },
    { lng: -76.72, lat: 45.86 },
    { lng: -76.73, lat: 45.76 },
    { lng: -76.68, lat: 45.76 },
    { lng: -76.66, lat: 45.74 },
    { lng: -76.63, lat: 45.59 },
    { lng: -76.6, lat: 45.57 },
    { lng: -76.49, lat: 45.56 },
    { lng: -76.37, lat: 45.5 },
    { lng: -76.27, lat: 45.51 },
    { lng: -76.26, lat: 45.54 },
    { lng: -76.22, lat: 45.56 },
    { lng: -76.08, lat: 45.56 },
    { lng: -76.03, lat: 45.53 },
    { lng: -75.96, lat: 45.52 },
    { lng: -75.87, lat: 45.43 },
    { lng: -75.81, lat: 45.41 },
    { lng: -75.78, lat: 45.45 },
    { lng: -75.73, lat: 45.46 },
    { lng: -75.7, lat: 45.5 },
    { lng: -75.58, lat: 45.51 },
    { lng: -75.48, lat: 45.56 },
    { lng: -75.36, lat: 45.57 },
    { lng: -75.25, lat: 45.63 },
    { lng: -75.18, lat: 45.62 },
    { lng: -75.04, lat: 45.63 },
    { lng: -74.94, lat: 45.69 },
    { lng: -74.7, lat: 45.67 },
    { lng: -74.63, lat: 45.68 },
    { lng: -74.53, lat: 45.63 },
    { lng: -74.48, lat: 45.64 },
    { lng: -74.41, lat: 45.61 },
    { lng: -74.37, lat: 45.61 },
    { lng: -74.34, lat: 45.58 },
    { lng: -74.42, lat: 45.31 },
    { lng: -74.33, lat: 45.25 },
    { lng: -74.28, lat: 45.2 },
    { lng: -74.28, lat: 45.17 },
    { lng: -74.35, lat: 45.12 },
    { lng: -74.42, lat: 45.1 },
    { lng: -74.47, lat: 45.03 },
    { lng: -74.5, lat: 45.03 },
    { lng: -74.52, lat: 45.01 },
    { lng: -74.59, lat: 45 },
    { lng: -74.66, lat: 44.96 },
    { lng: -74.72, lat: 44.95 },
    { lng: -74.85, lat: 44.97 },
    { lng: -74.9, lat: 44.94 },
    { lng: -74.97, lat: 44.94 },
    { lng: -75.12, lat: 44.86 },
    { lng: -75.26, lat: 44.82 },
    { lng: -75.28, lat: 44.79 },
    { lng: -75.46, lat: 44.69 },
    { lng: -75.75, lat: 44.48 },
    { lng: -75.78, lat: 44.42 },
    { lng: -75.89, lat: 44.33 },
    { lng: -75.96, lat: 44.3 },
    { lng: -76.03, lat: 44.3 },
    { lng: -76.08, lat: 44.26 },
    { lng: -76.11, lat: 44.26 },
    { lng: -76.13, lat: 44.22 },
    { lng: -76.18, lat: 44.18 },
    { lng: -76.29, lat: 44.16 },
    { lng: -76.33, lat: 44.1 },
    { lng: -76.41, lat: 44.06 },
    { lng: -76.78, lat: 43.59 },
    { lng: -78.68, lat: 43.59 },
    { lng: -79.14, lat: 43.43 },
    { lng: -79.02, lat: 43.27 },
    { lng: -79, lat: 43.16 },
    { lng: -79.01, lat: 43.11 },
    { lng: -78.96, lat: 43.08 },
    { lng: -78.96, lat: 43.04 },
    { lng: -78.98, lat: 43.01 },
    { lng: -78.89, lat: 42.98 },
    { lng: -78.87, lat: 42.95 },
    { lng: -78.87, lat: 42.88 },
    { lng: -78.91, lat: 42.79 },
    { lng: -80.07, lat: 42.35 },
    { lng: -81.23, lat: 42.17 },
    { lng: -82.19, lat: 41.74 },
    { lng: -82.37, lat: 41.64 },
    { lng: -82.7, lat: 41.64 },
    { lng: -83.08, lat: 41.82 },
    { lng: -83.11, lat: 41.85 },
    { lng: -83.19, lat: 42.02 },
    { lng: -83.19, lat: 42.06 },
    { lng: -83.16, lat: 42.12 },
    { lng: -83.17, lat: 42.25 },
    { lng: -83.11, lat: 42.34 },
    { lng: -82.85, lat: 42.41 },
    { lng: -82.68, lat: 42.58 },
    { lng: -82.64, lat: 42.6 },
    { lng: -82.61, lat: 42.59 },
    { lng: -82.57, lat: 42.63 },
    { lng: -82.55, lat: 42.63 },
    { lng: -82.55, lat: 42.68 },
    { lng: -82.51, lat: 42.76 },
    { lng: -82.51, lat: 42.91 },
    { lng: -82.46, lat: 42.98 },
    { lng: -82.46, lat: 43.02 },
    { lng: -82.16, lat: 43.6 },
    { lng: -82.55, lat: 45.31 },
    { lng: -83.63, lat: 45.79 },
    { lng: -83.64, lat: 45.81 },
    { lng: -83.63, lat: 45.85 },
    { lng: -83.49, lat: 45.99 },
    { lng: -83.59, lat: 46.07 },
    { lng: -83.66, lat: 46.08 },
    { lng: -83.77, lat: 46.06 },
    { lng: -83.82, lat: 46.08 },
    { lng: -83.89, lat: 46.02 },
    { lng: -83.98, lat: 46.02 },
    { lng: -84.04, lat: 46.09 },
    { lng: -84.05, lat: 46.13 },
    { lng: -84.1, lat: 46.15 },
    { lng: -84.15, lat: 46.23 },
    { lng: -84.19, lat: 46.41 },
    { lng: -84.16, lat: 46.49 },
    { lng: -84.2, lat: 46.5 },
    { lng: -84.22, lat: 46.47 },
    { lng: -84.26, lat: 46.45 },
    { lng: -84.35, lat: 46.47 },
    { lng: -84.42, lat: 46.46 },
    { lng: -84.46, lat: 46.41 },
    { lng: -84.57, lat: 46.42 },
    { lng: -84.8, lat: 46.61 },
    { lng: -84.89, lat: 46.86 },
    { lng: -87.13, lat: 47.78 },
    { lng: -88.35, lat: 48.26 },
    { lng: -88.67, lat: 48.21 },
    { lng: -89.33, lat: 47.93 },
    { lng: -89.46, lat: 47.97 },
    { lng: -89.65, lat: 47.96 },
    { lng: -89.74, lat: 47.98 },
    { lng: -89.79, lat: 47.98 },
    { lng: -89.84, lat: 47.95 },
    { lng: -89.92, lat: 47.95 },
    { lng: -90.01, lat: 47.99 },
    { lng: -90.06, lat: 48.06 },
    { lng: -90.13, lat: 48.07 },
    { lng: -90.39, lat: 48.05 },
    { lng: -90.46, lat: 48.07 },
    { lng: -90.59, lat: 48.06 },
    { lng: -90.6, lat: 48.08 },
    { lng: -90.64, lat: 48.06 },
    { lng: -90.77, lat: 48.05 },
    { lng: -90.87, lat: 48.15 },
    { lng: -90.87, lat: 48.2 },
    { lng: -91.08, lat: 48.14 },
    { lng: -91.2, lat: 48.06 },
    { lng: -91.29, lat: 48.03 },
    { lng: -91.36, lat: 48.03 },
    { lng: -91.4, lat: 48.01 },
    { lng: -91.46, lat: 48.01 },
    { lng: -91.48, lat: 48.03 },
    { lng: -91.58, lat: 48 },
    { lng: -91.62, lat: 48.05 },
    { lng: -91.61, lat: 48.06 },
    { lng: -91.71, lat: 48.07 },
    { lng: -91.75, lat: 48.09 },
    { lng: -91.76, lat: 48.16 },
    { lng: -91.88, lat: 48.17 },
    { lng: -91.91, lat: 48.2 },
    { lng: -91.97, lat: 48.19 },
    { lng: -92.04, lat: 48.24 },
    { lng: -92.05, lat: 48.29 },
    { lng: -92.07, lat: 48.31 },
    { lng: -92.25, lat: 48.31 },
    { lng: -92.26, lat: 48.29 },
    { lng: -92.23, lat: 48.27 },
    { lng: -92.24, lat: 48.22 },
    { lng: -92.38, lat: 48.18 },
    { lng: -92.51, lat: 48.33 },
    { lng: -92.52, lat: 48.41 },
    { lng: -92.69, lat: 48.4 },
    { lng: -92.75, lat: 48.44 },
    { lng: -92.74, lat: 48.5 },
    { lng: -92.97, lat: 48.58 },
    { lng: -93.09, lat: 48.59 },
    { lng: -93.18, lat: 48.58 },
    { lng: -93.21, lat: 48.6 },
    { lng: -93.25, lat: 48.6 },
    { lng: -93.39, lat: 48.56 },
    { lng: -93.41, lat: 48.57 },
    { lng: -93.44, lat: 48.51 },
    { lng: -93.51, lat: 48.49 },
    { lng: -93.81, lat: 48.48 },
    { lng: -93.84, lat: 48.49 },
    { lng: -93.86, lat: 48.52 },
    { lng: -93.86, lat: 48.59 },
    { lng: -94.25, lat: 48.61 },
    { lng: -94.29, lat: 48.64 },
    { lng: -94.29, lat: 48.67 },
    { lng: -94.41, lat: 48.67 },
    { lng: -94.46, lat: 48.65 },
    { lng: -94.61, lat: 48.68 },
    { lng: -94.63, lat: 48.7 },
    { lng: -94.66, lat: 48.7 },
    { lng: -94.73, lat: 48.76 },
    { lng: -94.73, lat: 48.89 },
    { lng: -94.78, lat: 49.08 },
    { lng: -94.81, lat: 49.1 },
    { lng: -94.84, lat: 49.19 },
    { lng: -94.86, lat: 49.29 },
    { lng: -94.96, lat: 49.33 },
    { lng: -95.07, lat: 49.31 },
    { lng: -95.15, lat: 49.33 },
    { lng: -95.18, lat: 49.36 },
  ],
  // BRITISH COLUMBIA
  BC: [
    [
      { lng: -139.06, lat: 59.96 },
      { lng: -139.09, lat: 59.97 },
      { lng: -139.08, lat: 60.03 },
      { lng: -137.94, lat: 60.04 },
      { lng: -119.99, lat: 60.04 },
      { lng: -119.96, lat: 60.02 },
      { lng: -119.96, lat: 53.83 },
      { lng: -119.88, lat: 53.82 },
      { lng: -119.85, lat: 53.8 },
      { lng: -119.85, lat: 53.75 },
      { lng: -119.77, lat: 53.74 },
      { lng: -119.74, lat: 53.7 },
      { lng: -119.7, lat: 53.68 },
      { lng: -119.7, lat: 53.65 },
      { lng: -119.68, lat: 53.64 },
      { lng: -119.68, lat: 53.59 },
      { lng: -119.7, lat: 53.57 },
      { lng: -119.74, lat: 53.55 },
      { lng: -119.82, lat: 53.56 },
      { lng: -119.79, lat: 53.53 },
      { lng: -119.76, lat: 53.53 },
      { lng: -119.71, lat: 53.43 },
      { lng: -119.68, lat: 53.43 },
      { lng: -119.66, lat: 53.41 },
      { lng: -119.57, lat: 53.42 },
      { lng: -119.45, lat: 53.39 },
      { lng: -119.42, lat: 53.41 },
      { lng: -119.37, lat: 53.4 },
      { lng: -119.32, lat: 53.36 },
      { lng: -119.29, lat: 53.29 },
      { lng: -119.22, lat: 53.23 },
      { lng: -119.19, lat: 53.22 },
      { lng: -119.13, lat: 53.23 },
      { lng: -119.11, lat: 53.2 },
      { lng: -119.1, lat: 53.21 },
      { lng: -119.05, lat: 53.19 },
      { lng: -119.07, lat: 53.23 },
      { lng: -119.03, lat: 53.27 },
      { lng: -118.94, lat: 53.28 },
      { lng: -118.9, lat: 53.25 },
      { lng: -118.85, lat: 53.24 },
      { lng: -118.84, lat: 53.22 },
      { lng: -118.81, lat: 53.22 },
      { lng: -118.8, lat: 53.2 },
      { lng: -118.77, lat: 53.2 },
      { lng: -118.75, lat: 53.16 },
      { lng: -118.72, lat: 53.16 },
      { lng: -118.69, lat: 53.13 },
      { lng: -118.7, lat: 53.09 },
      { lng: -118.69, lat: 53.08 },
      { lng: -118.63, lat: 53.07 },
      { lng: -118.6, lat: 53.02 },
      { lng: -118.6, lat: 52.98 },
      { lng: -118.57, lat: 52.94 },
      { lng: -118.56, lat: 52.95 },
      { lng: -118.46, lat: 52.94 },
      { lng: -118.41, lat: 52.89 },
      { lng: -118.37, lat: 52.89 },
      { lng: -118.35, lat: 52.87 },
      { lng: -118.34, lat: 52.84 },
      { lng: -118.37, lat: 52.79 },
      { lng: -118.3, lat: 52.77 },
      { lng: -118.29, lat: 52.73 },
      { lng: -118.26, lat: 52.71 },
      { lng: -118.25, lat: 52.65 },
      { lng: -118.29, lat: 52.61 },
      { lng: -118.26, lat: 52.61 },
      { lng: -118.23, lat: 52.58 },
      { lng: -118.24, lat: 52.54 },
      { lng: -118.23, lat: 52.53 },
      { lng: -118.17, lat: 52.52 },
      { lng: -118.15, lat: 52.48 },
      { lng: -118.17, lat: 52.44 },
      { lng: -118.14, lat: 52.45 },
      { lng: -118.1, lat: 52.44 },
      { lng: -118.09, lat: 52.49 },
      { lng: -118.05, lat: 52.5 },
      { lng: -118.01, lat: 52.54 },
      { lng: -117.98, lat: 52.54 },
      { lng: -117.93, lat: 52.49 },
      { lng: -117.87, lat: 52.46 },
      { lng: -117.74, lat: 52.45 },
      { lng: -117.69, lat: 52.42 },
      { lng: -117.66, lat: 52.36 },
      { lng: -117.68, lat: 52.32 },
      { lng: -117.7, lat: 52.32 },
      { lng: -117.73, lat: 52.28 },
      { lng: -117.76, lat: 52.28 },
      { lng: -117.77, lat: 52.25 },
      { lng: -117.75, lat: 52.24 },
      { lng: -117.65, lat: 52.24 },
      { lng: -117.59, lat: 52.2 },
      { lng: -117.58, lat: 52.17 },
      { lng: -117.53, lat: 52.2 },
      { lng: -117.49, lat: 52.2 },
      { lng: -117.47, lat: 52.18 },
      { lng: -117.37, lat: 52.18 },
      { lng: -117.35, lat: 52.22 },
      { lng: -117.32, lat: 52.23 },
      { lng: -117.29, lat: 52.22 },
      { lng: -117.26, lat: 52.1 },
      { lng: -117.19, lat: 52.06 },
      { lng: -117.17, lat: 52.01 },
      { lng: -117.12, lat: 52.01 },
      { lng: -116.98, lat: 51.92 },
      { lng: -116.97, lat: 51.9 },
      { lng: -116.98, lat: 51.88 },
      { lng: -116.94, lat: 51.85 },
      { lng: -116.92, lat: 51.77 },
      { lng: -116.89, lat: 51.74 },
      { lng: -116.86, lat: 51.75 },
      { lng: -116.75, lat: 51.85 },
      { lng: -116.63, lat: 51.84 },
      { lng: -116.6, lat: 51.77 },
      { lng: -116.55, lat: 51.74 },
      { lng: -116.54, lat: 51.68 },
      { lng: -116.46, lat: 51.65 },
      { lng: -116.42, lat: 51.59 },
      { lng: -116.38, lat: 51.59 },
      { lng: -116.34, lat: 51.54 },
      { lng: -116.35, lat: 51.51 },
      { lng: -116.31, lat: 51.51 },
      { lng: -116.25, lat: 51.48 },
      { lng: -116.24, lat: 51.34 },
      { lng: -116.16, lat: 51.34 },
      { lng: -116.1, lat: 51.29 },
      { lng: -116.05, lat: 51.29 },
      { lng: -116.03, lat: 51.26 },
      { lng: -115.99, lat: 51.26 },
      { lng: -115.96, lat: 51.22 },
      { lng: -115.97, lat: 51.16 },
      { lng: -115.93, lat: 51.15 },
      { lng: -115.92, lat: 51.13 },
      { lng: -115.8, lat: 51.13 },
      { lng: -115.78, lat: 51.11 },
      { lng: -115.73, lat: 51.11 },
      { lng: -115.71, lat: 51.07 },
      { lng: -115.68, lat: 51.07 },
      { lng: -115.66, lat: 51.04 },
      { lng: -115.62, lat: 51.03 },
      { lng: -115.54, lat: 50.94 },
      { lng: -115.52, lat: 50.91 },
      { lng: -115.52, lat: 50.88 },
      { lng: -115.54, lat: 50.86 },
      { lng: -115.52, lat: 50.83 },
      { lng: -115.48, lat: 50.82 },
      { lng: -115.45, lat: 50.79 },
      { lng: -115.43, lat: 50.8 },
      { lng: -115.39, lat: 50.76 },
      { lng: -115.33, lat: 50.77 },
      { lng: -115.29, lat: 50.76 },
      { lng: -115.24, lat: 50.67 },
      { lng: -115.24, lat: 50.63 },
      { lng: -115.21, lat: 50.62 },
      { lng: -115.2, lat: 50.6 },
      { lng: -115.08, lat: 50.63 },
      { lng: -114.99, lat: 50.62 },
      { lng: -114.98, lat: 50.59 },
      { lng: -114.88, lat: 50.48 },
      { lng: -114.86, lat: 50.48 },
      { lng: -114.78, lat: 50.4 },
      { lng: -114.74, lat: 50.39 },
      { lng: -114.71, lat: 50.3 },
      { lng: -114.72, lat: 50.25 },
      { lng: -114.69, lat: 50.21 },
      { lng: -114.69, lat: 50.13 },
      { lng: -114.62, lat: 50.07 },
      { lng: -114.63, lat: 50.05 },
      { lng: -114.61, lat: 49.96 },
      { lng: -114.62, lat: 49.94 },
      { lng: -114.65, lat: 49.93 },
      { lng: -114.65, lat: 49.91 },
      { lng: -114.6, lat: 49.85 },
      { lng: -114.59, lat: 49.72 },
      { lng: -114.62, lat: 49.68 },
      { lng: -114.63, lat: 49.61 },
      { lng: -114.68, lat: 49.6 },
      { lng: -114.63, lat: 49.59 },
      { lng: -114.58, lat: 49.61 },
      { lng: -114.54, lat: 49.59 },
      { lng: -114.53, lat: 49.52 },
      { lng: -114.56, lat: 49.43 },
      { lng: -114.47, lat: 49.39 },
      { lng: -114.41, lat: 49.3 },
      { lng: -114.35, lat: 49.28 },
      { lng: -114.34, lat: 49.24 },
      { lng: -114.3, lat: 49.24 },
      { lng: -114.27, lat: 49.22 },
      { lng: -114.21, lat: 49.23 },
      { lng: -114.18, lat: 49.2 },
      { lng: -114.15, lat: 49.2 },
      { lng: -114.11, lat: 49.16 },
      { lng: -114.11, lat: 49.11 },
      { lng: -114.05, lat: 49.09 },
      { lng: -114.02, lat: 49.06 },
      { lng: -114.01, lat: 49.02 },
      { lng: -114.05, lat: 48.96 },
      { lng: -123.17, lat: 48.96 },
      { lng: -122.97, lat: 48.85 },
      { lng: -122.97, lat: 48.75 },
      { lng: -123.01, lat: 48.72 },
      { lng: -123.22, lat: 48.67 },
      { lng: -123.16, lat: 48.52 },
      { lng: -123.13, lat: 48.48 },
      { lng: -123.09, lat: 48.46 },
      { lng: -123.08, lat: 48.4 },
      { lng: -123.24, lat: 48.24 },
      { lng: -123.55, lat: 48.18 },
      { lng: -124.02, lat: 48.26 },
      { lng: -124.74, lat: 48.45 },
      { lng: -124.88, lat: 48.59 },
      { lng: -125.18, lat: 48.66 },
      { lng: -125.22, lat: 48.7 },
      { lng: -125.42, lat: 48.76 },
      { lng: -125.66, lat: 48.88 },
      { lng: -125.7, lat: 48.92 },
      { lng: -125.74, lat: 48.93 },
      { lng: -125.78, lat: 48.98 },
      { lng: -125.86, lat: 48.98 },
      { lng: -125.95, lat: 49.02 },
      { lng: -126.01, lat: 49.07 },
      { lng: -126.07, lat: 49.07 },
      { lng: -126.16, lat: 49.1 },
      { lng: -126.2, lat: 49.15 },
      { lng: -126.2, lat: 49.18 },
      { lng: -126.23, lat: 49.18 },
      { lng: -126.31, lat: 49.22 },
      { lng: -126.33, lat: 49.27 },
      { lng: -126.39, lat: 49.31 },
      { lng: -126.46, lat: 49.29 },
      { lng: -126.5, lat: 49.3 },
      { lng: -126.57, lat: 49.29 },
      { lng: -126.62, lat: 49.31 },
      { lng: -126.67, lat: 49.39 },
      { lng: -126.67, lat: 49.49 },
      { lng: -126.87, lat: 49.54 },
      { lng: -126.93, lat: 49.6 },
      { lng: -126.97, lat: 49.61 },
      { lng: -127.02, lat: 49.65 },
      { lng: -127.09, lat: 49.73 },
      { lng: -127.31, lat: 49.84 },
      { lng: -127.33, lat: 49.87 },
      { lng: -127.39, lat: 49.88 },
      { lng: -127.43, lat: 49.91 },
      { lng: -127.5, lat: 49.91 },
      { lng: -127.62, lat: 49.95 },
      { lng: -127.68, lat: 50.02 },
      { lng: -127.72, lat: 50.02 },
      { lng: -127.75, lat: 50 },
      { lng: -127.85, lat: 50 },
      { lng: -128.01, lat: 50.06 },
      { lng: -128.03, lat: 50.1 },
      { lng: -128.03, lat: 50.15 },
      { lng: -127.98, lat: 50.21 },
      { lng: -127.99, lat: 50.24 },
      { lng: -128.06, lat: 50.28 },
      { lng: -128.09, lat: 50.37 },
      { lng: -128.18, lat: 50.4 },
      { lng: -128.2, lat: 50.43 },
      { lng: -128.26, lat: 50.45 },
      { lng: -128.34, lat: 50.53 },
      { lng: -128.39, lat: 50.55 },
      { lng: -128.47, lat: 50.64 },
      { lng: -128.51, lat: 50.72 },
      { lng: -128.57, lat: 50.7 },
      { lng: -128.66, lat: 50.7 },
      { lng: -128.69, lat: 50.72 },
      { lng: -128.73, lat: 50.7 },
      { lng: -128.81, lat: 50.7 },
      { lng: -129.16, lat: 50.8 },
      { lng: -129.18, lat: 50.82 },
      { lng: -129.19, lat: 50.88 },
      { lng: -129.16, lat: 50.92 },
      { lng: -129.12, lat: 50.94 },
      { lng: -129.06, lat: 50.94 },
      { lng: -128.89, lat: 50.9 },
      { lng: -128.75, lat: 50.92 },
      { lng: -128.65, lat: 50.91 },
      { lng: -128.49, lat: 50.87 },
      { lng: -128.27, lat: 50.89 },
      { lng: -127.99, lat: 51.01 },
      { lng: -127.88, lat: 51.01 },
      { lng: -127.86, lat: 51.04 },
      { lng: -127.92, lat: 51.19 },
      { lng: -127.95, lat: 51.32 },
      { lng: -127.98, lat: 51.35 },
      { lng: -128.19, lat: 51.42 },
      { lng: -128.23, lat: 51.5 },
      { lng: -128.28, lat: 51.69 },
      { lng: -128.38, lat: 51.76 },
      { lng: -128.55, lat: 51.81 },
      { lng: -128.62, lat: 51.88 },
      { lng: -128.61, lat: 51.99 },
      { lng: -128.56, lat: 52.06 },
      { lng: -128.66, lat: 52.16 },
      { lng: -128.78, lat: 52.18 },
      { lng: -128.86, lat: 52.25 },
      { lng: -128.86, lat: 52.29 },
      { lng: -128.99, lat: 52.32 },
      { lng: -129.17, lat: 52.41 },
      { lng: -129.2, lat: 52.44 },
      { lng: -129.46, lat: 52.42 },
      { lng: -129.54, lat: 52.48 },
      { lng: -129.57, lat: 52.7 },
      { lng: -129.5, lat: 52.87 },
      { lng: -129.71, lat: 52.86 },
      { lng: -129.81, lat: 52.92 },
      { lng: -129.83, lat: 53 },
      { lng: -129.89, lat: 53.06 },
      { lng: -130.09, lat: 53.12 },
      { lng: -130.17, lat: 53.18 },
      { lng: -130.2, lat: 53.23 },
      { lng: -130.24, lat: 53.26 },
      { lng: -130.39, lat: 53.31 },
      { lng: -130.42, lat: 53.34 },
      { lng: -130.5, lat: 53.36 },
      { lng: -130.61, lat: 53.36 },
      { lng: -130.73, lat: 53.41 },
      { lng: -130.76, lat: 53.45 },
      { lng: -130.76, lat: 53.51 },
      { lng: -130.7, lat: 53.58 },
      { lng: -130.71, lat: 53.6 },
      { lng: -130.7, lat: 53.63 },
      { lng: -130.64, lat: 53.69 },
      { lng: -130.61, lat: 53.7 },
      { lng: -130.77, lat: 53.74 },
      { lng: -130.85, lat: 53.83 },
      { lng: -130.87, lat: 54.02 },
      { lng: -130.96, lat: 54.07 },
      { lng: -130.97, lat: 54.09 },
      { lng: -130.99, lat: 54.17 },
      { lng: -130.97, lat: 54.25 },
      { lng: -131.04, lat: 54.34 },
      { lng: -131.15, lat: 54.41 },
      { lng: -131.27, lat: 54.54 },
      { lng: -131.27, lat: 54.6 },
      { lng: -131.22, lat: 54.66 },
      { lng: -131.1, lat: 54.68 },
      { lng: -130.66, lat: 54.7 },
      { lng: -130.7, lat: 54.75 },
      { lng: -130.7, lat: 54.78 },
      { lng: -130.68, lat: 54.8 },
      { lng: -130.5, lat: 54.87 },
      { lng: -130.3, lat: 55 },
      { lng: -130.22, lat: 55.08 },
      { lng: -130.22, lat: 55.11 },
      { lng: -130.13, lat: 55.23 },
      { lng: -130.03, lat: 55.29 },
      { lng: -130.06, lat: 55.32 },
      { lng: -130.08, lat: 55.43 },
      { lng: -130.13, lat: 55.48 },
      { lng: -130.17, lat: 55.57 },
      { lng: -130.15, lat: 55.67 },
      { lng: -130.19, lat: 55.7 },
      { lng: -130.19, lat: 55.79 },
      { lng: -130.06, lat: 55.91 },
      { lng: -130.05, lat: 55.94 },
      { lng: -130.05, lat: 56 },
      { lng: -130.12, lat: 56.08 },
      { lng: -130.26, lat: 56.06 },
      { lng: -130.44, lat: 56.1 },
      { lng: -130.49, lat: 56.2 },
      { lng: -130.64, lat: 56.23 },
      { lng: -130.8, lat: 56.33 },
      { lng: -131.11, lat: 56.37 },
      { lng: -131.32, lat: 56.46 },
      { lng: -131.49, lat: 56.51 },
      { lng: -131.59, lat: 56.57 },
      { lng: -131.86, lat: 56.56 },
      { lng: -131.89, lat: 56.66 },
      { lng: -131.94, lat: 56.74 },
      { lng: -131.93, lat: 56.78 },
      { lng: -132.16, lat: 56.85 },
      { lng: -132.16, lat: 56.89 },
      { lng: -132.1, lat: 57.01 },
      { lng: -132.4, lat: 57.06 },
      { lng: -132.41, lat: 57.11 },
      { lng: -132.3, lat: 57.21 },
      { lng: -132.4, lat: 57.32 },
      { lng: -132.58, lat: 57.47 },
      { lng: -132.9, lat: 57.81 },
      { lng: -133.1, lat: 57.97 },
      { lng: -133.2, lat: 58.13 },
      { lng: -133.37, lat: 58.24 },
      { lng: -133.5, lat: 58.37 },
      { lng: -133.5, lat: 58.41 },
      { lng: -133.47, lat: 58.43 },
      { lng: -133.67, lat: 58.54 },
      { lng: -133.86, lat: 58.69 },
      { lng: -134.27, lat: 58.82 },
      { lng: -134.36, lat: 58.89 },
      { lng: -134.38, lat: 58.93 },
      { lng: -134.44, lat: 58.95 },
      { lng: -134.45, lat: 58.99 },
      { lng: -134.43, lat: 59.03 },
      { lng: -134.5, lat: 59.09 },
      { lng: -134.58, lat: 59.09 },
      { lng: -134.7, lat: 59.16 },
      { lng: -134.73, lat: 59.21 },
      { lng: -134.99, lat: 59.25 },
      { lng: -135.07, lat: 59.33 },
      { lng: -135.06, lat: 59.37 },
      { lng: -135.14, lat: 59.41 },
      { lng: -135.13, lat: 59.46 },
      { lng: -135.07, lat: 59.5 },
      { lng: -135.07, lat: 59.54 },
      { lng: -135.25, lat: 59.63 },
      { lng: -135.26, lat: 59.66 },
      { lng: -135.38, lat: 59.7 },
      { lng: -135.48, lat: 59.76 },
      { lng: -135.94, lat: 59.62 },
      { lng: -136.22, lat: 59.59 },
      { lng: -136.2, lat: 59.58 },
      { lng: -136.2, lat: 59.51 },
      { lng: -136.28, lat: 59.43 },
      { lng: -136.35, lat: 59.41 },
      { lng: -136.44, lat: 59.42 },
      { lng: -136.43, lat: 59.27 },
      { lng: -136.56, lat: 59.13 },
      { lng: -136.82, lat: 59.12 },
      { lng: -137.07, lat: 59.02 },
      { lng: -137.27, lat: 58.96 },
      { lng: -137.43, lat: 58.87 },
      { lng: -137.55, lat: 58.87 },
      { lng: -137.57, lat: 58.91 },
      { lng: -137.54, lat: 58.99 },
      { lng: -137.64, lat: 59.21 },
      { lng: -138.04, lat: 59.43 },
      { lng: -138.64, lat: 59.73 },
      { lng: -138.7, lat: 59.78 },
      { lng: -138.74, lat: 59.87 },
      { lng: -139.06, lat: 59.96 },
    ],

    [
      { lng: -133.26, lat: 53.89 },
      { lng: -133.27, lat: 53.91 },
      { lng: -133.26, lat: 53.95 },
      { lng: -133.24, lat: 53.96 },
      { lng: -133.23, lat: 54.03 },
      { lng: -133.2, lat: 54.06 },
      { lng: -133.22, lat: 54.13 },
      { lng: -133.2, lat: 54.16 },
      { lng: -133.19, lat: 54.25 },
      { lng: -133.17, lat: 54.29 },
      { lng: -133.11, lat: 54.33 },
      { lng: -132.94, lat: 54.33 },
      { lng: -132.86, lat: 54.29 },
      { lng: -132.83, lat: 54.21 },
      { lng: -132.64, lat: 54.23 },
      { lng: -132.58, lat: 54.22 },
      { lng: -132.49, lat: 54.17 },
      { lng: -132.36, lat: 54.2 },
      { lng: -132.25, lat: 54.19 },
      { lng: -132.14, lat: 54.14 },
      { lng: -132.02, lat: 54.11 },
      { lng: -131.93, lat: 54.12 },
      { lng: -131.76, lat: 54.19 },
      { lng: -131.74, lat: 54.23 },
      { lng: -131.69, lat: 54.27 },
      { lng: -131.58, lat: 54.27 },
      { lng: -131.54, lat: 54.23 },
      { lng: -131.54, lat: 54.16 },
      { lng: -131.58, lat: 53.97 },
      { lng: -131.63, lat: 53.89 },
      { lng: -131.75, lat: 53.76 },
      { lng: -131.75, lat: 53.68 },
      { lng: -131.81, lat: 53.59 },
      { lng: -131.83, lat: 53.52 },
      { lng: -131.69, lat: 53.27 },
      { lng: -131.5, lat: 53.11 },
      { lng: -131.48, lat: 53 },
      { lng: -131.37, lat: 52.9 },
      { lng: -131.35, lat: 52.77 },
      { lng: -131.24, lat: 52.61 },
      { lng: -131.14, lat: 52.5 },
      { lng: -131.13, lat: 52.43 },
      { lng: -130.89, lat: 52.26 },
      { lng: -130.82, lat: 52.13 },
      { lng: -130.82, lat: 52.05 },
      { lng: -130.89, lat: 51.94 },
      { lng: -130.88, lat: 51.92 },
      { lng: -130.89, lat: 51.88 },
      { lng: -130.93, lat: 51.84 },
      { lng: -131, lat: 51.83 },
      { lng: -131.15, lat: 51.9 },
      { lng: -131.21, lat: 51.96 },
      { lng: -131.22, lat: 52 },
      { lng: -131.3, lat: 52.02 },
      { lng: -131.32, lat: 52.05 },
      { lng: -131.39, lat: 52.08 },
      { lng: -131.41, lat: 52.11 },
      { lng: -131.5, lat: 52.15 },
      { lng: -131.6, lat: 52.25 },
      { lng: -131.64, lat: 52.26 },
      { lng: -131.69, lat: 52.32 },
      { lng: -131.72, lat: 52.39 },
      { lng: -131.87, lat: 52.45 },
      { lng: -131.92, lat: 52.5 },
      { lng: -131.97, lat: 52.51 },
      { lng: -132.02, lat: 52.57 },
      { lng: -132.11, lat: 52.61 },
      { lng: -132.25, lat: 52.72 },
      { lng: -132.28, lat: 52.72 },
      { lng: -132.33, lat: 52.75 },
      { lng: -132.37, lat: 52.82 },
      { lng: -132.39, lat: 52.82 },
      { lng: -132.47, lat: 52.89 },
      { lng: -132.47, lat: 52.95 },
      { lng: -132.55, lat: 52.95 },
      { lng: -132.61, lat: 52.98 },
      { lng: -132.64, lat: 53.02 },
      { lng: -132.64, lat: 53.06 },
      { lng: -132.67, lat: 53.06 },
      { lng: -132.71, lat: 53.11 },
      { lng: -132.7, lat: 53.17 },
      { lng: -132.78, lat: 53.18 },
      { lng: -132.82, lat: 53.22 },
      { lng: -132.84, lat: 53.26 },
      { lng: -132.84, lat: 53.39 },
      { lng: -132.9, lat: 53.38 },
      { lng: -132.97, lat: 53.4 },
      { lng: -133.1, lat: 53.53 },
      { lng: -133.12, lat: 53.58 },
      { lng: -133.11, lat: 53.64 },
      { lng: -133.14, lat: 53.67 },
      { lng: -133.14, lat: 53.69 },
      { lng: -133.22, lat: 53.74 },
      { lng: -133.24, lat: 53.78 },
      { lng: -133.23, lat: 53.82 },
      { lng: -133.26, lat: 53.86 },
      { lng: -133.26, lat: 53.89 },
    ],
  ],
  // SASKATCHEWAN
  SK: [
    { lng: -110.05, lat: 60 },
    { lng: -110.02, lat: 60.04 },
    { lng: -101.99, lat: 60.04 },
    { lng: -101.97, lat: 60.02 },
    { lng: -101.96, lat: 55.85 },
    { lng: -101.92, lat: 55.82 },
    { lng: -101.92, lat: 55.5 },
    { lng: -101.88, lat: 55.47 },
    { lng: -101.88, lat: 55.15 },
    { lng: -101.85, lat: 55.13 },
    { lng: -101.85, lat: 54.8 },
    { lng: -101.81, lat: 54.78 },
    { lng: -101.81, lat: 54.45 },
    { lng: -101.77, lat: 54.43 },
    { lng: -101.77, lat: 54.1 },
    { lng: -101.73, lat: 54.08 },
    { lng: -101.73, lat: 53.75 },
    { lng: -101.7, lat: 53.73 },
    { lng: -101.7, lat: 53.41 },
    { lng: -101.66, lat: 53.38 },
    { lng: -101.66, lat: 53.05 },
    { lng: -101.63, lat: 53.03 },
    { lng: -101.63, lat: 52.71 },
    { lng: -101.6, lat: 52.69 },
    { lng: -101.6, lat: 52.36 },
    { lng: -101.57, lat: 52.33 },
    { lng: -101.57, lat: 52.01 },
    { lng: -101.54, lat: 51.99 },
    { lng: -101.53, lat: 51.34 },
    { lng: -101.5, lat: 51.32 },
    { lng: -101.5, lat: 50.99 },
    { lng: -101.47, lat: 50.96 },
    { lng: -101.47, lat: 50.63 },
    { lng: -101.44, lat: 50.62 },
    { lng: -101.44, lat: 50.28 },
    { lng: -101.41, lat: 50.26 },
    { lng: -101.41, lat: 49.93 },
    { lng: -101.38, lat: 49.91 },
    { lng: -101.38, lat: 49.57 },
    { lng: -101.35, lat: 49.54 },
    { lng: -101.35, lat: 49.21 },
    { lng: -101.33, lat: 49.21 },
    { lng: -101.32, lat: 49.19 },
    { lng: -101.33, lat: 48.97 },
    { lng: -110.02, lat: 48.96 },
    { lng: -110.04, lat: 48.98 },
    { lng: -110.05, lat: 60 },
  ],
  // ALBERTA
  AB: [
    { lng: -120.04, lat: 54.74 },
    { lng: -120.04, lat: 60.02 },
    { lng: -120.02, lat: 60.04 },
    { lng: -109.99, lat: 60.04 },
    { lng: -109.97, lat: 60.02 },
    { lng: -109.97, lat: 48.98 },
    { lng: -109.99, lat: 48.96 },
    { lng: -114.09, lat: 48.96 },
    { lng: -114.11, lat: 48.98 },
    { lng: -114.11, lat: 49.02 },
    { lng: -114.19, lat: 49.08 },
    { lng: -114.19, lat: 49.11 },
    { lng: -114.22, lat: 49.14 },
    { lng: -114.3, lat: 49.14 },
    { lng: -114.33, lat: 49.16 },
    { lng: -114.35, lat: 49.15 },
    { lng: -114.42, lat: 49.17 },
    { lng: -114.44, lat: 49.19 },
    { lng: -114.44, lat: 49.22 },
    { lng: -114.52, lat: 49.28 },
    { lng: -114.53, lat: 49.32 },
    { lng: -114.61, lat: 49.35 },
    { lng: -114.65, lat: 49.44 },
    { lng: -114.64, lat: 49.5 },
    { lng: -114.71, lat: 49.51 },
    { lng: -114.77, lat: 49.55 },
    { lng: -114.79, lat: 49.63 },
    { lng: -114.71, lat: 49.68 },
    { lng: -114.72, lat: 49.7 },
    { lng: -114.69, lat: 49.74 },
    { lng: -114.7, lat: 49.78 },
    { lng: -114.68, lat: 49.8 },
    { lng: -114.71, lat: 49.86 },
    { lng: -114.73, lat: 49.87 },
    { lng: -114.73, lat: 49.97 },
    { lng: -114.7, lat: 50.02 },
    { lng: -114.77, lat: 50.09 },
    { lng: -114.78, lat: 50.12 },
    { lng: -114.77, lat: 50.18 },
    { lng: -114.81, lat: 50.23 },
    { lng: -114.81, lat: 50.28 },
    { lng: -114.84, lat: 50.31 },
    { lng: -114.84, lat: 50.34 },
    { lng: -114.89, lat: 50.36 },
    { lng: -114.9, lat: 50.39 },
    { lng: -114.99, lat: 50.47 },
    { lng: -115.03, lat: 50.53 },
    { lng: -115.15, lat: 50.53 },
    { lng: -115.18, lat: 50.49 },
    { lng: -115.23, lat: 50.49 },
    { lng: -115.27, lat: 50.52 },
    { lng: -115.27, lat: 50.55 },
    { lng: -115.35, lat: 50.61 },
    { lng: -115.34, lat: 50.68 },
    { lng: -115.45, lat: 50.68 },
    { lng: -115.46, lat: 50.71 },
    { lng: -115.59, lat: 50.77 },
    { lng: -115.6, lat: 50.8 },
    { lng: -115.62, lat: 50.79 },
    { lng: -115.66, lat: 50.8 },
    { lng: -115.69, lat: 50.85 },
    { lng: -115.69, lat: 50.89 },
    { lng: -115.65, lat: 50.91 },
    { lng: -115.65, lat: 50.94 },
    { lng: -115.67, lat: 50.96 },
    { lng: -115.79, lat: 51 },
    { lng: -115.83, lat: 51.04 },
    { lng: -115.88, lat: 51.05 },
    { lng: -115.93, lat: 51.04 },
    { lng: -115.98, lat: 51.08 },
    { lng: -116.03, lat: 51.09 },
    { lng: -116.08, lat: 51.16 },
    { lng: -116.08, lat: 51.21 },
    { lng: -116.17, lat: 51.22 },
    { lng: -116.2, lat: 51.26 },
    { lng: -116.23, lat: 51.25 },
    { lng: -116.3, lat: 51.28 },
    { lng: -116.35, lat: 51.37 },
    { lng: -116.35, lat: 51.4 },
    { lng: -116.33, lat: 51.42 },
    { lng: -116.38, lat: 51.43 },
    { lng: -116.43, lat: 51.48 },
    { lng: -116.44, lat: 51.52 },
    { lng: -116.47, lat: 51.52 },
    { lng: -116.51, lat: 51.55 },
    { lng: -116.52, lat: 51.59 },
    { lng: -116.63, lat: 51.63 },
    { lng: -116.64, lat: 51.65 },
    { lng: -116.63, lat: 51.69 },
    { lng: -116.65, lat: 51.69 },
    { lng: -116.68, lat: 51.72 },
    { lng: -116.69, lat: 51.76 },
    { lng: -116.73, lat: 51.76 },
    { lng: -116.76, lat: 51.73 },
    { lng: -116.76, lat: 51.7 },
    { lng: -116.81, lat: 51.66 },
    { lng: -116.94, lat: 51.67 },
    { lng: -116.97, lat: 51.71 },
    { lng: -117.01, lat: 51.73 },
    { lng: -117.01, lat: 51.78 },
    { lng: -117.07, lat: 51.83 },
    { lng: -117.07, lat: 51.88 },
    { lng: -117.15, lat: 51.93 },
    { lng: -117.22, lat: 51.94 },
    { lng: -117.25, lat: 51.98 },
    { lng: -117.27, lat: 51.98 },
    { lng: -117.27, lat: 52 },
    { lng: -117.3, lat: 52.03 },
    { lng: -117.34, lat: 52.05 },
    { lng: -117.35, lat: 52.07 },
    { lng: -117.34, lat: 52.1 },
    { lng: -117.51, lat: 52.11 },
    { lng: -117.54, lat: 52.09 },
    { lng: -117.6, lat: 52.09 },
    { lng: -117.64, lat: 52.11 },
    { lng: -117.67, lat: 52.15 },
    { lng: -117.71, lat: 52.14 },
    { lng: -117.84, lat: 52.19 },
    { lng: -117.88, lat: 52.25 },
    { lng: -117.88, lat: 52.29 },
    { lng: -117.77, lat: 52.37 },
    { lng: -117.85, lat: 52.37 },
    { lng: -118, lat: 52.43 },
    { lng: -118.01, lat: 52.38 },
    { lng: -118.03, lat: 52.36 },
    { lng: -118.09, lat: 52.37 },
    { lng: -118.17, lat: 52.33 },
    { lng: -118.23, lat: 52.33 },
    { lng: -118.26, lat: 52.35 },
    { lng: -118.29, lat: 52.42 },
    { lng: -118.28, lat: 52.47 },
    { lng: -118.32, lat: 52.5 },
    { lng: -118.33, lat: 52.54 },
    { lng: -118.35, lat: 52.54 },
    { lng: -118.4, lat: 52.6 },
    { lng: -118.39, lat: 52.66 },
    { lng: -118.36, lat: 52.67 },
    { lng: -118.38, lat: 52.71 },
    { lng: -118.44, lat: 52.73 },
    { lng: -118.46, lat: 52.76 },
    { lng: -118.46, lat: 52.82 },
    { lng: -118.49, lat: 52.83 },
    { lng: -118.5, lat: 52.86 },
    { lng: -118.63, lat: 52.84 },
    { lng: -118.66, lat: 52.88 },
    { lng: -118.66, lat: 52.92 },
    { lng: -118.68, lat: 52.92 },
    { lng: -118.71, lat: 52.96 },
    { lng: -118.71, lat: 53 },
    { lng: -118.79, lat: 53 },
    { lng: -118.81, lat: 53.02 },
    { lng: -118.82, lat: 53.06 },
    { lng: -118.8, lat: 53.1 },
    { lng: -118.82, lat: 53.11 },
    { lng: -118.82, lat: 53.13 },
    { lng: -118.95, lat: 53.19 },
    { lng: -118.96, lat: 53.11 },
    { lng: -119.02, lat: 53.08 },
    { lng: -119.07, lat: 53.09 },
    { lng: -119.09, lat: 53.12 },
    { lng: -119.14, lat: 53.12 },
    { lng: -119.15, lat: 53.14 },
    { lng: -119.28, lat: 53.14 },
    { lng: -119.32, lat: 53.22 },
    { lng: -119.35, lat: 53.23 },
    { lng: -119.4, lat: 53.29 },
    { lng: -119.4, lat: 53.32 },
    { lng: -119.47, lat: 53.31 },
    { lng: -119.52, lat: 53.33 },
    { lng: -119.69, lat: 53.33 },
    { lng: -119.71, lat: 53.35 },
    { lng: -119.75, lat: 53.35 },
    { lng: -119.79, lat: 53.39 },
    { lng: -119.83, lat: 53.47 },
    { lng: -119.91, lat: 53.47 },
    { lng: -119.94, lat: 53.5 },
    { lng: -119.93, lat: 53.56 },
    { lng: -119.97, lat: 53.59 },
    { lng: -119.97, lat: 53.63 },
    { lng: -119.92, lat: 53.66 },
    { lng: -119.84, lat: 53.65 },
    { lng: -119.87, lat: 53.67 },
    { lng: -119.93, lat: 53.67 },
    { lng: -119.96, lat: 53.71 },
    { lng: -119.95, lat: 53.74 },
    { lng: -119.98, lat: 53.74 },
    { lng: -120.04, lat: 53.78 },
    { lng: -120.04, lat: 54.74 },
  ],
  // MANITOBA
  MB: [
    { lng: -102.04, lat: 56.14 },
    { lng: -102.05, lat: 60.01 },
    { lng: -102.02, lat: 60.04 },
    { lng: -94.81, lat: 60.04 },
    { lng: -94.79, lat: 60.02 },
    { lng: -94.8, lat: 59.96 },
    { lng: -94.77, lat: 59.92 },
    { lng: -94.76, lat: 59.84 },
    { lng: -94.73, lat: 59.79 },
    { lng: -94.77, lat: 59.64 },
    { lng: -94.79, lat: 59.62 },
    { lng: -94.73, lat: 59.59 },
    { lng: -94.72, lat: 59.57 },
    { lng: -94.74, lat: 59.52 },
    { lng: -94.69, lat: 59.45 },
    { lng: -94.7, lat: 59.43 },
    { lng: -94.67, lat: 59.4 },
    { lng: -94.65, lat: 59.4 },
    { lng: -94.62, lat: 59.36 },
    { lng: -94.63, lat: 59.32 },
    { lng: -94.68, lat: 59.31 },
    { lng: -94.68, lat: 59.29 },
    { lng: -94.72, lat: 59.28 },
    { lng: -94.75, lat: 59.24 },
    { lng: -94.74, lat: 59.23 },
    { lng: -94.74, lat: 59.13 },
    { lng: -94.76, lat: 59.1 },
    { lng: -94.75, lat: 59.06 },
    { lng: -94.71, lat: 59.03 },
    { lng: -94.68, lat: 59.03 },
    { lng: -94.65, lat: 58.99 },
    { lng: -94.56, lat: 58.97 },
    { lng: -94.55, lat: 58.88 },
    { lng: -94.46, lat: 58.84 },
    { lng: -94.43, lat: 58.8 },
    { lng: -94.43, lat: 58.76 },
    { lng: -94.4, lat: 58.74 },
    { lng: -94.35, lat: 58.75 },
    { lng: -94.3, lat: 58.83 },
    { lng: -94.25, lat: 58.84 },
    { lng: -94.23, lat: 58.86 },
    { lng: -94.19, lat: 58.85 },
    { lng: -94.16, lat: 58.82 },
    { lng: -94.09, lat: 58.81 },
    { lng: -93.88, lat: 58.8 },
    { lng: -93.85, lat: 58.84 },
    { lng: -93.81, lat: 58.84 },
    { lng: -93.79, lat: 58.82 },
    { lng: -93.77, lat: 58.84 },
    { lng: -93.63, lat: 58.83 },
    { lng: -93.6, lat: 58.81 },
    { lng: -93.51, lat: 58.82 },
    { lng: -93.47, lat: 58.81 },
    { lng: -93.44, lat: 58.75 },
    { lng: -93.35, lat: 58.8 },
    { lng: -93.3, lat: 58.79 },
    { lng: -93.25, lat: 58.82 },
    { lng: -93.18, lat: 58.8 },
    { lng: -93.1, lat: 58.7 },
    { lng: -93.11, lat: 58.64 },
    { lng: -93.09, lat: 58.62 },
    { lng: -93.09, lat: 58.54 },
    { lng: -93.07, lat: 58.52 },
    { lng: -93.07, lat: 58.49 },
    { lng: -93.01, lat: 58.43 },
    { lng: -93, lat: 58.37 },
    { lng: -92.94, lat: 58.34 },
    { lng: -92.93, lat: 58.29 },
    { lng: -92.89, lat: 58.25 },
    { lng: -92.89, lat: 58.23 },
    { lng: -92.83, lat: 58.2 },
    { lng: -92.8, lat: 58.1 },
    { lng: -92.76, lat: 58.07 },
    { lng: -92.75, lat: 58.04 },
    { lng: -92.76, lat: 57.97 },
    { lng: -92.74, lat: 57.96 },
    { lng: -92.73, lat: 57.9 },
    { lng: -92.7, lat: 57.88 },
    { lng: -92.69, lat: 57.81 },
    { lng: -92.61, lat: 57.7 },
    { lng: -92.52, lat: 57.61 },
    { lng: -92.52, lat: 57.59 },
    { lng: -92.45, lat: 57.53 },
    { lng: -92.41, lat: 57.46 },
    { lng: -92.38, lat: 57.33 },
    { lng: -92.4, lat: 57.25 },
    { lng: -92.52, lat: 57.06 },
    { lng: -92.46, lat: 57.09 },
    { lng: -92.28, lat: 57.11 },
    { lng: -92.19, lat: 57.11 },
    { lng: -92.12, lat: 57.09 },
    { lng: -91.89, lat: 57.11 },
    { lng: -91.74, lat: 57.16 },
    { lng: -91.56, lat: 57.19 },
    { lng: -91.36, lat: 57.25 },
    { lng: -90.98, lat: 57.31 },
    { lng: -90.68, lat: 57.26 },
    { lng: -90.6, lat: 57.27 },
    { lng: -90.52, lat: 57.23 },
    { lng: -90.47, lat: 57.24 },
    { lng: -90.39, lat: 57.22 },
    { lng: -90.37, lat: 57.19 },
    { lng: -90.19, lat: 57.14 },
    { lng: -90.12, lat: 57.1 },
    { lng: -90.04, lat: 57.09 },
    { lng: -89.95, lat: 57.04 },
    { lng: -89.88, lat: 57.04 },
    { lng: -89.51, lat: 56.97 },
    { lng: -89.38, lat: 56.97 },
    { lng: -89.19, lat: 56.92 },
    { lng: -88.98, lat: 56.89 },
    { lng: -88.95, lat: 56.86 },
    { lng: -88.95, lat: 56.83 },
    { lng: -88.97, lat: 56.81 },
    { lng: -89.95, lat: 56.22 },
    { lng: -91.36, lat: 55.31 },
    { lng: -91.4, lat: 55.27 },
    { lng: -92.43, lat: 54.58 },
    { lng: -93.64, lat: 53.7 },
    { lng: -94.3, lat: 53.32 },
    { lng: -95.11, lat: 52.82 },
    { lng: -95.11, lat: 48.99 },
    { lng: -95.14, lat: 48.96 },
    { lng: -101.38, lat: 48.96 },
    { lng: -101.4, lat: 48.98 },
    { lng: -101.4, lat: 49.14 },
    { lng: -101.43, lat: 49.16 },
    { lng: -101.43, lat: 49.5 },
    { lng: -101.45, lat: 49.5 },
    { lng: -101.46, lat: 49.52 },
    { lng: -101.46, lat: 49.85 },
    { lng: -101.49, lat: 49.88 },
    { lng: -101.49, lat: 50.2 },
    { lng: -101.52, lat: 50.23 },
    { lng: -101.52, lat: 50.56 },
    { lng: -101.55, lat: 50.58 },
    { lng: -101.55, lat: 50.91 },
    { lng: -101.58, lat: 50.93 },
    { lng: -101.58, lat: 51.26 },
    { lng: -101.61, lat: 51.29 },
    { lng: -101.62, lat: 51.93 },
    { lng: -101.65, lat: 51.95 },
    { lng: -101.65, lat: 52.28 },
    { lng: -101.68, lat: 52.31 },
    { lng: -101.68, lat: 52.63 },
    { lng: -101.71, lat: 52.65 },
    { lng: -101.71, lat: 52.98 },
    { lng: -101.74, lat: 53 },
    { lng: -101.74, lat: 53.33 },
    { lng: -101.76, lat: 53.33 },
    { lng: -101.78, lat: 53.37 },
    { lng: -101.78, lat: 53.67 },
    { lng: -101.81, lat: 53.7 },
    { lng: -101.81, lat: 54.02 },
    { lng: -101.85, lat: 54.05 },
    { lng: -101.85, lat: 54.37 },
    { lng: -101.89, lat: 54.41 },
    { lng: -101.89, lat: 54.72 },
    { lng: -101.93, lat: 54.76 },
    { lng: -101.93, lat: 55.07 },
    { lng: -101.96, lat: 55.09 },
    { lng: -101.96, lat: 55.42 },
    { lng: -102, lat: 55.44 },
    { lng: -102, lat: 55.77 },
    { lng: -102.04, lat: 55.79 },
    { lng: -102.04, lat: 56.14 },
  ],
  // QUEBEC
  QC: [
    { lng: -79.69, lat: 54.58 },
    { lng: -79.76, lat: 54.57 },
    { lng: -79.8, lat: 54.59 },
    { lng: -79.81, lat: 54.66 },
    { lng: -79.78, lat: 54.69 },
    { lng: -79.62, lat: 54.74 },
    { lng: -79.57, lat: 54.77 },
    { lng: -79.5, lat: 54.77 },
    { lng: -79.42, lat: 54.81 },
    { lng: -79.35, lat: 54.81 },
    { lng: -79.14, lat: 54.87 },
    { lng: -79, lat: 54.89 },
    { lng: -78.94, lat: 54.93 },
    { lng: -78.7, lat: 54.98 },
    { lng: -78.58, lat: 55.02 },
    { lng: -78.56, lat: 55.04 },
    { lng: -78.48, lat: 55.04 },
    { lng: -78.44, lat: 55.06 },
    { lng: -78.35, lat: 55.07 },
    { lng: -78.31, lat: 55.09 },
    { lng: -78.29, lat: 55.12 },
    { lng: -78.17, lat: 55.18 },
    { lng: -77.92, lat: 55.26 },
    { lng: -77.86, lat: 55.3 },
    { lng: -77.82, lat: 55.3 },
    { lng: -77.69, lat: 55.4 },
    { lng: -77.56, lat: 55.44 },
    { lng: -77.44, lat: 55.53 },
    { lng: -77.38, lat: 55.55 },
    { lng: -77.32, lat: 55.6 },
    { lng: -77.27, lat: 55.61 },
    { lng: -77.25, lat: 55.64 },
    { lng: -77.23, lat: 55.64 },
    { lng: -77.24, lat: 55.68 },
    { lng: -77.22, lat: 55.71 },
    { lng: -77.15, lat: 55.74 },
    { lng: -77.12, lat: 55.78 },
    { lng: -77.03, lat: 55.83 },
    { lng: -77.02, lat: 55.86 },
    { lng: -77, lat: 55.86 },
    { lng: -76.94, lat: 55.92 },
    { lng: -76.92, lat: 55.92 },
    { lng: -76.89, lat: 55.96 },
    { lng: -76.86, lat: 55.96 },
    { lng: -76.82, lat: 56.04 },
    { lng: -76.75, lat: 56.05 },
    { lng: -76.71, lat: 56.13 },
    { lng: -76.69, lat: 56.14 },
    { lng: -76.71, lat: 56.21 },
    { lng: -76.67, lat: 56.23 },
    { lng: -76.65, lat: 56.28 },
    { lng: -76.59, lat: 56.31 },
    { lng: -76.6, lat: 56.36 },
    { lng: -76.58, lat: 56.38 },
    { lng: -76.57, lat: 56.44 },
    { lng: -76.59, lat: 56.46 },
    { lng: -76.6, lat: 56.57 },
    { lng: -76.58, lat: 56.59 },
    { lng: -76.59, lat: 56.62 },
    { lng: -76.57, lat: 56.7 },
    { lng: -76.57, lat: 56.8 },
    { lng: -76.59, lat: 56.89 },
    { lng: -76.61, lat: 56.91 },
    { lng: -76.62, lat: 57.11 },
    { lng: -76.7, lat: 57.31 },
    { lng: -76.75, lat: 57.37 },
    { lng: -76.75, lat: 57.39 },
    { lng: -76.84, lat: 57.47 },
    { lng: -76.85, lat: 57.52 },
    { lng: -76.93, lat: 57.62 },
    { lng: -76.94, lat: 57.69 },
    { lng: -77.26, lat: 58 },
    { lng: -77.36, lat: 58.05 },
    { lng: -77.4, lat: 58.05 },
    { lng: -77.44, lat: 58.07 },
    { lng: -77.46, lat: 58.1 },
    { lng: -77.56, lat: 58.12 },
    { lng: -77.59, lat: 58.16 },
    { lng: -77.58, lat: 58.19 },
    { lng: -77.65, lat: 58.2 },
    { lng: -77.77, lat: 58.25 },
    { lng: -78.09, lat: 58.34 },
    { lng: -78.12, lat: 58.38 },
    { lng: -78.28, lat: 58.47 },
    { lng: -78.49, lat: 58.52 },
    { lng: -78.53, lat: 58.56 },
    { lng: -78.54, lat: 58.6 },
    { lng: -78.62, lat: 58.61 },
    { lng: -78.66, lat: 58.66 },
    { lng: -78.65, lat: 58.71 },
    { lng: -78.61, lat: 58.73 },
    { lng: -78.63, lat: 58.76 },
    { lng: -78.63, lat: 58.81 },
    { lng: -78.61, lat: 58.82 },
    { lng: -78.61, lat: 58.84 },
    { lng: -78.64, lat: 58.89 },
    { lng: -78.64, lat: 58.95 },
    { lng: -78.57, lat: 58.99 },
    { lng: -78.52, lat: 58.99 },
    { lng: -78.48, lat: 58.96 },
    { lng: -78.43, lat: 58.95 },
    { lng: -78.36, lat: 59.01 },
    { lng: -78.37, lat: 59.08 },
    { lng: -78.31, lat: 59.11 },
    { lng: -78.29, lat: 59.15 },
    { lng: -78.24, lat: 59.16 },
    { lng: -78.23, lat: 59.19 },
    { lng: -78.21, lat: 59.19 },
    { lng: -78.21, lat: 59.23 },
    { lng: -78.19, lat: 59.25 },
    { lng: -78.08, lat: 59.27 },
    { lng: -78.06, lat: 59.3 },
    { lng: -78.01, lat: 59.31 },
    { lng: -77.97, lat: 59.35 },
    { lng: -78.01, lat: 59.38 },
    { lng: -78.01, lat: 59.46 },
    { lng: -77.98, lat: 59.48 },
    { lng: -77.97, lat: 59.52 },
    { lng: -77.94, lat: 59.55 },
    { lng: -77.82, lat: 59.58 },
    { lng: -77.89, lat: 59.67 },
    { lng: -77.89, lat: 59.71 },
    { lng: -77.85, lat: 59.76 },
    { lng: -77.79, lat: 59.77 },
    { lng: -77.73, lat: 59.76 },
    { lng: -77.7, lat: 59.73 },
    { lng: -77.66, lat: 59.73 },
    { lng: -77.61, lat: 59.8 },
    { lng: -77.53, lat: 59.8 },
    { lng: -77.5, lat: 59.83 },
    { lng: -77.44, lat: 59.85 },
    { lng: -77.45, lat: 59.87 },
    { lng: -77.51, lat: 59.88 },
    { lng: -77.6, lat: 59.97 },
    { lng: -77.61, lat: 60.01 },
    { lng: -77.69, lat: 60.04 },
    { lng: -77.69, lat: 60.08 },
    { lng: -77.66, lat: 60.14 },
    { lng: -77.61, lat: 60.16 },
    { lng: -77.63, lat: 60.25 },
    { lng: -77.66, lat: 60.29 },
    { lng: -77.68, lat: 60.29 },
    { lng: -77.7, lat: 60.33 },
    { lng: -77.81, lat: 60.35 },
    { lng: -77.83, lat: 60.38 },
    { lng: -77.82, lat: 60.42 },
    { lng: -77.71, lat: 60.51 },
    { lng: -77.75, lat: 60.54 },
    { lng: -77.84, lat: 60.56 },
    { lng: -77.89, lat: 60.63 },
    { lng: -77.87, lat: 60.69 },
    { lng: -77.81, lat: 60.71 },
    { lng: -77.83, lat: 60.73 },
    { lng: -77.95, lat: 60.71 },
    { lng: -77.96, lat: 60.74 },
    { lng: -78.11, lat: 60.76 },
    { lng: -78.22, lat: 60.74 },
    { lng: -78.27, lat: 60.77 },
    { lng: -78.27, lat: 60.82 },
    { lng: -78.23, lat: 60.89 },
    { lng: -78.18, lat: 60.91 },
    { lng: -78.12, lat: 60.97 },
    { lng: -78.07, lat: 60.98 },
    { lng: -77.99, lat: 61.05 },
    { lng: -77.87, lat: 61.11 },
    { lng: -77.87, lat: 61.17 },
    { lng: -77.82, lat: 61.2 },
    { lng: -77.84, lat: 61.23 },
    { lng: -77.84, lat: 61.26 },
    { lng: -77.82, lat: 61.28 },
    { lng: -77.82, lat: 61.35 },
    { lng: -77.87, lat: 61.45 },
    { lng: -77.84, lat: 61.5 },
    { lng: -77.75, lat: 61.49 },
    { lng: -77.76, lat: 61.56 },
    { lng: -77.79, lat: 61.61 },
    { lng: -77.83, lat: 61.63 },
    { lng: -77.83, lat: 61.65 },
    { lng: -78, lat: 61.65 },
    { lng: -78.01, lat: 61.67 },
    { lng: -78.04, lat: 61.67 },
    { lng: -78.07, lat: 61.71 },
    { lng: -78.07, lat: 61.75 },
    { lng: -78.14, lat: 61.85 },
    { lng: -78.15, lat: 61.95 },
    { lng: -78.18, lat: 61.96 },
    { lng: -78.21, lat: 62 },
    { lng: -78.2, lat: 62.04 },
    { lng: -78.22, lat: 62.06 },
    { lng: -78.22, lat: 62.12 },
    { lng: -78.24, lat: 62.13 },
    { lng: -78.24, lat: 62.18 },
    { lng: -78.21, lat: 62.22 },
    { lng: -78.24, lat: 62.24 },
    { lng: -78.24, lat: 62.28 },
    { lng: -78.21, lat: 62.32 },
    { lng: -78.11, lat: 62.4 },
    { lng: -78, lat: 62.42 },
    { lng: -77.93, lat: 62.47 },
    { lng: -77.82, lat: 62.48 },
    { lng: -77.7, lat: 62.55 },
    { lng: -77.61, lat: 62.56 },
    { lng: -77.53, lat: 62.62 },
    { lng: -77.42, lat: 62.62 },
    { lng: -77.33, lat: 62.59 },
    { lng: -77.03, lat: 62.57 },
    { lng: -76.93, lat: 62.58 },
    { lng: -76.76, lat: 62.56 },
    { lng: -76.63, lat: 62.51 },
    { lng: -75.52, lat: 62.31 },
    { lng: -75.45, lat: 62.35 },
    { lng: -75.36, lat: 62.36 },
    { lng: -74.91, lat: 62.3 },
    { lng: -74.8, lat: 62.25 },
    { lng: -74.75, lat: 62.26 },
    { lng: -74.71, lat: 62.3 },
    { lng: -74.48, lat: 62.29 },
    { lng: -74.39, lat: 62.3 },
    { lng: -74.23, lat: 62.37 },
    { lng: -74.13, lat: 62.38 },
    { lng: -73.97, lat: 62.43 },
    { lng: -73.86, lat: 62.5 },
    { lng: -73.66, lat: 62.52 },
    { lng: -73.53, lat: 62.43 },
    { lng: -73.46, lat: 62.43 },
    { lng: -73.17, lat: 62.35 },
    { lng: -73.15, lat: 62.34 },
    { lng: -73.15, lat: 62.3 },
    { lng: -72.87, lat: 62.18 },
    { lng: -72.7, lat: 62.19 },
    { lng: -72.6, lat: 62.16 },
    { lng: -72.56, lat: 62.13 },
    { lng: -72.53, lat: 62.06 },
    { lng: -72.54, lat: 62.04 },
    { lng: -72.53, lat: 61.98 },
    { lng: -72.44, lat: 61.95 },
    { lng: -72.21, lat: 61.92 },
    { lng: -72.08, lat: 61.83 },
    { lng: -72.05, lat: 61.79 },
    { lng: -72, lat: 61.77 },
    { lng: -71.56, lat: 61.67 },
    { lng: -71.49, lat: 61.62 },
    { lng: -71.49, lat: 61.55 },
    { lng: -71.51, lat: 61.53 },
    { lng: -71.72, lat: 61.51 },
    { lng: -71.72, lat: 61.45 },
    { lng: -71.61, lat: 61.42 },
    { lng: -71.61, lat: 61.35 },
    { lng: -71.66, lat: 61.31 },
    { lng: -71.61, lat: 61.31 },
    { lng: -71.55, lat: 61.28 },
    { lng: -71.54, lat: 61.26 },
    { lng: -71.41, lat: 61.26 },
    { lng: -71.38, lat: 61.21 },
    { lng: -70.99, lat: 61.17 },
    { lng: -70.89, lat: 61.14 },
    { lng: -70.73, lat: 61.13 },
    { lng: -70.64, lat: 61.1 },
    { lng: -70.51, lat: 61.1 },
    { lng: -70.42, lat: 61.13 },
    { lng: -70.15, lat: 61.14 },
    { lng: -70.08, lat: 61.1 },
    { lng: -70.06, lat: 61.07 },
    { lng: -70.09, lat: 61 },
    { lng: -70.05, lat: 60.95 },
    { lng: -70.04, lat: 60.9 },
    { lng: -69.9, lat: 60.85 },
    { lng: -69.86, lat: 60.92 },
    { lng: -69.73, lat: 60.96 },
    { lng: -69.74, lat: 61.01 },
    { lng: -69.63, lat: 61.12 },
    { lng: -69.5, lat: 61.12 },
    { lng: -69.47, lat: 61.1 },
    { lng: -69.4, lat: 60.98 },
    { lng: -69.35, lat: 60.96 },
    { lng: -69.33, lat: 60.93 },
    { lng: -69.35, lat: 60.88 },
    { lng: -69.32, lat: 60.8 },
    { lng: -69.34, lat: 60.77 },
    { lng: -69.58, lat: 60.7 },
    { lng: -69.59, lat: 60.67 },
    { lng: -69.63, lat: 60.66 },
    { lng: -69.59, lat: 60.6 },
    { lng: -69.59, lat: 60.56 },
    { lng: -69.72, lat: 60.5 },
    { lng: -69.69, lat: 60.49 },
    { lng: -69.69, lat: 60.43 },
    { lng: -69.63, lat: 60.37 },
    { lng: -69.63, lat: 60.34 },
    { lng: -69.66, lat: 60.31 },
    { lng: -69.51, lat: 60.22 },
    { lng: -69.51, lat: 60.18 },
    { lng: -69.55, lat: 60.07 },
    { lng: -69.58, lat: 60.04 },
    { lng: -69.73, lat: 60.01 },
    { lng: -69.66, lat: 59.99 },
    { lng: -69.66, lat: 59.94 },
    { lng: -69.52, lat: 59.91 },
    { lng: -69.49, lat: 59.88 },
    { lng: -69.51, lat: 59.72 },
    { lng: -69.46, lat: 59.7 },
    { lng: -69.44, lat: 59.65 },
    { lng: -69.47, lat: 59.59 },
    { lng: -69.51, lat: 59.56 },
    { lng: -69.62, lat: 59.52 },
    { lng: -69.6, lat: 59.48 },
    { lng: -69.57, lat: 59.46 },
    { lng: -69.56, lat: 59.38 },
    { lng: -69.45, lat: 59.41 },
    { lng: -69.37, lat: 59.4 },
    { lng: -69.33, lat: 59.37 },
    { lng: -69.21, lat: 59.37 },
    { lng: -69.18, lat: 59.32 },
    { lng: -69.19, lat: 59.29 },
    { lng: -69.17, lat: 59.28 },
    { lng: -69.17, lat: 59.22 },
    { lng: -69.19, lat: 59.2 },
    { lng: -69.13, lat: 59.18 },
    { lng: -69.11, lat: 59.12 },
    { lng: -69.04, lat: 59.07 },
    { lng: -69.04, lat: 59.03 },
    { lng: -69.14, lat: 58.96 },
    { lng: -68.97, lat: 58.96 },
    { lng: -68.91, lat: 58.94 },
    { lng: -68.83, lat: 58.97 },
    { lng: -68.62, lat: 58.96 },
    { lng: -68.56, lat: 58.95 },
    { lng: -68.31, lat: 58.85 },
    { lng: -68.27, lat: 58.77 },
    { lng: -68.31, lat: 58.72 },
    { lng: -68.25, lat: 58.64 },
    { lng: -68.15, lat: 58.6 },
    { lng: -67.99, lat: 58.63 },
    { lng: -67.89, lat: 58.56 },
    { lng: -67.86, lat: 58.51 },
    { lng: -67.87, lat: 58.45 },
    { lng: -67.85, lat: 58.5 },
    { lng: -67.82, lat: 58.52 },
    { lng: -67.78, lat: 58.53 },
    { lng: -67.69, lat: 58.5 },
    { lng: -67.65, lat: 58.47 },
    { lng: -67.64, lat: 58.42 },
    { lng: -67.66, lat: 58.35 },
    { lng: -67.65, lat: 58.31 },
    { lng: -67.59, lat: 58.3 },
    { lng: -67.34, lat: 58.37 },
    { lng: -67.2, lat: 58.46 },
    { lng: -67.12, lat: 58.48 },
    { lng: -67.03, lat: 58.48 },
    { lng: -66.97, lat: 58.55 },
    { lng: -66.94, lat: 58.56 },
    { lng: -66.87, lat: 58.53 },
    { lng: -66.74, lat: 58.56 },
    { lng: -66.71, lat: 58.55 },
    { lng: -66.6, lat: 58.72 },
    { lng: -66.56, lat: 58.76 },
    { lng: -66.53, lat: 58.77 },
    { lng: -66.52, lat: 58.76 },
    { lng: -66.53, lat: 58.81 },
    { lng: -66.5, lat: 58.86 },
    { lng: -66.46, lat: 58.89 },
    { lng: -66.35, lat: 58.9 },
    { lng: -66.16, lat: 58.81 },
    { lng: -66.05, lat: 58.9 },
    { lng: -66.05, lat: 58.94 },
    { lng: -66.03, lat: 58.96 },
    { lng: -65.93, lat: 58.98 },
    { lng: -65.87, lat: 59.03 },
    { lng: -65.83, lat: 59.03 },
    { lng: -65.82, lat: 59.07 },
    { lng: -65.79, lat: 59.09 },
    { lng: -65.72, lat: 59.09 },
    { lng: -65.71, lat: 59.1 },
    { lng: -65.75, lat: 59.12 },
    { lng: -65.79, lat: 59.17 },
    { lng: -65.79, lat: 59.29 },
    { lng: -65.75, lat: 59.32 },
    { lng: -65.63, lat: 59.31 },
    { lng: -65.6, lat: 59.41 },
    { lng: -65.54, lat: 59.43 },
    { lng: -65.57, lat: 59.46 },
    { lng: -65.57, lat: 59.49 },
    { lng: -65.54, lat: 59.52 },
    { lng: -65.47, lat: 59.52 },
    { lng: -65.53, lat: 59.61 },
    { lng: -65.6, lat: 59.66 },
    { lng: -65.61, lat: 59.69 },
    { lng: -65.6, lat: 59.75 },
    { lng: -65.51, lat: 59.84 },
    { lng: -65.27, lat: 59.96 },
    { lng: -65.23, lat: 60.01 },
    { lng: -65.21, lat: 60.01 },
    { lng: -65.18, lat: 60.04 },
    { lng: -65.18, lat: 60.09 },
    { lng: -65.07, lat: 60.15 },
    { lng: -65, lat: 60.22 },
    { lng: -65.01, lat: 60.27 },
    { lng: -64.95, lat: 60.32 },
    { lng: -64.89, lat: 60.33 },
    { lng: -64.9, lat: 60.39 },
    { lng: -64.84, lat: 60.42 },
    { lng: -64.58, lat: 60.38 },
    { lng: -64.48, lat: 60.33 },
    { lng: -64.48, lat: 60.28 },
    { lng: -64.51, lat: 60.26 },
    { lng: -64.57, lat: 60.27 },
    { lng: -64.65, lat: 60.25 },
    { lng: -64.76, lat: 60.25 },
    { lng: -64.76, lat: 60.23 },
    { lng: -64.72, lat: 60.21 },
    { lng: -64.59, lat: 60.21 },
    { lng: -64.55, lat: 60.16 },
    { lng: -64.56, lat: 60.14 },
    { lng: -64.55, lat: 60.11 },
    { lng: -64.58, lat: 60.07 },
    { lng: -64.67, lat: 60.07 },
    { lng: -64.69, lat: 60.03 },
    { lng: -64.75, lat: 60.02 },
    { lng: -64.71, lat: 59.99 },
    { lng: -64.67, lat: 60 },
    { lng: -64.61, lat: 59.97 },
    { lng: -64.59, lat: 59.95 },
    { lng: -64.59, lat: 59.91 },
    { lng: -64.64, lat: 59.85 },
    { lng: -64.7, lat: 59.84 },
    { lng: -64.71, lat: 59.82 },
    { lng: -64.75, lat: 59.81 },
    { lng: -64.76, lat: 59.78 },
    { lng: -64.71, lat: 59.74 },
    { lng: -64.71, lat: 59.7 },
    { lng: -64.77, lat: 59.65 },
    { lng: -64.84, lat: 59.63 },
    { lng: -64.82, lat: 59.61 },
    { lng: -64.82, lat: 59.58 },
    { lng: -64.74, lat: 59.55 },
    { lng: -64.68, lat: 59.49 },
    { lng: -64.65, lat: 59.52 },
    { lng: -64.59, lat: 59.51 },
    { lng: -64.58, lat: 59.53 },
    { lng: -64.55, lat: 59.53 },
    { lng: -64.52, lat: 59.56 },
    { lng: -64.47, lat: 59.56 },
    { lng: -64.45, lat: 59.58 },
    { lng: -64.3, lat: 59.55 },
    { lng: -64.28, lat: 59.52 },
    { lng: -64.31, lat: 59.46 },
    { lng: -64.35, lat: 59.46 },
    { lng: -64.38, lat: 59.43 },
    { lng: -64.42, lat: 59.43 },
    { lng: -64.42, lat: 59.4 },
    { lng: -64.46, lat: 59.37 },
    { lng: -64.45, lat: 59.34 },
    { lng: -64.48, lat: 59.3 },
    { lng: -64.45, lat: 59.28 },
    { lng: -64.45, lat: 59.24 },
    { lng: -64.47, lat: 59.22 },
    { lng: -64.45, lat: 59.22 },
    { lng: -64.43, lat: 59.19 },
    { lng: -64.45, lat: 59.14 },
    { lng: -64.36, lat: 59.13 },
    { lng: -64.29, lat: 59.1 },
    { lng: -64.22, lat: 59.03 },
    { lng: -64.22, lat: 59 },
    { lng: -64.23, lat: 58.98 },
    { lng: -64.28, lat: 58.96 },
    { lng: -64.38, lat: 58.97 },
    { lng: -64.44, lat: 58.95 },
    { lng: -64.26, lat: 58.93 },
    { lng: -64.25, lat: 58.91 },
    { lng: -64.2, lat: 58.89 },
    { lng: -64.19, lat: 58.83 },
    { lng: -64.16, lat: 58.83 },
    { lng: -64.13, lat: 58.79 },
    { lng: -64.11, lat: 58.79 },
    { lng: -64.11, lat: 58.81 },
    { lng: -64.08, lat: 58.82 },
    { lng: -64.08, lat: 58.84 },
    { lng: -64.03, lat: 58.86 },
    { lng: -63.9, lat: 58.88 },
    { lng: -63.87, lat: 58.87 },
    { lng: -63.8, lat: 58.92 },
    { lng: -63.7, lat: 58.93 },
    { lng: -63.65, lat: 58.91 },
    { lng: -63.64, lat: 58.89 },
    { lng: -63.59, lat: 58.9 },
    { lng: -63.53, lat: 58.87 },
    { lng: -63.47, lat: 58.8 },
    { lng: -63.43, lat: 58.78 },
    { lng: -63.44, lat: 58.73 },
    { lng: -63.5, lat: 58.7 },
    { lng: -63.6, lat: 58.69 },
    { lng: -63.63, lat: 58.67 },
    { lng: -63.75, lat: 58.68 },
    { lng: -63.79, lat: 58.66 },
    { lng: -63.91, lat: 58.67 },
    { lng: -63.92, lat: 58.65 },
    { lng: -64.03, lat: 58.66 },
    { lng: -64.06, lat: 58.62 },
    { lng: -64.05, lat: 58.57 },
    { lng: -63.99, lat: 58.57 },
    { lng: -63.96, lat: 58.6 },
    { lng: -63.92, lat: 58.61 },
    { lng: -63.83, lat: 58.6 },
    { lng: -63.81, lat: 58.57 },
    { lng: -63.81, lat: 58.53 },
    { lng: -63.77, lat: 58.51 },
    { lng: -63.77, lat: 58.47 },
    { lng: -63.87, lat: 58.39 },
    { lng: -63.99, lat: 58.39 },
    { lng: -63.98, lat: 58.36 },
    { lng: -64.01, lat: 58.33 },
    { lng: -64.14, lat: 58.33 },
    { lng: -64.18, lat: 58.25 },
    { lng: -64.18, lat: 58.22 },
    { lng: -64.23, lat: 58.19 },
    { lng: -64.39, lat: 58.16 },
    { lng: -64.39, lat: 58.11 },
    { lng: -64.34, lat: 58.11 },
    { lng: -64.3, lat: 58.09 },
    { lng: -64.25, lat: 58.1 },
    { lng: -64.18, lat: 58.06 },
    { lng: -64.19, lat: 58.01 },
    { lng: -64.16, lat: 57.96 },
    { lng: -64.12, lat: 57.94 },
    { lng: -64.12, lat: 57.9 },
    { lng: -64.06, lat: 57.82 },
    { lng: -64.03, lat: 57.85 },
    { lng: -63.93, lat: 57.83 },
    { lng: -63.89, lat: 57.84 },
    { lng: -63.86, lat: 57.81 },
    { lng: -63.87, lat: 57.78 },
    { lng: -63.86, lat: 57.76 },
    { lng: -63.8, lat: 57.74 },
    { lng: -63.77, lat: 57.76 },
    { lng: -63.73, lat: 57.76 },
    { lng: -63.72, lat: 57.73 },
    { lng: -63.64, lat: 57.78 },
    { lng: -63.58, lat: 57.78 },
    { lng: -63.56, lat: 57.76 },
    { lng: -63.56, lat: 57.64 },
    { lng: -63.63, lat: 57.58 },
    { lng: -63.71, lat: 57.56 },
    { lng: -63.71, lat: 57.51 },
    { lng: -63.73, lat: 57.48 },
    { lng: -63.7, lat: 57.48 },
    { lng: -63.68, lat: 57.46 },
    { lng: -63.66, lat: 57.35 },
    { lng: -63.68, lat: 57.33 },
    { lng: -63.76, lat: 57.32 },
    { lng: -63.78, lat: 57.3 },
    { lng: -63.71, lat: 57.27 },
    { lng: -63.7, lat: 57.2 },
    { lng: -63.74, lat: 57.17 },
    { lng: -63.72, lat: 57.14 },
    { lng: -63.78, lat: 57.05 },
    { lng: -63.84, lat: 57.04 },
    { lng: -63.82, lat: 57.02 },
    { lng: -63.82, lat: 56.98 },
    { lng: -63.87, lat: 56.94 },
    { lng: -63.83, lat: 56.92 },
    { lng: -63.82, lat: 56.85 },
    { lng: -63.84, lat: 56.83 },
    { lng: -63.9, lat: 56.84 },
    { lng: -63.97, lat: 56.82 },
    { lng: -63.96, lat: 56.78 },
    { lng: -63.98, lat: 56.75 },
    { lng: -64.08, lat: 56.71 },
    { lng: -64, lat: 56.69 },
    { lng: -63.97, lat: 56.6 },
    { lng: -63.89, lat: 56.55 },
    { lng: -63.9, lat: 56.51 },
    { lng: -63.87, lat: 56.51 },
    { lng: -63.83, lat: 56.46 },
    { lng: -63.83, lat: 56.43 },
    { lng: -63.88, lat: 56.39 },
    { lng: -63.9, lat: 56.4 },
    { lng: -63.95, lat: 56.37 },
    { lng: -64.07, lat: 56.38 },
    { lng: -64.06, lat: 56.3 },
    { lng: -63.89, lat: 56.3 },
    { lng: -63.87, lat: 56.26 },
    { lng: -63.83, lat: 56.24 },
    { lng: -63.82, lat: 56.2 },
    { lng: -63.84, lat: 56.17 },
    { lng: -63.8, lat: 56.14 },
    { lng: -63.78, lat: 56.1 },
    { lng: -63.7, lat: 56.11 },
    { lng: -63.67, lat: 56.1 },
    { lng: -63.65, lat: 56.07 },
    { lng: -63.47, lat: 56.08 },
    { lng: -63.43, lat: 56.07 },
    { lng: -63.4, lat: 56.04 },
    { lng: -63.43, lat: 55.97 },
    { lng: -63.46, lat: 55.95 },
    { lng: -63.51, lat: 55.95 },
    { lng: -63.55, lat: 55.97 },
    { lng: -63.6, lat: 55.93 },
    { lng: -63.65, lat: 55.93 },
    { lng: -63.71, lat: 55.89 },
    { lng: -63.75, lat: 55.89 },
    { lng: -63.71, lat: 55.86 },
    { lng: -63.71, lat: 55.83 },
    { lng: -63.65, lat: 55.83 },
    { lng: -63.63, lat: 55.81 },
    { lng: -63.63, lat: 55.76 },
    { lng: -63.67, lat: 55.69 },
    { lng: -63.63, lat: 55.68 },
    { lng: -63.6, lat: 55.65 },
    { lng: -63.63, lat: 55.58 },
    { lng: -63.63, lat: 55.52 },
    { lng: -63.68, lat: 55.47 },
    { lng: -63.47, lat: 55.47 },
    { lng: -63.42, lat: 55.45 },
    { lng: -63.34, lat: 55.46 },
    { lng: -63.28, lat: 55.43 },
    { lng: -63.28, lat: 55.37 },
    { lng: -63.3, lat: 55.33 },
    { lng: -63.44, lat: 55.3 },
    { lng: -63.39, lat: 55.3 },
    { lng: -63.36, lat: 55.27 },
    { lng: -63.36, lat: 55.24 },
    { lng: -63.4, lat: 55.18 },
    { lng: -63.48, lat: 55.16 },
    { lng: -63.55, lat: 55.1 },
    { lng: -63.52, lat: 55.06 },
    { lng: -63.52, lat: 54.97 },
    { lng: -63.54, lat: 54.95 },
    { lng: -63.55, lat: 54.88 },
    { lng: -63.57, lat: 54.86 },
    { lng: -63.64, lat: 54.87 },
    { lng: -63.69, lat: 54.86 },
    { lng: -63.75, lat: 54.9 },
    { lng: -63.8, lat: 54.88 },
    { lng: -63.78, lat: 54.85 },
    { lng: -63.78, lat: 54.8 },
    { lng: -63.82, lat: 54.77 },
    { lng: -63.78, lat: 54.76 },
    { lng: -63.72, lat: 54.7 },
    { lng: -63.69, lat: 54.69 },
    { lng: -63.67, lat: 54.61 },
    { lng: -63.72, lat: 54.58 },
    { lng: -63.81, lat: 54.6 },
    { lng: -63.85, lat: 54.59 },
    { lng: -63.88, lat: 54.56 },
    { lng: -63.96, lat: 54.58 },
    { lng: -63.99, lat: 54.56 },
    { lng: -64.07, lat: 54.56 },
    { lng: -64.12, lat: 54.57 },
    { lng: -64.15, lat: 54.63 },
    { lng: -64.21, lat: 54.66 },
    { lng: -64.22, lat: 54.68 },
    { lng: -64.3, lat: 54.69 },
    { lng: -64.34, lat: 54.73 },
    { lng: -64.41, lat: 54.75 },
    { lng: -64.44, lat: 54.75 },
    { lng: -64.45, lat: 54.72 },
    { lng: -64.5, lat: 54.71 },
    { lng: -64.55, lat: 54.67 },
    { lng: -64.59, lat: 54.69 },
    { lng: -64.78, lat: 54.69 },
    { lng: -64.84, lat: 54.74 },
    { lng: -64.82, lat: 54.8 },
    { lng: -64.88, lat: 54.8 },
    { lng: -64.91, lat: 54.83 },
    { lng: -64.9, lat: 54.86 },
    { lng: -64.97, lat: 54.9 },
    { lng: -65.02, lat: 54.89 },
    { lng: -65.08, lat: 54.92 },
    { lng: -65.11, lat: 54.89 },
    { lng: -65.15, lat: 54.88 },
    { lng: -65.16, lat: 54.84 },
    { lng: -65.19, lat: 54.81 },
    { lng: -65.25, lat: 54.81 },
    { lng: -65.28, lat: 54.78 },
    { lng: -65.39, lat: 54.78 },
    { lng: -65.39, lat: 54.73 },
    { lng: -65.44, lat: 54.69 },
    { lng: -65.63, lat: 54.7 },
    { lng: -65.68, lat: 54.67 },
    { lng: -65.71, lat: 54.67 },
    { lng: -65.81, lat: 54.78 },
    { lng: -65.83, lat: 54.78 },
    { lng: -65.89, lat: 54.83 },
    { lng: -65.9, lat: 54.89 },
    { lng: -65.94, lat: 54.9 },
    { lng: -66.06, lat: 54.88 },
    { lng: -66.15, lat: 54.94 },
    { lng: -66.27, lat: 54.94 },
    { lng: -66.31, lat: 55 },
    { lng: -66.33, lat: 55 },
    { lng: -66.37, lat: 55.05 },
    { lng: -66.46, lat: 55.1 },
    { lng: -66.52, lat: 55.16 },
    { lng: -66.54, lat: 55.16 },
    { lng: -66.56, lat: 55.2 },
    { lng: -66.59, lat: 55.2 },
    { lng: -66.61, lat: 55.23 },
    { lng: -66.65, lat: 55.22 },
    { lng: -66.63, lat: 55.2 },
    { lng: -66.64, lat: 55.15 },
    { lng: -66.71, lat: 55.15 },
    { lng: -66.71, lat: 55.13 },
    { lng: -66.59, lat: 55.01 },
    { lng: -66.59, lat: 54.96 },
    { lng: -66.61, lat: 54.94 },
    { lng: -66.64, lat: 54.94 },
    { lng: -66.64, lat: 54.91 },
    { lng: -66.56, lat: 54.83 },
    { lng: -66.56, lat: 54.8 },
    { lng: -66.6, lat: 54.75 },
    { lng: -66.63, lat: 54.74 },
    { lng: -66.62, lat: 54.72 },
    { lng: -66.65, lat: 54.67 },
    { lng: -66.7, lat: 54.67 },
    { lng: -66.71, lat: 54.69 },
    { lng: -66.85, lat: 54.75 },
    { lng: -66.86, lat: 54.77 },
    { lng: -66.93, lat: 54.76 },
    { lng: -67.05, lat: 54.82 },
    { lng: -67.1, lat: 54.88 },
    { lng: -67.31, lat: 55 },
    { lng: -67.31, lat: 55.05 },
    { lng: -67.38, lat: 55.05 },
    { lng: -67.4, lat: 55.03 },
    { lng: -67.37, lat: 55.02 },
    { lng: -67.36, lat: 54.98 },
    { lng: -67.34, lat: 54.98 },
    { lng: -67.22, lat: 54.86 },
    { lng: -67.2, lat: 54.86 },
    { lng: -67.17, lat: 54.81 },
    { lng: -67.06, lat: 54.75 },
    { lng: -67.03, lat: 54.72 },
    { lng: -67.02, lat: 54.68 },
    { lng: -67.06, lat: 54.63 },
    { lng: -67.09, lat: 54.63 },
    { lng: -67.11, lat: 54.59 },
    { lng: -67.2, lat: 54.57 },
    { lng: -67.19, lat: 54.5 },
    { lng: -67.21, lat: 54.47 },
    { lng: -67.25, lat: 54.45 },
    { lng: -67.3, lat: 54.45 },
    { lng: -67.32, lat: 54.47 },
    { lng: -67.34, lat: 54.46 },
    { lng: -67.43, lat: 54.52 },
    { lng: -67.48, lat: 54.44 },
    { lng: -67.56, lat: 54.45 },
    { lng: -67.57, lat: 54.43 },
    { lng: -67.64, lat: 54.43 },
    { lng: -67.68, lat: 54.41 },
    { lng: -67.69, lat: 54.36 },
    { lng: -67.67, lat: 54.36 },
    { lng: -67.64, lat: 54.33 },
    { lng: -67.63, lat: 54.26 },
    { lng: -67.58, lat: 54.22 },
    { lng: -67.58, lat: 54.17 },
    { lng: -67.63, lat: 54.12 },
    { lng: -67.71, lat: 54.12 },
    { lng: -67.72, lat: 54.07 },
    { lng: -67.75, lat: 54.04 },
    { lng: -67.66, lat: 54.01 },
    { lng: -67.63, lat: 53.97 },
    { lng: -67.58, lat: 53.96 },
    { lng: -67.53, lat: 53.88 },
    { lng: -67.47, lat: 53.87 },
    { lng: -67.45, lat: 53.83 },
    { lng: -67.48, lat: 53.79 },
    { lng: -67.46, lat: 53.76 },
    { lng: -67.42, lat: 53.77 },
    { lng: -67.39, lat: 53.76 },
    { lng: -67.34, lat: 53.63 },
    { lng: -67.29, lat: 53.62 },
    { lng: -67.27, lat: 53.59 },
    { lng: -67.25, lat: 53.6 },
    { lng: -67.23, lat: 53.58 },
    { lng: -67.16, lat: 53.59 },
    { lng: -67.14, lat: 53.57 },
    { lng: -67.02, lat: 53.57 },
    { lng: -66.99, lat: 53.54 },
    { lng: -66.91, lat: 53.51 },
    { lng: -66.89, lat: 53.46 },
    { lng: -66.85, lat: 53.43 },
    { lng: -66.86, lat: 53.38 },
    { lng: -66.88, lat: 53.36 },
    { lng: -66.92, lat: 53.36 },
    { lng: -66.93, lat: 53.34 },
    { lng: -66.91, lat: 53.3 },
    { lng: -66.94, lat: 53.24 },
    { lng: -66.92, lat: 53.2 },
    { lng: -66.93, lat: 53.16 },
    { lng: -66.92, lat: 53.11 },
    { lng: -66.95, lat: 53.1 },
    { lng: -66.94, lat: 53.09 },
    { lng: -66.96, lat: 53.05 },
    { lng: -67.08, lat: 53.04 },
    { lng: -67.11, lat: 53.07 },
    { lng: -67.14, lat: 53.07 },
    { lng: -67.16, lat: 53.1 },
    { lng: -67.24, lat: 53.12 },
    { lng: -67.32, lat: 53.08 },
    { lng: -67.35, lat: 53.08 },
    { lng: -67.32, lat: 53.02 },
    { lng: -67.23, lat: 53.02 },
    { lng: -67.2, lat: 52.98 },
    { lng: -67.23, lat: 52.94 },
    { lng: -67.27, lat: 52.92 },
    { lng: -67.21, lat: 52.88 },
    { lng: -67.06, lat: 52.92 },
    { lng: -67.01, lat: 52.87 },
    { lng: -67.02, lat: 52.8 },
    { lng: -66.94, lat: 52.8 },
    { lng: -66.91, lat: 52.77 },
    { lng: -66.87, lat: 52.81 },
    { lng: -66.83, lat: 52.8 },
    { lng: -66.82, lat: 52.83 },
    { lng: -66.8, lat: 52.84 },
    { lng: -66.76, lat: 52.84 },
    { lng: -66.72, lat: 52.81 },
    { lng: -66.69, lat: 52.86 },
    { lng: -66.7, lat: 52.91 },
    { lng: -66.67, lat: 52.97 },
    { lng: -66.65, lat: 52.99 },
    { lng: -66.56, lat: 52.98 },
    { lng: -66.51, lat: 53.03 },
    { lng: -66.52, lat: 53.04 },
    { lng: -66.5, lat: 53.08 },
    { lng: -66.46, lat: 53.1 },
    { lng: -66.38, lat: 53.06 },
    { lng: -66.35, lat: 53.06 },
    { lng: -66.29, lat: 52.99 },
    { lng: -66.29, lat: 52.96 },
    { lng: -66.25, lat: 52.94 },
    { lng: -66.22, lat: 52.89 },
    { lng: -66.22, lat: 52.85 },
    { lng: -66.24, lat: 52.82 },
    { lng: -66.3, lat: 52.79 },
    { lng: -66.27, lat: 52.74 },
    { lng: -66.28, lat: 52.69 },
    { lng: -66.24, lat: 52.65 },
    { lng: -66.24, lat: 52.6 },
    { lng: -66.28, lat: 52.56 },
    { lng: -66.34, lat: 52.57 },
    { lng: -66.32, lat: 52.51 },
    { lng: -66.32, lat: 52.43 },
    { lng: -66.3, lat: 52.41 },
    { lng: -66.31, lat: 52.34 },
    { lng: -66.25, lat: 52.35 },
    { lng: -66.18, lat: 52.27 },
    { lng: -66.13, lat: 52.27 },
    { lng: -66.09, lat: 52.24 },
    { lng: -66.04, lat: 52.18 },
    { lng: -66.03, lat: 52.12 },
    { lng: -66, lat: 52.12 },
    { lng: -65.98, lat: 52.14 },
    { lng: -65.91, lat: 52.13 },
    { lng: -65.86, lat: 52.16 },
    { lng: -65.73, lat: 52.14 },
    { lng: -65.66, lat: 52.16 },
    { lng: -65.63, lat: 52.14 },
    { lng: -65.61, lat: 52.08 },
    { lng: -65.57, lat: 52.07 },
    { lng: -65.57, lat: 52.09 },
    { lng: -65.54, lat: 52.11 },
    { lng: -65.52, lat: 52.15 },
    { lng: -65.48, lat: 52.15 },
    { lng: -65.45, lat: 52.12 },
    { lng: -65.45, lat: 52.07 },
    { lng: -65.43, lat: 52.04 },
    { lng: -65.36, lat: 52.04 },
    { lng: -65.32, lat: 52 },
    { lng: -65.31, lat: 51.96 },
    { lng: -65.32, lat: 51.91 },
    { lng: -65.25, lat: 51.91 },
    { lng: -65.21, lat: 51.86 },
    { lng: -65.15, lat: 51.86 },
    { lng: -65.13, lat: 51.84 },
    { lng: -65.12, lat: 51.79 },
    { lng: -65.07, lat: 51.81 },
    { lng: -65.01, lat: 51.81 },
    { lng: -64.97, lat: 51.78 },
    { lng: -64.94, lat: 51.82 },
    { lng: -64.79, lat: 51.82 },
    { lng: -64.77, lat: 51.8 },
    { lng: -64.71, lat: 51.8 },
    { lng: -64.67, lat: 51.78 },
    { lng: -64.66, lat: 51.74 },
    { lng: -64.64, lat: 51.75 },
    { lng: -64.6, lat: 51.72 },
    { lng: -64.58, lat: 51.66 },
    { lng: -64.55, lat: 51.63 },
    { lng: -64.47, lat: 51.66 },
    { lng: -64.46, lat: 51.7 },
    { lng: -64.43, lat: 51.72 },
    { lng: -64.39, lat: 51.7 },
    { lng: -64.34, lat: 51.73 },
    { lng: -64.35, lat: 51.79 },
    { lng: -64.39, lat: 51.83 },
    { lng: -64.4, lat: 52.02 },
    { lng: -64.36, lat: 52.05 },
    { lng: -64.33, lat: 52.05 },
    { lng: -64.33, lat: 52.1 },
    { lng: -64.29, lat: 52.12 },
    { lng: -64.29, lat: 52.16 },
    { lng: -64.25, lat: 52.18 },
    { lng: -64.27, lat: 52.2 },
    { lng: -64.27, lat: 52.25 },
    { lng: -64.29, lat: 52.28 },
    { lng: -64.26, lat: 52.33 },
    { lng: -64.21, lat: 52.36 },
    { lng: -64.2, lat: 52.39 },
    { lng: -64.17, lat: 52.4 },
    { lng: -64.19, lat: 52.5 },
    { lng: -64.26, lat: 52.55 },
    { lng: -64.27, lat: 52.58 },
    { lng: -64.25, lat: 52.62 },
    { lng: -64.21, lat: 52.63 },
    { lng: -64.2, lat: 52.65 },
    { lng: -64.21, lat: 52.7 },
    { lng: -64.19, lat: 52.71 },
    { lng: -64.17, lat: 52.76 },
    { lng: -64.09, lat: 52.78 },
    { lng: -63.96, lat: 52.77 },
    { lng: -63.95, lat: 52.79 },
    { lng: -63.89, lat: 52.81 },
    { lng: -63.76, lat: 52.81 },
    { lng: -63.74, lat: 52.84 },
    { lng: -63.72, lat: 52.84 },
    { lng: -63.7, lat: 52.89 },
    { lng: -63.66, lat: 52.89 },
    { lng: -63.63, lat: 52.92 },
    { lng: -63.59, lat: 52.91 },
    { lng: -63.56, lat: 52.88 },
    { lng: -63.56, lat: 52.8 },
    { lng: -63.53, lat: 52.77 },
    { lng: -63.45, lat: 52.76 },
    { lng: -63.36, lat: 52.71 },
    { lng: -63.34, lat: 52.65 },
    { lng: -63.4, lat: 52.61 },
    { lng: -63.62, lat: 52.61 },
    { lng: -63.69, lat: 52.59 },
    { lng: -63.76, lat: 52.6 },
    { lng: -63.95, lat: 52.56 },
    { lng: -64.01, lat: 52.53 },
    { lng: -64.04, lat: 52.46 },
    { lng: -63.99, lat: 52.43 },
    { lng: -63.98, lat: 52.4 },
    { lng: -63.95, lat: 52.4 },
    { lng: -63.93, lat: 52.38 },
    { lng: -63.89, lat: 52.38 },
    { lng: -63.87, lat: 52.36 },
    { lng: -63.76, lat: 52.37 },
    { lng: -63.72, lat: 52.35 },
    { lng: -63.7, lat: 52.33 },
    { lng: -63.71, lat: 52.25 },
    { lng: -63.67, lat: 52.21 },
    { lng: -63.65, lat: 52.11 },
    { lng: -63.61, lat: 52.07 },
    { lng: -63.61, lat: 52.04 },
    { lng: -57.09, lat: 52.04 },
    { lng: -57.07, lat: 52.02 },
    { lng: -57.07, lat: 51.19 },
    { lng: -57.1, lat: 51.16 },
    { lng: -57.72, lat: 50.96 },
    { lng: -58.91, lat: 49.82 },
    { lng: -60.44, lat: 48.76 },
    { lng: -60.36, lat: 47.76 },
    { lng: -60.74, lat: 47.39 },
    { lng: -60.97, lat: 47.3 },
    { lng: -61.32, lat: 46.99 },
    { lng: -61.36, lat: 46.82 },
    { lng: -61.38, lat: 46.8 },
    { lng: -62.32, lat: 46.8 },
    { lng: -63, lat: 47.1 },
    { lng: -63.81, lat: 48.18 },
    { lng: -64.41, lat: 48.18 },
    { lng: -65.1, lat: 47.88 },
    { lng: -65.53, lat: 47.79 },
    { lng: -65.57, lat: 47.81 },
    { lng: -65.63, lat: 47.9 },
    { lng: -65.83, lat: 47.97 },
    { lng: -66.16, lat: 48.01 },
    { lng: -66.31, lat: 47.99 },
    { lng: -66.37, lat: 48.05 },
    { lng: -66.46, lat: 48.05 },
    { lng: -66.51, lat: 48.01 },
    { lng: -66.66, lat: 47.97 },
    { lng: -66.73, lat: 47.97 },
    { lng: -66.77, lat: 47.95 },
    { lng: -66.9, lat: 47.95 },
    { lng: -66.9, lat: 47.9 },
    { lng: -66.94, lat: 47.85 },
    { lng: -66.99, lat: 47.85 },
    { lng: -67.05, lat: 47.89 },
    { lng: -67.17, lat: 47.84 },
    { lng: -67.31, lat: 47.85 },
    { lng: -67.33, lat: 47.81 },
    { lng: -67.36, lat: 47.8 },
    { lng: -67.41, lat: 47.81 },
    { lng: -67.43, lat: 47.84 },
    { lng: -67.48, lat: 47.85 },
    { lng: -67.5, lat: 47.87 },
    { lng: -67.62, lat: 47.89 },
    { lng: -67.65, lat: 47.93 },
    { lng: -67.65, lat: 47.96 },
    { lng: -68.08, lat: 47.96 },
    { lng: -68.08, lat: 47.91 },
    { lng: -68.1, lat: 47.88 },
    { lng: -68.34, lat: 47.88 },
    { lng: -68.34, lat: 47.55 },
    { lng: -68.36, lat: 47.52 },
    { lng: -68.5, lat: 47.42 },
    { lng: -68.57, lat: 47.38 },
    { lng: -68.72, lat: 47.33 },
    { lng: -69.04, lat: 47.26 },
    { lng: -69.07, lat: 47.26 },
    { lng: -69.09, lat: 47.28 },
    { lng: -69.1, lat: 47.39 },
    { lng: -69.22, lat: 47.41 },
    { lng: -69.96, lat: 46.68 },
    { lng: -70.02, lat: 46.39 },
    { lng: -70.13, lat: 46.32 },
    { lng: -70.16, lat: 46.32 },
    { lng: -70.17, lat: 46.28 },
    { lng: -70.21, lat: 46.25 },
    { lng: -70.23, lat: 46.21 },
    { lng: -70.23, lat: 46.19 },
    { lng: -70.2, lat: 46.17 },
    { lng: -70.21, lat: 46.09 },
    { lng: -70.25, lat: 46.03 },
    { lng: -70.24, lat: 45.99 },
    { lng: -70.2, lat: 45.96 },
    { lng: -70.22, lat: 45.87 },
    { lng: -70.36, lat: 45.79 },
    { lng: -70.34, lat: 45.73 },
    { lng: -70.37, lat: 45.69 },
    { lng: -70.47, lat: 45.66 },
    { lng: -70.5, lat: 45.63 },
    { lng: -70.54, lat: 45.63 },
    { lng: -70.56, lat: 45.6 },
    { lng: -70.64, lat: 45.56 },
    { lng: -70.64, lat: 45.53 },
    { lng: -70.67, lat: 45.5 },
    { lng: -70.59, lat: 45.44 },
    { lng: -70.58, lat: 45.41 },
    { lng: -70.59, lat: 45.37 },
    { lng: -70.63, lat: 45.34 },
    { lng: -70.73, lat: 45.35 },
    { lng: -70.76, lat: 45.37 },
    { lng: -70.77, lat: 45.29 },
    { lng: -70.8, lat: 45.26 },
    { lng: -70.81, lat: 45.2 },
    { lng: -70.83, lat: 45.19 },
    { lng: -70.89, lat: 45.19 },
    { lng: -70.93, lat: 45.21 },
    { lng: -70.94, lat: 45.25 },
    { lng: -70.96, lat: 45.26 },
    { lng: -70.96, lat: 45.29 },
    { lng: -71.07, lat: 45.27 },
    { lng: -71.09, lat: 45.23 },
    { lng: -71.13, lat: 45.2 },
    { lng: -71.25, lat: 45.21 },
    { lng: -71.3, lat: 45.25 },
    { lng: -71.34, lat: 45.21 },
    { lng: -71.36, lat: 45.21 },
    { lng: -71.36, lat: 45.18 },
    { lng: -71.39, lat: 45.15 },
    { lng: -71.39, lat: 45.1 },
    { lng: -71.45, lat: 45.05 },
    { lng: -71.47, lat: 44.98 },
    { lng: -71.49, lat: 44.97 },
    { lng: -72.33, lat: 44.96 },
    { lng: -72.66, lat: 44.98 },
    { lng: -72.94, lat: 44.97 },
    { lng: -73.09, lat: 44.98 },
    { lng: -74.05, lat: 44.95 },
    { lng: -74.68, lat: 44.96 },
    { lng: -74.71, lat: 44.99 },
    { lng: -74.71, lat: 45.02 },
    { lng: -74.67, lat: 45.06 },
    { lng: -74.58, lat: 45.08 },
    { lng: -74.5, lat: 45.12 },
    { lng: -74.46, lat: 45.17 },
    { lng: -74.39, lat: 45.19 },
    { lng: -74.51, lat: 45.28 },
    { lng: -74.51, lat: 45.33 },
    { lng: -74.43, lat: 45.53 },
    { lng: -74.48, lat: 45.56 },
    { lng: -74.54, lat: 45.55 },
    { lng: -74.64, lat: 45.6 },
    { lng: -74.7, lat: 45.59 },
    { lng: -74.93, lat: 45.61 },
    { lng: -74.94, lat: 45.59 },
    { lng: -75.03, lat: 45.55 },
    { lng: -75.09, lat: 45.54 },
    { lng: -75.23, lat: 45.55 },
    { lng: -75.31, lat: 45.5 },
    { lng: -75.38, lat: 45.48 },
    { lng: -75.46, lat: 45.48 },
    { lng: -75.57, lat: 45.43 },
    { lng: -75.63, lat: 45.43 },
    { lng: -75.8, lat: 45.33 },
    { lng: -75.9, lat: 45.35 },
    { lng: -75.99, lat: 45.44 },
    { lng: -76.1, lat: 45.48 },
    { lng: -76.2, lat: 45.48 },
    { lng: -76.2, lat: 45.45 },
    { lng: -76.22, lat: 45.43 },
    { lng: -76.39, lat: 45.42 },
    { lng: -76.45, lat: 45.46 },
    { lng: -76.62, lat: 45.49 },
    { lng: -76.68, lat: 45.52 },
    { lng: -76.71, lat: 45.56 },
    { lng: -76.71, lat: 45.61 },
    { lng: -76.75, lat: 45.64 },
    { lng: -76.75, lat: 45.68 },
    { lng: -76.81, lat: 45.72 },
    { lng: -76.82, lat: 45.76 },
    { lng: -76.81, lat: 45.84 },
    { lng: -76.83, lat: 45.85 },
    { lng: -76.86, lat: 45.85 },
    { lng: -76.87, lat: 45.79 },
    { lng: -76.9, lat: 45.76 },
    { lng: -77.01, lat: 45.75 },
    { lng: -77.03, lat: 45.77 },
    { lng: -77.07, lat: 45.77 },
    { lng: -77.09, lat: 45.8 },
    { lng: -77.14, lat: 45.8 },
    { lng: -77.16, lat: 45.82 },
    { lng: -77.23, lat: 45.84 },
    { lng: -77.27, lat: 45.9 },
    { lng: -77.31, lat: 45.92 },
    { lng: -77.32, lat: 45.97 },
    { lng: -77.35, lat: 46.01 },
    { lng: -77.59, lat: 46.13 },
    { lng: -77.92, lat: 46.18 },
    { lng: -77.99, lat: 46.21 },
    { lng: -78.06, lat: 46.2 },
    { lng: -78.09, lat: 46.22 },
    { lng: -78.13, lat: 46.22 },
    { lng: -78.15, lat: 46.24 },
    { lng: -78.25, lat: 46.24 },
    { lng: -78.27, lat: 46.22 },
    { lng: -78.33, lat: 46.21 },
    { lng: -78.42, lat: 46.26 },
    { lng: -78.51, lat: 46.25 },
    { lng: -78.58, lat: 46.28 },
    { lng: -78.74, lat: 46.29 },
    { lng: -78.77, lat: 46.33 },
    { lng: -78.77, lat: 46.36 },
    { lng: -78.91, lat: 46.42 },
    { lng: -79.03, lat: 46.53 },
    { lng: -79.05, lat: 46.61 },
    { lng: -79.11, lat: 46.64 },
    { lng: -79.13, lat: 46.69 },
    { lng: -79.16, lat: 46.71 },
    { lng: -79.16, lat: 46.74 },
    { lng: -79.19, lat: 46.79 },
    { lng: -79.23, lat: 46.79 },
    { lng: -79.36, lat: 46.92 },
    { lng: -79.38, lat: 46.98 },
    { lng: -79.47, lat: 47.06 },
    { lng: -79.49, lat: 47.11 },
    { lng: -79.47, lat: 47.17 },
    { lng: -79.48, lat: 47.19 },
    { lng: -79.47, lat: 47.24 },
    { lng: -79.53, lat: 47.29 },
    { lng: -79.54, lat: 47.32 },
    { lng: -79.61, lat: 47.38 },
    { lng: -79.63, lat: 47.42 },
    { lng: -79.63, lat: 47.46 },
    { lng: -79.6, lat: 47.49 },
    { lng: -79.59, lat: 47.54 },
    { lng: -79.56, lat: 47.55 },
    { lng: -79.55, lat: 51.48 },
    { lng: -79.59, lat: 51.56 },
    { lng: -79.56, lat: 51.6 },
    { lng: -79.39, lat: 51.71 },
    { lng: -79.3, lat: 51.72 },
    { lng: -79.27, lat: 51.7 },
    { lng: -79.19, lat: 51.69 },
    { lng: -79.18, lat: 51.62 },
    { lng: -79.2, lat: 51.58 },
    { lng: -79.09, lat: 51.58 },
    { lng: -78.98, lat: 51.52 },
    { lng: -78.94, lat: 51.48 },
    { lng: -78.93, lat: 51.42 },
    { lng: -78.88, lat: 51.46 },
    { lng: -78.86, lat: 51.48 },
    { lng: -78.89, lat: 51.51 },
    { lng: -78.9, lat: 51.58 },
    { lng: -79, lat: 51.64 },
    { lng: -79.03, lat: 51.71 },
    { lng: -79.09, lat: 51.74 },
    { lng: -79.09, lat: 51.79 },
    { lng: -79.02, lat: 51.84 },
    { lng: -78.91, lat: 51.86 },
    { lng: -78.9, lat: 51.87 },
    { lng: -78.93, lat: 51.89 },
    { lng: -78.94, lat: 51.92 },
    { lng: -78.93, lat: 51.95 },
    { lng: -78.87, lat: 51.99 },
    { lng: -78.76, lat: 52.02 },
    { lng: -78.76, lat: 52.08 },
    { lng: -78.65, lat: 52.14 },
    { lng: -78.61, lat: 52.18 },
    { lng: -78.63, lat: 52.23 },
    { lng: -78.63, lat: 52.29 },
    { lng: -78.58, lat: 52.34 },
    { lng: -78.59, lat: 52.35 },
    { lng: -78.58, lat: 52.45 },
    { lng: -78.59, lat: 52.47 },
    { lng: -78.64, lat: 52.46 },
    { lng: -78.68, lat: 52.49 },
    { lng: -78.76, lat: 52.5 },
    { lng: -78.81, lat: 52.53 },
    { lng: -78.8, lat: 52.58 },
    { lng: -78.85, lat: 52.6 },
    { lng: -78.87, lat: 52.68 },
    { lng: -78.92, lat: 52.73 },
    { lng: -78.91, lat: 52.76 },
    { lng: -78.85, lat: 52.82 },
    { lng: -78.88, lat: 52.81 },
    { lng: -78.92, lat: 52.83 },
    { lng: -78.93, lat: 52.87 },
    { lng: -78.96, lat: 52.89 },
    { lng: -78.95, lat: 52.94 },
    { lng: -79, lat: 52.96 },
    { lng: -79.04, lat: 53.01 },
    { lng: -79.03, lat: 53.1 },
    { lng: -79.01, lat: 53.11 },
    { lng: -79.02, lat: 53.18 },
    { lng: -78.99, lat: 53.21 },
    { lng: -79.01, lat: 53.29 },
    { lng: -79.04, lat: 53.31 },
    { lng: -79.04, lat: 53.35 },
    { lng: -79.02, lat: 53.38 },
    { lng: -79.08, lat: 53.37 },
    { lng: -79.11, lat: 53.42 },
    { lng: -79.16, lat: 53.45 },
    { lng: -79.18, lat: 53.51 },
    { lng: -79.16, lat: 53.54 },
    { lng: -79.11, lat: 53.56 },
    { lng: -79.12, lat: 53.59 },
    { lng: -79.11, lat: 53.65 },
    { lng: -79.16, lat: 53.65 },
    { lng: -79.2, lat: 53.68 },
    { lng: -79.2, lat: 53.72 },
    { lng: -79.17, lat: 53.75 },
    { lng: -79.13, lat: 53.76 },
    { lng: -79.12, lat: 53.81 },
    { lng: -79.1, lat: 53.83 },
    { lng: -79.14, lat: 53.87 },
    { lng: -79.15, lat: 53.91 },
    { lng: -79.18, lat: 53.91 },
    { lng: -79.2, lat: 53.95 },
    { lng: -79.19, lat: 53.98 },
    { lng: -79.14, lat: 53.99 },
    { lng: -79.14, lat: 54.01 },
    { lng: -79.21, lat: 54.05 },
    { lng: -79.22, lat: 54.1 },
    { lng: -79.28, lat: 54.1 },
    { lng: -79.31, lat: 54.13 },
    { lng: -79.4, lat: 54.13 },
    { lng: -79.44, lat: 54.16 },
    { lng: -79.44, lat: 54.21 },
    { lng: -79.42, lat: 54.23 },
    { lng: -79.45, lat: 54.23 },
    { lng: -79.48, lat: 54.26 },
    { lng: -79.52, lat: 54.27 },
    { lng: -79.53, lat: 54.33 },
    { lng: -79.55, lat: 54.33 },
    { lng: -79.56, lat: 54.35 },
    { lng: -79.55, lat: 54.42 },
    { lng: -79.58, lat: 54.45 },
    { lng: -79.59, lat: 54.51 },
    { lng: -79.63, lat: 54.53 },
    { lng: -79.63, lat: 54.57 },
    { lng: -79.69, lat: 54.58 },
  ],
  // NEW BRUNSWICK
  NB: [
    { lng: -69.075, lat: 47.295 },
    { lng: -69.06, lat: 47.32 },
    { lng: -68.805, lat: 47.37 },
    { lng: -68.59, lat: 47.44 },
    { lng: -68.43, lat: 47.545 },
    { lng: -68.405, lat: 47.565 },
    { lng: -68.405, lat: 47.88 },
    { lng: -68.395, lat: 47.935 },
    { lng: -68.145, lat: 47.935 },
    { lng: -68.145, lat: 48 },
    { lng: -68.13, lat: 48.02 },
    { lng: -67.59, lat: 48.02 },
    { lng: -67.575, lat: 48.005 },
    { lng: -67.575, lat: 47.945 },
    { lng: -67.505, lat: 47.935 },
    { lng: -67.46, lat: 47.91 },
    { lng: -67.38, lat: 47.885 },
    { lng: -67.37, lat: 47.875 },
    { lng: -67.37, lat: 47.9 },
    { lng: -67.36, lat: 47.915 },
    { lng: -67.315, lat: 47.91 },
    { lng: -67.305, lat: 47.92 },
    { lng: -67.28, lat: 47.92 },
    { lng: -67.27, lat: 47.91 },
    { lng: -67.215, lat: 47.905 },
    { lng: -67.205, lat: 47.895 },
    { lng: -67.175, lat: 47.915 },
    { lng: -67.155, lat: 47.915 },
    { lng: -67.15, lat: 47.93 },
    { lng: -67.14, lat: 47.935 },
    { lng: -67.08, lat: 47.94 },
    { lng: -67.07, lat: 47.955 },
    { lng: -67.045, lat: 47.955 },
    { lng: -67.03, lat: 47.94 },
    { lng: -66.99, lat: 47.925 },
    { lng: -66.985, lat: 47.95 },
    { lng: -66.965, lat: 47.965 },
    { lng: -66.96, lat: 47.985 },
    { lng: -66.93, lat: 48.005 },
    { lng: -66.89, lat: 48.015 },
    { lng: -66.885, lat: 48.01 },
    { lng: -66.87, lat: 48.02 },
    { lng: -66.78, lat: 48.015 },
    { lng: -66.735, lat: 48.03 },
    { lng: -66.7, lat: 48.025 },
    { lng: -66.675, lat: 48.04 },
    { lng: -66.66, lat: 48.035 },
    { lng: -66.535, lat: 48.065 },
    { lng: -66.525, lat: 48.085 },
    { lng: -66.505, lat: 48.1 },
    { lng: -66.38, lat: 48.11 },
    { lng: -66.335, lat: 48.095 },
    { lng: -66.315, lat: 48.08 },
    { lng: -66.3, lat: 48.055 },
    { lng: -66.19, lat: 48.07 },
    { lng: -65.995, lat: 48.055 },
    { lng: -65.985, lat: 48.045 },
    { lng: -65.985, lat: 47.95 },
    { lng: -65.795, lat: 47.955 },
    { lng: -65.73, lat: 47.895 },
    { lng: -65.685, lat: 47.84 },
    { lng: -65.67, lat: 47.79 },
    { lng: -65.525, lat: 47.71 },
    { lng: -65.415, lat: 47.75 },
    { lng: -65.385, lat: 47.775 },
    { lng: -65.315, lat: 47.795 },
    { lng: -65.225, lat: 47.845 },
    { lng: -64.97, lat: 47.885 },
    { lng: -64.53, lat: 48.055 },
    { lng: -64.395, lat: 48.05 },
    { lng: -64.38, lat: 48.04 },
    { lng: -64.38, lat: 48.025 },
    { lng: -64.41, lat: 47.92 },
    { lng: -64.455, lat: 47.815 },
    { lng: -64.605, lat: 47.705 },
    { lng: -64.75, lat: 47.64 },
    { lng: -64.82, lat: 47.54 },
    { lng: -64.845, lat: 47.45 },
    { lng: -64.855, lat: 47.265 },
    { lng: -64.97, lat: 47.14 },
    { lng: -64.93, lat: 47.11 },
    { lng: -64.77, lat: 47.095 },
    { lng: -64.755, lat: 47.075 },
    { lng: -64.78, lat: 47.04 },
    { lng: -64.775, lat: 47.01 },
    { lng: -64.79, lat: 46.96 },
    { lng: -64.855, lat: 46.915 },
    { lng: -64.87, lat: 46.88 },
    { lng: -64.865, lat: 46.825 },
    { lng: -64.845, lat: 46.815 },
    { lng: -64.8, lat: 46.765 },
    { lng: -64.685, lat: 46.705 },
    { lng: -64.675, lat: 46.675 },
    { lng: -64.68, lat: 46.63 },
    { lng: -64.67, lat: 46.595 },
    { lng: -64.61, lat: 46.55 },
    { lng: -64.58, lat: 46.51 },
    { lng: -64.57, lat: 46.47 },
    { lng: -64.54, lat: 46.415 },
    { lng: -64.54, lat: 46.39 },
    { lng: -64.48, lat: 46.375 },
    { lng: -64.46, lat: 46.34 },
    { lng: -64.455, lat: 46.315 },
    { lng: -64.46, lat: 46.295 },
    { lng: -64.425, lat: 46.26 },
    { lng: -64.405, lat: 46.26 },
    { lng: -64.38, lat: 46.245 },
    { lng: -64.275, lat: 46.26 },
    { lng: -64.15, lat: 46.235 },
    { lng: -64.14, lat: 46.225 },
    { lng: -64.06, lat: 46.25 },
    { lng: -63.88, lat: 46.24 },
    { lng: -63.74, lat: 46.25 },
    { lng: -63.595, lat: 46.1 },
    { lng: -63.595, lat: 46.085 },
    { lng: -63.605, lat: 46.075 },
    { lng: -63.64, lat: 46.065 },
    { lng: -63.73, lat: 46.06 },
    { lng: -64.02, lat: 46.005 },
    { lng: -64.03, lat: 45.965 },
    { lng: -64.045, lat: 45.955 },
    { lng: -64.145, lat: 45.96 },
    { lng: -64.15, lat: 45.945 },
    { lng: -64.205, lat: 45.885 },
    { lng: -64.24, lat: 45.865 },
    { lng: -64.265, lat: 45.815 },
    { lng: -64.29, lat: 45.81 },
    { lng: -64.325, lat: 45.835 },
    { lng: -64.34, lat: 45.835 },
    { lng: -64.34, lat: 45.825 },
    { lng: -64.39, lat: 45.775 },
    { lng: -64.445, lat: 45.735 },
    { lng: -64.46, lat: 45.695 },
    { lng: -64.49, lat: 45.675 },
    { lng: -64.535, lat: 45.665 },
    { lng: -64.805, lat: 45.515 },
    { lng: -64.95, lat: 45.515 },
    { lng: -65.165, lat: 45.48 },
    { lng: -65.175, lat: 45.465 },
    { lng: -65.32, lat: 45.43 },
    { lng: -65.35, lat: 45.405 },
    { lng: -65.41, lat: 45.385 },
    { lng: -65.44, lat: 45.36 },
    { lng: -65.5, lat: 45.335 },
    { lng: -65.55, lat: 45.285 },
    { lng: -65.66, lat: 45.26 },
    { lng: -65.725, lat: 45.225 },
    { lng: -65.785, lat: 45.235 },
    { lng: -65.86, lat: 45.185 },
    { lng: -65.91, lat: 45.165 },
    { lng: -65.925, lat: 45.165 },
    { lng: -65.955, lat: 45.19 },
    { lng: -65.98, lat: 45.18 },
    { lng: -66.02, lat: 45.19 },
    { lng: -66.135, lat: 45.165 },
    { lng: -66.205, lat: 45.12 },
    { lng: -66.31, lat: 45.1 },
    { lng: -66.45, lat: 45.035 },
    { lng: -66.47, lat: 45.035 },
    { lng: -66.535, lat: 45.075 },
    { lng: -66.585, lat: 45.075 },
    { lng: -66.645, lat: 45.055 },
    { lng: -66.67, lat: 44.955 },
    { lng: -66.72, lat: 44.91 },
    { lng: -66.805, lat: 44.925 },
    { lng: -66.865, lat: 44.945 },
    { lng: -66.865, lat: 44.92 },
    { lng: -66.71, lat: 44.78 },
    { lng: -66.66, lat: 44.715 },
    { lng: -66.65, lat: 44.63 },
    { lng: -66.655, lat: 44.595 },
    { lng: -66.76, lat: 44.535 },
    { lng: -66.845, lat: 44.575 },
    { lng: -66.935, lat: 44.585 },
    { lng: -66.935, lat: 44.66 },
    { lng: -66.92, lat: 44.7 },
    { lng: -66.91, lat: 44.785 },
    { lng: -66.935, lat: 44.805 },
    { lng: -66.975, lat: 44.81 },
    { lng: -66.995, lat: 44.835 },
    { lng: -67, lat: 44.885 },
    { lng: -66.99, lat: 44.9 },
    { lng: -67.04, lat: 44.945 },
    { lng: -67.115, lat: 45.065 },
    { lng: -67.125, lat: 45.095 },
    { lng: -67.17, lat: 45.145 },
    { lng: -67.24, lat: 45.145 },
    { lng: -67.27, lat: 45.17 },
    { lng: -67.275, lat: 45.14 },
    { lng: -67.285, lat: 45.13 },
    { lng: -67.305, lat: 45.125 },
    { lng: -67.305, lat: 45.115 },
    { lng: -67.35, lat: 45.105 },
    { lng: -67.39, lat: 45.135 },
    { lng: -67.415, lat: 45.14 },
    { lng: -67.425, lat: 45.15 },
    { lng: -67.425, lat: 45.17 },
    { lng: -67.445, lat: 45.18 },
    { lng: -67.455, lat: 45.215 },
    { lng: -67.48, lat: 45.23 },
    { lng: -67.495, lat: 45.26 },
    { lng: -67.505, lat: 45.265 },
    { lng: -67.51, lat: 45.29 },
    { lng: -67.495, lat: 45.31 },
    { lng: -67.475, lat: 45.315 },
    { lng: -67.475, lat: 45.335 },
    { lng: -67.45, lat: 45.355 },
    { lng: -67.45, lat: 45.385 },
    { lng: -67.49, lat: 45.41 },
    { lng: -67.505, lat: 45.455 },
    { lng: -67.525, lat: 45.48 },
    { lng: -67.525, lat: 45.495 },
    { lng: -67.51, lat: 45.51 },
    { lng: -67.455, lat: 45.53 },
    { lng: -67.445, lat: 45.555 },
    { lng: -67.455, lat: 45.58 },
    { lng: -67.475, lat: 45.58 },
    { lng: -67.5, lat: 45.565 },
    { lng: -67.61, lat: 45.585 },
    { lng: -67.63, lat: 45.6 },
    { lng: -67.655, lat: 45.595 },
    { lng: -67.69, lat: 45.615 },
    { lng: -67.71, lat: 45.645 },
    { lng: -67.725, lat: 45.64 },
    { lng: -67.765, lat: 45.65 },
    { lng: -67.775, lat: 45.66 },
    { lng: -67.815, lat: 45.66 },
    { lng: -67.84, lat: 45.69 },
    { lng: -67.84, lat: 45.7 },
    { lng: -67.825, lat: 45.71 },
    { lng: -67.825, lat: 45.805 },
    { lng: -67.815, lat: 45.82 },
    { lng: -67.795, lat: 45.83 },
    { lng: -67.825, lat: 45.86 },
    { lng: -67.825, lat: 45.89 },
    { lng: -67.79, lat: 45.915 },
    { lng: -67.8, lat: 45.93 },
    { lng: -67.81, lat: 47.055 },
    { lng: -67.905, lat: 47.095 },
    { lng: -67.91, lat: 47.115 },
    { lng: -67.96, lat: 47.16 },
    { lng: -67.97, lat: 47.185 },
    { lng: -68, lat: 47.19 },
    { lng: -68.02, lat: 47.215 },
    { lng: -68.09, lat: 47.245 },
    { lng: -68.1, lat: 47.26 },
    { lng: -68.145, lat: 47.275 },
    { lng: -68.175, lat: 47.31 },
    { lng: -68.22, lat: 47.32 },
    { lng: -68.24, lat: 47.335 },
    { lng: -68.265, lat: 47.33 },
    { lng: -68.285, lat: 47.34 },
    { lng: -68.295, lat: 47.335 },
    { lng: -68.35, lat: 47.34 },
    { lng: -68.365, lat: 47.325 },
    { lng: -68.355, lat: 47.28 },
    { lng: -68.375, lat: 47.265 },
    { lng: -68.41, lat: 47.27 },
    { lng: -68.42, lat: 47.26 },
    { lng: -68.44, lat: 47.265 },
    { lng: -68.45, lat: 47.26 },
    { lng: -68.47, lat: 47.265 },
    { lng: -68.475, lat: 47.275 },
    { lng: -68.52, lat: 47.275 },
    { lng: -68.55, lat: 47.26 },
    { lng: -68.575, lat: 47.265 },
    { lng: -68.58, lat: 47.24 },
    { lng: -68.62, lat: 47.22 },
    { lng: -68.67, lat: 47.215 },
    { lng: -68.68, lat: 47.225 },
    { lng: -68.72, lat: 47.22 },
    { lng: -68.76, lat: 47.2 },
    { lng: -68.81, lat: 47.195 },
    { lng: -68.85, lat: 47.17 },
    { lng: -68.89, lat: 47.16 },
    { lng: -68.915, lat: 47.16 },
    { lng: -68.945, lat: 47.185 },
    { lng: -68.945, lat: 47.18 },
    { lng: -68.97, lat: 47.185 },
    { lng: -68.985, lat: 47.2 },
    { lng: -69.05, lat: 47.225 },
    { lng: -69.07, lat: 47.245 },
    { lng: -69.075, lat: 47.295 },
  ],
  // PRINCE EDWARD ISLAND
  PE: [
    { lng: -64.465, lat: 46.665 },
    { lng: -64.465, lat: 46.69 },
    { lng: -64.405, lat: 46.77 },
    { lng: -64.32, lat: 46.83 },
    { lng: -64.24, lat: 46.93 },
    { lng: -64.13, lat: 47.005 },
    { lng: -64.075, lat: 47.03 },
    { lng: -64.01, lat: 47.085 },
    { lng: -63.96, lat: 47.085 },
    { lng: -63.95, lat: 47.035 },
    { lng: -63.96, lat: 46.755 },
    { lng: -63.81, lat: 46.655 },
    { lng: -63.69, lat: 46.595 },
    { lng: -63.6, lat: 46.6 },
    { lng: -63.555, lat: 46.59 },
    { lng: -63.47, lat: 46.555 },
    { lng: -63.44, lat: 46.525 },
    { lng: -63.33, lat: 46.52 },
    { lng: -63.305, lat: 46.51 },
    { lng: -63.26, lat: 46.47 },
    { lng: -63.235, lat: 46.46 },
    { lng: -63.075, lat: 46.445 },
    { lng: -62.94, lat: 46.46 },
    { lng: -62.865, lat: 46.455 },
    { lng: -62.735, lat: 46.465 },
    { lng: -62.64, lat: 46.485 },
    { lng: -62.49, lat: 46.495 },
    { lng: -62.385, lat: 46.485 },
    { lng: -62.295, lat: 46.49 },
    { lng: -62.215, lat: 46.505 },
    { lng: -62.13, lat: 46.505 },
    { lng: -61.97, lat: 46.485 },
    { lng: -61.945, lat: 46.46 },
    { lng: -61.945, lat: 46.445 },
    { lng: -61.98, lat: 46.415 },
    { lng: -62.13, lat: 46.345 },
    { lng: -62.15, lat: 46.345 },
    { lng: -62.18, lat: 46.325 },
    { lng: -62.28, lat: 46.31 },
    { lng: -62.325, lat: 46.315 },
    { lng: -62.35, lat: 46.17 },
    { lng: -62.375, lat: 46.12 },
    { lng: -62.38, lat: 46.055 },
    { lng: -62.4, lat: 46.015 },
    { lng: -62.43, lat: 45.98 },
    { lng: -62.48, lat: 45.955 },
    { lng: -62.485, lat: 45.845 },
    { lng: -62.78, lat: 45.84 },
    { lng: -62.9, lat: 45.86 },
    { lng: -62.96, lat: 45.88 },
    { lng: -63.055, lat: 45.94 },
    { lng: -63.11, lat: 45.985 },
    { lng: -63.28, lat: 46.035 },
    { lng: -63.335, lat: 46.05 },
    { lng: -63.485, lat: 46.07 },
    { lng: -63.58, lat: 46.08 },
    { lng: -63.625, lat: 46.075 },
    { lng: -63.76, lat: 46.215 },
    { lng: -63.805, lat: 46.255 },
    { lng: -63.84, lat: 46.275 },
    { lng: -63.84, lat: 46.355 },
    { lng: -63.885, lat: 46.37 },
    { lng: -63.925, lat: 46.32 },
    { lng: -63.95, lat: 46.315 },
    { lng: -64.01, lat: 46.355 },
    { lng: -64.18, lat: 46.38 },
    { lng: -64.19, lat: 46.4 },
    { lng: -64.125, lat: 46.56 },
    { lng: -64.18, lat: 46.57 },
    { lng: -64.44, lat: 46.585 },
    { lng: -64.45, lat: 46.6 },
    { lng: -64.465, lat: 46.665 },
  ],
  // NOVA SCOTIA
  NS: [
    { lng: -66.375, lat: 44.135 },
    { lng: -66.46, lat: 44.22 },
    { lng: -66.465, lat: 44.255 },
    { lng: -66.16, lat: 44.545 },
    { lng: -65.955, lat: 44.67 },
    { lng: -65.77, lat: 44.765 },
    { lng: -65.695, lat: 44.795 },
    { lng: -65.525, lat: 44.885 },
    { lng: -65.255, lat: 45.01 },
    { lng: -65.085, lat: 45.1 },
    { lng: -65.06, lat: 45.105 },
    { lng: -65.045, lat: 45.26 },
    { lng: -65.015, lat: 45.285 },
    { lng: -64.95, lat: 45.465 },
    { lng: -64.78, lat: 45.58 },
    { lng: -64.75, lat: 45.59 },
    { lng: -64.545, lat: 45.705 },
    { lng: -64.495, lat: 45.72 },
    { lng: -64.475, lat: 45.76 },
    { lng: -64.38, lat: 45.84 },
    { lng: -64.365, lat: 45.87 },
    { lng: -64.335, lat: 45.88 },
    { lng: -64.3, lat: 45.87 },
    { lng: -64.29, lat: 45.86 },
    { lng: -64.275, lat: 45.89 },
    { lng: -64.205, lat: 45.945 },
    { lng: -64.17, lat: 45.995 },
    { lng: -64.16, lat: 46 },
    { lng: -64.065, lat: 45.995 },
    { lng: -64.05, lat: 46.035 },
    { lng: -64.03, lat: 46.045 },
    { lng: -63.585, lat: 46.12 },
    { lng: -63.33, lat: 46.09 },
    { lng: -63.095, lat: 46.02 },
    { lng: -62.96, lat: 45.925 },
    { lng: -62.895, lat: 45.9 },
    { lng: -62.79, lat: 45.88 },
    { lng: -62.49, lat: 45.88 },
    { lng: -62.48, lat: 45.87 },
    { lng: -62.485, lat: 45.805 },
    { lng: -62.565, lat: 45.735 },
    { lng: -62.5, lat: 45.705 },
    { lng: -62.455, lat: 45.695 },
    { lng: -62.35, lat: 45.725 },
    { lng: -62.295, lat: 45.755 },
    { lng: -62.26, lat: 45.765 },
    { lng: -62.255, lat: 45.775 },
    { lng: -62.175, lat: 45.815 },
    { lng: -62.085, lat: 45.885 },
    { lng: -62.005, lat: 45.92 },
    { lng: -61.665, lat: 46.02 },
    { lng: -61.53, lat: 46.095 },
    { lng: -61.515, lat: 46.11 },
    { lng: -61.495, lat: 46.16 },
    { lng: -61.465, lat: 46.195 },
    { lng: -61.325, lat: 46.265 },
    { lng: -61.295, lat: 46.38 },
    { lng: -61.06, lat: 46.66 },
    { lng: -60.945, lat: 46.76 },
    { lng: -60.925, lat: 46.8 },
    { lng: -60.815, lat: 46.885 },
    { lng: -60.73, lat: 46.995 },
    { lng: -60.625, lat: 47.08 },
    { lng: -60.545, lat: 47.075 },
    { lng: -60.465, lat: 47.085 },
    { lng: -60.19, lat: 47.255 },
    { lng: -60.1, lat: 47.25 },
    { lng: -60.09, lat: 47.245 },
    { lng: -60.09, lat: 47.225 },
    { lng: -60.115, lat: 47.16 },
    { lng: -60.27, lat: 46.95 },
    { lng: -60.28, lat: 46.66 },
    { lng: -60.32, lat: 46.375 },
    { lng: -60.09, lat: 46.295 },
    { lng: -59.99, lat: 46.27 },
    { lng: -59.965, lat: 46.255 },
    { lng: -59.765, lat: 46.21 },
    { lng: -59.68, lat: 46.16 },
    { lng: -59.645, lat: 46.12 },
    { lng: -59.63, lat: 46.035 },
    { lng: -59.685, lat: 45.97 },
    { lng: -59.74, lat: 45.97 },
    { lng: -59.74, lat: 45.96 },
    { lng: -59.76, lat: 45.945 },
    { lng: -59.77, lat: 45.915 },
    { lng: -59.805, lat: 45.885 },
    { lng: -60.065, lat: 45.755 },
    { lng: -60.1, lat: 45.71 },
    { lng: -60.105, lat: 45.685 },
    { lng: -60.3, lat: 45.59 },
    { lng: -60.505, lat: 45.515 },
    { lng: -60.585, lat: 45.5 },
    { lng: -60.75, lat: 45.485 },
    { lng: -60.87, lat: 45.49 },
    { lng: -60.88, lat: 45.33 },
    { lng: -60.91, lat: 45.265 },
    { lng: -61, lat: 45.2 },
    { lng: -61.105, lat: 45.145 },
    { lng: -61.24, lat: 45.125 },
    { lng: -61.445, lat: 45.125 },
    { lng: -61.51, lat: 45.07 },
    { lng: -61.56, lat: 45.04 },
    { lng: -61.845, lat: 44.97 },
    { lng: -62.07, lat: 44.86 },
    { lng: -62.19, lat: 44.79 },
    { lng: -62.205, lat: 44.79 },
    { lng: -62.22, lat: 44.805 },
    { lng: -62.335, lat: 44.75 },
    { lng: -62.47, lat: 44.75 },
    { lng: -62.66, lat: 44.695 },
    { lng: -62.77, lat: 44.655 },
    { lng: -62.785, lat: 44.64 },
    { lng: -62.845, lat: 44.635 },
    { lng: -62.935, lat: 44.61 },
    { lng: -63.035, lat: 44.595 },
    { lng: -63.235, lat: 44.6 },
    { lng: -63.47, lat: 44.545 },
    { lng: -63.45, lat: 44.495 },
    { lng: -63.46, lat: 44.46 },
    { lng: -63.485, lat: 44.415 },
    { lng: -63.525, lat: 44.39 },
    { lng: -63.81, lat: 44.395 },
    { lng: -63.9, lat: 44.42 },
    { lng: -63.965, lat: 44.46 },
    { lng: -63.965, lat: 44.47 },
    { lng: -63.98, lat: 44.48 },
    { lng: -63.985, lat: 44.395 },
    { lng: -64.22, lat: 44.235 },
    { lng: -64.295, lat: 44.155 },
    { lng: -64.595, lat: 43.99 },
    { lng: -64.75, lat: 43.735 },
    { lng: -64.93, lat: 43.73 },
    { lng: -64.965, lat: 43.685 },
    { lng: -65.28, lat: 43.49 },
    { lng: -65.365, lat: 43.415 },
    { lng: -65.84, lat: 43.25 },
    { lng: -65.905, lat: 43.27 },
    { lng: -65.955, lat: 43.3 },
    { lng: -66.005, lat: 43.35 },
    { lng: -66.06, lat: 43.39 },
    { lng: -66.065, lat: 43.435 },
    { lng: -66.045, lat: 43.51 },
    { lng: -66.1, lat: 43.51 },
    { lng: -66.195, lat: 43.615 },
    { lng: -66.22, lat: 43.745 },
    { lng: -66.24, lat: 43.99 },
    { lng: -66.375, lat: 44.135 },
  ],
  // NEWFOUNDLAND AND LABRADOR
  NL: [
    [
      { lng: -67.85, lat: 54.11 },
      { lng: -67.84, lat: 54.16 },
      { lng: -67.78, lat: 54.2 },
      { lng: -67.73, lat: 54.19 },
      { lng: -67.69, lat: 54.2 },
      { lng: -67.72, lat: 54.23 },
      { lng: -67.72, lat: 54.29 },
      { lng: -67.78, lat: 54.32 },
      { lng: -67.79, lat: 54.34 },
      { lng: -67.78, lat: 54.38 },
      { lng: -67.81, lat: 54.4 },
      { lng: -67.81, lat: 54.44 },
      { lng: -67.78, lat: 54.48 },
      { lng: -67.7, lat: 54.5 },
      { lng: -67.66, lat: 54.55 },
      { lng: -67.63, lat: 54.55 },
      { lng: -67.61, lat: 54.53 },
      { lng: -67.55, lat: 54.53 },
      { lng: -67.57, lat: 54.58 },
      { lng: -67.53, lat: 54.62 },
      { lng: -67.44, lat: 54.62 },
      { lng: -67.34, lat: 54.56 },
      { lng: -67.3, lat: 54.63 },
      { lng: -67.27, lat: 54.63 },
      { lng: -67.23, lat: 54.66 },
      { lng: -67.18, lat: 54.65 },
      { lng: -67.14, lat: 54.7 },
      { lng: -67.23, lat: 54.75 },
      { lng: -67.27, lat: 54.8 },
      { lng: -67.29, lat: 54.8 },
      { lng: -67.31, lat: 54.84 },
      { lng: -67.33, lat: 54.84 },
      { lng: -67.4, lat: 54.92 },
      { lng: -67.43, lat: 54.93 },
      { lng: -67.45, lat: 54.97 },
      { lng: -67.48, lat: 54.98 },
      { lng: -67.48, lat: 55 },
      { lng: -67.51, lat: 55.03 },
      { lng: -67.5, lat: 55.09 },
      { lng: -67.43, lat: 55.13 },
      { lng: -67.27, lat: 55.12 },
      { lng: -67.2, lat: 55.08 },
      { lng: -67.19, lat: 55.02 },
      { lng: -67.12, lat: 54.99 },
      { lng: -67.1, lat: 54.96 },
      { lng: -67.06, lat: 54.95 },
      { lng: -66.95, lat: 54.86 },
      { lng: -66.87, lat: 54.86 },
      { lng: -66.78, lat: 54.81 },
      { lng: -66.76, lat: 54.85 },
      { lng: -66.71, lat: 54.85 },
      { lng: -66.69, lat: 54.83 },
      { lng: -66.73, lat: 54.91 },
      { lng: -66.81, lat: 54.99 },
      { lng: -66.78, lat: 55.05 },
      { lng: -66.82, lat: 55.09 },
      { lng: -66.83, lat: 55.13 },
      { lng: -66.8, lat: 55.17 },
      { lng: -66.79, lat: 55.23 },
      { lng: -66.85, lat: 55.27 },
      { lng: -66.87, lat: 55.32 },
      { lng: -66.86, lat: 55.37 },
      { lng: -66.82, lat: 55.4 },
      { lng: -66.76, lat: 55.39 },
      { lng: -66.68, lat: 55.35 },
      { lng: -66.66, lat: 55.31 },
      { lng: -66.58, lat: 55.31 },
      { lng: -66.56, lat: 55.28 },
      { lng: -66.47, lat: 55.24 },
      { lng: -66.42, lat: 55.17 },
      { lng: -66.4, lat: 55.17 },
      { lng: -66.21, lat: 55.02 },
      { lng: -66.15, lat: 55.03 },
      { lng: -66.06, lat: 54.98 },
      { lng: -65.99, lat: 54.97 },
      { lng: -65.91, lat: 54.98 },
      { lng: -65.88, lat: 54.96 },
      { lng: -65.82, lat: 54.96 },
      { lng: -65.8, lat: 54.93 },
      { lng: -65.8, lat: 54.86 },
      { lng: -65.74, lat: 54.83 },
      { lng: -65.69, lat: 54.77 },
      { lng: -65.67, lat: 54.79 },
      { lng: -65.53, lat: 54.77 },
      { lng: -65.51, lat: 54.78 },
      { lng: -65.53, lat: 54.81 },
      { lng: -65.53, lat: 54.85 },
      { lng: -65.51, lat: 54.87 },
      { lng: -65.47, lat: 54.89 },
      { lng: -65.38, lat: 54.87 },
      { lng: -65.36, lat: 54.89 },
      { lng: -65.24, lat: 54.89 },
      { lng: -65.22, lat: 54.91 },
      { lng: -65.21, lat: 54.96 },
      { lng: -65.16, lat: 54.97 },
      { lng: -65.15, lat: 55 },
      { lng: -65.07, lat: 55.01 },
      { lng: -65.02, lat: 54.99 },
      { lng: -65.01, lat: 54.97 },
      { lng: -64.96, lat: 54.98 },
      { lng: -64.9, lat: 54.96 },
      { lng: -64.81, lat: 54.89 },
      { lng: -64.74, lat: 54.87 },
      { lng: -64.71, lat: 54.83 },
      { lng: -64.71, lat: 54.77 },
      { lng: -64.68, lat: 54.78 },
      { lng: -64.63, lat: 54.76 },
      { lng: -64.53, lat: 54.8 },
      { lng: -64.51, lat: 54.83 },
      { lng: -64.38, lat: 54.83 },
      { lng: -64.29, lat: 54.8 },
      { lng: -64.26, lat: 54.77 },
      { lng: -64.16, lat: 54.76 },
      { lng: -64.14, lat: 54.74 },
      { lng: -64.14, lat: 54.71 },
      { lng: -64.09, lat: 54.7 },
      { lng: -64.05, lat: 54.65 },
      { lng: -64.01, lat: 54.64 },
      { lng: -63.99, lat: 54.66 },
      { lng: -63.95, lat: 54.66 },
      { lng: -63.91, lat: 54.64 },
      { lng: -63.83, lat: 54.69 },
      { lng: -63.96, lat: 54.75 },
      { lng: -63.96, lat: 54.8 },
      { lng: -63.92, lat: 54.84 },
      { lng: -63.88, lat: 54.84 },
      { lng: -63.89, lat: 54.9 },
      { lng: -63.87, lat: 54.96 },
      { lng: -63.79, lat: 55 },
      { lng: -63.65, lat: 54.95 },
      { lng: -63.64, lat: 55.04 },
      { lng: -63.66, lat: 55.07 },
      { lng: -63.64, lat: 55.15 },
      { lng: -63.6, lat: 55.19 },
      { lng: -63.63, lat: 55.19 },
      { lng: -63.7, lat: 55.23 },
      { lng: -63.72, lat: 55.25 },
      { lng: -63.72, lat: 55.28 },
      { lng: -63.59, lat: 55.38 },
      { lng: -63.72, lat: 55.39 },
      { lng: -63.83, lat: 55.45 },
      { lng: -63.83, lat: 55.49 },
      { lng: -63.81, lat: 55.51 },
      { lng: -63.72, lat: 55.54 },
      { lng: -63.72, lat: 55.61 },
      { lng: -63.77, lat: 55.61 },
      { lng: -63.8, lat: 55.63 },
      { lng: -63.81, lat: 55.66 },
      { lng: -63.76, lat: 55.71 },
      { lng: -63.77, lat: 55.72 },
      { lng: -63.76, lat: 55.75 },
      { lng: -63.8, lat: 55.76 },
      { lng: -63.82, lat: 55.83 },
      { lng: -63.86, lat: 55.84 },
      { lng: -63.89, lat: 55.88 },
      { lng: -63.88, lat: 55.95 },
      { lng: -63.75, lat: 55.97 },
      { lng: -63.7, lat: 56.01 },
      { lng: -63.73, lat: 56.03 },
      { lng: -63.77, lat: 56.01 },
      { lng: -63.86, lat: 56.01 },
      { lng: -63.88, lat: 56.05 },
      { lng: -63.91, lat: 56.06 },
      { lng: -63.99, lat: 56.03 },
      { lng: -64.05, lat: 56.03 },
      { lng: -64.08, lat: 56.07 },
      { lng: -64.06, lat: 56.11 },
      { lng: -64.08, lat: 56.16 },
      { lng: -64.06, lat: 56.19 },
      { lng: -64, lat: 56.22 },
      { lng: -64.02, lat: 56.23 },
      { lng: -64.11, lat: 56.22 },
      { lng: -64.13, lat: 56.26 },
      { lng: -64.16, lat: 56.26 },
      { lng: -64.19, lat: 56.29 },
      { lng: -64.19, lat: 56.32 },
      { lng: -64.16, lat: 56.35 },
      { lng: -64.17, lat: 56.38 },
      { lng: -64.22, lat: 56.41 },
      { lng: -64.21, lat: 56.45 },
      { lng: -64.17, lat: 56.48 },
      { lng: -64, lat: 56.45 },
      { lng: -63.97, lat: 56.46 },
      { lng: -63.98, lat: 56.52 },
      { lng: -64.07, lat: 56.57 },
      { lng: -64.09, lat: 56.61 },
      { lng: -64.16, lat: 56.65 },
      { lng: -64.19, lat: 56.7 },
      { lng: -64.11, lat: 56.8 },
      { lng: -64.05, lat: 56.81 },
      { lng: -64.05, lat: 56.87 },
      { lng: -64.02, lat: 56.9 },
      { lng: -63.96, lat: 56.91 },
      { lng: -63.95, lat: 57 },
      { lng: -63.93, lat: 57.02 },
      { lng: -63.94, lat: 57.04 },
      { lng: -63.93, lat: 57.1 },
      { lng: -63.9, lat: 57.13 },
      { lng: -63.84, lat: 57.12 },
      { lng: -63.82, lat: 57.14 },
      { lng: -63.82, lat: 57.17 },
      { lng: -63.86, lat: 57.15 },
      { lng: -63.92, lat: 57.17 },
      { lng: -63.92, lat: 57.3 },
      { lng: -63.88, lat: 57.37 },
      { lng: -63.81, lat: 57.38 },
      { lng: -63.78, lat: 57.4 },
      { lng: -63.8, lat: 57.42 },
      { lng: -63.82, lat: 57.49 },
      { lng: -63.8, lat: 57.54 },
      { lng: -63.81, lat: 57.61 },
      { lng: -63.79, lat: 57.63 },
      { lng: -63.76, lat: 57.63 },
      { lng: -63.78, lat: 57.65 },
      { lng: -63.8, lat: 57.64 },
      { lng: -63.85, lat: 57.67 },
      { lng: -63.91, lat: 57.68 },
      { lng: -63.94, lat: 57.74 },
      { lng: -63.97, lat: 57.76 },
      { lng: -63.99, lat: 57.76 },
      { lng: -63.99, lat: 57.74 },
      { lng: -64.02, lat: 57.72 },
      { lng: -64.09, lat: 57.73 },
      { lng: -64.15, lat: 57.78 },
      { lng: -64.17, lat: 57.84 },
      { lng: -64.2, lat: 57.89 },
      { lng: -64.24, lat: 57.92 },
      { lng: -64.24, lat: 57.95 },
      { lng: -64.26, lat: 57.96 },
      { lng: -64.28, lat: 58.01 },
      { lng: -64.3, lat: 58 },
      { lng: -64.36, lat: 58.03 },
      { lng: -64.43, lat: 58.02 },
      { lng: -64.47, lat: 58.05 },
      { lng: -64.48, lat: 58.09 },
      { lng: -64.45, lat: 58.22 },
      { lng: -64.39, lat: 58.25 },
      { lng: -64.27, lat: 58.26 },
      { lng: -64.19, lat: 58.4 },
      { lng: -64.1, lat: 58.42 },
      { lng: -64.06, lat: 58.41 },
      { lng: -64.06, lat: 58.43 },
      { lng: -64.01, lat: 58.48 },
      { lng: -63.88, lat: 58.47 },
      { lng: -63.9, lat: 58.49 },
      { lng: -63.9, lat: 58.53 },
      { lng: -63.95, lat: 58.49 },
      { lng: -64.03, lat: 58.48 },
      { lng: -64.13, lat: 58.53 },
      { lng: -64.16, lat: 58.61 },
      { lng: -64.14, lat: 58.67 },
      { lng: -64.11, lat: 58.68 },
      { lng: -64.09, lat: 58.72 },
      { lng: -64.16, lat: 58.71 },
      { lng: -64.19, lat: 58.74 },
      { lng: -64.24, lat: 58.74 },
      { lng: -64.28, lat: 58.79 },
      { lng: -64.27, lat: 58.84 },
      { lng: -64.32, lat: 58.86 },
      { lng: -64.44, lat: 58.85 },
      { lng: -64.45, lat: 58.87 },
      { lng: -64.49, lat: 58.85 },
      { lng: -64.57, lat: 58.85 },
      { lng: -64.59, lat: 58.87 },
      { lng: -64.64, lat: 58.87 },
      { lng: -64.66, lat: 58.91 },
      { lng: -64.72, lat: 58.91 },
      { lng: -64.76, lat: 58.88 },
      { lng: -64.85, lat: 58.88 },
      { lng: -64.93, lat: 58.92 },
      { lng: -64.9, lat: 59.04 },
      { lng: -64.8, lat: 59.11 },
      { lng: -64.73, lat: 59.12 },
      { lng: -64.66, lat: 59.11 },
      { lng: -64.64, lat: 59.08 },
      { lng: -64.56, lat: 59.07 },
      { lng: -64.53, lat: 59.05 },
      { lng: -64.51, lat: 59.06 },
      { lng: -64.54, lat: 59.1 },
      { lng: -64.53, lat: 59.16 },
      { lng: -64.55, lat: 59.16 },
      { lng: -64.56, lat: 59.18 },
      { lng: -64.55, lat: 59.26 },
      { lng: -64.58, lat: 59.29 },
      { lng: -64.58, lat: 59.39 },
      { lng: -64.55, lat: 59.44 },
      { lng: -64.7, lat: 59.41 },
      { lng: -64.78, lat: 59.45 },
      { lng: -64.84, lat: 59.51 },
      { lng: -64.96, lat: 59.53 },
      { lng: -64.98, lat: 59.55 },
      { lng: -64.97, lat: 59.61 },
      { lng: -64.94, lat: 59.63 },
      { lng: -64.93, lat: 59.68 },
      { lng: -64.91, lat: 59.7 },
      { lng: -64.85, lat: 59.71 },
      { lng: -64.84, lat: 59.75 },
      { lng: -64.85, lat: 59.8 },
      { lng: -64.87, lat: 59.82 },
      { lng: -64.86, lat: 59.85 },
      { lng: -64.8, lat: 59.88 },
      { lng: -64.8, lat: 59.9 },
      { lng: -64.77, lat: 59.92 },
      { lng: -64.78, lat: 59.94 },
      { lng: -64.87, lat: 59.94 },
      { lng: -64.91, lat: 60.01 },
      { lng: -64.96, lat: 60.04 },
      { lng: -64.95, lat: 60.1 },
      { lng: -64.89, lat: 60.11 },
      { lng: -64.81, lat: 60.09 },
      { lng: -64.81, lat: 60.12 },
      { lng: -64.78, lat: 60.15 },
      { lng: -64.89, lat: 60.2 },
      { lng: -64.9, lat: 60.28 },
      { lng: -64.87, lat: 60.31 },
      { lng: -64.77, lat: 60.32 },
      { lng: -64.73, lat: 60.34 },
      { lng: -64.6, lat: 60.34 },
      { lng: -64.57, lat: 60.35 },
      { lng: -64.51, lat: 60.41 },
      { lng: -64.42, lat: 60.42 },
      { lng: -64.39, lat: 60.39 },
      { lng: -64.39, lat: 60.34 },
      { lng: -64.34, lat: 60.32 },
      { lng: -64.34, lat: 60.27 },
      { lng: -64.25, lat: 60.27 },
      { lng: -64.23, lat: 60.25 },
      { lng: -64.23, lat: 60.22 },
      { lng: -64.2, lat: 60.19 },
      { lng: -64.2, lat: 60.16 },
      { lng: -64.17, lat: 60.12 },
      { lng: -64.18, lat: 60.08 },
      { lng: -64.13, lat: 60.06 },
      { lng: -64.11, lat: 60.03 },
      { lng: -64.11, lat: 59.94 },
      { lng: -63.99, lat: 59.87 },
      { lng: -63.99, lat: 59.81 },
      { lng: -63.95, lat: 59.81 },
      { lng: -63.9, lat: 59.78 },
      { lng: -63.87, lat: 59.65 },
      { lng: -63.83, lat: 59.63 },
      { lng: -63.82, lat: 59.6 },
      { lng: -63.71, lat: 59.56 },
      { lng: -63.69, lat: 59.52 },
      { lng: -63.63, lat: 59.48 },
      { lng: -63.63, lat: 59.42 },
      { lng: -63.65, lat: 59.4 },
      { lng: -63.59, lat: 59.38 },
      { lng: -63.51, lat: 59.4 },
      { lng: -63.49, lat: 59.39 },
      { lng: -63.49, lat: 59.34 },
      { lng: -63.47, lat: 59.33 },
      { lng: -63.47, lat: 59.31 },
      { lng: -63.38, lat: 59.32 },
      { lng: -63.35, lat: 59.3 },
      { lng: -63.31, lat: 59.18 },
      { lng: -63.36, lat: 59.13 },
      { lng: -63.29, lat: 59.13 },
      { lng: -63.2, lat: 59.09 },
      { lng: -63.1, lat: 59.08 },
      { lng: -63.09, lat: 59.02 },
      { lng: -63.13, lat: 58.99 },
      { lng: -63.12, lat: 58.96 },
      { lng: -63, lat: 58.93 },
      { lng: -62.95, lat: 58.88 },
      { lng: -62.87, lat: 58.84 },
      { lng: -62.85, lat: 58.8 },
      { lng: -62.86, lat: 58.75 },
      { lng: -62.8, lat: 58.7 },
      { lng: -62.83, lat: 58.59 },
      { lng: -62.74, lat: 58.61 },
      { lng: -62.64, lat: 58.6 },
      { lng: -62.6, lat: 58.57 },
      { lng: -62.59, lat: 58.53 },
      { lng: -62.53, lat: 58.51 },
      { lng: -62.52, lat: 58.47 },
      { lng: -62.54, lat: 58.44 },
      { lng: -62.52, lat: 58.42 },
      { lng: -62.43, lat: 58.41 },
      { lng: -62.37, lat: 58.37 },
      { lng: -62.38, lat: 58.32 },
      { lng: -62.45, lat: 58.31 },
      { lng: -62.56, lat: 58.26 },
      { lng: -62.47, lat: 58.24 },
      { lng: -62.4, lat: 58.18 },
      { lng: -62.13, lat: 58.25 },
      { lng: -62.08, lat: 58.25 },
      { lng: -62.05, lat: 58.22 },
      { lng: -62.05, lat: 58.19 },
      { lng: -62.16, lat: 58.06 },
      { lng: -62.16, lat: 58.04 },
      { lng: -62.1, lat: 58.02 },
      { lng: -62.1, lat: 57.99 },
      { lng: -62.08, lat: 57.97 },
      { lng: -61.93, lat: 57.95 },
      { lng: -61.64, lat: 57.96 },
      { lng: -61.6, lat: 57.93 },
      { lng: -61.6, lat: 57.89 },
      { lng: -61.65, lat: 57.83 },
      { lng: -61.62, lat: 57.81 },
      { lng: -61.61, lat: 57.78 },
      { lng: -61.61, lat: 57.75 },
      { lng: -61.63, lat: 57.74 },
      { lng: -61.64, lat: 57.7 },
      { lng: -61.63, lat: 57.67 },
      { lng: -61.61, lat: 57.65 },
      { lng: -61.39, lat: 57.64 },
      { lng: -61.27, lat: 57.59 },
      { lng: -61.27, lat: 57.55 },
      { lng: -61.29, lat: 57.52 },
      { lng: -61.39, lat: 57.48 },
      { lng: -61.38, lat: 57.42 },
      { lng: -61.5, lat: 57.37 },
      { lng: -61.59, lat: 57.36 },
      { lng: -61.44, lat: 57.31 },
      { lng: -61.42, lat: 57.28 },
      { lng: -61.4, lat: 57.18 },
      { lng: -61.33, lat: 57.19 },
      { lng: -61.3, lat: 57.16 },
      { lng: -61.29, lat: 57.13 },
      { lng: -61.33, lat: 57.08 },
      { lng: -61.3, lat: 57.04 },
      { lng: -61.3, lat: 56.96 },
      { lng: -61.24, lat: 56.94 },
      { lng: -61.19, lat: 56.89 },
      { lng: -61.08, lat: 56.93 },
      { lng: -61.03, lat: 56.92 },
      { lng: -60.85, lat: 56.78 },
      { lng: -60.84, lat: 56.74 },
      { lng: -60.88, lat: 56.67 },
      { lng: -60.87, lat: 56.63 },
      { lng: -60.89, lat: 56.61 },
      { lng: -61.09, lat: 56.54 },
      { lng: -60.86, lat: 56.49 },
      { lng: -60.85, lat: 56.44 },
      { lng: -60.88, lat: 56.41 },
      { lng: -60.95, lat: 56.38 },
      { lng: -60.83, lat: 56.33 },
      { lng: -60.69, lat: 56.3 },
      { lng: -60.65, lat: 56.27 },
      { lng: -60.66, lat: 56.23 },
      { lng: -60.74, lat: 56.14 },
      { lng: -60.74, lat: 56.06 },
      { lng: -60.71, lat: 56.03 },
      { lng: -60.63, lat: 56.03 },
      { lng: -60.59, lat: 56.01 },
      { lng: -60.58, lat: 55.96 },
      { lng: -60.61, lat: 55.92 },
      { lng: -60.56, lat: 55.88 },
      { lng: -60.56, lat: 55.86 },
      { lng: -60.47, lat: 55.84 },
      { lng: -60.37, lat: 55.89 },
      { lng: -60.27, lat: 55.88 },
      { lng: -60.22, lat: 55.79 },
      { lng: -60.1, lat: 55.74 },
      { lng: -60.09, lat: 55.72 },
      { lng: -60.09, lat: 55.69 },
      { lng: -60.22, lat: 55.59 },
      { lng: -60.12, lat: 55.6 },
      { lng: -60.1, lat: 55.58 },
      { lng: -60.04, lat: 55.57 },
      { lng: -60, lat: 55.55 },
      { lng: -60, lat: 55.49 },
      { lng: -59.82, lat: 55.55 },
      { lng: -59.78, lat: 55.53 },
      { lng: -59.75, lat: 55.46 },
      { lng: -59.75, lat: 55.41 },
      { lng: -59.9, lat: 55.32 },
      { lng: -59.91, lat: 55.3 },
      { lng: -59.78, lat: 55.38 },
      { lng: -59.74, lat: 55.38 },
      { lng: -59.64, lat: 55.42 },
      { lng: -59.54, lat: 55.41 },
      { lng: -59.52, lat: 55.37 },
      { lng: -59.58, lat: 55.3 },
      { lng: -59.49, lat: 55.27 },
      { lng: -59.38, lat: 55.33 },
      { lng: -59.37, lat: 55.35 },
      { lng: -59.3, lat: 55.35 },
      { lng: -59.17, lat: 55.27 },
      { lng: -58.74, lat: 55.25 },
      { lng: -58.7, lat: 55.21 },
      { lng: -58.71, lat: 55.16 },
      { lng: -58.43, lat: 55.08 },
      { lng: -58.2, lat: 55.04 },
      { lng: -58.14, lat: 55 },
      { lng: -58.12, lat: 54.93 },
      { lng: -58.08, lat: 54.96 },
      { lng: -57.93, lat: 54.96 },
      { lng: -57.87, lat: 54.94 },
      { lng: -57.79, lat: 54.85 },
      { lng: -57.8, lat: 54.78 },
      { lng: -57.69, lat: 54.71 },
      { lng: -57.6, lat: 54.68 },
      { lng: -57.55, lat: 54.7 },
      { lng: -57.42, lat: 54.69 },
      { lng: -57.35, lat: 54.63 },
      { lng: -57.31, lat: 54.66 },
      { lng: -56.85, lat: 54.77 },
      { lng: -56.83, lat: 54.75 },
      { lng: -56.83, lat: 54.72 },
      { lng: -56.86, lat: 54.69 },
      { lng: -57.08, lat: 54.55 },
      { lng: -57.04, lat: 54.53 },
      { lng: -56.93, lat: 54.53 },
      { lng: -56.88, lat: 54.5 },
      { lng: -56.86, lat: 54.45 },
      { lng: -56.89, lat: 54.42 },
      { lng: -57.2, lat: 54.39 },
      { lng: -57.43, lat: 54.4 },
      { lng: -57.54, lat: 54.37 },
      { lng: -57.62, lat: 54.29 },
      { lng: -57.52, lat: 54.27 },
      { lng: -57.3, lat: 54.33 },
      { lng: -57.24, lat: 54.31 },
      { lng: -57.24, lat: 54.27 },
      { lng: -57.08, lat: 54.17 },
      { lng: -57.08, lat: 54.14 },
      { lng: -57.14, lat: 54.07 },
      { lng: -57.14, lat: 54.05 },
      { lng: -57.22, lat: 54.01 },
      { lng: -57.08, lat: 53.96 },
      { lng: -57.08, lat: 53.91 },
      { lng: -57.14, lat: 53.89 },
      { lng: -57.11, lat: 53.87 },
      { lng: -57.08, lat: 53.9 },
      { lng: -56.86, lat: 53.9 },
      { lng: -56.76, lat: 53.81 },
      { lng: -56.76, lat: 53.79 },
      { lng: -56.73, lat: 53.79 },
      { lng: -56.65, lat: 53.81 },
      { lng: -56.6, lat: 53.95 },
      { lng: -56.57, lat: 53.98 },
      { lng: -56.53, lat: 53.98 },
      { lng: -56.28, lat: 53.8 },
      { lng: -56.29, lat: 53.75 },
      { lng: -56.31, lat: 53.74 },
      { lng: -56.26, lat: 53.73 },
      { lng: -56.24, lat: 53.71 },
      { lng: -56.25, lat: 53.64 },
      { lng: -56.23, lat: 53.63 },
      { lng: -56.14, lat: 53.63 },
      { lng: -56.06, lat: 53.7 },
      { lng: -55.87, lat: 53.72 },
      { lng: -55.85, lat: 53.7 },
      { lng: -55.84, lat: 53.64 },
      { lng: -55.9, lat: 53.57 },
      { lng: -55.9, lat: 53.55 },
      { lng: -55.88, lat: 53.53 },
      { lng: -55.81, lat: 53.57 },
      { lng: -55.76, lat: 53.55 },
      { lng: -55.72, lat: 53.56 },
      { lng: -55.68, lat: 53.51 },
      { lng: -55.68, lat: 53.47 },
      { lng: -55.72, lat: 53.42 },
      { lng: -55.71, lat: 53.39 },
      { lng: -55.73, lat: 53.37 },
      { lng: -55.72, lat: 53.32 },
      { lng: -55.67, lat: 53.3 },
      { lng: -55.66, lat: 53.2 },
      { lng: -55.7, lat: 53.16 },
      { lng: -55.7, lat: 53.04 },
      { lng: -55.72, lat: 53.02 },
      { lng: -55.74, lat: 52.87 },
      { lng: -55.76, lat: 52.85 },
      { lng: -55.77, lat: 52.8 },
      { lng: -55.75, lat: 52.77 },
      { lng: -55.75, lat: 52.73 },
      { lng: -55.7, lat: 52.68 },
      { lng: -55.69, lat: 52.66 },
      { lng: -55.7, lat: 52.63 },
      { lng: -55.66, lat: 52.57 },
      { lng: -55.66, lat: 52.53 },
      { lng: -55.69, lat: 52.5 },
      { lng: -55.61, lat: 52.48 },
      { lng: -55.58, lat: 52.45 },
      { lng: -55.59, lat: 52.4 },
      { lng: -55.58, lat: 52.35 },
      { lng: -55.62, lat: 52.31 },
      { lng: -55.56, lat: 52.31 },
      { lng: -55.53, lat: 52.25 },
      { lng: -55.65, lat: 52.1 },
      { lng: -55.66, lat: 52.06 },
      { lng: -55.74, lat: 52.02 },
      { lng: -55.83, lat: 51.93 },
      { lng: -56.22, lat: 51.74 },
      { lng: -56.44, lat: 51.67 },
      { lng: -56.5, lat: 51.63 },
      { lng: -56.59, lat: 51.6 },
      { lng: -56.68, lat: 51.55 },
      { lng: -56.81, lat: 51.43 },
      { lng: -56.94, lat: 51.38 },
      { lng: -57.13, lat: 51.34 },
      { lng: -57.15, lat: 51.36 },
      { lng: -57.15, lat: 51.96 },
      { lng: -63.83, lat: 51.96 },
      { lng: -63.87, lat: 52.02 },
      { lng: -63.88, lat: 52.08 },
      { lng: -63.85, lat: 52.12 },
      { lng: -63.81, lat: 52.13 },
      { lng: -63.74, lat: 52.11 },
      { lng: -63.75, lat: 52.18 },
      { lng: -63.79, lat: 52.21 },
      { lng: -63.79, lat: 52.23 },
      { lng: -63.84, lat: 52.25 },
      { lng: -63.85, lat: 52.28 },
      { lng: -64.02, lat: 52.32 },
      { lng: -64.05, lat: 52.34 },
      { lng: -64.06, lat: 52.37 },
      { lng: -64.08, lat: 52.37 },
      { lng: -64.12, lat: 52.33 },
      { lng: -64.13, lat: 52.29 },
      { lng: -64.18, lat: 52.25 },
      { lng: -64.16, lat: 52.19 },
      { lng: -64.12, lat: 52.16 },
      { lng: -64.13, lat: 52.09 },
      { lng: -64.16, lat: 52.07 },
      { lng: -64.21, lat: 52.08 },
      { lng: -64.23, lat: 52.05 },
      { lng: -64.19, lat: 51.99 },
      { lng: -64.22, lat: 51.94 },
      { lng: -64.31, lat: 51.94 },
      { lng: -64.3, lat: 51.91 },
      { lng: -64.31, lat: 51.85 },
      { lng: -64.26, lat: 51.82 },
      { lng: -64.26, lat: 51.78 },
      { lng: -64.23, lat: 51.75 },
      { lng: -64.24, lat: 51.71 },
      { lng: -64.29, lat: 51.67 },
      { lng: -64.3, lat: 51.64 },
      { lng: -64.41, lat: 51.62 },
      { lng: -64.43, lat: 51.59 },
      { lng: -64.5, lat: 51.57 },
      { lng: -64.52, lat: 51.54 },
      { lng: -64.55, lat: 51.53 },
      { lng: -64.59, lat: 51.55 },
      { lng: -64.62, lat: 51.59 },
      { lng: -64.64, lat: 51.59 },
      { lng: -64.66, lat: 51.61 },
      { lng: -64.66, lat: 51.64 },
      { lng: -64.69, lat: 51.67 },
      { lng: -64.71, lat: 51.67 },
      { lng: -64.75, lat: 51.72 },
      { lng: -64.78, lat: 51.71 },
      { lng: -64.81, lat: 51.73 },
      { lng: -64.84, lat: 51.72 },
      { lng: -64.87, lat: 51.73 },
      { lng: -64.91, lat: 51.68 },
      { lng: -64.94, lat: 51.67 },
      { lng: -64.99, lat: 51.68 },
      { lng: -65.03, lat: 51.72 },
      { lng: -65.13, lat: 51.71 },
      { lng: -65.2, lat: 51.73 },
      { lng: -65.23, lat: 51.76 },
      { lng: -65.23, lat: 51.78 },
      { lng: -65.27, lat: 51.78 },
      { lng: -65.3, lat: 51.81 },
      { lng: -65.33, lat: 51.78 },
      { lng: -65.37, lat: 51.78 },
      { lng: -65.44, lat: 51.88 },
      { lng: -65.44, lat: 51.95 },
      { lng: -65.51, lat: 52.01 },
      { lng: -65.6, lat: 51.99 },
      { lng: -65.62, lat: 51.95 },
      { lng: -65.68, lat: 51.95 },
      { lng: -65.72, lat: 51.99 },
      { lng: -65.72, lat: 52.04 },
      { lng: -65.85, lat: 52.08 },
      { lng: -65.88, lat: 52.04 },
      { lng: -65.94, lat: 52.04 },
      { lng: -65.97, lat: 52.01 },
      { lng: -66, lat: 52.01 },
      { lng: -66.05, lat: 52.05 },
      { lng: -66.11, lat: 52.05 },
      { lng: -66.14, lat: 52.08 },
      { lng: -66.14, lat: 52.12 },
      { lng: -66.12, lat: 52.14 },
      { lng: -66.13, lat: 52.17 },
      { lng: -66.18, lat: 52.17 },
      { lng: -66.23, lat: 52.2 },
      { lng: -66.22, lat: 52.16 },
      { lng: -66.25, lat: 52.11 },
      { lng: -66.31, lat: 52.09 },
      { lng: -66.39, lat: 52.11 },
      { lng: -66.42, lat: 52.14 },
      { lng: -66.42, lat: 52.18 },
      { lng: -66.45, lat: 52.18 },
      { lng: -66.51, lat: 52.24 },
      { lng: -66.51, lat: 52.29 },
      { lng: -66.53, lat: 52.31 },
      { lng: -66.53, lat: 52.36 },
      { lng: -66.5, lat: 52.39 },
      { lng: -66.48, lat: 52.39 },
      { lng: -66.45, lat: 52.42 },
      { lng: -66.41, lat: 52.42 },
      { lng: -66.44, lat: 52.51 },
      { lng: -66.43, lat: 52.56 },
      { lng: -66.48, lat: 52.61 },
      { lng: -66.48, lat: 52.65 },
      { lng: -66.44, lat: 52.68 },
      { lng: -66.44, lat: 52.7 },
      { lng: -66.41, lat: 52.72 },
      { lng: -66.37, lat: 52.72 },
      { lng: -66.37, lat: 52.75 },
      { lng: -66.42, lat: 52.77 },
      { lng: -66.46, lat: 52.85 },
      { lng: -66.43, lat: 52.91 },
      { lng: -66.35, lat: 52.91 },
      { lng: -66.37, lat: 52.96 },
      { lng: -66.41, lat: 52.99 },
      { lng: -66.43, lat: 52.99 },
      { lng: -66.43, lat: 52.97 },
      { lng: -66.48, lat: 52.91 },
      { lng: -66.59, lat: 52.9 },
      { lng: -66.62, lat: 52.82 },
      { lng: -66.61, lat: 52.76 },
      { lng: -66.68, lat: 52.72 },
      { lng: -66.71, lat: 52.72 },
      { lng: -66.72, lat: 52.65 },
      { lng: -66.74, lat: 52.63 },
      { lng: -66.8, lat: 52.63 },
      { lng: -66.82, lat: 52.65 },
      { lng: -66.85, lat: 52.63 },
      { lng: -66.92, lat: 52.64 },
      { lng: -66.97, lat: 52.71 },
      { lng: -67.06, lat: 52.71 },
      { lng: -67.07, lat: 52.74 },
      { lng: -67.11, lat: 52.76 },
      { lng: -67.1, lat: 52.82 },
      { lng: -67.12, lat: 52.82 },
      { lng: -67.14, lat: 52.78 },
      { lng: -67.19, lat: 52.78 },
      { lng: -67.21, lat: 52.8 },
      { lng: -67.24, lat: 52.8 },
      { lng: -67.28, lat: 52.84 },
      { lng: -67.36, lat: 52.85 },
      { lng: -67.4, lat: 52.92 },
      { lng: -67.38, lat: 52.96 },
      { lng: -67.4, lat: 52.97 },
      { lng: -67.43, lat: 53.06 },
      { lng: -67.43, lat: 53.14 },
      { lng: -67.4, lat: 53.17 },
      { lng: -67.34, lat: 53.17 },
      { lng: -67.29, lat: 53.22 },
      { lng: -67.24, lat: 53.22 },
      { lng: -67.22, lat: 53.19 },
      { lng: -67.14, lat: 53.19 },
      { lng: -67.12, lat: 53.17 },
      { lng: -67.05, lat: 53.18 },
      { lng: -67.02, lat: 53.21 },
      { lng: -67.01, lat: 53.28 },
      { lng: -67.06, lat: 53.3 },
      { lng: -67.07, lat: 53.34 },
      { lng: -67.04, lat: 53.4 },
      { lng: -66.98, lat: 53.42 },
      { lng: -67.03, lat: 53.44 },
      { lng: -67.05, lat: 53.49 },
      { lng: -67.24, lat: 53.49 },
      { lng: -67.26, lat: 53.51 },
      { lng: -67.33, lat: 53.51 },
      { lng: -67.35, lat: 53.52 },
      { lng: -67.36, lat: 53.56 },
      { lng: -67.4, lat: 53.56 },
      { lng: -67.43, lat: 53.59 },
      { lng: -67.45, lat: 53.67 },
      { lng: -67.54, lat: 53.69 },
      { lng: -67.55, lat: 53.72 },
      { lng: -67.62, lat: 53.72 },
      { lng: -67.64, lat: 53.74 },
      { lng: -67.63, lat: 53.84 },
      { lng: -67.65, lat: 53.88 },
      { lng: -67.7, lat: 53.9 },
      { lng: -67.72, lat: 53.94 },
      { lng: -67.79, lat: 53.95 },
      { lng: -67.85, lat: 53.99 },
      { lng: -67.86, lat: 54.04 },
      { lng: -67.83, lat: 54.07 },
      { lng: -67.85, lat: 54.11 },
    ],
    [
      { lng: -59.38, lat: 47.8 },
      { lng: -59.41, lat: 47.8 },
      { lng: -59.43, lat: 47.85 },
      { lng: -59.45, lat: 47.86 },
      { lng: -59.45, lat: 47.94 },
      { lng: -59.28, lat: 48.05 },
      { lng: -58.95, lat: 48.22 },
      { lng: -58.75, lat: 48.35 },
      { lng: -58.71, lat: 48.41 },
      { lng: -58.59, lat: 48.49 },
      { lng: -58.61, lat: 48.51 },
      { lng: -58.72, lat: 48.51 },
      { lng: -58.76, lat: 48.49 },
      { lng: -58.92, lat: 48.47 },
      { lng: -59.1, lat: 48.47 },
      { lng: -59.2, lat: 48.43 },
      { lng: -59.28, lat: 48.42 },
      { lng: -59.31, lat: 48.45 },
      { lng: -59.28, lat: 48.59 },
      { lng: -58.99, lat: 48.71 },
      { lng: -58.79, lat: 48.83 },
      { lng: -58.63, lat: 48.85 },
      { lng: -58.57, lat: 48.92 },
      { lng: -58.55, lat: 48.95 },
      { lng: -58.55, lat: 49.03 },
      { lng: -58.49, lat: 49.08 },
      { lng: -58.48, lat: 49.12 },
      { lng: -58.43, lat: 49.16 },
      { lng: -58.42, lat: 49.21 },
      { lng: -58.37, lat: 49.27 },
      { lng: -58.3, lat: 49.33 },
      { lng: -58.28, lat: 49.42 },
      { lng: -58.21, lat: 49.49 },
      { lng: -58.05, lat: 49.59 },
      { lng: -58.01, lat: 49.64 },
      { lng: -58, lat: 49.72 },
      { lng: -57.9, lat: 49.86 },
      { lng: -57.87, lat: 49.94 },
      { lng: -57.74, lat: 50.07 },
      { lng: -57.63, lat: 50.25 },
      { lng: -57.55, lat: 50.43 },
      { lng: -57.44, lat: 50.57 },
      { lng: -57.43, lat: 50.61 },
      { lng: -57.36, lat: 50.66 },
      { lng: -57.43, lat: 50.66 },
      { lng: -57.45, lat: 50.68 },
      { lng: -57.45, lat: 50.72 },
      { lng: -57.43, lat: 50.75 },
      { lng: -57.39, lat: 50.76 },
      { lng: -57.35, lat: 50.85 },
      { lng: -57.11, lat: 51.06 },
      { lng: -56.99, lat: 51.09 },
      { lng: -56.89, lat: 51.15 },
      { lng: -56.83, lat: 51.27 },
      { lng: -56.74, lat: 51.35 },
      { lng: -56.58, lat: 51.44 },
      { lng: -56.42, lat: 51.46 },
      { lng: -56.21, lat: 51.55 },
      { lng: -56.11, lat: 51.57 },
      { lng: -55.91, lat: 51.66 },
      { lng: -55.74, lat: 51.62 },
      { lng: -55.57, lat: 51.68 },
      { lng: -55.4, lat: 51.67 },
      { lng: -55.37, lat: 51.63 },
      { lng: -55.38, lat: 51.54 },
      { lng: -55.41, lat: 51.51 },
      { lng: -55.4, lat: 51.48 },
      { lng: -55.43, lat: 51.45 },
      { lng: -55.45, lat: 51.35 },
      { lng: -55.5, lat: 51.31 },
      { lng: -55.55, lat: 51.3 },
      { lng: -55.56, lat: 51.27 },
      { lng: -55.59, lat: 51.25 },
      { lng: -55.64, lat: 51.25 },
      { lng: -55.72, lat: 51.28 },
      { lng: -55.75, lat: 51.27 },
      { lng: -55.79, lat: 51.3 },
      { lng: -55.9, lat: 51.28 },
      { lng: -55.76, lat: 51.25 },
      { lng: -55.72, lat: 51.22 },
      { lng: -55.66, lat: 51.23 },
      { lng: -55.63, lat: 51.2 },
      { lng: -55.64, lat: 51.15 },
      { lng: -55.71, lat: 51.04 },
      { lng: -55.64, lat: 51.01 },
      { lng: -55.6, lat: 51.03 },
      { lng: -55.55, lat: 51.03 },
      { lng: -55.51, lat: 51 },
      { lng: -55.51, lat: 50.94 },
      { lng: -55.55, lat: 50.91 },
      { lng: -55.49, lat: 50.84 },
      { lng: -55.43, lat: 50.82 },
      { lng: -55.43, lat: 50.78 },
      { lng: -55.46, lat: 50.72 },
      { lng: -55.55, lat: 50.66 },
      { lng: -55.63, lat: 50.66 },
      { lng: -55.68, lat: 50.68 },
      { lng: -56.03, lat: 50.73 },
      { lng: -56.1, lat: 50.62 },
      { lng: -56.21, lat: 50.54 },
      { lng: -56.26, lat: 50.47 },
      { lng: -56.39, lat: 50.36 },
      { lng: -56.49, lat: 50.2 },
      { lng: -56.67, lat: 50.04 },
      { lng: -56.71, lat: 49.96 },
      { lng: -56.7, lat: 49.93 },
      { lng: -56.72, lat: 49.86 },
      { lng: -56.75, lat: 49.82 },
      { lng: -56.75, lat: 49.78 },
      { lng: -56.7, lat: 49.79 },
      { lng: -56.62, lat: 49.87 },
      { lng: -56.5, lat: 49.94 },
      { lng: -56.26, lat: 50.15 },
      { lng: -56.18, lat: 50.2 },
      { lng: -55.83, lat: 50.27 },
      { lng: -55.73, lat: 50.27 },
      { lng: -55.69, lat: 50.25 },
      { lng: -55.68, lat: 50.22 },
      { lng: -55.45, lat: 50 },
      { lng: -55.44, lat: 49.96 },
      { lng: -55.45, lat: 49.92 },
      { lng: -55.52, lat: 49.87 },
      { lng: -55.68, lat: 49.79 },
      { lng: -55.73, lat: 49.79 },
      { lng: -55.86, lat: 49.74 },
      { lng: -55.77, lat: 49.7 },
      { lng: -55.33, lat: 49.58 },
      { lng: -55.05, lat: 49.58 },
      { lng: -54.9, lat: 49.64 },
      { lng: -54.86, lat: 49.64 },
      { lng: -54.86, lat: 49.7 },
      { lng: -54.82, lat: 49.73 },
      { lng: -54.74, lat: 49.73 },
      { lng: -54.69, lat: 49.7 },
      { lng: -54.63, lat: 49.69 },
      { lng: -54.56, lat: 49.74 },
      { lng: -54.41, lat: 49.73 },
      { lng: -54.17, lat: 49.79 },
      { lng: -54.08, lat: 49.78 },
      { lng: -53.89, lat: 49.61 },
      { lng: -53.74, lat: 49.63 },
      { lng: -53.72, lat: 49.59 },
      { lng: -53.82, lat: 49.44 },
      { lng: -53.58, lat: 49.37 },
      { lng: -53.46, lat: 49.31 },
      { lng: -53.43, lat: 49.27 },
      { lng: -53.44, lat: 49.2 },
      { lng: -53.52, lat: 49.05 },
      { lng: -53.58, lat: 48.89 },
      { lng: -53.56, lat: 48.84 },
      { lng: -53.59, lat: 48.76 },
      { lng: -53.54, lat: 48.71 },
      { lng: -53.53, lat: 48.61 },
      { lng: -53.46, lat: 48.68 },
      { lng: -53.35, lat: 48.67 },
      { lng: -53.26, lat: 48.61 },
      { lng: -53.22, lat: 48.64 },
      { lng: -53.17, lat: 48.74 },
      { lng: -53.08, lat: 48.76 },
      { lng: -53.04, lat: 48.75 },
      { lng: -52.97, lat: 48.67 },
      { lng: -52.94, lat: 48.61 },
      { lng: -52.95, lat: 48.53 },
      { lng: -53, lat: 48.47 },
      { lng: -53.01, lat: 48.43 },
      { lng: -53.06, lat: 48.38 },
      { lng: -53.19, lat: 48.3 },
      { lng: -53.27, lat: 48.32 },
      { lng: -53.33, lat: 48.3 },
      { lng: -53.33, lat: 48.27 },
      { lng: -53.38, lat: 48.22 },
      { lng: -53.38, lat: 48.18 },
      { lng: -53.47, lat: 48.1 },
      { lng: -53.49, lat: 48.06 },
      { lng: -53.56, lat: 48.01 },
      { lng: -53.7, lat: 47.78 },
      { lng: -53.67, lat: 47.74 },
      { lng: -53.57, lat: 47.72 },
      { lng: -53.51, lat: 47.83 },
      { lng: -53.35, lat: 48.03 },
      { lng: -53.31, lat: 48.06 },
      { lng: -53.16, lat: 48.11 },
      { lng: -53.12, lat: 48.1 },
      { lng: -52.97, lat: 48.22 },
      { lng: -52.75, lat: 48.21 },
      { lng: -52.73, lat: 48.19 },
      { lng: -52.74, lat: 48.08 },
      { lng: -52.76, lat: 48.06 },
      { lng: -52.87, lat: 48.03 },
      { lng: -52.93, lat: 47.98 },
      { lng: -53.01, lat: 47.9 },
      { lng: -53, lat: 47.88 },
      { lng: -53.03, lat: 47.82 },
      { lng: -53.1, lat: 47.75 },
      { lng: -53.08, lat: 47.7 },
      { lng: -53.09, lat: 47.67 },
      { lng: -53.13, lat: 47.64 },
      { lng: -53.12, lat: 47.51 },
      { lng: -53.07, lat: 47.56 },
      { lng: -53.07, lat: 47.63 },
      { lng: -53.01, lat: 47.68 },
      { lng: -52.96, lat: 47.69 },
      { lng: -52.88, lat: 47.74 },
      { lng: -52.84, lat: 47.84 },
      { lng: -52.81, lat: 47.86 },
      { lng: -52.74, lat: 47.86 },
      { lng: -52.72, lat: 47.82 },
      { lng: -52.67, lat: 47.78 },
      { lng: -52.6, lat: 47.63 },
      { lng: -52.61, lat: 47.57 },
      { lng: -52.58, lat: 47.56 },
      { lng: -52.57, lat: 47.52 },
      { lng: -52.62, lat: 47.4 },
      { lng: -52.66, lat: 47.37 },
      { lng: -52.71, lat: 47.29 },
      { lng: -52.71, lat: 47.25 },
      { lng: -52.75, lat: 47.16 },
      { lng: -52.8, lat: 47.09 },
      { lng: -52.8, lat: 47 },
      { lng: -52.84, lat: 46.96 },
      { lng: -52.89, lat: 46.78 },
      { lng: -53.06, lat: 46.6 },
      { lng: -53.12, lat: 46.58 },
      { lng: -53.25, lat: 46.59 },
      { lng: -53.31, lat: 46.66 },
      { lng: -53.39, lat: 46.64 },
      { lng: -53.5, lat: 46.57 },
      { lng: -53.57, lat: 46.56 },
      { lng: -53.66, lat: 46.6 },
      { lng: -53.7, lat: 46.68 },
      { lng: -53.69, lat: 46.75 },
      { lng: -53.71, lat: 46.76 },
      { lng: -53.71, lat: 46.84 },
      { lng: -53.67, lat: 46.89 },
      { lng: -53.76, lat: 46.92 },
      { lng: -53.77, lat: 46.96 },
      { lng: -53.87, lat: 46.85 },
      { lng: -54.05, lat: 46.74 },
      { lng: -54.13, lat: 46.75 },
      { lng: -54.24, lat: 46.79 },
      { lng: -54.24, lat: 46.96 },
      { lng: -54.13, lat: 47.11 },
      { lng: -54.11, lat: 47.18 },
      { lng: -54.07, lat: 47.23 },
      { lng: -54.02, lat: 47.458 },
      { lng: -54.1, lat: 47.35 },
      { lng: -54.16, lat: 47.31 },
      { lng: -54.38, lat: 47.36 },
      { lng: -54.41, lat: 47.38 },
      { lng: -54.51, lat: 47.3 },
      { lng: -54.75, lat: 47.23 },
      { lng: -54.78, lat: 47.19 },
      { lng: -54.81, lat: 47.18 },
      { lng: -54.87, lat: 47.21 },
      { lng: -54.96, lat: 47.19 },
      { lng: -55.05, lat: 47.03 },
      { lng: -55.11, lat: 46.96 },
      { lng: -55.27, lat: 46.86 },
      { lng: -55.38, lat: 46.83 },
      { lng: -55.5, lat: 46.83 },
      { lng: -55.54, lat: 46.87 },
      { lng: -55.57, lat: 46.86 },
      { lng: -55.6, lat: 46.81 },
      { lng: -55.69, lat: 46.82 },
      { lng: -55.74, lat: 46.8 },
      { lng: -55.85, lat: 46.8 },
      { lng: -56.02, lat: 46.89 },
      { lng: -56.04, lat: 46.95 },
      { lng: -56.03, lat: 47.02 },
      { lng: -55.89, lat: 47.12 },
      { lng: -55.77, lat: 47.16 },
      { lng: -55.72, lat: 47.15 },
      { lng: -55.58, lat: 47.17 },
      { lng: -55.44, lat: 47.26 },
      { lng: -55.37, lat: 47.28 },
      { lng: -55.34, lat: 47.37 },
      { lng: -55.34, lat: 47.42 },
      { lng: -55.2, lat: 47.51 },
      { lng: -55.14, lat: 47.53 },
      { lng: -55.28, lat: 47.58 },
      { lng: -55.3, lat: 47.49 },
      { lng: -55.38, lat: 47.43 },
      { lng: -55.53, lat: 47.4 },
      { lng: -55.55, lat: 47.36 },
      { lng: -55.66, lat: 47.35 },
      { lng: -55.76, lat: 47.32 },
      { lng: -55.79, lat: 47.25 },
      { lng: -55.84, lat: 47.21 },
      { lng: -56.03, lat: 47.19 },
      { lng: -56.05, lat: 47.24 },
      { lng: -56, lat: 47.4 },
      { lng: -56.25, lat: 47.43 },
      { lng: -56.27, lat: 47.46 },
      { lng: -56.25, lat: 47.53 },
      { lng: -56.12, lat: 47.57 },
      { lng: -56.55, lat: 47.57 },
      { lng: -56.8, lat: 47.48 },
      { lng: -56.88, lat: 47.48 },
      { lng: -57.01, lat: 47.54 },
      { lng: -57.15, lat: 47.53 },
      { lng: -57.21, lat: 47.55 },
      { lng: -57.29, lat: 47.46 },
      { lng: -57.38, lat: 47.45 },
      { lng: -57.47, lat: 47.46 },
      { lng: -57.5, lat: 47.48 },
      { lng: -57.51, lat: 47.52 },
      { lng: -57.46, lat: 47.57 },
      { lng: -57.53, lat: 47.57 },
      { lng: -57.61, lat: 47.51 },
      { lng: -57.7, lat: 47.51 },
      { lng: -57.76, lat: 47.58 },
      { lng: -57.84, lat: 47.58 },
      { lng: -57.94, lat: 47.62 },
      { lng: -58.01, lat: 47.6 },
      { lng: -58.15, lat: 47.63 },
      { lng: -58.21, lat: 47.6 },
      { lng: -58.54, lat: 47.61 },
      { lng: -58.7, lat: 47.56 },
      { lng: -58.89, lat: 47.56 },
      { lng: -58.99, lat: 47.53 },
      { lng: -59.15, lat: 47.52 },
      { lng: -59.25, lat: 47.54 },
      { lng: -59.35, lat: 47.59 },
      { lng: -59.38, lat: 47.8 },
    ],
  ],

  // USA STATES
  // ALABAMA
  AL: [
    { lat: 35.0041, lng: -88.1955 },
    { lat: 34.9918, lng: -85.6068 },
    { lat: 32.8404, lng: -85.1756 },
    { lat: 32.2593, lng: -84.8927 },
    { lat: 32.1535, lng: -85.0342 },
    { lat: 31.7947, lng: -85.1358 },
    { lat: 31.5200, lng: -85.0438 },
    { lat: 31.3384, lng: -85.0836 },
    { lat: 31.2093, lng: -85.1070 },
    { lat: 31.0023, lng: -84.9944 },
    { lat: 30.9953, lng: -87.6009 },
    { lat: 30.9423, lng: -87.5926 },
    { lat: 30.8539, lng: -87.6256 },
    { lat: 30.6745, lng: -87.4072 },
    { lat: 30.4404, lng: -87.3688 },
    { lat: 30.1463, lng: -87.5240 },
    { lat: 30.1546, lng: -88.3864 },
    { lat: 31.8939, lng: -88.4743 },
    { lat: 34.8938, lng: -88.1021 },
    { lat: 34.9479, lng: -88.1721 },
    { lat: 34.9107, lng: -88.1461 },
  ],
  // ALASKA
  AK: [
    { lat: 70.0187, lng: -141.0205 },
    { lat: 70.1292, lng: -141.7291 },
    { lat: 70.4515, lng: -144.8163 },
    { lat: 70.7471, lng: -148.4583 },
    { lat: 70.7923, lng: -151.1609 },
    { lat: 71.1470, lng: -152.6221 },
    { lat: 71.1185, lng: -153.9954 },
    { lat: 71.4307, lng: -154.8853 },
    { lat: 71.5232, lng: -156.7529 },
    { lat: 71.2796, lng: -157.9449 },
    { lat: 71.2249, lng: -159.6313 },
    { lat: 70.6363, lng: -161.8671 },
    { lat: 70.0843, lng: -163.5809 },
    { lat: 69.3028, lng: -165.2399 },
    { lat: 69.1782, lng: -166.8768 },
    { lat: 68.3344, lng: -168.0414 },
    { lat: 67.6844, lng: -165.9155 },
    { lat: 67.2933, lng: -164.6082 },
    { lat: 66.7789, lng: -164.0149 },
    { lat: 66.5810, lng: -165.7507 },
    { lat: 66.2867, lng: -167.5745 },
    { lat: 66.0269, lng: -168.9862 },
    { lat: 65.4970, lng: -168.9478 },
    { lat: 65.0420, lng: -167.4756 },
    { lat: 64.3922, lng: -167.0142 },
    { lat: 64.0554, lng: -165.7343 },
    { lat: 64.0193, lng: -163.2294 },
    { lat: 63.9615, lng: -162.1143 },
    { lat: 63.6877, lng: -163.6029 },
    { lat: 63.4530, lng: -165.3717 },
    { lat: 62.4133, lng: -166.3715 },
    { lat: 61.6534, lng: -166.9867 },
    { lat: 60.8556, lng: -166.4429 },
    { lat: 60.5357, lng: -167.8381 },
    { lat: 59.5482, lng: -167.7118 },
    { lat: 59.4115, lng: -165.8002 },
    { lat: 59.3696, lng: -164.5972 },
    { lat: 59.1168, lng: -162.8558 },
    { lat: 58.1185, lng: -162.5427 },
    { lat: 58.1359, lng: -160.6421 },
    { lat: 58.0285, lng: -159.5050 },
    { lat: 57.6336, lng: -158.8953 },
    { lat: 56.9090, lng: -159.9060 },
    { lat: 56.3926, lng: -160.6531 },
    { lat: 56.2342, lng: -161.8835 },
    { lat: 55.7240, lng: -162.9822 },
    { lat: 55.2478, lng: -164.3994 },
    { lat: 54.7753, lng: -165.3168 },
    { lat: 54.1463, lng: -167.1075 },
    { lat: 53.5632, lng: -168.5852 },
    { lat: 53.1402, lng: -169.9146 },
    { lat: 52.5964, lng: -169.5959 },
    { lat: 52.9089, lng: -168.2227 },
    { lat: 54.2139, lng: -162.7734 },
    { lat: 54.6786, lng: -159.1452 },
    { lat: 55.6567, lng: -155.4634 },
    { lat: 57.3510, lng: -152.1400 },
    { lat: 59.2209, lng: -150.8203 },
    { lat: 59.7695, lng: -147.4461 },
    { lat: 60.3521, lng: -145.9850 },
    { lat: 59.8917, lng: -144.1544 },
    { lat: 59.8172, lng: -141.6811 },
    { lat: 59.5225, lng: -140.5124 },
    { lat: 59.0292, lng: -138.8548 },
    { lat: 57.9032, lng: -136.8526 },
    { lat: 56.9157, lng: -136.0725 },
    { lat: 56.1555, lng: -134.9794 },
    { lat: 55.3237, lng: -134.0057 },
    { lat: 54.6341, lng: -133.6418 },
    { lat: 54.7135, lng: -130.6261 },
    { lat: 55.2869, lng: -129.9930 },
    { lat: 55.9869, lng: -130.0108 },
    { lat: 56.1057, lng: -130.1083 },
    { lat: 56.6086, lng: -131.5887 },
    { lat: 57.8404, lng: -132.8755 },
    { lat: 58.7276, lng: -133.8423 },
    { lat: 59.3108, lng: -134.9121 },
    { lat: 59.8020, lng: -135.4724 },
    { lat: 59.6039, lng: -136.3445 },
    { lat: 59.1619, lng: -136.8251 },
    { lat: 59.2441, lng: -137.6079 },
    { lat: 60.0902, lng: -139.2119 },
    { lat: 60.3575, lng: -139.0938 },
    { lat: 60.1866, lng: -140.0056 },
    { lat: 60.3059, lng: -140.9999 },
    { lat: 70.0187, lng: -141.0205 },
  ],
  // ARIZONA
  AZ: [
    { lat: 36.9993, lng: -112.5989 },
    { lat: 37.0004, lng: -110.8630 },
    { lat: 37.0004, lng: -109.0475 },
    { lat: 31.3325, lng: -109.0503 },
    { lat: 31.3325, lng: -111.0718 },
    { lat: 32.4935, lng: -114.8126 },
    { lat: 32.5184, lng: -114.8099 },
    { lat: 32.5827, lng: -114.8044 },
    { lat: 32.6246, lng: -114.7992 },
    { lat: 32.6700, lng: -114.7474 },
    { lat: 32.7457, lng: -114.7014 },
    { lat: 32.7342, lng: -114.6176 },
    { lat: 32.7422, lng: -114.5819 },
    { lat: 32.7584, lng: -114.5393 },
    { lat: 32.8167, lng: -114.5095 },
    { lat: 32.8450, lng: -114.4696 },
    { lat: 32.9107, lng: -114.4817 },
    { lat: 32.9741, lng: -114.4803 },
    { lat: 33.0317, lng: -114.5256 },
    { lat: 33.0259, lng: -114.6094 },
    { lat: 33.0317, lng: -114.6588 },
    { lat: 33.0904, lng: -114.7096 },
    { lat: 33.2065, lng: -114.6849 },
    { lat: 33.2846, lng: -114.7220 },
    { lat: 33.3546, lng: -114.6973 },
    { lat: 33.4051, lng: -114.7258 },
    { lat: 33.4120, lng: -114.6533 },
    { lat: 33.5016, lng: -114.5888 },
    { lat: 33.5317, lng: -114.5599 },
    { lat: 33.6306, lng: -114.5187 },
    { lat: 33.6786, lng: -114.5297 },
    { lat: 33.7083, lng: -114.4940 },
    { lat: 33.7609, lng: -114.5036 },
    { lat: 33.8157, lng: -114.5284 },
    { lat: 33.8545, lng: -114.5325 },
    { lat: 33.9285, lng: -114.5380 },
    { lat: 33.9530, lng: -114.5235 },
    { lat: 34.0049, lng: -114.4748 },
    { lat: 34.0299, lng: -114.4308 },
    { lat: 34.0891, lng: -114.4363 },
    { lat: 34.1357, lng: -114.3526 },
    { lat: 34.1720, lng: -114.2908 },
    { lat: 34.2044, lng: -114.2255 },
    { lat: 34.2595, lng: -114.1685 },
    { lat: 34.2572, lng: -114.1301 },
    { lat: 34.3037, lng: -114.1397 },
    { lat: 34.3664, lng: -114.2276 },
    { lat: 34.4012, lng: -114.2633 },
    { lat: 34.4534, lng: -114.3388 },
    { lat: 34.4930, lng: -114.3608 },
    { lat: 34.5292, lng: -114.3811 },
    { lat: 34.5959, lng: -114.4377 },
    { lat: 34.6547, lng: -114.4569 },
    { lat: 34.7506, lng: -114.5297 },
    { lat: 34.8172, lng: -114.5847 },
    { lat: 34.8724, lng: -114.6341 },
    { lat: 34.9490, lng: -114.6313 },
    { lat: 35.0342, lng: -114.6351 },
    { lat: 35.1019, lng: -114.6451 },
    { lat: 35.1233, lng: -114.6190 },
    { lat: 35.1716, lng: -114.5682 },
    { lat: 35.3364, lng: -114.5984 },
    { lat: 35.4506, lng: -114.6643 },
    { lat: 35.5780, lng: -114.6753 },
    { lat: 35.6171, lng: -114.6547 },
    { lat: 35.6528, lng: -114.6918 },
    { lat: 35.7053, lng: -114.7028 },
    { lat: 35.8050, lng: -114.7093 },
    { lat: 35.8679, lng: -114.6602 },
    { lat: 35.9836, lng: -114.7426 },
    { lat: 36.0891, lng: -114.7536 },
    { lat: 36.1124, lng: -114.6794 },
    { lat: 36.1423, lng: -114.6327 },
    { lat: 36.1301, lng: -114.4872 },
    { lat: 36.1445, lng: -114.3690 },
    { lat: 36.0746, lng: -114.3038 },
    { lat: 36.0602, lng: -114.3172 },
    { lat: 36.0163, lng: -114.2451 },
    { lat: 36.0402, lng: -114.1438 },
    { lat: 36.0979, lng: -114.1150 },
    { lat: 36.1101, lng: -114.1274 },
    { lat: 36.1190, lng: -114.1054 },
    { lat: 36.1989, lng: -114.0463 },
    { lat: 36.3638, lng: -114.0450 },
    { lat: 37.0001, lng: -114.0508 },
  ],
  // ARKANSAS
  AR: [
    { lat: 33.0225, lng: -94.0416 },
    { lat: 33.0075, lng: -91.2057 },
    { lat: 33.1180, lng: -91.1989 },
    { lat: 33.1824, lng: -91.1041 },
    { lat: 33.3053, lng: -91.1343 },
    { lat: 33.4211, lng: -91.1646 },
    { lat: 33.4337, lng: -91.2263 },
    { lat: 33.5403, lng: -91.2524 },
    { lat: 33.6112, lng: -91.1797 },
    { lat: 33.6855, lng: -91.2524 },
    { lat: 33.6946, lng: -91.1261 },
    { lat: 33.7883, lng: -91.1412 },
    { lat: 33.7700, lng: -91.0451 },
    { lat: 33.8328, lng: -91.0341 },
    { lat: 33.9399, lng: -91.0863 },
    { lat: 34.0208, lng: -90.9256 },
    { lat: 34.0856, lng: -90.9036 },
    { lat: 34.1345, lng: -90.9586 },
    { lat: 34.1675, lng: -90.9132 },
    { lat: 34.1380, lng: -90.8501 },
    { lat: 34.2311, lng: -90.9325 },
    { lat: 34.3446, lng: -90.6935 },
    { lat: 34.4409, lng: -90.5603 },
    { lat: 34.5348, lng: -90.5548 },
    { lat: 34.5959, lng: -90.5768 },
    { lat: 34.7213, lng: -90.5301 },
    { lat: 34.7574, lng: -90.5328 },
    { lat: 34.8780, lng: -90.4546 },
    { lat: 34.8454, lng: -90.3529 },
    { lat: 34.8690, lng: -90.2911 },
    { lat: 35.0255, lng: -90.3104 },
    { lat: 35.1154, lng: -90.2843 },
    { lat: 35.1323, lng: -90.1772 },
    { lat: 35.1985, lng: -90.1112 },
    { lat: 35.2826, lng: -90.1524 },
    { lat: 35.4383, lng: -90.1332 },
    { lat: 35.5579, lng: -90.0206 },
    { lat: 35.6740, lng: -89.9780 },
    { lat: 35.7287, lng: -89.9547 },
    { lat: 35.9169, lng: -89.6594 },
    { lat: 35.9658, lng: -89.6883 },
    { lat: 36.0013, lng: -89.7130 },
    { lat: 35.9958, lng: -90.3735 },
    { lat: 36.1268, lng: -90.2664 },
    { lat: 36.2875, lng: -90.0934 },
    { lat: 36.3892, lng: -90.0742 },
    { lat: 36.4180, lng: -90.1511 },
    { lat: 36.4997, lng: -90.1566 },
    { lat: 36.4986, lng: -94.6198 },
    { lat: 35.3801, lng: -94.4412 },
    { lat: 33.6318, lng: -94.4893 },
    { lat: 33.6421, lng: -94.4522 },
    { lat: 33.5597, lng: -94.4000 },
    { lat: 33.5883, lng: -94.2462 },
    { lat: 33.5872, lng: -94.1885 },
    { lat: 33.5345, lng: -94.0375 },
    { lat: 33.4314, lng: -94.0430 },
    { lat: 33.0213, lng: -94.0430 },
  ],
  // CALIFORNIA
  CA: [
    { lat: 41.9983, lng: -124.4009 },
    { lat: 42.0024, lng: -123.6237 },
    { lat: 42.0126, lng: -123.1526 },
    { lat: 42.0075, lng: -122.0073 },
    { lat: 41.9962, lng: -121.2369 },
    { lat: 41.9983, lng: -119.9982 },
    { lat: 39.0021, lng: -120.0037 },
    { lat: 37.5555, lng: -117.9575 },
    { lat: 36.3594, lng: -116.3699 },
    { lat: 35.0075, lng: -114.6368 },
    { lat: 34.9659, lng: -114.6382 },
    { lat: 34.9107, lng: -114.6286 },
    { lat: 34.8758, lng: -114.6382 },
    { lat: 34.8454, lng: -114.5970 },
    { lat: 34.7890, lng: -114.5682 },
    { lat: 34.7269, lng: -114.4968 },
    { lat: 34.6648, lng: -114.4501 },
    { lat: 34.6581, lng: -114.4597 },
    { lat: 34.5869, lng: -114.4322 },
    { lat: 34.5235, lng: -114.3787 },
    { lat: 34.4601, lng: -114.3869 },
    { lat: 34.4500, lng: -114.3361 },
    { lat: 34.4375, lng: -114.3031 },
    { lat: 34.4024, lng: -114.2674 },
    { lat: 34.3559, lng: -114.1864 },
    { lat: 34.3049, lng: -114.1383 },
    { lat: 34.2561, lng: -114.1315 },
    { lat: 34.2595, lng: -114.1651 },
    { lat: 34.2044, lng: -114.2249 },
    { lat: 34.1914, lng: -114.2221 },
    { lat: 34.1720, lng: -114.2908 },
    { lat: 34.1368, lng: -114.3237 },
    { lat: 34.1186, lng: -114.3622 },
    { lat: 34.1118, lng: -114.4089 },
    { lat: 34.0856, lng: -114.4363 },
    { lat: 34.0276, lng: -114.4336 },
    { lat: 34.0117, lng: -114.4652 },
    { lat: 33.9582, lng: -114.5119 },
    { lat: 33.9308, lng: -114.5366 },
    { lat: 33.9058, lng: -114.5091 },
    { lat: 33.8613, lng: -114.5256 },
    { lat: 33.8248, lng: -114.5215 },
    { lat: 33.7597, lng: -114.5050 },
    { lat: 33.7083, lng: -114.4940 },
    { lat: 33.6832, lng: -114.5284 },
    { lat: 33.6363, lng: -114.5242 },
    { lat: 33.5895, lng: -114.5393 },
    { lat: 33.5528, lng: -114.5242 },
    { lat: 33.5311, lng: -114.5586 },
    { lat: 33.5070, lng: -114.5778 },
    { lat: 33.4418, lng: -114.6245 },
    { lat: 33.4142, lng: -114.6506 },
    { lat: 33.4039, lng: -114.7055 },
    { lat: 33.3546, lng: -114.6973 },
    { lat: 33.3041, lng: -114.7302 },
    { lat: 33.2858, lng: -114.7206 },
    { lat: 33.2754, lng: -114.6808 },
    { lat: 33.2582, lng: -114.6698 },
    { lat: 33.2467, lng: -114.6904 },
    { lat: 33.1720, lng: -114.6794 },
    { lat: 33.0904, lng: -114.7083 },
    { lat: 33.0858, lng: -114.6918 },
    { lat: 33.0328, lng: -114.6629 },
    { lat: 33.0501, lng: -114.6451 },
    { lat: 33.0305, lng: -114.6286 },
    { lat: 33.0282, lng: -114.5888 },
    { lat: 33.0351, lng: -114.5750 },
    { lat: 33.0328, lng: -114.5174 },
    { lat: 32.9718, lng: -114.4913 },
    { lat: 32.9764, lng: -114.4775 },
    { lat: 32.9372, lng: -114.4844 },
    { lat: 32.8427, lng: -114.4679 },
    { lat: 32.8161, lng: -114.5091 },
    { lat: 32.7850, lng: -114.5311 },
    { lat: 32.7573, lng: -114.5284 },
    { lat: 32.7503, lng: -114.5641 },
    { lat: 32.7353, lng: -114.6162 },
    { lat: 32.7480, lng: -114.6986 },
    { lat: 32.7191, lng: -114.7220 },
    { lat: 32.6868, lng: -115.1944 },
    { lat: 32.5121, lng: -117.3395 },
    { lat: 32.7838, lng: -117.4823 },
    { lat: 33.0501, lng: -117.5977 },
    { lat: 33.2341, lng: -117.6814 },
    { lat: 33.4578, lng: -118.0591 },
    { lat: 33.5403, lng: -118.6290 },
    { lat: 33.7928, lng: -118.7073 },
    { lat: 33.9582, lng: -119.3706 },
    { lat: 34.1925, lng: -120.0050 },
    { lat: 34.2561, lng: -120.7164 },
    { lat: 34.5360, lng: -120.9128 },
    { lat: 34.9749, lng: -120.8427 },
    { lat: 35.2131, lng: -121.1325 },
    { lat: 35.5255, lng: -121.3220 },
    { lat: 35.9691, lng: -121.8013 },
    { lat: 36.2808, lng: -122.1446 },
    { lat: 36.7268, lng: -122.1721 },
    { lat: 37.2227, lng: -122.6871 },
    { lat: 37.7783, lng: -122.8903 },
    { lat: 37.8965, lng: -123.2378 },
    { lat: 38.3449, lng: -123.3202 },
    { lat: 38.7423, lng: -123.8338 },
    { lat: 38.9946, lng: -123.9793 },
    { lat: 39.3088, lng: -124.0329 },
    { lat: 39.7642, lng: -124.0823 },
    { lat: 40.1663, lng: -124.5314 },
    { lat: 40.4658, lng: -124.6509 },
    { lat: 41.0110, lng: -124.3144 },
    { lat: 41.2386, lng: -124.3419 },
    { lat: 41.7170, lng: -124.4545 },
    { lat: 41.9983, lng: -124.4009 },
  ],
  // COLORADO
  CO: [
    { lat: 37.0004, lng: -109.0448 },
    { lat: 36.9949, lng: -102.0424 },
    { lat: 41.0006, lng: -102.0534 },
    { lat: 40.9996, lng: -109.0489 },
    { lat: 37.0004, lng: -109.0448 },
  ],
  // CONNECTICUT
  CT: [
    { lat: 42.0498, lng: -73.4875 },
    { lat: 42.0511, lng: -73.4247 },
    { lat: 42.0371, lng: -72.8146 },
    { lat: 41.9983, lng: -72.8174 },
    { lat: 42.0044, lng: -72.7638 },
    { lat: 42.0360, lng: -72.7563 },
    { lat: 42.0368, lng: -72.6945 },
    { lat: 42.0309, lng: -72.6086 },
    { lat: 42.0269, lng: -72.6059 },
    { lat: 42.0269, lng: -72.5784 },
    { lat: 42.0350, lng: -72.5729 },
    { lat: 42.0350, lng: -72.4026 },
    { lat: 42.0248, lng: -71.7984 },
    { lat: 41.6832, lng: -71.7874 },
    { lat: 41.4165, lng: -71.7984 },
    { lat: 41.3892, lng: -71.8341 },
    { lat: 41.3273, lng: -71.8526 },
    { lat: 41.3309, lng: -71.8938 },
    { lat: 41.3103, lng: -71.9302 },
    { lat: 41.2907, lng: -72.0195 },
    { lat: 41.2618, lng: -72.0827 },
    { lat: 41.1962, lng: -72.4322 },
    { lat: 41.0866, lng: -73.0007 },
    { lat: 41.0255, lng: -73.2493 },
    { lat: 40.9509, lng: -73.6132 },
    { lat: 40.9830, lng: -73.6606 },
    { lat: 41.0338, lng: -73.6723 },
    { lat: 41.1011, lng: -73.7272 },
    { lat: 41.2153, lng: -73.4834 },
    { lat: 41.2953, lng: -73.5507 },
    { lat: 41.4906, lng: -73.5329 },
    { lat: 42.0493, lng: -73.4875 },
  ],
  // DELAWARE
  DE: [
    { lat: 39.7188, lng: -75.7919 },
    { lat: 39.5210, lng: -75.7837 },
    { lat: 38.9081, lng: -75.7288 },
    { lat: 38.5911, lng: -75.7068 },
    { lat: 38.4600, lng: -75.6944 },
    { lat: 38.4482, lng: -74.8608 },
    { lat: 38.8654, lng: -74.8526 },
    { lat: 38.8451, lng: -75.0504 },
    { lat: 39.0565, lng: -75.1678 },
    { lat: 39.2525, lng: -75.3236 },
    { lat: 39.3662, lng: -75.4610 },
    { lat: 39.4542, lng: -75.5592 },
    { lat: 39.4908, lng: -75.5578 },
    { lat: 39.5713, lng: -75.5118 },
    { lat: 39.6284, lng: -75.5557 },
    { lat: 39.8106, lng: -75.3937 },
    { lat: 39.8249, lng: -75.4692 },
    { lat: 39.8296, lng: -75.6477 },
    { lat: 39.7199, lng: -75.7906 },
  ],
  // FLORIDA
  FL: [
    { lat: 30.9988, lng: -87.6050 },
    { lat: 30.9964, lng: -86.5613 },
    { lat: 31.0035, lng: -85.5313 },
    { lat: 31.0012, lng: -85.1193 },
    { lat: 31.0023, lng: -85.0012 },
    { lat: 30.9364, lng: -84.9847 },
    { lat: 30.8845, lng: -84.9367 },
    { lat: 30.8409, lng: -84.9271 },
    { lat: 30.7902, lng: -84.9257 },
    { lat: 30.7489, lng: -84.9147 },
    { lat: 30.6993, lng: -84.8611 },
    { lat: 30.6911, lng: -84.4272 },
    { lat: 30.6509, lng: -83.5991 },
    { lat: 30.5895, lng: -82.5595 },
    { lat: 30.5682, lng: -82.2134 },
    { lat: 30.5315, lng: -82.2134 },
    { lat: 30.3883, lng: -82.1997 },
    { lat: 30.3598, lng: -82.1544 },
    { lat: 30.3598, lng: -82.0638 },
    { lat: 30.4877, lng: -82.0226 },
    { lat: 30.6308, lng: -82.0473 },
    { lat: 30.6757, lng: -82.0514 },
    { lat: 30.7111, lng: -82.0377 },
    { lat: 30.7371, lng: -82.0514 },
    { lat: 30.7678, lng: -82.0102 },
    { lat: 30.7914, lng: -82.0322 },
    { lat: 30.7997, lng: -81.9717 },
    { lat: 30.8244, lng: -81.9608 },
    { lat: 30.8056, lng: -81.8893 },
    { lat: 30.7914, lng: -81.8372 },
    { lat: 30.7796, lng: -81.7960 },
    { lat: 30.7536, lng: -81.6696 },
    { lat: 30.7289, lng: -81.6051 },
    { lat: 30.7324, lng: -81.5666 },
    { lat: 30.7229, lng: -81.5295 },
    { lat: 30.7253, lng: -81.4856 },
    { lat: 30.7111, lng: -81.4609 },
    { lat: 30.7088, lng: -81.4169 },
    { lat: 30.7064, lng: -81.2274 },
    { lat: 30.4345, lng: -81.2357 },
    { lat: 30.3160, lng: -81.1725 },
    { lat: 29.7763, lng: -81.0379 },
    { lat: 28.8603, lng: -80.5861 },
    { lat: 28.4771, lng: -80.3650 },
    { lat: 28.1882, lng: -80.3815 },
    { lat: 27.1789, lng: -79.9255 },
    { lat: 26.8425, lng: -79.8198 },
    { lat: 26.1394, lng: -79.9118 },
    { lat: 25.5115, lng: -79.9997 },
    { lat: 24.8802, lng: -80.3815 },
    { lat: 24.5384, lng: -80.8704 },
    { lat: 24.3959, lng: -81.9250 },
    { lat: 24.4496, lng: -82.2066 },
    { lat: 24.5484, lng: -82.3137 },
    { lat: 24.6982, lng: -82.1997 },
    { lat: 25.2112, lng: -81.3977 },
    { lat: 25.6019, lng: -81.4622 },
    { lat: 25.9235, lng: -81.9456 },
    { lat: 26.3439, lng: -82.2876 },
    { lat: 26.9098, lng: -82.5307 },
    { lat: 27.3315, lng: -82.8342 },
    { lat: 27.7565, lng: -83.0182 },
    { lat: 28.0574, lng: -83.0017 },
    { lat: 28.6098, lng: -82.8548 },
    { lat: 28.9697, lng: -83.0264 },
    { lat: 29.0478, lng: -83.2050 },
    { lat: 29.4157, lng: -83.5318 },
    { lat: 29.9133, lng: -83.9767 },
    { lat: 29.8930, lng: -84.1072 },
    { lat: 29.6940, lng: -84.4409 },
    { lat: 29.4551, lng: -85.0465 },
    { lat: 29.4946, lng: -85.3610 },
    { lat: 29.7262, lng: -85.5807 },
    { lat: 30.1594, lng: -86.1946 },
    { lat: 30.2175, lng: -86.8510 },
    { lat: 30.1499, lng: -87.5171 },
    { lat: 30.3006, lng: -87.4429 },
    { lat: 30.4256, lng: -87.3750 },
    { lat: 30.4830, lng: -87.3743 },
    { lat: 30.5658, lng: -87.3907 },
    { lat: 30.6344, lng: -87.4004 },
    { lat: 30.6763, lng: -87.4141 },
    { lat: 30.7702, lng: -87.5253 },
    { lat: 30.8527, lng: -87.6256 },
    { lat: 30.9470, lng: -87.5912 },
    { lat: 30.9682, lng: -87.5912 },
    { lat: 30.9964, lng: -87.6050 },
  ],
  // GEORGIA
  GA: [
    { lat: 34.9974, lng: -85.6082 },
    { lat: 34.9906, lng: -84.7266 },
    { lat: 34.9895, lng: -84.1580 },
    { lat: 34.9996, lng: -83.1088 },
    { lat: 34.9287, lng: -83.1418 },
    { lat: 34.8318, lng: -83.3025 },
    { lat: 34.7281, lng: -83.3560 },
    { lat: 34.6569, lng: -83.3080 },
    { lat: 34.5744, lng: -83.1528 },
    { lat: 34.4839, lng: -83.0072 },
    { lat: 34.4681, lng: -82.8918 },
    { lat: 34.4443, lng: -82.8589 },
    { lat: 34.2674, lng: -82.7490 },
    { lat: 34.1254, lng: -82.6831 },
    { lat: 34.0140, lng: -82.5952 },
    { lat: 33.8647, lng: -82.3988 },
    { lat: 33.7563, lng: -82.2505 },
    { lat: 33.6695, lng: -82.2217 },
    { lat: 33.5963, lng: -82.1558 },
    { lat: 33.5036, lng: -82.0432 },
    { lat: 33.3707, lng: -81.9484 },
    { lat: 33.2077, lng: -81.8303 },
    { lat: 33.1674, lng: -81.7795 },
    { lat: 33.1456, lng: -81.7424 },
    { lat: 33.0881, lng: -81.6078 },
    { lat: 33.0075, lng: -81.5034 },
    { lat: 32.9418, lng: -81.5089 },
    { lat: 32.6914, lng: -81.4142 },
    { lat: 32.5815, lng: -81.4087 },
    { lat: 32.5283, lng: -81.2769 },
    { lat: 32.4576, lng: -81.1945 },
    { lat: 32.3185, lng: -81.1642 },
    { lat: 32.2151, lng: -81.1436 },
    { lat: 32.1128, lng: -81.1134 },
    { lat: 32.0477, lng: -80.9225 },
    { lat: 32.0500, lng: -80.6960 },
    { lat: 31.8881, lng: -80.7289 },
    { lat: 31.4697, lng: -80.9665 },
    { lat: 30.9988, lng: -81.1011 },
    { lat: 30.7041, lng: -81.2288 },
    { lat: 30.7241, lng: -81.6023 },
    { lat: 30.7713, lng: -81.7657 },
    { lat: 30.8221, lng: -81.9498 },
    { lat: 30.7560, lng: -82.0239 },
    { lat: 30.6379, lng: -82.0459 },
    { lat: 30.4866, lng: -82.0239 },
    { lat: 30.4309, lng: -82.0363 },
    { lat: 30.3575, lng: -82.0610 },
    { lat: 30.3598, lng: -82.1585 },
    { lat: 30.3859, lng: -82.2025 },
    { lat: 30.4842, lng: -82.2148 },
    { lat: 30.5682, lng: -82.2162 },
    { lat: 30.6131, lng: -82.9688 },
    { lat: 30.7041, lng: -84.8639 },
    { lat: 30.7831, lng: -84.9257 },
    { lat: 30.9117, lng: -84.9586 },
    { lat: 30.9741, lng: -84.9985 },
    { lat: 31.1282, lng: -85.0630 },
    { lat: 31.2116, lng: -85.1070 },
    { lat: 31.5247, lng: -85.0493 },
    { lat: 31.8006, lng: -85.1358 },
    { lat: 31.9592, lng: -85.0919 },
    { lat: 32.1570, lng: -85.0342 },
    { lat: 32.2500, lng: -84.9023 },
    { lat: 32.3974, lng: -84.9628 },
    { lat: 32.5468, lng: -85.0342 },
    { lat: 32.6949, lng: -85.1001 },
    { lat: 32.8138, lng: -85.1660 },
    { lat: 32.9833, lng: -85.2072 },
    { lat: 33.6512, lng: -85.3418 },
    { lat: 34.5620, lng: -85.5231 },
    { lat: 34.9929, lng: -85.6068 },
  ],
  // HAWAII
  HI: [
    { lat: 19.5158, lng: -154.6284 },
    { lat: 19.8894, lng: -154.8235 },
    { lat: 20.1849, lng: -155.0391 },
    { lat: 20.3910, lng: -155.5952 },
    { lat: 20.8614, lng: -155.7930 },
    { lat: 21.0948, lng: -156.1858 },
    { lat: 21.3597, lng: -156.6595 },
    { lat: 21.4748, lng: -157.1182 },
    { lat: 21.5515, lng: -157.5247 },
    { lat: 21.8832, lng: -157.8186 },
    { lat: 22.3386, lng: -159.2180 },
    { lat: 22.3374, lng: -159.7508 },
    { lat: 22.1493, lng: -160.1422 },
    { lat: 21.8857, lng: -160.3922 },
    { lat: 21.6025, lng: -160.3249 },
    { lat: 21.7276, lng: -159.9939 },
    { lat: 21.6932, lng: -159.4295 },
    { lat: 21.1037, lng: -158.1784 },
    { lat: 20.8152, lng: -157.4629 },
    { lat: 20.6803, lng: -157.2171 },
    { lat: 20.3408, lng: -156.8285 },
    { lat: 20.2931, lng: -156.5456 },
    { lat: 20.1643, lng: -156.1418 },
    { lat: 19.9308, lng: -156.2393 },
    { lat: 19.2826, lng: -156.1432 },
    { lat: 18.9881, lng: -156.1212 },
    { lat: 18.7100, lng: -155.7120 },
    { lat: 18.8829, lng: -155.3673 },
    { lat: 19.2048, lng: -154.8839 },
    { lat: 19.5158, lng: -154.6271 },
  ],
  // IDAHO
  ID: [
    { lat: 49.0000, lng: -117.0319 },
    { lat: 49.0018, lng: -116.0486 },
    { lat: 47.9752, lng: -116.0445 },
    { lat: 47.5765, lng: -115.6915 },
    { lat: 47.5487, lng: -115.7574 },
    { lat: 47.4239, lng: -115.7595 },
    { lat: 47.3109, lng: -115.5350 },
    { lat: 47.2606, lng: -115.3235 },
    { lat: 47.1888, lng: -115.2878 },
    { lat: 47.1542, lng: -115.2493 },
    { lat: 46.9728, lng: -115.0433 },
    { lat: 46.8677, lng: -114.9472 },
    { lat: 46.7201, lng: -114.7865 },
    { lat: 46.6984, lng: -114.7549 },
    { lat: 46.6325, lng: -114.5874 },
    { lat: 46.6325, lng: -114.4638 },
    { lat: 46.6466, lng: -114.3279 },
    { lat: 46.5135, lng: -114.3430 },
    { lat: 46.4530, lng: -114.3896 },
    { lat: 46.3488, lng: -114.4144 },
    { lat: 46.2682, lng: -114.4611 },
    { lat: 46.1227, lng: -114.5105 },
    { lat: 45.8585, lng: -114.4418 },
    { lat: 45.7742, lng: -114.5654 },
    { lat: 45.6745, lng: -114.5229 },
    { lat: 45.5621, lng: -114.5654 },
    { lat: 45.5439, lng: -114.4666 },
    { lat: 45.4601, lng: -114.3375 },
    { lat: 45.5468, lng: -114.2441 },
    { lat: 45.5631, lng: -114.1342 },
    { lat: 45.6889, lng: -113.9708 },
    { lat: 45.6102, lng: -113.8403 },
    { lat: 45.4409, lng: -113.7978 },
    { lat: 45.2720, lng: -113.7085 },
    { lat: 45.0260, lng: -113.4256 },
    { lat: 44.9405, lng: -113.4998 },
    { lat: 44.7887, lng: -113.3459 },
    { lat: 44.8062, lng: -113.2471 },
    { lat: 44.7350, lng: -113.1180 },
    { lat: 44.4887, lng: -113.0246 },
    { lat: 44.3592, lng: -112.8502 },
    { lat: 44.4151, lng: -112.8310 },
    { lat: 44.4887, lng: -112.7266 },
    { lat: 44.4504, lng: -112.3901 },
    { lat: 44.5347, lng: -112.3270 },
    { lat: 44.5220, lng: -112.1127 },
    { lat: 44.5582, lng: -111.8848 },
    { lat: 44.5132, lng: -111.8271 },
    { lat: 44.5396, lng: -111.4645 },
    { lat: 44.6198, lng: -111.5057 },
    { lat: 44.7292, lng: -111.3684 },
    { lat: 44.4759, lng: -111.0539 },
    { lat: 43.8623, lng: -111.0471 },
    { lat: 42.0013, lng: -111.0471 },
    { lat: 41.9962, lng: -112.1663 },
    { lat: 41.9871, lng: -113.8458 },
    { lat: 41.9942, lng: -114.0422 },
    { lat: 42.0013, lng: -114.8222 },
    { lat: 41.9973, lng: -115.9126 },
    { lat: 41.9962, lng: -117.0140 },
    { lat: 42.0013, lng: -117.0264 },
    { lat: 43.7820, lng: -117.0277 },
    { lat: 43.8330, lng: -117.0325 },
    { lat: 43.8632, lng: -117.0030 },
    { lat: 43.9073, lng: -116.9776 },
    { lat: 44.0244, lng: -116.9302 },
    { lat: 44.0491, lng: -116.9735 },
    { lat: 44.1014, lng: -116.9330 },
    { lat: 44.1561, lng: -116.8945 },
    { lat: 44.1965, lng: -116.9714 },
    { lat: 44.2442, lng: -116.9810 },
    { lat: 44.2486, lng: -117.0339 },
    { lat: 44.2304, lng: -117.0525 },
    { lat: 44.2585, lng: -117.0895 },
    { lat: 44.2806, lng: -117.1122 },
    { lat: 44.2590, lng: -117.1541 },
    { lat: 44.2973, lng: -117.2255 },
    { lat: 44.3445, lng: -117.1994 },
    { lat: 44.3813, lng: -117.2372 },
    { lat: 44.4769, lng: -117.2269 },
    { lat: 44.5234, lng: -117.1836 },
    { lat: 44.5376, lng: -117.1458 },
    { lat: 44.7423, lng: -117.0442 },
    { lat: 44.7921, lng: -116.9316 },
    { lat: 44.8568, lng: -116.8980 },
    { lat: 44.9356, lng: -116.8327 },
    { lat: 44.9624, lng: -116.8513 },
    { lat: 44.9896, lng: -116.8554 },
    { lat: 45.0313, lng: -116.8417 },
    { lat: 45.0968, lng: -116.7819 },
    { lat: 45.1627, lng: -116.7229 },
    { lat: 45.2178, lng: -116.7105 },
    { lat: 45.3213, lng: -116.6741 },
    { lat: 45.3984, lng: -116.6185 },
    { lat: 45.4433, lng: -116.5883 },
    { lat: 45.4630, lng: -116.5553 },
    { lat: 45.5371, lng: -116.5334 },
    { lat: 45.6140, lng: -116.4640 },
    { lat: 45.6904, lng: -116.5354 },
    { lat: 45.7340, lng: -116.5354 },
    { lat: 45.7541, lng: -116.5594 },
    { lat: 45.7843, lng: -116.6357 },
    { lat: 45.7781, lng: -116.5965 },
    { lat: 45.7805, lng: -116.6597 },
    { lat: 45.8259, lng: -116.7105 },
    { lat: 45.8159, lng: -116.7586 },
    { lat: 45.8341, lng: -116.7908 },
    { lat: 45.8642, lng: -116.8046 },
    { lat: 45.9053, lng: -116.8595 },
    { lat: 45.9545, lng: -116.8739 },
    { lat: 45.9769, lng: -116.8925 },
    { lat: 46.0218, lng: -116.9302 },
    { lat: 46.0932, lng: -116.9838 },
    { lat: 46.1385, lng: -116.9344 },
    { lat: 46.1727, lng: -116.9268 },
    { lat: 46.2007, lng: -116.9646 },
    { lat: 46.2435, lng: -116.9591 },
    { lat: 46.2782, lng: -116.9920 },
    { lat: 46.3152, lng: -117.0209 },
    { lat: 46.3446, lng: -117.0511 },
    { lat: 46.4270, lng: -117.0408 },
    { lat: 46.9451, lng: -117.0394 },
    { lat: 48.9996, lng: -117.0319 },
  ],
  // ILLINOIS
  IL: [
    { lat: 42.5116, lng: -90.6290 },
    { lat: 42.4924, lng: -87.0213 },
    { lat: 41.7641, lng: -87.2067 },
    { lat: 41.7611, lng: -87.5226 },
    { lat: 39.6417, lng: -87.5336 },
    { lat: 39.3566, lng: -87.5308 },
    { lat: 39.1386, lng: -87.6517 },
    { lat: 38.9445, lng: -87.5157 },
    { lat: 38.7294, lng: -87.5047 },
    { lat: 38.6115, lng: -87.6146 },
    { lat: 38.4944, lng: -87.6544 },
    { lat: 38.3740, lng: -87.7780 },
    { lat: 38.2856, lng: -87.8371 },
    { lat: 38.2414, lng: -87.9758 },
    { lat: 38.1454, lng: -87.9291 },
    { lat: 37.9788, lng: -88.0225 },
    { lat: 37.8900, lng: -88.0458 },
    { lat: 37.7881, lng: -88.0321 },
    { lat: 37.6349, lng: -88.1529 },
    { lat: 37.5097, lng: -88.0609 },
    { lat: 37.4149, lng: -88.4152 },
    { lat: 37.2828, lng: -88.5086 },
    { lat: 37.1428, lng: -88.4221 },
    { lat: 37.0585, lng: -88.4990 },
    { lat: 37.1428, lng: -88.7256 },
    { lat: 37.2128, lng: -88.9453 },
    { lat: 37.1559, lng: -89.0689 },
    { lat: 37.0376, lng: -89.1650 },
    { lat: 36.9894, lng: -89.2873 },
    { lat: 37.1505, lng: -89.4356 },
    { lat: 37.2762, lng: -89.5345 },
    { lat: 37.3996, lng: -89.4315 },
    { lat: 37.6936, lng: -89.5358 },
    { lat: 37.9767, lng: -89.9670 },
    { lat: 38.2587, lng: -90.3790 },
    { lat: 38.6169, lng: -90.2376 },
    { lat: 38.7573, lng: -90.1744 },
    { lat: 38.8247, lng: -90.1167 },
    { lat: 38.8846, lng: -90.1799 },
    { lat: 38.9680, lng: -90.4504 },
    { lat: 38.8654, lng: -90.5905 },
    { lat: 39.0405, lng: -90.7086 },
    { lat: 39.2301, lng: -90.7306 },
    { lat: 39.3173, lng: -90.8350 },
    { lat: 39.3853, lng: -90.9338 },
    { lat: 39.5559, lng: -91.1398 },
    { lat: 39.7262, lng: -91.3554 },
    { lat: 39.8570, lng: -91.4406 },
    { lat: 39.9940, lng: -91.4941 },
    { lat: 40.1694, lng: -91.5120 },
    { lat: 40.3497, lng: -91.4667 },
    { lat: 40.4166, lng: -91.3939 },
    { lat: 40.5566, lng: -91.4021 },
    { lat: 40.6265, lng: -91.2524 },
    { lat: 40.6963, lng: -91.1151 },
    { lat: 40.8232, lng: -91.0890 },
    { lat: 40.9312, lng: -90.9792 },
    { lat: 41.1642, lng: -91.0162 },
    { lat: 41.2355, lng: -91.1055 },
    { lat: 41.4170, lng: -91.0368 },
    { lat: 41.4458, lng: -90.8487 },
    { lat: 41.4417, lng: -90.7251 },
    { lat: 41.5816, lng: -90.3516 },
    { lat: 41.7713, lng: -90.2637 },
    { lat: 41.9023, lng: -90.1538 },
    { lat: 42.0819, lng: -90.1758 },
    { lat: 42.2021, lng: -90.3598 },
    { lat: 42.2936, lng: -90.4395 },
    { lat: 42.4032, lng: -90.5356 },
    { lat: 42.4843, lng: -90.6564 },
  ],
  // INDIANA
  IN: [
    { lat: 41.7611, lng: -87.5253 },
    { lat: 41.7611, lng: -84.8090 },
    { lat: 39.0981, lng: -84.8199 },
    { lat: 39.0533, lng: -84.8927 },
    { lat: 38.8996, lng: -84.8625 },
    { lat: 38.8312, lng: -84.8268 },
    { lat: 38.7841, lng: -84.8145 },
    { lat: 38.7905, lng: -84.8941 },
    { lat: 38.7809, lng: -84.9861 },
    { lat: 38.6877, lng: -85.1797 },
    { lat: 38.7198, lng: -85.4420 },
    { lat: 38.5653, lng: -85.4091 },
    { lat: 38.4461, lng: -85.5986 },
    { lat: 38.2695, lng: -85.7510 },
    { lat: 38.2824, lng: -85.8266 },
    { lat: 38.2414, lng: -85.8376 },
    { lat: 38.0967, lng: -85.9035 },
    { lat: 38.0232, lng: -85.9200 },
    { lat: 37.9594, lng: -86.0477 },
    { lat: 38.0102, lng: -86.0944 },
    { lat: 38.0578, lng: -86.2729 },
    { lat: 38.0935, lng: -86.2811 },
    { lat: 38.1346, lng: -86.2729 },
    { lat: 38.1842, lng: -86.3704 },
    { lat: 38.0416, lng: -86.5187 },
    { lat: 37.9193, lng: -86.5874 },
    { lat: 37.8402, lng: -86.6409 },
    { lat: 37.9085, lng: -86.6478 },
    { lat: 37.9085, lng: -86.6876 },
    { lat: 37.9821, lng: -86.8236 },
    { lat: 37.9464, lng: -86.9019 },
    { lat: 37.9009, lng: -87.0392 },
    { lat: 37.7924, lng: -87.1394 },
    { lat: 37.9464, lng: -87.4429 },
    { lat: 37.9756, lng: -87.5885 },
    { lat: 37.9225, lng: -87.6283 },
    { lat: 37.8694, lng: -87.6915 },
    { lat: 37.9236, lng: -87.8879 },
    { lat: 37.7718, lng: -87.9620 },
    { lat: 37.7870, lng: -88.0321 },
    { lat: 37.8092, lng: -88.0376 },
    { lat: 37.8011, lng: -88.0643 },
    { lat: 37.8206, lng: -88.0925 },
    { lat: 37.8223, lng: -88.0451 },
    { lat: 37.8483, lng: -88.0575 },
    { lat: 37.9041, lng: -88.0980 },
    { lat: 37.9307, lng: -88.0705 },
    { lat: 37.9561, lng: -88.0369 },
    { lat: 37.9669, lng: -88.0122 },
    { lat: 38.0102, lng: -88.0259 },
    { lat: 38.0384, lng: -88.0417 },
    { lat: 38.0530, lng: -88.0005 },
    { lat: 38.0762, lng: -87.9607 },
    { lat: 38.1000, lng: -88.0163 },
    { lat: 38.1313, lng: -87.9710 },
    { lat: 38.1497, lng: -87.9284 },
    { lat: 38.1734, lng: -87.9387 },
    { lat: 38.1939, lng: -87.9730 },
    { lat: 38.2349, lng: -87.9813 },
    { lat: 38.2608, lng: -87.9421 },
    { lat: 38.2759, lng: -87.8604 },
    { lat: 38.3029, lng: -87.8302 },
    { lat: 38.3233, lng: -87.8350 },
    { lat: 38.3567, lng: -87.8137 },
    { lat: 38.3767, lng: -87.7739 },
    { lat: 38.4116, lng: -87.7444 },
    { lat: 38.5149, lng: -87.6448 },
    { lat: 38.5460, lng: -87.6723 },
    { lat: 38.5949, lng: -87.6105 },
    { lat: 38.5986, lng: -87.6242 },
    { lat: 38.6828, lng: -87.5343 },
    { lat: 38.7284, lng: -87.5075 },
    { lat: 38.7696, lng: -87.4972 },
    { lat: 38.8247, lng: -87.5322 },
    { lat: 38.9039, lng: -87.5171 },
    { lat: 38.9413, lng: -87.5253 },
    { lat: 38.9712, lng: -87.5281 },
    { lat: 38.9872, lng: -87.5761 },
    { lat: 39.0906, lng: -87.6228 },
    { lat: 39.1066, lng: -87.6517 },
    { lat: 39.1365, lng: -87.6599 },
    { lat: 39.1695, lng: -87.6366 },
    { lat: 39.2493, lng: -87.5899 },
    { lat: 39.3492, lng: -87.5336 },
    { lat: 41.7600, lng: -87.5253 },
  ],
  // IOWA
  IA: [
    { lat: 40.5848, lng: -95.7623 },
    { lat: 40.5785, lng: -93.5445 },
    { lat: 40.6129, lng: -91.7372 },
    { lat: 40.5545, lng: -91.6768 },
    { lat: 40.5451, lng: -91.6246 },
    { lat: 40.3622, lng: -91.4365 },
    { lat: 40.4637, lng: -91.3623 },
    { lat: 40.5482, lng: -91.4021 },
    { lat: 40.6931, lng: -91.1124 },
    { lat: 40.8107, lng: -91.1028 },
    { lat: 40.9218, lng: -90.9668 },
    { lat: 41.1642, lng: -91.0121 },
    { lat: 41.2406, lng: -91.1082 },
    { lat: 41.4067, lng: -91.0451 },
    { lat: 41.4510, lng: -90.7086 },
    { lat: 41.5178, lng: -90.4793 },
    { lat: 41.5908, lng: -90.3419 },
    { lat: 41.7457, lng: -90.2788 },
    { lat: 41.8164, lng: -90.2074 },
    { lat: 41.9023, lng: -90.1538 },
    { lat: 42.0962, lng: -90.1744 },
    { lat: 42.1441, lng: -90.2692 },
    { lat: 42.2905, lng: -90.4298 },
    { lat: 42.4093, lng: -90.5370 },
    { lat: 42.5217, lng: -90.6400 },
    { lat: 42.6360, lng: -90.7127 },
    { lat: 42.6956, lng: -90.7883 },
    { lat: 42.7712, lng: -91.0533 },
    { lat: 42.8448, lng: -91.0904 },
    { lat: 42.9082, lng: -91.1398 },
    { lat: 43.0609, lng: -91.1549 },
    { lat: 43.1391, lng: -91.1522 },
    { lat: 43.2882, lng: -91.0547 },
    { lat: 43.3322, lng: -91.2057 },
    { lat: 43.4140, lng: -91.2236 },
    { lat: 43.5008, lng: -91.2305 },
    { lat: 43.4998, lng: -96.5973 },
    { lat: 43.4818, lng: -96.6110 },
    { lat: 43.3871, lng: -96.5245 },
    { lat: 43.2232, lng: -96.5533 },
    { lat: 43.1301, lng: -96.4421 },
    { lat: 42.9243, lng: -96.5479 },
    { lat: 42.7188, lng: -96.6357 },
    { lat: 42.6158, lng: -96.5561 },
    { lat: 42.5055, lng: -96.4847 },
    { lat: 42.4599, lng: -96.3995 },
    { lat: 42.3667, lng: -96.4050 },
    { lat: 42.2722, lng: -96.3446 },
    { lat: 42.2051, lng: -96.3625 },
    { lat: 41.9983, lng: -96.2416 },
    { lat: 41.9513, lng: -96.1372 },
    { lat: 41.7662, lng: -96.0741 },
    { lat: 41.6267, lng: -96.0988 },
    { lat: 41.4561, lng: -95.9477 },
    { lat: 41.2819, lng: -95.8804 },
    { lat: 41.0338, lng: -95.8653 },
    { lat: 40.8346, lng: -95.8365 },
    { lat: 40.6775, lng: -95.8461 },
    { lat: 40.5837, lng: -95.7610 },
  ],
  // KANSAS
  KS: [
    { lat: 40.0034, lng: -102.0506 },
    { lat: 40.0034, lng: -102.0506 },
    { lat: 36.9927, lng: -102.0438 },
    { lat: 36.9982, lng: -94.6211 },
    { lat: 38.8803, lng: -94.6046 },
    { lat: 39.0789, lng: -94.6143 },
    { lat: 39.1971, lng: -94.6184 },
    { lat: 39.1673, lng: -94.7255 },
    { lat: 39.2759, lng: -94.8793 },
    { lat: 39.5612, lng: -95.0990 },
    { lat: 39.7283, lng: -94.8807 },
    { lat: 39.8286, lng: -94.8930 },
    { lat: 39.8823, lng: -94.9342 },
    { lat: 39.8971, lng: -95.0098 },
    { lat: 39.8760, lng: -95.0922 },
    { lat: 39.9445, lng: -95.2213 },
    { lat: 40.0087, lng: -95.3036 },
    { lat: 40.0024, lng: -102.0506 },
  ],
  // KENTUCKY
  KY: [
    { lat: 36.4986, lng: -89.5372 },
    { lat: 36.5074, lng: -89.3010 },
    { lat: 36.5008, lng: -88.6871 },
    { lat: 36.4931, lng: -88.0568 },
    { lat: 36.6695, lng: -88.0692 },
    { lat: 36.6343, lng: -87.8535 },
    { lat: 36.6265, lng: -86.5654 },
    { lat: 36.5979, lng: -83.6375 },
    { lat: 36.6860, lng: -83.3423 },
    { lat: 36.7466, lng: -83.1377 },
    { lat: 36.9762, lng: -82.8589 },
    { lat: 37.2894, lng: -82.3192 },
    { lat: 37.4934, lng: -82.0308 },
    { lat: 37.6653, lng: -82.2121 },
    { lat: 37.8618, lng: -82.4016 },
    { lat: 37.9908, lng: -82.5073 },
    { lat: 38.1778, lng: -82.6392 },
    { lat: 38.3761, lng: -82.5952 },
    { lat: 38.5030, lng: -82.7477 },
    { lat: 38.5825, lng: -82.8369 },
    { lat: 38.7316, lng: -82.9015 },
    { lat: 38.7027, lng: -83.0196 },
    { lat: 38.6190, lng: -83.1418 },
    { lat: 38.5986, lng: -83.2819 },
    { lat: 38.6941, lng: -83.5291 },
    { lat: 38.6351, lng: -83.6595 },
    { lat: 38.7487, lng: -83.8930 },
    { lat: 38.7701, lng: -84.0440 },
    { lat: 38.8119, lng: -84.2184 },
    { lat: 38.9872, lng: -84.3228 },
    { lat: 39.1013, lng: -84.4917 },
    { lat: 39.1183, lng: -84.6277 },
    { lat: 39.1439, lng: -84.7554 },
    { lat: 39.0523, lng: -84.8914 },
    { lat: 38.9263, lng: -84.8735 },
    { lat: 38.7894, lng: -84.8131 },
    { lat: 38.7691, lng: -84.9957 },
    { lat: 38.6866, lng: -85.1921 },
    { lat: 38.7209, lng: -85.4407 },
    { lat: 38.5653, lng: -85.4077 },
    { lat: 38.4461, lng: -85.5972 },
    { lat: 38.2748, lng: -85.7455 },
    { lat: 38.2716, lng: -85.8087 },
    { lat: 38.2069, lng: -85.8650 },
    { lat: 38.0286, lng: -85.9323 },
    { lat: 37.9550, lng: -86.0422 },
    { lat: 38.0135, lng: -86.1108 },
    { lat: 38.0643, lng: -86.2756 },
    { lat: 38.1389, lng: -86.2770 },
    { lat: 38.1864, lng: -86.3690 },
    { lat: 38.0308, lng: -86.5283 },
    { lat: 37.9204, lng: -86.5874 },
    { lat: 37.8423, lng: -86.6423 },
    { lat: 37.9041, lng: -86.6547 },
    { lat: 37.9864, lng: -86.8250 },
    { lat: 37.9095, lng: -87.0406 },
    { lat: 37.7935, lng: -87.1381 },
    { lat: 37.9420, lng: -87.4168 },
    { lat: 37.9745, lng: -87.5858 },
    { lat: 37.8749, lng: -87.6929 },
    { lat: 37.9215, lng: -87.8906 },
    { lat: 37.7761, lng: -87.9552 },
    { lat: 37.7903, lng: -88.0307 },
    { lat: 37.6479, lng: -88.1584 },
    { lat: 37.5097, lng: -88.0664 },
    { lat: 37.4193, lng: -88.4180 },
    { lat: 37.2784, lng: -88.5086 },
    { lat: 37.1428, lng: -88.4248 },
    { lat: 37.0738, lng: -88.5059 },
    { lat: 37.1461, lng: -88.7421 },
    { lat: 37.2249, lng: -88.9522 },
    { lat: 37.1406, lng: -89.0964 },
    { lat: 37.0278, lng: -89.1815 },
    { lat: 36.9488, lng: -89.1032 },
    { lat: 36.8214, lng: -89.1733 },
    { lat: 36.7411, lng: -89.1925 },
    { lat: 36.6265, lng: -89.2007 },
    { lat: 36.5449, lng: -89.2529 },
    { lat: 36.6232, lng: -89.3518 },
    { lat: 36.4986, lng: -89.5345 },
  ],
  // LOUISIANA
  LA: [
    { lat: 33.0225, lng: -94.0430 },
    { lat: 33.0179, lng: -93.0048 },
    { lat: 33.0087, lng: -91.1646 },
    { lat: 32.9269, lng: -91.2209 },
    { lat: 32.8773, lng: -91.1220 },
    { lat: 32.8358, lng: -91.1481 },
    { lat: 32.7642, lng: -91.1412 },
    { lat: 32.6382, lng: -91.1536 },
    { lat: 32.5804, lng: -91.1069 },
    { lat: 32.6093, lng: -91.0080 },
    { lat: 32.4588, lng: -91.0904 },
    { lat: 32.4379, lng: -91.0355 },
    { lat: 32.3742, lng: -91.0286 },
    { lat: 32.3150, lng: -90.9064 },
    { lat: 32.2616, lng: -90.9723 },
    { lat: 32.1942, lng: -91.0464 },
    { lat: 32.1198, lng: -91.0739 },
    { lat: 32.0593, lng: -91.0464 },
    { lat: 31.9918, lng: -91.1014 },
    { lat: 31.9498, lng: -91.1865 },
    { lat: 31.8262, lng: -91.3101 },
    { lat: 31.7947, lng: -91.3527 },
    { lat: 31.6230, lng: -91.3925 },
    { lat: 31.6218, lng: -91.5134 },
    { lat: 31.5668, lng: -91.4310 },
    { lat: 31.5130, lng: -91.5161 },
    { lat: 31.3701, lng: -91.5244 },
    { lat: 31.2598, lng: -91.5477 },
    { lat: 31.2692, lng: -91.6425 },
    { lat: 31.2328, lng: -91.6603 },
    { lat: 31.1917, lng: -91.5848 },
    { lat: 31.1047, lng: -91.6287 },
    { lat: 31.0318, lng: -91.5614 },
    { lat: 30.9988, lng: -91.6397 },
    { lat: 31.0012, lng: -89.7336 },
    { lat: 30.6686, lng: -89.8517 },
    { lat: 30.5386, lng: -89.7858 },
    { lat: 30.3148, lng: -89.6347 },
    { lat: 30.1807, lng: -89.5688 },
    { lat: 30.1582, lng: -89.4960 },
    { lat: 30.2140, lng: -89.1843 },
    { lat: 30.1463, lng: -89.0373 },
    { lat: 30.0905, lng: -88.8354 },
    { lat: 29.8383, lng: -88.7421 },
    { lat: 29.5758, lng: -88.8712 },
    { lat: 29.1833, lng: -88.9371 },
    { lat: 28.9649, lng: -89.0359 },
    { lat: 28.8832, lng: -89.2282 },
    { lat: 28.9048, lng: -89.4754 },
    { lat: 29.1210, lng: -89.7418 },
    { lat: 28.9529, lng: -90.1126 },
    { lat: 28.9120, lng: -90.6619 },
    { lat: 28.9553, lng: -91.0355 },
    { lat: 29.1210, lng: -91.3211 },
    { lat: 29.2864, lng: -91.9061 },
    { lat: 29.4360, lng: -92.7452 },
    { lat: 29.6009, lng: -93.8177 },
    { lat: 29.6749, lng: -93.8631 },
    { lat: 29.7370, lng: -93.8933 },
    { lat: 29.7930, lng: -93.9304 },
    { lat: 29.8216, lng: -93.9276 },
    { lat: 29.8883, lng: -93.8370 },
    { lat: 29.9811, lng: -93.7985 },
    { lat: 30.0144, lng: -93.7601 },
    { lat: 30.0691, lng: -93.7106 },
    { lat: 30.0929, lng: -93.7354 },
    { lat: 30.1166, lng: -93.6996 },
    { lat: 30.1997, lng: -93.7271 },
    { lat: 30.2899, lng: -93.7106 },
    { lat: 30.3350, lng: -93.7656 },
    { lat: 30.3871, lng: -93.7601 },
    { lat: 30.4416, lng: -93.6914 },
    { lat: 30.5102, lng: -93.7106 },
    { lat: 30.5433, lng: -93.7463 },
    { lat: 30.5954, lng: -93.7106 },
    { lat: 30.5906, lng: -93.6914 },
    { lat: 30.6545, lng: -93.6859 },
    { lat: 30.6781, lng: -93.6365 },
    { lat: 30.7513, lng: -93.6200 },
    { lat: 30.7890, lng: -93.5925 },
    { lat: 30.8150, lng: -93.5513 },
    { lat: 30.8645, lng: -93.5623 },
    { lat: 30.8881, lng: -93.5788 },
    { lat: 30.9187, lng: -93.5541 },
    { lat: 30.9423, lng: -93.5294 },
    { lat: 31.0082, lng: -93.5760 },
    { lat: 31.0318, lng: -93.5101 },
    { lat: 31.0906, lng: -93.5596 },
    { lat: 31.1211, lng: -93.5321 },
    { lat: 31.1799, lng: -93.5349 },
    { lat: 31.1658, lng: -93.5953 },
    { lat: 31.2292, lng: -93.6282 },
    { lat: 31.2668, lng: -93.6118 },
    { lat: 31.3044, lng: -93.6859 },
    { lat: 31.3888, lng: -93.6694 },
    { lat: 31.4240, lng: -93.7051 },
    { lat: 31.4427, lng: -93.6859 },
    { lat: 31.4755, lng: -93.7573 },
    { lat: 31.5083, lng: -93.7189 },
    { lat: 31.5411, lng: -93.8040 },
    { lat: 31.6113, lng: -93.8425 },
    { lat: 31.6581, lng: -93.8205 },
    { lat: 31.7071, lng: -93.7985 },
    { lat: 31.8029, lng: -93.8480 },
    { lat: 31.8892, lng: -93.9029 },
    { lat: 31.9149, lng: -93.9606 },
    { lat: 32.0081, lng: -94.0430 },
    { lat: 32.7041, lng: -94.0430 },
    { lat: 33.0225, lng: -94.0430 },
  ],
  // MAINE
  ME: [
    { lat: 45.3425, lng: -71.0129 },
    { lat: 45.3328, lng: -70.9525 },
    { lat: 45.2294, lng: -70.8618 },
    { lat: 45.3917, lng: -70.8247 },
    { lat: 45.4274, lng: -70.7808 },
    { lat: 45.3830, lng: -70.6380 },
    { lat: 45.5092, lng: -70.7190 },
    { lat: 45.6544, lng: -70.5721 },
    { lat: 45.7292, lng: -70.3894 },
    { lat: 45.7924, lng: -70.4169 },
    { lat: 45.9368, lng: -70.2493 },
    { lat: 45.9597, lng: -70.3098 },
    { lat: 46.0923, lng: -70.2946 },
    { lat: 46.0989, lng: -70.2589 },
    { lat: 46.1342, lng: -70.2466 },
    { lat: 46.1903, lng: -70.2905 },
    { lat: 46.2710, lng: -70.2466 },
    { lat: 46.3270, lng: -70.2040 },
    { lat: 46.4151, lng: -70.0571 },
    { lat: 46.6956, lng: -69.9994 },
    { lat: 47.4550, lng: -69.2303 },
    { lat: 47.4132, lng: -69.0381 },
    { lat: 47.2578, lng: -69.0504 },
    { lat: 47.1748, lng: -68.8843 },
    { lat: 47.2643, lng: -68.6206 },
    { lat: 47.3546, lng: -68.3350 },
    { lat: 47.3165, lng: -68.1564 },
    { lat: 47.1038, lng: -67.8804 },
    { lat: 47.0664, lng: -67.7898 },
    { lat: 45.9359, lng: -67.7802 },
    { lat: 45.9177, lng: -67.7527 },
    { lat: 45.7599, lng: -67.8090 },
    { lat: 45.6208, lng: -67.6524 },
    { lat: 45.5987, lng: -67.4533 },
    { lat: 45.5044, lng: -67.4176 },
    { lat: 45.4823, lng: -67.5014 },
    { lat: 45.3714, lng: -67.4231 },
    { lat: 45.2768, lng: -67.4863 },
    { lat: 45.1297, lng: -67.3434 },
    { lat: 45.1830, lng: -67.2487 },
    { lat: 45.1230, lng: -67.1223 },
    { lat: 44.8315, lng: -66.9672 },
    { lat: 44.7409, lng: -66.8628 },
    { lat: 44.4945, lng: -67.3105 },
    { lat: 44.3268, lng: -67.9051 },
    { lat: 43.8702, lng: -68.6673 },
    { lat: 43.7274, lng: -68.8431 },
    { lat: 43.6639, lng: -69.7137 },
    { lat: 43.5625, lng: -70.0818 },
    { lat: 42.9182, lng: -70.5569 },
    { lat: 43.0649, lng: -70.7108 },
    { lat: 43.1391, lng: -70.8302 },
    { lat: 43.2292, lng: -70.8179 },
    { lat: 43.3631, lng: -70.9799 },
    { lat: 43.5675, lng: -70.9717 },
    { lat: 45.3029, lng: -71.0829 },
  ],
  // MARYLAND
  MD: [
    { lat: 39.7220, lng: -79.4778 },
    { lat: 39.7220, lng: -78.3600 },
    { lat: 39.7220, lng: -75.7878 },
    { lat: 39.5655, lng: -75.7809 },
    { lat: 39.3152, lng: -75.7617 },
    { lat: 38.9498, lng: -75.7329 },
    { lat: 38.4611, lng: -75.6944 },
    { lat: 38.4482, lng: -74.8581 },
    { lat: 38.0200, lng: -74.9721 },
    { lat: 38.0275, lng: -75.2316 },
    { lat: 37.9962, lng: -75.6079 },
    { lat: 37.9951, lng: -75.6230 },
    { lat: 37.9464, lng: -75.6436 },
    { lat: 37.9529, lng: -75.7288 },
    { lat: 37.9117, lng: -75.8084 },
    { lat: 37.9095, lng: -75.9512 },
    { lat: 37.9464, lng: -75.9430 },
    { lat: 37.9529, lng: -76.0584 },
    { lat: 37.8889, lng: -76.2396 },
    { lat: 37.9474, lng: -76.3454 },
    { lat: 37.9669, lng: -76.4154 },
    { lat: 38.0146, lng: -76.4703 },
    { lat: 38.0275, lng: -76.5170 },
    { lat: 38.0751, lng: -76.5363 },
    { lat: 38.1464, lng: -76.6063 },
    { lat: 38.1616, lng: -76.6928 },
    { lat: 38.1670, lng: -76.7601 },
    { lat: 38.1637, lng: -76.8494 },
    { lat: 38.2080, lng: -76.9482 },
    { lat: 38.2748, lng: -76.9908 },
    { lat: 38.3093, lng: -77.0306 },
    { lat: 38.3761, lng: -77.0114 },
    { lat: 38.4009, lng: -77.0430 },
    { lat: 38.3697, lng: -77.0897 },
    { lat: 38.3697, lng: -77.1432 },
    { lat: 38.3320, lng: -77.2627 },
    { lat: 38.4525, lng: -77.3135 },
    { lat: 38.5514, lng: -77.2737 },
    { lat: 38.5954, lng: -77.2490 },
    { lat: 38.6373, lng: -77.1281 },
    { lat: 38.6737, lng: -77.1378 },
    { lat: 38.7112, lng: -77.0760 },
    { lat: 38.7187, lng: -77.0361 },
    { lat: 38.7766, lng: -77.0416 },
    { lat: 38.8451, lng: -77.0320 },
    { lat: 38.9025, lng: -77.0708 },
    { lat: 38.9570, lng: -77.1395 },
    { lat: 38.9773, lng: -77.2335 },
    { lat: 39.0240, lng: -77.2462 },
    { lat: 39.0634, lng: -77.3431 },
    { lat: 39.0717, lng: -77.4351 },
    { lat: 39.0792, lng: -77.4636 },
    { lat: 39.1218, lng: -77.5202 },
    { lat: 39.1804, lng: -77.5092 },
    { lat: 39.2269, lng: -77.4577 },
    { lat: 39.3051, lng: -77.5666 },
    { lat: 39.3067, lng: -77.6321 },
    { lat: 39.3202, lng: -77.7159 },
    { lat: 39.3383, lng: -77.7626 },
    { lat: 39.3810, lng: -77.7544 },
    { lat: 39.4288, lng: -77.7602 },
    { lat: 39.4367, lng: -77.8038 },
    { lat: 39.4606, lng: -77.7997 },
    { lat: 39.5019, lng: -77.7859 },
    { lat: 39.5062, lng: -77.8436 },
    { lat: 39.5210, lng: -77.8217 },
    { lat: 39.5337, lng: -77.8354 },
    { lat: 39.5231, lng: -77.8656 },
    { lat: 39.5591, lng: -77.8848 },
    { lat: 39.6015, lng: -77.8821 },
    { lat: 39.6078, lng: -77.9974 },
    { lat: 39.6247, lng: -78.0222 },
    { lat: 39.6924, lng: -78.1430 },
    { lat: 39.6945, lng: -78.1924 },
    { lat: 39.6839, lng: -78.2062 },
    { lat: 39.6839, lng: -78.2419 },
    { lat: 39.6586, lng: -78.2281 },
    { lat: 39.6226, lng: -78.2776 },
    { lat: 39.6438, lng: -78.3517 },
    { lat: 39.6120, lng: -78.3765 },
    { lat: 39.6036, lng: -78.4067 },
    { lat: 39.5824, lng: -78.4177 },
    { lat: 39.5750, lng: -78.4245 },
    { lat: 39.5464, lng: -78.4232 },
    { lat: 39.5146, lng: -78.4698 },
    { lat: 39.5189, lng: -78.5687 },
    { lat: 39.5337, lng: -78.6676 },
    { lat: 39.5888, lng: -78.7390 },
    { lat: 39.6015, lng: -78.7720 },
    { lat: 39.6184, lng: -78.7363 },
    { lat: 39.6438, lng: -78.7775 },
    { lat: 39.6036, lng: -78.7912 },
    { lat: 39.6036, lng: -78.8187 },
    { lat: 39.5549, lng: -78.8571 },
    { lat: 39.4913, lng: -78.9203 },
    { lat: 39.4426, lng: -78.9725 },
    { lat: 39.4834, lng: -79.0542 },
    { lat: 39.4738, lng: -79.0604 },
    { lat: 39.4553, lng: -79.1043 },
    { lat: 39.3853, lng: -79.1936 },
    { lat: 39.3449, lng: -79.2705 },
    { lat: 39.3014, lng: -79.3282 },
    { lat: 39.2535, lng: -79.4044 },
    { lat: 39.2073, lng: -79.4696 },
    { lat: 39.2051, lng: -79.4861 },
    { lat: 39.2546, lng: -79.4861 },
    { lat: 39.3444, lng: -79.4854 },
    { lat: 39.3454, lng: -79.4840 },
    { lat: 39.5316, lng: -79.4833 },
    { lat: 39.7214, lng: -79.4772 },
  ],
  // MASSACHUSETTS
  MA: [
    { lat: 42.0003, lng: -72.7789 },
    { lat: 42.0330, lng: -72.7405 },
    { lat: 42.0330, lng: -72.3779 },
    { lat: 42.0228, lng: -71.7984 },
    { lat: 42.0085, lng: -71.8011 },
    { lat: 42.0197, lng: -71.3850 },
    { lat: 41.8961, lng: -71.3837 },
    { lat: 41.8982, lng: -71.3411 },
    { lat: 41.8358, lng: -71.3370 },
    { lat: 41.8245, lng: -71.3493 },
    { lat: 41.7816, lng: -71.3342 },
    { lat: 41.7529, lng: -71.2628 },
    { lat: 41.6719, lng: -71.1914 },
    { lat: 41.6616, lng: -71.1351 },
    { lat: 41.6124, lng: -71.1433 },
    { lat: 41.5939, lng: -71.1310 },
    { lat: 41.4973, lng: -71.1214 },
    { lat: 41.3149, lng: -71.0266 },
    { lat: 41.1590, lng: -70.8316 },
    { lat: 41.1662, lng: -69.9225 },
    { lat: 41.3201, lng: -69.7948 },
    { lat: 41.8133, lng: -69.7398 },
    { lat: 42.1939, lng: -70.0337 },
    { lat: 42.2173, lng: -70.5144 },
    { lat: 42.4133, lng: -70.6984 },
    { lat: 42.6420, lng: -70.3647 },
    { lat: 42.8286, lng: -70.4759 },
    { lat: 42.8760, lng: -70.6133 },
    { lat: 42.8619, lng: -70.8440 },
    { lat: 42.8890, lng: -70.9154 },
    { lat: 42.8075, lng: -71.0651 },
    { lat: 42.8226, lng: -71.1337 },
    { lat: 42.7873, lng: -71.1859 },
    { lat: 42.7369, lng: -71.1832 },
    { lat: 42.7470, lng: -71.2189 },
    { lat: 42.7400, lng: -71.2560 },
    { lat: 42.6986, lng: -71.2985 },
    { lat: 42.7127, lng: -71.9151 },
    { lat: 42.7309, lng: -72.5441 },
    { lat: 42.7450, lng: -73.2541 },
    { lat: 42.7460, lng: -73.2664 },
    { lat: 42.5460, lng: -73.3406 },
    { lat: 42.2671, lng: -73.4436 },
    { lat: 42.1349, lng: -73.4917 },
    { lat: 42.0880, lng: -73.5081 },
    { lat: 42.0483, lng: -73.4985 },
    { lat: 42.0452, lng: -73.1841 },
    { lat: 42.0371, lng: -72.8146 },
    { lat: 41.9962, lng: -72.8160 },
    { lat: 42.0024, lng: -72.7803 },
  ],
  // MICHIGAN
  MI: [
    { lat: 48.3033, lng: -88.3713 },
    { lat: 48.0101, lng: -87.6050 },
    { lat: 46.8902, lng: -84.8584 },
    { lat: 46.6362, lng: -84.7650 },
    { lat: 46.4606, lng: -84.5563 },
    { lat: 46.4525, lng: -84.4780 },
    { lat: 46.4894, lng: -84.4450 },
    { lat: 46.5008, lng: -84.4203 },
    { lat: 46.4989, lng: -84.3956 },
    { lat: 46.5093, lng: -84.3750 },
    { lat: 46.5069, lng: -84.3386 },
    { lat: 46.4927, lng: -84.2905 },
    { lat: 46.4951, lng: -84.2651 },
    { lat: 46.5343, lng: -84.2253 },
    { lat: 46.5404, lng: -84.1951 },
    { lat: 46.5272, lng: -84.1779 },
    { lat: 46.5348, lng: -84.1347 },
    { lat: 46.5041, lng: -84.1113 },
    { lat: 46.4189, lng: -84.1457 },
    { lat: 46.3720, lng: -84.1395 },
    { lat: 46.3218, lng: -84.1058 },
    { lat: 46.3147, lng: -84.1203 },
    { lat: 46.2672, lng: -84.1148 },
    { lat: 46.2563, lng: -84.0969 },
    { lat: 46.2411, lng: -84.1093 },
    { lat: 46.2098, lng: -84.0859 },
    { lat: 46.1879, lng: -84.0777 },
    { lat: 46.1508, lng: -84.0097 },
    { lat: 46.1180, lng: -84.0070 },
    { lat: 46.1018, lng: -83.9761 },
    { lat: 46.0570, lng: -83.9555 },
    { lat: 46.0604, lng: -83.9040 },
    { lat: 46.1185, lng: -83.8264 },
    { lat: 46.1028, lng: -83.7598 },
    { lat: 46.1218, lng: -83.6547 },
    { lat: 46.1056, lng: -83.5723 },
    { lat: 45.9993, lng: -83.4343 },
    { lat: 45.8211, lng: -83.5977 },
    { lat: 45.3396, lng: -82.5197 },
    { lat: 43.5918, lng: -82.1221 },
    { lat: 43.0112, lng: -82.4119 },
    { lat: 42.9956, lng: -82.4249 },
    { lat: 42.9579, lng: -82.4236 },
    { lat: 42.9021, lng: -82.4648 },
    { lat: 42.8543, lng: -82.4689 },
    { lat: 42.8100, lng: -82.4826 },
    { lat: 42.7863, lng: -82.4723 },
    { lat: 42.7339, lng: -82.4847 },
    { lat: 42.6855, lng: -82.5032 },
    { lat: 42.6380, lng: -82.5108 },
    { lat: 42.6036, lng: -82.5307 },
    { lat: 42.5672, lng: -82.5774 },
    { lat: 42.5490, lng: -82.5993 },
    { lat: 42.5521, lng: -82.6501 },
    { lat: 42.5354, lng: -82.6680 },
    { lat: 42.4746, lng: -82.7257 },
    { lat: 42.4726, lng: -82.7250 },
    { lat: 42.3738, lng: -82.8280 },
    { lat: 42.3469, lng: -82.9440 },
    { lat: 42.3382, lng: -82.9550 },
    { lat: 42.3098, lng: -83.0779 },
    { lat: 42.2392, lng: -83.1294 },
    { lat: 42.1741, lng: -83.1342 },
    { lat: 42.1267, lng: -83.1212 },
    { lat: 42.0411, lng: -83.1493 },
    { lat: 41.9600, lng: -83.1116 },
    { lat: 41.7344, lng: -83.4164 },
    { lat: 41.7211, lng: -83.8724 },
    { lat: 41.7057, lng: -84.3736 },
    { lat: 41.6965, lng: -84.8062 },
    { lat: 41.7611, lng: -84.8076 },
    { lat: 41.7621, lng: -87.2067 },
    { lat: 42.4934, lng: -87.0241 },
    { lat: 43.3771, lng: -87.1477 },
    { lat: 43.7056, lng: -87.1216 },
    { lat: 43.9958, lng: -87.0474 },
    { lat: 44.1674, lng: -86.9939 },
    { lat: 44.4720, lng: -86.8662 },
    { lat: 44.8841, lng: -86.6849 },
    { lat: 45.0813, lng: -86.5009 },
    { lat: 45.2353, lng: -86.2495 },
    { lat: 45.4438, lng: -86.7563 },
    { lat: 45.4438, lng: -87.0996 },
    { lat: 45.3772, lng: -87.1518 },
    { lat: 45.3502, lng: -87.1710 },
    { lat: 45.2401, lng: -87.3166 },
    { lat: 45.2024, lng: -87.4059 },
    { lat: 45.0774, lng: -87.4416 },
    { lat: 45.0910, lng: -87.5912 },
    { lat: 45.1036, lng: -87.6407 },
    { lat: 45.2207, lng: -87.6970 },
    { lat: 45.3367, lng: -87.6476 },
    { lat: 45.3878, lng: -87.6984 },
    { lat: 45.3425, lng: -87.8494 },
    { lat: 45.5025, lng: -87.7959 },
    { lat: 45.6726, lng: -87.7890 },
    { lat: 45.7570, lng: -87.9318 },
    { lat: 45.7953, lng: -87.9922 },
    { lat: 45.8058, lng: -88.1186 },
    { lat: 45.8585, lng: -88.0870 },
    { lat: 45.9531, lng: -88.1955 },
    { lat: 45.9722, lng: -88.3438 },
    { lat: 45.9836, lng: -88.3891 },
    { lat: 46.0113, lng: -88.5457 },
    { lat: 45.9970, lng: -88.7022 },
    { lat: 46.0227, lng: -88.8135 },
    { lat: 46.0418, lng: -88.8547 },
    { lat: 46.1408, lng: -89.0936 },
    { lat: 46.3384, lng: -90.1222 },
    { lat: 46.5692, lng: -90.4175 },
    { lat: 46.9034, lng: -90.2019 },
    { lat: 47.2913, lng: -89.9547 },
    { lat: 48.0129, lng: -89.4946 },
    { lat: 47.9743, lng: -89.3381 },
    { lat: 48.2448, lng: -88.6761 },
    { lat: 48.3042, lng: -88.3726 },
  ],
  // MINNESOTA
  MN: [
    { lat: 43.5008, lng: -96.4517 },
    { lat: 43.5017, lng: -91.2195 },
    { lat: 43.8226, lng: -91.3101 },
    { lat: 43.9651, lng: -91.4914 },
    { lat: 44.1113, lng: -91.7084 },
    { lat: 44.2806, lng: -91.8951 },
    { lat: 44.3710, lng: -91.9556 },
    { lat: 44.4357, lng: -92.2083 },
    { lat: 44.5513, lng: -92.3360 },
    { lat: 44.6501, lng: -92.6367 },
    { lat: 44.7877, lng: -92.7658 },
    { lat: 45.3135, lng: -92.7081 },
    { lat: 45.4505, lng: -92.6532 },
    { lat: 45.6083, lng: -92.8482 },
    { lat: 45.8307, lng: -92.7356 },
    { lat: 45.9760, lng: -92.5159 },
    { lat: 46.0151, lng: -92.3566 },
    { lat: 46.0789, lng: -92.2934 },
    { lat: 46.5957, lng: -92.2879 },
    { lat: 47.3072, lng: -90.6564 },
    { lat: 47.2885, lng: -89.9615 },
    { lat: 48.0120, lng: -89.4919 },
    { lat: 48.0193, lng: -89.7583 },
    { lat: 48.0285, lng: -89.9931 },
    { lat: 48.0827, lng: -90.0261 },
    { lat: 48.1074, lng: -90.1758 },
    { lat: 48.0955, lng: -90.3502 },
    { lat: 48.1074, lng: -90.4834 },
    { lat: 48.1175, lng: -90.5644 },
    { lat: 48.0928, lng: -90.7471 },
    { lat: 48.1588, lng: -90.7759 },
    { lat: 48.2402, lng: -90.8405 },
    { lat: 48.2174, lng: -90.9792 },
    { lat: 48.0726, lng: -91.3252 },
    { lat: 48.0505, lng: -91.5738 },
    { lat: 48.1166, lng: -91.7070 },
    { lat: 48.1963, lng: -91.7166 },
    { lat: 48.2494, lng: -91.9844 },
    { lat: 48.3188, lng: -92.0078 },
    { lat: 48.3544, lng: -92.0531 },
    { lat: 48.3599, lng: -92.1561 },
    { lat: 48.3307, lng: -92.2975 },
    { lat: 48.2475, lng: -92.2742 },
    { lat: 48.2228, lng: -92.3717 },
    { lat: 48.3854, lng: -92.4609 },
    { lat: 48.4474, lng: -92.5104 },
    { lat: 48.4611, lng: -92.7122 },
    { lat: 48.4984, lng: -92.6340 },
    { lat: 48.5403, lng: -92.6395 },
    { lat: 48.6393, lng: -93.2066 },
    { lat: 48.5884, lng: -93.4648 },
    { lat: 48.5439, lng: -93.4621 },
    { lat: 48.5166, lng: -93.8013 },
    { lat: 48.6284, lng: -93.8356 },
    { lat: 48.6547, lng: -94.2531 },
    { lat: 48.7046, lng: -94.2792 },
    { lat: 48.6982, lng: -94.4467 },
    { lat: 48.7861, lng: -94.6925 },
    { lat: 48.8756, lng: -94.6788 },
    { lat: 49.0955, lng: -94.7488 },
    { lat: 49.1889, lng: -94.7955 },
    { lat: 49.3189, lng: -94.8175 },
    { lat: 49.3815, lng: -94.9631 },
    { lat: 49.3538, lng: -95.0400 },
    { lat: 49.3681, lng: -95.1196 },
    { lat: 49.3877, lng: -95.1553 },
    { lat: 48.9991, lng: -95.1553 },
    { lat: 49.0000, lng: -97.2304 },
    { lat: 48.8647, lng: -97.1851 },
    { lat: 48.7806, lng: -97.1576 },
    { lat: 48.6683, lng: -97.1040 },
    { lat: 48.5539, lng: -97.1645 },
    { lat: 48.2832, lng: -97.1411 },
    { lat: 48.1578, lng: -97.1397 },
    { lat: 47.9633, lng: -97.0587 },
    { lat: 47.7098, lng: -96.9434 },
    { lat: 47.5821, lng: -96.8582 },
    { lat: 47.2345, lng: -96.8335 },
    { lat: 46.6702, lng: -96.8005 },
    { lat: 46.5135, lng: -96.7126 },
    { lat: 46.2786, lng: -96.6028 },
    { lat: 46.0189, lng: -96.5767 },
    { lat: 45.8173, lng: -96.5891 },
    { lat: 45.6169, lng: -96.8486 },
    { lat: 45.4601, lng: -96.7456 },
    { lat: 45.3676, lng: -96.5918 },
    { lat: 45.2961, lng: -96.4558 },
    { lat: 43.5008, lng: -96.4531 },
  ],
  // MISSISSIPPI
  MS: [
    { lat: 35.0041, lng: -90.3049 },
    { lat: 35.0075, lng: -88.1955 },
    { lat: 34.8882, lng: -88.0994 },
    { lat: 34.7044, lng: -88.1241 },
    { lat: 33.6661, lng: -88.2573 },
    { lat: 31.8939, lng: -88.4756 },
    { lat: 30.8657, lng: -88.4180 },
    { lat: 30.1594, lng: -88.3850 },
    { lat: 30.0905, lng: -88.8327 },
    { lat: 30.2104, lng: -89.1870 },
    { lat: 30.1570, lng: -89.4919 },
    { lat: 30.1796, lng: -89.5757 },
    { lat: 30.3326, lng: -89.6457 },
    { lat: 30.5232, lng: -89.7748 },
    { lat: 30.6663, lng: -89.8531 },
    { lat: 30.9988, lng: -89.7377 },
    { lat: 30.9988, lng: -91.6287 },
    { lat: 31.0341, lng: -91.5601 },
    { lat: 31.1106, lng: -91.6273 },
    { lat: 31.1658, lng: -91.5916 },
    { lat: 31.2304, lng: -91.6589 },
    { lat: 31.2656, lng: -91.6452 },
    { lat: 31.2609, lng: -91.5436 },
    { lat: 31.3724, lng: -91.5271 },
    { lat: 31.4099, lng: -91.5161 },
    { lat: 31.5071, lng: -91.5120 },
    { lat: 31.5692, lng: -91.4502 },
    { lat: 31.6230, lng: -91.5147 },
    { lat: 31.6253, lng: -91.3966 },
    { lat: 31.7936, lng: -91.3513 },
    { lat: 31.8589, lng: -91.2744 },
    { lat: 31.9755, lng: -91.1673 },
    { lat: 32.0267, lng: -91.0767 },
    { lat: 32.1198, lng: -91.0767 },
    { lat: 32.1942, lng: -91.0437 },
    { lat: 32.2221, lng: -91.0107 },
    { lat: 32.3150, lng: -90.9132 },
    { lat: 32.3742, lng: -91.0313 },
    { lat: 32.4263, lng: -91.0217 },
    { lat: 32.4634, lng: -91.0986 },
    { lat: 32.6070, lng: -91.0080 },
    { lat: 32.5746, lng: -91.1096 },
    { lat: 32.6394, lng: -91.1536 },
    { lat: 32.7226, lng: -91.1426 },
    { lat: 32.7873, lng: -91.1426 },
    { lat: 32.8519, lng: -91.1536 },
    { lat: 32.8796, lng: -91.1206 },
    { lat: 32.9257, lng: -91.2195 },
    { lat: 32.9995, lng: -91.2085 },
    { lat: 33.0444, lng: -91.2016 },
    { lat: 33.1192, lng: -91.2016 },
    { lat: 33.1835, lng: -91.1041 },
    { lat: 33.3397, lng: -91.1536 },
    { lat: 33.4223, lng: -91.1646 },
    { lat: 33.4337, lng: -91.2291 },
    { lat: 33.5414, lng: -91.2524 },
    { lat: 33.6135, lng: -91.1838 },
    { lat: 33.6878, lng: -91.2524 },
    { lat: 33.6969, lng: -91.1261 },
    { lat: 33.7883, lng: -91.1426 },
    { lat: 33.7700, lng: -91.0437 },
    { lat: 33.8339, lng: -91.0327 },
    { lat: 33.8795, lng: -91.0657 },
    { lat: 33.9434, lng: -91.0876 },
    { lat: 33.9889, lng: -90.9998 },
    { lat: 34.0253, lng: -90.9229 },
    { lat: 34.0891, lng: -90.9009 },
    { lat: 34.1345, lng: -90.9668 },
    { lat: 34.1709, lng: -90.9119 },
    { lat: 34.1345, lng: -90.8501 },
    { lat: 34.2277, lng: -90.9338 },
    { lat: 34.2833, lng: -90.8267 },
    { lat: 34.3434, lng: -90.6921 },
    { lat: 34.3774, lng: -90.6509 },
    { lat: 34.3978, lng: -90.6152 },
    { lat: 34.4432, lng: -90.5589 },
    { lat: 34.5179, lng: -90.5740 },
    { lat: 34.5880, lng: -90.5823 },
    { lat: 34.7506, lng: -90.5356 },
    { lat: 34.7913, lng: -90.5136 },
    { lat: 34.8780, lng: -90.4532 },
    { lat: 34.8476, lng: -90.3543 },
    { lat: 34.8702, lng: -90.2911 },
    { lat: 35.0041, lng: -90.3062 },
  ],
  // MISSOURI
  MO: [
    { lat: 40.6181, lng: -91.7468 },
    { lat: 40.5597, lng: -91.6809 },
    { lat: 40.5472, lng: -91.6260 },
    { lat: 40.4658, lng: -91.5463 },
    { lat: 40.3675, lng: -91.4337 },
    { lat: 40.1663, lng: -91.5161 },
    { lat: 39.9866, lng: -91.4900 },
    { lat: 39.8634, lng: -91.4447 },
    { lat: 39.7283, lng: -91.3623 },
    { lat: 39.6861, lng: -91.3074 },
    { lat: 39.5464, lng: -91.1096 },
    { lat: 39.4022, lng: -90.9558 },
    { lat: 39.2450, lng: -90.7306 },
    { lat: 38.9893, lng: -90.6812 },
    { lat: 38.8697, lng: -90.5878 },
    { lat: 38.9722, lng: -90.4504 },
    { lat: 38.8868, lng: -90.1813 },
    { lat: 38.8269, lng: -90.1154 },
    { lat: 38.7155, lng: -90.1978 },
    { lat: 38.4149, lng: -90.3186 },
    { lat: 38.2597, lng: -90.3790 },
    { lat: 37.9572, lng: -89.9341 },
    { lat: 37.6925, lng: -89.5331 },
    { lat: 37.4007, lng: -89.4287 },
    { lat: 37.2784, lng: -89.5386 },
    { lat: 37.1734, lng: -89.4452 },
    { lat: 37.0859, lng: -89.3793 },
    { lat: 36.9938, lng: -89.2859 },
    { lat: 37.0311, lng: -89.1829 },
    { lat: 36.9839, lng: -89.1403 },
    { lat: 36.9466, lng: -89.1005 },
    { lat: 36.7884, lng: -89.1788 },
    { lat: 36.6288, lng: -89.2035 },
    { lat: 36.5449, lng: -89.2516 },
    { lat: 36.6188, lng: -89.3532 },
    { lat: 36.5538, lng: -89.4397 },
    { lat: 36.4942, lng: -89.5358 },
    { lat: 36.3594, lng: -89.5331 },
    { lat: 36.2509, lng: -89.5345 },
    { lat: 36.0891, lng: -89.6100 },
    { lat: 36.0002, lng: -89.7116 },
    { lat: 35.9958, lng: -90.3735 },
    { lat: 36.1223, lng: -90.2747 },
    { lat: 36.2886, lng: -90.0934 },
    { lat: 36.3903, lng: -90.0769 },
    { lat: 36.4213, lng: -90.1538 },
    { lat: 36.5008, lng: -90.1538 },
    { lat: 36.4975, lng: -94.6184 },
    { lat: 38.9722, lng: -94.6143 },
    { lat: 39.1982, lng: -94.6143 },
    { lat: 39.1684, lng: -94.7241 },
    { lat: 39.2790, lng: -94.8834 },
    { lat: 39.5612, lng: -95.1086 },
    { lat: 39.7283, lng: -94.8834 },
    { lat: 39.8296, lng: -94.8944 },
    { lat: 39.8845, lng: -94.9384 },
    { lat: 39.8971, lng: -95.0153 },
    { lat: 39.8718, lng: -95.0922 },
    { lat: 40.0066, lng: -95.3119 },
    { lat: 40.2460, lng: -95.4794 },
    { lat: 40.3256, lng: -95.6635 },
    { lat: 40.5879, lng: -95.7527 },
    { lat: 40.5795, lng: -94.3135 },
    { lat: 40.5962, lng: -92.5818 },
    { lat: 40.6140, lng: -91.7358 },
  ],
  // MONTANA
  MT: [
    { lat: 44.4750, lng: -111.0512 },
    { lat: 44.7311, lng: -111.3684 },
    { lat: 44.6198, lng: -111.5071 },
    { lat: 44.5386, lng: -111.4673 },
    { lat: 44.5151, lng: -111.8285 },
    { lat: 44.5592, lng: -111.8848 },
    { lat: 44.5229, lng: -112.1127 },
    { lat: 44.5367, lng: -112.3270 },
    { lat: 44.4475, lng: -112.3901 },
    { lat: 44.4896, lng: -112.7293 },
    { lat: 44.4181, lng: -112.8296 },
    { lat: 44.3563, lng: -112.8529 },
    { lat: 44.4857, lng: -113.0205 },
    { lat: 44.5905, lng: -113.0713 },
    { lat: 44.7389, lng: -113.1152 },
    { lat: 44.8091, lng: -113.2471 },
    { lat: 44.7887, lng: -113.3459 },
    { lat: 44.9386, lng: -113.4970 },
    { lat: 45.0269, lng: -113.4229 },
    { lat: 45.2749, lng: -113.7085 },
    { lat: 45.4447, lng: -113.7964 },
    { lat: 45.6140, lng: -113.8403 },
    { lat: 45.6908, lng: -113.9722 },
    { lat: 45.5631, lng: -114.1315 },
    { lat: 45.5525, lng: -114.2358 },
    { lat: 45.4592, lng: -114.3347 },
    { lat: 45.5439, lng: -114.4652 },
    { lat: 45.5583, lng: -114.5668 },
    { lat: 45.6755, lng: -114.5215 },
    { lat: 45.7733, lng: -114.5641 },
    { lat: 45.8613, lng: -114.4391 },
    { lat: 45.9989, lng: -114.4817 },
    { lat: 46.1237, lng: -114.5132 },
    { lat: 46.2577, lng: -114.4652 },
    { lat: 46.3441, lng: -114.4171 },
    { lat: 46.4530, lng: -114.3896 },
    { lat: 46.5069, lng: -114.3484 },
    { lat: 46.6504, lng: -114.3279 },
    { lat: 46.6334, lng: -114.4652 },
    { lat: 46.6362, lng: -114.5929 },
    { lat: 46.7003, lng: -114.7659 },
    { lat: 46.8423, lng: -114.9307 },
    { lat: 47.0402, lng: -115.1147 },
    { lat: 47.1888, lng: -115.2933 },
    { lat: 47.2569, lng: -115.3290 },
    { lat: 47.3025, lng: -115.5267 },
    { lat: 47.4253, lng: -115.7547 },
    { lat: 47.5487, lng: -115.7533 },
    { lat: 47.5793, lng: -115.6956 },
    { lat: 47.9771, lng: -116.0458 },
    { lat: 48.9991, lng: -116.0458 },
    { lat: 48.9991, lng: -104.0501 },
    { lat: 45.0269, lng: -104.0186 },
    { lat: 45.0153, lng: -104.0186 },
    { lat: 44.9978, lng: -104.0199 },
    { lat: 44.9959, lng: -111.0498 },
    { lat: 44.4750, lng: -111.0512 },
  ],
  // NEBRASKA
  NE: [
    { lat: 42.9986, lng: -104.0543 },
    { lat: 41.0027, lng: -104.0543 },
    { lat: 41.0006, lng: -102.0506 },
    { lat: 40.0034, lng: -102.0493 },
    { lat: 39.9992, lng: -95.3091 },
    { lat: 40.2397, lng: -95.4808 },
    { lat: 40.3130, lng: -95.6470 },
    { lat: 40.4302, lng: -95.6689 },
    { lat: 40.5900, lng: -95.7500 },
    { lat: 40.6827, lng: -95.8543 },
    { lat: 40.8138, lng: -95.8447 },
    { lat: 40.9654, lng: -95.8324 },
    { lat: 41.0794, lng: -95.8667 },
    { lat: 41.2923, lng: -95.8722 },
    { lat: 41.4458, lng: -95.9354 },
    { lat: 41.5261, lng: -95.9999 },
    { lat: 41.6380, lng: -96.0988 },
    { lat: 41.7703, lng: -96.0686 },
    { lat: 41.8368, lng: -96.1084 },
    { lat: 41.9677, lng: -96.1372 },
    { lat: 42.0330, lng: -96.2402 },
    { lat: 42.1155, lng: -96.2746 },
    { lat: 42.2021, lng: -96.3583 },
    { lat: 42.2448, lng: -96.3281 },
    { lat: 42.3890, lng: -96.4188 },
    { lat: 42.4731, lng: -96.4037 },
    { lat: 42.5369, lng: -96.6357 },
    { lat: 42.6057, lng: -96.7099 },
    { lat: 42.6532, lng: -96.6893 },
    { lat: 42.6602, lng: -96.7621 },
    { lat: 42.7147, lng: -96.8390 },
    { lat: 42.7571, lng: -96.9763 },
    { lat: 42.8085, lng: -97.2029 },
    { lat: 42.8458, lng: -97.2290 },
    { lat: 42.8629, lng: -97.3979 },
    { lat: 42.8427, lng: -97.5133 },
    { lat: 42.8488, lng: -97.6149 },
    { lat: 42.8659, lng: -97.8456 },
    { lat: 42.7470, lng: -97.9980 },
    { lat: 42.8337, lng: -98.1450 },
    { lat: 42.9293, lng: -98.4485 },
    { lat: 42.9966, lng: -98.5020 },
    { lat: 43.0006, lng: -104.0543 },
  ],
  // NEVADA
  NV: [
    { lat: 41.9922, lng: -120.0037 },
    { lat: 42.0003, lng: -117.9602 },
    { lat: 42.0003, lng: -117.0264 },
    { lat: 42.0003, lng: -115.7300 },
    { lat: 41.9952, lng: -114.0436 },
    { lat: 38.2856, lng: -114.0546 },
    { lat: 36.1955, lng: -114.0491 },
    { lat: 36.1334, lng: -114.0985 },
    { lat: 36.1068, lng: -114.1260 },
    { lat: 36.0891, lng: -114.1150 },
    { lat: 36.0313, lng: -114.1534 },
    { lat: 36.0136, lng: -114.2468 },
    { lat: 36.0624, lng: -114.3292 },
    { lat: 36.1024, lng: -114.3237 },
    { lat: 36.1434, lng: -114.3896 },
    { lat: 36.1290, lng: -114.5105 },
    { lat: 36.1467, lng: -114.5215 },
    { lat: 36.1556, lng: -114.5654 },
    { lat: 36.1334, lng: -114.6204 },
    { lat: 36.1157, lng: -114.6863 },
    { lat: 36.0979, lng: -114.7742 },
    { lat: 36.0580, lng: -114.7357 },
    { lat: 36.0447, lng: -114.7522 },
    { lat: 35.9691, lng: -114.7412 },
    { lat: 35.8980, lng: -114.7028 },
    { lat: 35.8757, lng: -114.6588 },
    { lat: 35.8579, lng: -114.7083 },
    { lat: 35.8267, lng: -114.6973 },
    { lat: 35.8089, lng: -114.7192 },
    { lat: 35.6618, lng: -114.6973 },
    { lat: 35.6037, lng: -114.6478 },
    { lat: 35.5814, lng: -114.6863 },
    { lat: 35.5233, lng: -114.6533 },
    { lat: 35.5054, lng: -114.6863 },
    { lat: 35.4070, lng: -114.6149 },
    { lat: 35.3218, lng: -114.5929 },
    { lat: 35.1783, lng: -114.5737 },
    { lat: 35.1199, lng: -114.6094 },
    { lat: 35.1109, lng: -114.6368 },
    { lat: 35.0929, lng: -114.6368 },
    { lat: 35.0750, lng: -114.6039 },
    { lat: 35.0480, lng: -114.6259 },
    { lat: 35.0345, lng: -114.6478 },
    { lat: 35.0030, lng: -114.6423 },
    { lat: 35.1334, lng: -114.8016 },
    { lat: 37.2303, lng: -117.5153 },
    { lat: 39.0000, lng: -120.0009 },
    { lat: 41.9942, lng: -119.9982 },
  ],
  // NEW HAMPSHIRE
  NH: [
    { lat: 45.0153, lng: -71.5045 },
    { lat: 45.0532, lng: -71.4977 },
    { lat: 45.0910, lng: -71.4619 },
    { lat: 45.1520, lng: -71.4276 },
    { lat: 45.2043, lng: -71.3960 },
    { lat: 45.2362, lng: -71.4455 },
    { lat: 45.2372, lng: -71.3878 },
    { lat: 45.2710, lng: -71.3617 },
    { lat: 45.3029, lng: -71.2834 },
    { lat: 45.2507, lng: -71.2299 },
    { lat: 45.2391, lng: -71.1818 },
    { lat: 45.2478, lng: -71.1584 },
    { lat: 45.2469, lng: -71.1337 },
    { lat: 45.2846, lng: -71.1145 },
    { lat: 45.3058, lng: -71.0843 },
    { lat: 44.9230, lng: -71.0527 },
    { lat: 44.3592, lng: -71.0143 },
    { lat: 43.7364, lng: -70.9840 },
    { lat: 43.5704, lng: -70.9731 },
    { lat: 43.3621, lng: -70.9813 },
    { lat: 43.2942, lng: -70.9030 },
    { lat: 43.2272, lng: -70.8083 },
    { lat: 43.1411, lng: -70.8261 },
    { lat: 43.1000, lng: -70.7671 },
    { lat: 43.0498, lng: -70.6970 },
    { lat: 42.9222, lng: -70.5583 },
    { lat: 42.8760, lng: -70.6146 },
    { lat: 42.8599, lng: -70.8467 },
    { lat: 42.8860, lng: -70.9195 },
    { lat: 42.8105, lng: -71.0609 },
    { lat: 42.8125, lng: -71.1420 },
    { lat: 42.7904, lng: -71.1873 },
    { lat: 42.7349, lng: -71.1832 },
    { lat: 42.7450, lng: -71.2463 },
    { lat: 42.6986, lng: -71.2972 },
    { lat: 42.7077, lng: -71.7421 },
    { lat: 42.7268, lng: -72.4590 },
    { lat: 42.7621, lng: -72.5098 },
    { lat: 42.8105, lng: -72.5414 },
    { lat: 42.8609, lng: -72.5592 },
    { lat: 42.8981, lng: -72.5317 },
    { lat: 42.9524, lng: -72.5400 },
    { lat: 42.9745, lng: -72.4919 },
    { lat: 42.9906, lng: -72.4658 },
    { lat: 43.0127, lng: -72.4480 },
    { lat: 43.0287, lng: -72.4713 },
    { lat: 43.0508, lng: -72.4713 },
    { lat: 43.0819, lng: -72.4356 },
    { lat: 43.1040, lng: -72.4452 },
    { lat: 43.1180, lng: -72.4342 },
    { lat: 43.1501, lng: -72.4590 },
    { lat: 43.2052, lng: -72.4329 },
    { lat: 43.2562, lng: -72.4384 },
    { lat: 43.2812, lng: -72.4095 },
    { lat: 43.3741, lng: -72.4150 },
    { lat: 43.4988, lng: -72.3807 },
    { lat: 43.5127, lng: -72.4013 },
    { lat: 43.5834, lng: -72.3779 },
    { lat: 43.6420, lng: -72.3134 },
    { lat: 43.6758, lng: -72.3024 },
    { lat: 43.7036, lng: -72.3079 },
    { lat: 43.7344, lng: -72.2694 },
    { lat: 43.7741, lng: -72.2035 },
    { lat: 43.8187, lng: -72.1870 },
    { lat: 43.8672, lng: -72.1843 },
    { lat: 43.8890, lng: -72.1568 },
    { lat: 43.9117, lng: -72.1321 },
    { lat: 43.9454, lng: -72.1198 },
    { lat: 44.0017, lng: -72.1170 },
    { lat: 44.0856, lng: -72.0470 },
    { lat: 44.1093, lng: -72.0552 },
    { lat: 44.1428, lng: -72.0387 },
    { lat: 44.1763, lng: -72.0580 },
    { lat: 44.1999, lng: -72.0703 },
    { lat: 44.2511, lng: -72.0525 },
    { lat: 44.2796, lng: -72.0607 },
    { lat: 44.3199, lng: -72.0291 },
    { lat: 44.3376, lng: -71.9783 },
    { lat: 44.3592, lng: -71.9289 },
    { lat: 44.3366, lng: -71.8712 },
    { lat: 44.3572, lng: -71.8135 },
    { lat: 44.3877, lng: -71.8108 },
    { lat: 44.4014, lng: -71.7778 },
    { lat: 44.4122, lng: -71.7380 },
    { lat: 44.4416, lng: -71.6597 },
    { lat: 44.4642, lng: -71.6542 },
    { lat: 44.5073, lng: -71.5855 },
    { lat: 44.5357, lng: -71.5746 },
    { lat: 44.5601, lng: -71.6048 },
    { lat: 44.5680, lng: -71.5897 },
    { lat: 44.5680, lng: -71.5649 },
    { lat: 44.5914, lng: -71.5485 },
    { lat: 44.5993, lng: -71.5649 },
    { lat: 44.6315, lng: -71.5540 },
    { lat: 44.6608, lng: -71.5924 },
    { lat: 44.7243, lng: -71.6130 },
    { lat: 44.7526, lng: -71.6364 },
    { lat: 44.8198, lng: -71.5746 },
    { lat: 44.8325, lng: -71.5526 },
    { lat: 44.8539, lng: -71.5553 },
    { lat: 44.8744, lng: -71.5279 },
    { lat: 44.9123, lng: -71.5018 },
    { lat: 44.9366, lng: -71.5182 },
    { lat: 44.9697, lng: -71.5237 },
    { lat: 44.9862, lng: -71.5443 },
    { lat: 45.0017, lng: -71.5237 },
    { lat: 45.0163, lng: -71.5045 },
  ],
  // NEW JERSEY
  NJ: [
    { lat: 41.3572, lng: -74.6950 },
    { lat: 41.3394, lng: -74.6559 },
    { lat: 40.9934, lng: -73.8940 },
    { lat: 40.8398, lng: -73.9586 },
    { lat: 40.7691, lng: -74.0094 },
    { lat: 40.6994, lng: -74.0231 },
    { lat: 40.6786, lng: -74.0437 },
    { lat: 40.6515, lng: -74.0808 },
    { lat: 40.6421, lng: -74.1357 },
    { lat: 40.6452, lng: -74.1962 },
    { lat: 40.5952, lng: -74.2003 },
    { lat: 40.5566, lng: -74.2195 },
    { lat: 40.4877, lng: -74.2552 },
    { lat: 40.4762, lng: -74.2264 },
    { lat: 40.5253, lng: -73.9503 },
    { lat: 40.4846, lng: -73.8885 },
    { lat: 40.0045, lng: -73.9352 },
    { lat: 39.6131, lng: -74.0410 },
    { lat: 39.4744, lng: -74.2209 },
    { lat: 38.9882, lng: -74.6713 },
    { lat: 38.8664, lng: -74.8553 },
    { lat: 38.8472, lng: -75.0476 },
    { lat: 39.0565, lng: -75.1685 },
    { lat: 39.2525, lng: -75.3250 },
    { lat: 39.4500, lng: -75.5544 },
    { lat: 39.4966, lng: -75.5612 },
    { lat: 39.4998, lng: -75.5283 },
    { lat: 39.5411, lng: -75.5338 },
    { lat: 39.5761, lng: -75.5090 },
    { lat: 39.6237, lng: -75.5708 },
    { lat: 39.6713, lng: -75.5104 },
    { lat: 39.7167, lng: -75.4843 },
    { lat: 39.8033, lng: -75.4156 },
    { lat: 39.8360, lng: -75.2632 },
    { lat: 39.8823, lng: -75.1918 },
    { lat: 40.1180, lng: -74.7922 },
    { lat: 40.1390, lng: -74.7331 },
    { lat: 40.2565, lng: -74.8485 },
    { lat: 40.3361, lng: -74.9419 },
    { lat: 40.4020, lng: -74.9721 },
    { lat: 40.4240, lng: -75.0627 },
    { lat: 40.4898, lng: -75.0613 },
    { lat: 40.5733, lng: -75.1067 },
    { lat: 40.5639, lng: -75.2138 },
    { lat: 40.6192, lng: -75.2028 },
    { lat: 40.6494, lng: -75.2069 },
    { lat: 40.8284, lng: -75.0806 },
    { lat: 40.8429, lng: -75.0998 },
    { lat: 40.8689, lng: -75.0504 },
    { lat: 40.9913, lng: -75.1369 },
    { lat: 41.2293, lng: -74.8677 },
    { lat: 41.3479, lng: -74.7537 },
    { lat: 41.3469, lng: -74.7249 },
    { lat: 41.3593, lng: -74.6960 },
  ],
  // NEW MEXICO
  NM: [
    { lat: 36.9971, lng: -109.0448 },
    { lat: 31.3337, lng: -109.0489 },
    { lat: 31.3349, lng: -108.2140 },
    { lat: 31.7795, lng: -108.2071 },
    { lat: 31.7830, lng: -106.5317 },
    { lat: 32.0034, lng: -106.6223 },
    { lat: 31.9999, lng: -103.0696 },
    { lat: 36.9982, lng: -103.0023 },
    { lat: 36.9982, lng: -109.0475 },
  ],
  // NEW YORK
  NY: [
    { lat: 42.5142, lng: -79.7624 },
    { lat: 42.7783, lng: -79.0672 },
    { lat: 42.8508, lng: -78.9313 },
    { lat: 42.9061, lng: -78.9024 },
    { lat: 42.9554, lng: -78.9313 },
    { lat: 42.9584, lng: -78.9656 },
    { lat: 42.9886, lng: -79.0219 },
    { lat: 43.0568, lng: -79.0027 },
    { lat: 43.0769, lng: -79.0727 },
    { lat: 43.1220, lng: -79.0713 },
    { lat: 43.1441, lng: -79.0302 },
    { lat: 43.1801, lng: -79.0576 },
    { lat: 43.2482, lng: -79.0604 },
    { lat: 43.2812, lng: -79.0837 },
    { lat: 43.4509, lng: -79.2004 },
    { lat: 43.6311, lng: -78.6909 },
    { lat: 43.6321, lng: -76.7958 },
    { lat: 43.9987, lng: -76.4978 },
    { lat: 44.0965, lng: -76.4388 },
    { lat: 44.1349, lng: -76.3536 },
    { lat: 44.1989, lng: -76.3124 },
    { lat: 44.2049, lng: -76.2437 },
    { lat: 44.2413, lng: -76.1655 },
    { lat: 44.2973, lng: -76.1353 },
    { lat: 44.3327, lng: -76.0474 },
    { lat: 44.3553, lng: -75.9856 },
    { lat: 44.3749, lng: -75.9196 },
    { lat: 44.3994, lng: -75.8730 },
    { lat: 44.4308, lng: -75.8221 },
    { lat: 44.4740, lng: -75.8098 },
    { lat: 44.5425, lng: -75.7288 },
    { lat: 44.6647, lng: -75.5585 },
    { lat: 44.7672, lng: -75.4088 },
    { lat: 44.8101, lng: -75.3442 },
    { lat: 44.8383, lng: -75.3058 },
    { lat: 44.8676, lng: -75.2399 },
    { lat: 44.9211, lng: -75.1204 },
    { lat: 44.9609, lng: -74.9995 },
    { lat: 44.9803, lng: -74.9899 },
    { lat: 44.9852, lng: -74.9103 },
    { lat: 45.0017, lng: -74.8856 },
    { lat: 45.0153, lng: -74.8306 },
    { lat: 45.0046, lng: -74.7633 },
    { lat: 45.0027, lng: -74.7070 },
    { lat: 45.0007, lng: -74.5642 },
    { lat: 44.9920, lng: -74.1467 },
    { lat: 45.0037, lng: -73.7306 },
    { lat: 45.0085, lng: -73.4203 },
    { lat: 45.0109, lng: -73.3430 },
    { lat: 44.9874, lng: -73.3547 },
    { lat: 44.9648, lng: -73.3379 },
    { lat: 44.9160, lng: -73.3396 },
    { lat: 44.8354, lng: -73.3739 },
    { lat: 44.8013, lng: -73.3324 },
    { lat: 44.7419, lng: -73.3667 },
    { lat: 44.6139, lng: -73.3873 },
    { lat: 44.5787, lng: -73.3736 },
    { lat: 44.4916, lng: -73.3049 },
    { lat: 44.4289, lng: -73.2953 },
    { lat: 44.3513, lng: -73.3365 },
    { lat: 44.2757, lng: -73.3118 },
    { lat: 44.1980, lng: -73.3818 },
    { lat: 44.1142, lng: -73.4079 },
    { lat: 44.0511, lng: -73.4367 },
    { lat: 44.0165, lng: -73.4065 },
    { lat: 43.9375, lng: -73.4079 },
    { lat: 43.8771, lng: -73.3749 },
    { lat: 43.8167, lng: -73.3914 },
    { lat: 43.7790, lng: -73.3557 },
    { lat: 43.6460, lng: -73.4244 },
    { lat: 43.5893, lng: -73.4340 },
    { lat: 43.5655, lng: -73.3969 },
    { lat: 43.6112, lng: -73.3818 },
    { lat: 43.6271, lng: -73.3049 },
    { lat: 43.5764, lng: -73.3063 },
    { lat: 43.5675, lng: -73.2582 },
    { lat: 43.5227, lng: -73.2445 },
    { lat: 43.2582, lng: -73.2582 },
    { lat: 42.9715, lng: -73.2733 },
    { lat: 42.8004, lng: -73.2898 },
    { lat: 42.7460, lng: -73.2664 },
    { lat: 42.4630, lng: -73.3708 },
    { lat: 42.0840, lng: -73.5095 },
    { lat: 42.0218, lng: -73.4903 },
    { lat: 41.8808, lng: -73.4999 },
    { lat: 41.2953, lng: -73.5535 },
    { lat: 41.2128, lng: -73.4834 },
    { lat: 41.1011, lng: -73.7275 },
    { lat: 41.0237, lng: -73.6644 },
    { lat: 40.9851, lng: -73.6578 },
    { lat: 40.9509, lng: -73.6132 },
    { lat: 41.1869, lng: -72.4823 },
    { lat: 41.2551, lng: -72.0950 },
    { lat: 41.3005, lng: -71.9714 },
    { lat: 41.3108, lng: -71.9193 },
    { lat: 41.1838, lng: -71.7915 },
    { lat: 41.1249, lng: -71.7929 },
    { lat: 41.0462, lng: -71.7517 },
    { lat: 40.6306, lng: -72.9465 },
    { lat: 40.5368, lng: -73.4628 },
    { lat: 40.4887, lng: -73.8885 },
    { lat: 40.5232, lng: -73.9490 },
    { lat: 40.4772, lng: -74.2271 },
    { lat: 40.4861, lng: -74.2532 },
    { lat: 40.6468, lng: -74.1866 },
    { lat: 40.6556, lng: -74.0547 },
    { lat: 40.7618, lng: -74.0156 },
    { lat: 40.8699, lng: -73.9421 },
    { lat: 40.9980, lng: -73.8934 },
    { lat: 41.0343, lng: -73.9854 },
    { lat: 41.3268, lng: -74.6274 },
    { lat: 41.3583, lng: -74.7084 },
    { lat: 41.3811, lng: -74.7101 },
    { lat: 41.4386, lng: -74.8265 },
    { lat: 41.5075, lng: -74.9913 },
    { lat: 41.6000, lng: -75.0668 },
    { lat: 41.6719, lng: -75.0366 },
    { lat: 41.7672, lng: -75.0545 },
    { lat: 41.8808, lng: -75.1945 },
    { lat: 42.0013, lng: -75.3552 },
    { lat: 42.0003, lng: -75.4266 },
    { lat: 42.0013, lng: -77.0306 },
    { lat: 41.9993, lng: -79.7250 },
    { lat: 42.0003, lng: -79.7621 },
    { lat: 42.1827, lng: -79.7621 },
    { lat: 42.5146, lng: -79.7621 },
  ],
  // NORTH CAROLINA
  NC: [
    { lat: 33.7963, lng: -78.4850 },
    { lat: 34.8037, lng: -79.6742 },
    { lat: 34.8206, lng: -80.8003 },
    { lat: 34.9377, lng: -80.7880 },
    { lat: 35.1019, lng: -80.9377 },
    { lat: 35.0356, lng: -81.0379 },
    { lat: 35.1457, lng: -81.0324 },
    { lat: 35.1660, lng: -81.3867 },
    { lat: 35.1985, lng: -82.2739 },
    { lat: 35.2041, lng: -82.3933 },
    { lat: 35.0637, lng: -82.7765 },
    { lat: 35.0817, lng: -82.7861 },
    { lat: 34.9996, lng: -83.1075 },
    { lat: 34.9918, lng: -83.6183 },
    { lat: 34.9918, lng: -84.3201 },
    { lat: 35.2131, lng: -84.2885 },
    { lat: 35.2680, lng: -84.2226 },
    { lat: 35.2310, lng: -84.1113 },
    { lat: 35.2815, lng: -84.0454 },
    { lat: 35.4058, lng: -84.0248 },
    { lat: 35.4719, lng: -83.9424 },
    { lat: 35.5166, lng: -83.8559 },
    { lat: 35.5512, lng: -83.6938 },
    { lat: 35.5680, lng: -83.5181 },
    { lat: 35.6327, lng: -83.3849 },
    { lat: 35.7142, lng: -83.2475 },
    { lat: 35.7799, lng: -82.9962 },
    { lat: 35.8445, lng: -82.9276 },
    { lat: 35.9224, lng: -82.8191 },
    { lat: 35.9958, lng: -82.7710 },
    { lat: 36.0613, lng: -82.6419 },
    { lat: 35.9702, lng: -82.6103 },
    { lat: 35.9547, lng: -82.5677 },
    { lat: 36.0236, lng: -82.4730 },
    { lat: 36.0669, lng: -82.4194 },
    { lat: 36.1168, lng: -82.3535 },
    { lat: 36.1345, lng: -82.2862 },
    { lat: 36.1467, lng: -82.1461 },
    { lat: 36.1035, lng: -82.1228 },
    { lat: 36.1268, lng: -82.0267 },
    { lat: 36.2797, lng: -81.9360 },
    { lat: 36.3527, lng: -81.7987 },
    { lat: 36.3361, lng: -81.7081 },
    { lat: 36.5880, lng: -81.6724 },
    { lat: 36.5659, lng: -80.7234 },
    { lat: 36.5438, lng: -80.2977 },
    { lat: 36.5449, lng: -79.6729 },
    { lat: 36.5449, lng: -77.2559 },
    { lat: 36.5505, lng: -75.7562 },
    { lat: 36.3129, lng: -75.7068 },
    { lat: 35.7131, lng: -75.4129 },
    { lat: 35.2041, lng: -75.4720 },
    { lat: 34.9794, lng: -76.0748 },
    { lat: 34.5258, lng: -76.4951 },
    { lat: 34.5880, lng: -76.8109 },
    { lat: 34.5314, lng: -77.1378 },
    { lat: 34.3910, lng: -77.4481 },
    { lat: 34.0481, lng: -77.7983 },
    { lat: 33.7666, lng: -77.9260 },
    { lat: 33.7963, lng: -78.4863 },
  ],
  // NORTH DAKOTA
  ND: [
    { lat: 48.9955, lng: -104.0501 },
    { lat: 45.9445, lng: -104.0446 },
    { lat: 45.9340, lng: -96.5671 },
    { lat: 46.3242, lng: -96.6028 },
    { lat: 46.6636, lng: -96.7978 },
    { lat: 46.8602, lng: -96.7896 },
    { lat: 46.9503, lng: -96.7896 },
    { lat: 47.1300, lng: -96.8335 },
    { lat: 47.2345, lng: -96.8335 },
    { lat: 47.4132, lng: -96.8555 },
    { lat: 47.5469, lng: -96.8555 },
    { lat: 47.6506, lng: -96.8774 },
    { lat: 47.9918, lng: -97.0601 },
    { lat: 48.1267, lng: -97.1260 },
    { lat: 48.2859, lng: -97.1109 },
    { lat: 48.4301, lng: -97.1233 },
    { lat: 48.5530, lng: -97.1425 },
    { lat: 48.6765, lng: -97.0999 },
    { lat: 48.7326, lng: -97.1356 },
    { lat: 48.7951, lng: -97.1727 },
    { lat: 48.9081, lng: -97.2290 },
    { lat: 48.9982, lng: -97.2331 },
    { lat: 48.9946, lng: -104.0501 },
  ],
  // OHIA
  OH: [
    { lat: 38.4385, lng: -82.3425 },
    { lat: 38.5707, lng: -82.2917 },
    { lat: 38.5965, lng: -82.1722 },
    { lat: 38.7712, lng: -82.1997 },
    { lat: 39.0181, lng: -82.0294 },
    { lat: 38.8750, lng: -81.8729 },
    { lat: 38.9359, lng: -81.7644 },
    { lat: 39.1865, lng: -81.7397 },
    { lat: 39.2812, lng: -81.5680 },
    { lat: 39.4022, lng: -81.4444 },
    { lat: 39.3460, lng: -81.3661 },
    { lat: 39.4479, lng: -81.1244 },
    { lat: 39.5549, lng: -81.0352 },
    { lat: 39.6565, lng: -80.8374 },
    { lat: 39.8676, lng: -80.7948 },
    { lat: 40.5941, lng: -80.6520 },
    { lat: 40.6223, lng: -80.5188 },
    { lat: 42.3210, lng: -80.5229 },
    { lat: 42.2153, lng: -81.2521 },
    { lat: 41.9962, lng: -81.6806 },
    { lat: 41.9962, lng: -81.7094 },
    { lat: 41.6770, lng: -82.3961 },
    { lat: 41.6709, lng: -82.6845 },
    { lat: 41.9585, lng: -83.1157 },
    { lat: 41.7314, lng: -83.4219 },
    { lat: 41.6944, lng: -84.8021 },
    { lat: 39.1056, lng: -84.8172 },
    { lat: 39.1407, lng: -84.7444 },
    { lat: 39.0960, lng: -84.5068 },
    { lat: 39.0459, lng: -84.4052 },
    { lat: 38.9434, lng: -84.2857 },
    { lat: 38.8055, lng: -84.2432 },
    { lat: 38.7712, lng: -84.0866 },
    { lat: 38.7519, lng: -83.8916 },
    { lat: 38.6330, lng: -83.6636 },
    { lat: 38.6962, lng: -83.5263 },
    { lat: 38.5976, lng: -83.2736 },
    { lat: 38.6169, lng: -83.1445 },
    { lat: 38.7027, lng: -83.0127 },
    { lat: 38.7327, lng: -82.8973 },
    { lat: 38.5782, lng: -82.8355 },
    { lat: 38.3761, lng: -82.5952 },
  ],
  // OKLAHOMA
  OK: [
    { lat: 37.0015, lng: -103.0051 },
    { lat: 36.5030, lng: -103.0037 },
    { lat: 36.4986, lng: -100.0003 },
    { lat: 34.5620, lng: -99.9934 },
    { lat: 34.3763, lng: -99.6172 },
    { lat: 34.4137, lng: -99.5787 },
    { lat: 34.3763, lng: -99.4290 },
    { lat: 34.4534, lng: -99.3823 },
    { lat: 34.3616, lng: -99.2505 },
    { lat: 34.2129, lng: -99.1818 },
    { lat: 34.1982, lng: -98.9648 },
    { lat: 34.1232, lng: -98.7424 },
    { lat: 34.1254, lng: -98.5570 },
    { lat: 34.0618, lng: -98.4746 },
    { lat: 34.1414, lng: -98.3743 },
    { lat: 34.1118, lng: -98.1793 },
    { lat: 34.0060, lng: -98.0818 },
    { lat: 33.8966, lng: -97.9843 },
    { lat: 33.8487, lng: -97.8662 },
    { lat: 33.9798, lng: -97.6932 },
    { lat: 33.8841, lng: -97.4529 },
    { lat: 33.8282, lng: -97.4611 },
    { lat: 33.8237, lng: -97.3622 },
    { lat: 33.9001, lng: -97.2166 },
    { lat: 33.7426, lng: -97.1645 },
    { lat: 33.7392, lng: -97.0930 },
    { lat: 33.9354, lng: -96.9173 },
    { lat: 33.8704, lng: -96.8527 },
    { lat: 33.8271, lng: -96.7484 },
    { lat: 33.8202, lng: -96.5602 },
    { lat: 33.7769, lng: -96.5053 },
    { lat: 33.6912, lng: -96.3474 },
    { lat: 33.8522, lng: -96.0205 },
    { lat: 33.8396, lng: -95.8379 },
    { lat: 33.8887, lng: -95.7349 },
    { lat: 33.8841, lng: -95.5426 },
    { lat: 33.8738, lng: -95.2831 },
    { lat: 33.9570, lng: -95.2171 },
    { lat: 33.8670, lng: -95.0496 },
    { lat: 33.7677, lng: -94.8862 },
    { lat: 33.6695, lng: -94.6692 },
    { lat: 33.6386, lng: -94.4852 },
    { lat: 35.3499, lng: -94.4357 },
    { lat: 36.3715, lng: -94.5978 },
    { lat: 36.4942, lng: -94.6198 },
    { lat: 36.9982, lng: -94.6211 },
    { lat: 37.0004, lng: -103.0064 },
  ],
  // OREGON
  OR: [
    { lat: 46.2587, lng: -124.2444 },
    { lat: 46.2634, lng: -124.0384 },
    { lat: 46.2891, lng: -124.0205 },
    { lat: 46.2407, lng: -123.9368 },
    { lat: 46.2388, lng: -123.8681 },
    { lat: 46.2691, lng: -123.7390 },
    { lat: 46.2520, lng: -123.6868 },
    { lat: 46.2596, lng: -123.6456 },
    { lat: 46.2577, lng: -123.5468 },
    { lat: 46.2672, lng: -123.4863 },
    { lat: 46.2369, lng: -123.4287 },
    { lat: 46.1817, lng: -123.4287 },
    { lat: 46.1513, lng: -123.3765 },
    { lat: 46.1494, lng: -123.3051 },
    { lat: 46.1874, lng: -123.1512 },
    { lat: 46.1380, lng: -123.0222 },
    { lat: 46.0980, lng: -122.9398 },
    { lat: 46.0551, lng: -122.8848 },
    { lat: 45.9588, lng: -122.8189 },
    { lat: 45.9053, lng: -122.8162 },
    { lat: 45.8824, lng: -122.7887 },
    { lat: 45.8173, lng: -122.7969 },
    { lat: 45.7637, lng: -122.7585 },
    { lat: 45.6851, lng: -122.7750 },
    { lat: 45.6505, lng: -122.7530 },
    { lat: 45.6064, lng: -122.5937 },
    { lat: 45.5679, lng: -122.4426 },
    { lat: 45.5756, lng: -122.3712 },
    { lat: 45.5506, lng: -122.3245 },
    { lat: 45.5641, lng: -122.2064 },
    { lat: 45.5987, lng: -122.0856 },
    { lat: 45.6659, lng: -121.8933 },
    { lat: 45.6812, lng: -121.8906 },
    { lat: 45.7081, lng: -121.8164 },
    { lat: 45.6966, lng: -121.7560 },
    { lat: 45.7254, lng: -121.5335 },
    { lat: 45.6928, lng: -121.3907 },
    { lat: 45.7081, lng: -121.3646 },
    { lat: 45.6582, lng: -121.2039 },
    { lat: 45.6083, lng: -121.1957 },
    { lat: 45.6102, lng: -121.1462 },
    { lat: 45.6524, lng: -121.0803 },
    { lat: 45.6582, lng: -121.0144 },
    { lat: 45.6467, lng: -120.9814 },
    { lat: 45.6563, lng: -120.9430 },
    { lat: 45.6371, lng: -120.9100 },
    { lat: 45.6755, lng: -120.8524 },
    { lat: 45.7445, lng: -120.6052 },
    { lat: 45.7158, lng: -120.5310 },
    { lat: 45.6966, lng: -120.4733 },
    { lat: 45.7234, lng: -120.2893 },
    { lat: 45.7234, lng: -120.2124 },
    { lat: 45.7714, lng: -120.1575 },
    { lat: 45.8211, lng: -119.9680 },
    { lat: 45.8498, lng: -119.7839 },
    { lat: 45.8575, lng: -119.6658 },
    { lat: 45.9149, lng: -119.6136 },
    { lat: 45.9244, lng: -119.5724 },
    { lat: 45.9110, lng: -119.5258 },
    { lat: 45.9378, lng: -119.2676 },
    { lat: 45.9168, lng: -119.1687 },
    { lat: 45.9779, lng: -119.0149 },
    { lat: 46.0027, lng: -118.9847 },
    { lat: 45.9970, lng: -116.9165 },
    { lat: 45.9569, lng: -116.8781 },
    { lat: 45.8957, lng: -116.8588 },
    { lat: 45.8747, lng: -116.8066 },
    { lat: 45.8307, lng: -116.7902 },
    { lat: 45.8192, lng: -116.7654 },
    { lat: 45.8231, lng: -116.7105 },
    { lat: 45.7828, lng: -116.6748 },
    { lat: 45.7828, lng: -116.6144 },
    { lat: 45.7522, lng: -116.5430 },
    { lat: 45.6889, lng: -116.5402 },
    { lat: 45.6121, lng: -116.4606 },
    { lat: 45.5006, lng: -116.5567 },
    { lat: 45.4640, lng: -116.5567 },
    { lat: 45.4447, lng: -116.5869 },
    { lat: 45.3213, lng: -116.6721 },
    { lat: 45.2633, lng: -116.6940 },
    { lat: 45.1414, lng: -116.7325 },
    { lat: 45.1065, lng: -116.7764 },
    { lat: 45.0696, lng: -116.7847 },
    { lat: 45.0231, lng: -116.8451 },
    { lat: 44.9765, lng: -116.8616 },
    { lat: 44.9298, lng: -116.8286 },
    { lat: 44.7955, lng: -116.9302 },
    { lat: 44.7487, lng: -117.0346 },
    { lat: 44.5787, lng: -117.1225 },
    { lat: 44.5455, lng: -117.1445 },
    { lat: 44.4847, lng: -117.2076 },
    { lat: 44.4789, lng: -117.2269 },
    { lat: 44.4063, lng: -117.2269 },
    { lat: 44.3926, lng: -117.2488 },
    { lat: 44.3455, lng: -117.1939 },
    { lat: 44.2983, lng: -117.2269 },
    { lat: 44.2570, lng: -117.1774 },
    { lat: 44.2609, lng: -117.1445 },
    { lat: 44.2806, lng: -117.1005 },
    { lat: 44.2314, lng: -117.0511 },
    { lat: 44.2491, lng: -117.0428 },
    { lat: 44.2432, lng: -116.9797 },
    { lat: 44.1940, lng: -116.9742 },
    { lat: 44.1664, lng: -116.8945 },
    { lat: 44.0935, lng: -116.9330 },
    { lat: 44.0836, lng: -116.9714 },
    { lat: 44.0521, lng: -116.9879 },
    { lat: 44.0244, lng: -116.9302 },
    { lat: 43.9533, lng: -116.9659 },
    { lat: 43.8207, lng: -117.0209 },
    { lat: 43.6639, lng: -117.0264 },
    { lat: 42.0024, lng: -117.0264 },
    { lat: 41.9983, lng: -121.2836 },
    { lat: 42.0085, lng: -122.5003 },
    { lat: 42.0064, lng: -123.0798 },
    { lat: 42.0105, lng: -123.1540 },
    { lat: 42.0044, lng: -123.2419 },
    { lat: 42.0024, lng: -123.6209 },
    { lat: 41.9952, lng: -124.3982 },
    { lat: 42.1593, lng: -124.5493 },
    { lat: 42.6521, lng: -124.5877 },
    { lat: 42.8115, lng: -124.7305 },
    { lat: 43.7552, lng: -124.4119 },
    { lat: 46.0065, lng: -124.2554 },
    { lat: 46.2587, lng: -124.2444 },
  ],
  // PENNSYLVANIA
  PA: [
    { lat: 42.3261, lng: -80.5174 },
    { lat: 42.3961, lng: -80.0821 },
    { lat: 42.5167, lng: -79.7621 },
    { lat: 42.0003, lng: -79.7607 },
    { lat: 41.9983, lng: -75.3580 },
    { lat: 41.9431, lng: -75.2673 },
    { lat: 41.8696, lng: -75.1794 },
    { lat: 41.7713, lng: -75.0586 },
    { lat: 41.6729, lng: -75.0366 },
    { lat: 41.6021, lng: -75.0641 },
    { lat: 41.5086, lng: -74.9927 },
    { lat: 41.4283, lng: -74.7935 },
    { lat: 41.3933, lng: -74.7070 },
    { lat: 41.2282, lng: -74.8608 },
    { lat: 40.9830, lng: -75.1355 },
    { lat: 40.8554, lng: -75.0490 },
    { lat: 40.6806, lng: -75.1904 },
    { lat: 40.5639, lng: -75.2124 },
    { lat: 40.5743, lng: -75.1025 },
    { lat: 40.5013, lng: -75.0600 },
    { lat: 40.4208, lng: -75.0655 },
    { lat: 40.4072, lng: -74.9776 },
    { lat: 40.3392, lng: -74.9432 },
    { lat: 40.2628, lng: -74.8389 },
    { lat: 40.1495, lng: -74.7221 },
    { lat: 39.9592, lng: -75.0929 },
    { lat: 39.8370, lng: -75.2577 },
    { lat: 39.8128, lng: -75.4321 },
    { lat: 39.8317, lng: -75.6477 },
    { lat: 39.7199, lng: -75.7892 },
    { lat: 39.7220, lng: -80.5243 },
    { lat: 42.3240, lng: -80.5202 },
  ],
  // RHODE ISLAND
  RI: [
    { lat: 41.3706, lng: -71.0568 },
    { lat: 41.3706, lng: -71.0541 },
    { lat: 41.6339, lng: -71.1447 },
    { lat: 41.6791, lng: -71.1942 },
    { lat: 41.7611, lng: -71.2793 },
    { lat: 41.7775, lng: -71.3315 },
    { lat: 41.7918, lng: -71.3425 },
    { lat: 41.8225, lng: -71.3507 },
    { lat: 41.8512, lng: -71.3370 },
    { lat: 41.8880, lng: -71.3397 },
    { lat: 41.9288, lng: -71.3837 },
    { lat: 42.0156, lng: -71.3795 },
    { lat: 42.0064, lng: -71.7984 },
    { lat: 41.6750, lng: -71.7874 },
    { lat: 41.4530, lng: -71.7984 },
    { lat: 41.3943, lng: -71.8492 },
    { lat: 41.3315, lng: -71.8520 },
    { lat: 41.3057, lng: -71.9041 },
    { lat: 41.1849, lng: -71.7915 },
    { lat: 41.2665, lng: -71.4084 },
  ],
  // SOUTH CAROLINA
  SC: [
    { lat: 32.0488, lng: -80.7001 },
    { lat: 32.0453, lng: -80.8978 },
    { lat: 32.1105, lng: -81.1134 },
    { lat: 32.2058, lng: -81.1423 },
    { lat: 32.3846, lng: -81.1821 },
    { lat: 32.4576, lng: -81.1986 },
    { lat: 32.5283, lng: -81.2769 },
    { lat: 32.5838, lng: -81.4087 },
    { lat: 32.6926, lng: -81.4183 },
    { lat: 32.8242, lng: -81.4746 },
    { lat: 32.9465, lng: -81.5117 },
    { lat: 33.0098, lng: -81.5034 },
    { lat: 33.0777, lng: -81.6010 },
    { lat: 33.1238, lng: -81.7122 },
    { lat: 33.2065, lng: -81.8289 },
    { lat: 33.3443, lng: -81.9319 },
    { lat: 33.4830, lng: -82.0280 },
    { lat: 33.5860, lng: -82.1475 },
    { lat: 33.6878, lng: -82.2437 },
    { lat: 33.7609, lng: -82.2437 },
    { lat: 33.8305, lng: -82.3576 },
    { lat: 33.9308, lng: -82.5018 },
    { lat: 33.9650, lng: -82.5471 },
    { lat: 34.0947, lng: -82.6625 },
    { lat: 34.1664, lng: -82.7216 },
    { lat: 34.3434, lng: -82.7930 },
    { lat: 34.4647, lng: -82.8905 },
    { lat: 34.4760, lng: -82.9893 },
    { lat: 34.5281, lng: -83.0855 },
    { lat: 34.6581, lng: -83.3121 },
    { lat: 34.7326, lng: -83.3588 },
    { lat: 34.8318, lng: -83.2983 },
    { lat: 34.9276, lng: -83.1459 },
    { lat: 34.9996, lng: -83.1047 },
    { lat: 35.0817, lng: -82.7779 },
    { lat: 35.2075, lng: -82.3920 },
    { lat: 35.1974, lng: -82.2203 },
    { lat: 35.1480, lng: -81.0379 },
    { lat: 35.0446, lng: -81.0324 },
    { lat: 35.1019, lng: -80.9322 },
    { lat: 34.9344, lng: -80.7811 },
    { lat: 34.8194, lng: -80.7948 },
    { lat: 34.8048, lng: -79.6756 },
    { lat: 34.2016, lng: -78.9560 },
    { lat: 33.7951, lng: -78.4836 },
    { lat: 33.6489, lng: -78.7871 },
    { lat: 33.2019, lng: -79.0837 },
    { lat: 32.7607, lng: -79.4476 },
    { lat: 32.5225, lng: -79.8116 },
    { lat: 32.3556, lng: -80.1508 },
    { lat: 32.2012, lng: -80.4240 },
    { lat: 32.0500, lng: -80.7001 },
  ],
  // SOUTH DAKOTA
  SD: [
    { lat: 45.9435, lng: -104.0501 },
    { lat: 43.0026, lng: -104.0529 },
    { lat: 42.9946, lng: -98.4993 },
    { lat: 42.9283, lng: -98.4444 },
    { lat: 42.8438, lng: -98.1958 },
    { lat: 42.7773, lng: -98.0571 },
    { lat: 42.7631, lng: -97.9761 },
    { lat: 42.8599, lng: -97.8566 },
    { lat: 42.8518, lng: -97.2290 },
    { lat: 42.8075, lng: -97.2057 },
    { lat: 42.7157, lng: -96.8486 },
    { lat: 42.5237, lng: -96.6220 },
    { lat: 42.4772, lng: -96.4806 },
    { lat: 42.7299, lng: -96.6289 },
    { lat: 43.0046, lng: -96.5039 },
    { lat: 43.1331, lng: -96.4380 },
    { lat: 43.2262, lng: -96.5561 },
    { lat: 43.3841, lng: -96.5259 },
    { lat: 43.4848, lng: -96.6138 },
    { lat: 43.4998, lng: -96.4558 },
    { lat: 45.2981, lng: -96.4545 },
    { lat: 45.4601, lng: -96.7429 },
    { lat: 45.6160, lng: -96.8472 },
    { lat: 45.8202, lng: -96.5849 },
    { lat: 45.9311, lng: -96.5643 },
    { lat: 45.9416, lng: -104.0501 },
  ],
  // TENNESSEE
  TN: [
    { lat: 36.6739, lng: -88.0678 },
    { lat: 36.6354, lng: -87.8522 },
    { lat: 36.6023, lng: -83.6787 },
    { lat: 36.5946, lng: -81.9402 },
    { lat: 36.6144, lng: -81.9209 },
    { lat: 36.6111, lng: -81.6518 },
    { lat: 36.3295, lng: -81.7163 },
    { lat: 36.3516, lng: -81.7973 },
    { lat: 36.2974, lng: -81.9072 },
    { lat: 36.1212, lng: -82.0308 },
    { lat: 36.1024, lng: -82.1255 },
    { lat: 36.1434, lng: -82.1475 },
    { lat: 36.1323, lng: -82.2450 },
    { lat: 36.1168, lng: -82.3521 },
    { lat: 36.0702, lng: -82.4167 },
    { lat: 35.9669, lng: -82.5389 },
    { lat: 35.9702, lng: -82.6076 },
    { lat: 36.0602, lng: -82.6378 },
    { lat: 35.9925, lng: -82.7751 },
    { lat: 35.9169, lng: -82.8177 },
    { lat: 35.8623, lng: -82.9042 },
    { lat: 35.7755, lng: -83.0017 },
    { lat: 35.7131, lng: -83.2393 },
    { lat: 35.5635, lng: -83.4961 },
    { lat: 35.5501, lng: -83.6938 },
    { lat: 35.5233, lng: -83.8284 },
    { lat: 35.5065, lng: -83.8847 },
    { lat: 35.4014, lng: -84.0248 },
    { lat: 35.2905, lng: -84.0276 },
    { lat: 35.2322, lng: -84.1113 },
    { lat: 35.2624, lng: -84.2294 },
    { lat: 35.2198, lng: -84.2926 },
    { lat: 34.9884, lng: -84.3201 },
    { lat: 34.9996, lng: -90.3131 },
    { lat: 35.1233, lng: -90.2843 },
    { lat: 35.1379, lng: -90.1758 },
    { lat: 35.1985, lng: -90.1112 },
    { lat: 35.2849, lng: -90.1524 },
    { lat: 35.4372, lng: -90.1346 },
    { lat: 35.5568, lng: -90.0192 },
    { lat: 35.7343, lng: -89.9547 },
    { lat: 35.8579, lng: -89.7638 },
    { lat: 35.9180, lng: -89.6635 },
    { lat: 35.9947, lng: -89.7130 },
    { lat: 36.0902, lng: -89.6759 },
    { lat: 36.1279, lng: -89.5894 },
    { lat: 36.1856, lng: -89.6484 },
    { lat: 36.2343, lng: -89.7006 },
    { lat: 36.2531, lng: -89.5331 },
    { lat: 36.2996, lng: -89.6210 },
    { lat: 36.3494, lng: -89.5784 },
    { lat: 36.3406, lng: -89.5180 },
    { lat: 36.4964, lng: -89.5345 },
    { lat: 36.5107, lng: -89.3051 },
    { lat: 36.4986, lng: -88.1667 },
    { lat: 36.4997, lng: -88.0692 },
    { lat: 36.6871, lng: -88.0637 },
  ],
  // TEXAS
  TX: [
    { lat: 31.8659, lng: -106.5715 },
    { lat: 31.7504, lng: -106.5042 },
    { lat: 31.6242, lng: -106.3092 },
    { lat: 31.4638, lng: -106.2103 },
    { lat: 31.3912, lng: -106.0181 },
    { lat: 31.1846, lng: -105.7874 },
    { lat: 31.0012, lng: -105.5663 },
    { lat: 30.8456, lng: -105.4015 },
    { lat: 30.6462, lng: -105.0032 },
    { lat: 30.3847, lng: -104.8521 },
    { lat: 30.2591, lng: -104.7437 },
    { lat: 30.0738, lng: -104.6915 },
    { lat: 29.9169, lng: -104.6777 },
    { lat: 29.7644, lng: -104.5679 },
    { lat: 29.6475, lng: -104.5280 },
    { lat: 29.5603, lng: -104.4044 },
    { lat: 29.4719, lng: -104.2067 },
    { lat: 29.3834, lng: -104.1559 },
    { lat: 29.2948, lng: -103.9774 },
    { lat: 29.2804, lng: -103.9128 },
    { lat: 29.2481, lng: -103.8208 },
    { lat: 29.1378, lng: -103.5640 },
    { lat: 29.0682, lng: -103.4692 },
    { lat: 29.0105, lng: -103.3154 },
    { lat: 28.9601, lng: -103.1616 },
    { lat: 29.0177, lng: -103.0957 },
    { lat: 29.1330, lng: -103.0298 },
    { lat: 29.2157, lng: -102.8677 },
    { lat: 29.2565, lng: -102.8979 },
    { lat: 29.3570, lng: -102.8375 },
    { lat: 29.4898, lng: -102.8004 },
    { lat: 29.6881, lng: -102.7002 },
    { lat: 29.7691, lng: -102.5134 },
    { lat: 29.7596, lng: -102.3843 },
    { lat: 29.8788, lng: -102.3047 },
    { lat: 29.7834, lng: -102.1509 },
    { lat: 29.7572, lng: -101.7004 },
    { lat: 29.7644, lng: -101.4917 },
    { lat: 29.6308, lng: -101.2939 },
    { lat: 29.5269, lng: -101.2582 },
    { lat: 29.3642, lng: -101.0056 },
    { lat: 29.3056, lng: -100.9204 },
    { lat: 29.1642, lng: -100.7707 },
    { lat: 29.0946, lng: -100.7007 },
    { lat: 28.9012, lng: -100.6306 },
    { lat: 28.6593, lng: -100.4974 },
    { lat: 28.4675, lng: -100.3601 },
    { lat: 28.2778, lng: -100.2969 },
    { lat: 28.1882, lng: -100.1733 },
    { lat: 28.0526, lng: -100.0195 },
    { lat: 27.9435, lng: -99.9344 },
    { lat: 27.7638, lng: -99.8438 },
    { lat: 27.6641, lng: -99.7119 },
    { lat: 27.4839, lng: -99.4812 },
    { lat: 27.3059, lng: -99.5375 },
    { lat: 27.1948, lng: -99.4290 },
    { lat: 27.0175, lng: -99.4455 },
    { lat: 26.8829, lng: -99.3164 },
    { lat: 26.6867, lng: -99.2065 },
    { lat: 26.4116, lng: -99.0967 },
    { lat: 26.3574, lng: -98.8138 },
    { lat: 26.2257, lng: -98.6668 },
    { lat: 26.2343, lng: -98.5474 },
    { lat: 26.1357, lng: -98.3276 },
    { lat: 26.0457, lng: -98.1697 },
    { lat: 26.0518, lng: -97.9143 },
    { lat: 26.0050, lng: -97.6643 },
    { lat: 25.8419, lng: -97.4020 },
    { lat: 25.9074, lng: -97.3526 },
    { lat: 25.9679, lng: -97.0148 },
    { lat: 26.1789, lng: -97.0697 },
    { lat: 26.8253, lng: -97.2249 },
    { lat: 27.4230, lng: -97.0752 },
    { lat: 28.0599, lng: -96.6096 },
    { lat: 28.4228, lng: -95.9285 },
    { lat: 28.7568, lng: -95.3036 },
    { lat: 29.0742, lng: -94.7296 },
    { lat: 29.3810, lng: -94.3355 },
    { lat: 29.6021, lng: -93.8205 },
    { lat: 29.8013, lng: -93.9317 },
    { lat: 29.9157, lng: -93.8136 },
    { lat: 30.0489, lng: -93.7230 },
    { lat: 30.1214, lng: -93.6996 },
    { lat: 30.2021, lng: -93.7216 },
    { lat: 30.2792, lng: -93.7038 },
    { lat: 30.3278, lng: -93.7628 },
    { lat: 30.3835, lng: -93.7587 },
    { lat: 30.4380, lng: -93.7010 },
    { lat: 30.5079, lng: -93.7024 },
    { lat: 30.5362, lng: -93.7299 },
    { lat: 30.6296, lng: -93.6694 },
    { lat: 30.7466, lng: -93.6090 },
    { lat: 30.8114, lng: -93.5527 },
    { lat: 30.8834, lng: -93.5747 },
    { lat: 30.9376, lng: -93.5307 },
    { lat: 31.0318, lng: -93.5074 },
    { lat: 31.0812, lng: -93.5266 },
    { lat: 31.1787, lng: -93.5335 },
    { lat: 31.1670, lng: -93.5980 },
    { lat: 31.3055, lng: -93.6832 },
    { lat: 31.3830, lng: -93.6708 },
    { lat: 31.4369, lng: -93.6887 },
    { lat: 31.5107, lng: -93.7202 },
    { lat: 31.5820, lng: -93.8315 },
    { lat: 31.6440, lng: -93.8123 },
    { lat: 31.7188, lng: -93.8232 },
    { lat: 31.7936, lng: -93.8342 },
    { lat: 31.8309, lng: -93.8782 },
    { lat: 31.8869, lng: -93.9221 },
    { lat: 31.9335, lng: -93.9661 },
    { lat: 32.0081, lng: -94.0430 },
    { lat: 33.4681, lng: -94.0430 },
    { lat: 33.5414, lng: -94.0430 },
    { lat: 33.5689, lng: -94.1528 },
    { lat: 33.5872, lng: -94.1968 },
    { lat: 33.5872, lng: -94.2627 },
    { lat: 33.5689, lng: -94.3176 },
    { lat: 33.5597, lng: -94.3945 },
    { lat: 33.5780, lng: -94.4275 },
    { lat: 33.6055, lng: -94.4275 },
    { lat: 33.6421, lng: -94.4495 },
    { lat: 33.6329, lng: -94.4879 },
    { lat: 33.6421, lng: -94.5236 },
    { lat: 33.6695, lng: -94.6637 },
    { lat: 33.7061, lng: -94.7461 },
    { lat: 33.7791, lng: -94.8999 },
    { lat: 33.8818, lng: -95.0757 },
    { lat: 33.9251, lng: -95.1526 },
    { lat: 33.9604, lng: -95.2254 },
    { lat: 33.8750, lng: -95.2858 },
    { lat: 33.8841, lng: -95.5399 },
    { lat: 33.8887, lng: -95.7568 },
    { lat: 33.8408, lng: -95.8420 },
    { lat: 33.8556, lng: -96.0274 },
    { lat: 33.6901, lng: -96.3528 },
    { lat: 33.8442, lng: -96.6179 },
    { lat: 33.8898, lng: -96.5836 },
    { lat: 33.8955, lng: -96.6673 },
    { lat: 33.8179, lng: -96.7538 },
    { lat: 33.8613, lng: -96.8335 },
    { lat: 33.8613, lng: -96.8774 },
    { lat: 33.9388, lng: -96.9159 },
    { lat: 33.7392, lng: -97.0917 },
    { lat: 33.7449, lng: -97.1645 },
    { lat: 33.8978, lng: -97.2180 },
    { lat: 33.8225, lng: -97.3746 },
    { lat: 33.8305, lng: -97.4611 },
    { lat: 33.8761, lng: -97.4460 },
    { lat: 33.9798, lng: -97.6945 },
    { lat: 33.8476, lng: -97.8648 },
    { lat: 33.8978, lng: -97.9651 },
    { lat: 34.0299, lng: -98.0983 },
    { lat: 34.1141, lng: -98.1752 },
    { lat: 34.1425, lng: -98.3743 },
    { lat: 34.0640, lng: -98.4773 },
    { lat: 34.1209, lng: -98.5529 },
    { lat: 34.1232, lng: -98.7520 },
    { lat: 34.2095, lng: -98.9539 },
    { lat: 34.2073, lng: -99.0637 },
    { lat: 34.2141, lng: -99.1832 },
    { lat: 34.3593, lng: -99.2505 },
    { lat: 34.4613, lng: -99.3823 },
    { lat: 34.3774, lng: -99.4318 },
    { lat: 34.4160, lng: -99.5718 },
    { lat: 34.3706, lng: -99.6158 },
    { lat: 34.4726, lng: -99.8094 },
    { lat: 34.5631, lng: -99.9934 },
    { lat: 36.4975, lng: -100.0017 },
    { lat: 36.5008, lng: -103.0408 },
    { lat: 32.0011, lng: -103.0655 },
    { lat: 32.0023, lng: -106.6168 },
  ],
  // UTAH
  UT: [
    { lat: 36.9982, lng: -114.0491 },
    { lat: 37.0026, lng: -109.0462 },
    { lat: 40.9986, lng: -109.0503 },
    { lat: 41.0006, lng: -111.0471 },
    { lat: 41.9993, lng: -111.0498 },
    { lat: 41.9901, lng: -114.0395 },
    { lat: 37.0015, lng: -114.0504 },
  ],
  // VERMONT
  VT: [
    { lat: 45.0104, lng: -73.3427 },
    { lat: 45.0134, lng: -73.1827 },
    { lat: 45.0153, lng: -72.7432 },
    { lat: 45.0134, lng: -72.6100 },
    { lat: 45.0075, lng: -72.5551 },
    { lat: 45.0090, lng: -72.4562 },
    { lat: 45.0037, lng: -72.3113 },
    { lat: 45.0066, lng: -72.0964 },
    { lat: 45.0070, lng: -71.9131 },
    { lat: 45.0138, lng: -71.5636 },
    { lat: 45.0138, lng: -71.5059 },
    { lat: 44.9748, lng: -71.5294 },
    { lat: 44.9123, lng: -71.4949 },
    { lat: 44.8296, lng: -71.5567 },
    { lat: 44.7506, lng: -71.6281 },
    { lat: 44.7077, lng: -71.6061 },
    { lat: 44.6481, lng: -71.5677 },
    { lat: 44.5817, lng: -71.5388 },
    { lat: 44.5533, lng: -71.6006 },
    { lat: 44.5308, lng: -71.5746 },
    { lat: 44.4955, lng: -71.5883 },
    { lat: 44.4504, lng: -71.6556 },
    { lat: 44.4093, lng: -71.7146 },
    { lat: 44.3975, lng: -71.7957 },
    { lat: 44.3563, lng: -71.8163 },
    { lat: 44.3327, lng: -71.8698 },
    { lat: 44.3484, lng: -71.9138 },
    { lat: 44.3386, lng: -71.9865 },
    { lat: 44.3052, lng: -72.0346 },
    { lat: 44.2432, lng: -72.0428 },
    { lat: 44.1930, lng: -72.0662 },
    { lat: 44.1349, lng: -72.0360 },
    { lat: 44.0698, lng: -72.0580 },
    { lat: 44.0017, lng: -72.1101 },
    { lat: 43.9671, lng: -72.0937 },
    { lat: 43.9088, lng: -72.1252 },
    { lat: 43.8682, lng: -72.1733 },
    { lat: 43.7899, lng: -72.1994 },
    { lat: 43.7899, lng: -72.1994 },
    { lat: 43.7384, lng: -72.2392 },
    { lat: 43.7056, lng: -72.3010 },
    { lat: 43.6391, lng: -72.3271 },
    { lat: 43.5893, lng: -72.3436 },
    { lat: 43.5814, lng: -72.3793 },
    { lat: 43.5027, lng: -72.3972 },
    { lat: 43.4988, lng: -72.3807 },
    { lat: 43.4150, lng: -72.3999 },
    { lat: 43.3601, lng: -72.4123 },
    { lat: 43.3591, lng: -72.3903 },
    { lat: 43.3282, lng: -72.4081 },
    { lat: 43.2762, lng: -72.3999 },
    { lat: 43.2342, lng: -72.4370 },
    { lat: 43.1852, lng: -72.4493 },
    { lat: 43.1311, lng: -72.4480 },
    { lat: 43.0679, lng: -72.4507 },
    { lat: 43.0067, lng: -72.4438 },
    { lat: 42.9846, lng: -72.4699 },
    { lat: 42.9645, lng: -72.5276 },
    { lat: 42.8951, lng: -72.5331 },
    { lat: 42.8639, lng: -72.5633 },
    { lat: 42.7863, lng: -72.5098 },
    { lat: 42.7652, lng: -72.5166 },
    { lat: 42.7541, lng: -72.4741 },
    { lat: 42.7289, lng: -72.4590 },
    { lat: 42.7465, lng: -73.2761 },
    { lat: 42.8025, lng: -73.2912 },
    { lat: 42.8357, lng: -73.2850 },
    { lat: 43.0679, lng: -73.2678 },
    { lat: 43.5022, lng: -73.2472 },
    { lat: 43.5615, lng: -73.2561 },
    { lat: 43.5774, lng: -73.2939 },
    { lat: 43.6271, lng: -73.3049 },
    { lat: 43.6271, lng: -73.3557 },
    { lat: 43.5675, lng: -73.3976 },
    { lat: 43.5883, lng: -73.4326 },
    { lat: 43.6351, lng: -73.4285 },
    { lat: 43.6684, lng: -73.4079 },
    { lat: 43.7031, lng: -73.3907 },
    { lat: 43.7701, lng: -73.3516 },
    { lat: 43.8207, lng: -73.3928 },
    { lat: 43.8533, lng: -73.3832 },
    { lat: 43.9033, lng: -73.3969 },
    { lat: 43.9365, lng: -73.4086 },
    { lat: 43.9795, lng: -73.4134 },
    { lat: 44.0427, lng: -73.4381 },
    { lat: 44.1058, lng: -73.4141 },
    { lat: 44.1921, lng: -73.3928 },
    { lat: 44.2393, lng: -73.3427 },
    { lat: 44.2467, lng: -73.3186 },
    { lat: 44.3484, lng: -73.3406 },
    { lat: 44.3690, lng: -73.3385 },
    { lat: 44.4328, lng: -73.2946 },
    { lat: 44.5367, lng: -73.3296 },
    { lat: 44.5919, lng: -73.3832 },
    { lat: 44.6569, lng: -73.3770 },
    { lat: 44.7477, lng: -73.3681 },
    { lat: 44.7857, lng: -73.3317 },
    { lat: 44.8043, lng: -73.3324 },
    { lat: 44.8398, lng: -73.3818 },
    { lat: 44.9040, lng: -73.3564 },
    { lat: 44.9181, lng: -73.3392 },
    { lat: 44.9643, lng: -73.3372 },
    { lat: 44.9799, lng: -73.3537 },
    { lat: 45.0046, lng: -73.3447 },
    { lat: 45.0109, lng: -73.3447 },
    { lat: 45.0104, lng: -73.3426 },
  ],
  // VIRGINIA
  VA: [
    { lat: 36.6017, lng: -83.6753 },
    { lat: 36.6519, lng: -83.5613 },
    { lat: 36.6673, lng: -83.4432 },
    { lat: 36.7108, lng: -83.3080 },
    { lat: 36.7389, lng: -83.1974 },
    { lat: 36.7433, lng: -83.1363 },
    { lat: 36.8032, lng: -83.1047 },
    { lat: 36.8302, lng: -83.1033 },
    { lat: 36.8544, lng: -83.0704 },
    { lat: 36.8478, lng: -83.0127 },
    { lat: 36.9839, lng: -82.8534 },
    { lat: 37.0086, lng: -82.7971 },
    { lat: 37.0442, lng: -82.7360 },
    { lat: 37.2664, lng: -82.3494 },
    { lat: 37.3046, lng: -82.3027 },
    { lat: 37.5054, lng: -82.0136 },
    { lat: 37.5380, lng: -81.9681 },
    { lat: 37.5139, lng: -81.9259 },
    { lat: 37.4889, lng: -81.9707 },
    { lat: 37.4563, lng: -81.9800 },
    { lat: 37.4389, lng: -81.9374 },
    { lat: 37.3592, lng: -81.9319 },
    { lat: 37.3134, lng: -81.8619 },
    { lat: 37.2872, lng: -81.7987 },
    { lat: 37.2424, lng: -81.7259 },
    { lat: 37.2008, lng: -81.6792 },
    { lat: 37.2083, lng: -81.5549 },
    { lat: 37.2588, lng: -81.4988 },
    { lat: 37.2456, lng: -81.4856 },
    { lat: 37.2742, lng: -81.4367 },
    { lat: 37.3243, lng: -81.3675 },
    { lat: 37.2653, lng: -81.2741 },
    { lat: 37.2719, lng: -81.1560 },
    { lat: 37.3025, lng: -80.9802 },
    { lat: 37.3396, lng: -80.8566 },
    { lat: 37.3876, lng: -80.8786 },
    { lat: 37.4247, lng: -80.8511 },
    { lat: 37.3702, lng: -80.7715 },
    { lat: 37.4749, lng: -80.5518 },
    { lat: 37.4792, lng: -80.5133 },
    { lat: 37.4247, lng: -80.4803 },
    { lat: 37.5097, lng: -80.3018 },
    { lat: 37.5359, lng: -80.2798 },
    { lat: 37.5380, lng: -80.3293 },
    { lat: 37.6316, lng: -80.2249 },
    { lat: 37.6903, lng: -80.3018 },
    { lat: 37.7251, lng: -80.2551 },
    { lat: 37.7707, lng: -80.2551 },
    { lat: 37.8347, lng: -80.1961 },
    { lat: 37.8749, lng: -80.1508 },
    { lat: 38.0394, lng: -79.9722 },
    { lat: 38.1302, lng: -79.9448 },
    { lat: 38.1972, lng: -79.9063 },
    { lat: 38.2684, lng: -79.7882 },
    { lat: 38.3093, lng: -79.8129 },
    { lat: 38.3589, lng: -79.7662 },
    { lat: 38.3513, lng: -79.7401 },
    { lat: 38.3869, lng: -79.7333 },
    { lat: 38.4321, lng: -79.6893 },
    { lat: 38.4987, lng: -79.6948 },
    { lat: 38.5857, lng: -79.6495 },
    { lat: 38.5492, lng: -79.5328 },
    { lat: 38.4557, lng: -79.4724 },
    { lat: 38.4149, lng: -79.3103 },
    { lat: 38.5138, lng: -79.2087 },
    { lat: 38.6576, lng: -79.1016 },
    { lat: 38.6941, lng: -79.0851 },
    { lat: 38.7112, lng: -79.1016 },
    { lat: 38.7712, lng: -79.0576 },
    { lat: 38.7926, lng: -79.0604 },
    { lat: 38.7926, lng: -79.0247 },
    { lat: 38.8504, lng: -78.9944 },
    { lat: 38.7637, lng: -78.8736 },
    { lat: 38.8857, lng: -78.7830 },
    { lat: 38.9327, lng: -78.7198 },
    { lat: 38.9071, lng: -78.7129 },
    { lat: 38.9850, lng: -78.6291 },
    { lat: 38.9658, lng: -78.6044 },
    { lat: 39.0170, lng: -78.5522 },
    { lat: 39.0384, lng: -78.5770 },
    { lat: 39.0938, lng: -78.5083 },
    { lat: 39.1492, lng: -78.4314 },
    { lat: 39.1684, lng: -78.4039 },
    { lat: 39.1939, lng: -78.4314 },
    { lat: 39.3470, lng: -78.3380 },
    { lat: 39.3853, lng: -78.3380 },
    { lat: 39.4659, lng: -78.3469 },
    { lat: 39.2283, lng: -77.9744 },
    { lat: 39.1338, lng: -77.8302 },
    { lat: 39.3130, lng: -77.7355 },
    { lat: 39.3213, lng: -77.7090 },
    { lat: 39.3043, lng: -77.6218 },
    { lat: 39.3067, lng: -77.5686 },
    { lat: 39.2248, lng: -77.4567 },
    { lat: 39.1543, lng: -77.5226 },
    { lat: 39.1277, lng: -77.5243 },
    { lat: 39.0914, lng: -77.4687 },
    { lat: 39.0720, lng: -77.4485 },
    { lat: 39.0634, lng: -77.3427 },
    { lat: 39.0456, lng: -77.2936 },
    { lat: 39.0168, lng: -77.2459 },
    { lat: 38.9829, lng: -77.2445 },
    { lat: 38.9680, lng: -77.1834 },
    { lat: 38.9250, lng: -77.1120 },
    { lat: 38.8798, lng: -77.0464 },
    { lat: 38.7538, lng: -77.0444 },
    { lat: 38.7142, lng: -77.0409 },
    { lat: 38.6635, lng: -77.1312 },
    { lat: 38.6319, lng: -77.1285 },
    { lat: 38.5935, lng: -77.2456 },
    { lat: 38.4547, lng: -77.3094 },
    { lat: 38.3904, lng: -77.3046 },
    { lat: 38.3500, lng: -77.2188 },
    { lat: 38.4041, lng: -77.0622 },
    { lat: 38.4256, lng: -77.0265 },
    { lat: 38.3039, lng: -76.9826 },
    { lat: 38.2155, lng: -76.8851 },
    { lat: 38.1745, lng: -76.5926 },
    { lat: 37.9496, lng: -76.2424 },
    { lat: 37.9561, lng: -76.0501 },
    { lat: 37.9474, lng: -75.9416 },
    { lat: 37.9085, lng: -75.9512 },
    { lat: 37.9139, lng: -75.8002 },
    { lat: 37.9539, lng: -75.6931 },
    { lat: 37.9474, lng: -75.6477 },
    { lat: 37.9962, lng: -75.6258 },
    { lat: 38.0091, lng: -75.4596 },
    { lat: 38.0373, lng: -74.9707 },
    { lat: 37.3625, lng: -75.5777 },
    { lat: 36.8994, lng: -75.8180 },
    { lat: 36.5516, lng: -75.8043 },
    { lat: 36.5516, lng: -76.1476 },
    { lat: 36.5516, lng: -76.8617 },
    { lat: 36.5516, lng: -76.9180 },
    { lat: 36.5438, lng: -76.9180 },
    { lat: 36.5505, lng: -77.1803 },
    { lat: 36.5449, lng: -77.5278 },
    { lat: 36.5427, lng: -80.2730 },
    { lat: 36.5571, lng: -80.5971 },
    { lat: 36.5626, lng: -80.7248 },
    { lat: 36.5593, lng: -80.8511 },
    { lat: 36.5681, lng: -81.1066 },
    { lat: 36.5813, lng: -81.4746 },
    { lat: 36.5802, lng: -81.5007 },
    { lat: 36.5902, lng: -81.6792 },
    { lat: 36.6122, lng: -81.6476 },
    { lat: 36.6177, lng: -81.9237 },
    { lat: 36.5935, lng: -81.9360 },
    { lat: 36.5957, lng: -82.3961 },
    { lat: 36.5935, lng: -82.9344 },
    { lat: 36.5957, lng: -83.2654 },
    { lat: 36.6023, lng: -83.6746 },
  ],
  // WASHINGTON
  WA: [
    { lat: 49.0023, lng: -123.3208 },
    { lat: 49.0027, lng: -123.0338 },
    { lat: 49.0018, lng: -122.0650 },
    { lat: 48.9973, lng: -121.7491 },
    { lat: 48.9991, lng: -121.5912 },
    { lat: 49.0009, lng: -119.6082 },
    { lat: 49.0005, lng: -118.0378 },
    { lat: 48.9996, lng: -117.0319 },
    { lat: 47.9614, lng: -117.0415 },
    { lat: 46.5060, lng: -117.0394 },
    { lat: 46.4274, lng: -117.0394 },
    { lat: 46.3498, lng: -117.0621 },
    { lat: 46.3384, lng: -117.0277 },
    { lat: 46.2848, lng: -116.9879 },
    { lat: 46.2388, lng: -116.9577 },
    { lat: 46.2022, lng: -116.9659 },
    { lat: 46.1722, lng: -116.9254 },
    { lat: 46.1432, lng: -116.9357 },
    { lat: 46.1009, lng: -116.9584 },
    { lat: 46.0785, lng: -116.9762 },
    { lat: 46.0537, lng: -116.9433 },
    { lat: 45.9960, lng: -116.9165 },
    { lat: 46.0008, lng: -118.0330 },
    { lat: 45.9998, lng: -118.9867 },
    { lat: 45.9320, lng: -119.1302 },
    { lat: 45.9278, lng: -119.1708 },
    { lat: 45.9402, lng: -119.2559 },
    { lat: 45.9354, lng: -119.3047 },
    { lat: 45.9220, lng: -119.3644 },
    { lat: 45.9172, lng: -119.4386 },
    { lat: 45.9067, lng: -119.4894 },
    { lat: 45.9249, lng: -119.5724 },
    { lat: 45.9196, lng: -119.6013 },
    { lat: 45.8565, lng: -119.6700 },
    { lat: 45.8479, lng: -119.8052 },
    { lat: 45.8278, lng: -119.9096 },
    { lat: 45.8245, lng: -119.9652 },
    { lat: 45.7852, lng: -120.0710 },
    { lat: 45.7623, lng: -120.1705 },
    { lat: 45.7258, lng: -120.2110 },
    { lat: 45.7057, lng: -120.3628 },
    { lat: 45.6951, lng: -120.4829 },
    { lat: 45.7469, lng: -120.5942 },
    { lat: 45.7460, lng: -120.6340 },
    { lat: 45.7143, lng: -120.6924 },
    { lat: 45.6721, lng: -120.8558 },
    { lat: 45.6409, lng: -120.9142 },
    { lat: 45.6572, lng: -120.9471 },
    { lat: 45.6419, lng: -120.9787 },
    { lat: 45.6529, lng: -121.0645 },
    { lat: 45.6078, lng: -121.1469 },
    { lat: 45.6083, lng: -121.1847 },
    { lat: 45.6721, lng: -121.2177 },
    { lat: 45.7057, lng: -121.3392 },
    { lat: 45.6932, lng: -121.4010 },
    { lat: 45.7263, lng: -121.5328 },
    { lat: 45.7091, lng: -121.6145 },
    { lat: 45.6947, lng: -121.7361 },
    { lat: 45.7067, lng: -121.8095 },
    { lat: 45.6452, lng: -121.9338 },
    { lat: 45.6088, lng: -122.0451 },
    { lat: 45.5833, lng: -122.1089 },
    { lat: 45.5838, lng: -122.1426 },
    { lat: 45.5660, lng: -122.2009 },
    { lat: 45.5439, lng: -122.2641 },
    { lat: 45.5482, lng: -122.3321 },
    { lat: 45.5756, lng: -122.3795 },
    { lat: 45.5636, lng: -122.4392 },
    { lat: 45.6006, lng: -122.5676 },
    { lat: 45.6236, lng: -122.6891 },
    { lat: 45.6582, lng: -122.7647 },
    { lat: 45.6817, lng: -122.7750 },
    { lat: 45.7613, lng: -122.7619 },
    { lat: 45.8106, lng: -122.7962 },
    { lat: 45.8642, lng: -122.7839 },
    { lat: 45.9120, lng: -122.8114 },
    { lat: 45.9612, lng: -122.8148 },
    { lat: 46.0160, lng: -122.8587 },
    { lat: 46.0604, lng: -122.8848 },
    { lat: 46.0832, lng: -122.9034 },
    { lat: 46.1028, lng: -122.9597 },
    { lat: 46.1556, lng: -123.0579 },
    { lat: 46.1865, lng: -123.1210 },
    { lat: 46.1893, lng: -123.1664 },
    { lat: 46.1446, lng: -123.2810 },
    { lat: 46.1470, lng: -123.3703 },
    { lat: 46.1822, lng: -123.4314 },
    { lat: 46.2293, lng: -123.4287 },
    { lat: 46.2691, lng: -123.4946 },
    { lat: 46.2582, lng: -123.5557 },
    { lat: 46.2573, lng: -123.6209 },
    { lat: 46.2497, lng: -123.6875 },
    { lat: 46.2691, lng: -123.7404 },
    { lat: 46.2350, lng: -123.8729 },
    { lat: 46.2383, lng: -123.9292 },
    { lat: 46.2677, lng: -123.9711 },
    { lat: 46.2924, lng: -124.0212 },
    { lat: 46.2653, lng: -124.0329 },
    { lat: 46.2596, lng: -124.2444 },
    { lat: 46.4312, lng: -124.2691 },
    { lat: 46.8386, lng: -124.3529 },
    { lat: 47.1832, lng: -124.4380 },
    { lat: 47.4689, lng: -124.5616 },
    { lat: 47.8012, lng: -124.7566 },
    { lat: 48.0423, lng: -124.8679 },
    { lat: 48.2457, lng: -124.8679 },
    { lat: 48.3727, lng: -124.8486 },
    { lat: 48.4984, lng: -124.7539 },
    { lat: 48.4096, lng: -124.4174 },
    { lat: 48.3599, lng: -124.2389 },
    { lat: 48.2964, lng: -124.0116 },
    { lat: 48.2795, lng: -123.9141 },
    { lat: 48.2247, lng: -123.5413 },
    { lat: 48.2539, lng: -123.3998 },
    { lat: 48.2841, lng: -123.2501 },
    { lat: 48.4233, lng: -123.1169 },
    { lat: 48.4533, lng: -123.1609 },
    { lat: 48.5548, lng: -123.2220 },
    { lat: 48.5902, lng: -123.2336 },
    { lat: 48.6901, lng: -123.2721 },
    { lat: 48.7675, lng: -123.0084 },
    { lat: 48.8313, lng: -123.0084 },
    { lat: 49.0023, lng: -123.3215 },
  ],
  // WEST VIRGINIA
  WV: [
    { lat: 39.7188, lng: -79.4765 },
    { lat: 39.2057, lng: -79.4806 },
    { lat: 39.4871, lng: -79.0576 },
    { lat: 39.4394, lng: -78.9725 },
    { lat: 39.6438, lng: -78.7747 },
    { lat: 39.5369, lng: -78.6703 },
    { lat: 39.5210, lng: -78.5742 },
    { lat: 39.5210, lng: -78.4644 },
    { lat: 39.6015, lng: -78.4094 },
    { lat: 39.6395, lng: -78.3517 },
    { lat: 39.6205, lng: -78.2817 },
    { lat: 39.6839, lng: -78.2405 },
    { lat: 39.6935, lng: -78.1732 },
    { lat: 39.5972, lng: -77.8835 },
    { lat: 39.4987, lng: -77.7859 },
    { lat: 39.3279, lng: -77.7310 },
    { lat: 39.1279, lng: -77.8271 },
    { lat: 39.4563, lng: -78.3421 },
    { lat: 39.3407, lng: -78.3408 },
    { lat: 39.1918, lng: -78.4341 },
    { lat: 39.1652, lng: -78.4053 },
    { lat: 39.0299, lng: -78.5715 },
    { lat: 39.0160, lng: -78.5522 },
    { lat: 38.8846, lng: -78.7843 },
    { lat: 38.7669, lng: -78.8722 },
    { lat: 38.8451, lng: -78.9917 },
    { lat: 38.7102, lng: -79.0974 },
    { lat: 38.6555, lng: -79.1029 },
    { lat: 38.4106, lng: -79.3007 },
    { lat: 38.4600, lng: -79.4792 },
    { lat: 38.5342, lng: -79.5314 },
    { lat: 38.5868, lng: -79.6481 },
    { lat: 38.4869, lng: -79.6962 },
    { lat: 38.4213, lng: -79.6989 },
    { lat: 38.3557, lng: -79.7690 },
    { lat: 38.2813, lng: -79.7937 },
    { lat: 38.1518, lng: -79.9365 },
    { lat: 38.0232, lng: -79.9818 },
    { lat: 37.8835, lng: -80.1370 },
    { lat: 37.7295, lng: -80.2592 },
    { lat: 37.6795, lng: -80.2881 },
    { lat: 37.6295, lng: -80.2194 },
    { lat: 37.5391, lng: -80.3224 },
    { lat: 37.5315, lng: -80.2881 },
    { lat: 37.4988, lng: -80.3032 },
    { lat: 37.4182, lng: -80.4776 },
    { lat: 37.4814, lng: -80.5119 },
    { lat: 37.3691, lng: -80.7715 },
    { lat: 37.4214, lng: -80.8635 },
    { lat: 37.3429, lng: -80.8525 },
    { lat: 37.2872, lng: -81.0516 },
    { lat: 37.2598, lng: -81.2741 },
    { lat: 37.3254, lng: -81.3675 },
    { lat: 37.2019, lng: -81.5543 },
    { lat: 37.1953, lng: -81.6724 },
    { lat: 37.2697, lng: -81.7685 },
    { lat: 37.3003, lng: -81.8701 },
    { lat: 37.3636, lng: -81.9333 },
    { lat: 37.4574, lng: -81.9772 },
    { lat: 37.4912, lng: -82.0308 },
    { lat: 37.6142, lng: -82.1558 },
    { lat: 37.8054, lng: -82.3535 },
    { lat: 38.0481, lng: -82.5513 },
    { lat: 38.1864, lng: -82.6392 },
    { lat: 38.3761, lng: -82.5966 },
    { lat: 38.4106, lng: -82.4854 },
    { lat: 38.4407, lng: -82.3412 },
    { lat: 38.5890, lng: -82.2876 },
    { lat: 38.5976, lng: -82.1764 },
    { lat: 38.7980, lng: -82.2148 },
    { lat: 39.0160, lng: -82.0280 },
    { lat: 38.9615, lng: -81.9649 },
    { lat: 38.8750, lng: -81.8756 },
    { lat: 38.9370, lng: -81.7671 },
    { lat: 39.0117, lng: -81.7657 },
    { lat: 39.0800, lng: -81.7520 },
    { lat: 39.1790, lng: -81.7465 },
    { lat: 39.2642, lng: -81.5872 },
    { lat: 39.3969, lng: -81.4499 },
    { lat: 39.3481, lng: -81.3675 },
    { lat: 39.4012, lng: -81.2370 },
    { lat: 39.4691, lng: -81.1093 },
    { lat: 39.5644, lng: -81.0104 },
    { lat: 39.6120, lng: -80.9198 },
    { lat: 39.6522, lng: -80.8319 },
    { lat: 39.8054, lng: -80.8072 },
    { lat: 39.8802, lng: -80.7893 },
    { lat: 39.8518, lng: -80.7990 },
    { lat: 40.0980, lng: -80.7495 },
    { lat: 40.2282, lng: -80.7262 },
    { lat: 40.3926, lng: -80.6918 },
    { lat: 40.5910, lng: -80.6451 },
    { lat: 40.6338, lng: -80.5188 },
    { lat: 39.7241, lng: -80.5174 },
    { lat: 39.7209, lng: -79.4778 },
  ],
  // WISCONSIN
  WI: [
    { lat: 42.5116, lng: -90.6303 },
    { lat: 42.4954, lng: -87.0241 },
    { lat: 43.3721, lng: -87.1477 },
    { lat: 43.6838, lng: -87.1230 },
    { lat: 44.1694, lng: -86.9911 },
    { lat: 44.4828, lng: -86.8579 },
    { lat: 44.8792, lng: -86.6876 },
    { lat: 45.2343, lng: -86.2523 },
    { lat: 45.4418, lng: -86.7535 },
    { lat: 45.4438, lng: -87.1024 },
    { lat: 45.3531, lng: -87.1724 },
    { lat: 45.2401, lng: -87.3193 },
    { lat: 45.1995, lng: -87.4100 },
    { lat: 45.0784, lng: -87.4443 },
    { lat: 45.0987, lng: -87.6379 },
    { lat: 45.2217, lng: -87.6956 },
    { lat: 45.3367, lng: -87.6462 },
    { lat: 45.3869, lng: -87.6956 },
    { lat: 45.3425, lng: -87.8549 },
    { lat: 45.4813, lng: -87.7945 },
    { lat: 45.5679, lng: -87.7945 },
    { lat: 45.6764, lng: -87.7876 },
    { lat: 45.7934, lng: -87.9936 },
    { lat: 45.8077, lng: -88.1145 },
    { lat: 45.8728, lng: -88.0733 },
    { lat: 45.9521, lng: -88.1982 },
    { lat: 46.0122, lng: -88.5498 },
    { lat: 45.9970, lng: -88.7036 },
    { lat: 46.0380, lng: -88.8451 },
    { lat: 46.1389, lng: -89.0909 },
    { lat: 46.3412, lng: -90.1195 },
    { lat: 46.5664, lng: -90.4147 },
    { lat: 47.2876, lng: -89.9615 },
    { lat: 47.3100, lng: -90.6578 },
    { lat: 46.7079, lng: -92.0352 },
    { lat: 46.5928, lng: -92.2948 },
    { lat: 46.0761, lng: -92.2975 },
    { lat: 46.0132, lng: -92.3579 },
    { lat: 45.9769, lng: -92.5227 },
    { lat: 45.8135, lng: -92.7466 },
    { lat: 45.6140, lng: -92.8564 },
    { lat: 45.5477, lng: -92.7754 },
    { lat: 45.4293, lng: -92.6587 },
    { lat: 45.3029, lng: -92.7136 },
    { lat: 44.9648, lng: -92.7466 },
    { lat: 44.7877, lng: -92.7686 },
    { lat: 44.6530, lng: -92.6422 },
    { lat: 44.5504, lng: -92.3552 },
    { lat: 44.4652, lng: -92.2632 },
    { lat: 44.4024, lng: -92.0654 },
    { lat: 44.2580, lng: -91.8745 },
    { lat: 44.0600, lng: -91.6383 },
    { lat: 43.9612, lng: -91.4941 },
    { lat: 43.8296, lng: -91.3184 },
    { lat: 43.6440, lng: -91.2524 },
    { lat: 43.5167, lng: -91.2305 },
    { lat: 43.3342, lng: -91.2085 },
    { lat: 43.2932, lng: -91.0547 },
    { lat: 43.1651, lng: -91.1865 },
    { lat: 43.0257, lng: -91.1536 },
    { lat: 42.9082, lng: -91.1426 },
    { lat: 42.7692, lng: -91.0547 },
    { lat: 42.6915, lng: -90.7910 },
    { lat: 42.6340, lng: -90.7031 },
    { lat: 42.5096, lng: -90.6303 },
  ],
  // WYOMING
  WY: [
    { lat: 41.0037, lng: -104.0556 },
    { lat: 44.9949, lng: -104.0584 },
    { lat: 44.9998, lng: -111.0539 },
    { lat: 40.9986, lng: -111.0457 },
    { lat: 41.0006, lng: -104.0556 },
  ],
};

export default provinces;
