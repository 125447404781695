import {
  fetchDeals, addDeal, getDeal, updateDeal, deleteDeal, fetchAllDeals, patchDeal, getHouseDeals, deleteDeals,
} from 'actions/deals';

const deals = () => {
  const initialState = {
    deals: null,
    selectedDeal: null,
    allDeals: null,
  };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case fetchDeals.requestTypes.REQUEST:
        return {
          ...state,
          deals: null,
          selectedDeal: null,
        };
      case fetchDeals.requestTypes.SUCCESS:
        return {
          ...state,
          deals: response,
        };
      case fetchAllDeals.requestTypes.REQUEST:
        return {
          ...state,
          allDeals: null,
        };
      case fetchAllDeals.requestTypes.SUCCESS:
        return {
          ...state,
          allDeals: response,
        };
      case addDeal.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case addDeal.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case addDeal.requestTypes.SUCCESS:
        return {
          ...state,
          selectedDeal: response,
          isSuccessful: true,
        };
      case updateDeal.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateDeal.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateDeal.requestTypes.SUCCESS:
        return {
          ...state,
          selectedDeal: response,
          isSuccessful: true,
        };
        //
      case patchDeal.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case patchDeal.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case patchDeal.requestTypes.SUCCESS:
        return {
          ...state,
          selectedDeal: response,
          isSuccessful: true,
        };
      case getDeal.requestTypes.SUCCESS:
        return {
          ...state,
          selectedDeal: response,
        };
      case getHouseDeals.requestTypes.REQUEST:
        return {
          ...state,
          houseDeals: null,
        };
      case getHouseDeals.requestTypes.SUCCESS:
        return {
          ...state,
          houseDeals: response,
        };

      case deleteDeal.requestTypes.SUCCESS:
        return {
          ...state,
          [response.type]: { ...state[response.type], total: state.total - response.ids.length },
          type: response.type,
        };
      case deleteDeals.requestTypes.SUCCESS:
        return {
          ...state,
          [response.type]: { ...state[response.type], total: state.total - response.ids.length },
          type: response.type,
        };
      default:
        return state;
    }
  };
};

export default deals();
