import axios from 'axios';
import Promise from 'bluebird';
import { MARKET_DATA_ROOT } from 'configs/env-vars';

Promise.config({
  cancellation: true,
});

// overwrite native Promise implementation with Bluebird's
window.Promise = Promise;

export default () => {
  const service = axios.create({
    baseURL: MARKET_DATA_ROOT, // url of the api
  });
  service.interceptors.response.use(
    (response) => response,
  );
  return service;
};
