import { makeRequestAction } from './index';

export const addSurvey = makeRequestAction('ADD_SURVEY', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const getSurvey = makeRequestAction('GET_SURVEY', {
  onSuccess(params, response) {
    return {
      response,
    };
  },
});
