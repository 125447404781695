import { getForms } from 'actions/form-builder';

const formBuilder = () => {
  const initialState = {
    forms: null,
    selectedForm: null,
  };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case getForms.requestTypes.SUCCESS:
        return {
          ...state,
          forms: response,
        };
      default:
        return state;
    }
  };
};

export default formBuilder();
