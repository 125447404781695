import { call, takeLatest, put } from 'redux-saga/effects';
import { getEmailTemplate } from 'actions/email-templates';
import { getEmailTemplateApi } from 'services/email-templates';
import fetchEntity from './fetch-entity';

const getEmailTemplateData = fetchEntity.bind(null, getEmailTemplate.actions, getEmailTemplateApi);

export function* loadEmailTemplate({ params }) {
  yield call(getEmailTemplateData, { ...params });

  if (params.type && params.type === 'CLEAR_TEMPLATE') {
    yield put({ type: 'CLEAR_TEMPLATE' });
  }
}

function* watchEmailTemplate() {
  yield takeLatest(getEmailTemplate.actionName, loadEmailTemplate);
}

export default {
  watchEmailTemplate,
};
