import { call, takeLatest } from 'redux-saga/effects';
import { lenderCategoryValues } from 'actions/lenderCategory';
import { lenderCategoryApi } from 'services/lenderCategory';
import fetchEntity from './fetch-entity';

const fetchLenderCategoryValues = fetchEntity.bind(null, lenderCategoryValues.actions, lenderCategoryApi);

export function* loadLenderCategory({ params }) {
  yield call(fetchLenderCategoryValues, { ...params });
}

function* watchLenderCategoryValues() {
  yield takeLatest(lenderCategoryValues.actionName, loadLenderCategory);
}

export default {
  watchLenderCategoryValues,
};
