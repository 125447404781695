import {
  getBrokerContact,
  addBrokerContact,
  getBrokerContacts,
  updateBrokerContact,
  bulkUpdateManager,
} from 'actions/broker-contacts';

const brokerContacts = () => {
  const initialState = {
    brokerContact: {},
    allBrokerContacts: [],
    bulkUpdateSuccess: false,
  };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case getBrokerContact.requestTypes.SUCCESS:
        return {
          ...state,
          brokerContact: response,
          bulkUpdateSuccess: false,
        };
      case addBrokerContact.requestTypes.SUCCESS:
        return {
          ...state,
          brokerContact: response,
          bulkUpdateSuccess: false,
        };
      case updateBrokerContact.requestTypes.SUCCESS:
        return {
          ...state,
          brokerContact: response,
          bulkUpdateSuccess: false,
        };
      case getBrokerContacts.requestTypes.SUCCESS:
        return {
          ...state,
          allBrokerContacts: response,
          bulkUpdateSuccess: false,
        };
      case bulkUpdateManager.requestTypes.SUCCESS:
        return {
          ...state,
          bulkUpdateSuccess: true,
        };
      default:
        return state;
    }
  };
};

export default brokerContacts();
