import client from './main-client';

export const sendLeadsApi = (data) => {
  const formData = new FormData();

  for (const [key, val] of Object.entries(data)) {
    if (key === 'files') {
      val.forEach((item) => formData.append('files', item.originFileObj));
    } else {
      formData.append(key, JSON.stringify(val));
    }
  }

  return client({ 'content-type': 'multipart/form-data' }).post('/bulk-emails-service', formData);
};
export const sendApprovalLeadsEmailApi = (data) => client().put('/bulk-emails-service', data);

export const checkLeadsEmailLimitApi = (data) => client().post('/bulk-emails-service?limit=check', data);
/* eslint-disable eol-last */
