import { call, takeLatest } from 'redux-saga/effects';
import {
  fetchUserTargetValues,
  addUserTargetValues,
  updateUserTargetValues,
  fetchUserTargetAllyears,
} from 'actions/user-target';
import {
  addUserTargetApi, userTargetApi, updateUserTargetApi, fetchUserTargetAllyearsApi,
} from 'services/user-target';
import fetchEntity from './fetch-entity';

const fetchUserTargetValuesData = fetchEntity.bind(
  null,
  fetchUserTargetValues.actions,
  userTargetApi,
);
const fetchUserTargetAllYears = fetchEntity.bind(
  null,
  fetchUserTargetAllyears.actions,
  fetchUserTargetAllyearsApi,
);
function* loadFetchUserTarget({ params }) {
  if (params.targetYear === undefined) {
    const fullDate = new Date();
    const currentYear = fullDate.getFullYear();
    params.targetYear = currentYear;
  }
  yield call(fetchUserTargetValuesData, { ...params });
}
function* loadFetchUserTargetAllyears({ params }) {
  yield call(fetchUserTargetAllYears, { ...params });
}
const fetchAddUserTargetValues = fetchEntity.bind(
  null,
  addUserTargetValues.actions,
  addUserTargetApi,
);

function* loadAddUserTarget({ params }) {
  yield call(fetchAddUserTargetValues, params);
}

function* watchAddUserTarget() {
  yield takeLatest(addUserTargetValues.actionName, loadAddUserTarget);
}

function* watchFetchUser() {
  yield takeLatest(fetchUserTargetValues.actionName, loadFetchUserTarget);
}

function* watchFetchUserAllTarget() {
  yield takeLatest(fetchUserTargetAllyears.actionName, loadFetchUserTargetAllyears);
}

const fetchUpdateUserTargetValues = fetchEntity.bind(
  null,
  updateUserTargetValues.actions,
  updateUserTargetApi,
);

function* loadUpdateUserTarget({ params }) {
  const { id } = params;
  delete params.id;
  const param = params;
  yield call(fetchUpdateUserTargetValues, { param, id });
}

function* watchUpdateUserTarget() {
  yield takeLatest(updateUserTargetValues.actionName, loadUpdateUserTarget);
}

export default {
  watchFetchUser,
  watchAddUserTarget,
  watchUpdateUserTarget,
  watchFetchUserAllTarget,
};
