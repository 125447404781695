import { makeRequestAction } from './index';

export const loansValues = makeRequestAction('LOANS', {
  onSuccess(params, response) {
    return {
      response: {
        entities: {
          loansData: response,
        },
      },
    };
  },
});

export const addLoan = makeRequestAction('CREATE_LOAN');

// export const addLoan = makeRequestAction('CREATE_LOAN', {
//   onSuccess(params, response) {
//     return {
//       response: {
//         entities: {
//           createLoan: response,
//         },
//       },
//     };
//   },
// });

export const updateLoan = makeRequestAction('UPDATE_LOAN', {
  onSuccess(params, response) {
    return {
      response: {
        entities: {
          updateLoan: response,
        },
      },
    };
  },
});
