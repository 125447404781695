import { makeRequestAction } from './index';

export const getAllNotesAction = makeRequestAction('GET_NOTES_COLLIERS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const addNoteAction = makeRequestAction('ADD_NOTE_COLLIERS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const updateNoteAction = makeRequestAction('UPDATE_NOTE_COLLIERS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const deleteNoteAction = makeRequestAction('DELETE_NOTE_COLLIERS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});
