import { getMarkets, getAllMarkets } from 'actions/markets';

const markets = () => {
  const initialState = {
    markets: {},
    allMarkets: [],
  };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case getMarkets.requestTypes.SUCCESS:
        return {
          ...state,
          markets: response,
        };
      case getAllMarkets.requestTypes.SUCCESS:
        return {
          ...state,
          allMarkets: response,
        };
      default:
        return state;
    }
  };
};

export default markets();
