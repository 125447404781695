import styled, { css } from 'styled-components';
import { Tag as tag } from 'antd';

const Tag = styled(tag)`
  padding: 4px 7px !important;
  ${(props) => props.color === 'blue'
    && css`
      border-color: #4962f5 !important;
      background: none !important;
      color: #4962f5 !important;
      font-size:12px!important;
      margin-top: 5px!important;
      margin-bottom: 10px!important;
    `}
    ${(props) => props.color === 'white'
    && css`
      border: 1px solid rgba(255,255,255,0.2) !important;
      background-color: rgba(255,255,255,0.05) !important;
      margin-bottom: 10px!important;
      strong
      {
        color: #fff;
      }
    `}
`;

/** @component */
export default Tag;
