import {
  getPropertyTypes, getAllPropertyTypes,
} from 'actions/property-types';

const propertyTypes = () => {
  const initialState = {
    propertyTypes: {},
    allPropertyTypes: [],
  };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case getPropertyTypes.requestTypes.SUCCESS:
        return {
          ...state,
          propertyTypes: response,
        };
      case getAllPropertyTypes.requestTypes.SUCCESS:
        return {
          ...state,
          allPropertyTypes: response,
        };
      default:
        return state;
    }
  };
};

export default propertyTypes();
