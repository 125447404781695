import { call, takeLatest } from 'redux-saga/effects';
import {
  reportsValues,
  getReport,
  addReport,
  updateReport,
  deleteReport,
  guestReport as getGuestReport,
  updateGuestReport,
} from 'actions/annual-reports';
import {
  reportsApi,
  getReportApi,
  addReportApi,
  updateReportApi,
  deleteReportApi,
  guestReportApi,
  updateGuestReportApi,
} from 'services/annual-reports';
import fetchEntity from './fetch-entity';

const fetchReportsValues = fetchEntity.bind(null, reportsValues.actions, reportsApi);

export function* loadReports({ params }) {
  yield call(fetchReportsValues, { ...params });
}

function* watchReportsValues() {
  yield takeLatest(reportsValues.actionName, loadReports);
}

const fetchReportData = fetchEntity.bind(
  null,
  getReport.actions,
  getReportApi,
);

export function* loadFetchReportData({ params }) {
  yield call(fetchReportData, { ...params });
}

function* watchFetchReportData() {
  yield takeLatest(getReport.actionName, loadFetchReportData);
}

const postAddReport = fetchEntity.bind(null, addReport.actions, addReportApi);

export function* loadAddReport({ params }) {
  yield call(postAddReport, { ...params });
}

function* watchAddReport() {
  yield takeLatest(addReport.actionName, loadAddReport);
}

const postUpdateReport = fetchEntity.bind(null, updateReport.actions, updateReportApi);

export function* loadUpdateReport({ params }) {
  yield call(postUpdateReport, { ...params });
}

function* watchUpdateReport() {
  yield takeLatest(updateReport.actionName, loadUpdateReport);
}

const removeReport = fetchEntity.bind(null, deleteReport.actions, deleteReportApi);

export function* loadRemoveReport({ params }) {
  yield call(removeReport, { ...params });
}

function* watchRemoveReport() {
  yield takeLatest(deleteReport.actionName, loadRemoveReport);
}

const guestReport = fetchEntity.bind(null, getGuestReport.actions, guestReportApi);

export function* loadGuestReport({ params }) {
  yield call(guestReport, { ...params });
}

function* watchGuestReport() {
  yield takeLatest(getGuestReport.actionName, loadGuestReport);
}

const postUpdateGuestReport = fetchEntity.bind(null, updateGuestReport.actions, updateGuestReportApi);

export function* loadPostUpdateReport({ params }) {
  yield call(postUpdateGuestReport, { ...params });
}

function* watchUpdateGuestReport() {
  yield takeLatest(updateGuestReport.actionName, loadPostUpdateReport);
}

export default {
  watchReportsValues,
  watchFetchReportData,
  watchAddReport,
  watchUpdateReport,
  watchRemoveReport,
  watchGuestReport,
  watchUpdateGuestReport,
};
