import {
  sendDealEmail,
  sendApprovalEmail,
  checkDealEmailLimit,
} from 'actions/deal-email';

const dealEmail = () => {
  const initialState = {};

  return (state = initialState, { type, response }) => {
    switch (type) {
      case sendDealEmail.requestTypes.SUCCESS:
        return {
          ...state,
          email: response,
        };
      case sendApprovalEmail.requestTypes.SUCCESS:
        return {
          ...state,
          email: response,
        };
      case checkDealEmailLimit.requestTypes.SUCCESS:
        return {
          ...state,
          response,
        };
      default:
        return state;
    }
  };
};

export default dealEmail();
