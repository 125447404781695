import styled, { css } from 'styled-components';
import propTypes from 'prop-types';

const ThumbnailWrapper = styled.div`
  
  ${(props) => (props.mode === 'cover' ? css`
    width: ${props.width ? props.width : '100%'};
    height: ${props.height ? props.height : '400px'};
    `
    : css`
    ${props.thumbWidth && `width: ${props.thumbWidth}px;`}
    ${props.thumbWidth && `height: ${props.thumbHeight}px;`}
    border-radius: 6px;
    overflow: hidden;
  `)
}
    float: left;
    margin: 10px 10px 0px 0; 
    background: url('${(props) => props.imgsrc}'); 
    background-repeat: no-repeat; 
    background-size: contain;
    cursor: pointer;
`;

ThumbnailWrapper.propTypes = {
  imgsrc: propTypes.string,
  thumbWidth: propTypes.number,
  thumbHeight: propTypes.number,
};

ThumbnailWrapper.defaultProps = {
  thumbWidth: 100,
  thumbHeight: 100,
};

export default ThumbnailWrapper;
