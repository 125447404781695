import client from './main-client';

export const reportsApi = (params) => client().get('annual-reports', { params });

export const getReportApi = (data) => client().get(`/annual-reports/${data.id}`);

export const addReportApi = (data) => client().post('/annual-reports', data);

export const updateReportApi = (data) => client().put(`/annual-reports/${data.id}`, data);

export const deleteReportApi = (data) => client().delete(`/annual-reports/${data.reportId}?info=${JSON.stringify(data.info)}`);

export const guestReportApi = (data) => client().get(`/guest-service/?service=annual-reports&id=${data.id}&guestToken=${data.guestToken}`);

export const updateGuestReportApi = (data) => client().patch(
  `/guest-service/?service=report-documents&id=${data.docId}&guestToken=${data.guestToken}`,
  data,
);
