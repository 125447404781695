import { API_ROOT } from 'configs/env-vars';
import { makeRequestAction } from './index';

export const getColliersLeads = makeRequestAction('GET_COLLIERS_LEADS', {
  onSuccess(params, response) {
    return {
      response: response.code === 204 ? null : {
        ...response,
      },
    };
  },
});

export const getColliersMarketLeads = makeRequestAction('GET_COLLIERS_MARKET_LEADS', {
  onSuccess(params, response) {
    return {
      response: response.code === 204 ? null : {
        ...response,
      },
    };
  },
});

export const downloadLeads = makeRequestAction('DOWNLOAD_LEADS', {
  onFailure(params, response) {
    return {
      response,
    };
  },
  onSuccess(params, response) {
    if (!response.status) {
      return {
        response: {
          fileName: `${params.type}_${params.leadType}_leads.csv`,
          file: response.blob(),
        },
      };
    }
    return {
      response,
    };
  },
});

export const getFinneoLeads = makeRequestAction('GET_FINNEO_LEADS', {
  onSuccess(params, response) {
    return {
      response: response.code === 204 ? null : {
        ...response,
      },
    };
  },
});

export const getRelatedFinneoLeads = makeRequestAction('GET_RELATED_FINNEO_LEADS', {
  onSuccess(params, response) {
    return {
      response: response.code === 204 ? null : {
        ...response,
      },
    };
  },
});

export const getPreviousFinneoLeads = makeRequestAction('GET_PREVIOUS_FINNEO_LEADS', {
  onSuccess(params, response) {
    return {

      response: response.code === 204 ? null : {
        ...response,
      },
    };
  },
});

export const finneoUploadLeads = makeRequestAction('UPLOAD_FINNEO_LEADS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const colliersUploadLeads = makeRequestAction('UPLOAD_COLLIERS_LEADS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const getTemplateDownloadLink = makeRequestAction('GET_TEMPLATE_DOWNLOAD_LINK', {
  onSuccess(params, response) {
    return {
      response: `${API_ROOT}/file-storage/${response.data.hashedId}`,
    };
  },
});

export const getFinneoLeadPermission = makeRequestAction('GET_FINNEO_LEAD_PERMISSION', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const getColliersLeadPermission = makeRequestAction('GET_COLLIERS_LEAD_PERMISSION', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const getMarketLeadPermission = makeRequestAction('GET_MARKET_LEAD_PERMISSION', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const deleteUnassignColliersLeads = makeRequestAction('DELETE_COLLIERS_LEADS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const assignColliersLeads = makeRequestAction('ASSIGN_COLLIERS_LEADS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const updatestageColliersLeads = makeRequestAction('UPDATE_STAGE_COLLIERS_LEADS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const updateLeads = makeRequestAction('UPDATE_LEADS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});
