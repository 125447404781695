import { makeRequestAction } from './index';

export const getPropertyTypes = makeRequestAction('GET_PROPERTY_TYPES', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const getAllPropertyTypes = makeRequestAction('GET_ALL_PROPERTY_TYPES', {
  onSuccess(params, response) {
    return {
      response,
    };
  },
});
