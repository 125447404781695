import { call, takeLatest } from 'redux-saga/effects';
import {
  getLoanTypes,
} from 'actions/loan-types';
import {
  getLoanTypesApi,
} from 'services/loan-types';
import fetchEntity from './fetch-entity';

const getLoanTypesData = fetchEntity.bind(
  null,
  getLoanTypes.actions,
  getLoanTypesApi,
);

export function* loadGetLoanTypes({ params }) {
  yield call(getLoanTypesData, { ...params });
}

function* watchGetLoanTypes() {
  yield takeLatest(getLoanTypes.actionName, loadGetLoanTypes);
}

export default {
  watchGetLoanTypes,
};
