import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from 'components';
import { Icon as icon } from 'antd';
import { withRouter } from 'react-router-dom';

const Icon = styled(icon)`
  color: #4962f5 !important;
  font-size: 21px;
  font-weight: bold;
  transform: rotate(90deg);
  line-height: 21px !important;
`;

const ActionButtonWrapper = styled.div`
  .button-wrapper {
    display: none;
    opacity: 0;
    z-index: -1;
    padding-right: 10px;
    margin-right: -5px;
    transition-duration: 0.6s;
  }
  :hover .button-wrapper {
    display: block;
    opacity: 1;
    z-index: 100;
  }
  .more-icon {
    cursor: pointer;
  }
`;

const ButtonWrapper = styled.div`
position:absolute;
  top: calc(94% - 20px);
  right: 0px;
  width: 700%;
  height:0px;
  text-align: right;
  z-index:11;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+5 */
  background: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 5%,
    rgba(255, 255, 255, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 5%,
    rgba(255, 255, 255, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 5%,
    rgba(255, 255, 255, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
  button {
    margin-left: 5px;
    margin-right: 5px;
    padding: 0 !important;
    border: 0 !important;
    font-size: 12px !important;
  }
  a {
    border: 0 !important;
    font-size: 12px !important;
  }
`;

const ActionButtons = (props) => {
  const [showAccordion, setShowAccordion] = useState(false);
  const Buttons = props.actions && Object.keys(props.actions).length ? Object.keys(props.actions).map((key, index) => {
    const { to } = props.actions[key];
    return (
      <Button
        {...props.actions[key]}
              // href={props.actions[key].href}
        onClick={
                to
                  ? () => {
                    props.history.replace(to);
                  }
                  : props.actions[key].onClick
              }
        key={props.actions[key].label}
            > {props.actions[key].label}
      </Button>
    );
  })
    : null;

  return (
    <ActionButtonWrapper>
      <Icon
        type='more'
        className={'more-icon'}
        onClick={() => setShowAccordion(!showAccordion)}
      />
      { showAccordion ? <ButtonWrapper>{Buttons}</ButtonWrapper> : null}
    </ActionButtonWrapper>
  );
};

export default withRouter(ActionButtons);
