import client from './main-client';

export const addBrokerContactApi = (data) => client().post('/broker-company-contacts', data);
export const getBrokerContactApi = ({ id, ...params }) => client().get(`/broker-company-contacts/${id}`, { ...params });
export const getBrokerContactsApi = (params) => client().get('/broker-company-contacts', { params });
export const updateBrokerContactApi = ({ id, ...params }) => client().patch(`/broker-company-contacts/${id}`, { ...params });
export const bulkUpdateManagerApi = (data) => {
  const queryString = data.brokerIds && Array.isArray(data.brokerIds)
    ? data.brokerIds.map((item) => `id[$in][]=${item}`).join('&')
    : '';

  delete data.brokerIds;
  return client().patch(`/broker-company-contacts?bulkAssign=true&${queryString}`, data);
};
