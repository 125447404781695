import { getTerritories } from 'actions/territories';

const territories = () => {
  const initialState = {};

  return (state = initialState, { type, response }) => {
    switch (type) {
      case getTerritories.requestTypes.SUCCESS:
        return {
          ...response,
        };
      default:
        return state;
    }
  };
};

export default territories();
