import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import AppRouter from './AppRouter';
import AuthProvider from './AuthProvider';
import './app.css';

// Note: we import this globally, but are only using it selectively in charts, so will uregister here:
// See: https://chartjs-plugin-datalabels.netlify.app/guide/getting-started.html#registration
Chart.plugins.unregister(ChartDataLabels);

class App extends Component {
  render() {
    return (
      <Provider store={this.props.store}>
        <AuthProvider>
          {/* NO-807: antd bug in Select - global solution: https://github.com/ant-design/ant-design/issues/8268#issuecomment-594475570 */}
          <ConfigProvider getPopupContainer={(trigger) => (trigger ? trigger.parentNode : document.body)}>
            <AppRouter />
          </ConfigProvider>
        </AuthProvider>
      </Provider>
    );
  }
}

App.propTypes = {
  store: PropTypes.object.isRequired,
};

export default App;
