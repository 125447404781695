import { call, takeLatest } from 'redux-saga/effects';

import {
  sendLeadEmail as sendLeadEmailAction,
  sendLeadApprovalEmail as sendLeadApprovalEmailAction,
  checkLeadEmailLimit as checkLeadEmailLimitAction,
  // addLeadEmailUploads
} from 'actions/leads-email';
import { sendLeadsApi, sendApprovalLeadsEmailApi, checkLeadsEmailLimitApi } from 'services/leads-email';
// import { addUploadApi } from 'services/uploads';
import fetchEntity from './fetch-entity';

const sendLeadsEmail = fetchEntity.bind(null, sendLeadEmailAction.actions, sendLeadsApi);

export function* loadSendLeadsEmail({ params }) {
  // yield put({ type: 'CLEAR_UPLOADS' });
  yield call(sendLeadsEmail, { ...params });
}

function* watchSendLeadsEmail() {
  yield takeLatest(sendLeadEmailAction.actionName, loadSendLeadsEmail);
}

const sendApprovalLeadsEmail = fetchEntity.bind(null, sendLeadApprovalEmailAction.actions, sendApprovalLeadsEmailApi);

export function* loadSendLeadsApprovalEmail({ params }) {
  yield call(sendApprovalLeadsEmail, { ...params });
}

function* watchSendLeadsApprovalEmail() {
  yield takeLatest(sendLeadApprovalEmailAction.actionName, loadSendLeadsApprovalEmail);
}

const checkLeadsEmailLimit = fetchEntity.bind(null, checkLeadEmailLimitAction.actions, checkLeadsEmailLimitApi);
export function* loadCheckLeadsEmail({ params }) {
  yield call(checkLeadsEmailLimit, { ...params });
}

function* watchCheckLeadsEmail() {
  yield takeLatest(checkLeadEmailLimitAction.actionName, loadCheckLeadsEmail);
}

export default {
  watchSendLeadsEmail,
  // watchAddEmailUploads,
  watchSendLeadsApprovalEmail,
  watchCheckLeadsEmail,
};
