import {
  fetchInboxEmails,
  addInboxEmail,
  getInboxEmail,
  updateInboxEmail,
  deleteInboxEmail,
} from 'actions/inbox-emails';

const inboxEmails = () => {
  const initialState = {
    inboxEmails: null,
    selectedInboxEmail: null,
  };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case fetchInboxEmails.requestTypes.SUCCESS:
        return {
          ...state,
          inboxEmails: response,
        };
      case addInboxEmail.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case addInboxEmail.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case addInboxEmail.requestTypes.SUCCESS:
        return {
          ...state,
          selectedInboxEmail: response,
          isSuccessful: true,
        };
      case updateInboxEmail.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateInboxEmail.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateInboxEmail.requestTypes.SUCCESS:
        return {
          ...state,
          selectedInboxEmail: response,
          isSuccessful: true,
        };
      case getInboxEmail.requestTypes.SUCCESS:
        return {
          ...state,
          selectedInboxEmail: response,
        };
      case deleteInboxEmail.requestTypes.SUCCESS:
        return {
          ...state,
          [response.type]: { ...state[response.type], total: state.total - response.ids.length },
          type: response.type,
        };
      default:
        return state;
    }
  };
};

export default inboxEmails();
