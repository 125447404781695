import {
  getService, getServices, deleteServices, updateService, addNewService,
} from 'actions/services';

const services = () => {
  const initialState = {};

  return (state = initialState, action) => {
    const { type, response } = action;
    switch (type) {
      case getServices.requestTypes.SUCCESS:
        return {
          ...state,
          ...response,
          isSuccessful: false,
        };
      case getService.requestTypes.SUCCESS:
        return {
          ...state,
          selectedService: response,
        };
      case deleteServices.requestTypes.SUCCESS:
        return {
          ...state,
          [response.type]: { ...state[response.type], total: state.total - response.ids.length },
          type: response.type,
        };
      case addNewService.requestTypes.REQUEST:
      case updateService.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case addNewService.requestTypes.SUCCESS:
      case updateService.requestTypes.SUCCESS:
        return {
          ...state,
          isSuccessful: true,
        };
      case addNewService.requestTypes.FAILURE:
      case updateService.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      default:
        return state;
    }
  };
};

export default services();
