import {
  call, takeLatest,
} from 'redux-saga/effects';
import {
  getCollaboratorsApi, updateCollaboratorsApi, addCollaboratorsApi, deleteCollaboratorsApi,
} from 'services/collaborator';
import {
  getCollaborators, addCollaborators, updateCollaborators, deleteCollaborators,
} from 'actions/collaborator';
import fetchEntity from './fetch-entity';

const fetchCollaboratorsData = fetchEntity
  .bind(null, getCollaborators.actions, getCollaboratorsApi);

export function* loadFetchCollaborators({ params }) {
  yield call(fetchCollaboratorsData, params);
}

function* watchFetchCollaborators() {
  yield takeLatest(getCollaborators.actionName, loadFetchCollaborators);
}

const fetchAddCollaborators = fetchEntity.bind(
  null,
  addCollaborators.actions,
  addCollaboratorsApi,
);

export function* loadAddCollaborators({ params }) {
  yield call(fetchAddCollaborators, params);
}

function* watchAddCollaborators() {
  yield takeLatest(addCollaborators.actionName, loadAddCollaborators);
}

const fetchUpdateCollaborators = fetchEntity
  .bind(null, updateCollaborators.actions, updateCollaboratorsApi);

export function* loadUpdateCollaborators({ params }) {
  yield call(fetchUpdateCollaborators, params);
}

function* watchUpdateCollaborators() {
  yield takeLatest(updateCollaborators.actionName, loadUpdateCollaborators);
}

const fetchDeleteCollaborators = fetchEntity
  .bind(null, deleteCollaborators.actions, deleteCollaboratorsApi);

export function* loadDeleteCollaborators({ params }) {
  yield call(fetchDeleteCollaborators, params);
}

function* watchDeleteCollaborators() {
  yield takeLatest(deleteCollaborators.actionName, loadDeleteCollaborators);
}

export default {
  watchFetchCollaborators,
  watchAddCollaborators,
  watchUpdateCollaborators,
  watchDeleteCollaborators,
};
