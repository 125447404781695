import {
  call, takeLatest,
} from 'redux-saga/effects';
import {
  getFinneoClients,
  getScheduleBClients,
} from 'actions/owners';
import {
  getOwnersApi,
} from 'services/owners';
import fetchEntity from './fetch-entity';

const fetchGetFinneoClients = fetchEntity.bind(
  null,
  getFinneoClients.actions,
  getOwnersApi,
);

export function* loadGetFinneoClients({ params }) {
  yield call(fetchGetFinneoClients, params);
}

function* watchGetFinneoClients() {
  yield takeLatest(getFinneoClients.actionName, loadGetFinneoClients);
}

const fetchGetScheduleBClients = fetchEntity.bind(
  null,
  getScheduleBClients.actions,
  getOwnersApi,
);

export function* loadGetScheduleBClients({ params }) {
  yield call(fetchGetScheduleBClients, params);
}

function* watchGetScheduleBClients() {
  yield takeLatest(getScheduleBClients.actionName, loadGetScheduleBClients);
}

export default {
  watchGetFinneoClients,
  watchGetScheduleBClients,
};
