import { makeRequestAction } from './index';

export const getCollaborators = makeRequestAction('GET_COLLOABORATORS', {
  onSuccess(params, response) {
    return {
      response: {
        data: response,
      },
    };
  },
});

export const addCollaborators = makeRequestAction('ADD_COLLOABORATORS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const updateCollaborators = makeRequestAction('UPDATE_COLLOABORATORS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const deleteCollaborators = makeRequestAction('DELETE_COLLOABORATORS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});
