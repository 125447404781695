import React from 'react';
import { Row, Col, Form } from 'antd';
import { FormItem, Input, Button } from 'components';

const AddTab = (props) => {
  const { form: { getFieldDecorator, getFieldError } } = props;
  const categoryNameError = getFieldError('categoryName') && getFieldError('categoryName')[0];

  const handleSubmit = (event) => {
    event.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        props.handleOk(values.categoryName);
        props.form.resetFields();
      }
    });
  };

  return (
    <Form>
      <FormItem
        label='Category Name'
        validateStatus={(props.validation || categoryNameError) ? 'error' : ''}
        help={props.validation || categoryNameError}
      >
        {getFieldDecorator('categoryName', {
          initialValue: '',
          rules: [
            {
              required: true,
              message: 'Categrory name is required.',
            },
          ],
        })(<Input />)}
      </FormItem>
      <Row gutter={[20, 20]} style={{ marginTop: '25px' }}>
        <Col span={24} offset={8}>
          <Button onClick={() => props.hideModal(false)}>Cancel</Button>
          &nbsp;
          <Button type='primary' onClick={handleSubmit}>Ok</Button>
        </Col>
      </Row>
    </Form>
  );
};

const AddTabForm = Form.create({ name: 'AddTabForm' })(AddTab);
export default AddTabForm;
