import styled, { css } from 'styled-components';

const Seperater = styled.div`
    border-left: 1px solid #D0D1DB;
    position: relative;
    z-index: 99999;
    height: 8px;
    top: 47px;
    ${(props) => props.left
        && css`
        left: ${props.left};
    `}
    ${(props) => props.top
        && css`
        top: ${props.top};
    `}
`;

export default Seperater;
