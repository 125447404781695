import { call, takeLatest } from 'redux-saga/effects';
import { getDashboardCompanies } from 'actions/dashboard-companies';
import { getDashboardCompaniesApi } from 'services/dashboard-companies';
import fetchEntity from './fetch-entity';

const fetchDashboardCompanies = fetchEntity
  .bind(null, getDashboardCompanies.actions, getDashboardCompaniesApi);

export function* loadFetchDashboardCompanies({ params }) {
  yield call(fetchDashboardCompanies, { ...params });
}

function* watchFetchDashboardCompanies() {
  yield takeLatest(getDashboardCompanies.actionName, loadFetchDashboardCompanies);
}

export default {
  watchFetchDashboardCompanies,
};
