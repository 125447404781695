import { makeRequestAction } from './index';

export const listings = makeRequestAction('LISTINGS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const addListings = makeRequestAction('CREATE_LISTINGS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const updateListing = makeRequestAction('UPDATE_LISTINGS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const updateMultipleListings = makeRequestAction('UPDATE_MULTIPLE_LISTINGS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const brokerListings = makeRequestAction('BROKER_LISTINGS', {
  onSuccess(params, response) {
    return {
      response: [
        ...response,
      ],
    };
  },
});
