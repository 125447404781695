import { API_ROOT } from 'configs/env-vars';

import { makeRequestAction } from './index';

export const fetchDeals = makeRequestAction('FETCH_DEALS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const fetchAllDeals = makeRequestAction('FETCH_ALL_DEALS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const addDeal = makeRequestAction('ADD_DEAL', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const updateDeal = makeRequestAction('UPDATE_DEAL', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const patchDeal = makeRequestAction('PATCH_DEAL', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const getDeal = makeRequestAction('GET_DEAL', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const getHouseDeals = makeRequestAction('GET_HOUSE_DEALS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const deleteDeal = makeRequestAction('DELETE_DEAL', {
  onSuccess(params, response) {
    return {
      response: { ids: [response.id], type: response.type },
    };
  },
});

export const addConfAgreUpload = makeRequestAction('ADD_DEAL_CONFAGRE_UPLOAD', {
  onSuccess(params, response) {
    return {
      files: response.map((file) => ({
        uid: file.hashedId,
        name: file.fileName,
        url: `${API_ROOT}/file-storage/${file.hashedId}`,
        source: file.source,
      })),
    };
  },
});

export const deleteConfAgreUpload = makeRequestAction('DELETE_DEAL_CONFAGRE_UPLOAD', {
  onSuccess(params, response) {
    return {
      deletedFileId: response.hashedId,
    };
  },
});

export const deleteDeals = makeRequestAction('DELETE_DEALS', {
  onSuccess(params, response) {
    return {
      response: { ids: [response.id], type: response.type },
    };
  },
});
