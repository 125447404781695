import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import logo from 'assets/logo.png';

const LogoImg = styled.img`
  margin: ${(props) => props.margin + props.importance};
  padding: ${(props) => props.padding + props.importance};
  color: ${(props) => props.color + props.importance};
`;

LogoImg.propTypes = {
  margin: propTypes.string,
  padding: propTypes.string,
  width: propTypes.string,
  color: propTypes.string,
  importance: propTypes.string,
};

LogoImg.defaultProps = {
  margin: '15px 0 15px 0',
  height: '50px',
  maxWidth: '100%',
  padding: 'padding',
  color: '#fff',
  importance: '!important',
};

const Logo = ({ margin, width, padding }) => (
  <LogoImg src={logo} margin={margin} width={width} padding={padding} />
);

export default Logo;
