import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styled, { css } from 'styled-components';
import propTypes from 'prop-types';
import reactComponentDebounce from 'react-component-debounce';

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ color: [] }, { background: [] }],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    [{ script: 'sub' }, { script: 'super' }],
    ['link', 'image'],
    ['clean'],
    [{ align: [] }],
  ],
};

const formats = [
  'header',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'color', 'background',
  'list', 'bullet', 'indent',
  'script',
  'link', 'image',
  'clean',
  'align',
];

const StyledReactQuillDef = styled(ReactQuill)`
  background-color: white;
  .ql-editor
  {
    min-height: 250px;
  }
  ${(props) => (props.type === 'messagebox')
    && css`
    .ql-editor
    {
      min-height: 50px;
    }
    `
} 
`;

/* NO-537 - Resolves the performance issue associated with slow typing in any input fields on large forms */
const StyledReactQuill = reactComponentDebounce({
  valuePropName: 'value',
  triggerMs: 2500,
})(StyledReactQuillDef);

StyledReactQuill.propTypes = {
  type: propTypes.string,
};

StyledReactQuill.defaultProps = {
  modules,
  formats,
};

export default StyledReactQuill;
