import styled from 'styled-components';

const Sticky = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: ${(props) => props.offsetTop || 0}px; 
  z-index: 10;
`;

export default Sticky;
