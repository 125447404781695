import {
  getPreFundingData, addPreFundingData,
  updatePreFundingData, deletePreFundingData,
  resetPreFundingData, getPreFundingCountData, addSyndicateLendersData, generatePrefundingTokenData, getShareableLinkData, deleteFileData,
} from 'actions/preFundingTracker';

import { Message } from 'antd';

const preFundingTracker = () => {
  const initialState = {
    preFundingDocumentCount: null,
    preFundingData: null,
    preFundingRequest: false,
    preFundingSuccess: false,
    preFundingFailure: false,
    preFundingGuestToken: null,
    preFundingShareableLinkData: null,
  };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case getPreFundingData.requestTypes.SUCCESS: {
        return {
          ...state,
          preFundingData: response,
          preFundingRequest: false,
          preFundingSuccess: false,
          preFundingFailure: false,
        };
      }

      // Add operation
      case addPreFundingData.requestTypes.REQUEST: {
        return {
          ...state,
          preFundingRequest: true,
          preFundingFailure: false,
          preFundingSuccess: false,
        };
      }

      case addPreFundingData.requestTypes.SUCCESS: {
        Message.success('PreFunding Document Added');
        return {
          ...state,
          preFundingRequest: false,
          preFundingFailure: false,
          preFundingSuccess: true,
        };
      }

      case addPreFundingData.requestTypes.FAILURE: {
        Message.warning('Please Try Again!');
        return {
          ...state,
          preFundingRequest: false,
          preFundingFailure: true,
          preFundingSuccess: false,
        };
      }

      // Update operation
      case updatePreFundingData.requestTypes.REQUEST: {
        return {
          ...state,
          preFundingRequest: true,
          preFundingFailure: false,
          preFundingSuccess: false,
        };
      }

      case updatePreFundingData.requestTypes.SUCCESS: {
        Message.success('PreFunding Document Updated');
        return {
          ...state,
          preFundingRequest: false,
          preFundingFailure: false,
          preFundingSuccess: true,
        };
      }

      case updatePreFundingData.requestTypes.FAILURE: {
        Message.warning('Please Try Again!');
        return {
          ...state,
          preFundingRequest: false,
          preFundingFailure: true,
          preFundingSuccess: false,
        };
      }

      // Delete operation
      case deletePreFundingData.requestTypes.REQUEST: {
        return {
          ...state,
          preFundingRequest: true,
          preFundingFailure: false,
          preFundingSuccess: false,
        };
      }

      case deletePreFundingData.requestTypes.SUCCESS: {
        Message.success('PreFunding Document Deleted');
        return {
          ...state,
          preFundingRequest: false,
          preFundingFailure: false,
          preFundingSuccess: true,
        };
      }

      case deletePreFundingData.requestTypes.FAILURE: {
        Message.warning('Please Try Again!');
        return {
          ...state,
          preFundingRequest: false,
          preFundingFailure: true,
          preFundingSuccess: false,
        };
      }

      // delete file corresponding to document
      case deleteFileData.requestTypes.REQUEST: {
        return {
          ...state,
          preFundingRequest: true,
          preFundingFailure: false,
          preFundingSuccess: false,
        };
      }

      case deleteFileData.requestTypes.SUCCESS: {
        Message.success('File Deleted');
        return {
          ...state,
          preFundingRequest: false,
          preFundingFailure: false,
          preFundingSuccess: true,
        };
      }

      case deleteFileData.requestTypes.FAILURE: {
        Message.warning('Please Try Again!');
        return {
          ...state,
          preFundingRequest: false,
          preFundingFailure: true,
          preFundingSuccess: false,
        };
      }

      // add Syndicate Lenders
      case addSyndicateLendersData.requestTypes.REQUEST: {
        return {
          ...state,
          preFundingRequest: true,
          preFundingFailure: false,
          preFundingSuccess: false,
        };
      }

      case addSyndicateLendersData.requestTypes.SUCCESS: {
        // Message.success('Syndicate Lenders Added');
        return {
          ...state,
          preFundingRequest: false,
          preFundingFailure: false,
          preFundingSuccess: true,
        };
      }

      case addSyndicateLendersData.requestTypes.FAILURE: {
        // Message.warning('Please Try Again!');
        return {
          ...state,
          preFundingRequest: false,
          preFundingFailure: true,
          preFundingSuccess: false,
        };
      }

      // reset prefunding data
      case resetPreFundingData.requestTypes.SUCCESS: {
        return {
          ...state,
        };
      }

      // preFunding guest token
      case generatePrefundingTokenData.requestTypes.SUCCESS: {
        return {
          ...state,
          preFundingGuestToken: response.prefundingGuestToken,
        };
      }

      case generatePrefundingTokenData.requestTypes.FAILURE: {
        return {
          ...state,
          preFundingGuestToken: null,
        };
      }

      // getShareable link data
      case getShareableLinkData.requestTypes.SUCCESS: {
        return {
          ...state,
          preFundingShareableLinkData: response,
        };
      }

      case getShareableLinkData.requestTypes.FAILURE: {
        return {
          ...state,
          preFundingShareableLinkData: response,
        };
      }

      // getting prefunding count
      case getPreFundingCountData.requestTypes.SUCCESS: {
        return {
          ...state,
          preFundingDocumentCount: response,
        };
      }

      default: {
        return {
          ...state,
        };
      }
    }
  };
};

export default preFundingTracker();
