import { call, takeLatest } from 'redux-saga/effects';
import {
  sendLenderInfo,
  fetchLenderLoans,
  fetchLoanNumbers,
  fetchBorrowerData,
  fetchLoanBorrowers,
  fetchLoanComparison,
} from 'actions/lender-loans';
import { fetchLenderLoansApi, sendLenderInfoLoansApi } from 'services/lender-loans';
import fetchEntity from './fetch-entity';

const fetchLenderLoansData = fetchEntity.bind(
  null,
  fetchLenderLoans.actions,
  fetchLenderLoansApi,
);

export function* loadFetchLenderLoanData({ params }) {
  yield call(fetchLenderLoansData, { ...params });
}

function* watchFetchLenderLoansData() {
  yield takeLatest(fetchLenderLoans.actionName, loadFetchLenderLoanData);
}

const fetchLoanNumbersData = fetchEntity.bind(
  null,
  fetchLoanNumbers.actions,
  fetchLenderLoansApi,
);

export function* loadFetchLoanNumbersData({ params }) {
  yield call(fetchLoanNumbersData, { ...params });
}

function* watchFetchLoanNumbersData() {
  yield takeLatest(fetchLoanNumbers.actionName, loadFetchLoanNumbersData);
}

const sendLenderLoansData = fetchEntity.bind(
  null,
  sendLenderInfo.actions,
  sendLenderInfoLoansApi,
);

export function* loadSendLendersInfo({ params }) {
  yield call(sendLenderLoansData, { ...params });
}

function* watchsendLenderLoansData() {
  yield takeLatest(sendLenderInfo.actionName, loadSendLendersInfo);
}

const fetchLoanBorrowersData = fetchEntity.bind(
  null,
  fetchLoanBorrowers.actions,
  fetchLenderLoansApi,
);

export function* loadFetchLoanBorrowersData({ params }) {
  yield call(fetchLoanBorrowersData, { ...params });
}

function* watchFetchLoanBorrowersData() {
  yield takeLatest(fetchLoanBorrowers.actionName, loadFetchLoanBorrowersData);
}

const fetchBorrowerInfo = fetchEntity.bind(
  null,
  fetchBorrowerData.actions,
  fetchLenderLoansApi,
);

export function* loadFetchBorrowersData({ params }) {
  yield call(fetchBorrowerInfo, { ...params });
}

function* watchFetchBorrowersData() {
  yield takeLatest(fetchBorrowerData.actionName, loadFetchBorrowersData);
}

const fetchLoanComparisonData = fetchEntity.bind(
  null,
  fetchLoanComparison.actions,
  fetchLenderLoansApi,
);

export function* loadFetchLoanComparisonData({ params }) {
  yield call(fetchLoanComparisonData, { ...params });
}

function* watchFetchLoanComparisonData() {
  yield takeLatest(fetchLoanComparison.actionName, loadFetchLoanComparisonData);
}

export default {
  watchFetchLenderLoansData,
  watchFetchLoanNumbersData,
  watchsendLenderLoansData,
  watchFetchBorrowersData,
  watchFetchLoanBorrowersData,
  watchFetchLoanComparisonData,
};
