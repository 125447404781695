import styled from 'styled-components';
import { List as list } from 'antd';

const Messenger = styled(list)`
    margin-left: -40px !important;
    height: 100%; 
    background: #fff;
   
    h4
    {
        font-size: 14px;
        font-weight: bold;
    }
    .date
    {
         font-size: 12px;
    }
    .active
    {
        background-color: #F9EDBE;
    }
    .ant-list-item
    {
        padding: 30px !important;
        position: realive;
        cursor: pointer; 
    }
    .date
    {
        position: absolute;
        top: 15px;
        right: 15px;
        font-size: 11px;

    }
    
`;

/** @component */
export default Messenger;
