import styled from 'styled-components';
import { Layout } from 'antd';

const sider = Layout.Sider;

const Sider = styled(sider)`
    min-height: 100vh !important; 
`;

/** @component */
export default Sider;
